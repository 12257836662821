<template>
  <div class="h-full flex flex-col gap-4 mt-4">
    <svn-pro-text-field
      v-model="participantSearch"
      :label="$t('Search')"
      :disabled="disabled"
      prepend-inner-icon="custom:mingcute:search-2-line"
    />

    <div class="w-full flex flex-col gap-2 items-start">
      <div class="w-full flex flex-col items-start gap-1">
        <svn-pro-text body-medium medium>
          {{ $t('Selected participant(s) : ') }} {{ learnTraining?.participant_ids?.length || 0 }}
        </svn-pro-text>

        <v-card variant="text" class="w-full" :disabled="disabled">
          <v-data-table
            v-model="learnTraining.participant_ids"
            :headers="users?.length ? headers : []"
            :items="users ?? []"
            :items-per-page="10"
            :page="page"
            :search="participantSearch"
            select-strategy="all"
            :loading="users ? loading : true"
            item-value="id"
            :pagination-total-pages="totalPages"
            :show-select="users?.length ? true : false"
          >
            <template #item="{ item }">
              <tr class="hover:bg-[#46464F14] active:bg-[#46464F1F]/[0.12]">
                <!-- Checkbox -->
                <td class="px-2">
                  <v-checkbox-btn
                    v-model="learnTraining.participant_ids"
                    hide-details
                    :value="item?.id"
                  />
                </td>
  
                <!-- User with avatar, fullname, email -->
                <td>
                  <svn-pro-data-table-cell
                    cell-type="avatar_text_supporting_text"
                    :avatar="item?.avatar?.['50']"
                    :text="item?.fullname"
                    :supporting-text="item?.email"
                  />
                </td>
  
                <!-- Job title tag -->
                <td>
                  <svn-pro-data-table-cell
                    cell-type="textt"
                    :text="item?.job_title"
                  />
                </td>
              </tr>
            </template>
  
            <template #bottom="{ pageCount }">
              <svn-pro-pagination
                v-if="totalUsers > 10"
                v-model="page"
                :length="pageCount"
                class="mt-6"
              />
            </template>
  
            <template #no-data>
              <svn-pro-empty-states
                actions
                :variant="participantSearch ? 'results' : 'index'"
                :title="$t('Nothing to show for now')"
                :size="isMdScreen ? 'default' : 'compact'"
                :supporting-text="participantSearch ? $t('Oops, we didn’t find any results matching your search.') : $t('Hmm, it seems there is no user yet.')"
              />
            </template>
  
            <template #loading>
              <div class="w-full h-[693px] flex justify-center items-center">
                <svn-pro-progress-circular
                  :size="48"
                  :width="4"
                  indeterminate
                />
              </div>
            </template>
          </v-data-table>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script setup>
import i18n from '@/plugins/i18n';
import { storeToRefs } from "pinia";
import { ref, onMounted, computed } from 'vue';
import { useMobileStore } from "@/store/mobile";
import { useSnackbar } from "@/store/snackbar.js";
import { useCompanyStore } from "@/store/company.js";
import { useLearnTrainingStore } from '@/store/learn-trainings';

const props = defineProps({
  disabled: { type: Boolean, default: false },
});

const snackbar = useSnackbar();
const { fetchCompanyUsers } = useCompanyStore();

const { users } = storeToRefs(useCompanyStore());
const { isMdScreen } = storeToRefs(useMobileStore());
const { learnTraining } = storeToRefs(useLearnTrainingStore());

onMounted(async() => {
  try {
    if (!users?.value?.length) {
      loading.value = true
      await fetchCompanyUsers();
      loading.value = false
    }
  } catch (error) {
    snackbar.setBgColor("onSurface").setCustomClass('mb-4').setMsg("Error fetching users.").displaySnackBar();
  }
});

const page = ref(1);
const headers = ref([
  {
    align: "start",
    key: "fullname",
    sortable: false,
    title: i18n.global.t("People"),
    minWidth: 225,
  },
  {
    align: "start",
    key: "job_title",
    sortable: true,
    title:  i18n.global.t("Job title"),
    minWidth: 160,
  },
]);
const loading = ref(false);
const participantSearch = ref('');
const totalPages = computed(() => {
  return (users?.value?.length / 10)
})
const totalUsers = computed(() => {
  return users?.value?.length
})
</script>