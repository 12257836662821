<template>
  <div
    class="sm:max-w-[348px] md:max-w-[472px] h-[324px] flex flex-col
      lg:max-w-[378px] xl:max-w-[341px] 2xl:w-full skeleton
      rounded-[12px] border border-darkGrey overflow-hidden cursor-progress"
  >
    <v-skeleton-loader
      type="image"
      class="h-fit"
    />

    <div class="flex flex-col p-4 h-1/2 justify-between">
      <div class="flex flex-col gap-2">
        <v-skeleton-loader type="list-item" />

        <v-skeleton-loader type="subtitle" />
      </div>

      <div class="flex items-center justify-between">
        <div class="flex flex-col items-start">
          <div class="flex items-start gap-2">
            <v-skeleton-loader
              type="list-item"
              class="w-[75px]"
            />

            <v-skeleton-loader
              type="list-item"
              class="w-[125px]"
            />
          </div>
        </div>
        
        <v-skeleton-loader
          type="chip"
          class="w-[34px] rounded-full"
        />
      </div>
    </div>
  </div>
</template>