import {defineStore} from "pinia";
import {genericFunctions, genericStates} from "@/tools/genericFunctions.js";
import axiosService from "@/tools/axios-service.js";

export const useRoadmapTemplateStore = defineStore('roadmap_template', {
  state: () => ({
    ...genericStates,
    roadmapTemplates: null,
    roadmapTrashesTemplates: [],
    roadmapTemplate: null,
  }),
  actions: {
    ...genericFunctions,
    async fetchRoadmapTemplates() {
      const roadmapTemplateStore = useRoadmapTemplateStore()

      await roadmapTemplateStore.fetchEntity('/api/v1/roadmaps/templates', 'objective_elements', 'roadmapTemplates')
    },
    async fetchRoadmapTrashesTemplates() {
      const roadmapTemplateStore = useRoadmapTemplateStore()

      await roadmapTemplateStore.fetchEntity('/api/v1/roadmaps/templates/trashes', 'objective_elements', 'roadmapTrashesTemplates')
    },
    async fetchRoadmapTemplate(id) {
      const res = await this.fetchEntity(`/api/v1/roadmaps/templates/${id}`, 'objective_element', 'roadmapTemplate')

      return res
    },
    async deleteRoadmapTemplate(id) {
      const roadmapTemplateStore = useRoadmapTemplateStore()

      await roadmapTemplateStore.deleteEntity(`/api/v1/roadmaps/templates/${id}`)
      const trash = this.roadmapTemplates.find(trash => trash.id === id)
      if (trash) {
        trash.deleted_at = new Date()
        this.roadmapTrashesTemplates.push(trash)
      }
      this.roadmapTemplates = this.roadmapTemplates.filter(trash => trash.id !== id)
    },
    async deletePermanentlyRoadmapTemplate(id) {
      const roadmapTemplateStore = useRoadmapTemplateStore()

      await roadmapTemplateStore.deleteEntity(`/api/v1/roadmaps/templates/${id}/destroy_permanently`)
      this.removeTemplatesFromTrashes(id)
    },
    async restoreRoadmapTemplate(id) {
      const roadmapTemplateStore = useRoadmapTemplateStore()

      await roadmapTemplateStore.updateEntity(`/api/v1/roadmaps/templates/${id}/restore`)
      this.roadmapTemplates.push(this.roadmapTrashesTemplates.find(trash => trash.id === id))
      this.removeTemplatesFromTrashes(id)
    },
    removeTemplatesFromTrashes (id) {
      this.roadmapTrashesTemplates = this.roadmapTrashesTemplates.filter(trash => trash.id !== id)
    }
  }
})
