<template>
  <apps-header
    app-icon="mingcute:building-2-line"
    app-title=""
  >
    <router-view />
  </apps-header>
</template>
  
<script setup>
  import AppsHeader from '../components/header/AppsHeader.vue'
  import { useRoute } from 'vue-router'
  import { onUpdated, ref } from "vue";
  import { useTheme } from "vuetify";

const theme = useTheme()
theme.global.name.value = 'alephLight'
let routeName = ref(useRoute().name)

onUpdated(() => {
  routeName.value = useRoute().name
})
</script>