<template>
  <pop-up-skeleton>
    <template #body>
      <div class="flex flex-col justify-start -mt-12">
        <p class="text-fakeBlack text-start font-medium text-xl mb-4">
          {{ $t('Description') }}
        </p>

        <textarea
          readonly
          class="h-[440px] sm:w-[500px]  text-sm text-start text-fakeBlack font-normal pb-8"
          :value="data?.desc"
        />
      </div>
    </template>
  </pop-up-skeleton>
</template>

<script setup>
import PopUpSkeleton from './PopUpSkeleton.vue';
import { usePopUpStore } from '@/store/pop-up';
import { storeToRefs } from 'pinia';

const { data } = storeToRefs(usePopUpStore());
</script>