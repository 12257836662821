<template>
  <svn-pro-modal
    ref="manageReviewers"
    :width="isMdScreen ? 528 : undefined"
    :title="$t('Manage reviewers')"
  >
    <template #activator="{ props }">
      <slot name="activator" :props="props" />
    </template>

    <template #text>
      <div class="w-full flex flex-col gap-2">
        <svn-pro-autocomplete
          :key="reviewerAutocomplete"
          v-model="userToAdd"
          item-value="id"
          :menu-icon="'custom:mingcute:down-small-fill'"
          item-title="fullname"
          :clearable="false"
          :placeholder="$t('Search for reviewers')"
          :items="computedSearchUsers"
          @input="customSearch($event)"
          @update:model-value="addPair"
        />

        <v-list :height="388" v-if="interviews?.length">
          <v-list-item
            v-for="(feedbackInterview, idx) in interviews"
            :active="false"
            :key="idx"
          >
            <div class="w-full flex items-center">
              <div class="flex gap-4 flex-1 items-center">
                <svn-pro-avatar
                  :size="40"
                  :firstname="feedbackInterview?.employee?.fullname"
                  :image="feedbackInterview?.employee?.avatar?.['50']"
                  :type="feedbackInterview?.employee?.avatar?.['50'] ? 'photo' : 'monogram'"
                />

                <div class="flex flex-col">
                  <svn-pro-text body-large regular class="line-clamp-1">
                    {{ feedbackInterview?.employee?.fullname }}
                  </svn-pro-text>

                  <svn-pro-text body-medium regular :color="getColorByCampaignStatus(feedbackInterview?.status)">
                    {{ $t(getTextByCampaignStatus(feedbackInterview?.status)) }}
                  </svn-pro-text>
                </div>
              </div>

              <svn-pro-button
                v-if="feedbackInterview?.status === 'not_started'"
                variant="text"
                :text="$t('Remove')"
                @click="removePair(feedbackInterview?.id)"
              />

              <svn-pro-dialog-validation
                v-else
                ref="confirmRemoveReviewer"
                icon="noto:warning"
                :width="312"
                :title="$t('If you continue, answers will be deleted.')"
                :action-two-title="$t('Cancel')"
                :action-one-title="$t('Yes, remove')"
                :content-text="$t('This reviewer has started to answer. If you remove him/her, answers will be deleted.')"
                @click-primary-button="removeAnsweredPair(feedbackInterview?.id)"
                @click-secondary-button="confirmRemoveReviewer.dialog = false"
              >
                <template #activator="{ props }">
                  <svn-pro-button
                    v-bind="props"
                    variant="text"
                    :text="$t('Remove')"
                  />
                </template>
              </svn-pro-dialog-validation>
            </div>
          </v-list-item>
        </v-list>

        <div
          v-else
          class="w-full h-[388px] flex flex-col justify-center items-center gap-2"
        >
          <div>
            <Icon
              icon="noto:busts-in-silhouette"
              height="32"
              width="32"
            />
          </div>

          <div class="flex flex-col gap-1">
            <svn-pro-title h6 medium class="text-center">
              {{ $t('No reviewers selected') }}
            </svn-pro-title>

            <svn-pro-text body-medium regular color="onSurfaceVariant" class="text-center">
              {{ $t('Search for reviewers and add them.') }}
            </svn-pro-text>
          </div>
        </div>
      </div>
    </template>
  </svn-pro-modal>
</template>

<script setup>
import { debounce } from "lodash";
import { storeToRefs } from "pinia";
import { Icon } from "@iconify/vue";
import { onMounted, ref, computed } from "vue";
import { useSnackbar } from "@/store/snackbar.js";
import useCampaigns from "@/tools/useCampaigns.js";
import axiosService from "@/tools/axios-service.js";
import { useCompanyStore } from "@/store/company.js";
import { useMobileStore } from "@/store/mobile";

const snackbar = useSnackbar();
const { searchCompanyUsers } = useCompanyStore();
const { getTextByCampaignStatus, getColorByCampaignStatus } = useCampaigns();

const { searchUsers } = storeToRefs(useCompanyStore());
const { isMdScreen } = storeToRefs(useMobileStore());

const props = defineProps({
  participantId: { type: Number, default: null },
  feedbackId: { type: Number, default: null },
  feedback: { type: Object, default: null },
  canAutoEvaluation: {type: Boolean, default: false},
  campaignId: { type: Number, default: null },
  feedbackInterviews: { type: Array, default: null },
});

const interviews = ref([]);
const reviewerAutocomplete = ref(1);
const manageReviewers = ref(null);
const userToAdd = ref(null);
const confirmRemoveReviewer = ref(null);

onMounted(async () => {
  interviews.value = props?.feedbackInterviews?.filter((int) => int?.employee)
});

const computedSearchUsers = computed(() => {
  return searchUsers?.value.filter((user) => {
    if (!interviews?.value.find(interview => interview?.employee?.id == user?.id )) {
      return user
    }
  })
})

const addPair = debounce(async(pairId) => {
  if (!pairId) return

  const response = await axiosService.post(`/api/v2/interview_app/campaigns/${props?.campaignId}/interview_sets/generate_interview_feedback`,
    {
      feedback_id: props.feedbackId,
      pair_id: pairId,
    }
  );
  if (response.status === 422) {
    snackbar.setBgColor('onSurface').displaySnackBar(response.data.message)
  } else if ( response.status === 200) {
    snackbar.setBgColor('onSurface').displaySnackBar('Reviewer added successfully.')
    interviews?.value?.push(response.data);
  }
  userToAdd.value = null
  reviewerAutocomplete.value = reviewerAutocomplete.value + 1
}, 300);

const removePair = debounce(async(interviewId) => {
  try {
    await axiosService.delete(`/api/v2/interview_app/campaigns/${props?.campaignId}/interview_sets/remove_interview_feedback`,
      {
        params: {
          interview_id: interviewId,
        },
      }
    );

    let index = interviews?.value?.findIndex((item) => item?.id === interviewId);
    interviews?.value?.splice(index, 1);
    snackbar.setBgColor('onSurface').displaySnackBar('Reviewer removed successfully.')
  } catch (e) {
    snackbar.setBgColor('onSurface').displaySnackBar('Error removing pair')
  }
}, 300);

const removeAnsweredPair = (interviewId) => {
  removePair(interviewId);
};

const customSearch = async(search, limit = 10) => {
  await searchCompanyUsers(search, limit);
};

defineExpose({
  manageReviewers
})
</script>