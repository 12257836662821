<template>
  <div class="flex flex-col p-4 items-end border border-opacity-100 border-borderColor rounded-[8px]" :class="isEditing ? 'gap-6' : 'gap-1'">
    <!-- Editing Mode Tag category -->
    <div
      v-if="isEditing"
      class="w-full flex flex-col gap-4"
    >
      <svn-pro-title h6 medium>
        {{ tagCategory?.name }} 
      </svn-pro-title>

      <!-- (v) => (v && checkUniqueCategoryName(tagCategory?.name)) || 'This category name already exists', -->
      <svn-pro-text-field
        variant="outlined"
        v-model="tagCategory.name"
        :label="$t('Category name')"
        :rules="[
          (v) => !!v || 'Category name cannot be empty',
        ]"
      />
    </div>

    <!-- View Mode Tag category -->
    <div v-else class="w-full flex justify-between items-center pl-4">
      <svn-pro-title h6 medium>
        {{ tagCategory?.name }}
      </svn-pro-title>

      <!-- Buttons -->
      <div class="flex items-center gap-2">
        <!-- Edit Tag Category -->
        <svn-pro-icon-button
          variant="text"
          color="onSurfaceVariant"
          icon="custom:mingcute:edit-2-line"
          @click="editLabel(tagCategory?.id)"
        />

        <!-- Delete Tag Category -->
        <svn-pro-dialog-validation
          icon="noto:warning"
          :title="$t('Delete category?')"
          :contentText="$t('This category and all the associated tags will be permanently lost.')"
          :action-one-title="$t('Yes, delete')"
          :action-two-title="$t('Cancel')"
          @click-primary-button="deleteTagCategory()"
        >
          <template #activator="{ props }">
            <svn-pro-icon-button
              v-bind="props"
              variant="text"
              color="onSurfaceVariant"
              icon="custom:mingcute:delete-2-line"
            />
          </template>
        </svn-pro-dialog-validation>
      </div>
    </div>

    <!-- Editing Mode Tags -->
    <div
      v-if="isEditing"
      class="w-full flex flex-col gap-4"
    >
      <svn-pro-title h6 medium>
        {{ $t('Tags') }}
      </svn-pro-title>

      <!-- Tags list & Buttons -->
      <div class="flex flex-col gap-4 self-stretch">
        <!-- Tags list -->
        <!-- (v) => (v && !tags?.find(el => el.tagName === tag.tagName)) || 'This tag name already exists', -->
        <div
          v-for="(tag, index) in tags"
          :key="tag?.id"
          :class="!tag._destroy ? 'flex flex-col self-stretch gap-1' : ''"
        >
          <div v-if="!tag._destroy" class="w-full flex items-center gap-2">
            <svn-pro-text-field
              v-model="tag.tagName"
              :placeholder="$t('Tag')"
              variant="outlined"
              :rules="[
                (v) => !!v || 'Tag name cannot be empty',
              ]"
            />

            <svn-pro-icon-button
              variant="text"
              color="onSurfaceVariant"
              icon="custom:mingcute:close-line"
              @click="removeTagFromCategory(tag?.id, index)"
            />
          </div>
        </div>

        <!-- Add new tag button -->
        <svn-pro-button
          variant="outlined"
          prepend-icon="custom:mingcute:add-line"
          :text="$t('New tag')"
          class="w-fit"
          @click="addNewTag"
        />
      </div>
    </div>

    <!-- View Mode Tags -->
    <div
      v-else
      class="flex flex-col gap self-stretch truncate"
    >
      <v-list class="!pb-0">
        <v-list-item
          v-if="tags?.length > 10"
          v-for="tag in tags?.slice(0, tags?.indexOf(tags?.[10]))"
          :key="tag?.id"
          density="compact"
          :active="false"
        >
          <svn-pro-text subtitle-large regular>
            {{ tag?.tagName }}
          </svn-pro-text>
        </v-list-item>

        <v-list-item
          v-else
          v-for="tag in tags"
          :key="tag"
          density="compact"
          :active="false"
        >
          <svn-pro-text subtitle-large regular>
            {{ tag?.tagName }}
          </svn-pro-text>
        </v-list-item>
      </v-list>

      <v-list v-if="showAll" class="!pt-0">
        <v-list-item
          v-for="tag in tags?.slice(tags?.indexOf(tags?.[10]))"
          :key="tag?.id"
          density="compact"
          :active="false"
        >
          <svn-pro-text subtitle-large regular>
            {{ tag?.tagName }}
          </svn-pro-text>
        </v-list-item>
      </v-list>

      <svn-pro-button
        v-if="tags?.length > 10"
        variant="text"
        :text="showAll ? $t('Show less') : $t('Show all')"
        class="w-fit transition-all"
        :prepend-icon="showAll ? 'custom:mingcute:up-line' : 'custom:mingcute:down-line'"
        @click="showAll = !showAll"
      />
    </div>

    <!-- Buttons -->
    <div v-if="isEditing" class="flex self-end">
      <!-- Cancel button -->
      <svn-pro-button
        variant="text"
        :text="$t('Cancel')"
        class="w-fit"
        @click="cancelAndReset(index)"
      />

      <!-- Save button -->
      <svn-pro-button
        variant="flat"
        :text="$t('Save')"
        class="w-fit"
        :disabled="!tagCategory.name ||
          checkUniqueCategoryName(tagCategory.name) ||
          checkUniqueTagName(tags) ||
          checkEmptyTagName(tags)"
        @click="updateTagAndTagCategory"
      />
    </div>
  </div>
</template>

<script setup>
import { useCompanyStore } from '@/store/company';
import { storeToRefs } from 'pinia';
import { ref, onMounted } from 'vue';
import { useSnackbar } from '@/store/snackbar';
import { useMobileStore } from '@/store/mobile';

onMounted(() => {
  initialSetup()
})

const props = defineProps({
  currentTagCategory: { type: Object, default: null },
  index: { type: Number, default: null },
  tagCategories: { type: Object, default: null }
})

const emit = defineEmits(['recall-tagcategories'])

const { isMobile } = storeToRefs(useMobileStore())

const { updateTagCategoryWithTags, deleteTagCatagory } = useCompanyStore()

const snackbar = useSnackbar()
const tagCategory = ref({})
const tagCategoryName = ref(null)
const tags = ref([])
const isEditing = ref(false)
const showAll = ref(false)

const addNewTag = () => {
  tags.value.push({ tagName: '' })
}

const updateTagAndTagCategory = async() => {
  try {
    const res = await updateTagCategoryWithTags(tagCategory.value, tags.value)
    tagCategory.value = res.data
    tagCategoryName.value = tagCategory.value.name
    tags.value = res.data.tags
    snackbarSavedTagCategoryName()
    isEditing.value = false
  } catch (error) {
    snackbar.setBgColor('onSurface').setMsg('Error updating category name.').setCustomClass('mb-5').displaySnackBar()
  }
}

const editLabel = () => {
  isEditing.value = true
}

const deleteTagCategory = async() => {
  try {
    await deleteTagCatagory(props?.currentTagCategory?.id)
    emit('recall-tagcategories')
    snackbar.setBgColor('onSurface').setMsg('Tag category has been deleted successfully.').setCustomClass('mb-5').displaySnackBar()
  } catch (error) {
    snackbar.setBgColor('onSurface').setMsg('Error deleting Tag category.').setCustomClass('mb-5').displaySnackBar()
  }
}

const removeTagFromCategory = async(tagId, index) => {
  if (tagId) {
    tags.value.forEach((tag) => {
      if (tag.id == tagId) { 
        tag['_destroy'] = '1'
      }
    })
  } else if (!tagId && index >= 0) {
    tags.value = tags.value.filter((tag, tagIndex) => tagIndex != index)
  }
}

const snackbarSavedTagCategoryName = () => {
  snackbar.setBgColor('onSurface').setMsg('Tags have been saved successfully.').setCustomClass('mb-5').displaySnackBar()
}

const initialSetup = () => {
  tagCategoryName.value = props.currentTagCategory.name
  tagCategory.value = props.currentTagCategory
  tags.value = props.currentTagCategory.tags
}

const cancelAndReset = async() => {
  isEditing.value = false
  initialSetup()
  tags.value.forEach(tag => {
    if (tag['_destroy']) {
      delete tag['_destroy']
    }
  })
}

const checkUniqueCategoryName = (name) => {
  let categoryNames = props.tagCategories.map(category => category.name)
  if (categoryNames.filter(x => x === name).length > 1) {
    return true
  }
  else {
    return false
  }
}

const checkUniqueTagName = (tags) => {
  let tagNames = tags.map(tag => tag.tagName)
  let counter = {}
  tagNames.forEach(x => {
    if (counter[x]) {
      counter[x] += 1
    }
    else {
      counter[x] = 1
    }
  })
  let values = Object.values(counter)
  if (values.find(x => x > 1)) {
    return true
  }
  else {
    return false
  }
}

const checkEmptyTagName = (tags) => {
  let found = tags.find(x => !x.tagName)

  if (found) {
    return true
  }
  else {
    return false
  }
}
</script>