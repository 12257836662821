<template>
  <div class="w-full flex flex-col border border-borderColor rounded-[8px] border-opacity-100 mx-auto lg:!mx-0">
    <div class="flex flex-col px-6 py-4">
      <svn-pro-title h6 medium>
        {{ $t('Training completion - Module') }}
      </svn-pro-title>

      <svn-pro-text subtitle-medium regular color="onSurfaceVariant">
        {{ $t('Compare completion by module') }}
      </svn-pro-text>
    </div>

    <svn-pro-divider color="[#767680]" class="border-opacity-100" />

    <div class="flex flex-col px-6 py-4">
      <bkt-bar
        :data="trainingCompletionModuleData"
        :options="trainingCompletionModuleOptions"
      />
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
import BktBar from "@/components/chart/BktBar.vue";

const props = defineProps({
  completionByModule: { type: Object, required: true },
})

const trainingCompletionModuleData = ref({
  labels: props?.completionByModule?.map(module => module?.name),
  datasets: [
    {
      label: '',
      borderRadius: 4,
      backgroundColor: '#3E52CA',
      data: props?.completionByModule?.map(module => module?.data),
    },
  ]
})

const trainingCompletionModuleOptions = {
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    y: {
      suggestedMax: props?.completionByModule?.map(module => module?.data).sort((a, b) => b > a)[0] + 1,
    },
  },
  plugins: {
    legend: {
      display: false,
    }
  },
}
</script>