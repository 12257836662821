<template>
  <div class="w-full max-w-[413px] flex flex-col border border-borderColor rounded-[8px] border-opacity-100 mx-auto lg:!mx-0">
    <div class="flex flex-col px-6 py-4">
      <svn-pro-title h6 medium>
        {{ $t('Training sets completion - All') }}
      </svn-pro-title>

      <svn-pro-text subtitle-medium regular color="onSurfaceVariant">
        {{ $t('Number of people completing the training') }}
      </svn-pro-text>
    </div>

    <svn-pro-divider color="[#767680]" class="border-opacity-100" />

    <div class="flex flex-col px-6 py-4">
      <BktDoughnut
        inner-class="w-full h-full"
        chart-class="w-full h-full"
        :datas="trainingCompletionAllData.completionAllData"
        :labels="[$t('Completed'), $t('In progress'), $t('Not started')]"
        :colors="trainingCompletionAllData.chartColors"
        :chart-options="trainingCompletionAllData.chartCompletionAllOptions"
      />
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
import BktDoughnut from '@/components/chart/BktDoughnut.vue';

const props = defineProps({
  trainingCompletionAll: {
    type: Object,
    required: true,
  },
})

const trainingCompletionAllData = ref({
  completionAllData: [props.trainingCompletionAll.acquired, props.trainingCompletionAll.in_progress, props.trainingCompletionAll.not_started],
  chartColors: ['#1C6D1E', '#CA8100', '#BA1A1A', '#767680'],
  chartCompletionAllOptions: {
    cutout: "55%",
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        position: "left",
        align: "center",
        labels: {
          boxWidth: 21,
          boxHeight: 8,
          color: '#4F4F4F',
          font: {
            weight: 400,
          }
        },
        title: {
          display: false,
        },
        padding: {
          x: 0,
          y: 0
        }
      },
      datalabels: {
        color: '36A2EB',
      }
    },
  },
})
</script>