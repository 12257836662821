export default function () {
  function subtractMonths(numOfMonths, date = new Date()) {
    date.setMonth(date.getMonth() - numOfMonths);
    return date;
  }
  function subtractYears(numOfYears, date = new Date()) {
    date.setFullYear(date.getFullYear() - numOfYears);
    return date;
  }
  function getFirstDayOfYear(year) {
    return new Date(year, 0, 1);
  }
  function getLastDayOfYear(year) {
    return new Date(year, 11, 31);
  }
  return {
    subtractMonths,
      subtractYears,
      getFirstDayOfYear,
      getLastDayOfYear
  }
}
