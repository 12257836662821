<template>
  <svn-pro-info-tag
    :tag-type="getTagTypeByCampaignStatus(status)"
    :leading-item="undefined"
    tag-size="compact"
    tag-style="filled"
    class="w-fit"
    :text="$t(campaign_status_str(status))"
  />
</template>

<script setup>
import useCampaigns from "@/tools/useCampaigns.js";

defineProps({
  status: { type: String, default: '' }
})
const { getTagTypeByCampaignStatus, campaign_status_str } = useCampaigns()
</script>
