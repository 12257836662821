<template>
  <div class="w-full flex flex-col px-4 md:!px-0 gap-4 md:!max-w-[672px] lg:!max-w-[614px] xl:!max-w-[560px] pb-[78px] mx-auto">
    <!-- Card image -->
    <div class="w-full">
      <bkt-image-cover-position
        mode="show"
        :url="training?.cover_url?.['original'] ? training?.cover_url?.['original'] : ''"
        :coordinates="{left: training?.cover_offset_left ?? 0, top: training?.cover_offset_top ?? 0,}"
      />
    </div>

    <div class="flex flex-col gap-4">
      <!-- Duration -->
      <div class="flex items-center gap-1">
        <div>
          <Icon
            icon="mingcute:time-line"
            height="16"
            width="16"
            class="text-onSurfaceVariant"
          />
        </div>

        <svn-pro-text body-medium regular color="onSurfaceVariant">
          {{ $t('Total duration') }}:
        </svn-pro-text>

        <svn-pro-text body-medium bold color="onSurfaceVariant">
          {{ training?.all_modules_duration || $t('No duration') }}
        </svn-pro-text>
      </div>

      <!-- Description -->
      <svn-pro-text subtitle-medium regular color="onSurfaceVariant">
        {{ training?.description }}
      </svn-pro-text>

      <!-- Themes -->
      <div
        v-if="training?.module_themes?.length"
        class="w-full flex items-start content-start gap-2 flex-wrap"
      >
        <svn-pro-info-tag
          v-for="(theme) in training?.module_themes"
          :key="theme"
          tag-type="info"
          tag-style="outlined"
          tag-size="compact"
          :text="theme"
        />
      </div>

      <!-- No theme -->
      <svn-pro-info-tag
        v-else
        tag-type="info"
        tag-style="outlined"
        tag-size="compact"
        :text="$t('No theme')"
      />

      <!-- Content list -->
      <div class="flex flex-col gap-4 md:!gap-6">
        <svn-pro-title h5 medium>
          {{ $t('Content') }}
        </svn-pro-title>

        <module-or-playlist-in-training
          v-if="training?.modules_and_playlist?.length"
          :training-content="training?.modules_and_playlist"
          @go-to-content-show="goToContentShow"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { Icon } from '@iconify/vue';
import { useRouter } from 'vue-router';
import BktImageCoverPosition from "@/components/image/bkt-image-cover-position.vue";
import ModuleOrPlaylistInTraining from '@/components/trainingApp/ModuleOrPlaylistInTraining.vue';

const router = useRouter();

const props = defineProps({
  training: { type: Object, required: true },
  isDeleted: { type: Boolean, required: true },
})

const goToContentShow = (content) => {
  router.push({ name: 'module_show', params: { id: content?.id }, query: { trainingId: props?.training?.id }})
}
</script>