<template>
  <div class="w-full flex flex-col gap-6 md:!max-w-[672px] lg:!max-w-[928px] xl:!max-w-[1136px] pb-[78px] mx-auto">
    <!-- If the training hasn't been launched yet -->
    <div
      v-if="status === 'draft'"
      class="w-full flex flex-col justify-center items-center p-8 self-stretch h-[166px]"
    >
      <svn-pro-text subtitle-medium medium color="onSurfaceVariant">
        {{ $t('No data available yet as training has not yet begun.') }}
      </svn-pro-text>
    </div>

    <div
      v-else
      class="w-full flex flex-col gap-6 px-5 md:!px-0"
    >
      <analytics-trained :training-completion-all="trainingCompletionAll" />

      <div class="w-full flex flex-col gap-4 lg:flex-row">
        <analytics-training-completion-all
          :training-completion-all="trainingCompletionAll"
        />

        <analytics-training-completion-category
          :training-id="trainingId"
          :training-participants-list="trainingParticipantsList"
        />
      </div>

      <analytics-training-completion-module
        :completion-by-module="completionByModule"
      />

      <analytics-participants-list
        :loading="loading"
        :training-participants-list="trainingParticipantsList"
        @get-training-participants="getTrainingParticipants"
      />
    </div>
  </div>
</template>

<script setup>
import AnalyticsTrained from './AnalyticsTrained.vue';
import AnalyticsTrainingCompletionAll from './AnalyticsTrainingCompletionAll.vue';
import AnalyticsTrainingCompletionModule from './AnalyticsTrainingCompletionModule.vue';
import AnalyticsTrainingCompletionCategory from './AnalyticsTrainingCompletionCategory.vue';
import AnalyticsParticipantsList from './AnalyticsParticipantsList.vue';

const props = defineProps({
  status: { type: String, required: true },
  loading: { type: Boolean, default: false },
  trainingId: {type: String, required: true },
  isDeleted: { type: Boolean, default: false },
  completionByModule: { type: Object, required: true },
  trainingCompletionAll: {type: Object, required: true },
  trainingParticipantsList: { type: Array, required: true },
})
const emit = defineEmits(['get-training-participants'])

const getTrainingParticipants = (value) => {
  emit('get-training-participants', value)
}
</script>