<template>
  <div>
    <div
      class="bg-surface flex flex-col w-full lg:!max-w-[928px] xl:!max-w-[1136px] 2xl:pt-10 sm:pt-5 pt-6 md:!max-w-[672px] mx-auto h-full gap-10"
    >
      <div class="flex flex-col gap-6 px-12 md:!px-0">
        <svn-pro-header-section
          :size="isMdScreen ? 'default' : 'compact'"
          :title="$t('My Trainings')"
          :subtitle="$t('Here is the list of all your trainings.')"
        />
        <div class="flex gap-2 md:!gap-4 flex-wrap justify-center md:!justify-start">
          <svn-pro-info-tag
            :text="
              `${trainingCompleted?.completedLearnTrainingCount || 0} ` + $t('Trainings completed')
            "
            tag-type="info"
            tag-style="filled"
            tag-size="compact"
            :break-all="true"
            class="max-w-[900px] justify-self-start p-2"
            leading-item="icon"
            icon="noto:smiling-face-with-sunglasses"
          />

          <svn-pro-info-tag
            :text="
              `${trainingCompleted?.completedLearnModulesCount || 0} ` + $t('Modules acquired')
            "
            tag-type="info"
            tag-style="filled"
            tag-size="compact"
            :break-all="true"
            class="max-w-[900px] justify-self-start p-2"
            leading-item="icon"
            icon="noto:open-book"
          />
        </div>
      </div>

      <div>
        <!-- Tabs -->
        <v-tabs v-model="tab" color="primary" :grow="isMobile">
          <!-- To do Tab -->
          <v-tab
            value="todo"
            class="w-1/2 md:w-auto"
            :text="`${$t('To do')} (${trainingToDo?.todoLearnTrainingCount || 0})`"
          />

          <!-- Archived targets Tab -->
          <v-tab
            value="completed"
            class="w-1/2 md:w-auto"
            :text="`${$t('Completed')} (${trainingCompleted?.completedLearnTrainingCount || 0})`"
          />
        </v-tabs>

        <!-- Windows -->
        <v-window v-model="tab" class="w-full pt-4">
          <!-- ToDo window -->
          <v-window-item value="todo">
            <div
              v-if="trainingToDo?.todoLearnTrainingCount > 0"
              class="flex flex-col flex-wrap lg:!grid lg:!grid-cols-2 gap-4 justify-center"
            >
              <svn-pro-training-card
                v-for="training in trainingToDo?.learnTrainings"
                :card-size="isMdScreen ? 'default' : 'compact'"
                :key="training?.id"
                :title="training?.title"
                :duration="training?.allModulesDuration"
                :status="training?.status"
                :image="training?.coverUrl?.size500"
                :progress="(training?.moduleCompletion / training?.moduleCount) * 100"
                @click-primary-button="goToTrainingShow(training)"
                @go-to-item-show="goToTrainingShow(training)"
                class="cursor-pointer"
                :primary-button-text="
                  training?.moduleCompletion ? 'Keep going!' : 'Start training!'
                "
              />
            </div>

            <template v-else-if="tab === 'todo' && trainingToDo?.todoLearnTrainingCount === 0">
              <svn-pro-empty-states
                :variant="'index'"
                :title="$t('Nothing to show for now')"
                :size="isMdScreen ? 'default' : 'compact'"
                supporting-text="You don't have any training to do yet. </br> Come back later to see your trainings!"
              />
            </template>
          </v-window-item>

          <!-- Completed window -->
          <v-window-item value="completed">
            <div
              v-if="trainingCompleted.learnTrainings?.length"
              class="flex flex-col flex-wrap lg:!grid lg:!grid-cols-2 gap-4 justify-center"
            >
              <svn-pro-training-card
                v-for="training in trainingCompleted.learnTrainings"
                :card-size="isMdScreen ? 'default' : 'compact'"
                :key="training?.id"
                :title="training?.title"
                :duration="training?.allModulesDuration"
                :status="training?.status"
                :image="training?.coverUrl?.size500"
                class="cursor-pointer"
                :progress="
                  (training?.moduleCompletion.moduleCompletion / training?.moduleCount) * 100
                "
                @click-primary-button="goToTrainingShow(training)"
                @go-to-item-show="goToTrainingShow(training)"
              />
            </div>

            <template
              v-else-if="tab === 'completed' && trainingCompleted.learnTrainings?.length === 0"
            >
              <svn-pro-empty-states
                :variant="'index'"
                :title="$t('Nothing to show for now')"
                :size="isMdScreen ? 'default' : 'compact'"
                supporting-text="No training completed...yet!"
              />
            </template>
          </v-window-item>
        </v-window>
      </div>

      <!-- Discover -->
      <div class="flex flex-col py-2 mb-[80px] lg:!py-0 mx-5 md:!mx-0">
        <div class="flex flex-col items-center gap-y-6 overflow-hidden">
          <!-- Header with texts -->
          <div class="w-full flex flex-col px-4 lg:!px-0">
            <svn-pro-title h5 medium color="onSurface" class="flex gap-2 items-center">
              <icon icon="noto:eyes" class="text-onSurface" /> {{ $t('New modules to discover!') }}
            </svn-pro-title>

            <svn-pro-text body-large regular color="onSurfaceVariant">
              {{ $t('Explore the latest modules added to the catalog.') }}
            </svn-pro-text>
          </div>

          <!-- Skeleton loaders -->
          <div
            v-if="learningsModulesToDiscoverIsPending"
            class="flex flex-col justify-center px-4 items-start self-stretch overflow-x-auto lg:!px-0 lg:!overflow-x-visible"
          >
            <div
              class="flex justify-center items-start py-2 gap-2 lg:gap-6 lg:!overflow-x-visible lg:!justify-start"
            >
              <skeleton-loader-module-card v-for="item in isMobile ? 1 : 4" :key="item" />
            </div>
          </div>

          <!-- Finished loading -->
          <div v-else class="flex flex-col justify-center items-start self-stretch overflow-x-auto">
            <!-- Modules card list -->
            <div
              v-if="learningsModulesToDiscover"
              class="flex justify-center items-start py-2 gap-4 lg:!overflow-x-visible lg:!justify-start"
            >
              <module-card
                v-for="module in modulesToDisplay"
                :key="module?.id"
                :module-creator-id="module?.creatorId"
                :to-discover="true"
                :background-image="module?.coverUrl?.size500"
                :title="module?.title"
                :reactions="module?.userReactions"
                :is-favorite="module?.connectedUserFavorited"
                :theme-list="module?.themes"
                :duration="module?.duration"
                :submission-status="module?.submissionStatus"
                @toggle-favorite="toggleModuleFavorite(module?.id, module?.status)"
                @go-to-item-show="goToModuleShow(module)"
              />
            </div>

            <div class="md:flex-col items-end self-stretch flex pt-3">
              <!-- See all modules -->
              <svn-pro-button
                v-if="isMobile || learningsModulesToDiscover?.length !== learnModules?.length"
                variant="text"
                :text="$t('See all')"
                @click="seeAllModules"
              />
            </div>
          </div>
        </div>
      </div>

      <!-- Scroll to top button -->
      <svn-pro-floating-action-button
        to-top
        size="small"
        color="primary"
        variant="tonal"
        :rounded="'lg'"
        class="fixed bottom-4 right-4 bg-white"
        icon="custom:mingcute:arrow-to-up-line"
      />
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref, onUnmounted, computed } from 'vue';
import { useRouter } from 'vue-router';
import { storeToRefs } from 'pinia';
import { Icon } from '@iconify/vue';
import ModuleCard from '@/components/trainingApp/ModuleCard.vue';
import { useUserStore } from '@/store/user.js';
import { useMobileStore } from '@/store/mobile';
import SkeletonLoaderModuleCard from '@/components/skeletonLoaders/SkeletonLoaderModuleCard.vue';
import { useLearnModuleStore } from '@/store/learn-module';
import { useBreadcrumbsStore } from '@/store/breadcrumbs';
import filters from '@/tools/filters';
import { useSnackbar } from '@/store/snackbar';
import TrainingApi from '@/apis/training.api';
import { useQuery } from '@tanstack/vue-query';

const {
  isPending: trainingToDoIsPending,
  isFetching: trainingToDoIsFetching,
  isError: trainingToDoIsError,
  data: trainingToDo,
  error: trainingToDoError,
} = useQuery({
  queryKey: ['learn', 'my-learning', 'todo'],
  queryFn: async ({ queryKey }) => await TrainingApi.getMylearnings({ status: 'todo' }),
});

const {
  isPending: trainingCompletedIsPending,
  isFetching: trainingCompletedIsFetching,
  isError: trainingCompletedIsError,
  data: trainingCompleted,
  error: trainingCompletedError,
} = useQuery({
  queryKey: ['learn', 'my-learning', 'completed'],
  queryFn: async ({ queryKey }) => await TrainingApi.getMylearnings({ status: 'completed' }),
});

const {
  isPending: learningsModulesToDiscoverIsPending,
  isFetching: learningsModulesToDiscoverIsFetching,
  isError: learningsModulesToDiscoverIsError,
  data: learningsModulesToDiscover,
  error: learningsModulesToDiscoverError,
} = useQuery({
  queryKey: ['learn', 'my-learning', 'modules-to-discover'],
  queryFn: async ({ queryKey }) => await TrainingApi.getModulesToDiscover(),
});

const tab = ref(null);

const router = useRouter();

const { addNode, update } = useBreadcrumbsStore();

const { toggleFavorite } = useLearnModuleStore();

const { isMobile, isXlScreen, isMdScreen } = storeToRefs(useMobileStore());

const { firstname } = storeToRefs(useUserStore());

const snackbar = useSnackbar();

const { learnModules } = storeToRefs(useLearnModuleStore());

onMounted(async () => {
  window.addEventListener('resize', updateWindowWidth);
});

const seeAllModules = () => {
  router.push({ name: 'catalog', query: { tab: 'module' } });
};

const toggleModuleFavorite = async (moduleId, status) => {
  try {
    const updatedModule = await toggleFavorite(moduleId, status);
    for (let element of learningsModulesToDiscover?.value) {
      if (element?.id === moduleId) {
        element.connectedUserFavorited = updatedModule?.connected_user_favorited;
      }
    }
    snackbar.setBgColor('onSurface');
    snackbar.setCustomClass(isMobile.value ? 'mb-[72px]' : 'mb-4');
    snackbar.displaySnackBar(
      updatedModule?.connected_user_favorited
        ? 'Module added to favorites'
        : 'Module removed from favorites',
    );
  } catch (error) {
    snackbar.setBgColor('error');
    snackbar.setCustomClass(isMobile.value ? 'mb-[72px]' : 'mb-4');
    snackbar.displaySnackBar('Error toggling favorite');
  }
};

const goToModuleShow = (module) => {
  // updateBreadcrumbs(i18n.global.t('My Learnings'))
  router.push({ name: 'module_show', params: { id: module?.id } });
};

const goToTrainingShow = (training) => {
  // updateBreadcrumbs(i18n.global.t('My Learnings'))
  router.push({ name: 'training_show', params: { id: training?.id } });
};

// Reactive reference for window width
const windowWidth = ref(window.innerWidth);

// Function to update window width
const updateWindowWidth = () => {
  windowWidth.value = window.innerWidth;
};

// Remove event listener on unmount
onUnmounted(() => {
  window.removeEventListener('resize', updateWindowWidth);
});

// Computed property to determine the modules to display based on window width
const modulesToDisplay = computed(() => {
  if (windowWidth.value > 1281) {
    // More than 1281px, show 4 modules
    return learningsModulesToDiscover.value.slice(0, 4);
  } else if (windowWidth.value > 1025) {
    // Between 1025px and 1280px, show 3 modules
    return learningsModulesToDiscover.value.slice(0, 3);
  } else if (windowWidth.value > 600) {
    // Between 600px and 1024px, show 2 modules
    return learningsModulesToDiscover.value.slice(0, 2);
  } else {
    // Less than or equal to 600px, show 1 module
    return learningsModulesToDiscover.value.slice(0, 1);
  }
});
</script>
