<template>
  <div class="w-full flex flex-col gap-4">
    <campaign-show-search
      :set="set"
      :campaign="campaign"
      :is-trashed="isTrashed"
      :overview="overview"
      @fetch="fetchFromCampaignShowSearch($event)"
    />

    <campaign-show-table
      :campaign="campaign"
      :current-user-id="currentUserId || id.toString()"
      :overview="overview"
      :set="set"
      :anonymous="anonymous"
      :set-params="setParams"
      :is-trashed="isTrashed"
      :pagination="pagination"
      @refetch-interview="fetchFromCampaignShowSearch"
    />
  </div>
</template>

<script setup>
import { storeToRefs } from "pinia";
import { useUserStore } from "@/store/user.js";
import { useCampaignStore } from "@/store/campaign.js";
import CampaignShowTable from "@/views/interviews/campaigns/CampaignShowTable.vue";
import CampaignShowSearch from "@/views/interviews/campaigns/CampaignShowSearch.vue";

const props = defineProps({
  set: { type: Array, required: true },
  overview: { type: String, default: null },
  campaignId: { type: String, default: null },
  anonymous: { type: Boolean, default: true },
  isTrashed: { type: Boolean, default: true },
  currentUserId: { type: String, default: null },
});
const { id } = storeToRefs(useUserStore());
const { campaign, pagination } = storeToRefs(useCampaignStore());
const { setParams, updateParams } = useCampaignStore();

const emit = defineEmits(['fetchFromCampaignShowSearch'])

const fetchFromCampaignShowSearch = (event) => {
  setParams(event);
  updateParams({ tags: [] });

  emit('fetchFromCampaignShowSearch')
};
</script>