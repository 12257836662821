<template>
  <router-view />
</template>

<script setup>
import { useTheme } from "vuetify";
import { ref, onUpdated } from "vue";
import { useRoute } from 'vue-router';

let routeName = ref(useRoute().name)
const theme = useTheme();
theme.global.name.value = "alephDark";

onUpdated(() => {
  routeName.value = useRoute().name
})
</script>