<template>
  <div class="w-full flex flex-col gap-6 p-4 lg:!p-6 rounded-[12px] border border-borderColor border-opacity-100">
    <MyInterviewCardHeader
      :campaign-deadline="campaign.deadline"
      :campaign-type="campaign.campaign_type"
      :campaign-name="campaign.title"
    />

    <div class="flex flex-col gap-8">
      <div class="flex flex-col lg:!flex-row gap-6 lg:!gap-14">
        <div
          v-if="interviewSet.employee_interview"
          class="flex gap-2 items-center"
        >
          <svn-pro-avatar
            :size="40"
            :firstname="interviewee?.firstname"
            :image="interviewee?.avatar?.['100']"
            :type="interviewee?.avatar?.['100'] ? 'photo' : 'monogram'"
          />
  
          <div class="flex flex-col">
            <svn-pro-text subtitle-large medium class="line-clamp-1">
              {{ $t('Interviewee') }} {{ `(${$t('you')})` }}
            </svn-pro-text>
  
            <InterviewStatusChip :status="interviewSet.employee_interview.status" />
          </div>
        </div>

        <div
          v-if="interviewSet.manager_interview"
          class="flex gap-2 items-center"
        >
          <svn-pro-avatar
            :size="40"
            :firstname="interviewer?.firstname"
            :image="interviewer?.avatar?.['100']"
            :type="interviewer?.avatar?.['100'] ? 'photo' : 'monogram'"
          />
  
          <div class="flex flex-col">
            <svn-pro-text subtitle-large medium class="line-clamp-1">
              {{ interviewer?.fullname }}
            </svn-pro-text>
  
            <InterviewStatusChip :status="interviewSet.manager_interview.status" />
          </div>
        </div>

        <div
          v-if="interviewSet.crossed_interview"
          class="flex gap-2 items-center"
        >
          <div class="flex -space-x-[14px]">
            <svn-pro-avatar
              :size="40"
              :firstname="interviewee?.firstname"
              :image="interviewee?.avatar?.['100']"
              :type="interviewee?.avatar?.['100'] ? 'photo' : 'monogram'"
            />

            <svn-pro-avatar
              :size="40"
              :firstname="interviewer?.firstname"
              :image="interviewer?.avatar?.['100']"
              :type="interviewer?.avatar?.['100'] ? 'photo' : 'monogram'"
            />
          </div>
  
          <div class="flex flex-col">
            <svn-pro-text subtitle-large medium class="line-clamp-1">
              {{ $t('Cross Review') }}
            </svn-pro-text>
  
            <InterviewStatusChip :status="interviewSet.crossed_interview.status" />
          </div>
        </div>
      </div>

      <svn-pro-button
        v-if="interviewSet?.employee_interview"
        :variant="buttonVariant()"
        class="lg:!self-end"
        :prepend-icon="interviewSet?.employee_interview &&
          (buttonSentence() === 'Start my interview' || buttonSentence() === 'Continue my interview') ? 'custom:mingcute:arrow-right-line' : ''"
        :text="$t(buttonSentence())"
        @click="clickInterview"
      />

      <svn-pro-text v-else caption color="onSurfaceVariant" class="lg:!self-end">
        {{ $t("You have no action to do on this interview.") }}
      </svn-pro-text>
    </div>
  </div>
</template>

<script setup>
import router from "@/router/index.js";
import InterviewStatusChip from "@/components/interviewApp/InterviewStatusChip.vue";
import MyInterviewCardHeader from "@/components/interviewApp/InterviewCardHeader.vue";

const props = defineProps({
  id: {type: Number, required: true},
  campaign: {type: Object, required: true},
  interviewee: {type: Object, required: true},
  interviewer: {type: Object, required: true},
  interviewSet: {type: Object, required: true},
  crossedInterviewId: {type: Number, required: true},
})

const buttonSentence = () => {
  if (props.interviewSet?.crossed_interview?.status === "submitted")
    return "View Cross Review answers"
  else if (props.interviewSet?.employee_interview) {
    switch (props.interviewSet?.employee_interview?.status) {
      case "submitted":
        return "View my answers"
      case "in_progress":
        return "Continue my interview"
      case "not_started":
        return "Start my interview"
      default:
        return ""
    }
  }
  return "You have no action to do on this interview.";
}

const buttonVariant = () => {
  if (props.interviewSet?.crossed_interview?.status === "submitted")
    return "outlined"
  else if (props.interviewSet?.employee_interview) {
    switch (props.interviewSet?.employee_interview?.status) {
      case "submitted":
        return "outlined"
      case "in_progress":
        return "flat"
      case "not_started":
        return "flat"
      default:
        return "plain"
    }
  }
  return "flat";
}

const clickInterview = () => {
  if (props.interviewSet?.crossed_interview?.status === "submitted") {
    router.push({name:"interview_answer", params: { id: props.crossedInterviewId }})
  } else {
    router.push({name:"interview_answer", params: { id: props.id }})
  }
}
</script>
