<template>
  <dialog-validation
    ref="closeModuleCreate"
    title="Do you want to save changes ?"
    description="Your changes will be lost. Save changes in your drafts before closing ?"
    button-primary-color="primary"
    :display-close="false"
    :vertical="isMobile ? true : false"
    secondary-button-text="Quit without saving"
    primary-button-text="Save"
    @click-secondary-button="closeAndGoBack"
    @click-primary-button="saveDraftAndExit"
  >
    <template #button>
      <div>
        <Icon
          :icon="'material-symbols:close'"
          class="h-6 w-6 text-dark-grey hover:text-fake-black cursor-pointer"
        />
      </div>
    </template>
  </dialog-validation>
</template>

<script setup>
import { ref } from "vue";
import { Icon } from "@iconify/vue";
import DialogValidation from "@/components/BktPopUp/Dialogs/learn/DialogValidation.vue";
import { storeToRefs } from "pinia";
import { useMobileStore } from "@/store/mobile";
import { useRouter } from "vue-router";
import { useSnackbar } from "@/store/snackbar";

const emit = defineEmits(['save-draft-and-exit'])

const closeModuleCreate = ref(null)

const router = useRouter()

const snackbar = useSnackbar()

const { isMobile } = storeToRefs(useMobileStore())

const closeAndGoBack = () => {
  closeModuleCreate.value.dialog = false

  snackbar.setBgColor('fakeBlack')
  snackbar.setMsg('Your module has not been saved')
  snackbar.setCustomClass(isMobile.value ? 'mb-[80px]' : '')
  snackbar.displaySnackBar()

  setTimeout(() => {
    router.push({ name: "catalog" })
  }, 150);
}

const saveDraftAndExit = () => {
  closeModuleCreate.value.dialog = false

  emit('save-draft-and-exit')
}
</script>