import MetaPagination from '@/models/meta_pagination';
import SevenImgObject from '@/models/seven_img_object';

export default class LearnPlaylist {
  constructor({
    id,
    title,
    themes,
    status,
    trainings,
    coverUrls,
    creatorId,
    updatedAt,
    deletedAt,
    description,
    contentType,
    learnModules,
    learnModuleIds,
    learnModulesCount,
    allModulesDuration,
    connectedUserFavorited,
  }) {
    this.id = id;
    this.title = title;
    this.themes = themes;
    this.status = status;
    this.trainings = trainings;
    this.coverUrls = coverUrls;
    this.creatorId = creatorId;
    this.updatedAt = updatedAt;
    this.deletedAt = deletedAt;
    this.description = description;
    this.contentType = contentType;
    this.learnModules = learnModules;
    this.learnModuleIds = learnModuleIds;
    this.learnModulesCount = learnModulesCount;
    this.allModulesDuration = allModulesDuration;
    this.connectedUserFavorited = connectedUserFavorited;
  }

  static fromJson(data) {
    const json = {};

    json['id'] = data?.['id']
    json['title'] = data?.['title']
    json['themes'] = data?.['themes']
    json['status'] = data?.['status']
    json['trainings'] = data?.['trainings']
    json['coverUrls'] = data?.['cover_urls']?.map(SevenImgObject.fromJson) ?? []
    json['creatorId'] = data?.['creator_id']
    json['updatedAt'] = data?.['updated_at']
    json['deletedAt'] = data?.['deleted_at']
    json['description'] = data?.['description']
    json['contentType'] = data?.['content_type']
    json['learnModules'] = data?.['learn_modules']
    json['learnModuleIds'] = data?.['learn_module_ids']
    json['learnModulesCount'] = data?.['learn_modules_count']
    json['allModulesDuration'] = data?.['all_modules_duration']
    json['connectedUserFavorited'] = data?.['connected_user_favorited']

    return new LearnPlaylist(json);
  }

  copyWith({
    id,
    title,
    themes,
    status,
    trainings,
    coverUrls,
    creatorId,
    updatedAt,
    deletedAt,
    description,
    contentType,
    learnModules,
    learnModuleIds,
    learnModulesCount,
    allModulesDuration,
    connectedUserFavorited,
  }) {
    return new LearnPlaylist({
      id: id ?? this.id,
      title: title ?? this.title,
      themes: themes ?? this.themes,
      status: status ?? this.status,
      trainings: trainings ?? this.trainings,
      coverUrls: coverUrls ?? this.coverUrls,
      creatorId: creatorId ?? this.creatorId,
      updatedAt: updatedAt ?? this.updatedAt,
      deletedAt: deletedAt ?? this.deletedAt,
      description: description ?? this.description,
      contentType: contentType ?? this.contentType,
      learnModules: learnModules ?? this.learnModules,
      learnModuleIds: learnModuleIds ?? this.learnModuleIds,
      learnModulesCount: learnModulesCount ?? this.learnModulesCount,
      allModulesDuration: allModulesDuration ?? this.allModulesDuration,
      connectedUserFavorited: connectedUserFavorited ?? this.connectedUserFavorited,
    });
  }
}

export class LearnPlaylistPagination {
  constructor({ learnPlaylists, meta }) {
    this.learnPlaylists = learnPlaylists;
    this.meta = meta;
  }

  static fromJson(data) {
    const json = {};

    json['learnPlaylists'] = data?.['learn_playlists']?.map(LearnPlaylist.fromJson) ?? [];
    json['meta'] = MetaPagination.fromJson(data?.['meta']);

    return new LearnPlaylistPagination(json);
  }

  copyWith({ learnPlaylists, meta }) {
    return new LearnPlaylistPagination({
      learnPlaylists: learnPlaylists ?? this.learnPlaylists,
      meta: meta ?? this.meta,
    });
  }
}
