<template>
  <div v-if="loading" class="w-full h-screen flex flex-col justify-center items-center">
    <svn-pro-progress-circular
      :size="48"
      :width="5"
      indeterminate
    />
  </div>

  <div v-else class="w-full flex flex-col">
    <trash-warnning
      v-if="isDeleted"
      title="This playlist is in trash."
      @restore="restoreDeletedItems(learnPlaylist?.id)"
      @delete-permanently="deleteTargetRef.dialogRef.dialog = true"
    />

    <div class="flex flex-col w-full bg-surface lg:!max-w-[560px] max-w-[560px] 2xl:py-10 py-5 px-5 md:!max-w-[452px] mx-auto h-full gap-6">
      <div class="flex" :class="isMobile ? 'flex-col-reverse justify-end gap-2' : 'justify-center gap-6 items-center'">
        <svn-pro-title class="w-full" h5 medium color="onSurface">
          {{ learnPlaylist?.title }}
        </svn-pro-title>

        <div class="flex gap-4 justify-end md:justify-center">
          <svn-pro-icon-button
            variant="text"
            :disabled="isDeleted"
            :color="learnPlaylist?.connected_user_favorited ? 'primary' : 'onSurfaceVariant'"
            :icon="learnPlaylist?.connected_user_favorited ? 'custom:mingcute:heart-fill' : 'custom:mingcute:heart-line'"
            @click="favoritePlaylist"
          />

          <svn-pro-icon-button
            variant="text"
            color="onSurfaceVariant"
            icon="custom:mingcute:link-line"
            :disabled="isDeleted"
            @click="copyPlaylistLink"
          />

          <svn-pro-menu v-if="amICreatorOfEntityOrAdmin(learnPlaylist?.creator_id)">
            <template #activator="{ props }">
              <svn-pro-icon-button
                v-bind="props"
                variant="text"
                color="onSurfaceVariant"
                :disabled="isDeleted"
                icon="custom:mingcute:more-2-fill"
              />
            </template>

            <template #dropdown>
              <v-list-item
                @click="editPlaylist(route?.params?.id)"
                :active="false"
                value="edit_playlist"
              >
                <svn-pro-text body-large regular>
                  {{ $t('Edit playlist') }}
                </svn-pro-text>
              </v-list-item>

              <v-list-item
                @click="openDeletePlaylist(route?.params?.id)"
                :active="false"
                value="delete_playlist"
              >
                <svn-pro-text color="error" body-large regular>
                  {{ $t('Delete playlist') }}
                </svn-pro-text>
              </v-list-item>
            </template>
          </svn-pro-menu>
        </div>
      </div>

      <div class="flex flex-col gap-4 justify-center">
        <div class="flex ga-2">
          <div class="flex gap-1 items-center">
            <v-icon
              icon="custom:mingcute:time-line"
              color="onSurfaceVariant"
              size="16"
            />
            <svn-pro-text body-medium regular color="onSurfaceVariant">
              {{ learnPlaylist.all_modules_duration || 'No Duration' }}
            </svn-pro-text>
          </div>
        </div>
        <svn-pro-text subtitle-medium regular color="onSurfaceVariant">
          {{ learnPlaylist?.description  }}
        </svn-pro-text>

        <div class="flex gap-2 flex-wrap">
          <svn-pro-info-tag
            v-if="learnPlaylist?.themes?.length"
            v-for="(theme) in learnPlaylist?.themes"
            tag-style="outlined"
            tagType="info"
            :text="theme"
            class="w-fit h-fit"
            tag-size="compact"
          />

          <svn-pro-info-tag
            v-else
            tag-style="outlined"
            tagType="info"
            text="No theme"
            class="w-fit h-fit"
            tag-size="compact"
          />
        </div>
      </div>

      <svn-pro-title class="w-full" h6 medium color="onSurface">
        Modules
      </svn-pro-title>

      <div>
        <transition-group v-if="amICreatorOfEntityOrAdmin(learnPlaylist?.creator_id) && learnPlaylist?.learn_modules?.length">
          <draggable
            key="draggable"
            class="w-full flex flex-col self-stretch gap-4"
            :list="learnPlaylist?.learn_modules"
            v-bind="dragOptions"
            item-key="item"
            :delay="50"
            :delay-on-touch-only="true"
            @start="drag = true"
            :disabled="isDeleted"
            @end="drag = false"
            @change="getListAfterDrag($event)"
          >
            <template #item="{ element }">
              <div
                :key="element?.id"
                class="flex justify-center items-center gap-5"
              >
                <!-- Drag Icon -->
                <svn-pro-icon-button
                  variant="text"
                  color="onSurfaceVariant"
                  icon="custom:mingcute:dots-line"
                  :disabled="isDeleted"
                />

                <svn-pro-card-learn
                  :key="element?.id"
                  :card-size="isMdScreen ? 'default' : 'compact'"
                  card-type="module_in_playlist"
                  :card-image="element.cover_url['500']"
                  :cardTitle="element.title"
                  :duration="element.duration"
                  :isDisabled="isDeleted"
                  :isAcquired="element?.submission_status == 'acquired'"
                  :themes="element.themes.map(theme => theme.name)"
                  @go-to-item-show="isDeleted ? null : goToModule(element?.id)"
                  :delete-text="'Remove module from playlist'"
                  @on-edit="goToModuleEdit(element?.id)"
                  @on-delete="openRemoveModuleFromPlaylistDialog(element)"
                />
              </div>
            </template>
          </draggable>
        </transition-group>

        <div v-if="!amICreatorOfEntityOrAdmin(learnPlaylist?.creator_id) && learnPlaylist?.learn_modules?.length" class="flex flex-col gap-4">
          <svn-pro-card-learn
            v-for="element in learnPlaylist?.learn_modules"
            :card-size="isMdScreen ? 'default' : 'compact'"
            :key="element?.id"
            :state="element.status"
            card-type="module_in_playlist"
            :card-image="element.cover_url['500']"
            :cardTitle="element.title"
            :duration="element.duration"
            :isDisabled="isDeleted"
            :isAcquired="element?.submission?.status == 'acquired'"
            :themes="element.themes.map(theme => theme.name)"
            @go-to-item-show="isDeleted ? null : goToModule(element?.id)"
            />
        </div>

        <div v-if="!learnPlaylist?.learn_modules?.length && amICreatorOfEntityOrAdmin(learnPlaylist?.creator_id)">
          <svn-pro-empty-states
            :variant="'index'"
            :title="$t('Nothing to show for now')"
            :size="isMdScreen ? 'default' : 'compact'"
            supporting-text="This playlist is empty."
          />
        </div>

        <!-- Add modules -->
        <modal-add-content-to-training
          v-if="amICreatorOfEntityOrAdmin(learnPlaylist?.creator_id)"
          content-type="module"
          :training-themes="learnThemes"
          :contents="learnModules"
          :loading="searchLoading"
          :selected-modules-to-add="learnModules.filter(module => selectedModules.includes(module.id))"
          :with-validate-button="true"
          @toggle-content-in-training="toggleLearnModuleSelection"
          @update-list="addModulesToPlaylist"
          @toggle-search="toggleSearch"
          @load-more-data="loadMoreData"
        >
          <template #activator="{ props }">
            <svn-pro-button
              v-bind="props"
              prepend-icon="custom:mingcute:add-line"
              :text="$t('Add modules')"
              class="w-full md:!w-fit mt-4"
              variant="tonal"
              :disabled="isDeleted"
            />
          </template>
        </modal-add-content-to-training>
      </div>
    </div>
  </div>

  <!-- Dialog edit Playlist -->
  <dialog-edit-playlist
    ref="editPlaylistDialog"
    :title="learnPlaylist?.title"
    :description="learnPlaylist?.description"
    @update-playlist="updateLearnPlaylist"
  />

  <!-- Scroll to top button -->
  <svn-floating-action-button
    to-top
  />

  <svn-pro-floating-action-button
    to-top
    size="small"
    color="primary"
    variant="tonal"
    :rounded="'lg'"
    class="fixed bottom-4 right-4 bg-white"
    icon="custom:mingcute:arrow-to-up-line"
  />

  <!-- Dialog edit Playlist -->
  <dialog-delete-content
    v-if="trainings.length"
    ref="deletePlaylistDialog"
    :items="trainings"
    :title="$t(`Playlist will be deleted with their trainings`)"
    :description="$t(`If this playlist is the only content of a training, the training will be deleted. Training(s) containing only this playlist :`)"
    @delete-content="deleteLearnPlaylist"
  />

  <svn-pro-dialog-validation
    v-else
    ref="deletePlaylistDialog"
    width="312"
    icon="noto:warning"
    :action-two-title="$t('Cancel')"
    :action-one-title="$t('Delete')"
    :title="$t(`Playlist will be deleted`)"
    :content-text="$t('Deleted playlists are stored for 30 days. After this period, they will be permanently deleted.')"
    @click-primary-button="deleteLearnPlaylist"
  >
    <template #activator="{ props }">
      <div class="hidden"/>
    </template>
  </svn-pro-dialog-validation>

  <svn-pro-dialog-validation
    ref="removeModuleFromPlaylistDialog"
    width="312"
    icon="noto:warning"
    :action-two-title="$t('No, cancel')"
    :action-one-title="$t('Yes, remove')"
    :title="$t(`Remove module from playlist`)"
    :content-text="$t('You will still be able to add it later using the &quot;Add modules button&quot;.')"
    @click-primary-button="removeModule"
  >
    <template #activator="{ props }">
      <div class="hidden"/>
    </template>
  </svn-pro-dialog-validation>

  <svn-pro-dialog-validation
    ref="deleteTargetRef"
    icon="noto:warning"
    :action-two-title="$t('Cancel')"
    :action-one-title="$t('Delete')"
    :title="$t(`Playlist will be deleted`)"
    :content-text="$t('This is a permanent action.')"
    @click-primary-button="deletePermanentlyDeletedItems()"
    @click-secondary-button="deleteTargetRef.dialogRef.dialog  = false"
  >
    <template #activator="{ props }">
      <div class="hidden"/>
    </template>
  </svn-pro-dialog-validation>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import draggable from 'vuedraggable';
import DialogEditPlaylist from '@/components/BktPopUp/Dialogs/learn/DialogEditPlaylist.vue';
import DialogDeleteContent from '@/components/BktPopUp/Dialogs/learn/DialogDeleteContent.vue';
import { useUserStore } from "@/store/user.js";
import { useLearnThemesStore } from '@/store/learn-themes';
import { useLearnPlaylistStore } from '@/store/learn-playlist';
import { useLearnModuleStore } from '@/store/learn-module';
import { useMobileStore } from '@/store/mobile';
import { storeToRefs } from 'pinia';
import { useRoute } from 'vue-router';
import { useRouter } from 'vue-router';
import { useSnackbar } from '@/store/snackbar';
import { debounce } from 'lodash';
import TrashWarnning from "@/components/TrashWarnning.vue"
import ModalAddContentToTraining from "@/components/BktPopUp/Modals/learn/ModalAddContentToTraining.vue";

const route = useRoute();
const router = useRouter();
const snackbar = useSnackbar();
const { amICreatorOfEntityOrAdmin } = useUserStore();

const { fetchThemes } = useLearnThemesStore();
const { learnThemes } = storeToRefs(useLearnThemesStore());
const { learnModules } = storeToRefs(useLearnModuleStore());
const { learnPlaylist } = storeToRefs(useLearnPlaylistStore());
const { isMobile, isMdScreen } = storeToRefs(useMobileStore());
const { fetchModules, fetchModulesOnlyWithPages } = useLearnModuleStore();
const { fetchPlaylist, toggleFavorite, removeModuleFromPlaylist, updatePlaylist, deletePlaylist, updateModuleIds, restorePlaylist, deletePlaylistPermanently, updatePlaylistInfos } = useLearnPlaylistStore();

const drag = ref(false);
const modulePage = ref(1);
const trainings = ref([]);
const loading = ref(false);
const isDeleted = ref(false);
const selectedModules = ref([]);
const searchLoading = ref(false);
const moduleToRemove = ref(null);
const deleteTargetRef = ref(null);
const totalModulePages = ref(null);
const editPlaylistDialog = ref(false);
const deletePlaylistDialog = ref(false);
const removeModuleFromPlaylistDialog = ref(false);

onMounted(async() => {
  loading.value = true

  try {
    await fetchPlaylist(route?.params?.id)
    selectedModules.value = learnPlaylist.value.learn_module_ids
    trainings.value = learnPlaylist.value.trainings
  } catch (error) {
    snackbar.setBgColor('onSurface').setCustomClass(isMdScreen.value ? 'mb-4' : 'mb-[72px]').displaySnackBar('Error fetching playlist')
  }

  loading.value = false

  try {
    const res = await fetchModules()
    totalModulePages.value = res.data.meta.pagination.total_pages
  } catch (error) {
    snackbar.setBgColor('onSurface').setCustomClass(isMdScreen.value ? 'mb-4' : 'mb-[72px]').displaySnackBar('Error fetching modules')
  }

  try {
    await fetchThemes()
  } catch (error) {
    snackbar.setBgColor('onSurface').setCustomClass(isMdScreen.value ? 'mb-4' : 'mb-[72px]').displaySnackBar('Error fetching themes')
  }

  isDeleted.value = learnPlaylist.value.status === "trash"
})

const dragOptions = ref({
  animation: 200,
  group: 'description',
  disabled: false,
  ghostClass: 'ghost',
});


const favoritePlaylist = debounce(async() => {
  try {
    await toggleFavorite(route?.params?.id)
    snackbar.setBgColor('onSurface')
    snackbar.setCustomClass(isMobile.value ? 'mb-[72px]' : 'mb-10')
    snackbar.displaySnackBar(learnPlaylist?.value?.connected_user_favorited ?
    'Playlist added to favorites.' : 'Playlist removed from favorites.')
  } catch (error) {
    snackbar.setBgColor('error')
    snackbar.setCustomClass(isMobile.value ? 'mb-[72px]' : 'mb-10')
    snackbar.displaySnackBar('Error toggling favorite')
  }
}, 200)

const copyPlaylistLink = debounce(() => {
  const link = router.resolve({  name: 'playlist_show', params: { id: route.params.id } })

  navigator.clipboard.writeText(new URL(link.href, window.location.origin).href).then(() => {
    snackbar.setBgColor('onSurface')
    snackbar.setCustomClass(isMobile.value ? 'mb-[72px]' : 'mb-10')
    snackbar.displaySnackBar('Link copied to clipboard.')
  }).catch(() => {
    snackbar.setBgColor('error')
    snackbar.setCustomClass(isMobile.value ? 'mb-[72px]' : 'mb-10')
    snackbar.displaySnackBar('Error copying link to clipboard')
  });
}, 200)

const editPlaylist = () => {
  editPlaylistDialog.value.dialogEditPlaylist = true
}

const goToModuleEdit = (id) => {
  router.push({ name: "module_edit", params: { id: id } })
}

const goToModule = (id) => {
  router.push({ name: "module_show", params: { id: id }, query: { playlistId: learnPlaylist.value.id }})
}

const updateLearnPlaylist = async(title, description) => {
  try {
    await updatePlaylist(route?.params?.id, title, description)

    snackbar.setBgColor('onSurface')
    snackbar.setMsg('Successfully changed the playlist title & description')
    snackbar.setCustomClass(isMobile.value ? 'mb-[72px]' : 'mb-10')
    snackbar.displaySnackBar()
  } catch (error) {
    snackbar.setBgColor('error')
    snackbar.setMsg('Error updating the playlist')
    snackbar.setCustomClass(isMobile.value ? 'mb-[72px]' : 'mb-10')
    snackbar.displaySnackBar()
  }

  editPlaylistDialog.value.dialogEditPlaylist = false
}

const openDeletePlaylist = () => {
  if (trainings.value.length) {
    deletePlaylistDialog.value.deleteDialog = true
  } else {
    deletePlaylistDialog.value.dialogRef.dialog = true
  }
}

const openRemoveModuleFromPlaylistDialog = (module) => {
  moduleToRemove.value = module
  removeModuleFromPlaylistDialog.value.dialogRef.dialog = true
}

const deleteLearnPlaylist = async() => {
  try {
    await deletePlaylist(route?.params?.id)

    if (trainings.value.length) {
      deletePlaylistDialog.value.deleteDialog = false
    } else {
      deletePlaylistDialog.value.dialogRef.dialog = false
    }

    snackbar.setBgColor('onSurface')
    snackbar.setMsg('Playlist has been deleted successfully.')
    snackbar.setCustomClass(isMobile.value ? 'mb-[72px]' : 'mb-10')
    snackbar.displaySnackBar()

    router.push({ name: "catalog" })
  } catch (error) {
    console.log(error)
    snackbar.setBgColor('error')
    snackbar.setMsg('Error deleting playlist')
    snackbar.setCustomClass(isMobile.value ? 'mb-[72px]' : 'mb-10')
    snackbar.displaySnackBar()
  }
}

const removeModule = async() => {
  try {
    await removeModuleFromPlaylist(learnPlaylist?.value?.id, moduleToRemove?.value?.id)
    selectedModules.value = learnPlaylist?.value.learn_module_ids
    snackbar.setBgColor('onSurface')
    snackbar.setMsg('Module Removed successfully')
    snackbar.setCustomClass(isMobile.value ? 'mb-[72px]' : 'mb-10')
    snackbar.displaySnackBar()
  } catch (error) {
    snackbar.setBgColor('error')
    snackbar.setMsg('Error removing module from playlist')
    snackbar.setCustomClass(isMobile.value ? 'mb-[72px]' : 'mb-10')
    snackbar.displaySnackBar()
  }
}

const toggleLearnModuleSelection = async(module) => {
  try {
    const found = selectedModules.value.find(el => el === module?.id)

    if (found) {
      selectedModules.value.splice(selectedModules.value.findIndex(el => el === module?.id), 1)
    }
    else {
      selectedModules.value.push(module.id)
    }
  } catch (error) {
    snackbar.setBgColor('error')
    snackbar.setMsg('Error toggling module')
    snackbar.setCustomClass(isMobile.value ? 'mb-[72px]' : 'mb-10')
    snackbar.displaySnackBar()
  }
}

const getListAfterDrag = async() => {
  if (amICreatorOfEntityOrAdmin(learnPlaylist.value.creator_id)) {
    const modulesIds = learnPlaylist.value?.learn_modules.map(module => module.id)
    await updateModuleIds(learnPlaylist.value?.id, modulesIds)
  }
}

const restoreDeletedItems = async (id) => {
  try {
    await restorePlaylist(id)
    snackbar.setBgColor('onSurface')
    snackbar.setMsg('Playlist has been restored successfully.')
    snackbar.setCustomClass(isMobile.value ? 'mb-[96px]' : 'mb-[42px]')
    snackbar.displaySnackBar()
    isDeleted.value = false
  } catch (e) {
    snackbar.setBgColor('error')
    snackbar.setMsg('Error while restoring a playlist')
    snackbar.setCustomClass(isMobile.value ? 'mb-[96px]' : 'mb-[42px]')
    snackbar.displaySnackBar()
  }
}

const deletePermanentlyDeletedItems = async (i) => {
  try {
    await deletePlaylistPermanently(learnPlaylist.value.id)
    snackbar.setBgColor('onSurface')
    snackbar.setMsg('Playlist has been deleted successfully.')
    snackbar.setCustomClass(isMobile.value ? 'mb-[96px]' : 'mb-[42px]')
    snackbar.displaySnackBar()

    await router.push({ name: "catalog" });

  } catch (e) {
    snackbar.setBgColor('error')
    snackbar.setMsg('Error while deleting permanently a playlist')
    snackbar.setCustomClass(isMobile.value ? 'mb-[96px]' : 'mb-[42px]')
    snackbar.displaySnackBar()
  }
}

const toggleSearch = async(text, contentName, theme_ids) => {
  try {
    modulePage.value = 1
    await fetchModules(text, theme_ids)
  } catch (error) {
    snackbar.setBgColor('error')
    snackbar.setCustomClass(isMobile.value ? 'mb-[85px]' : 'mb-0')
    snackbar.setMsg('Error fetching modules')
    snackbar.displaySnackBar()
  }
}

const addModulesToPlaylist = async() => {
  try {
    await updatePlaylistInfos(route?.params?.id, selectedModules.value)

    snackbar.setBgColor('onSurface')
    snackbar.setCustomClass(isMobile.value ? 'mb-[85px]' : 'mb-0')
    snackbar.setMsg((selectedModules?.value?.length === 0 || selectedModules?.value?.length === 1) ?
      `Module successfully added to playlist.` :
      `Modules successfully added to playlist.`)
    snackbar.displaySnackBar()
  } catch (error) {
    console.log(error)
    snackbar.setBgColor('error')
    snackbar.setCustomClass(isMobile.value ? 'mb-[85px]' : 'mb-0')
    snackbar.setMsg('Error adding module(s) to playlist')
    snackbar.displaySnackBar()
  }
}

const loadMoreData = async({ done }) => {
  if (modulePage.value < totalModulePages.value) {
    try {
      const res = await fetchModulesOnlyWithPages(modulePage.value + 1)
        if (res.data.learn_modules.length) {
          learnModules.value = [...learnModules.value, ...res.data.learn_modules]
        }
        done('ok')
        modulePage.value++
    } catch (error) {
      done('error')
    }
  }
  else {
    done('empty')
  }
}
</script>