<template>
  <div :class="containerClass" class="rounded-[8px] overflow-hidden bg-surfaceVariant">
    <!-- :lazy-src="imageLazySrc" -->
    <v-img
      :src="imageSrc"
      aspect-ratio="1/1"
      :cover="cover"
      class="w-full h-full"
    >
      <template #placeholder>
        <div class="d-flex align-center justify-center fill-height">
          <svn-loader loading-size="lg" />
        </div>
      </template>

      <template #error>
        <div class="w-full h-full flex flex-col justify-center items-center">
          <p class="sm medium text-center text-onSurfaceVariant">
            {{ $t('Link expired') }}
          </p>
        </div>
      </template>

      <template #default>
        <div v-if="!imageSrc" class="absolute top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%]">
          <Icon
            icon="mingcute:pic-line"
            height="88"
            width="88"
            class="text-[#46464F]/[0.36]"
          />
        </div>

        <div class="w-full flex justify-end items-end bg-transparent relative">
          <svn-pro-dialog-upload-image @file-uploaded="uploadImage">
            <template #activator="{ isActive }">
              <svn-pro-icon-button
                :icon="isActive ? 'custom:mingcute:edit-2-fill' : 'custom:mingcute:edit-2-line'"
                color="surface"
                class="mt-4 mr-4"
              />
            </template>
          </svn-pro-dialog-upload-image>
        </div>
      </template>
    </v-img>
  </div>
</template>

<script setup>
import { ref } from "vue"
import { Icon } from '@iconify/vue';
import { useSnackbar } from '@/store/snackbar';

const props = defineProps({
  containerClass: { type: String, default: "" },
  imageLazySrc: { type: String, default: "" },
  imageSrc: { type: String, default: "" },
  cover: {type: Boolean, default: true }
});

const emit = defineEmits(['file-upload']);

const snackbar = useSnackbar()

const opened = ref(false);

const uploadImage = async (imageLink) => {
  try {
    const tmpFile = await loadXHR(imageLink)
    emit('file-upload', tmpFile)
  } catch (error) {
    snackbar.setBgColor('onSurface').setMsg('Error updating image.').setCustomClass('mb-5').displaySnackBar()
  }
  opened.value = false
}

const loadXHR = (url) => {
  return new Promise(function (resolve, reject) {
    try {
      const xhr = new XMLHttpRequest();
      xhr.open("GET", url);
      xhr.responseType = "blob";
      xhr.onerror = function () {
        reject("Network error.")
      };
      xhr.onload = function () {
        if (xhr.status === 200) {
          const file = new File([xhr.response], `tmp-${new Date()}`);
          resolve(file)
        } else {
          reject("Loading error:" + xhr.statusText)
        }
      };
      xhr.send();
    } catch (err) {
      reject(err.message)
    }
  });
}
</script>