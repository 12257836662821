<template>
  <div class="w-full flex flex-col gap-6">
    <!-- Content -->
    <div class="flex flex-col gap-4">
      <div class="flex flex-col gap-4">
        <svn-pro-text subtitle-large medium>
          {{ $t('Choose participants') }}
        </svn-pro-text>

        <div class="w-full flex flex-col">
          <v-tabs
            v-model="campaignDraft.interviewee_selection_method"
            color="primary"
          >
            <v-tab value="manual" :text="$t('Manual selection')" />

            <v-tab value="by_category" :text="$t('By category')" />
          </v-tabs>

          <v-window v-model="campaignDraft.interviewee_selection_method">
            <v-window-item value="manual">
              <participant-selection-manual />
            </v-window-item>

            <v-window-item value="by_category">
              <participant-selection-by-tag />
            </v-window-item>
          </v-window>
        </div>
      </div>

      <svn-pro-divider color="[#767680]" />

      <div class="w-full flex flex-col gap-4">
        <!-- Person in charge text -->
        <div class="flex flex-col gap-2">
          <svn-pro-text subtitle-large medium>
            {{ getIntroTextAccordingToCampaignType }}
          </svn-pro-text>

          <svn-pro-text
            v-if="campaignDraft?.campaign_type === CampaignTypeEnum?.SURVEY"
            subtitle-medium
            regular
            color="onSurfaceVariant"
          >
            {{ $t('Answers will be visible in the campaign in the section ‘Answers’') }}
          </svn-pro-text>
        </div>

        <!-- Person in charge for Survey Input -->
        <div v-if="campaignDraft?.campaign_type === CampaignTypeEnum?.SURVEY" class="flex gap-4">
          <svn-pro-autocomplete
            v-model="campaignDraft.interviewer_ids"
            :items="managers"
            :error="personInChargeError"
            item-value="id"
            item-title="fullname"
            multiple
            class="md:!w-1/2"
            :label="$t('Person in charge*')"
          />

          <div class="hidden md:!flex md:!w-1/2" />
        </div>

        <!-- Manager or Specific person -->
        <v-item-group
          v-else
          v-model="campaignDraft.interviewer_selection_method"
          mandatory
        >
          <div class="flex flex-col md:!flex-row gap-4">
            <svn-pro-extended-radio-button
              v-for="personInChargeType in personInChargeTypes"
              :key="personInChargeType?.id"
              :value="personInChargeType?.id"
              :icon="personInChargeType?.icon"
              :title="personInChargeType?.title"
              :subtitle="personInChargeType?.subtitle"
            />
          </div>
        </v-item-group>

        <!-- Person in charge for One to one & Feedback Input -->
        <div
          v-if="campaignDraft?.campaign_type !== CampaignTypeEnum?.SURVEY"
          class="flex flex-col gap-4"
        >
          <div class="flex gap-2 items-center">
            <div>
              <Icon
                icon="mingcute:information-line"
                height="16"
                width="16"
                class="text-borderColor"
              />
            </div>

            <svn-pro-text subtitle-large medium>
              {{ campaignDraft.interviewer_selection_method === 'manager' ? $t('Choose a default person in charge') : $t('Choose a specific person') }}
            </svn-pro-text>
          </div>

          <svn-pro-autocomplete
            v-model="campaignDraft.default_interviewer_id"
            :items="managers"
            :error="managerError"
            item-value="id"
            item-title="fullname"
            :label="$t('Select a manager*')"
            :loading="loadingManagerList"
            menu-icon="custom:mingcute:down-small-fill"
          />
        </div>
      </div>
    </div>

    <!-- Previous & Next button button -->
    <div class="w-full flex justify-end items-center gap-2">
      <svn-pro-button
        variant="outlined"
        :text="$t('Previous')"
        @click="previous"
      />

      <svn-pro-button
        variant="flat"
        :text="$t('Next')"
        :loading="loading"
        prepend-icon="custom:mingcute:arrow-right-line"
        @click="next"
      />
    </div>
  </div>
</template>

<script setup>
import { debounce } from "lodash";
import { useI18n } from "vue-i18n";
import { storeToRefs } from "pinia";
import { Icon } from "@iconify/vue";
import { onMounted, ref, watch, computed } from "vue";
import { useSnackbar } from "@/store/snackbar.js";
import { useCompanyStore } from "@/store/company.js";
import CampaignTypeEnum from "@/constants/CampaignTypeEnum";
import { useCampaignDraftStore } from "@/store/campaign-draft.js";
import ParticipantSelectionByTag from "./ParticipantSelectionByTag.vue";
import ParticipantSelectionManual from "./ParticipantSelectionManual.vue";

const emit = defineEmits(["next"]);

const snackbar = useSnackbar();
const { fetchCompanyManagers } = useCompanyStore();
const { updateCampaignDraft } = useCampaignDraftStore();

const { managers } = storeToRefs(useCompanyStore());
const { campaignDraft } = storeToRefs(useCampaignDraftStore());

onMounted( async () => {
  if (!managers.value.length) {
    fetchCompanyManagers().then(() => {
      loadingManagerList.value = false;
    });
  } else {
    loadingManagerList.value = false;
  }
});

const loading = ref(false);
const managerError = ref(false);
const personInChargeError = ref(false);
const loadingManagerList = ref(true);
const personInChargeTypes = ref([
  {
    id: 'manager',
    value: 'manager',
    icon: 'noto:necktie',
    title: 'Direct manager',
    subtitle: 'Managers from database are in charge of the interview.',
  },
  {
    id: 'specific_manager',
    value: 'specific_manager',
    icon: 'noto:index-pointing-at-the-viewer',
    title: 'Specific person',
    subtitle: 'Designate any user that will be in charge of the campaign.',
  },
])
const isNextDisabled = computed(() => {
  let hasInterviewerIds = false

  if (campaignDraft.value.campaign_type === 'Survey') { // only for survey
    hasInterviewerIds = campaignDraft.value.interviewer_ids && campaignDraft.value.interviewer_ids.length <= 0
  } else { // for one to one and feedback
    hasInterviewerIds = !campaignDraft.value.default_interviewer_id
  }
  return !campaignDraft.value.interviewer_selection_method || hasInterviewerIds
});
const getIntroTextAccordingToCampaignType = computed(() => {
  switch (campaignDraft?.value?.campaign_type) {
    case 'OneToOne':
      return 'Choose who will be in charge of the One to one'
    case 'Feedback':
      return 'Choose who will be in charge of the Feedback 360'
    case 'Survey':
      return 'Choose who will be in charge of the Survey'
    default:
      return ''
  }
})

const previous = debounce(() => {
  emit("previous");
});

const next = debounce(async () => {
  if (campaignDraft?.value?.campaign_type === 'Survey') {
    if (!campaignDraft?.value?.interviewer_ids?.length) {
      personInChargeError.value = true
      snackbar.setBgColor("onSurface").setCustomClass('mb-4').setMsg("Please fill all required fields (*).").displaySnackBar();
      return
    }
    if (!personInChargeError?.value) {
      try {
        await updateCampaignDraft({
          interviewee_selection_method: campaignDraft?.value?.interviewee_selection_method,
          interviewee_ids: campaignDraft?.value?.interviewee_selection_method === 'manual' ? campaignDraft?.value?.interviewee_ids : [],
          interviewer_ids: campaignDraft?.value?.interviewer_ids,
          participant_filter_tag_ids: campaignDraft?.value?.interviewee_selection_method === 'by_category' ? campaignDraft?.value?.participant_filter_tag_ids : [],
          interviewer_selection_method: campaignDraft?.value?.interviewer_selection_method,
          default_interviewer_id: campaignDraft?.value?.default_interviewer_id,
        });
        emit("next");
      } catch (error) {
        snackbar.setBgColor("onSurface").setCustomClass('mb-4').setMsg("An error occured, please try again").displaySnackBar()
      }
    }
  } else {
    if (!campaignDraft?.value?.default_interviewer_id) {
      managerError.value = true
      snackbar.setBgColor("onSurface").setCustomClass('mb-4').setMsg("Please fill all required fields (*).").displaySnackBar();
      return
    }
    if (!managerError?.value) {
      try {
        await updateCampaignDraft({
          interviewee_selection_method: campaignDraft?.value?.interviewee_selection_method,
          interviewee_ids: campaignDraft?.value?.interviewee_selection_method === 'manual' ? campaignDraft?.value?.interviewee_ids : [],
          interviewer_ids: campaignDraft?.value?.interviewer_ids,
          participant_filter_tag_ids: campaignDraft?.value?.interviewee_selection_method === 'by_category' ? campaignDraft?.value?.participant_filter_tag_ids : [],
          interviewer_selection_method: campaignDraft?.value?.interviewer_selection_method,
          default_interviewer_id: campaignDraft?.value?.default_interviewer_id,
        });
        emit("next");
      } catch (error) {
        snackbar.setBgColor("onSurface").setCustomClass('mb-4').setMsg("An error occured, please try again").displaySnackBar()
      }
    }
  }
}, 300);

watch(campaignDraft, (newValue, oldValue) => {
  if (newValue?.campaign_type === CampaignTypeEnum?.SURVEY) {
    if (personInChargeError?.value && newValue?.interviewer_ids?.length) {
      personInChargeError.value = false
    }
  }
  if (campaignDraft?.value?.campaign_type !== CampaignTypeEnum?.SURVEY) {
    if (managerError?.value && newValue?.default_interviewer_id) {
      managerError.value = false
    }
  }
}, { deep: true })
</script>