<template>
  <v-dialog
    v-model="dialog"
    width="auto"
  >
    <template #activator="{ props, isActive }">
      <slot :props="props" name="activator" :isActive="isActive">
        <svn-pro-button text="Activate" />
      </slot>
    </template>

    <template #default>
      <svn-pro-time-picker
        :width="'100%'"
        actions
        :border="0"
        format="24hr"
        :elevation="0"
        :model-value="time"
        @update:model-value="uptimeTime"
      >
        <template #actions>
          <svn-pro-button
            variant="text"
            :text="$t('Cancel')"
            @click="dialog = false"
          />

          <svn-pro-button
            variant="text"
            text="Ok"
            @click="save"
          />
        </template>
      </svn-pro-time-picker>
    </template>
  </v-dialog>
</template>

<script setup>
import { ref, watch, onMounted } from 'vue';

const props = defineProps({
  timeValue: { type: String, default: undefined },
})

const emit = defineEmits(['save'])

onMounted(() => {
  time.value = props.timeValue
})

const time = ref(null);
const dialog = ref(false);

const save = () => {
  emit('save', time.value)
  dialog.value = false
}

const uptimeTime = (value) => {
  time.value = value
}

watch(props, (newValue, oldValue) => {
  if (newValue.timeValue) {
    time.value = newValue.timeValue
  }
})

defineExpose({
  dialog,
})
</script>