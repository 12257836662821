// Js
import {createApp} from "vue";
import App from "@/App.vue";
import {registerPlugins} from "@/plugins/index.js";

const app = createApp(App);

// plugins
registerPlugins(app)

app.mount("#app");