import { defineStore } from "pinia";
import { inject, provide, reactive } from "vue";

export const useSnackbar = defineStore("snackbar", {
  state: () => ({
    msg: "",
    bgColor: "onSurface",
    textColor: "onPrimary",
    closeColor: "onPrimary",
    closeLogo: "mdi-close",
    customClass: '',
    display: false,
    timeout: 3000,
    snackbar: null,
    retry: null,
    vertical: false,
    multiLine: false,
    closeButton: false,
    action: null,
  }),
  actions: {
    init() {
      provide(
        "snackbar",
        reactive({
          status: null,
          message: null,
        })
      );

      this.snackbar = inject(
        "snackbar",
        reactive({
          status: null,
          message: null,
        })
      );

      return this;
    },
    /**
     * Check if it contains value to display
     *
     * status options :
     *  - created : default color success
     *  - updated : default color success
     *  - deleted : default color success
     *  - error : default color failed
     *  - none : default color neutral
     * @returns this
     */
    setStatus(status = null, message = null) {
      this.snackbar.status = status;
      this.snackbar.message = message;

      return this;
    },
    /**
     * check if it need to display a snackbar
     *
     * @returns {actions}
     */
    setMsg(sentence) {
      this.msg = sentence;

      return this;
    },
    setBgColor(color) {
      this.bgColor = color;

      return this;
    },
    setVertical(vertical) {
      this.vertical = vertical;

      return this;
    },
    setMultiLine(multiLine) {
      this.multiLine = multiLine;

      return this;
    },
    setTextColor(color) {
      this.textColor = color;

      return this;
    },
    setCloseColor(color) {
      this.closeColor = color;

      return this;
    },
    setCustomClass(value) {
      this.customClass = value;

      return this;
    },
    setTimer(time) {
      this.timer = time;

      return this;
    },
    setCloseButton(closeButton) {
      this.closeButton = closeButton;

      return this;
    },
    setAction(name, callback) {
      this.action = {
        name,
        callback
      }

      return this;
    },
    /**
     * Set default color to snackbar
     *
     * default color available:
     *  - success :
     *    - background : green
     *    - text : white
     *    - close color : white
     *  - success :
     *    - background : red
     *    - text : white
     *    - close color : white
     *  - neutral :
     *    - background : lightgrey
     *    - text : black
     *    - close color : black
     *
     * @param color
     * @returns this
     */
    setDefaultColor(color) {
      switch (color) {
        case "success":
          this.setBgColor("success").setTextColor("onPrimary").setCloseColor("onPrimary");
          break;
        case 'updated':
          this.setBgColor('success')
            .setTextColor('onPrimary')
            .setCloseColor('white')
          break;
        case 'failed':
          this.setBgColor('error')
            .setTextColor('onPrimary')
            .setCloseColor('white')
          break;
        case "neutral":
          this.setBgColor("info")
            .setTextColor("onPrimary")
            .setCloseColor("black");
          break;
        case "warning":
          this.setBgColor("warning")
            .setTextColor("onPrimary")
            .setCloseColor("white");
          break;
        default:
          break;
      }
      return this;
    },
    displaySnackBar(message = this.message, timeout = this.timeout) {
      this.display = true;
      if (timeout) this.timeout = timeout;
      if (message) this.msg = message;

      return this;
    },
    closeSnackBar() {
      this.display = false;
      this.action = null;
      this.actionText = null;
      this.withCloseIcon = true;
    },
    checkStatus() {
      switch (this.snackbar.status) {
        case "created":
          this.setDefaultColor("success").displaySnackBar(
            this.snackbar.message
          );
          break;
        case "updated":
          this.setDefaultColor("success").displaySnackBar(
            this.snackbar.message
          );
          break;
        case "deleted":
          this.setDefaultColor("success").displaySnackBar(
            this.snackbar.message
          );
          break;
        case "warning":
          this.setDefaultColor("warning").displaySnackBar(
            this.snackbar.message
          );
          break;
        case "error":
          this.setDefaultColor("failed").displaySnackBar(this.snackbar.message);
          break;
        case "none":
          this.setDefaultColor("neutral").displaySnackBar(
            this.snackbar.message
          );
          break;
        default:
          break;
      }

      this.snackbar.status = null;
      this.snackbar.message = null;

      return this;
    },
  },
});
