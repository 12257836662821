import { defineStore } from "pinia";
import axiosService from "@/tools/axios-service.js";
import { genericFunctions, genericStates } from "@/tools/genericFunctions.js";
import {useCampaignStore} from "@/store/campaign.js";

export const useFeedbackStore = defineStore({
  id: "feedback",
  state: () => ({
    isFeedbackLoading: false,
    id: null,
    title: null,
    questions: null,
    participant: null,
    campaign: null,
    campaignId: null,
    date: null,
    employee: null,
    endsAt: null,
    interviewForm: null,
    starts_at: null,
    status: null,
    employeeAnswers: [],
    managerAnswers: [],
    ...genericStates,
  }),
  getters: {},
  actions: {
    ...genericFunctions,
    clearFeedback() {
      this.isFeedbackLoading = false;
      this.id = null;
      this.title = null;
      this.questions = null;
      this.options = null;
      this.participant = null;
      this.campaign = null;
      this.date = null;
      this.interviewForm = null;
      this.starts_at = null;
    },
    async fetchFeedback(id) {
      try {
        this.clearFeedback();
        this.isFeedbackLoading = true;
        const feedback = await this.fetchEntity(
          `/api/v2/interview_app/campaign_packs/${id}.json`,
          'campaign_pack'
        );
        this.id = feedback.id;
        this.title = feedback.title;
        this.questions = feedback.questions;
        this.options = feedback.options
        this.campaign = feedback.campaign;
        this.participant = feedback.participant;
        this.date = feedback.date;
        this.interviewForm = feedback.interview_form;
        this.starts_at = feedback.starts_at;
      } catch (e) {
        console.log("fail while fetching template", e);
      } finally {
        this.isFeedbackLoading = false;
      }
    },
    async deleteFeedback(id, campaignId) {
      try {
        const campaignStore = useCampaignStore();
        const {fetchCampaignFeedback} = campaignStore

        await axiosService.delete(`/api/v2/interview_app/campaign_packs/${id}`)
        await fetchCampaignFeedback(campaignId)
      } catch (e) {
        console.log("fail to delete feedback", e);
      }
    },
  },
});
