<template>
  <pop-up-skeleton
    v-bind="data"
    :title="$t('Access level')"
  >
    <template #body>
      <div class="w-full flex flex-col items-center bg-white relative">
        <div class="w-full">
          <v-tabs
            v-model="tab"
            class="w-full flex justify-center items-center mb-10 overflow-x-auto"
          >
            <div class="w-full flex gap-3 justify-center items-center">
              <v-tab
                v-for="value in tabs"
                :key="value.name"
                :value="value.name"
                class="!rounded-lg border-fakeBlack border"
                selected-class="bg-fakeBlack text-white"
              >
                {{ $t(value.name) }}
              </v-tab>
            </div>
          </v-tabs>

          <div>
            <v-window v-model="tab">
              <v-window-item
                v-for="value in tabs"
                :key="value.name"
                :value="value.name"
                class="overflow-y-auto"
                style="height: calc(100vh - 300px);"
              >
                <v-row
                  no-gutters
                  class="uppercase text-xs flex justify-center font-bold mb-4 min-w-[538px]"
                >
                  <v-col class="min-w-[140px]" />
                  <v-col
                    v-for="head in header"
                    :key="head.name"
                    cols="2"
                    class="min-w-[97.5px]"
                  >
                    <v-sheet class="text-center flex-col justify-center">
                      <Icon
                        :icon="head.icon"
                        height="50"
                        width="50"
                        class="m-auto mb-3"
                      />
                      <span>
                        {{ $t(head.name) }}
                      </span>
                    </v-sheet>
                  </v-col>
                </v-row>
                <v-row
                  v-for="(item, itemIndex) in value.values"
                  :key="item.name"
                  class="text-center min-w-[538px]"
                >
                  <v-col
                    class="text-uppercase text-darkGrey font-medium text-sm text-right flex justify-center items-center min-w-[140px]"
                    style="height: 57px"
                  >
                    <span>
                      {{ $t(item.name) }}
                    </span>
                  </v-col>
                  <v-col
                    v-for="(val, index) in item.values"
                    :key="item.name + '-' + index"
                    class="w-44 flex justify-center p-2 min-w-[97.5px]"
                    :class="itemIndex % 2 ? '' : 'bg-veryLightGrey'"
                    cols="2"
                  >
                    <div
                      v-if="val.value === 'yes'"
                      class=""
                    >
                      <svg
                        width="40"
                        height="40"
                        viewBox="0 0 40 40"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle
                          cx="20"
                          cy="20"
                          r="20"
                          fill="#7BC079"
                          fill-opacity="0.2"
                        />
                        <path
                          d="M17.6669 21.8663L24.5503 14.9829C24.7641 14.7691 25.0364 14.6621 25.3669 14.6621C25.6975 14.6621 25.9697 14.7691 26.1836 14.9829C26.3975 15.1968 26.5044 15.4691 26.5044 15.7996C26.5044 16.1302 26.3975 16.4024 26.1836 16.6163L18.4836 24.3163C18.2503 24.5496 17.978 24.6663 17.6669 24.6663C17.3558 24.6663 17.0836 24.5496 16.8503 24.3163L13.8169 21.2829C13.603 21.0691 13.4961 20.7968 13.4961 20.4663C13.4961 20.1357 13.603 19.8635 13.8169 19.6496C14.0308 19.4357 14.303 19.3288 14.6336 19.3288C14.9641 19.3288 15.2364 19.4357 15.4503 19.6496L17.6669 21.8663Z"
                          fill="#7BC079"
                        />
                      </svg>
                    </div>
                    <div
                      v-else-if="val.value === 'no'"
                      class="flex flex-column"
                    >
                      <svg
                        width="40"
                        height="40"
                        viewBox="0 0 40 40"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle
                          cx="20"
                          cy="20"
                          r="20"
                          fill="#F6F6F6"
                        />
                        <path
                          d="M20 20L15 15M20 20L25 25M20 20L25 15M20 20L15 25"
                          stroke="#CDCDCD"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </div>
                    <div
                      v-else-if="val.value === 'self'"
                      class="flex flex-column"
                    >
                      <svg
                        width="25"
                        height="25"
                        viewBox="0 0 40 40"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle
                          cx="20"
                          cy="20"
                          r="20"
                          fill="#7BC079"
                          fill-opacity="0.2"
                        />
                        <path
                          d="M17.6669 21.8663L24.5503 14.9829C24.7641 14.7691 25.0364 14.6621 25.3669 14.6621C25.6975 14.6621 25.9697 14.7691 26.1836 14.9829C26.3975 15.1968 26.5044 15.4691 26.5044 15.7996C26.5044 16.1302 26.3975 16.4024 26.1836 16.6163L18.4836 24.3163C18.2503 24.5496 17.978 24.6663 17.6669 24.6663C17.3558 24.6663 17.0836 24.5496 16.8503 24.3163L13.8169 21.2829C13.603 21.0691 13.4961 20.7968 13.4961 20.4663C13.4961 20.1357 13.603 19.8635 13.8169 19.6496C14.0308 19.4357 14.303 19.3288 14.6336 19.3288C14.9641 19.3288 15.2364 19.4357 15.4503 19.6496L17.6669 21.8663Z"
                          fill="#7BC079"
                        />
                      </svg>
                      <span class="text-[9px] font-medium text-darkGrey italic">
                        {{ $t(val.text) }}
                      </span>
                    </div>
                  </v-col>
                </v-row>
              </v-window-item>
            </v-window>
          </div>
        </div>
      </div>
    </template>
  </pop-up-skeleton>
</template>

<script setup>
import {ref} from 'vue'
import { Icon } from "@iconify/vue";
import PopUpSkeleton from './PopUpSkeleton.vue';
import { storeToRefs } from "pinia";
import { usePopUpStore } from "@/store/pop-up.js";

const tab = ref(null)

const { data } = storeToRefs(usePopUpStore());

const header = ref([
  {
    name: "employee",
    icon: "noto:hammer",
  },
  {
    name: "manager",
    icon: "noto:hammer-and-pick",
  },
  {
    name: "manager - creator",
    icon: "noto:hammer-and-wrench",
  },
  {
    name: "administrator",
    icon: "noto:toolbox",
  }
]);

/**
 * name: first column, line name
 * value: value of roles, [ employee, manager, manager creator, administrator ]
 * legend : yes : icon check, no : icon close, self : icon check with text
 * return array
 */

const interview = ref([
  {
    name: 'Access to \'my interviews\'',
    values: [
      { value: "yes", text: '' },
      { value: "yes", text: '' },
      { value: "yes", text: '' },
      { value: "yes", text: '' }
    ],
  },
  {
    name: 'Access to \'my team interviews\'',
    values: [
      { value: "no", text: '' },
      { value: "yes", text: '' },
      { value: "yes", text: '' },
      { value: "yes", text: '' }
    ],
  },
  {
    name: 'Can create campaigns',
    values: [
      { value: "no", text: '' },
      { value: "no", text: '' },
      { value: "yes", text: '' },
      { value: "yes", text: '' }
    ],
  },
  {
    name: 'Can view/edit campaigns',
    values: [
      { value: "no", text: '' },
      { value: "no", text: '' },
      { value: "self", text: 'Only campaigns he/she created' },
      { value: "yes", text: '' }
    ],
  },
  {
    name: 'Can create templates',
    values: [
      { value: "no", text: '' },
      { value: "no", text: '' },
      { value: "yes", text: '' },
      { value: "yes", text: '' }
    ],
  },
  {
    name: 'Can view/edit templates',
    values: [
      { value: "no", text: '' },
      { value: "no", text: '' },
      { value: "self", text: 'Only templates he/she created' },
      { value: "yes", text: '' }
    ],
  },
  {
    name: 'Can create reports',
    values: [
      { value: "no", text: '' },
      { value: "no", text: '' },
      { value: "yes", text: '' },
      { value: "yes", text: '' }
    ],
  },
  {
    name: 'Can view reports',
    values: [
      { value: "no", text: '' },
      { value: "no", text: '' },
      { value: "self", text: 'Only reports he/she created' },
      { value: "yes", text: '' }
    ],
  },
]);

const roadmap = ref([
  {
    name: 'Access to \'my roadmaps\'',
    values: [
      { value: "yes", text: '' },
      { value: "yes", text: '' },
      { value: "yes", text: '' },
      { value: "yes", text: '' }
    ],
  },
  {
    name: 'Access to \'my team roadmaps\'',
    values: [
      { value: "no", text: '' },
      { value: "yes", text: '' },
      { value: "yes", text: '' },
      { value: "yes", text: '' }
    ],
  },
  {
    name: 'Can create targets',
    values: [
      { value: "self", text: 'Only for himself/herself' },
      { value: "self", text: 'Only for himself/herself or team' },
      { value: "yes", text: '' },
      { value: "yes", text: '' }
    ],
  },
  {
    name: 'Access to \'target\'',
    values: [
      { value: "no", text: '' },
      { value: "no", text: '' },
      { value: "self", text: 'Only targets he/she created' },
      { value: "yes", text: '' }
    ],
  },
  {
    name: 'Access to \'employees\'',
    values: [
      { value: "no", text: '' },
      { value: "no", text: '' },
      { value: "no", text: '' },
      { value: "yes", text: '' }
    ],
  },
  {
    name: 'Can create templates',
    values: [
      { value: "no", text: '' },
      { value: "no", text: '' },
      { value: "yes", text: '' },
      { value: "yes", text: '' }
    ],
  },
  {
    name: 'Can view/edit templates',
    values: [
      { value: "no", text: '' },
      { value: "no", text: '' },
      { value: "self", text: 'Only templates he/she created' },
      { value: "yes", text: '' }
    ],
  },
]);

const people = ref([
  {
    name: 'View/edit his profile',
    values: [
      { value: "yes", text: '' },
      { value: "yes", text: '' },
      { value: "yes", text: '' },
      { value: "yes", text: '' }
    ],
  },
  {
    name: 'View/edit other profile',
    values: [
      { value: "no", text: '' },
      { value: "self", text: 'Only for his/her team' },
      { value: "self", text: 'Only for his/her team' },
      { value: "yes", text: '' }
    ],
  },
  {
    name: 'Access to employee list',
    values: [
      { value: "no", text: '' },
      { value: "self", text: 'Only for his/her team' },
      { value: "self", text: 'Only for his/her team' },
      { value: "yes", text: '' }
    ],
  },
  {
    name: 'Can create employees',
    values: [
      { value: "no", text: '' },
      { value: "no", text: '' },
      { value: "no", text: '' },
      { value: "yes", text: '' }
    ],
  },
  {
    name: 'Can remove employees',
    values: [
      { value: "no", text: '' },
      { value: "no", text: '' },
      { value: "no", text: '' },
      { value: "yes", text: '' }
    ],
  },
  {
    name: 'Can export employees',
    values: [
      { value: "no", text: '' },
      { value: "no", text: '' },
      { value: "no", text: '' },
      { value: "yes", text: '' }
    ],
  },
  {
    name: 'Can edit access level',
    values: [
      { value: "no", text: '' },
      { value: "no", text: '' },
      { value: "no", text: '' },
      { value: "yes", text: '' }
    ],
  },
]);

const tabs = ref([
  {
    name: "interview",
    values: interview
  },
  {
    name: "roadmap",
    values: roadmap
  },
  {
    name: "people",
    values: people
  }
])

</script>

<style scoped>

.v-overlay__content {
  height: calc(100% - 48px);
}

</style>