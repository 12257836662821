<template>
  <div class="flex items-center gap-2">
    <!-- Impersonnate -->
    <div v-if="accessLevel === 'super_admin' || impersonateAdminUser" class="flex items-center gap-2">
      <div v-if="selectedUser?.email" class="w-[98px] md:!w-full max-w-[98px] md:!max-w-full items-center justify-end">
        <svn-pro-text body-medium medium color="onSurfaceVariant" class="!line-clamp-1 !text-ellipsis">
          {{ selectedUser?.email }}
        </svn-pro-text>
      </div>

      <bkt-impersonate />
    </div>

    <!-- Apps -->
    <v-menu v-model="appsDropdownOpened" close-on-content-click>
      <template #activator="{ props }">
        <svn-pro-icon-button
          v-bind="props"
          :active="appsDropdownOpened"
          icon="custom:mingcute:dot-grid-fill"
          color="onSurfaceVariant"
          variant="text"
          class="hidden sm:flex"
        />
      </template>

      <div class="grid grid-cols-2 p-4 gap-2 bg-surfaceBright rounded-[4px] customShadow">
        <router-link
          v-for="(app, idx) in applicationsFiltered"
          :key="idx"
          :to="{ name: app.linkName, params: app.linkName === 'people_show' ? { id: currentUserId } : null }"
        >
          <svn-pro-card
            variant="outlined"
            :color="route.name === app.linkName ? 'primary' : 'default'"
            class="border-0"
            :class="route.name === app.linkName ? 'bg-[#3E52CA3D]/[0.24]' : ''"
          >
            <div class="w-[96px] h-[84px] flex flex-col gap-3 items-center justify-center">
              <div>
                <Icon
                  :icon="app.icon"
                  height="32"
                  width="32"
                  class="text-onSurface"
                />
              </div>

              <svn-pro-text body-medium regular color="onSurfaceVariant">
                {{ $t(app.appName) }}
              </svn-pro-text>
            </div>
          </svn-pro-card>
        </router-link>
      </div>
    </v-menu>

    <!-- Profile Dropdown -->
    <svn-pro-menu>
      <template #activator>
        <svn-pro-card :rounded="'xs'" class="hidden sm:flex">
          <div class="flex items-center p-2 gap-2">
            <div v-if="logo" class="w-full h-8 p-1">
              <img
                :src="logo"
                alt=""
                class="w-full h-full"
                style="max-width: 150px"
              >
            </div>

            <svn-pro-avatar
              :size="32"
              :image="avatar?.['100']"
              :firstname="firstname"
              :type="avatar?.['100'] ? 'photo' : 'monogram'"
            />
          </div>
        </svn-pro-card>
        
        <div class="sm:hidden cursor-pointer">
          <svn-pro-avatar
            :size="40"
            :image="avatar?.['100']"
            :firstname="firstname"
            :type="avatar?.['100'] ? 'photo' : 'monogram'"
          />
        </div>
      </template>

      <template #dropdown>
        <div class="w-full min-w-[280px] flex flex-col">
          <div class="w-full flex flex-col px-4 py-2 border-b !border-[#767680]">
            <!-- User Firstname & Lastname -->
            <svn-pro-text body-large regular color="onSurface">
              {{ firstname }} {{ lastname }}
            </svn-pro-text>

            <!-- User email -->
            <svn-pro-text body-medium regular color="onSurfaceVariant">
              {{ email }}
            </svn-pro-text>
          </div>

          <svn-pro-list-item
            v-for="item in menuItems"
            v-show="item?.show"
            :key="item?.value"
            :value="item?.value"
            leading="icon"
            :start="false"
            :title="item?.title"
            :leading-icon="item?.icon"
            leading-color="onSurfaceVariant"
            @click="item?.onClick()"
          />
        </div>
      </template>
    </svn-pro-menu>
  </div>

  <!-- Recommend Aleph -->
  <div class="hidden">
    <dialog-recommend-aleph ref="recommendAleph">
      <template #button>
        {{ null }}
      </template>
    </dialog-recommend-aleph>
  </div>

  <!-- Help & Support -->
  <div class="hidden">
    <dialog-help-and-support ref="helpAndSupport">
      <template #button>
        {{ null }}
      </template>
    </dialog-help-and-support>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { storeToRefs } from "pinia";
import { useUserStore } from "@/store/user.js";
import { useRouter, useRoute } from "vue-router";
import { useSnackbar } from "@/store/snackbar.js";
import axiosService from "@/tools/axios-service.js";
import { Icon } from "@iconify/vue/dist/iconify.js";
import { useImpersonateStore } from "@/store/impersonate.js";
import BktImpersonate from "@/components/impersonate/BktImpersonate.vue";
import DialogRecommendAleph from "../BktPopUp/Dialogs/DialogRecommendAleph.vue";
import DialogHelpAndSupport from "../BktPopUp/Dialogs/DialogHelpAndSupport.vue";

onMounted(async () => {
  try {
    await initImpersonate();
  } catch (error) {
    snackbar.setBgColor('error')
    snackbar.setMsg('Error while initiating impersonate')
    snackbar.displaySnackBar()
  }
})

const { id: currentUserId } = storeToRefs(useUserStore());
const { selectedUser, impersonateAdminUser } = storeToRefs(useImpersonateStore());

const route = useRoute();
const router = useRouter();
const snackbar = useSnackbar();
const { isAdminOrAbove } = useUserStore();
const { initImpersonate } = useImpersonateStore();

const recommendAleph = ref(null);
const helpAndSupport = ref(null);
const menuItems = ref([
  {
    value: "profile",
    title: "Profile",
    show: true,
    icon: "mingcute:user-3-line",
    onClick: () => goToProfile(),
  },
  {
    value: "company",
    title: "Company",
    show: isAdminOrAbove(),
    icon: "mingcute:building-2-line",
    onClick: () => goToCompanyShow(),
  },
  {
    value: "recommend",
    title: "Recommend Aleph",
    show: true,
    icon: "mingcute:star-line",
    onClick: () => recommendAleph.value.dialogRef.dialog = true,
  },
  {
    value: "help_and_support",
    title: "Help & Support",
    show: true,
    icon: "mingcute:question-line",
    onClick: () => helpAndSupport.value.dialogRef.dialog = true,
  },
  {
    value: "logout",
    title: "Logout",
    show: true,
    icon: "mingcute:exit-line",
    onClick: () => axiosService.logout(),
  },
])

const props = defineProps({
  id: { type: Number, default: null },
  firstname: { type: String, default: null },
  lastname: { type: String, default: null },
  email: { type: String, default: null },
  avatar: { type: Object, default: () => {} },
  accessLevel: { type: String, default: null },
  logo: { type: String, default: null },
  name: { type: String, default: null },
  applicationsFiltered: { type: Array, default: () => [] },
  impersonateDisplay: { type: Boolean, default: false },
});

const emit = defineEmits(['display-impersonate'])

const appsDropdownOpened = ref(false);

const goToProfile = async () => {
  const routeData = router.resolve({ name: "people_show", params: { id: props.id }, query: { page: 'interviews' } });
  window.open(routeData.href, '_blank')
};

const goToCompanyShow = () => {
  router.push({ name: "company", query: { tab: 'information' } })
}

</script>

<style lang="scss" scoped>
.customShadow {
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px 0px rgba(0, 0, 0, 0.12), 0px 1px 5px 0px rgba(0, 0, 0, 0.20);
}

.profile-box {
  height: 3rem !important;
  border: black 1px solid !important;
}

* :deep(.v-list-item--density-default.v-list-item--one-line) {
  min-height: 40px !important;
}
</style>
