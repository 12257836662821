<template>
  <v-infinite-scroll
    class="px-5 md:!px-0 overflow-hidden"
    :items="interviews"
    data-testid="container_interviews"
    @load="onLoad"
  >
    <div class="flex flex-col gap-4">
      <div v-for="(interview, index) in interviews" :key="index">
        <MyInterviewsOneToOneCard
          v-if="interview.campaign?.campaign_type === CampaignTypeBackend.ONE_TO_ONE"
          :id="interview.interview_id"
          :campaign="interview.campaign"
          :interview-set="interview.interview_set"
          :interviewee="interview.interviewee"
          :interviewer="interview.interviewer"
          :crossed-interview-id="interview.cross_interview_id"
        />
  
        <MyInterviewFeedbackCard
          v-else-if="interview.campaign.campaign_type === CampaignTypeBackend.FEEDBACK_360"
          :id="interview.interview_id"
          :feedback="interview"
          :campaign="interview.campaign"
          :can-auto-evaluation="interview?.can_self_evaluate || false"
          :can-manage-reviewers="interview?.can_choose_pairs || false"
          :can-consult-answers="interview?.can_view_pairs_answers || false"
        />
  
        <MyInterviewsSurveyCard
          v-else-if="interview.campaign.campaign_type === CampaignTypeBackend.SURVEY"
          :id="interview.interview_id"
          :campaign="interview.campaign"
          :interviewee="interview.interviewee"
          :status="interview.status"
        />
      </div>
    </div>

    <template #empty>
      <div v-if="!interviews?.length" class="h-[338px] flex flex-col justify-center items-center gap-2 px-6 py-4">
        <div>
          <Icon
            icon="noto:winking-face"
            height="32"
            width="32"
          />
        </div>

        <div class="w-full max-w-[608px] flex flex-col justify-center items-center">
          <svn-pro-title h6 medium>
            {{ $t('Nothing to show for now') }}
          </svn-pro-title>

          <p
            class="text-base font-normal leaing-6 tracking-[0.5px] text-center"
            v-html="getEmptyText(tab)"
          />
        </div>
      </div>

      <svn-pro-text v-else subtitle-medium regular class="text-center">
        {{ $t('No more interview to show.') }}
      </svn-pro-text>
    </template>

    <template #loading>
      <div class="w-full flex flex-col justify-center items-center py-4">
        <svn-pro-progress-circular
          :size="28"
          :width="4"
          indeterminate
          color="primary"
        />
      </div>
    </template>
  </v-infinite-scroll>
</template>

<script setup>
import i18n from "@/plugins/i18n";
import { Icon } from '@iconify/vue';
import { CampaignTypeBackend } from "@/constants/CampaignTypeEnum.js";
import MyInterviewsSurveyCard from "@/components/interviewApp/MyInterview/MyInterviewSurveyCard.vue";
import MyInterviewFeedbackCard from "@/components/interviewApp/MyInterview/MyInterviewFeedbackCard.vue";
import MyInterviewsOneToOneCard from "@/components/interviewApp/MyInterview/MyInterviewsOneToOneCard.vue";

const props = defineProps({
  tab: { type: String, default: '' },
  interviews: { type: Array, required: true },
  totalPages: { type: undefined, required: true },
  currentPage: { type: undefined, required: true },
})

const emits = defineEmits(["pagination"])

const getEmptyText = () => {
  switch (props?.tab) {
    case 'all':
      return i18n.global.t('<p>All your interviews and surveys will show up here.</p>')
    case 'to_do':
      return i18n.global.t('<p>Your current interviews and surveys will show up here.</p>')
    case 'done':
      return i18n.global.t('<p>Your completed interviews and surveys will show up here.</p>')
    case 'default':
      return ''
  }
}

const onLoad = async ({ done }) => {
  emits("pagination", done)
}
</script>