import { usePopUpStore } from "../store/pop-up.js";

export default {
  open(opt) {
    usePopUpStore().update({ open: true, ...opt });
  },
  close() {
    usePopUpStore().update({ open: false, loading: false, title: null });
  },
};
