<template>
  <div class="h-[338px] flex flex-col justify-center items-center gap-2 px-6 py-4">
    <div>
      <Icon
        icon="noto:persevering-face"
        height="32"
        width="32"
      />
    </div>

    <p
      class="text-base font-normal leaing-6 tracking-[0.5px]"
      v-html="$t('<p>It seems we failed to load this section, try to reload this page by clicking the button below.</p><br/><p>If the issue is persistent, contact our support at : support@byseven.co</p>')"
    />
    
    <svn-pro-button
      :text="$t('Reload')"
      prepend-icon="custom:mingcute:refresh-3-line"
      @click="reload"
    />
  </div>
</template>

<script setup>
import { Icon } from '@iconify/vue';

const reload = () => {
  window.location.reload()
}
</script>