<template>
  <svn-pro-modal
    v-model="dialogAddToPlaylist"
    :width="isMdScreen ? 480 : undefined"
    :title="$t('Add this module to playlist ()', { selectedPlaylists: selectedPlaylistsCount || 0 })"
    no-click-animation
    :sticky-bottom="isMdScreen ? true : false"
    :close-button-closes-modal="false"
    @click-close="dialogAddToPlaylist = false"
    @click-outside="deleteDialog = false"
    @click-secondary-button="dialogAddToPlaylist = false"
  >
    <template #activator="{ props }">
      {{ null }}
    </template>

    <template #text>
      <div class="w-full flex flex-col gap-4 h-full md:!h-[440px]">
        <div class="w-full flex flex-col md:!flex-row items-start md:!items-center gap-4">
          <svn-pro-text-field
            v-model="searchText"
            clearable
            class="w-full"
            :placeholder="$t('Search')"
            clear-icon="custom:mingcute:close-line"
            prepend-inner-icon="custom:mingcute:search-2-line"
            @update:model-value="search"
          />
        </div>

        <template v-if="playlistsPagesIsPending">
          <div class="w-full h-full flex flex-col items-center justify-center">
            <svn-pro-progress-circular :size="48" :width="5" indeterminate />
          </div>
        </template>

        <div v-else>
          <v-infinite-scroll
            v-if="playlistsPages?.pages?.[0]?.learnPlaylists?.length"
            class="overflow-hidden w-full"
            color="primary"
            @load="loadMoreData"
          >
            <v-list select-strategy="classic" color="primary">
              <template v-for="page in playlistsPages?.pages">
                <v-list-item
                  v-for="(content, index) in page?.learnPlaylists"
                  :key="index"
                  :active="false"
                  :value="`${content?.contentType}_${content?.id}`"
                  class="border-b border-b-[#767680] border-opacity-100"
                >
                  <content-card-item :content="caseStyles.convertKeysToSnake(content)" @toggle-content="toggleContent(content)" />
  
                  <template #append>
                    <v-list-item-action>
                      <v-checkbox-btn
                        color="primary"
                        :class="content?.learnModuleIds?.includes(moduleId) ? 'text-primary' : ''"
                        :model-value="content?.learnModuleIds?.includes(moduleId) ? true : false"
                        @update:model-value="toggleContent(content)"
                      />
                    </v-list-item-action>
                  </template>
                </v-list-item>
              </template>
            </v-list>

            <template #empty />
          </v-infinite-scroll>

          <!-- Empty states -->
          <div
            v-else
            class="flex text-center justify-center items-center"
          >
            <svn-pro-empty-states
              :variant="searchText ? 'results' : 'index'"
              :size="isMdScreen ? 'default' : 'compact'"
              :supporting-text="emptyStateSupportingText"
            />
          </div>
        </div>
      </div>
    </template>
  </svn-pro-modal>
</template>

<script setup>
import { debounce } from 'lodash';
import i18n from '@/plugins/i18n';
import { storeToRefs } from 'pinia';
import { ref, computed } from 'vue';
import PlaylistApi from '@/apis/playlist.api';
import caseStyles from "@/tools/caseStyles.js";
import { useSnackbar } from '@/store/snackbar';
import { useMobileStore } from '@/store/mobile';
import { useInfiniteQuery } from '@tanstack/vue-query';
import ContentCardItem from '@/components/trainingApp/ContentCardItem.vue';

const props = defineProps({
  moduleId: { type: Number, default: null },
  selectedPlaylistsCount: { type: Number, default: null },
});

const emit = defineEmits(['update-selected-count']);

const snackbar = useSnackbar();

const { isMdScreen } = storeToRefs(useMobileStore());

const searchText = ref('');
const searchTextDebounce = ref('');
const dialogAddToPlaylist = ref(false);
const playlistCacheKey = computed(() => {
  return [
    'learn',
    'catalog',
    'playlists',
    searchTextDebounce.value,
    [],
  ];
});

const {
  data: playlistsPages,
  fetchNextPage: playlistsPagesFetchNextPage,
  hasNextPage: playlistsPagesHasNextPage,
  isPending: playlistsPagesIsPending,
  refetch: playlistRefetch
} = useInfiniteQuery({
  queryKey: playlistCacheKey,
  queryFn: ({ pageParam = 1, queryKey }) => {
    const [one, two, three, text, themeIds, sortBy] = queryKey;

    return PlaylistApi.index({
      page: pageParam,
      text: text,
      themeIds: themeIds,
    });
  },
  getNextPageParam: (lastPage) => lastPage?.meta?.pagination?.nextPage,
});

const search = debounce(async () => {
  searchTextDebounce.value = searchText.value
}, 300);

const toggleContent = async (content) => {
  try {    
    let moduleIds = [...content?.learnModuleIds]
    if (!moduleIds?.includes(props?.moduleId)) {
      moduleIds?.push(props?.moduleId)
      await PlaylistApi.updateModuleIds(content?.id, moduleIds)
      snackbar.setBgColor('onSurface').setMsg('Module successfully added to playlist.').setCustomClass(isMdScreen.value ? 'mb-4' : 'mb-[72px]').displaySnackBar();
      emit('update-selected-count', 'added')
    } else {
      moduleIds = moduleIds?.filter(el => el !== props?.moduleId)
      await PlaylistApi.updateModuleIds(content?.id, moduleIds)
      snackbar.setBgColor('onSurface').setMsg('Module removed from playlist.').setCustomClass(isMdScreen.value ? 'mb-4' : 'mb-[72px]').displaySnackBar();
      emit('update-selected-count', 'removed')
    }
    await playlistRefetch()

  } catch (error) {
    console.log(error)
    snackbar.setBgColor('onSurface').setMsg('Error adding module to playlist(s)').setCustomClass(isMdScreen.value ? 'mb-4' : 'mb-[72px]').displaySnackBar();
  }
};

const loadMoreData = async ({ done }) => {
  if (playlistsPagesHasNextPage.value) {
    await playlistsPagesFetchNextPage();
    done('ok');
  } else {
    done('empty');
  }
};

const emptyStateSupportingText = computed(() => {
  return searchText.value
    ? i18n.global.t('Oops, we didn’t find any results matching your search.')
    : i18n.global.t(`You dont have any playlist for the moment.`);
});

defineExpose({
  dialogAddToPlaylist,
});
</script>