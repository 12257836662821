<template>
  <div
    class="relative w-full"
    :class="[`h-[196px] md:!h-[216px]`, mode === 'position' ? 'drag-position' : '']"
  >
    <cropper
      ref="cropperRef"
      class="absolute left-0 top-0 rounded-[8px] bg-onSurfaceVariant w-full h-full"
      :class="[mode !== 'show' ? 'hover:cursor-move' : '']"
      :src="urlRef"
      :stencil-props="{
        handlers: {},
        movable: false,
        resizable: false,
        previewClass: 'round-every-box'
      }"
      imageClass="round-every-box"
      backgroundClass="round-every-box"
      :stencil-size="stencilSize"
      image-restriction="stencil"
      :resize-image="{ wheel: false }"
      @change="updateCoordinates"
    />

    <div v-if="mode === 'show'" class="w-full h-full absolute top-0 left-0">
      <!-- The purpose of this empty div is to disable movement -->
    </div>

    <div v-else-if="mode === 'edit'" class="w-full h-full absolute top-0 left-0 flex flex-row items-start justify-end p-2 gap-2">
      <!-- Edit Image button -->
      <svn-pro-icon-button
        v-if="disabled"
        :disabled="disabled"
        icon="custom:mingcute:edit-2-line"
        color="surface"
        :variant="disabled ? 'tonal' : undefined"
      />

      <svn-pro-dialog-upload-image v-else @file-uploaded="uploadImage">
        <template #activator="{ isActive }">
          <svn-pro-icon-button
            :icon="isActive ? 'custom:mingcute:edit-2-fill' : 'custom:mingcute:edit-2-line'"
            color="surface"
          />
        </template>
      </svn-pro-dialog-upload-image>

      <!-- Position Image button -->
      <svn-pro-icon-button
        icon="custom:mingcute:move-line"
        color="surface"
        :disabled="disabled"
        :variant="disabled ? 'tonal' : undefined"
        class="!ml-0"
        @click="changeMode('position')"
      />
    </div>

    <div
      v-else-if="mode === 'position'"
      class="absolute top-0 right-0 flex flex-row items-start justify-end p-2 gap-2"
    >
      <!-- Validate changes -->
      <svn-pro-icon-button
        icon="custom:mingcute:check-line"
        color="surface"
        @click="validateChanges"
      />

      <!-- Discard changes -->
      <svn-pro-icon-button
        icon="custom:mingcute:close-line"
        color="surface"
        @click="discardChanges"
      />
    </div>
  </div>
</template>
<script setup>
import { onMounted, ref, watch } from "vue";
import 'vue-advanced-cropper/dist/style.css';
import { Cropper } from 'vue-advanced-cropper';

const props = defineProps({
  mode: { type: String, default: 'show' },
  url: { type: String, default: null },
  disabled: { type: Boolean, default: false },
  coordinates: { type: Object, default: () => ({ left: 0, top: 0 })},
});

onMounted(() => {
  urlRef.value = props?.url

  cropperRef.value.setCoordinates({
    left: props?.coordinates?.left,
    top: props?.coordinates?.top
  })
});

const emit = defineEmits(['fileUpload', 'update:coordinates', 'error', 'change-mode'])

const urlRef = ref(null);
const cropperRef = ref(null);
const cropperCoordinates = ref({});

const updateCoordinates = (event) => {
  cropperCoordinates.value = {
    left: event?.coordinates?.left,
    top: event?.coordinates?.top,
  }
}

const changeMode = (value) => {
  emit('change-mode', value)
}

const validateChanges = () => {
  changeMode('edit')
  validateCoor();
}

const discardChanges = () => {
  changeMode('edit')
  rollbackCoor();
}

const validateCoor = () => {
  emit('update:coordinates', {
    left: cropperCoordinates?.value?.left,
    top: cropperCoordinates?.value?.top
  })
  cropperRef?.value?.setCoordinates({
    left: cropperCoordinates?.value?.left,
    top: cropperCoordinates?.value?.top
  })
}

const rollbackCoor = () => {
  cropperRef?.value?.setCoordinates({
    left: props?.coordinates?.left,
    top: props?.coordinates?.top
  })
}

const stencilSize = ({ boundaries }) => {
  return {
    height: boundaries.height,
    width: boundaries.width,
    // height: 320,
    // width: 726,
  }
}

const uploadImage = async (imageLink) => {
  try {
    const tmpFile = await loadXHR(imageLink)
    emit('fileUpload', tmpFile)
    validateCoor()
  } catch (error) {
    emit('error', 'Error uploading module image')
  }
  document.getElementById('closeThefuckingOverlayCover').click()
}

const loadXHR = (url) => {
  return new Promise(function (resolve, reject) {
    try {
      const xhr = new XMLHttpRequest();
      xhr.open("GET", url);
      xhr.responseType = "blob";
      xhr.onerror = function () {
        reject("Network error.")
      };
      xhr.onload = function () {
        if (xhr.status === 200) {
          const file = new File([xhr.response], `tmp-${new Date()}`);
          resolve(file)
        } else {
          reject("Loading error:" + xhr.statusText)
        }
      };
      xhr.send();
    } catch (err) {
      reject(err.message)
    }
  });
}

watch(props, (newValue, oldValue) => {
  if (newValue?.url) {
    urlRef.value = newValue?.url
  }
  if (newValue?.coordinates) {
    cropperRef?.value?.setCoordinates({
      left: props?.coordinates?.left,
      top: props?.coordinates?.top
    })
  }
})
</script>

<style>
.round-every-box {
  border-radius: 8px !important;
}

.vue-advanced-cropper__image-wrapper,
.vue-advanced-cropper__foreground {
  border-radius: 8px;
}
</style>

<style scoped>
.drag-position::after {
  background-color: #1C1B1B;
  color: white;
  content: 'Drag image to reposition';
  position: absolute;
  cursor: not-allowed;
  max-width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 1;
  transition-property: width, opacity;
  transition-duration: 270ms;
  transition-timing-function: ease;
  padding-left: 16px;
  padding-right: 16px;
  border-radius: 8px;
}
</style>