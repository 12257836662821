<template>
  <div class="w-full max-w-[944px] md:!px-12 xl:!px-0 mx-auto flex flex-col pt-5 md:!pt-6 desktop:!pt-10 pb-16 gap-6 desktop:!gap-10 bg-surface">
    <svn-pro-header-section
      :size="isMdScreen ? 'default' : 'compact'"
      :title="$t('My Interviews')"
      :subtitle="$t('Here is the list of all your interviews. Complete them all!')"
    />

    <!-- Different tabs -->
    <div class="flex flex-col gap-2">
      <!-- Tabs -->
      <v-tabs v-model="tab" color="primary":grow="!isMdScreen" >
        <v-tab
          value="all"
          :text="$t('All') + ` (${interviewsMeta?.total_count || 0})`"
          class="w-fit"
        />

        <v-tab
          value="to_do"
          :text="$t('To do') + ` (${interviewsToDoMeta?.total_count || 0})`"
          class="w-fit"
        />

        <v-tab
          value="done"
          :text="$t('Done') + ` (${interviewsDoneMeta?.total_count || 0})`"
          class="w-fit"
        />
      </v-tabs>

      <v-window v-model="tab" class="w-full">
        <v-window-item value="all">
          <MyInterviewList
            v-if="interviews"
            :tab="tab"
            :interviews="interviews"
            :total-pages="interviewsMeta?.total_pages"
            :current-page="interviewsMeta?.current_page"
            @pagination="load"
          />

          <BktFailToLoad v-else-if="error[0]" class="mt-16 max-w-md mx-auto" />

          <div v-else class="w-full flex h-[30vh] justify-center items-center">
            <svn-loader loading-size="lg" />
          </div>
        </v-window-item>

        <v-window-item value="to_do">
          <MyInterviewList
            v-if="interviewsToDo"
            :tab="tab"
            :interviews="interviewsToDo"
            :total-pages="interviewsToDoMeta?.total_pages"
            :current-page="interviewsToDoMeta?.current_page"
            @pagination="load"
          />

          <BktFailToLoad v-else-if="error[1]" class="mt-16 max-w-sm mx-auto" />

          <div v-else class="w-full flex h-[30vh] justify-center items-center">
            <svn-loader loading-size="lg" />
          </div>
        </v-window-item>

        <v-window-item value="done">
          <MyInterviewList
            v-if="interviewsDone"
            :tab="tab"
            :interviews="interviewsDone"
            :total-pages="interviewsDoneMeta?.total_pages"
            :current-page="interviewsDoneMeta?.current_page"
            @pagination="load"
          />

          <BktFailToLoad v-else-if="error[2]" class="mt-16 max-w-sm mx-auto" />

          <div v-else class="w-full flex h-[30vh] justify-center items-center">
            <svn-loader loading-size="lg" />
          </div>
        </v-window-item>
      </v-window>
    </div>

    <svn-pro-floating-action-button
      to-top
      size="small"
      color="primary"
      variant="tonal"
      :rounded="'lg'"
      class="fixed bottom-4 right-4 bg-white"
      icon="custom:mingcute:arrow-up-line"
    />
  </div>
</template>

<script setup>
import { storeToRefs } from "pinia";
import { onMounted, ref, computed } from "vue";
import { useMobileStore } from "@/store/mobile.js";
import BktFailToLoad from "@/components/BktFailToLoad.vue";
import { useMyInterviewsStore } from "@/store/my-interviews.js";
import MyInterviewList from "@/components/interviewApp/MyInterview/MyInterviewList.vue";

const { isMdScreen } = storeToRefs(useMobileStore());
const { interviews, interviewsMeta, interviewsToDo, interviewsToDoMeta, interviewsDone, interviewsDoneMeta } = storeToRefs(useMyInterviewsStore());

const { fetchMyInterviews, fetchMyInterviewsToDo, fetchMyInterviewsDone } = useMyInterviewsStore();

onMounted(async () => {
  interviews.value = null
  interviewsToDo.value = null
  interviewsDone.value = null

  if (await fetchMyInterviews(1) === "error") error.value[0] = true
  if (await fetchMyInterviewsToDo(1) === "error") error.value[1] = true
  if (await fetchMyInterviewsDone(1) === "error") error.value[2] = true
})

const tab = ref(null)
const pages = ref({
  all: 1,
  to_do: 1,
  done: 1,
})
const totalPages = computed(() => {
  return {
    all: interviewsMeta?.value?.total_pages,
    to_do: interviewsToDoMeta?.value?.total_pages,
    done: interviewsDoneMeta?.value?.total_pages,
  }
})
const error = ref([false, false, false])
const tabs = ref({
  all: fetchMyInterviews,
  to_do: fetchMyInterviewsToDo,
  done: fetchMyInterviewsDone,
})

const load = async (done) => {
  if (pages?.value?.[tab?.value] < totalPages?.value?.[tab?.value]) {
    try {
      await tabs?.value?.[tab?.value](pages?.value?.[tab?.value] + 1)

      done('ok')
      pages.value[tab?.value] += 1
    } catch (error) {
      done('error')
    }
  }
  else {
    done('empty')
  }
}
</script>