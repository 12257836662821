<template>
  <v-menu v-model="parentMenu" :close-on-content-click="false">
    <template #activator="{ props, isActive }">
      <!-- Filter chip (which activates the menu) -->
      <div
        v-bind="props"
        class="flex justify-center h-fit items-center gap-2 pl-4 pr-2 py-[6px] border rounded-[8px] cursor-pointer max-w-full sm:max-w-[300px]"
        :class="selectedFilterAnswerData?.length ? 'border-0 bg-primary' : 'border-[#767680] border-opacity-100'"
      >
        <!-- Filter chip main content -->
        <div class="flex items-center gap-2">
          <!-- Filter Chip Check Icon when selected -->
          <div v-if="selectedFilterAnswerData?.length">
            <Icon
              height="18"
              width="18"
              icon="mingcute:check-line"
              class="text-onPrimary"
            />
          </div>

          <!-- Filter Chip title -->
          <svn-pro-text body-medium medium :color="selectedFilterAnswerData?.length ? 'surface' : 'onSurfaceVariant'">
            {{ item?.name }}:
          </svn-pro-text>

          <!-- Filter Chip Operator Option & Answer -->
          <div
            v-if="selectedFilterAnswerData?.length"
            class="flex items-center gap-1"
          >
            <!-- Filter Chip Selected Operator Option -->
            <svn-pro-text
              v-if="item?.type === 'number' || item?.type === 'date'"
              medium
              body-medium
              color="surface"
              class="max-w-[115px] line-clamp-1 break-all"
            >
              {{ getSelectItemText(filterOperatorOption) }}
            </svn-pro-text>

            <!-- Filter Chip Selected Answer(s) (Type multi_select) -->
            <svn-pro-text
              v-if="item?.type === 'multi_select'"
              medium
              body-medium
              color="surface"
              class="max-w-[115px] line-clamp-1 break-all"
            >
              {{ isColumnUser ? finalString : getFilterAnswers() }}
            </svn-pro-text>

            <!-- Filter Chip Selected Answer(s) (Other types) -->
            <div
              v-else
              class="max-w-[115px]"
            >
              <svn-pro-text
                medium
                body-medium
                color="surface"
                class="max-w-[115px] line-clamp-1 break-all"
              >
                {{ selectedFilterAnswerData }}
              </svn-pro-text>
            </div>
          </div>

          <!-- Filter up/down arrow -->
          <div>
            <Icon
              height="18"
              width="18"
              :icon=" isActive ? 'mingcute:up-small-fill' : 'mingcute:down-small-fill'"
              :class="selectedFilterAnswerData?.length ? 'text-onPrimary' : 'text-onSurface'"
            />
          </div>
        </div>
      </div>
    </template>

    <!-- Filter chip menu (after the chip is pressed) -->
    <v-list class="!py-0">
      <div class="flex flex-col p-4 items-start gap-2 w-full min-w-[227px] max-w-[500px]">
        <div
          v-if="item?.type !== 'text' && item?.type !== 'multi_select'"
          class="w-full justify-between flex items-center"
        >
          <div class="w-full flex items-center gap-4">
            <!-- Filter chip selected operator option (Other types) -->
            <v-menu v-model="childMenu" :close-on-content-click="false">
              <template #activator="{ props, isActive }">
                <!-- Menu activator to select another filter operator option -->
                <div
                  v-bind="props"
                  id="activatorMenu"
                  class="flex justify-center items-center gap-2 pl-4 pr-2 py-[6px] border border-[#767680] border-opacity-100 rounded-[8px] cursor-pointer max-w-full"
                >
                  <!-- Filter Chip selected operator option -->
                  <svn-pro-text body-medium medium color="onSurfaceVariant">
                    {{ getSelectItemText(filterOperatorOption) }}
                  </svn-pro-text>

                  <!-- Down arrow after filter operator option -->
                  <div>
                    <Icon
                      height="18"
                      width="18"
                      icon="mingcute:down-small-fill"
                      class="text-onSurface"
                    />
                  </div>
                </div>
              </template>

              <!-- Filter operator option list -->
              <v-list class="!py-0" color="primary">
                <v-list-item
                  v-for="(element, index) in getSelectItemsAccordingToType(item?.type)"
                  :key="index"
                  :value="index"
                  :active="false"
                  class="min-w-[167px]"
                  @click="changeSelectItemModel(element)"
                >
                  <!-- Filter operator option value -->
                  <svn-pro-text body-large class="line-clamp-1">
                    {{ getSelectItemText(element) }}
                  </svn-pro-text>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </div>

        <!-- Filter type="text" OR Filter type="number" -->
        <svn-pro-text-field
          v-if="item?.type === 'text' || item?.type === 'number'"
          v-model="selectedFilterAnswerData"
          :autofocus="false"
          :label="`${item?.name}`"
          hide-details
          clearable
          clear-icon="mdi-close"
          class="w-full"
          :type="item?.type"
          variant="outlined"
          @update:model-value="updateSelectedFilterAnswerData"
          @click:clear="clearFilterNoEmit"
        />

        <!-- Filter type="date" -->
        <pop-up-edit-date
          v-else-if="item?.type === 'date'"
          @save="updateDate"
        >
          <template #activator="{ props }">
            <svn-pro-text-field
              v-bind="props"
              variant="outlined"
              clearable
              clear-icon="mdi-close"
              v-model="selectedFilterAnswerData"
              color="primary"
              class="w-full"
              append-inner-icon="custom:mingcute:calendar-2-line"
              :label="$t('Date')"
              @click:clear="clearFilterNoEmit"
            />
          </template>
        </pop-up-edit-date>

        <!-- Filter type="multi_select" -->
        <svn-pro-autocomplete
          v-else-if="item?.type === 'multi_select'"
          v-model="selectedFilterAnswerData"
          v-model:search="searchText"
          :autofocus="false"
          variant="outlined"
          :multiple="true"
          clearable
          clear-icon="mdi-close"
          class="w-full max-w-[195px]"
          :menu-props="{ maxWidth: 195, maxHeight: 300 }"
          :item-title="column?.answer_options?.type === 'sync' ?
            (item) => { return `${item}`} :
            (item) => { return `${item?.fullname}`}"
          :item-value="column?.answer_options?.type === 'sync' ? 'key' : 'id'"
          :items="formattedFilteredData"
          :label="`${item?.name}`"
          @input="setSearch"
          @update:model-value="updateSelectedFilterAnswerData"
          @intersect="loadMoreData"
          @click:clear="clearFilterNoEmit"
        />
      </div>
    </v-list>
  </v-menu>
</template>

<script setup>
import moment from 'moment';
import { debounce } from 'lodash';
import { storeToRefs } from 'pinia';
import { Icon } from '@iconify/vue';
import { useFilterStore } from "@/store/filters";
import axiosService from "@/tools/axios-service.js";
import { ref, onMounted, watch, computed } from 'vue';
import PopUpEditDate from './popUpComponents/PopUpEditDate.vue';

const props = defineProps({
  item: { type: Object, default: () => {} },
  data: { type: Object, default: () => {} },
})

onMounted(async() => {
  initializeModels()
})

const emit = defineEmits(['update-selected-data-filters'])

const { columns } = storeToRefs(useFilterStore());

const column = ref(null);
const currentPage = ref(1);
const loading = ref(true);
const finalString = ref('');
const itemFound = ref(true);
const searchText = ref(null);
const totalPages = ref(null);
const formattedFilteredData = ref([]);
const filterOperatorOption = ref(null);
const selectedFilterAnswerData = ref(null);
const parentMenu = ref(false);
const childMenu = ref(false);


const getSelectItemsAccordingToType = (type) => {
  switch (type) {
    case "date":
      return ["before", "after"]
    case "number":
      return ["equal", "not_equal", "greater_than", "less_than"]
    default:
      return ["contains"]
  }
}

const getSelectItemText = (value) => {
  switch (value) {
    case "before":
      return "Before"
    case "after":
      return "After"
    case "equal":
      return "Equals"
    case "not_equal":
      return "Not Equal"
    case "greater_than":
      return "Greater than"
    case "less_than":
      return "Less than"
    default:
      return ""
  }
}

const changeSelectItemModel = (value) => {
  childMenu.value = false
  let oldValue = filterOperatorOption.value

  filterOperatorOption.value = value
  // document.getElementById('activatorMenu').click()

  if (value !== oldValue?.toLowerCase() && selectedFilterAnswerData?.value?.length) {
    emit('update-selected-data-filters', { value: selectedFilterAnswerData?.value, operator: value })
  }
}

const initializeModels = async() => {
  column.value = columns?.value?.find(el => el?.name === props?.item?.key)

  if (!formattedFilteredData?.value?.length) {
    if (column?.value?.answer_options?.type === "async") {
      if (column?.value?.answer_options?.endpoint === "/api/v1/users") {
        if (column?.value?.name === "manager_id") {
          try {
            const { data } = await axiosService.get(`/api/v1/users/users_search`,
            { params: {
                only_managers: true,
                "page[per]": 25
              }
            });
            formattedFilteredData.value = data?.users;
            totalPages.value = data?.meta?.pagination?.total_pages;
            loading.value = false;
          } catch (error) {
            console.log(error);
          }
        } else if (column?.value?.name === "objectivable_id") {
          try {
            const { data } = await axiosService.get(`/api/v1/users`,
            { params: {
                "page[per]": 25
              }
            });
            formattedFilteredData.value = data?.users;
            totalPages.value = data?.meta?.pagination?.total_pages;
            loading.value = false;
          } catch (error) {
            console.log(error);
          }
        }
      }
    } else {
      formattedFilteredData.value = column?.value?.answer_options?.items
    }
  }
  if (!filterOperatorOption?.value) {
    filterOperatorOption.value = getSelectItemsAccordingToType(props?.item?.type)?.[0]
  }
}

const clearFilterNoEmit = () => {
  selectedFilterAnswerData.value = null
  emit('update-selected-data-filters', selectedFilterAnswerData?.value)
}

const isColumnUser = computed(() => {
  return column?.value?.name === "manager_id" || column?.value?.name === "objectivable_id"
})

const updateDate = (value) => {
  selectedFilterAnswerData.value = moment(value).format('DD-MM-YYYY')

  updateSelectedFilterAnswerData([value])
}

const updateSelectedFilterAnswerData = debounce(async(e) => {
  emit('update-selected-data-filters', { value: e, operator: filterOperatorOption?.value })

  if (isColumnUser.value) {
    finalString.value = ""
    for (let [index, element] of e?.entries()) {
      const { data } = await axiosService.get(`/api/v1/users/${element}`)
      if (index !== e?.length - 1) {
        finalString.value += `${data?.user?.fullname}, `
      } else {
        finalString.value += `${data?.user?.fullname}`
      }
    }
  }
}, 300);

const getFilterAnswers = () => {
  let data = ''
  selectedFilterAnswerData.value.map((element, i) => {
    if (i !== selectedFilterAnswerData?.value?.length - 1) {
      data += `${element}, `
    } else {
      data += `${element}`
    }
  })
  return data
}

const setSearch = (e) => {
  if (column?.value?.name === "manager_id" || column?.value?.name === "objectivable_id") {
    loading.value = true
    let found = formattedFilteredData.value.find(el => el?.fullname?.toLowerCase().includes(searchText.value))
    setItemFound(found)
    setDataAfterSearch()
  } else {
    let found = formattedFilteredData.value.find(el => el?.toLowerCase().includes(searchText.value))
    setItemFound(found)
  }
}

const loadMoreData = async() => {
  if (currentPage?.value < totalPages?.value) {
    if (column?.value?.name === "manager_id") {
      try {
        loading.value = true;
        const { data } = await axiosService.get(`/api/v1/users/users_search`,
        { params: {
            only_managers: true,
            "page[per]": 25,
            "page[number]": currentPage?.value + 1
          }
        });
        formattedFilteredData.value = [ ...formattedFilteredData?.value, ...data?.users ]
        totalPages.value = data?.meta?.pagination?.total_pages
        loading.value = false;
      } catch (error) {
        console.log(error);
      }
    } else if (column?.value?.name === "objectivable_id") {
      try {
        loading.value = true;
        const { data } = await axiosService.get(`/api/v1/users`,
        { params: {
            "page[per]": 25,
            "page[number]": currentPage?.value + 1
          }
        });
        formattedFilteredData.value = [ ...formattedFilteredData?.value, ...data?.users ]
        totalPages.value = data?.meta?.pagination?.total_pages
        loading.value = false;
      } catch (error) {
        console.log(error);
      }
    }
    currentPage.value += 1;
  }
}

const setDataAfterSearch = debounce(async() => {
  try {
    const { data } = await axiosService.get(`/api/v1/users`,
    { params: {
        title: searchText?.value,
        "page[per]": 25,
        "page[number]": 1
      }
    });
    formattedFilteredData.value = data?.users;
    totalPages.value = data?.meta?.pagination?.total_pages;
    if (!data?.users?.length) {
      loading.value = false;
    }
  } catch (error) {
    console.log(error)
  }
}, 300)

const setItemFound = (found) => {
  if (found) {
    itemFound.value = true
  }
  else {
    itemFound.value = false
  }
}

watch(() => props?.data, (newValue, oldValue) => {
  if (newValue) {
    initializeModels()
  }
})
</script>
