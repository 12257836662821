<template>
  <div class="w-full max-w-[944px] md:!px-12 xl:!px-0 mx-auto flex flex-col pt-5 desktop:!pt-10 pb-16 gap-6 bg-surface">
    <svn-pro-header-section
      :size="isMdScreen ? 'default' : 'compact'"
      :title="$t('My Team Interviews')"
      :subtitle="$t('Here are all the interviews you are in charge of.')"
    />

    <!-- Different tabs -->
    <div class="flex flex-col">
      <!-- Tabs -->
      <v-tabs v-model="tab" color="primary":grow="!isMdScreen" >
        <v-tab
          value="all"
          :text="$t('All') + ` (${campaignsPagi?.total_count || 0})`"
          class="w-fit"
        />

        <v-tab
          value="to_do"
          :text="$t('To do') + ` (${campaignsToDoPagi?.total_count || 0})`"
          class="w-fit"
        />

        <v-tab
          value="done"
          :text="$t('Done') + ` (${campaignsDonePagi?.total_count || 0})`"
          class="w-fit"
        />
      </v-tabs>

      <v-window v-model="tab" class="w-full">
        <v-window-item value="all">
          <MyTeamInterviewList
            v-if="campaigns"
            :tab="tab"
            :campaigns="campaigns"
            @pagination="load"
          />

          <BktFailToLoad v-else-if="error[0]" class="mt-16 max-w-md mx-auto" />

          <div v-else class="w-full flex h-[30vh] justify-center items-center">
            <svn-loader loading-size="lg" />
          </div>
        </v-window-item>

        <v-window-item value="to_do">
          <MyTeamInterviewList
            v-if="campaignsToDo"
            :tab="tab"
            :campaigns="campaignsToDo"
            @pagination="load"
          />

          <BktFailToLoad v-else-if="error[1]" class="mt-16 max-w-sm mx-auto" />

          <div v-else class="w-full flex h-[30vh] justify-center items-center">
            <svn-loader loading-size="lg" />
          </div>
        </v-window-item>

        <v-window-item value="done">
          <MyTeamInterviewList
            v-if="campaignsDone"
            :tab="tab"
            :campaigns="campaignsDone"
            @pagination="load"
          />

          <BktFailToLoad v-else-if="error[2]" class="mt-16 max-w-sm mx-auto" />

          <div v-else class="w-full flex h-[30vh] justify-center items-center">
            <svn-loader loading-size="lg" />
          </div>
        </v-window-item>
      </v-window>
    </div>

    <svn-pro-floating-action-button
      to-top
      size="small"
      color="primary"
      variant="tonal"
      :rounded="'lg'"
      class="fixed bottom-4 right-4 bg-white"
      icon="custom:mingcute:arrow-up-line"
    />
  </div>
</template>

<script setup>
import { storeToRefs } from "pinia";
import { onMounted, ref, computed } from "vue";
import { useMobileStore } from "@/store/mobile.js";
import BktFailToLoad from "@/components/BktFailToLoad.vue";
import { useMyTeamInterviewsStore } from "@/store/my-team-interviews.js";
import MyTeamInterviewList from "@/components/interviewApp/MyTeamInterviews/MyTeamInterviewList.vue";

const { isMdScreen } = storeToRefs(useMobileStore());
const { campaigns, campaignsPagi, campaignsToDo, campaignsToDoPagi, campaignsDone, campaignsDonePagi } = storeToRefs(useMyTeamInterviewsStore());

const { fetchMyTeamInterviews, fetchMyTeamInterviewsToDo, fetchMyTeamInterviewsDone } = useMyTeamInterviewsStore();

onMounted(async () => {
  campaigns.value = null
  campaignsToDo.value = null
  campaignsDone.value = null
  error.value[0] = ((await fetchMyTeamInterviews(1)) === "error")
  error.value[1] = ((await fetchMyTeamInterviewsToDo(1)) === "error")
  error.value[2] = ((await fetchMyTeamInterviewsDone(1)) === "error")
})

const tab = ref(null)
const pages = ref({
  all: 1,
  to_do: 1,
  done: 1,
})
const totalPages = computed(() => {
  return {
    all: campaignsPagi?.value?.total_pages,
    to_do: campaignsToDoPagi?.value?.total_pages,
    done: campaignsDonePagi?.value?.total_pages,
  }
})
const error = ref([false, false, false])
const tabs = ref({
  all: fetchMyTeamInterviews,
  to_do: fetchMyTeamInterviewsToDo,
  done: fetchMyTeamInterviewsDone,
})

const load = async (done) => {
  if (pages?.value?.[tab?.value] < totalPages?.value?.[tab?.value]) {
    try {
      await tabs?.value?.[tab?.value](pages?.value?.[tab?.value] + 1)

      done('ok')
      pages.value[tab?.value] += 1
    } catch (error) {
      done('error')
    }
  }
  else {
    done('empty')
  }
}
</script>