<template>
  <svn-pro-data-table
    v-model="selectedCampaigns"
    :headers="campaigns?.length ? headers : []"
    :items="campaigns ?? []"
    :items-per-page="-1"
    :loading="campaigns ? loading : true"
    item-value="id"
    :show-select="campaigns?.length ? true : false"
    :pagination-total-pages="pagination?.total_pages"
    @update-pagination="fetchNewPage"
  >
    <template #item="{ item }">
      <tr class="hover:bg-[#46464F14] active:bg-[#46464F1F]/[0.12]">
        <td class="px-2">

          <v-checkbox-btn
            v-model="selectedCampaigns"
            :value="item.id"
            @click.stop
            hide-details
          ></v-checkbox-btn>
        </td>

        <td>
          <svn-pro-data-table-cell
            medium
            body-medium
            cell-type="text"
            :text="item?.title"
          />
        </td>

        <td>
          <svn-pro-data-table-cell
            cell-type="text"
            :text="$filters.formatSpecificDate(item?.startline, 'YYYY-MM-DD', 'DD MMM, YYYY')"
          />
        </td>

        <td>
          <svn-pro-data-table-cell
            cell-type="text"
            :text="$filters.formatSpecificDate(item?.deadline, 'YYYY-MM-DD', 'DD MMM, YYYY')"
          />
        </td>

        <td class="flex justify-end">
          <svn-pro-data-table-cell
            regular
            body-medium
            cell-type="text"
            :text="item?.employees_count"
          />
        </td>
      </tr>
    </template>

    <template #no-data>
      <svn-pro-empty-states
        actions
        :variant="search ? 'results' : 'index'"
        :title="$t('Nothing to show for now')"
        :size="isMobile ? 'compact' : 'default'"
        prepend-primary="custom:mingcute:add-line"
        :supporting-text="$t('Oops, we didn’t find any results matching your search.')"
      />
    </template>

    <template #loading>
    </template>
  </svn-pro-data-table>
</template>

<script setup>
import { ref } from 'vue';
import i18n from '@/plugins/i18n';
import { storeToRefs } from "pinia";
import {useReportStore} from "@/store/reports.js";

const props = defineProps({
  search: { type: String, default: null },
  loading: { type: Boolean, required: true },
  campaigns: { type: Object, default: () => {} },
  pagination: {type: Object, default: () => { return null }},
})

const emit = defineEmits(['fetch-new-page-page']);

const { selectedCampaigns } = storeToRefs(useReportStore());

const headers = ref([
  {
    align: "start",
    key: "title",
    sortable: true,
    title: i18n.global.t('Campaign title'),
    minWidth: 300,
  },
  {
    align: "start",
    key: "startdate",
    sortable: false,
    title: i18n.global.t('Start date'),
    minWidth: 120,
  },
  {
    align: "start",
    key: "deadline",
    sortable: false,
    title: i18n.global.t('Deadline'),
    minWidth: 120,
  },
  {
    align: "end",
    key: "participants",
    sortable: false,
    title: i18n.global.t('Participants'),
    minWidth: 50,
  }
]);

const fetchNewPage = (page) => {
  emit('fetch-new-page', page)
};
</script>

<style scoped>

</style>
