<template>
  <div class="flex items-center gap-2">
    <svn-pro-info-tag
      v-if="props?.setInterview && props?.setInterview?.employee_interview"
      :tag-type="getTagTypeByCampaignStatus(props?.setInterview?.employee_interview?.status)"
      tag-style="filled"
      tag-size="compact"
      :text="$t('IE')"
    />

    <svn-pro-info-tag
      v-if="props?.setInterview && props?.setInterview?.manager_interview"
      :tag-type="getTagTypeByCampaignStatus(props?.setInterview?.manager_interview?.status)"
      tag-style="filled"
      tag-size="compact"
      :text="$t('IR')"
    />

    <svn-pro-info-tag
      v-if="props?.setInterview && props?.setInterview?.crossed_interview"
      :tag-type="getTagTypeByCampaignStatus(props?.setInterview?.crossed_interview?.status)"
      tag-style="filled"
      tag-size="compact"
      :text="$t('CR')"
    />
  </div>
</template>

<script setup>
import useCampaigns from "@/tools/useCampaigns.js";

const { getTagTypeByCampaignStatus } = useCampaigns();

const props = defineProps({
  setInterview: { type: Object, default: null },
});
</script>