import { defineStore } from "pinia";
import { genericFunctions, genericStates } from "@/tools/genericFunctions.js";
import axiosService from "@/tools/axios-service.js";

export const useRoadmapTargetStore = defineStore('roadmap_target', {
  state: () => ({
    ...genericStates,
    roadmapTargets: [],
    roadmapTrashesTargets: [],
    roadmapTarget: null,
    roadmapUserTargets: [],
    selectedUsers: [],
  }),
  actions: {
    ...genericFunctions,
    async fetchRoadmapTargets() {
      this.selectedUsers = []
      const roadmapTargetStore = useRoadmapTargetStore()

      await roadmapTargetStore.fetchEntity('/api/v1/roadmaps/targets', 'objective_elements', 'roadmapTargets')
    },

    async fetchRoadmapTrashesTarget() {
      const roadmapTargetStore = useRoadmapTargetStore()

      await roadmapTargetStore.fetchEntity('/api/v1/roadmaps/targets/trashes', 'objective_elements', 'roadmapTrashesTargets')
    },
    async fetchRoadmapTarget(id) {
      const roadmapTargetStore = useRoadmapTargetStore()

      await roadmapTargetStore.fetchEntity(`/api/v1/roadmaps/targets/${id}`, 'objective_element', 'roadmapTarget')
      return this.roadmapTarget
    },
    async fetchRoadmapTargetsByUserId(id, searchText = "", page = 1) {
      this.loading = true
      const roadmapTargetStore = useRoadmapTargetStore()

      this.params = {
        search: searchText,
        "page[number]": page
      }

      await roadmapTargetStore.fetchEntity(`/api/v1/users/${id}/targets`, 'objective_elements', 'roadmapUserTargets')

      this.loading = false

      return this.roadmapUserTargets

    },
    async fetchAdditionalRoadmapTargetsByUserId(id, searchText = "", page = 1) {
      const res = await axiosService.get(`/api/v1/users/${id}/targets`, {
        params: {
          search: searchText,
          "page[number]": page
        }
      })

      this.pagination = res.data.meta.pagination
      if (res.data.objective_elements.length) {
        this.roadmapUserTargets = [...this.roadmapUserTargets, ...res.data.objective_elements]
      }
      return this.roadmapUserTargets
    },
    async deleteRoadmapTarget(id) {
      const roadmapTargetStore = useRoadmapTargetStore()

      await roadmapTargetStore.deleteEntity(`/api/v1/roadmaps/targets/${id}`)
      const trash = this.roadmapTargets.find(trash => trash?.id === id)
      if (trash) {
        trash.deleted_at = new Date()
        this.roadmapTrashesTargets.push(trash)
      }
      this.roadmapTargets = this.roadmapTargets.filter(trash => trash?.id !== id)
    },
    async deletePermanentlyRoadmapTarget(id) {
      const roadmapTargetStore = useRoadmapTargetStore()

      await roadmapTargetStore.deleteEntity(`/api/v1/roadmaps/targets/${id}/destroy_permanently`)
      this.removeTemplatesFromTrashes(id)
    },
    async restoreRoadmapTarget(id) {
      const roadmapTargetStore = useRoadmapTargetStore()

      await roadmapTargetStore.updateEntity(`/api/v1/roadmaps/targets/${id}/restore`)
      this.roadmapTargets.push(this.roadmapTrashesTargets.find(trash => trash?.id === id))
      this.removeTemplatesFromTrashes(id)
    },
    removeTemplatesFromTrashes (id) {
      this.roadmapTrashesTargets = this.roadmapTrashesTargets.filter(trash => trash?.id !== id)
    }
  }
})
