<template>
  <div class="w-full flex flex-col gap-6 p-4 lg:!p-6 rounded-[12px] border border-borderColor border-opacity-100">
    <MyInterviewCardHeader
      :campaign-deadline="campaign.deadline"
      :campaign-type="campaign.campaign_type"
      :campaign-name="campaign.title"
    />

    <div class="flex flex-col lg:!flex-row lg:!justify-between lg:!gap-0 gap-4">
      <div class="flex gap-2 items-center">
        <svn-pro-avatar
          :size="40"
          :firstname="interviewee?.firstname"
          :image="interviewee?.avatar?.['100']"
          :type="interviewee?.avatar?.['100'] ? 'photo' : 'monogram'"
        />

        <div class="flex flex-col">
          <svn-pro-text subtitle-large medium>
            {{ interviewee?.fullname }}
          </svn-pro-text>

          <InterviewStatusChip :status="status" />
        </div>
      </div>

      <div class="flex flex-col lg:!items-center lg:!flex-row gap-3">
        <pop-up-do-not-participate-survey v-if="status !== 'submitted'" :id="id">
          <template #activator="{ props }">
            <svn-pro-button
              v-bind="props"
              variant="outlined"
              :text="$t('Decline survey')"
            />
          </template>
        </pop-up-do-not-participate-survey>

        <svn-pro-button
          :variant="buttonVariant()"
          :prepend-icon="status === 'not_started' || status === 'in_progress' ? 'custom:mingcute:arrow-right-line' : ''"
          :text="$t(buttonSentence())"
          @click="clickInterview"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import router from "@/router/index.js";
import { usePopUpStore } from "@/store/pop-up.js";
import InterviewStatusChip from "@/components/interviewApp/InterviewStatusChip.vue";
import MyInterviewCardHeader from "@/components/interviewApp/InterviewCardHeader.vue";
import PopUpDoNotParticipateSurvey from "../../popUpComponents/PopUpDoNotParticipateSurvey.vue";

const props = defineProps({
  id: { type: Number, required: true },
  status: { type: String, required: true },
  campaign: { type: Object, required: true },
  interviewee: { type: Object, required: true },
})

const { openPopUp } = usePopUpStore()

const buttonSentence = () => {
  switch (props.status) {
    case "submitted":
      return "View my answers"
    case "in_progress":
      return "Continue my survey"
    case "not_started":
      return "Start survey"
    default:
      return ""
  }
}

const buttonVariant = () => {
  switch (props.status) {
    case "submitted":
      return "outlined"
    case "in_progress":
      return "flat"
    case "not_started":
      return "flat"
    default:
      return "plain"
  }
}

const clickInterview = () => {
  router.push({name:"interview_answer", params: { id: props.id }})
}
</script>