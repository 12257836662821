<template>
  <template-header-question
    :id="interviewQuestion.id"
    :is-deleted="isDeleted"
  >
    <template #title>
      <svn-pro-info-tag
        :text="$t('Multi-choice')"
        tag-type="primary"
        tag-style="filled"
        class="max-w-[900px] h-[32px] justify-self-start"
        leading-item="icon"
        icon="mingcute:checkbox-line"
      />
    </template>
    <template #body>
      <div class="flex flex-col gap-4 pt-3">
        <svn-pro-text-field
          v-model="interviewQuestion.text"
          :label="$t('Question')"
          variant="outlined"
          :disabled="isDeleted"
          @update:model-value="debounceUpdateQuestion(interviewQuestion.id, {text: $event})"
        />

        <div class="h-40 mb-16">
          <quill-editor
            v-model:content="interviewQuestion.description"
            content-type="html"
            :placeholder="$t('Description')"
            :enable="!isDeleted"
            @update:content="debounceUpdateQuestion(interviewQuestion.id, {description: $event})"
          />
        </div>
      </div>

      <div class="pb-6 flex flex-col gap-3">
        <div class="flex flex-col gap-3">
          <svn-pro-text subtitle-large medium color="onSurfaceVariant" class="text-start w-[260px]">
            {{ $t('Answer options') }}
          </svn-pro-text>

          <div class="flex flex-col justify-start items-start gap-4">
            <svn-pro-text-field
              v-for="(opt, idx) in options"
              :key="idx"
              v-model="opt.value"
              class="w-full"
              :label="`Option ${getAlphabetFromIndex(idx)}`"
              variant="outlined"
              append
              :disabled="isDeleted"
              @blur="beforeUpdateQuestionOptions(interviewQuestion.id, opt.position, { value: $event.target.value }, idx)"
            >
              <template #append>
                <svn-pro-icon-button
                  v-if="options.length > 1 "
                  @click="removeOption(idx, interviewQuestion.id, opt.position)"
                  variant="text"
                  color="onSurfaceVariant"
                  icon="custom:mingcute:delete-2-line"
                />
              </template>
            </svn-pro-text-field>
          </div>
        </div>

        <div class="flex justify-between flex-wrap">
          <svn-pro-button
            variant="text"
            :text="$t('Add answer option')"
            class="w-fit transition-all"
            :prepend-icon="'custom:mingcute:add-line'"
            @click="createOption"
          />
        </div>
      </div>

      <template-question-visible-required-for
        v-model:visible-for="visibleFor"
        v-model:allow-comments="interviewQuestion.allow_comments"
        v-model:required-for="requiredFor"
        :show-allow-comments="true"
        :is-deleted="isDeleted"
        :question-id="interviewQuestion.id"
        @update:visible-for-formated="debounceUpdateQuestion(interviewQuestion.id, {visible_for: $event})"
        @update:required-for-formated="debounceUpdateQuestion(interviewQuestion.id, {required_for: $event})"
        @update:allow-comment="debounceUpdateQuestion(interviewQuestion.id, {allow_comments: $event})"
      />
    </template>
  </template-header-question>
</template>

<script setup>
import {ref, watch} from "vue";
import TemplateHeaderQuestion from "@/components/interviewApp/template/Edit/TemplateHeaderQuestion.vue";
import TemplateQuestionVisibleRequiredFor
from "@/components/interviewApp/template/Edit/TemplateQuestionVisibleRequiredFor.vue";
import {useInterviewFormStore} from "@/store/interview-form.js";
import useTools from "@/tools/useTools.js";
import { useSnackbar } from "@/store/snackbar.js";
import {QuillEditor} from "@vueup/vue-quill";
import { debounce} from "lodash";

const props = defineProps({
  interviewQuestion: { type: Object, required: true },
  isDeleted: { type: Boolean, required: true }
})

const {updateQuestion, updateQuestionOptions, deleteQuestionOptions, createQuestionOptions } = useInterviewFormStore()
const tools = useTools()
const { setBgColor, setMsg, displaySnackBar } = useSnackbar()

const visibleFor = ref(tools.deduceForWhom(props.interviewQuestion.visible_for))
const requiredFor = ref(tools.deduceForWhom(props.interviewQuestion.required_for))

const removeOption = (idx, id, position) => {
  options.value.splice(idx, 1)
  deleteQuestionOptions(id, position)
}

const getAlphabetFromIndex = (index) => {
  const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  if (index >= 0 && index < alphabet.length) {
    return alphabet[index];
  } else {
    return ''; // Return null if the index is out of bounds
  }
}

const beforeUpdateQuestionOptions = (questionId, optionPos, params, idx) => {
  if (options.value.filter(option => option.value === params.value && optionPos !== option.position).length !== 0) {
    removeOption(idx, questionId, optionPos)
    setBgColor('negativeRed')
    setMsg('There can\'t be two identical options!')
    displaySnackBar()
  } else {
    updateQuestionOptions(questionId, optionPos, params)
  }
}

const createOption = () => {
  if (options.value && options.value.length > 0) {
    var maxPos = getMaxPosition(options.value);
    options.value.push({ value: `Option ${maxPos + 1}`, position: maxPos + 1 });
    createQuestionOptions(props.interviewQuestion.id, maxPos + 1);
  } else {
    options.value.push({ value: 'Option 1', position: 1 });
    createQuestionOptions(props.interviewQuestion.id, 1);
  }
};

const getMaxPosition = (options) => {
  return Math.max(...options.map(option => option.position));
};
const options = ref(props.interviewQuestion.options)

const debounceUpdateQuestion = debounce(async(id, data) => {
  await updateQuestion(id, data)
}, 300)

watch(requiredFor, (newVal, oldVal) => {
  for (const newValKey in newVal) {
    if (!visibleFor.value.includes(newVal[newValKey])) {
      visibleFor.value.push(newVal[newValKey])

      debounceUpdateQuestion(props.interviewQuestion.id, {visible_for: tools.deduceToBackend(visibleFor.value)})
    }
  }
})

watch(visibleFor, (newVal, oldVal) => {
  for (const newValKey in requiredFor.value) {
    if (!newVal.includes(requiredFor.value[newValKey])) {
      const index = requiredFor.value.indexOf(requiredFor.value[newValKey])
      requiredFor.value.splice(index, 1)

      debounceUpdateQuestion(props.interviewQuestion.id, {required_for: tools.deduceToBackend(requiredFor.value)})
    }
  }
})
</script>
