<template>
  <dialog-skeleton
    ref="dialogRef"
    :icon="icon"
    :title="title"
    :bind="bind"
    :subtitle="subtitle"
    :description="description"
    :display-close="displayClose"
    :vertical="isMobile ? true : false"
    :button-primary-color="buttonPrimaryColor"
    :width="isMobile ? 300 : 400"
    :display-secondary-button="displaySecondaryButton"
    :primary-button-text="primaryButtonText"
    :secondary-button-text="secondaryButtonText"
    @click-primary-button="clickPrimaryButton"
    @click-secondary-button="clickSecondaryButton"
  >
    <template #button>
      <slot name="button" />
    </template>

    <template
      v-if="displayClose"
      #close="{ close }"
    >
      <slot
        name="close"
        :close="close"
      />
    </template>
  </dialog-skeleton>
</template>

<script setup>
import { ref } from "vue";
import { storeToRefs } from "pinia";
import { useMobileStore } from "@/store/mobile";
import DialogSkeleton from "@/components/BktPopUp/skeleton/BktPopUpDialogSkeleton.vue"
import { Icon } from '@iconify/vue';


const props = defineProps({
  icon: { type: String, default: '' },
  title: { type: String, default: '' },
  subtitle: { type: String, default: '' },
  description: { type: String, default: '' },
  secondaryButtonText: { type: String, default: '' },
  displaySecondaryButton: { type: Boolean, default: true },
  displayClose: { type: Boolean, default: true },
  bind: { type: Boolean, default: true },
  vertical: { type: Boolean, default: false },
  primaryButtonText: { type: String, default: '' },
  buttonPrimaryColor: { type: String, default: '' },
})

const emit = defineEmits(['click-primary-button', 'click-secondary-button'])

const { isMobile } = storeToRefs(useMobileStore())

const dialogRef = ref(null)

const clickPrimaryButton = () => {
  dialogRef.value.dialog = false

  emit('click-primary-button')
}

const clickSecondaryButton = () => {
  dialogRef.value.dialog = false

  emit('click-secondary-button')
}
</script>
