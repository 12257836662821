<template>
  <div class="w-full h-full flex flex-col px-5 py-5 lg:!px-0">
    <div class="flex flex-col items-start gap-6 self-stretch">
      <!-- Global Informations -->
      <div class="flex flex-col items-start self-stretch" :class="isEditingCompanyName ? 'gap-3' : 'gap-1'">
        <svn-pro-title h6 medium>
          {{ $t('Company name') }}
        </svn-pro-title>

        <div v-if="isEditingCompanyName" class="w-full max-w-[464px] flex flex-col gap-6">
          <svn-pro-text-field
            v-model="companyName"
            variant="outlined"
            :label="$t('Company name*')"
            class="w-full"
            :error="companyNameError.error"
            :error-messages="companyNameError.errorMessage"
          />

          <div v-if="!isMobile" class="flex gap-2">
            <svn-pro-button
              :text="$t('Cancel')"
              variant="outlined"
              @click="cancelEditCompanyName"
            />

            <svn-pro-button
              :text="$t('Save')"
              variant="flat"
              @click="updateName"
            />
          </div>
        </div>

        <div v-else class="w-full flex items-center gap-2">
          <svn-pro-text subtitle-large regular color="onSurfaceVariant">
            {{ companyName }}
          </svn-pro-text>

          <svn-pro-button
            :text="$t('Edit')"
            variant="text"
            prepend-icon="custom:mingcute:edit-2-line"
            @click="toggleEditMode"
          />
        </div>
      </div>

      <!-- Shared Informations -->
      <div class="flex flex-col items-start gap-4 self-stretch">
        <div class="w-full flex flex-col gap-2">
          <svn-pro-title h6 medium>
            {{ $t('Shared Information') }}
          </svn-pro-title>

          <svn-pro-text subtitle-medium regular color="onSurfaceVariant">
            {{ $t('Links you put as shared information will show up on the homepage and be accessible to all users. Use it to share guidelines or global information to your employees.') }}
          </svn-pro-text>
        </div>

        <!-- Information Blocks -->
        <div v-if="informationBlocks?.length" class="w-full gap-4 grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3">
          <shared-information-card
            v-for="(block, index) in informationBlocks"
            :key="index"
            :block="block"
            @update-information-block="updateInformationBlock($event, index)"
            @delete-information-block="deleteInformationBlock(index)"
          />
        </div>

        <!-- Add information block -->
        <svn-pro-modal
          ref="newBlockModal"
          :title="$t('Shared information')"
          :actionOneTitle="$t('Save')"
          :primary-loading="loading"
          @click-primary-button="saveInformationBlock"
        >
          <template #activator="{ props }">
            <svn-pro-button
              v-bind="props"
              :text="$t('Add information')"
              variant="flat"
              :block="isMobile"
              prepend-icon="custom:mingcute:add-line"
            />
          </template>

          <template #text>
            <div class="w-full flex flex-col gap-10">
              <!-- Title -->
              <svn-pro-text-field
                v-model="linkTitle"
                :label="$t('Title*')"
                variant="outlined"
                :error="linkTitleError.error"
                :error-messages="linkTitleError.errorMessage"
              />

              <!-- Link -->
              <svn-pro-text-field
                v-model="linkValue"
                :label="$t('Link*')"
                variant="outlined"
                :error="linkValueError.error"
                :error-messages="linkValueError.errorMessage"
              />
            </div>
          </template>
        </svn-pro-modal>
      </div>
    </div>
  </div>

  <!-- Fixed buttons when you update the company name -->
  <svn-pro-button-sticky-container
    v-if="isEditingCompanyName && isMobile"
    :primary-action-text="$t('Save')"
    :secondary-action-text="$t('Cancel')"
    @click-secondary-action="cancelEditCompanyName"
    @click-primary-action="updateName"
  />
</template>

<script setup>
import { ref, watch, onMounted } from 'vue';
import { useSnackbar } from '@/store/snackbar';
import { useMobileStore } from '@/store/mobile';
import { storeToRefs } from 'pinia';
import { useCompanyStore } from '@/store/company';
import { useRoute } from 'vue-router';
import SharedInformationCard from './SharedInformationCard.vue';

onMounted(() => {
  companyName.value = name.value
  informationBlocks.value = links.value
});

const props = defineProps({
  companyLinks: { type: Array, default: null }
});

const { isMobile } = storeToRefs(useMobileStore());
const { name, links } = storeToRefs(useCompanyStore());

const { updateCompanyLinks, updateCompanyName } = useCompanyStore();

const companyName = ref(null)
const isEditingCompanyName = ref(false)
const snackbar = useSnackbar()
const route = useRoute()
const informationBlocks = ref([])
const linkTitle = ref(null)
const linkValue = ref(null)
const newBlocks = ref([])
const loading = ref(false)
const newBlockModal = ref(false)
const companyNameError = ref({ error: false, errorMessage: '' });
const linkTitleError = ref({ error: false, errorMessage: '' });
const linkValueError = ref({ error: false, errorMessage: '' });

const saveInformationBlock = async() => {
  loading.value = true;
  if (!linkTitle.value || !linkValue.value) {
    if (!linkTitle.value) {
      linkTitleError.value = { error: true, errorMessage: "*required" }
    }
    if (!linkValue.value) {
      linkValueError.value = { error: true, errorMessage: "*required" }
    }
  } else {
    try {
      if (linkTitle.value && linkValue.value) {
        newBlocks.value = await updateCompanyLinks([
          ...informationBlocks.value,
          {
            title: linkTitle.value,
            link: linkValue.value,
          }
        ])
        snackbarSavedInformationBlock()
        informationBlocks.value = newBlocks.value
        linkTitle.value = ""
        linkValue.value = ""
        newBlockModal.value.dialog = false
      }
    } catch (error) {
      console.log(error)
    }
  }
  loading.value = false
}

const updateInformationBlock = async(event, index) => {
  try {
    const found = informationBlocks?.value?.[index]
    if (found) {
      found.title = event?.title ? event?.title : found.title
      found.link = event.link ? event.link : found.link
      newBlocks.value = await updateCompanyLinks(informationBlocks.value)
      snackbarSavedInformationBlock()
      informationBlocks.value = newBlocks.value
      linkTitle.value = null
      linkValue.value = null
    }
  } catch (error) {
    console.log(error)
  }
}

const deleteInformationBlock = async(index) => {
  if (index >= 0) {
    informationBlocks.value.splice(index, 1)
    try {
      await updateCompanyLinks(informationBlocks.value)
      snackbar.setBgColor('onSurface')
      snackbar.setMsg('Shared information has been deleted.')
      snackbar.setCustomClass('mb-5')
      snackbar.displaySnackBar()
    } catch (error) {
      snackbar.setBgColor('error')
      snackbar.setMsg('Error while deleting shared information.')
      snackbar.setCustomClass('mb-5')
      snackbar.displaySnackBar()
    }
  }
  else {
    linkTitle.value = null
    linkValue.value = null
  }
}

const snackbarSavedInformationBlock = () => {
  snackbar.setBgColor('onSurface')
  snackbar.setMsg('Shared information has been saved.')
  snackbar.setCustomClass('mb-5')
  snackbar.displaySnackBar()
}

const cancelEditCompanyName = () => {
  companyName.value = name.value
  isEditingCompanyName.value = false
}

const updateName = () => {
  if (!companyName.value) {
    companyNameError.value = { error: true, errorMessage: "You must set a Company name." }
  } else {
    try {
      updateCompanyName(companyName.value).then((res) => {
        name.value = res
      })
      isEditingCompanyName.value = false
      snackbar.setBgColor('onSurface')
      snackbar.setMsg('Company name updated.')
      snackbar.setCustomClass('mb-5')
      snackbar.displaySnackBar()
    } catch (error) {
      snackbar.setBgColor('error')
      snackbar.setMsg('Error updating Company name.')
      snackbar.setCustomClass('mb-5')
      snackbar.displaySnackBar()
    }
  }
}

const setBlocks = () => {
  informationBlocks.value = informationBlocks.value.map((link) => {
    return { ...link }
  })
}

const toggleEditMode = () => {
  isEditingCompanyName.value = !isEditingCompanyName.value
}

watch(route, () => {
  setBlocks()
});

watch(companyName, () => {
  if (companyName.value !== name.value) {
    isEditingCompanyName.value = true
  }
});

watch(companyName, (newValue, oldValue) => {
  if (companyName.value && companyNameError.value.error) {
    companyNameError.value = { error: false, errorMessage: "" }
  }
});

watch(linkTitle, (newValue, oldValue) => {
  if (linkTitle.value && linkTitleError.value.error) {
    linkTitleError.value = { error: false, errorMessage: "" }
  }
});

watch(linkValue, (newValue, oldValue) => {
  if (linkValue.value && linkValueError.value.error) {
    linkValueError.value = { error: false, errorMessage: "" }
  }
});
</script>

<style scoped>
.input :deep(.v-field) {
  border-radius: 8px;
}
</style>