import { createRouter, createWebHistory } from 'vue-router';
import { useSnackbar } from '@/store/snackbar.js';
import Home from '@/views/Home.vue';
import Campaigns from '../views/interviews/campaigns/index.vue';
import CampaignShow from '../views/interviews/campaigns/Show.vue';
import CampaignDraft from '../views/interviews/campaignDraft/index.vue';
import Templates from '@/views/interviews/templates/index.vue';
import Reports from '@/views/interviews/reports/index.vue';
import EditReport from '@/views/interviews/reports/edit.vue';
import HomeLayout from '../layouts/HomeLayout.vue';
import InterviewsLayout from '../layouts/InterviewsLayout.vue';
import CompanyLayout from '../layouts/CompanyLayout.vue';
import MyCompany from '@/views/company/MyCompany.vue';
import MyInterviews from '../views/interviews/MyInterviews.vue';
import MyTeamInterviews from '../views/interviews/MyTeamInterviews.vue';
import NotFound from '../views/404.vue';
import InternalError from '@/views/500.vue';
import RoadmapsLayout from '@/layouts/roadmap/RoadmapsLayout.vue';
import MyRoadmaps from '@/views/roadmaps/MyRoadmaps.vue';
import MyTeamRoadmaps from '@/views/roadmaps/MyTeamRoadmaps.vue';
import RoadmapsTarget from '@/views/roadmaps/Targets/Index.vue';
import RoadmapsEmployees from '@/views/roadmaps/Employees/Index.vue';
import RoadmapsTemplatesIndex from '@/views/roadmaps/Templates/Index.vue';
import RoadmapsTemplatesNew from '@/views/roadmaps/Templates/New.vue';
import RoadmapsTargetNew from '@/views/roadmaps/Targets/New.vue';
import RoadmapsTargetShow from '@/views/roadmaps/Targets/Show.vue';
import GenericRouterView from '@/layouts/GenericRouterView.vue';
import InterviewsTemplateEdit from '@/views/interviews/templates/Edit.vue';
import InterviewAnswer from '@/views/interviews/interview_answer/Edit.vue';
import FeedbackAnswer from '@/views/interviews/feedback_answer/Show.vue';
import SurveyAnswer from '@/views/interviews/survey_answer/Show.vue';
import EmployeeShow from '../views/employees/Show.vue';
import PeopleIndex from '@/views/employees/Index.vue';
import Check from '@/views/sessions/Check.vue';
import SignIn from '@/views/sessions/SignIn.vue';
import EmailSentReset from '@/views/sessions/EmailSentReset.vue';
import EmailSentFirst from '@/views/sessions/EmailSentFirst.vue';
import EmailNotFound from '@/views/sessions/NotFound.vue';
import ResetPassword from '@/views/sessions/ResetPassword.vue';
import RequestDemo from '@/views/sessions/RequestDemo.vue';
import SessionsLayout from '@/layouts/SessionsLayout.vue';
import axiosService from '@/tools/axios-service.js';
import { useCompanyStore } from '@/store/company.js';
import { useUserStore } from '@/store/user.js';
import { storeToRefs } from 'pinia';
import i18n from '@/plugins/i18n.js';
import LearnsLayout from '../layouts/LearnsLayout.vue';
import MyLearnings from '@/views/learns/MyLearnings.vue';
import MyTrainings from '@/views/learns/MyTrainings.vue';
import NewTraining from '@/views/learns/training/New.vue';
import EditTraining from '@/views/learns/training/Edit.vue';
import ModuleCreate from '@/views/learns/catalog/content/module/Create.vue';
import ModuleEdit from '@/views/learns/catalog/content/module/Edit.vue';
import Catalog from '@/views/learns/catalog/Catalog.vue';
import ModuleShow from '@/views/learns/catalog/content/module/Show.vue';
import PlaylistShowEdit from '@/views/learns/catalog/content/playlist/Show.vue';
import TrainingShowEdit from '@/views/learns/training/Show.vue';
import { useActionCable } from '@/store/cable.js';
import PdfLayout from '@/layouts/PdfLayout.vue';
import BktOneToOnePdf from '@/components/pdf/interviews/oneToOneAnswer/BktOneToOnePdf.vue';
import BktFeedbackPdf from '@/components/pdf/interviews/feedbackAnswer/BktFeedbackPdf.vue';
import FaceToFaceAnswerPdf from '@/components/pdf/learns/faceToFaceAnswer/FaceToFaceAnswerPdf.vue';
import NotAuthorized from '@/views/NotAuthorized.vue';
import { useLearnModuleStore } from '@/store/learn-module.js';
import { useLearnTrainingStore } from '@/store/learn-trainings.js';
import { useFilterStore } from '@/store/filters';
import PeopleLayout from '@/layouts/PeopleLayout.vue';
import { useAppRouter } from '@/store/app-router';
import PrivacyPolicy from '../views/PrivacyPolicy.vue';
import QuizEdit from '@/views/learns/quiz/QuizEdit.vue';
import FaceToFaceEdit from '../views/learns/facetoface/FaceToFaceEdit.vue';
import QuizResult from '../views/learns/quiz/QuizResult.vue';

function protectWithAdmin(to, from) {
  const { isAdminOrAbove } = useUserStore();

  if (!isAdminOrAbove()) return '/not-auth';
}

function protectWithManagerCreator() {
  const { isManagerCreatorOrAbove } = useUserStore();

  if (!isManagerCreatorOrAbove()) return '/not-auth';
}

async function protectModuleEdit(to, from) {
  const { isAdminOrAbove, isManagerCreatorOrAbove } = useUserStore();
  const { fetchModule } = useLearnModuleStore();
  const { learnModule } = storeToRefs(useLearnModuleStore());
  const { id } = storeToRefs(useUserStore());

  if (isAdminOrAbove()) return true;

  await fetchModule(to?.params?.id);
  if (isManagerCreatorOrAbove() && learnModule.value.creator_id == id.value)
    return true;
  else return '/not-auth';
}

async function protectTrainingEdit(to, from) {
  const { isAdminOrAbove, isManagerCreatorOrAbove } = useUserStore();
  const { fetchTraining } = useLearnTrainingStore();
  const { learnTraining } = storeToRefs(useLearnTrainingStore());
  const { id } = storeToRefs(useUserStore());

  if (isAdminOrAbove()) return true;

  await fetchTraining(to?.params?.id);
  if (isManagerCreatorOrAbove() && learnTraining.value.creator_id == id.value)
    return true;
  else return '/not-auth';
}

const routes = [
  {
    path: '/auth',
    component: SessionsLayout,
    children: [
      { path: '', redirect: '/auth/check' },
      { path: 'check', name: 'check', component: Check },
      {
        path: 'email_not_found',
        name: 'email_not_found',
        component: EmailNotFound,
      },
      { path: 'request_demo', name: 'request_demo', component: RequestDemo },
      { path: 'sign_in', name: 'sign_in', component: SignIn },
      {
        path: 'reset_password',
        name: 'reset_password',
        component: ResetPassword,
      },
      {
        path: 'email_sent_reset',
        name: 'email_sent_reset',
        component: EmailSentReset,
      },
      {
        path: 'email_sent_first',
        name: 'email_sent_first',
        component: EmailSentFirst,
      },
      // Fake component in order to avoid a 404 while we're in router.beforeEach
      { path: 'check-google-creds', name: 'check-google-creds', component: {} },
    ],
  },
  {
    path: '/',
    redirect: '/home',
  },
  {
    path: '/home',
    component: HomeLayout,
    children: [{ path: '', name: 'home', component: Home }],
  },
  {
    path: '/not-auth',
    component: HomeLayout,
    children: [{ path: '', name: 'not-auth', component: NotAuthorized }],
  },
  // Old interview link not valid anymore since interview refoctor but we keep it
  // in order to be compatible with older mails
  {
    path: '/interviews/campaigns/:id/overview',
    redirect: '/home',
  },
  {
    path: '/interviews/campaigns/:id',
    redirect: '/home',
  },
  {
    path: '/interviews/interview_answer/:id',
    redirect: '/home',
  },
  {
    path: '/interviews/my_team_interviews',
    redirect: '/home',
  },
  {
    path: '/interviews/my_interviews',
    redirect: '/home',
  },
  {
    path: '/interviews',
    redirect: '/home',
  },
  {
    path: '/interviews/campaigns',
    redirect: '/home',
  },
  {
    path: '/interviews/templates',
    redirect: '/home',
  },
  {
    path: '/interviews/templates/index',
    redirect: '/home',
  },
  {
    path: '/interviews/templates/:id',
    redirect: '/home',
  },
  {
    path: '/interviews/campaign_draft',
    redirect: '/home',
  },
  {
    path: '/interviews/reports/edit',
    redirect: '/home',
  },
  {
    path: '/interviews/reports',
    redirect: '/home',
  },
  {
    path: '/interviews/feedback_answer/:id',
    redirect: '/home',
  },
  {
    path: '/interviews/survey_answer/:id',
    redirect: '/home',
  },
  // new path for interview v2
  {
    path: '/v2',
    children: [
      {
        path: 'interviews',
        component: InterviewsLayout,
        children: [
          { path: '', redirect: '/interviews/my_interviews' },
          {
            path: 'my_interviews',
            name: 'my_interviews',
            component: MyInterviews,
          },
          {
            path: 'my_team_interviews',
            name: 'my_team_interviews',
            component: MyTeamInterviews,
          },
          { path: 'campaigns', name: 'campaigns', component: Campaigns },
          {
            path: 'campaigns/:id',
            name: 'campaign_show',
            component: CampaignShow,
          },
          {
            path: 'campaigns/:id/overview',
            name: 'campaign_overview',
            component: CampaignShow,
            props: { overview: 'overview' },
          },
          {
            path: 'templates',
            component: GenericRouterView,
            children: [
              { path: '', redirect: '/interviews/templates/index' },
              { path: 'index', name: 'interview_forms', component: Templates },
              {
                path: ':id/edit',
                name: 'interviews_template_edit',
                component: InterviewsTemplateEdit,
              },
            ],
          },
          {
            path: 'campaign_draft',
            name: 'campaign_draft',
            component: CampaignDraft,
          },
          {
            path: 'reports/edit',
            name: 'edit_report',
            component: EditReport,
            beforeEnter: protectWithAdmin,
          },
          { path: 'reports', name: 'reports', component: Reports },
          {
            path: 'interview_answer/:id',
            name: 'interview_answer',
            component: InterviewAnswer,
          },
          {
            path: 'feedback_answer/:id',
            name: 'feedback_answer',
            component: FeedbackAnswer,
          },
          {
            path: 'survey_answer/:id',
            name: 'survey_answer',
            component: SurveyAnswer,
          },
        ],
      },
    ],
  },
  {
    path: '/company',
    component: CompanyLayout,
    children: [
      {
        path: '',
        name: 'company',
        component: MyCompany,
        beforeEnter: protectWithAdmin,
      },
    ],
  },
  {
    path: '/people',
    component: PeopleLayout,
    children: [
      {
        path: 'index',
        name: 'people_index',
        component: PeopleIndex,
        beforeEnter: protectWithAdmin,
      },
      { path: 'user_show/:id', name: 'people_show', component: EmployeeShow },
    ],
  },
  {
    path: '/pdf',
    component: PdfLayout,
    children: [
      {
        path: 'interview_answer/:id',
        name: 'pdf_interview_answer',
        component: BktOneToOnePdf,
      },
      {
        path: 'feedback_answer/:id',
        name: 'pdf_feedback_answer',
        component: BktFeedbackPdf,
      },
      {
        path: 'face_to_face_answer/:id/:editor_id',
        name: 'pdf_face_to_face_answer',
        component: FaceToFaceAnswerPdf,
      },
    ],
  },
  {
    path: '/roadmaps',
    component: RoadmapsLayout,
    children: [
      { path: '', redirect: '/roadmaps/my_roadmaps' },
      { path: 'my_roadmaps', name: 'my_roadmaps', component: MyRoadmaps },
      {
        path: 'my_roadmaps/:employee_id',
        name: 'his_roadmaps',
        component: MyRoadmaps,
      },
      {
        path: 'my_team_roadmaps',
        name: 'my_team_roadmaps',
        component: MyTeamRoadmaps,
      },
      {
        path: 'my_team_roadmaps/:employee_id',
        name: 'his_team_roadmaps',
        component: MyTeamRoadmaps,
      },
      {
        path: 'targets',
        component: GenericRouterView,
        children: [
          { path: '', redirect: '/roadmaps/targets/index' },
          {
            path: 'index',
            name: 'roadmaps_targets',
            component: RoadmapsTarget,
          },
          {
            path: 'new',
            name: 'roadmaps_targets_new',
            component: RoadmapsTargetNew,
            props: (route) => ({
              usersFromOverlay: route.params.usersFromOverlay,
              objectiveTemplateId: route.params.objectiveTemplateId,
            }),
          },
          {
            path: ':id',
            name: 'roadmaps_targets_show',
            component: RoadmapsTargetShow,
          },
        ],
      },
      {
        path: 'employees',
        component: GenericRouterView,
        children: [
          { path: '', redirect: '/roadmaps/employees/index' },
          {
            path: 'index',
            name: 'roadmaps_employees',
            component: RoadmapsEmployees,
          },
        ],
      },
      {
        path: 'templates',
        component: GenericRouterView,
        children: [
          { path: '', redirect: '/roadmaps/templates/index' },
          {
            path: 'index',
            name: 'roadmaps_templates',
            component: RoadmapsTemplatesIndex,
          },
          {
            path: 'new',
            name: 'roadmaps_templates_new',
            component: RoadmapsTemplatesNew,
          },
          {
            path: ':id',
            name: 'roadmaps_templates_show',
            component: RoadmapsTemplatesNew,
          },
        ],
      },
    ],
  },
  {
    path: '/compliance',
    component: SessionsLayout,
    children: [
      {
        path: 'privacy_policy',
        name: 'privacy_policy',
        component: PrivacyPolicy,
      },
    ],
  },
  {
    path: '/learns',
    component: LearnsLayout,
    children: [
      { path: '', redirect: '/learns/my_learnings' },
      { path: 'my_learnings', name: 'my_learnings', component: MyLearnings },
      { path: 'trainings', name: 'trainings', component: MyTrainings },
      {
        path: 'training/:id/edit',
        name: 'training_edit',
        component: EditTraining,
        beforeEnter: protectTrainingEdit,
      },
      {
        path: 'training/new',
        name: 'training_new',
        component: NewTraining,
        beforeEnter: protectWithManagerCreator,
      },
      {
        path: 'training/:id/show',
        name: 'training_show',
        component: TrainingShowEdit,
      },
      {
        path: 'module/new',
        name: 'module_new',
        component: ModuleCreate,
        beforeEnter: protectWithManagerCreator,
      },
      { path: 'module/:id/show', name: 'module_show', component: ModuleShow },
      {
        path: 'module/:id/edit',
        name: 'module_edit',
        component: ModuleEdit,
        beforeEnter: protectModuleEdit,
      },
      {
        path: 'playlist/:id/show',
        name: 'playlist_show',
        component: PlaylistShowEdit,
      },
      { path: 'catalog', name: 'catalog', component: Catalog },
      { path: 'quiz/:id/edit', name: 'quiz_edit', component: QuizEdit },
      {
        path: 'face_to_face/:id/edit',
        name: 'face_to_face_edit',
        component: FaceToFaceEdit,
      },
      { path: 'quiz/:id/result', name: 'quiz_result', component: QuizResult },
    ],
  },
  { path: '/404', name: 'not_found', component: NotFound },
  { path: '/500', name: 'internal_error', component: InternalError },
  { path: '/:catchAll(.*)', redirect: '/404' },
];

let redirectUrl = null;
let fromException = false;

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      setTimeout(() => {
        const el = document.getElementById(to.hash.split('#')[1]);

        if (el) el.scrollIntoView({ behavior: 'smooth' });
      }, 500);
    } else if (savedPosition) {
      return savedPosition;
    } else {
      const app = document.getElementById('app');

      if (app) app.scrollIntoView({ behavior: 'smooth' });
    }
  },
});

router.beforeEach(async (to, from, next) => {
  const { resetStates } = useFilterStore();

  try {
    let validated = useUserStore().isAuthenticated();
    // Compliance variable to add to all routes
    let isComplianceUrl = to.path.split('/').includes('compliance');

    if (
      !to.path.split('/').includes('auth') &&
      !validated &&
      !redirectUrl &&
      !isComplianceUrl
    ) {
      const { setBktRedirectPath } = useAppRouter();

      redirectUrl = to.fullPath;
      setBktRedirectPath(redirectUrl);
    }

    if (
      !to.path.split('/').includes('auth') &&
      !validated &&
      !fromException &&
      !isComplianceUrl
    ) {
      await initAndFetchData(); // may throw exception
    } else if (
      to.path === '/auth/check-google-creds' &&
      !validated &&
      !fromException &&
      !isComplianceUrl
    ) {
      const auth = JSON.parse(to.query?.creds);
      const googleState = to.query?.state;

      localStorage.setItem('bktAccess', JSON.stringify(auth));
      await initAndFetchData(); // may throw exception
      return next(googleState ?? { name: 'home' });
    }

    validated = useUserStore().isAuthenticated();
    if (
      (!to.path.split('/').includes('auth') && validated) ||
      fromException ||
      (to.path.split('/').includes('auth') && !validated && !isComplianceUrl)
    ) {
      fromException = false;

      if (!to.path.split('/').includes('auth') && validated && redirectUrl) {
        // Handle redirection
        const tmp = redirectUrl;
        redirectUrl = null;
        next(tmp);
      } else {
        resetStates();
        next();
      }
      // For accessing compliance route while no being logged in
    } else if (isComplianceUrl) {
      next();
    } else {
      resetStates();
      next(false);
    }
  } catch (e) {
    handleHttpExceptions(e, next);
  } finally {
    const elem = document.querySelector('#mainLoader');
    elem.style.display = 'none';
  }
});

async function initAndFetchData() {
  const { id: userId, lang } = storeToRefs(useUserStore());
  const { id: companyId } = storeToRefs(useCompanyStore());
  const { fetchUser } = useUserStore();
  const { fetchCompany } = useCompanyStore();
  const { initActionCable } = useActionCable();
  initActionCable();

  if (!companyId.value) await fetchCompany();
  if (!userId.value) await fetchUser();

  i18n.global.locale.value = lang.value || 'en';
}

function handleHttpExceptions(e, next) {
  const status = e?.response?.status;

  if (status === 401) {
    window.localStorage.removeItem('bktAccess');
    redirectToWithSnackbarWarning(
      'check',
      'You encountered an error, you were redirected to sign in page',
      next
    );
  } else if (status === 404) {
    next({ name: 'not_found' });
  } else if (status === 0 || status >= 500) {
    next({ name: 'internal_error' });
  } else {
    redirectToWithSnackbarWarning(
      'check',
      'You encountered an error, you were redirected to sign in page',
      next
    );
  }
  fromException = true;
}

function redirectToWithSnackbarWarning(to, message, next) {
  useSnackbar().setBgColor('onSurface').displaySnackBar(i18n.global.t(message));
  next({ name: to });
}

export default router;
