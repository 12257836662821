<template>
  <svn-pro-data-table
    :headers="data?.length ? headers : []"
    :items="data ?? []"
    :items-per-page="-1"
    :loading="data ? loading : true"
    item-value="id"
    :pagination-total-pages="paginationData?.total_pages"
    @update-pagination="fetchNewPage"
  >
    <template #item="{ item }">
      <tr class="hover:bg-[#46464F14] active:bg-[#46464F1F]/[0.12]" @click="goToModule(item?.id)">
        <td>
          <svn-pro-data-table-cell
            regular
            body-medium
            cell-type="text"
            :text="item?.title"
          />
        </td>

        <td>
          <svn-pro-data-table-cell
            regular
            body-medium
            :tag-type="getTagType(item?.submission_status)"
            tag-size="compact"
            cell-type="info_tag"
            :text="$t(getTagText(item?.submission_status))"
          />
        </td>

        <td>
          {{ $filters.formatDate(item?.submission_updated_at) || $t('Not acquired') }}
        </td>
      </tr>
    </template>

    <template #no-data>
      <svn-pro-empty-states
        :variant="searchText ? 'results' : 'index'"
        :title="$t('Nothing to show for now')"
        :size="isMdScreen ? 'default' : 'compact'"
        :supporting-text="searchText ? $t('Oops, we didn’t find any results matching your search.') :
          $t(`Hmm, it seems you have no modules.`)"
      />
    </template>

    <template #loading>
    </template>
  </svn-pro-data-table>
</template>

<script setup>
import { toRefs } from "vue";
import {storeToRefs} from "pinia";
import { useRouter } from "vue-router";
import { useMobileStore } from "@/store/mobile";
import { useLearnModuleStore } from "@/store/learn-module.js";

const props = defineProps({
  user: { type: Object, required: true },
  searchText: {type: String, default: ""},
  headers: { type: Array, required: true },
  data: { type: Array, default: () => [] },
  paginationData: { type: Object, default: () => {} },
});

const emit = defineEmits(['refetch-modules']);

const { isMdScreen } = storeToRefs(useMobileStore());
const { loading } = storeToRefs(useLearnModuleStore());

const router = useRouter();
const { searchText } = toRefs(props);
const { setModulesByUser } = useLearnModuleStore();

const goToModule = (moduleId) => {
  router.push({ name: 'module_show', params: { id: moduleId } })
}

const getTagType = (status) => {
  if (status === 'acquired') {
    return 'success'
  } else if (status === 'not_started') {
    return 'error'
  } else if (status === 'in_progress') {
    return 'warning'
  }
}

const getTagText = (status) => {
  if (status === 'acquired') {
    return 'Acquired'
  } else if (status === 'not_started') {
    return 'Not started'
  } else if (status === 'in_progress') {
    return 'In progress'
  }
}

const fetchNewPage = (page) => {
  setModulesByUser([])
  emit('refetch-modules', { userId: props.user.id, text: searchText?.value, page: page })
};
</script>
