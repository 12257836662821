import {
  SvnProSectionQuizLearnCorrectionType,
  SvnProQuizAnswerLearnItemStates,
} from 'svn-ui-library/components';

export default class LearnResponse {
  constructor({ id, inputId, selectedIndexes, selectedValues, status, text }) {
    this.id = id;
    this.inputId = inputId;
    this.selectedIndexes = selectedIndexes;
    this.selectedValues = selectedValues;
    this.status = status;
    this.text = text;
  }

  statusToSvnProSectionQuizLearnCorrectionType() {
    switch (this.status) {
      case 'wrong':
        return SvnProSectionQuizLearnCorrectionType.wrongAnswer;
      case 'correct':
        return SvnProSectionQuizLearnCorrectionType.rightAnswer;
      case 'ambiguous':
        return SvnProSectionQuizLearnCorrectionType.partiallyRight;
      case 'not_set':
        return SvnProSectionQuizLearnCorrectionType.none;
    }
    return this.status;
  }

  statusToSvnProQuizAnswerLearnItemStates() {
    switch (this.status) {
      case 'wrong':
        return SvnProQuizAnswerLearnItemStates.wrong;
      case 'correct':
        return SvnProQuizAnswerLearnItemStates.correct;
      case 'ambiguous':
        return SvnProQuizAnswerLearnItemStates.partiallyCorrect;
      case 'not_set':
        return SvnProQuizAnswerLearnItemStates.enable;
    }
    return this.status;
  }

  static fromJson(data) {
    return new LearnResponse({
      id: data['id'],
      inputId: data['input_id'],
      selectedIndexes: data['selected_indexes'],
      selectedValues: data['selected_values'],
      status: data['status'],
      text: data['text'],
    });
  }
}
