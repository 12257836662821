<template>
  <div class="w-screen h-screen flex items-center justify-center px-5 md:!px-12 py-16 lg:!px-0 lg:!py-0 overflow-y-auto">
    <div class="w-full max-w-[328px] flex h-[608px] md:h-[718px] lg:h-[536px] xl:max-w-[472px] xl:h-[592px] desktop:h-[712px] flex-col gap-4 relative justify-center items-center mx-auto">
      <div class="flex flex-col items-start gap-6 self-stretch">
        <!-- Welcome text -->
        <div class="w-full flex flex-col gap-2 items-start">
          <svn-pro-title :h5="!isMobile" :h6="isMobile" medium color="onSurface">
            {{ $t("Welcome on board !") }}
          </svn-pro-title>

          <svn-pro-text body-medium regular color="onSurface">
            {{ $t("Please enter your password.") }}
          </svn-pro-text>
        </div>

        <!-- Email input -->
        <svn-pro-text-field
          v-model="email"
          :label="$t('Email')"
          type="text"
          class="w-full"
          variant="outlined"
          :error="emailError.error"
          :error-messages="emailError.errorMessage"
        />

        <div class="w-full flex flex-col gap-1 items-end">
          <!-- Password input -->
          <svn-pro-text-field
            v-model="password"
            :label="$t('Password')"
            type="password"
            class="w-full"
            variant="outlined"
            :error="passwordError.error"
            :error-messages="passwordError.errorMessage"
            @keyup.enter="signIn"
          />

          <p
            class="font-normal text-sm text-right cursor-pointer underline text-onSurface"
            @click="resetPass"
          >
            {{ $t('Forgot my password') }}
          </p>
        </div>  
      </div>

      <svn-pro-button
        class="w-full"
        variant="flat"
        :loading="loginLoading"
        :text="$t('Log in')"
        @click="signIn"
      />

      <!-- Aleph Logo -->
      <div class="w-full flex flex-col items-start absolute top-0">
        <div
          class="flex justify-center items-center bg-cover bg-center overflow-hidden w-[120px] h-[34.5px]"
          :style="{'background-image': `url('${logo}')`}"
        />
      </div>
    </div>

    <div class="w-1/2 h-screen  hidden xl:flex">
      <div
        class="flex justify-center items-center bg-cover bg-center overflow-hidden w-full h-full"
        :style="{'background-image': `url('${bgHome}')`}"
      />
    </div>
  </div>
</template>

<script setup>
import { useTheme } from "vuetify";
import { storeToRefs } from "pinia";
import { ref, onMounted } from 'vue';
import bgHome from "@/assets/bghome.png";
import { useUserStore } from "@/store/user.js";
import logo from "@/assets/logo_aleph_white.svg";
import { useRouter, useRoute } from 'vue-router';
import { useSnackbar } from "@/store/snackbar.js";
import axiosService from "@/tools/axios-service.js";
import { useMobileStore } from '@/store/mobile.js';

const route = useRoute();
const router = useRouter();
const snackbar = useSnackbar();
const theme = useTheme();

snackbar.checkStatus();

const { email } = storeToRefs(useUserStore());
const { isMobile, isMdScreen } = storeToRefs(useMobileStore());

const password = ref()
const loginLoading = ref(false)
const emailError = ref({ error: false, errorMessage: '', hideDetails: true })
const passwordError = ref({ error: false, errorMessage: '', hideDetails: true })

onMounted(async() => {
  if (route.query?.creds) {
    var auth = route.query?.creds
    auth = JSON.parse(auth);

    localStorage.setItem('bktAccess', JSON.stringify(auth));

    if (await axiosService.verifyToken(auth)) {
      await router.push({name: 'home'})
    } else {
      snackbar.setBgColor('failed').displaySnackBar('Couldn\'t connect sorry...')
    }
  }
});

const signIn = async () => {
  try {
    loginLoading.value = true
    
    if (!email.value || !password.value) {
      snackbar.setBgColor('onSurface')
        .displaySnackBar('Fill the fields please')
      return
    }

    await axiosService.emailSignIn({
      email: email.value,
      password: password.value,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
    })

    router.push({name: 'home'})

    setTimeout(() => {
      theme.global.name.value = "alephLight";
    }, 200)
  } catch (e) {
    snackbar.setCustomClass('mb-4')
    snackbar.setBgColor('onSurface').displaySnackBar('Email or password incorrect. Please try again.')
    emailError.value = { error: true, errorMessage: "", hideDetails: true }
    passwordError.value = { error: true, errorMessage: "", hideDetails: true }
  } finally {
    loginLoading.value = false
  }
}

const resetPass = async () => {
  if (!email.value) {
    snackbar.setBgColor('failed').displaySnackBar('Email can\'t be blank')
    return
  }

  try {
    await axiosService.resetPass(email.value)
    window.localStorage.setItem("bktPass", JSON.stringify({
      email: email.value,
      timer: 0,
      mode: 'reset'
    }))
    await router.push({name: 'email_sent_reset'})
  } catch (error) {
    snackbar.setCustomClass('mb-4')
    snackbar.setBgColor('error').displaySnackBar('This email is not recognized. Please try again.')
    emailError.value = { error: true, errorMessage: "Email not found, please try again !", hideDetails: false }
  }
}
</script>

<style scoped>
.v-text-field :deep(.v-input__details) {
  padding-inline: 0px;
}

:deep(.v-field) {
  border-radius: 8px!important;
}
</style>
