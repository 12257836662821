<template>
  <div
    :id="question.id"
    class="py-6 text-2xl"
  >
    <div class="text-3xl break-words w-full font-medium">
      <span
        class="first-letter:uppercase"
      >
        {{ question.title }}
      </span>
    </div>

    <div class="text-2xl text-darkGrey break-words w-full my-4">
      <div
        v-html="question.description"
      />
    </div>
    <div
      v-for="answer in question.answers"
      :key="answer.id"
      class="mb-6"
    >
      <div class="font-medium">
        {{ answer.user.fullname }} {{ $t('Interviewee') }} {{ $t("answer") }} :
      </div>

      <div>
        <v-rating
          :length="objectFlip(question.options)[1]"
          :model-value="parseInt(answer.value)"
          color="darkGrey"
          readonly
          class="my-2"
        >
          <template #item="iconProps">
            <v-icon
              :color="'fakeBlack'"
              class="mr-4"
              @click="iconProps.onClick"
            >
              {{ iconProps.isFilled ? 'mdi-star' : 'mdi-star-outline' }}
            </v-icon>
          </template>
        </v-rating>
        <p class="leading-9">
          {{ answer.comments }}
        </p>
      </div>
    </div>
  </div>
</template>

<script setup>
import useTools from "@/tools/useTools.js";

const props = defineProps(["question", "loopIdx"]);

const { objectFlip } = useTools();
</script>
