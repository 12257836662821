<template>
  <div class="w-full h-full mx-auto flex flex-col px-5 lg:!px-0 py-6 gap-8">
    <div class="w-full flex flex-col gap-6 items-start">
      <div class="w-full flex flex-col gap-2 items-start">        
        <svn-pro-title h6 medium>
          {{ $t('Company logo') }}
        </svn-pro-title>
  
        <svn-pro-text subtitle-medium regular color="onSurfaceVariant">
          {{ $t('Enhance the identity of your workspace by uploading your company logo in Aleph. Make your mark in the navbar, creating a branded and professional look for the tool.') }}
        </svn-pro-text>
  
        <svn-pro-text subtitle-medium regular color="onSurfaceVariant">
          {{ $t('Only *.jpg, *.png and *.jpeg file formats are supported. The newly uploaded image will replace the current logo.') }}
        </svn-pro-text>
      </div>

      <!-- My Company logo -->
      <div class="w-full lg:max-w-[692px] flex flex-col gap-1 items-start mx-auto sm:!mx-0">
        <svn-pro-text body-medium regular color="onSurfaceVariant">
          {{ $t('My company logo') }}
        </svn-pro-text>

        <customization-item
          container-class="w-full h-[232px] sm:h-[320px] max-w-[560px]"
          :image-lazy-src="navbarLogo?.['150']"
          :image-src="navbarLogo?.['500']"
          @file-upload="changeImages($event, 'navbarlogo')"
        />
      </div>
    </div>

    <div class="w-full flex flex-col gap-4 items-start">
      <div class="w-full flex flex-col gap-2 items-start">
        <svn-pro-title h6 medium>
          {{ $t('Banner') }}
        </svn-pro-title>
  
        <svn-pro-text body-medium regular color="dark-grey">
          {{ $t('Elevate collaboration and visual communication with our new Banner  Upload feature in Aleph. Share event highlights, announcements, and team  achievements through eye-catching banners visible to all employees.  Simply upload and engage with your colleagues in this dynamic space.') }}
        </svn-pro-text>
  
        <svn-pro-text body-medium regular color="dark-grey">
          {{ $t('Only *.jpg, *.png and *.jpeg file formats are supported. The newly uploaded image will replace the current logo.') }}
        </svn-pro-text>
      </div>

      <!-- Homepage banner -->
      <div class="w-full flex flex-col gap-1 items-center sm:items-start mx-auto">
        <a :href="router.resolve({ name: 'home' }).href" target="_blank" class="self-start">
          <div class="flex items-center gap-1 self-start cursor-pointer">
            <v-hover>
              <template #default="{ isHovering, props }">
                <svn-pro-text v-bind="props" body-medium regular color="onSurfaceVariant" :class="isHovering ? 'underline' : ''">
                  {{ $t('Homepage banner') }}
                </svn-pro-text>
              </template>
            </v-hover>
  
            <div>
              <Icon
                icon="ic:twotone-open-in-new"
                height="16"
                width="16"
                class="text-onSurfaceVariant"
              />
            </div>
          </div>
        </a>

        <customization-item
          container-class="w-full h-[232px] sm:h-[320px]"
          :image-lazy-src="homepageImage?.['150']"
          :image-src="homepageImage?.['500']"
          @file-upload="changeImages($event, 'homepageimage')"
        />
      </div>

      <div class="w-full h-full flex flex-col sm:flex-row gap-6 items-start">
        <!-- My Interviews banner -->
        <div class="w-full flex-1 h-full flex flex-col gap-1 items-start">
          <a :href="router.resolve({ name: 'my_interviews' }).href" target="_blank" class="self-start">
            <div class="flex items-center gap-1 self-start cursor-pointer">
              <v-hover>
                <template #default="{ isHovering, props }">
                  <svn-pro-text v-bind="props" body-medium regular color="onSurfaceVariant" :class="isHovering ? 'underline' : ''">
                    {{ $t('My Interviews banner') }}
                  </svn-pro-text>
                </template>
              </v-hover>
    
              <div>
                <Icon
                  icon="ic:twotone-open-in-new"
                  height="16"
                  width="16"
                  class="text-onSurfaceVariant"
                />
              </div>
            </div>
          </a>
  
          <customization-item
            container-class="w-full h-[232px] sm:h-[320px]"
            :image-lazy-src="myInterviewsImage?.['150']"
            :image-src="myInterviewsImage?.['500']"
            @file-upload="changeImages($event, 'myinterviewsimage')"
          />
        </div>
  
        <!-- My Team Interviews banner -->
        <div class="w-full flex-1 h-full flex flex-col gap-1 items-start">
          <a :href="router.resolve({ name: 'my_team_interviews' }).href" target="_blank" class="self-start">
            <div class="flex items-center gap-1 self-start cursor-pointer">
              <v-hover>
                <template #default="{ isHovering, props }">
                  <svn-pro-text v-bind="props" body-medium regular color="onSurfaceVariant" :class="isHovering ? 'underline' : ''">
                    {{ $t('My Team Interviews banner') }}
                  </svn-pro-text>
                </template>
              </v-hover>
    
              <div>
                <Icon
                  icon="ic:twotone-open-in-new"
                  height="16"
                  width="16"
                  class="text-onSurfaceVariant"
                />
              </div>
            </div>
          </a>
  
          <customization-item
            container-class="w-full h-[232px] sm:h-[320px]"
            :image-lazy-src="myTeamInterviewsImage?.['150']"
            :image-src="myTeamInterviewsImage?.['500']"
            @file-upload="changeImages($event, 'myteaminterviewsimage')"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { Icon } from '@iconify/vue';
import { useCompanyStore } from '@/store/company';
import { storeToRefs } from 'pinia';
import { useMobileStore } from '@/store/mobile';
import { useSnackbar } from '@/store/snackbar';
import CustomizationItem from "@/components/companyApp/CustomizationItem.vue";
import { useRouter } from 'vue-router';

onMounted(() => {
  navbarLogo.value = logo.value
  homepageImage.value = homeBanner.value
  homepageLogo.value = homeLogo.value
  myInterviewsImage.value = myInterviewsBanner.value
  myTeamInterviewsImage.value = myTeamInterviewsBanner.value
})

const router = useRouter()
const { updateCompanyBannerOrLogo } = useCompanyStore()

const { isMobile } = storeToRefs(useMobileStore())
const { logo, homeBanner, homeLogo, myInterviewsBanner, myTeamInterviewsBanner } = storeToRefs(useCompanyStore())

const homepageLogo = ref(null)
const snackbar = useSnackbar()
const navbarLogo = ref(null)
const homepageImage = ref(null)
const myInterviewsImage = ref(null)
const myTeamInterviewsImage = ref(null)

const changeImages = async(file, type) => {
  try {
    switch (type) {
      case 'homepagelogo':
        await updateCompanyBannerOrLogo('home_logo', file)
        homepageLogo.value = {
          '150': homeLogo.value['150'],
          '500': homeLogo.value['500'],
        }
        break
      case 'navbarlogo':
        await updateCompanyBannerOrLogo('logo', file)
        navbarLogo.value = {
          '150': logo.value['150'],
          '500': logo.value['500'],
        }
        break
      case 'homepageimage':
        await updateCompanyBannerOrLogo('home_banner', file)
        homepageImage.value = {
          '150': homeBanner.value['150'],
          '500': homeBanner.value['500'],
        }
        break
      case 'myinterviewsimage':
        await updateCompanyBannerOrLogo('my_interviews_banner', file)
        myInterviewsImage.value = {
          '150': myInterviewsBanner.value['150'],
          '500': myInterviewsBanner.value['500'],
        }
        break
      case 'myteaminterviewsimage':
        await updateCompanyBannerOrLogo('my_team_interviews_banner', file)
        myTeamInterviewsImage.value = {
          '150': myTeamInterviewsBanner.value['150'],
          '500': myTeamInterviewsBanner.value['500'],
        }
        break
    }
    snackbar.setBgColor('onSurfaceVariant')
    snackbar.setMsg('Image updated successfully.')
    snackbar.setCustomClass('mb-5')
    snackbar.displaySnackBar()
  } catch (error) {
    snackbar.setBgColor('error')
    snackbar.setMsg('Error updating image.')
    snackbar.setCustomClass('mb-5')
    snackbar.displaySnackBar()
  }
}
</script>

<style scoped>
</style>