<template>
  <div class="w-full flex flex-col gap-6 items-start">
    <div class="w-full flex flex-col md:flex-row-reverse gap-4">
      <svn-pro-select
        v-model="tab"
        :items="tabs"
        item-title="text"
        :label="$t('Role')"
        item-value="value"
        class="w-full md:!max-w-[320px]"
        hide-details
      />

      <svn-pro-text-field
        color="primary"
        variant="outlined"
        v-model="searchText"
        :label="$t('Search')"
        prepend-inner-icon="custom:mingcute:search-2-line"
        @update:model-value="search"
      />

      <v-tabs
        v-model="tab"
        color="primary"
        class="hidden"
      >
        <v-tab
          value="as_participant"
          :text="$t('As participant')"
          class="w-[140px] md:!w-[180px]"
        />

        <v-tab
          value="as_reviewer"
          :text="$t('As reviewer')"
          class="w-[140px] md:!w-[180px]"
        />

        <v-tab
          value="as_person_in_charge"
          :text="$t('As person in charge')"
          class="w-[140px] md:!w-[180px]"
        />
      </v-tabs>
    </div>

    <v-window v-model="tab" class="!w-full">
      <v-window-item value="as_participant">
        <sub-tabs-in-interviews
          :headers="interviewsHeadersParticipant"
          :interview-data="interviewDataAsparticipant"
          :pagination-data="paginationAsParticipant"
          :tab="tab"
          :user="user"
          :search-text="searchText"
          @refetch-interviews="refetchInterviews"
        />
      </v-window-item>

      <v-window-item value="as_reviewer">
        <sub-tabs-in-interviews
          :headers="interviewsHeadersReviewer"
          :interview-data="interviewDataAsReviewer"
          :pagination-data="paginationAsReviewer"
          :tab="tab"
          :user="user"
          :search-text="searchText"
          @refetch-interviews="refetchInterviews"
        />
      </v-window-item>

      <v-window-item value="as_person_in_charge">
        <sub-tabs-in-interviews
          :headers="interviewsHeadersPersonInCharge"
          :interview-data="interviewDataAsPersonInCharge"
          :pagination-data="paginationAsPersonInCharge"
          :tab="tab"
          :user="user"
          :search-text="searchText"
          @refetch-interviews="refetchInterviews"
        />
      </v-window-item>
    </v-window>
  </div>  
 </template>

<script setup>
import { debounce} from "lodash";
import i18n from '@/plugins/i18n';
import { storeToRefs } from "pinia";
import { ref, computed, onMounted } from "vue";
import { useMobileStore } from "@/store/mobile";
import { useSnackbar } from "@/store/snackbar.js";
import { useInterviewStore } from "@/store/interview.js";
import SubTabsInInterviews from "@/components/BktSubTabsInterviewsInProfile.vue";

const props = defineProps({
  user: { type: Object, required: true }
});

const { isMobile } = storeToRefs(useMobileStore());
const { pagination } = storeToRefs(useInterviewStore());

const snackbar = useSnackbar();
const { getInterviewsByUser } = useInterviewStore();

onMounted(async () => {
  try {
    if (pagination.value) {
      pagination.value.current_page = 1
    }
    interviewDataAsparticipant.value = await getInterviewsByUser(props.user.id, 'as_participant', searchText?.value, 1);
    paginationAsParticipant.value = pagination.value
    interviewDataAsReviewer.value = await getInterviewsByUser(props.user.id, 'as_reviewer', searchText?.value, 1);
    paginationAsReviewer.value = pagination.value
    interviewDataAsPersonInCharge.value = await getInterviewsByUser(props.user.id, 'as_person_in_charge', searchText?.value, 1);
    paginationAsPersonInCharge.value = pagination.value
  } catch (error) {
    console.log(error)
  }
})

const searchText = ref('');
const tab = ref('as_participant');
const interviewDataAsparticipant = ref([]);
const paginationAsParticipant = ref({});
const interviewDataAsReviewer = ref([]);
const paginationAsReviewer = ref({});
const interviewDataAsPersonInCharge = ref([]);
const paginationAsPersonInCharge = ref({});
const tabs = ref([
  {
    value: "as_participant",
    text: i18n.global.t("As participant")
  },
  {
    value: "as_reviewer",
    text: i18n.global.t("As reviewer")
  },
  {
    value: "as_person_in_charge",
    text: i18n.global.t("As person in charge")
  }
])
const interviewsHeadersParticipant = computed(() => {
  return [
    { title: "Campaign",
      key: "campaign",
      align: 'start',
      sortable: true,
      minWidth: 287,
    },
    { title: "Person in charge",
      key: "person",
      align: 'start',
      sortable: false,
      width: 287,
      minWidth: 287,
    },
    { title: "Completion",
      key: "completion",
      align: 'start',
      sortable: false,
      width: 156,
      minWidth: 156,
    }
  ]
})
const interviewsHeadersReviewer = computed(() => {
  return [
    { title: "Campaign",
      key: "campaign",
      align: 'start',
      sortable: true,
      minWidth: 287,
    },
    { title: "Review of",
      key: "person",
      align: 'start',
      sortable: false,
      width: 287,
      minWidth: 287,
    },
    { title: "Completion",
      key: "completion",
      align: 'start',
      sortable: false,
      width: 156,
      minWidth: 156,
    }
  ]
})
const interviewsHeadersPersonInCharge = computed(() => {
  return [
    { title: "Campaign",
      key: "campaign",
      align: 'start',
      sortable: true,
      minWidth: 287,
    },
    { title: "In charge of",
      key: "person",
      align: 'start',
      sortable: false,
      width: 287,
      minWidth: 287,
    },
    { title: "Completion",
      key: "completion",
      align: 'start',
      sortable: false,
      width: 156,
      minWidth: 156,
    }
  ]
})

const refetchInterviews = async ({ userId, as, text, page }) => {
  switch (as) {
    case "as_participant":
      interviewDataAsparticipant.value = await getInterviewsByUser(userId, 'as_participant', text, page);
      paginationAsParticipant.value = pagination.value
    case "as_reviewer":
      interviewDataAsReviewer.value = await getInterviewsByUser(userId, 'as_reviewer', text, page);
      paginationAsReviewer.value = pagination.value
    case "as_person_in_charge":
      interviewDataAsPersonInCharge.value = await getInterviewsByUser(userId, 'as_person_in_charge', text, page);
      paginationAsPersonInCharge.value = pagination.value
  }
}

const search = debounce(async() => {
  switch (tab?.value) {
    case "as_participant":
      interviewDataAsparticipant.value = await getInterviewsByUser(props.user.id, 'as_participant', searchText?.value, 1);
      paginationAsParticipant.value = pagination.value
    case "as_reviewer":
      interviewDataAsReviewer.value = await getInterviewsByUser(props.user.id, 'as_reviewer', searchText?.value, 1);
      paginationAsReviewer.value = pagination.value
    case "as_person_in_charge":
      interviewDataAsPersonInCharge.value = await getInterviewsByUser(props.user.id, 'as_person_in_charge', searchText?.value, 1);
      paginationAsPersonInCharge.value = pagination.value
  }
}, 300)
</script>
