<template>
  <div
    class="w-full gap-6 flex flex-col md:!max-w-[672px] lg:!max-w-[928px] xl:!max-w-[1136px] mx-auto my-5 md:my-6 overflow-hidden"
  >
    <svn-pro-header-section
      :size="isMdScreen ? 'default' : 'compact'"
      :title="$t('Catalog')"
      :subtitle="$t('Here is all contents created in your company.')"
    />

    <div class="flex flex-col gap-4">
      <div class="flex justify-between md:!mx-0 mx-5 h-[48px]">
        <svn-pro-title :h5="!isMobile" :h6="isMobile" medium>
          {{ $t('Contents') }}
        </svn-pro-title>

        <div class="flex gap-2 items-center justify-center" v-if="isManagerCreatorOrAbove()">
          <svn-pro-button
            v-if="isMdScreen"
            @click="openCreatePlaylist.dialog = true"
            variant="outlined"
            prepend-icon="custom:mingcute:add-line"
            :text="$t('New playlist')"
          />

          <svn-pro-button
            v-if="isMdScreen"
            @click="goToNewModule"
            variant="flat"
            prepend-icon="custom:mingcute:add-line"
            :text="$t('New module')"
          />

          <svn-pro-menu v-if="['module', 'playlist'].includes(tab)">
            <template #activator="{ props }">
              <svn-pro-icon-button
                v-bind="props"
                icon="custom:mingcute:more-2-fill"
                color="onSurfaceVariant"
                variant="text"
              />
            </template>

            <template #dropdown>
              <modal-view-deleted-items
                v-if="tab"
                ref="deletedItems"
                :headers="trashesHeaders"
                :items="tab === 'module' ? learnTrashModules : learnTrashPlaylists"
                :content-type="tab"
                @view-item="viewDeletedItem"
                @close-modal="closeModal"
                @restore="restoreDeletedItems"
                @toggle-search="toggleSearchDeletedItems"
                @delete-permanently="deletePermanentlyDeletedItems"
              >
                <template #activator="{ props }">
                  <svn-pro-list-item
                    value="view_deleted"
                    v-bind="props"
                    :active="false"
                    :title="$t(`View deleted ${tab}s`)"
                    density="compact"
                    @click="
                      tab === 'module' ? customFetchTrashModules() : customFetchTrashPlaylist()
                    "
                  />
                </template>
              </modal-view-deleted-items>
            </template>
          </svn-pro-menu>
        </div>
      </div>

      <div>
        <!-- Tabs -->
        <v-tabs v-model="tab" color="primary">
          <v-tab
            v-for="(tab_data, index) in tab_datas"
            :key="index"
            :value="tab_data.key"
            class="w-1/3 md:w-auto"
          >
            {{ $t(tab_data.title) }}
          </v-tab>
        </v-tabs>

        <!-- Windows -->
        <v-tabs-window v-model="tab">
          <!-- Modules tab -->
          <v-tabs-window-item value="module">
            <catalog-module :themes="learnThemes" @goToNewModule="goToNewModule" />
          </v-tabs-window-item>

          <!-- Playlists tab -->
          <v-tabs-window-item value="playlist">
            <catalog-playlist
              :themes="learnThemes"
              @go-to-new-playlist="openCreatePlaylist.dialog = true"
            />
          </v-tabs-window-item>

          <!-- Modules Drafts tab -->
          <v-tabs-window-item value="draft">
            <catalog-module-draft :themes="learnThemes" @goToNewModule="goToNewModule" />
          </v-tabs-window-item>
        </v-tabs-window>
      </div>
    </div>
  </div>

  <dialog-create-new-playlist
    ref="openCreatePlaylist"
    title="Create new playlist"
    text-confirm="Validate"
  >
    <template #activator="{ props }">
      {{ null }}
    </template>
  </dialog-create-new-playlist>

  <svn-pro-floating-action-button
    v-if="!isMdScreen && isManagerCreatorOrAbove()"
    extended
    class="fixed bottom-4 right-4"
    variant="primary"
    :text="tab === 'playlist' ? $t('New Playlist') : $t('New Module')"
    prepend-icon="custom:mingcute:add-line"
    @click="tab === 'playlist' ? (openCreatePlaylist.dialog = true) : goToNewModule()"
  />
</template>

<script setup>
import { storeToRefs } from 'pinia';
import { ref, watch, computed, onMounted } from 'vue';
import CatalogModule from './CatalogModule.vue';
import CatalogPlaylist from './CatalogPlaylist.vue';
import CatalogModuleDraft from './CatalogModuleDraft.vue';
import { useMobileStore } from '@/store/mobile';
import { useUserStore } from '@/store/user.js';
import { useRoute, useRouter } from 'vue-router';
import { useLearnModuleStore } from '@/store/learn-module';
import { useSnackbar } from '@/store/snackbar.js';
import { useLearnThemesStore } from '@/store/learn-themes.js';
import DialogCreateNewPlaylist from '@/components/BktPopUp/Dialogs/learn/DialogCreateNewPlaylist.vue';
import i18n from '@/plugins/i18n.js';
import imageTest1 from '@/assets/images/learnImages/img1.png';
import imageTest2 from '@/assets/images/learnImages/img2.png';
import imageTest3 from '@/assets/images/learnImages/img3.png';
import imageTest4 from '@/assets/images/learnImages/img4.png';
import imageTest5 from '@/assets/images/learnImages/img5.png';
import imageTest6 from '@/assets/images/learnImages/img6.png';
import imageTest7 from '@/assets/images/learnImages/img7.png';
import imageTest8 from '@/assets/images/learnImages/img8.png';
import imageTest9 from '@/assets/images/learnImages/img9.png';
import imageTest10 from '@/assets/images/learnImages/img10.png';
import imageTest11 from '@/assets/images/learnImages/img11.png';
import imageTest12 from '@/assets/images/learnImages/img12.png';
import imageTest13 from '@/assets/images/learnImages/img13.png';
import imageTest14 from '@/assets/images/learnImages/img14.png';
import imageTest15 from '@/assets/images/learnImages/img15.png';
import ModalViewDeletedItems from '@/components/BktPopUp/Dialogs/learn/ModalViewDeletedItems.vue';
import { debounce } from 'lodash';
import { useLearnPlaylistStore } from '@/store/learn-playlist.js';

onMounted(async () => {
  tab.value = route?.query?.tab

  try {
    await fetchThemes();
  } catch (error) {
    snackbar.setBgColor('onSurface');
    snackbar.setMsg('Error fetching themes');
    snackbar.displaySnackBar();
  }
});

const router = useRouter();

const openCreatePlaylist = ref(false);

const tab = ref('module');

const route = useRoute();
const contentIdToDelete = ref(null);
const deletedItems = ref(null);

const imagesList = ref([
  imageTest1,
  imageTest2,
  imageTest3,
  imageTest4,
  imageTest5,
  imageTest6,
  imageTest7,
  imageTest8,
  imageTest9,
  imageTest10,
  imageTest11,
  imageTest12,
  imageTest13,
  imageTest14,
  imageTest15,
]);

const snackbar = useSnackbar();

const { fetchThemes } = useLearnThemesStore();

const {
  createModule,
  updateModuleImage,
  fetchTrashModules,
  restoreModule,
  deleteModulePermanently,
} = useLearnModuleStore();
const { restorePlaylist, fetchPlaylists, deletePlaylistPermanently, fetchTrashPlaylists } = useLearnPlaylistStore();

const { learnTrashModules } = storeToRefs(useLearnModuleStore());
const { learnTrashPlaylists } = storeToRefs(useLearnPlaylistStore());

const { isManagerCreatorOrAbove } = useUserStore();

const { isMobile } = storeToRefs(useMobileStore());

const { learnThemes } = storeToRefs(useLearnThemesStore());

const { isMdScreen } = storeToRefs(useMobileStore());

const trashesHeaders = computed(() => {
  return [
    {
      align: 'start',
      key: 'title',
      sortable: true,
      title: i18n.global.t(tab.value == 'playlist' ? 'Playlists' : 'Modules'),
      minWidth: 200,
    },
    {
      align: 'start',
      key: 'deleted_at',
      sortable: false,
      title: i18n.global.t('Deleted on'),
      minWidth: 150,
    },
    {
      align: 'start',
      key: 'restore',
      sortable: false,
      title: '',
      width: 40,
      minWidth: 40,
    },
    {
      align: 'start',
      key: 'delete',
      sortable: false,
      title: '',
      width: 40,
      minWidth: 40,
    },
  ];
});

const tab_datas = computed(() => {
  if (isManagerCreatorOrAbove()) {
    return [
      {
        id: 0,
        title: 'Modules',
        key: 'module',
      },
      {
        id: 1,
        title: 'Playlists',
        key: 'playlist',
      },
      {
        id: 2,
        title: 'Drafts',
        key: 'draft',
        noContentText: 'There are no drafts. Create your first module !',
      },
    ];
  } else {
    return [
      {
        id: 0,
        title: 'Modules',
        key: 'module',
        noContentText: 'There are no modules for the moment, come later to see what’s new !',
      },
      {
        id: 1,
        title: 'Playlists',
        key: 'playlist',
        noContentText: 'There are no playlists. Create your first playlist !',
      },
    ];
  }
});

const goToNewModule = async () => {
  // get random index value
  const randomIndex = Math.floor(Math.random() * imagesList.value.length);

  // get random Image
  const randomImage = imagesList.value[randomIndex];

  try {
    let module = await createModule('Module title', '30 minutes');

    loadXHR(randomImage).then(async (blob) => {
      updateModuleImage(module?.data?.id, blob);
    });

    router.push({ name: 'module_edit', params: { id: module?.data?.id } });
  } catch (error) {
    snackbar.setBgColor('error');
    snackbar.setMsg('Error creating module');
    snackbar.setCustomClass(isMobile.value ? 'mb-[96px]' : 'mb-[24px]');
    snackbar.displaySnackBar();
  }
};

const loadXHR = (url) => {
  return new Promise(function (resolve, reject) {
    try {
      var xhr = new XMLHttpRequest();
      xhr.open('GET', url);
      xhr.responseType = 'blob';
      xhr.onerror = function () {
        reject('Network error.');
      };
      xhr.onload = function () {
        if (xhr.status === 200) {
          resolve(xhr.response);
        } else {
          reject('Loading error:' + xhr.statusText);
        }
      };
      xhr.send();
    } catch (err) {
      reject(err.message);
    }
  });
};

const toggleSearchDeletedItems = debounce(async (value) => {
  try {
    if (tab.value === 'module') {
      await fetchTrashModules(value);
    } else {
      await fetchTrashPlaylists(value);
    }
  } catch (e) {
    console.error(e);
  }
}, 300);

const restoreDeletedItems = async (id) => {
  try {
    if (tab.value === 'module') {
      await restoreModule(id);
      router.push({ name: 'module_show', params: { id: id } });
    } else {
      await restorePlaylist(id);
      router.push({ name: 'playlist_show', params: { id: id } });
    }
    snackbar.setBgColor('onSurface');
    snackbar.setMsg(
      `${tab.value === 'module' ? 'Module' : 'Playlist'} has been restored successfully.`,
    );
    snackbar.setCustomClass(isMobile.value ? 'mb-[96px]' : 'mb-[24px]');
    snackbar.displaySnackBar();
  } catch (e) {
    snackbar.setBgColor('error');
    snackbar.setMsg(`Error while restoring a ${tab.value}`);
    snackbar.setCustomClass(isMobile.value ? 'mb-[96px]' : 'mb-[24px]');
    snackbar.displaySnackBar();
  }
};

const closeModal = (id) => {
  deletedItems.value.deletedItems.dialog = false;
  deletedItems.value.deleteTargetRef.dialogRef.dialog = true;
  contentIdToDelete.value = id;
};

const deletePermanentlyDeletedItems = async () => {
  try {
    if (tab.value === 'module') {
      await deleteModulePermanently(contentIdToDelete.value, false);
    } else {
      await deletePlaylistPermanently(contentIdToDelete.value);
    }
    snackbar.setBgColor('onSurface');
    snackbar.setMsg(
      `${tab.value === 'module' ? 'Module' : 'Playlist'} has been deleted successfully.`,
    );
    snackbar.setCustomClass(isMobile.value ? 'mb-[96px]' : 'mb-[24px]');
    snackbar.displaySnackBar();
  } catch (e) {
    snackbar.setBgColor('error');
    snackbar.setMsg(`Error while deleting permanently a ${tab.value}`);
    snackbar.setCustomClass(isMobile.value ? 'mb-[96px]' : 'mb-[24px]');
    snackbar.displaySnackBar();
  }
};

const viewDeletedItem = async (id) => {
  try {
    router.push({
      name: tab.value == 'module' ? 'module_show' : 'playlist_show',
      params: { id: id },
    });
  } catch (e) {
    snackbar.setBgColor('error');
    snackbar.setMsg('Error while showing deleted module');
    snackbar.setCustomClass(isMobile.value ? 'mb-[96px]' : 'mb-[24px]');
    snackbar.displaySnackBar();
  }
};

const customFetchTrashModules = async () => {
  try {
    await fetchTrashModules();
  } catch (error) {
    snackbar.setBgColor('negativeRed');
    snackbar.setMsg('Error fetching trash modules');
    snackbar.displaySnackBar();
  }
};

const customFetchTrashPlaylist = async () => {
  try {
    await fetchTrashPlaylists();
  } catch (error) {
    snackbar.setBgColor('error');
    snackbar.setMsg('Error fetching trash playlists');
    snackbar.displaySnackBar();
  }
};

watch(tab, (newVal, _) => {
  router.push({
    name: 'catalog',
    query: { tab: newVal }
  })
})

watch(route, () => {
  if (route.name == 'catalog') {
    tab.value = route?.query?.tab
  }
})
</script>