<template>
  <div class="w-full h-full relative bg-surface">
    <div class="w-full h-full max-w-[1136px] mx-auto flex flex-col gap-4 items-center pt-5">
      <!-- Header Container -->
      <div class="w-full flex flex-col items-center md:items-start gap-1 px-5 md:!px-12">
        <svn-pro-header-section
          :size="isMdScreen ? 'default' : 'compact'"
          :title="$t('My Team Roadmaps')"
          :subtitle="$t('Here is the list of all your team targets.')"
        />
      </div>

      <div class="w-full flex flex-col gap-4">
        <div class="w-full flex gap-4 px-5 md:!px-12 justify-between items-center">
          <svn-pro-title :h5="!isMobile" :h6="isMobile" medium>
            {{ $t('Targets') }}
          </svn-pro-title>

          <div class="flex items-center gap-2">
            <modal-create-new-target
              ref="refModalCreateNewTarget"
              from="my_team_roadmaps"
              :users-from-overlay="allUsersInMyTeam"
            >
              <template #activator="{ props }">
                <svn-pro-button
                  v-bind="props"
                  variant="flat"
                  prepend-icon="custom:mingcute:add-line"
                  :text="$t('New target')"
                  class="hidden md:flex"
                />
              </template>
            </modal-create-new-target>

            <svn-pro-menu>
              <template #activator="{ props }">
                <svn-pro-icon-button
                  v-bind="props"
                  icon="custom:mingcute:more-2-fill"
                  color="onSurfaceVariant"
                  variant="text"
                />
              </template>

              <template #dropdown>
                <modal-view-deleted-items
                  ref="deletedItems"
                  :headers="trashesHeaders"
                  :items="trashes"
                  content-type="Target"
                  @view-item="viewSelectedItem"
                  @restore="restoreDeletedItems"
                  @close-modal="closeModal"
                  @toggle-search="toggleSearchDeletedItems"
                  @delete-permanently="deletePermanentlyDeletedItems"
                >
                  <template #activator="{ props }">
                    <v-list-item
                      v-bind="props"
                      :active="false"
                      value="view_deleted"
                    >
                      <svn-pro-text body-large regular>
                        {{ $t('View deleted targets') }}
                      </svn-pro-text>
                    </v-list-item>
                  </template>
                </modal-view-deleted-items>
              </template>
            </svn-pro-menu>
          </div>
        </div>

        <!-- Tabs -->
        <v-tabs
          v-model="tab"
          color="primary"
          class="px-5 md:!px-12"
          :grow="isMobile"
        >
          <!-- Current targets Tab -->
          <v-tab
            value="current"
            class="w-1/2 md:w-auto"
            :text="`${$t('Current')} (${unarchivedPagination?.total_count || 0})`"
          />

          <!-- Archived targets Tab -->
          <v-tab
            value="archived"
            class="w-1/2 md:w-auto"
            :text="`${$t('Archived')} (${archivedPagination?.total_count || 0})`"
          />
        </v-tabs>

        <svn-pro-text-field
          v-model="currentSearch"
          variant="outlined"
          class="px-5 md:!px-12"
          :placeholder="$t('Search')"
          prepend-inner-icon="custom:mingcute:search-2-line"
          @update:model-value="search"
        />

        <!-- Windows -->
        <v-window v-model="tab">
          <v-window-item value="current">
            <div class="w-full h-full flex flex-col gap-4 px-5 md:!px-12">
              <my-team-roadmaps-table
                :search="currentSearch"
                :targets="unarchived"
                :loading="loading"
                :pagination="unarchivedPagination"
                type="unarchived"
                @update="update"
                @reload-page="fetchUnarchived($event, currentSearch)"
                @delete-target="deleteTarget"
                @open-modal-new-target="openModalNewTarget"
              />
            </div>
          </v-window-item>

          <v-window-item value="archived">
            <div class="w-full h-full flex flex-col gap-4 px-5 md:!px-12">
              <my-team-roadmaps-table
                :search="archivedSearch"
                :targets="archived"
                :loading="loading"
                :pagination="archivedPagination"
                type="archived"
                @update="update"
                @reload-page="fetchArchived($event, currentSearch)"
                @delete-target="deleteTarget"
                @open-modal-new-target="openModalNewTarget"
              />
            </div>
          </v-window-item>
        </v-window>
      </div>
    </div>

    <svn-pro-floating-action-button
      v-if="!isMdScreen"
      extended
      class="fixed bottom-4 right-4"
      variant="primary"
      :text="$t('New target')"
      prepend-icon="custom:mingcute:add-fill"
      @click="openModalNewTarget"
    />
  </div>
</template>

<script setup>
import { debounce } from "lodash";
import { storeToRefs } from "pinia";
import i18n from "@/plugins/i18n.js";
import { onMounted, ref } from "vue";
import { useUserStore } from "@/store/user.js";
import { useSnackbar } from "@/store/snackbar";
import { useMobileStore } from "@/store/mobile";
import { useRoute, useRouter } from "vue-router";
import axiosService from "@/tools/axios-service.js";
import { useRoadmapTargetStore } from "@/store/roadmap-target.js";
import MyTeamRoadmapsTable from "@/components/roadmapApp/myTeamRoadmap/table.vue";
import { useMyTeamRoadmapArchivedStore } from "@/store/my-team-roadmap-archived.js";
import { useMyTeamRoadmapUnarchivedStore } from "@/store/my-team-roadmap-unarchived.js";
import ModalCreateNewTarget from "../../components/BktPopUp/Modals/ModalCreateNewTarget.vue";
import ModalViewDeletedItems from "../../components/BktPopUp/Dialogs/learn/ModalViewDeletedItems.vue";

const { user: currentUser } = storeToRefs(useUserStore());
const { isMobile, isMdScreen } = storeToRefs(useMobileStore())
const { objectiveElements: usersArchive, pagination: paginationArchived } = storeToRefs(useMyTeamRoadmapArchivedStore());
const { objectiveElements: usersUnarchived, pagination: paginationUnarchived } = storeToRefs(useMyTeamRoadmapUnarchivedStore());

const snackbar = useSnackbar();
const { fetchMyTeamRoadmapArchived } = useMyTeamRoadmapArchivedStore();
const { fetchMyTeamRoadmapUnarchived } = useMyTeamRoadmapUnarchivedStore();
const { deletePermanentlyRoadmapTarget, restoreRoadmapTarget, deleteRoadmapTarget } = useRoadmapTargetStore();

const user = ref(null);
const route = useRoute();
const router = useRouter();
const tab = ref('current');
const allUsersInMyTeam = ref([]);
const unarchived = ref([]);
const unarchivedPagination = ref(null);
const archived = ref([]);
const archivedPagination = ref(null);
const trashes = ref([]);
const loading = ref(false)
const currentSearch = ref('')
const archivedSearch = ref('')
const deletedItems = ref(null);
const targetToDelete = ref(null);
const trashesPagination = ref({});
const refModalCreateNewTarget = ref(null);
const trashesHeaders = ref([
  {
    align: "start",
    key: "title",
    sortable: true,
    title: i18n.global.t('Target'),
    minWidth: 200,
  },
  {
    align: "start",
    key: "fullname",
    sortable: false,
    title: i18n.global.t('People'),
    minWidth: 200,
  },
  {
    align: "start",
    key: "deleted_at",
    sortable: false,
    title: i18n.global.t('Deleted on'),
    minWidth: 150,
  },
  {
    align: "start",
    key: "restore",
    sortable: false,
    title:"",
    width: 40,
    minWidth: 40,
  },
  {
    align: "start",
    key: "delete",
    sortable: false,
    title: "",
    width: 40,
    minWidth: 40,
  }
])

onMounted(async() => {
  await update();
  await fetchTrashes();

  try {
    const { data } = await axiosService.get("/api/v1/companies/get_users")

    data.forEach((user) => {
      if (user?.manager?.id === currentUser?.value?.id) {
        allUsersInMyTeam.value = [...allUsersInMyTeam.value, user.id]
      }
    })
  } catch (error) {
    snackbar.setBgColor('error');
    snackbar.setMsg('Failed to get all users')
    snackbar.displaySnackBar()
  }
});

const viewSelectedItem = (id) => {
  router.push({ name: 'roadmaps_targets_show', params: { id: id } })
}

const search = debounce((title) => {
  reloadAfterSearch(title)
}, 400)

const reloadAfterSearch = async(title) => {
  if (tab.value === 'current') {
    fetchUnarchived(1, title)
  } else {
    fetchArchived(1, title)
  }
}

const deleteTarget = async(id, type) => {
  await deleteRoadmapTarget(id)
  if (tab?.value === 'current') {
    fetchUnarchived(1)
  } else {
    fetchArchived(1)
  }
  fetchTrashes()
}

const fetchTrashes = async (title = null) => {
  const trashesReq = await axiosService.get(`/api/v1/roadmaps/employees/${user?.value?.id}/team_trashed_targets`,
    {
      params: { title }
    }
  );
  trashes.value = trashesReq?.data?.objective_elements
  trashesPagination.value = trashesReq?.data?.meta?.pagination
}

const update = async () => {
  loading.value = true
  if (route.params.employee_id) {
    const res = await axiosService.get(
      `/api/v1/users/${route.params.employee_id}`
    );

    user.value = res.data.user;
  } else {
    user.value = {
      id: currentUser?.value?.id,
      firstname: currentUser?.value?.firstname,
      lastname: currentUser?.value?.lastname,
      avatar: currentUser?.value?.avatar,
      job_title: currentUser?.value?.job_title,
      employee_ids: currentUser?.value?.employeeIds,
    };
  }
  try {
    fetchUnarchived()
    await fetchArchived()
  } catch (e) {
    useSnackbar().setBgColor("error").displaySnackBar(e?.message);
  }
  loading.value = false
};

const fetchUnarchived = async (page = 1, title = '') => {
  unarchived.value = null
  await fetchMyTeamRoadmapUnarchived(user?.value?.id, page, title)
  unarchived.value = usersUnarchived.value;
  unarchivedPagination.value = paginationUnarchived.value
}

const fetchArchived = async (page = 1, title = '') => {
  archived.value = null
  await fetchMyTeamRoadmapArchived(user?.value?.id, page, title)
  archived.value = usersArchive.value;
  archivedPagination.value = paginationArchived.value
}

const toggleSearchDeletedItems = debounce(async (value) => {
  try {
    await fetchTrashes(value)
  } catch (e) {
    console.error(e)
  }
}, 300)

const restoreDeletedItems = async (id) => {
  try {
    await restoreRoadmapTarget(id)
    if (tab?.value === 'current') {
      fetchUnarchived(1)
    } else {
      fetchArchived(1)
    }
    fetchTrashes()
    snackbar.setBgColor('onSurface')
    snackbar.setMsg('Target has been restored successfully.')
    snackbar.setCustomClass(isMobile.value ? 'mb-[88px]' : 'mb-5')
    snackbar.displaySnackBar()
  } catch (e) {
    snackbar.setBgColor('error')
    snackbar.setMsg('Error while restoring a target')
    snackbar.setCustomClass(isMobile.value ? 'mb-[88px]' : 'mb-5')
    snackbar.displaySnackBar()
  }
}

const deletePermanentlyDeletedItems = async () => {
  try {
    await deletePermanentlyRoadmapTarget(targetToDelete?.value)
    if (tab?.value === 'current') {
      fetchUnarchived(1)
    } else {
      fetchArchived(1)
    }
    fetchTrashes()
    snackbar.setBgColor('onSurface')
    snackbar.setMsg('Target has been deleted successfully.')
    snackbar.setCustomClass(isMobile.value ? 'mb-[88px]' : 'mb-5')
    snackbar.displaySnackBar()
  } catch (e) {
    snackbar.setBgColor('error')
    snackbar.setMsg('Error while deleting permanently a training')
    snackbar.setCustomClass(isMobile.value ? 'mb-[88px]' : 'mb-5')
    snackbar.displaySnackBar()
  }
  targetToDelete.value = null
}

const closeModal = (id) => {
  deletedItems.value.deletedItems.dialog = false
  deletedItems.value.deleteTargetRef.dialogRef.dialog = true

  targetToDelete.value = id
}

const openModalNewTarget = () => {
  refModalCreateNewTarget.value.modalNewTarget.dialog = true
}
</script>
