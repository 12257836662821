import apiService from '@/tools/axios-service';

export default class BaseLearnPiecesApi {
  constructor(model, apiUrl = '') {
    this.model = model;
    this.apiUrl = apiUrl;
  }

  async get(id) {
    const ret = await apiService.get(`${this.apiUrl}/${id}`);

    return this.model.fromJson(ret.data);
  }

  async patch(evaluationId, data) {
    return apiService.patch(`${this.apiUrl}/${evaluationId}/submission/`, data);
  }

  async post(evaluationId, endpoint, data) {
    return apiService.post(`${this.apiUrl}/${evaluationId}${endpoint}`, data);
  }
}
