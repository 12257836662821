import { defineStore } from "pinia";
import { genericFunctions, genericStates } from "@/tools/genericFunctions.js";

export const useCampaignDraftStore = defineStore({
  id: "campaignDraft",
  state: () => ({
    ...genericStates,
    participantTags: [],
    campaignDraft: null,
    campaignDrafts: null,

  }),
  actions: {
    ...genericFunctions,
    async fetchCampaignDraft() {
      try {
        const campaignStore = useCampaignDraftStore();
        await campaignStore.fetchEntity(
          `/api/v2/interview_app/campaign_draft`,
          "campaign_draft",
          "campaignDraft"
        );
      } catch (error) {
        console.log(error);
      }
    },
    async fetchCampaignDrafts() {
      await this.fetchEntity("/api/v2/interview_app/campaign_draft/schedule_campaign_drafts", "campaign_drafts", "campaignDrafts");
    },
    async resetStates() {
      this.campaignDrafts = null
    },
    async fetchCampaignDraftRecurrent(id) {
      const campaignStore = useCampaignDraftStore()

      await campaignStore.fetchEntity(`/api/v2/interview_app/campaign_drafts/${id}`, 'campaign_draft', 'campaignDraftRecurrent')
      return this.campaignDraftRecurrent
    },
    async updateCampaignDraftRecurrent(id, params) {
      this.campaignDraft = null;
      const campaignStore = useCampaignDraftStore()

      await campaignStore.updateEntity(`/api/v2/interview_app/campaign_drafts/${id}/update_recurrent`, params)
    },
    async updateCampaignDraft(opts) {
      const campaignStore = useCampaignDraftStore();
      await campaignStore.updateEntity(
        `/api/v2/interview_app/campaign_draft`,
        opts
      );
    },
    async createCampaign({ sendEmail = false, launchSchedule = false } = {}) {
      const campaignStore = useCampaignDraftStore();
      await campaignStore.createEntityWithoutCatch(
        "/api/v2/interview_app/campaign_draft/launch",
        { send_invitation: sendEmail, launch_schedule: launchSchedule }
      );
    },
    async fetchParticipantTags(tagCategoryId) {
      try {
        await this.fetchEntity(
          `/api/v2/interview_app/campaign_draft/participant_tags?tag_category_id=${tagCategoryId}`,
          "tags",
          "participantTags"
        );
      } catch (error) {
        console.log(error);
      }
    },
    async deleteCampaignDraft(id) {
      try {
        await this.deleteEntity(
          `/api/v2/interview_app/campaign_drafts/${id}`,
          "campaignDraft"
        );
      } catch (error) {
        console.log(error);
      }
    },
  },
});
