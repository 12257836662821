<template>
  <div
    class="w-full flex items-center justify-between px-1 py-2 border-b border-darkGrey sticky top-[63px] bg-white z-[5] bg-surface"
    :class="[type === 'show' ? 'justify-normal' : 'justify-end', modal ? 'top-0' : 'top-[72px]']">
    <!-- Go back button -->
    <svn-pro-icon-button
      v-if="type === 'show'"
      variant="text"
      color="onSurfaceVariant"
      icon="custom:mingcute:arrow-left-line"
      @click="emit('close-and-go-back')"
    />

    <div class="w-fit h-full flex items-center self-end">
      <!-- Edit button -->
      <svn-pro-icon-button
        v-if="amICreatorOfEntityOrAdmin(moduleCreatorId) && type === 'show'"
        variant="text"
        color="onSurfaceVariant"
        icon="custom:mingcute:edit-2-line"
        @click="emit('edit-module')"
      />

      <!-- Preview module button -->
      <slot v-if="type === 'draft' || type === 'edit'" name="preview-module" />

      <!-- Module options button -->
      <slot v-if="type === 'draft' || type === 'edit'" name="dots-button" />

      <!-- Publish module button -->
      <slot v-if="type === 'draft'" name="publish-module" />

      <!-- Comments button -->
      <svn-pro-icon-button
        v-if="type === 'show'"
        variant="text"
        color="onSurfaceVariant"
        icon="custom:mingcute:chat-4-line"
        @click="toggleComments"
      />

      <!-- Favorite button -->
      <svn-pro-icon-button
        v-if="type === 'show'"
        variant="text"
        :color="isFavorited ? 'primary' : 'onSurfaceVariant'"
        :icon="isFavorited ? 'custom:mingcute:heart-fill' : 'custom:mingcute:heart-line'"
        @click="emit('toggle-favorite')"
      />

      <!-- Copy link button -->
      <svn-pro-icon-button
        v-if="type === 'show'"
        variant="text"
        color="onSurfaceVariant"
        icon="custom:mingcute:link-line"
        @click="emit('copy-link')"
      />

      <!-- Close and Go back button -->
      <svn-pro-icon-button
        v-if="type !== 'show'"
        variant="text"
        color="onSurfaceVariant"
        icon="custom:mingcute:close-line"
        @click="emit('close-and-go-back')"
      />
    </div>
  </div>
</template>

<script setup>
import { useUserStore } from "@/store/user.js";

const { amICreatorOfEntityOrAdmin } = useUserStore();

const props = defineProps({
  type: { type: String, default: "view" },
  modal: { type: Boolean, default: false },
  moduleCreatorId: { type: Number, default: null },
  isFavorited: { type: Boolean, default: false },
  drawerCommentsLargeScreen: { type: Boolean, default: false }
})

const toggleComments = () => {
  emit('toggle-comments')
}

const emit = defineEmits(['toggle-comments', 'toggle-favorite', 'copy-link', 'edit-module', 'close-and-go-back'])
</script>