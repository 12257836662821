<template>
  <svn-pro-modal
    v-if="realSnapshotsUpdated?.length || snapshotsCreated?.length"
    ref="modalSubmitWithRoadmap"
    :title="$t('These targets will be updated or created')"
    :width="isMdScreen ? 680 : ''"
    :primary-loading="loading"
    :action-one-title="$t('Confirm')"
    :action-two-title="$t('Cancel')"
    @click-primary-button="closeModal"
    @click-secondary-button="modalSubmitWithRoadmap.dialog = false"
  >
    <template #activator="{ props }">
      {{ null }}
    </template>

    <template #text>
      <div class="flex flex-col gap-2">
        <svn-pro-text body-medium regular color="onSurfaceVariant">
          {{ $t('If you confirm, all target creations and updates will be applied. You can follow this up in Roadmap app.') }}
        </svn-pro-text>

        <v-list v-if="snapshotsUpdated?.length || snapshotsCreated?.length">
          <v-list-item
            v-for="(targetUpdated, index) in [...realSnapshotsUpdated, ...snapshotsCreated]"
            :key="index"
            class="border-b border-borderColor border-opacity-100"
          >
            <div class="w-full flex flex-col items-start">              
              <svn-pro-text subtitle-large regular>
                {{ targetUpdated?.title }}
              </svn-pro-text>
  
              <svn-pro-text body-medium regular color="onSurfaceVariant">
                {{ targetUpdated?.type === 'Objective::Draft' ? $t('Created') : $t('Updated') }}
              </svn-pro-text>
            </div>
          </v-list-item>
        </v-list>
      </div>
    </template>
  </svn-pro-modal>

  <svn-pro-dialog
    v-else
    ref="modalSubmitWithRoadmap"
    :title="$t('None of the targets have been updated')"
    :action-one-title="$t('Continue')"
    :action-two-title="$t('Cancel')"
    :content-text="$t('This interview contains one or more targets. Do you want to continue anyway?')"
    @click-primary-button="lockInterview"
    @click-secondary-button="modalSubmitWithRoadmap.dialog = false"
  >
    <template #activator="{ props }">
      {{ null }}
    </template>
  </svn-pro-dialog>
</template>

<script setup>
import { storeToRefs } from 'pinia';
import { ref, watch, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import { useRouter } from 'vue-router';
import { useUserStore } from '@/store/user';
import { useSnackbar } from '@/store/snackbar';
import { useMobileStore } from "@/store/mobile";
import axiosService from "@/tools/axios-service.js";
import { useInterviewStore } from "@/store/interview.js";
import { useTargetSuggestionsStore } from '@/store/targets-suggestions';

const route = useRoute();
const router = useRouter();
const snackbar = useSnackbar();
const { clearInterview, lock } = useInterviewStore();

const { isMdScreen } = storeToRefs(useMobileStore());
const { id, interviewKind } = storeToRefs(useInterviewStore());
const { snapshotsUpdated, snapshotsCreated } = storeToRefs(useTargetSuggestionsStore());

onMounted(() => {
  // if (snapshotsUpdated.value?.length) {
  //   snapshotsUpdated.value.forEach((target) => {
  //     let currentUserSuggestion = target?.current_suggestion
  //     if (currentUserSuggestion) {
  //       realSnapshotsUpdated.value.push(target)
  //     }
  //   })
  // }
});

const emit = defineEmits(['close-modal'])

const loading = ref(false);
const modalSubmitWithRoadmap = ref(false);
const realSnapshotsUpdated = ref([]);

const lockInterview = async () => {
  loading.value = true;

  try {
    await lock();
    snackbar.setBgColor("onSurface").setMsg("Your interview has been submitted, your answers are locked.").displaySnackBar();
  } catch (error) {
    snackbar.setBgColor("onSurface").setMsg("Your interview could not be submitted ! Please try again.").displaySnackBar();
  }

  if (interviewKind?.value === "employee" || interviewKind?.value === 'feedback')
    await router.push({ name: "my_interviews" });
  else if (interviewKind?.value === "manager")
    await router.push({ name: "my_team_interviews" });
  else if (interviewKind?.value === "pairs")
    await router.push({ name: "my_interviews" });
  else if (interviewKind?.value === "survey")
    await router.push({name: "my_interviews"})
  else if (interviewKind?.value === "crossed")
    await router.push({ name: "my_team_interviews" });
  else await router.push({ name: "home" });

  clearInterview();
  loading.value = false;
};

const closeModal = () => {
  modalSubmitWithRoadmap.value.dialog = false
  emit('close-modal');
}

defineExpose({
  modalSubmitWithRoadmap
})

watch(snapshotsUpdated, (newValue, oldValue) => {
  if (newValue?.length) {
    newValue.forEach((target) => {
      let currentUserSuggestion = target?.current_suggestion
      if (currentUserSuggestion && !realSnapshotsUpdated?.value?.find(el => el?.id === target?.id)) {
        realSnapshotsUpdated.value.push(target)
      }
    })
  }
})
</script>