<template>
  <apps-header
    app-icon="mingcute:chat-3-line"
    :app-title="$t('Interview')"
    :links="getLinks"
  >
    <router-view />
  </apps-header>
</template>

<script setup>
import AppsHeader from "../components/header/AppsHeader.vue";
import { computed } from "vue";
import { useTheme } from "vuetify";
import { useUserStore } from "@/store/user.js";

const { isManagerOrAbove, isManagerCreatorOrAbove } = useUserStore();
const theme = useTheme();
theme.global.name.value = "alephLight";

const links = [
  { title: "My interviews", linkName: "my_interviews" },
  { title: "My Team Interviews", linkName: "my_team_interviews" },
  { title: "Campaigns", linkName: "campaigns" },
  { title: "Templates", linkName: "interview_forms" },
  { title: "Reports", linkName: "reports" },
];

const getLinks = computed(() => {
  return links.filter((link) => {
    if (
      link.linkName === "reports" ||
      link.linkName === "campaigns" ||
      link.linkName === "interview_forms"
    ) {
      if (isManagerCreatorOrAbove()) {
        return true;
      }
      return false;
    } else if (link.linkName === "my_team_interviews") {
      if (isManagerOrAbove()) {
        return true;
      }
      return false;
    } else {
      return true;
    }
  });
});
</script>