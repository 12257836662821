import LearnTraining from '@/models/learn_training';

export default class User {
  constructor({
    id,
    firstname,
    lastname,
    fullname,
    email,
    avatar,
    jobTitle,
    manager,
    completedLearnModulesCount,
    completedLearnTrainingCount,
    todoLearnTrainingCount,
    learnTrainings,
  }) {
    this.id = id;
    this.firstname = firstname;
    this.lastname = lastname;
    this.fullname = fullname;
    this.email = email;
    this.avatar = avatar;
    this.jobTitle = jobTitle;
    this.manager = manager;
    this.completedLearnModulesCount = completedLearnModulesCount;
    this.completedLearnTrainingCount = completedLearnTrainingCount;
    this.todoLearnTrainingCount = todoLearnTrainingCount;
    this.learnTrainings = learnTrainings;
  }

  static fromJson(data) {
    const json = {};

    json['id'] = data?.id;
    json['firstname'] = data?.firstname;
    json['lastname'] = data?.lastname;
    json['fullname'] = data?.fullname;
    json['email'] = data?.email;
    json['avatar'] = data?.avatar ?? null;
    json['jobTitle'] = data?.job_title ?? null;
    json['manager'] = data?.manager ?? null;
    json['completedLearnModulesCount'] = data?.['completed_learn_modules_count'];
    json['completedLearnTrainingCount'] = data?.['completed_learn_training_count'];
    json['todoLearnTrainingCount'] = data?.['todo_learn_training_count'];
    json['learnTrainings'] = data?.['learn_trainings']?.map(LearnTraining.fromJson) ?? [];

    return new User(json);
  }

  getFullName() {
    return `${this.firstname} ${this.lastname}`;
  }

  hasManager() {
    return this.manager !== null;
  }
}
