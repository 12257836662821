<template>
  <div
    class="w-full flex flex-col gap-6 mx-auto"
    :class="campaign?.campaign_type !== 'OneToOne' ? 'md:!flex-row' : ''"
  >
    <div
      class="w-full flex flex-col gap-6 mx-auto lg:!flex-row justify-center items-center"
      :class="campaign?.campaign_type === 'OneToOne' ? '' : 'max-w-[368px]'"
    >
      <completion-all-data
        v-if="completionAllData"
        :campaign="campaign"
        :chart-colors="chartColors"
        :loading="loadingCompletionAll"
        :completion-all-data="completionAllData"
        :chart-completion-all-options="chartCompletionAllOptions"
      />
      
      <completion-individual
        v-if="campaign?.campaign_type === 'OneToOne'"
        :campaign="campaign"
        :loading="laodingCompletionIndividual"
        :completion-individual="completionIndividualData"
        :chart-completion-individual="chartCompletionIndividual"
        :chart-completion-individual-options="chartCompletionIndividualOptions"
      />
    </div>
    
    <chart-completion-category :campaign="campaign" />

    <div />
  </div>
</template>

<script setup>
import { useI18n } from "vue-i18n";
import { storeToRefs } from "pinia";
import { useRoute } from "vue-router";
import { ref, onMounted, computed } from "vue";
import { useCampaignStore } from "@/store/campaign.js";
import CompletionAllData from "../campaign/CompletionAllData.vue";
import { CampaignTypeBackend } from "@/constants/CampaignTypeEnum.js";
import CompletionIndividual from "../campaign/CompletionIndividual.vue";
import ChartCompletionCategory from "../campaign/ChartCompletionCategory.vue";

const route = useRoute();
const { t } = useI18n();
const { fetchCompletionIndividual } = useCampaignStore();

const { campaign, completionIndividual, completionAll, laodingCompletionIndividual, loadingCompletionAll } = storeToRefs(useCampaignStore());

onMounted(async () => {
  if (campaign?.value?.campaign_type === CampaignTypeBackend?.ONE_TO_ONE) {
    await fetchCompletionIndividual(campaign?.value?.id || route?.params?.id);
    completionIndividualData.value = completionIndividual?.value
  }
})

const chartColors = ref(['#1C6D1E', '#CA8100', '#BA1A1A', '#46464F']);
const chartLabels = ref([t('Submitted'), t('In progress'), t('Not started'), t('Not available yet')]);
const completionIndividualData = ref([])
const completionAllData = computed(() => {
  return [
    completionAll?.value?.['submitted'],
    completionAll?.value?.['in_progress'],
    completionAll?.value?.['not_started'],
  ]
})
const chartCompletionIndividual = computed(() => {
  return {
    labels: [
      t('Interviewee'),
      t('Interviewer'),
      t('Cross'),
    ],
    datasets: [
      {
        label: chartLabels?.value?.[0],
        backgroundColor: chartColors?.value[0],
        data: completionIndividual?.value ? Object.entries(completionIndividual?.value?.submitted)
          .sort(([keyA], [keyB]) => chartLabels?.value?.indexOf(keyA) - chartLabels?.value?.indexOf(keyB))
          .map(([, value]) => value) : null,
        maxBarThickness: 20,
      },
      {
        label: chartLabels?.value?.[1],
        backgroundColor: chartColors?.value[1],
        data: completionIndividual?.value ? Object.entries(completionIndividual?.value?.in_progress)
          .sort(([keyA], [keyB]) => chartLabels?.value?.indexOf(keyA) - chartLabels?.value?.indexOf(keyB))
          .map(([, value]) => value) : null,
        skipNull: true,
        maxBarThickness: 20,
      },
      {
        label: chartLabels?.value?.[2],
        backgroundColor: chartColors?.value[2],
        data: completionIndividual?.value ? Object.entries(completionIndividual?.value?.not_started)
          .sort(([keyA], [keyB]) => chartLabels?.value?.indexOf(keyA) - chartLabels?.value?.indexOf(keyB))
          .map(([, value]) => value) : null,
        skipNull: true,
        maxBarThickness: 20,
      },
      {
        label: chartLabels?.value?.[3],
        backgroundColor: chartColors?.value[3],
        data: completionIndividual?.value ? Object.entries(completionIndividual?.value?.not_available_yet)
          .sort(([keyA], [keyB]) => chartLabels?.value?.indexOf(keyA) - chartLabels?.value?.indexOf(keyB))
          .map(([, value]) => value) : null,
        skipNull: true,
        maxBarThickness: 20,
      }
    ]
  }
});

const chartCompletionAllOptions = {
  cutout: "55%",
  maintainAspectRatio: true,
  responsive: true,
  plugins: {
    legend: {
      position: "left",
      align: "center",
      title: {
        display: false
      },
      padding: {
        x: 0,
        y: 0
      }
    },
  },
}

const chartCompletionIndividualOptions = {
  maintainAspectRatio: false,
  plugins: {
    legend: {
      position: "bottom",
      align: "start"
    }
  }
}
</script>