<template>
  <div
    class="w-full sm:max-w-[348px] md:max-w-[472px] h-[302px]
    lg:max-w-[378px] xl:max-w-[341px] 2xl:w-full flex flex-col gap-4 skeleton
    rounded-[12px] border border-darkGrey p-6 cursor-progress self-stretch"
  >
    <div class="rounded-[12px] overflow-hidden">
      <v-skeleton-loader
        type="image"
        class="h-[190px] image"
      />
    </div>

    <div class="flex flex-col h-[46px]">
      <v-skeleton-loader
        type="list-item"
        class="item"
      />

      <v-skeleton-loader
        type="subtitle"
        class="item"
      />
    </div>
  </div>
</template>

<style scoped>
.image :deep(.v-skeleton-loader__image) {
  height: 190px;
}

.item :deep(.v-skeleton-loader__list-item) {
  margin-top: 0;
  margin-bottom: 0;
}
</style>
