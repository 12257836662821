<template>
  <div class="w-full h-full relative bg-surface">
    <div class="w-full h-full max-w-[1136px] mx-auto flex flex-col gap-4 items-center md:!items-start pt-5">
      <div class="w-full flex flex-col gap-4">
        <div class="w-full flex gap-4 px-5 justify-between items-center">
          <svn-pro-title :h5="!isMobile" :h6="isMobile" medium>
            {{ $t(`Campaigns (${(campaignPagination?.total_count || 0)})`) }} {{  }}
          </svn-pro-title>

          <div class="flex items-center gap-2">
            <svn-pro-button
              variant="flat"
              prepend-icon="custom:mingcute:add-line"
              :text="$t('New campaign')"
              class="hidden md:flex"
              @click="goToNewCampaign"
            />

            <svn-pro-menu>
              <template #activator="{ props }">
                <svn-pro-icon-button
                  v-bind="props"
                  icon="custom:mingcute:more-2-fill"
                  color="onSurfaceVariant"
                  variant="text"
                />
              </template>
              <template #dropdown>
                <v-list-item
                  @click="openDeletedItemModal"
                  :active="false"
                  value="view_deleted"
                >
                  <svn-pro-text body-large regular>
                    {{ $t('View deleted campaigns') }}
                  </svn-pro-text>
                </v-list-item>
              </template>
            </svn-pro-menu>
          </div>
        </div>
        <!-- Tabs -->
        <v-tabs
          v-model="tab"
          color="primary"
          class="px-5 "
          :grow="isMobile"
        >
          <!-- Current targets Tab -->
          <v-tab
            value="current"
            class="w-1/2 md:w-auto"
            :text="`${$t('Current')} (${campaignPagination?.total_count || 0})`"
          />

          <!-- Archived targets Tab -->
          <v-tab
            value="upcoming"
            class="w-1/2 md:w-auto"
            :text="`${$t('Upcoming')} (${campaignDraftPagination?.total_count || 0})`"
          />
        </v-tabs>

        <!-- Windows -->
        <v-window v-model="tab" class="px-5 ">
          <v-window-item value="current">
            <div :class="isMobile ? 'flex-col items-start' : 'flex-row items-center'" class="flex gap-4">
              <!-- Search -->
              <svn-pro-text-field
                v-model="searchText"
                class="w-full"
                variant="outlined"
                :placeholder="$t('Search')"
                prepend-inner-icon="custom:mingcute:search-2-line"
                @update:model-value="reloadAfterSearch"
              />

              <!-- Toggle Filter button -->
              <svn-pro-button
                :text="$t('Filter')"
                :append="activeFilterCount ? true : false"
                :prepend-icon="'custom:mingcute:filter-2-line'"
                :variant="activeFilterCount ? 'tonal' : 'text'"
                @click="toggleFilterVisibility"
              >
                <template v-if="activeFilterCount" #append>
                  {{ `(${activeFilterCount})` }}
                </template>
              </svn-pro-button>
            </div>

            <!-- Filters -->
            <svn-filter-results
              class="px-5 pt-5 xl:!px-0"
              :items="campaigns"
              :headers="filterHeaders"
              :should-show="shouldShow"
              table-name="interview_app_campaigns"
              @clear-all-filters="clearAllFilters"
              @refetch-data="fetchCampaignsAfterFilter"
              @update-active-filter-count="updateActiveFilterCount"
            />

            <div class="w-full h-full flex flex-col gap-4">
              <campaign-table
                @delete="deleteCampaign($event)"
                :search="searchText"
                @go-to-new-campaign="goToNewCampaign"
                :campaign-pagination="campaignPagination"
              />
            </div>
          </v-window-item>

          <v-window-item value="upcoming">
            <div :class="isMobile ? 'flex-col items-start' : 'flex-row items-center'" class="flex gap-4 ">
              <!-- Search -->
              <svn-pro-text-field
                v-model="draftSearchText"
                class="w-full"
                variant="outlined"
                :placeholder="$t('Search')"
                prepend-inner-icon="custom:mingcute:search-2-line"
                @update:model-value="reloadCampaignDraftsAfterSearch"
              />
            </div>

            <div class="w-full h-full flex flex-col gap-4">
              <campaign-draft-table
                :search="draftSearchText"
                @go-to-new-campaign="goToNewCampaign"
                />
            </div>
          </v-window-item>
        </v-window>

        <svn-pro-floating-action-button
          v-if="!isMdScreen"
          extended
          class="fixed bottom-4 right-4"
          variant="primary"
          :text="$t('New Campaign')"
          prepend-icon="custom:mingcute:add-line"
          @click="goToNewCampaign"
        />
      </div>
    </div>
  </div>

  <modal-view-deleted-items
    ref="deletedItemsRef"
    :headers="trashesHeaders"
    :items="trashedCampaigns"
    content-type="Campaign"
    @restore="restoreDeletedItems"
    @close-modal="closeModal"
    @toggle-search="searchDeletedItems"
    :pagination="trashCampaignPagination"
    @fetch-new-page="fetchTrashCampaign('', $event)"
    @delete-permanently="deletePermanently"
    @view-item="router.push({ name: 'campaign_show', params: { id: $event } })"

  >
    <template #activator="{ props }">
        {{ null }}
    </template>
  </modal-view-deleted-items>
</template>

<script setup>
import BktIndexSkeleton from "../../../components/BktIndexSkeleton.vue";
import { storeToRefs } from "pinia";
import CampaignIndexSearch from "./CampaignIndexSearch.vue";
import CampaignTable from "./CampaignTable.vue";
import CampaignDraftTable from "./CampaignDraftTable.vue";
import BktNoEntityInTable from "@/components/BktNoEntityInTable.vue";
import BktCreateEntityFromIndex from "../../../components/BktCreateEntityFromIndex.vue";
import BktNoEntityFromIndex from "../../../components/BktNoEntityFromIndex.vue";
import GroupCategoryFilter from "@/components/GroupCategoryFilter.vue";
import { onMounted, ref } from "vue";
import { useCampaignDraftStore } from "@/store/campaign-draft";
import i18n from "@/plugins/i18n.js";
import { useBreadcrumbsStore } from "@/store/breadcrumbs.js";
import { useUserStore } from "@/store/user";
import { debounce } from "lodash";
import {useCampaignStore} from "@/store/campaign.js";
import { useCampaignTrashStore } from "@/store/campaign-trash.js";
import { useMobileStore } from "@/store/mobile";
import { useSnackbar } from "@/store/snackbar";
import {useRouter} from "vue-router";
import ModalViewDeletedItems from '@/components/BktPopUp/Dialogs/learn/ModalViewDeletedItems.vue';
import SvnFilterResults from "@/components/SvnFilterResults.vue";

const { campaigns, params, pagination: campaignPagination } = storeToRefs(useCampaignStore());
const { trashedCampaigns, trashCampaignPagination } = storeToRefs(useCampaignTrashStore());
const { campaignDrafts, params: campaignDraftParams, pagination: campaignDraftPagination } = storeToRefs(useCampaignDraftStore());
const { fetchCampaigns, updateParams, setCampaigns, destroyCampaign } = useCampaignStore()
const { restoreCampaign, destroyPermanentlyCampaign, fetchTrashCampaign } = useCampaignTrashStore()
const { fetchCampaignDrafts, setCampaignDrafts, updateParams: updateParamsCampaignDraft } = useCampaignDraftStore();
const { addNode, reset, update } = useBreadcrumbsStore()
const { isManagerCreatorOrAbove } = useUserStore();
const router = useRouter();

const tab = ref(null);
const snackbar = useSnackbar();
const searchText = ref('');
const deletedItemsRef = ref(null);
const campaignIdToDelete = ref(null);

const activeFilterCount = ref(null);
const shouldShow = ref(false);
const draftSearchText = ref('')

const { isMobile, isMdScreen } = storeToRefs(useMobileStore())
const reloadAfterSearch = debounce(async(params) => {
  setCampaigns(null);
  updateParams({
    title: params,
    "page[number]": 1,
  });
  await fetchCampaigns();
}, 300)

const openDeletedItemModal = () => {
  deletedItemsRef.value.deletedItems.dialog = true
}

const reloadCampaignDraftsAfterSearch = async(params) => {
  setCampaignDrafts(null);
  updateParamsCampaignDraft({
    title: params,
    "page[number]": 1,
  });
  fetchCampaignDrafts();
}

const updateCampaignsFromGroupCategoryFilter = (e) => {
  updateParams({ tags: e });
  fetchCampaigns();
};

onMounted(() => {
  if (reset('/interviews/campaigns')) {
    addNode(i18n.global.t('Campaigns'), '/interviews/campaigns')
    update()
  }
    fetchCampaigns().catch(e => console.log(e));
    fetchCampaignDrafts().catch(e => console.log(e));
    fetchTrashCampaign().catch(e => console.log(e));
})

const deleteCampaign = async(id) => {
  await destroyCampaign(id);
  const trash = campaigns?.value?.find(trash => trash?.id === id)
  trash.deleted_at = new Date()
  trashedCampaigns?.value?.unshift(trash)
  campaigns.value = campaigns?.value?.filter(trash => trash?.id !== id)
  snackbar.setBgColor('onSurface')
  snackbar.setMsg('Campaign has been deleted successfully.')
  snackbar.setCustomClass(isMobile.value ? 'mb-[96px]' : 'mb-[42px]')
  snackbar.displaySnackBar()
};

const restoreDeletedItems = async (id) => {
  try {
    await restoreCampaign(id)
    campaigns?.value?.unshift(trashedCampaigns?.value?.find(trash => trash?.id === id))
    trashedCampaigns.value = trashedCampaigns?.value?.filter(trash => trash?.id !== id)
    snackbar.setBgColor('onSurface')
    snackbar.setMsg('Campaign has been restored successfully.')
    snackbar.setCustomClass(isMobile?.value ? 'mb-[96px]' : 'mb-[42px]')
    snackbar.displaySnackBar()
  } catch (e) {
    snackbar.setBgColor('error')
    snackbar.setMsg('Error while restoring a training')
    snackbar.setCustomClass(isMobile?.value ? 'mb-[96px]' : 'mb-[42px]')
    snackbar.displaySnackBar()
  }
}

const deletePermanently = async () => {
  const id = campaignIdToDelete.value

  try {
    await destroyPermanentlyCampaign(id)
    trashedCampaigns.value = trashedCampaigns?.value?.filter(trash => trash?.id !== id)
    snackbar.setBgColor('onSurface')
    snackbar.setMsg('Campaign has been deleted successfully.')
    snackbar.setCustomClass(isMobile?.value ? 'mb-[96px]' : 'mb-[42px]')
    snackbar.displaySnackBar()
  } catch (e) {
    snackbar.setBgColor('error')
    snackbar.setMsg('Error while deleting permanently a training')
    snackbar.setCustomClass(isMobile?.value ? 'mb-[96px]' : 'mb-[42px]')
    snackbar.displaySnackBar()
  }
}

const searchDeletedItems = debounce(async (value) => {
  try {
    await fetchTrashCampaign(value)
  } catch (e) {
    console.error(e)
  }
}, 300)

const goToNewCampaign = () => {
  router.push({ name: 'campaign_draft' })
}

const closeModal = (id) => {
  deletedItemsRef.value.deletedItems.dialog = false
  deletedItemsRef.value.deleteTargetRef.dialogRef.dialog = true
  campaignIdToDelete.value = id
}

const toggleFilterVisibility = () => {
  shouldShow.value = !shouldShow.value
}

const updateActiveFilterCount = (value) => {
  activeFilterCount.value = value
}

const clearAllFilters = async () => {
  params.value = {}
  activeFilterCount.value = null

  await fetchCampaigns()
}

const fetchCampaignsAfterFilter = async(data) => {
  if (data) {
    params.value = {
      filter_data: JSON.stringify(data),
    }
  } else {
    params.value = {}
  }

  await fetchCampaigns()
}
const trashesHeaders = ref([
  {
    align: "start",
    key: "title",
    sortable: true,
    title: i18n.global.t('Campaign title'),
    minWidth: 200,
  },
  {
    align: "start",
    key: "deleted_at",
    sortable: false,
    title: i18n.global.t('Deleted on'),
    minWidth: 150,
  },
  {
    align: "start",
    key: "restore",
    sortable: false,
    title:"",
    width: 40,
    minWidth: 40,
  },
  {
    align: "start",
    key: "delete",
    sortable: false,
    title: "",
    width: 40,
    minWidth: 40,
  }
])

const filterHeaders = ref([
  {
    name: "campaign_type",
    key: "campaign_type",
    title: i18n.global.t("Campaign type"),
    icon: "icon-park-outline:down-c",
  },
  {
    name: "title",
    key: "title",
    title: i18n.global.t("Campaign title"),
    icon: "ic:baseline-title",
  },
  {
    name: "startline",
    key: "startline",
    title: i18n.global.t("Period"),
    icon: "akar-icons:calendar",
  },
  {
    name: "employees_count",
    key: "employees_count",
    title: "Participants",
    icon: "mdi-account-outline",
  },
  {
    name: "completion",
    key: "completion",
    title: "Completion",
    icon: "material-symbols:percent",
  }
])
</script>
