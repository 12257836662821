import SevenImgObject from '@/models/seven_img_object';
import LearnModule from '@/models/learn_module';

export default class LearnTraining {
  constructor({
    id,
    allModulesDuration,
    description,
    status,
    title,
    moduleCompletion,
    moduleCount,
    moduleThemes,
    coverUrl,
    lastModuleAcquiredOn,
  }) {
    this.id = id;
    this.allModulesDuration = allModulesDuration;
    this.description = description;
    this.status = status;
    this.title = title;
    this.moduleCompletion = moduleCompletion;
    this.moduleCount = moduleCount;
    this.moduleThemes = moduleThemes;
    this.coverUrl = coverUrl;
    this.lastModuleAcquiredOn = lastModuleAcquiredOn;
  }

  static fromJson(data) {
    const json = {};

    json['id'] = data?.['id'];
    json['allModulesDuration'] = data?.['all_modules_duration'];
    json['description'] = data?.['description'];
    json['status'] = data?.['status'];
    json['title'] = data?.['title'];
    json['moduleCompletion'] = data?.['module_completion'];
    json['moduleCount'] = data?.['module_count'];
    json['moduleThemes'] = data?.['module_themes'];
    json['coverUrl'] = SevenImgObject.fromJson(data?.['cover_url']);
    json['lastModuleAcquiredOn'] = LearnModule.fromJson(data?.['last_module_acquired_on']);

    return new LearnTraining(json);
  }
}
