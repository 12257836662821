<template>
  <div class="w-full flex flex-col p-6 lg:!p-8 gap-4 lg:!gap-12 lg:!flex-row rounded-[8px] border border-borderColor border-opacity-100">
    <div class="flex justify-between lg:!flex-col items-center lg:!items-start gap-2 lg:!gap-4">
      <div class="flex flex-col lg:!flex-col gap-1 flex-1">
        <svn-pro-title h6 medium>
          {{ $t('Trained') }}
        </svn-pro-title>

        <svn-pro-text body-medium regular color="onSurfaceVariant">
          {{ $t('Number of people completing the training') }}
        </svn-pro-text>
      </div>

      <div class="flex items-center gap-1 md:!gap-2">
        <svn-pro-title :h4="isLgScreen" :h5="!isLgScreen" medium color="primary">
          {{ trainingCompletionAll?.acquired || 0 }}
        </svn-pro-title>

        <svn-pro-title :h4="isLgScreen" :h5="!isLgScreen" medium>
          {{ '/'}}
        </svn-pro-title>

        <svn-pro-title :h4="isLgScreen" :h5="!isLgScreen" medium>
          {{ trainingCompletionAll?.all }}
        </svn-pro-title>
      </div>
    </div>

    <div class="w-full flex flex-col lg:!flex-row gap-4 lg:!gap-12 lg:!text-center items-center">
      <div
        v-for="section in sections"
        :key="section"
        class="w-full flex flex-col lg:!flex-row gap-4 lg:!gap-12"
      >
        <svn-pro-divider :vertical="isLgScreen" color="[#767680]" class="border-opacity-100" />

        <div class="flex lg:!flex-col justify-between lg:!justify-start lg:!gap-4 items-center">
          <svn-pro-title h6 medium color="onSurfaceVariant">
            {{ $t(section?.title) }}
          </svn-pro-title>

          <svn-pro-title :h4="isLgScreen" :h5="!isLgScreen" medium>
            {{ section?.value }}
          </svn-pro-title>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { storeToRefs } from 'pinia';
import { useMobileStore } from '@/store/mobile';

const { isLgScreen } = storeToRefs(useMobileStore());

const props = defineProps({
  trainingCompletionAll: { type: Object, required: true },
})

const sections = computed(() => {
  return [
    {
      title: 'Participants',
      value: props?.trainingCompletionAll?.all,
    },
    {
      title: 'In progress',
      value: props?.trainingCompletionAll?.in_progress,
    },
    {
      title: 'Not started',
      value: props?.trainingCompletionAll?.not_started,
    },
  ]
});
</script>