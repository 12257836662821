<template>
  <svn-pro-data-table
    :headers="reports?.length ? headersTmp : []"
    :items="reports ?? []"
    :items-per-page="-1"
    :loading="reports ? loading : true"
    item-value="id"
    :pagination-total-pages="reportPagination?.total_pages"
    @update-pagination="fetchNewPage"
  >
    <template #item="{ item }">
      <tr class="hover:bg-[#46464F14] active:bg-[#46464F1F]/[0.12]">
        <td>
          <svn-pro-data-table-cell
            cell-type="avatar_text"
            :avatar="item?.creator?.avatar?.['50']"
            :text="`${item?.creator?.firstname} ${item?.creator?.lastname}`"
          />
        </td>

        <td>
          <svn-pro-data-table-cell
            cell-type="text"
            :text="$filters.formatSpecificDate(item?.created_at, 'YYYY-MM-DDTHH:mm:ss.sssZ', 'MM/DD/YY')"
          />
        </td>

        <td>
          <svn-pro-data-table-cell
            cell-type="emoji_text"
            :emoji="iconMode(item?.mode)"
            :text="strMode(item?.mode)"
          />
        </td>

        <td>
          <svn-pro-data-table-cell
            cell-type="text"
            :text="$filters.formatDate(item?.start_time)"
          />
        </td>


        <td>
          <svn-pro-data-table-cell
            cell-type="text"
            :text="$filters.formatDate(item?.end_time)"
          />
        </td>

        <td>
          <svn-pro-tooltip  v-if="item?.state === 'done'" :text="$t(`Download`)">
            <template #activator="{ props }">
              <a
              v-bind="props"
                :href="backendAutoLogin(item?.xlsx_path)"
                target="_blank"
              >
                <svn-pro-data-table-cell
                  cell-type="icon_button"
                  icon="custom:mingcute:download-2-line"
                  text=""
                />
              </a>
            </template>
          </svn-pro-tooltip>


          <div
            v-else
            class="flex justify-center items-center"
          >
            <svn-pro-progress-circular
              color="primary"
              indeterminate
              :size="24"
              width="2"
            />
          </div>
        </td>

        <td>
          <svn-pro-menu>
            <template #activator>
              <svn-pro-icon-button
                icon="custom:mingcute:more-2-line"
                variant="flat"
                color="surface"
              />
            </template>

            <template #dropdown>
              <svn-pro-dialog-validation
                :width="312"
                icon="noto:warning"
                :action-two-title="$t('Cancel')"
                :action-one-title="$t('Delete')"
                :title="$t('Report will be deleted')"
                :content-text="$t('Deleted reports are stored for 30 days. After this period, they will be permanently deleted.')"
                @click-primary-button="deleteInterviewReport(item?.id)"
              >
                <template #activator="{ props }">
                  <v-list-item v-bind="props" :active="false" density="compact" value="delete_report">
                    <svn-pro-text body-large regular color="error">
                      {{ $t('Delete') }}
                    </svn-pro-text>
                  </v-list-item>
                </template>
              </svn-pro-dialog-validation>
            </template>
          </svn-pro-menu>
        </td>
      </tr>
    </template>

    <template #no-data>
      <svn-pro-empty-states
        actions
        :variant="search ? 'results' : 'index'"
        :title="$t('Nothing to show for now')"
        :size="isMobile ? 'compact' : 'default'"
        :action-primary-title="$t('New report')"
        prepend-primary="custom:mingcute:add-line"
        :supporting-text="search ? $t('Oops, we didn’t find any results matching your search.') :
          $t('It seems you have no report for the moment.')"
        @click-primary="goToNewReport"
      />
    </template>

    <template #loading>
    </template>
  </svn-pro-data-table>
</template>

<script setup>
import {useReportStore} from "@/store/reports.js";
import {storeToRefs} from "pinia";
import useTools from "@/tools/useTools.js";
import { usePopUpStore } from "@/store/pop-up";
import i18n from '@/plugins/i18n'
import {useRouter} from "vue-router";
import { useMobileStore } from "@/store/mobile";
import { useSnackbar } from "@/store/snackbar.js";
import { debounce } from "lodash";

const router = useRouter();
const props = defineProps({
  search: { type: String, default: '' },
})

const snackbar = useSnackbar();
const { isMobile } = storeToRefs(useMobileStore());
const { backendAutoLogin } = useTools()
const { reports, reportPagination} = storeToRefs(useReportStore())
const { openPopUp, closePopUp } = usePopUpStore()
const {fetchReports, deleteReport, updateParams, setReports} = useReportStore()
const headersTmp = [
  {
    align: "start",
    key: "creator",
    sortable: false,
    title: i18n.global.t('Creator'),
    minWidth: 300,
  },
  {
    align: "start",
    key: "created_at",
    sortable: false,
    title: i18n.global.t('Created at'),
    minWidth: 150,
    width: 150
  },
  {
    align: "start",
    key: "type",
    sortable: false,
    title: i18n.global.t('Type'),
    minWidth: 150,
    width: 150
  },
  {
    align: "start",
    key: "from",
    sortable: false,
    title: i18n.global.t('From'),
    minWidth: 130,
    width: 130
  },
  {
    align: "start",
    key: "to",
    sortable: false,
    title: i18n.global.t('To'),
    minWidth: 150,
    width: 150
  },
  {
    align: "start",
    key: "downloads",
    sortable: false,
    minWidth: 50,
    width: 50
  },
  {
    align: "start",
    key: " ",
    sortable: false,
    title: "",
    minWidth: 50,
    width: 50
  }
];

const strMode = (mode) => {
  const arr = {
    analytics: "Analytics",
    data: "Global data",
    answers: "Answers",
    roadmap: 'Roadmap'
  }
  return arr[mode]
}
const iconMode = (mode) => {
  const arr = {
    analytics: "mingcute:chart-line-line",
    data: "mingcute:folder-line",
    answers: "mingcute:chat-4-line",
    roadmap: "mingcute:target-line",
  }
  return arr[mode]
}

const deleteInterviewReport = debounce(async(id) => {
  try {
    await deleteReport(id);
    snackbar.setCustomClass(isMobile?.value ? 'mb-[88px]' : '').setBgColor('onSurface').displaySnackBar(i18n.global.t('Report has been deleted successfully.'));
  } catch (error) {
    snackbar.setCustomClass(isMobile?.value ? 'mb-[88px]' : '').setBgColor('error').displaySnackBar(i18n.global.t('Error deleting your report !'));
  }
}, 300)
const fetchNewPage = (e) => {
  setReports(null);
  updateParams({
    search: props?.search,
    "page[number]": e,
  });
  fetchReports(props?.search, e);
};

const goToNewReport = () => {
  router.push({ name: 'edit_report' })
}

</script>
