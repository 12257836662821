<template>
  <div class="w-full flex flex-col gap-6 items-start">
    <div class="w-full flex flex-col md:flex-row-reverse gap-4">
      <svn-pro-select
        v-model="tab"
        :items="tabs"
        item-title="title"
        :label="$t('Type')"
        item-value="value"
        class="w-full md:!max-w-[320px]"
        hide-details
      />

      <svn-pro-text-field
        color="primary"
        variant="outlined"
        clearable
        clear-icon="mdi-close"
        v-model="searchText"
        :label="$t('Search')"
        prepend-inner-icon="custom:mingcute:search-2-line"
        @update:model-value="search"
      />

      <v-tabs
        v-model="tab"
        color="primary"
        class="hidden"
      >
        <v-tab
          value="modules"
          :text="$t('Modules')"
          class="w-[140px] md:!w-[180px]"
        />

        <v-tab
          value="trainings"
          :text="$t('Trainings')"
          class="w-[140px] md:!w-[180px]"
        />
      </v-tabs>
    </div>

    <v-window v-model="tab" class="!w-full">
      <v-window-item value="modules">
        <sub-tabs-in-learnings-module
          :user="user"
          :data="modulesByUser"
          :search-text="searchText"
          :headers="learningsHeadersModules"
          :pagination-data="paginationModules"
          @refetch-modules="refetchModules"
        />
      </v-window-item>

      <v-window-item value="trainings">
        <sub-tabs-in-learnings-training
          :user="user"
          :data="trainingsByUser"
          :search-text="searchText"
          :headers="learningsHeadersTrainings"
          :pagination-data="paginationTrainings"
          @refetch-trainings="refetchTrainings"
        />
      </v-window-item>
    </v-window>
  </div>
</template>

<script setup>
import i18n from '@/plugins/i18n';
import { debounce } from 'lodash';
import { storeToRefs } from "pinia";
import { ref, computed, onMounted } from "vue";
import { useMobileStore } from "@/store/mobile";
import { useLearnModuleStore } from "@/store/learn-module.js";
import { useLearnTrainingStore } from "@/store/learn-trainings.js";
import SubTabsInLearningsModule from "@/components/BktSubTabsLearningsInProfileModule.vue";
import SubTabsInLearningsTraining from "@/components/BktSubTabsLearningsInProfileTraining.vue";

const props = defineProps({
  user: { type: Object, required: true },
});

onMounted(async () => {
  try {
    if (paginationModules.value) {
      paginationModules.value.current_page = 1
    }
    await getModulesByUser(props.user.id, searchText.value);
    await getTrainingsByUser(props.user.id, searchText.value)
  } catch (error) {
    console.log(error)
  }
})

const { isMobile } = storeToRefs(useMobileStore());
const { pagination: paginationModules, modulesByUser } = storeToRefs(useLearnModuleStore());
const { pagination: paginationTrainings, trainingsByUser } = storeToRefs(useLearnTrainingStore());

const { getModulesByUser } = useLearnModuleStore();
const { getTrainingsByUser } = useLearnTrainingStore();

const searchText = ref('');
const tab = ref('information');
const tabs = ref([
  {
    value: "modules",
    title: i18n.global.t("Modules")
  },
  {
    value: "trainings",
    title: i18n.global.t("Trainings")
  }
])

const learningsHeadersModules = computed(() => {
  return [
    { title: "Name",
      key: "name",
      align: 'start',
      sortable: true,
      minWidth: 257,
    },
    { title: "Progress",
      key: "progress",
      align: 'start',
      sortable: false,
      width: 156,
      minWidth: 156,
    },
    { title: "Acquired on",
      key: "acquired",
      align: 'start',
      sortable: false,
      width: 156,
      minWidth: 156,
    }
  ]
});

const learningsHeadersTrainings = computed(() => {
  return [
    { title: "Name",
      key: "name",
      align: 'start',
      sortable: true,
      minWidth: 257,
    },
    { title: "Completion",
      key: "completion",
      align: 'start',
      sortable: false,
      width: 156,
      minWidth: 156,
    },
    { title: "Acquired on",
      key: "acquired",
      align: 'start',
      sortable: false,
      width: 156,
      minWidth: 156,
    }
  ]
});

const refetchModules = async({ userId, text, page = 1 }) => {
  try {
    await getModulesByUser(userId, text, page);
  } catch (error) {
    console.log(error)
  }
}

const refetchTrainings = async({ userId, text, page = 1 }) => {
  try {
    await getTrainingsByUser(userId, text, page);
  } catch (error) {
    console.log(error)
  }
}

const search = debounce(async() => {
  try {
    if (tab?.value === 'modules') {
      await getModulesByUser(props?.user?.id, searchText?.value, 1)
    } else if (tab?.value === 'trainings') {
      await getTrainingsByUser(props?.user?.id, searchText?.value, 1)
    }
  } catch (error) {
    console.log(error)
  }
}, 300)
</script>

<style scoped>

:deep(.v-input__control) {
  color: #333333;
  font-weight: 500;
}

</style>
