<template>
  <v-btn
    variant="text"
    :class="[
      `${assets[type]} ${customClass}`,
      iconify ? 'flex-row-between-centered' : 'flex-row-center-centered',
      'cursor-pointer'
    ]"
    :style="{ opacity: disable ? 0.5 : 1 }"
    @click="click"
  >
    <div
      v-if="iconify && left"
      class="flex-row-between-centered justify-content-center "
    >
      <Icon
        class="mr-3"
        :icon="iconify"
        width="20"
        height="20"
      />
    </div>
    <p class="text-base">
      <slot />
    </p>
    <div
      v-if="iconify && !left"
      class="flex-row-between-centered justify-content-center ml-3"
    >
      <Icon
        class="mr-3"
        :icon="iconify"
        width="20"
        height="20"
      />
    </div>
  </v-btn>
</template>

<script setup>
import {Icon} from "@iconify/vue";
import {defineComponent} from "vue";

const emit = defineEmits(["click"]);
const props = defineProps({
  iconify: {
    type: String,
    default: () => "",
  },
  type: {
    type: String,
    default() {
      return "transparent";
    },
  },
  href: {type: String, default: ""},
  customClass: {
    type: String,
    default() {
      return "";
    },
  },
  right: {type: String, default: ""},
  left: {
    type: Boolean,
    default() {
      return true;
    },
  },
  disable: {
    type: Boolean,
    default() {
      return false;
    },
  },
});
const click = (e) => {
  if (!props.disable) emit("click", e);
  if (!props.disable && props.href) window.location = props.href;
};
const assets = {
  transparent:
      " bkt-fakeblack-grey rounded-5px p-3 font-weight-500 bkt-bg-light-grey9-hover",
  employee:
      "bkt-btn-fakeblack-grey",
  "white-employee":
      "bkt-btn-fakeblack-grey-border",
  roadmap:
      "bkt-bg-objective-blue-important bkt-white-important rounded-5px p-3 font-semibold bkt-box-shadow-medium bkt-bg-light-blue2-hover",
  white:
      "bkt-bg-white-important bkt-objective-blue-important rounded-5px p-3 font-semibold bkt-box-shadow-medium bkt-bg-light-blue2-hover",
  "white-interview":
      "bkt-bg-white-important bkt-blue-important rounded-5px p-3 font-semibold bkt-box-shadow-medium bkt-bg-light-blue-hover border-bkt-blue ",
  interview:
      "bkt-bg-blue-important bkt-white-important rounded-5px p-3 font-semibold bkt-box-shadow-medium bkt-bg-light-blue2-hover",
  "white-grey":
      "bkt-light-grey6-important rounded-5px p-3 font-semibold bkt-box-shadow-medium",
  none: "",
};
</script>
