<template>
  <svn-pro-data-table
    v-model="selectedUsers"
    :headers="users?.length ? headers : []"
    :items="users ?? []"
    :items-per-page="-1"
    :loading="users ? loading : true"
    :show-select="users?.length ? true : false"
    item-value="id"
    :pagination-total-pages="pagination?.total_pages"
    @update-pagination="fetchNewPage"
  >
    <template #item="{ item }">
      <tr class="hover:bg-[#46464F14] active:bg-[#46464F1F]/[0.12]" @click="goToUserProfile(item?.id)">
        <td class="px-2">
          <v-checkbox-btn
            v-model="selectedUsers"
            hide-details
            :value="item.id"
            @click.stop
          />
        </td>

        <td>
          <svn-pro-data-table-cell
            medium
            body-large
            cell-type="avatar_text_supporting_text"
            :text="item?.fullname"
            :supporting-text="item?.email || null"
            :avatar="item?.avatar"
          />
        </td>

        <td>
          <svn-pro-data-table-cell
            cell-type="text"
            :text="formatAccessLevel(item?.access_level) || null"
          />
        </td>

        <td>
          <svn-pro-data-table-cell
            v-if="item?.manager"
            medium
            body-large
            cell-type="avatar_text_supporting_text"
            :text="item?.manager?.fullname"
            :supporting-text="item?.manager?.email"
            :avatar="item?.manager?.avatar?.['50']"
          />
        </td>

        <td>
          <svn-pro-data-table-cell
            cell-type="text"
            :text="$filters.formatSpecificDate(item?.hire_date, 'YYYY-MM-DD', 'DD MMM, YYYY') || null"
          />
        </td>

        <td
          v-for="tagCat in tagCategories"
          :key="tagCat.id"
        >
          <svn-pro-data-table-cell
            cell-type="text"
            :text="item.tags.find((tag) => tag.tag_category.id === tagCat.id)?.['tag_name'] || null"
          />
        </td>
      </tr>
    </template>

    <template #no-data>
      <svn-pro-empty-states
        actions
        :variant="search ? 'results' : 'index'"
        :title="$t('Nothing to show for now')"
        :size="isMobile ? 'compact' : 'default'"
        :action-primary-title="$t('New target')"
        prepend-primary="custom:mingcute:add-line"
        :supporting-text="search ? $t('Oops, we didn’t find any results matching your search.') :
          $t('Hmm, it seems you have no user yet.')"
        @click-primary=""
      />
    </template>

    <template #loading>
    </template>
  </svn-pro-data-table>
</template>

<script setup>
import { storeToRefs } from "pinia";
import { useRouter } from "vue-router";
import { useUserStore } from "@/store/user.js";
import { useMobileStore } from "@/store/mobile.js";
import { useCompanyStore } from "@/store/company.js";

const props = defineProps({
  search: { type: String, default: null },
  users: { type: Array, required: false },
  loading: { type: Boolean, required: true },
  pagination: { type: Object, default: () => {} },
  headers: { type: Array, default: () => [] }
})

const { isMobile } = storeToRefs(useMobileStore());
const { selectedUsers } = storeToRefs(useUserStore());
const { tagCategories } = storeToRefs(useCompanyStore());

const router = useRouter();
const { fetchUsers, updateParams, setUsers } = useUserStore();

const emit = defineEmits(["next", "reload-page"]);

const formatAccessLevel = (access_level) => {
  switch (access_level) {
    case "employee":
      return "Employee"
    case "manager":
      return "Manager"
    case "manager_creator":
      return "Manager - Creator"
    case "admin":
      return "Admin"
    case "super_admin":
      return "Super admin"
    default:
      return ""
  }
}

const goToUserProfile = (userId) => {
  const routeData = router.resolve({ name: "people_show", params: { id: userId }, query: { page: 'interviews' } });
  window.open(routeData.href, '_blank');
}

const fetchNewPage = (page) => {
  setUsers(null);
  updateParams({
    "page[number]": page,
  });
  fetchUsers();
};
</script>