<template>
  <v-dialog
    v-model="dialog"
    width="auto"
    :content-class="fullscreen ? '!rounded-none' : '!rounded'"
    :fullscreen="fullscreen"
  >
    <template #activator="{ props }">
      <div
        v-bind="bind ? props : null"
        class="flex justify-center"
        :class="fullWidthActivator ? 'w-full' : ''"
      >
        <slot
        name="button"
        >
          <v-btn
            class="bg-primary normal-case"
            variant="flat"
          >
            <slot name="button-text">
              {{ $t(buttonText) }}
            </slot>
          </v-btn>
        </slot>
      </div>
    </template>

    <slot name="dialog">
      <div
        class="flex flex-col p-6 bg-white !rounded-xl text-fake-black gap-8"
        :class="vertical ? width ? `w-[${width}px] sm:w-[${width}px]` : 'w-[300px] sm:w-[300px]' : width ? `w-[${width}px] sm:w-[${width}px]` : 'w-[400px] sm:w-[400px]'"
      >
        <div class="flex flex-col gap-4 relative">
          <slot
            name="close"
            :close="closeDialog"
          >
            <div
              v-if="displayClose"
              class="absolute right-0 top-0"
            >
              <Icon
                :icon="closeIcon"
                class="h-6 w-6 text-dark-grey hover:text-fake-black cursor-pointer"
                @click="dialog = false"
              />
            </div>
          </slot>
          <slot name="icon">
            <div
              v-if="icon"
              class="flex text-center justify-center"
            >
              <Icon
                v-if="icon"
                :icon="icon"
                :height="iconSize ? iconSize : 32"
                :width="iconSize ? iconSize : 32"
              />
            </div>
          </slot>

          <slot name="title">
            <div
              v-if="true"
              class="text-xl text-center font-medium text-fake-black break-words"
            >
              <slot name="title-text">
                {{ $t(title) }}
              </slot>
            </div>
          </slot>

          <slot name="subtitle">
            <div
              v-if="subtitle"
              class="text-sm font-semibold text-center text-dark-grey break-words"
            >
              <slot name="subtitle-text">
                {{ $t(subtitle) }}
              </slot>
            </div>
          </slot>

          <slot name="description">
            <div
              v-if="description"
              class="text-sm text-center text-dark-grey break-words"
            >
              <slot name="description-text">
                {{ $t(description) }}
              </slot>
            </div>
          </slot>

          <slot name="input" />

          <slot name="body" />
        </div>
        <slot name="actionButtons">
          <div
            class="w-full flex gap-3"
            :class="vertical ? 'flex-col' : 'flex-row  justify-center'"
          >
            <slot name="secondary-button">
              <v-btn
                v-if="displaySecondaryButton"
                class="text-fake-black normal-case text-xs"
                :color="buttonSecondaryColor"
                :class="secondaryButtonClass ? secondaryButtonClass : [vertical ? 'w-full' :'w-1/2', buttonSecondaryColor === 'primary' ? null : 'border-middle-grey']"
                variant="outlined"
                @click="secondaryButtonClick"
              >
                <slot name="secondary-button-body">
                  {{ $t(secondaryButtonText) }}
                </slot>
              </v-btn>
            </slot>
            <slot name="primary-button">
              <!-- pour le bouton en bas :class="vertical ? displaySecondaryButton ? 'w-1/2' : 'w-full' : displaySecondaryButton ? 'w-1/2' :'w-full sm:w-[170px]'"-->
              <v-btn
                v-if="displayPrimaryButton"
                class="normal-case w-full text-xs"
                :color="buttonPrimaryColor"
                :disabled="primaryButtonDisabled"
                :class="primaryButtonClass ? primaryButtonClass : vertical ? 'w-full' :'!w-1/2'"
                variant="flat"
                @click="primaryButtonClick"
              >
                <slot name="primary-button-body">
                  {{ $t(primaryButtonText) }}
                </slot>
              </v-btn>
            </slot>
          </div>
        </slot>
      </div>
    </slot>
  </v-dialog>
</template>

<script setup>
import { ref } from "vue";
import { Icon } from "@iconify/vue";

const dialog = ref(false)

const emit = defineEmits(['clickPrimaryButton', 'clickSecondaryButton'])

defineExpose({
  dialog
})

const props = defineProps({
  vertical: {
    type: Boolean,
    default: true
  },
  bind: {
    type: Boolean,
    default: false
  },
  buttonText: {
    type: String,
    default: 'Open Dialog'
  },
  icon: {
    type: String,
    default: ''
  },
  displayClose: {
    type: Boolean,
    default: true
  },
  fullscreen: {
    type: Boolean,
    default: false
  },
  closeIcon: {
    type: String,
    default: 'material-symbols:close'
  },
  title: {
    type: String,
    default: ''
  },
  subtitle: {
    type: String,
    default: ''
  },
  description: {
    type: String,
    default: ''
  },
  displayPrimaryButton: {
    type: Boolean,
    default: true,
  },
  primaryButtonDisabled: {
    type: Boolean,
    default: false,
  },
  primaryButtonText: {
    type: String,
    default: ''
  },
  primaryButtonClass: {
    type: String,
    default: ''
  },
  displaySecondaryButton: {
    type: Boolean,
    default: true,
  },
  secondaryButtonText: {
    type: String,
    default: ''
  },
  secondaryButtonClass: {
    type: String,
    default: ''
  },
  iconSize: {
    type: Number,
    default: 0
  },
  width: {
    type: Number,
    default: 0
  },
  buttonPrimaryColor: {
    type: String,
    default: 'primary'
  },
  buttonSecondaryColor: {
    type: String,
    default: 'fakeBlack'
  },
  fullWidthActivator: {
    type: Boolean,
    default: false
  }

})

const closeDialog = () => {
  dialog.value = false
}

const secondaryButtonClick = () => {
  closeDialog()
  emit('clickSecondaryButton')
}

const primaryButtonClick = () => {
  closeDialog()
  emit('clickPrimaryButton')
}
</script>
