import { LearnInputType } from 'svn-ui-library/components';

export default class LearnInput {
  constructor({
    id,
    type,
    title,
    text,
    proposals,
    editorContent,
    description,
    comment,
  }) {
    this.id = id;
    this.type = type;
    this.title = title;
    this.text = text;
    this.proposals = proposals || [];
    this.editorContent = editorContent;
    this.description = description;
    this.comment = comment;
  }

  isCheckBoxQuestion() {
    return this.type === LearnInputType.CHECKBOX;
  }

  static fromJson(data) {
    const {
      id,
      type,
      title,
      text,
      proposals,
      editor_content: editorContent,
      description,
      comment,
    } = data;
    return new LearnInput({
      id,
      type,
      title,
      text,
      proposals,
      editorContent,
      description,
      comment,
    });
  }

  toJson() {
    return {
      id: this.id,
      type: this.type,
      title: this.title,
      text: this.text,
      proposals: this.proposals,
      editor_content: this.editorContent,
      description: this.description,
      comments: this.comment,
      value: { comment: this.comment },
    };
  }
}
