import LearnResponse from '@/models/learn_response';
import { SvnProGraphTargetProgress } from 'svn-ui-library/components';
import User from './user';

export default class Submission {
  constructor({
    id,
    status,
    expert,
    learnResponsesCheckboxes,
    currentProgression,
    learnResponsesAnswers,
    learnResponsesCheckboxesCount,
  }) {
    this.id = id;
    this.status = status;
    this.expert = expert;
    this.learnResponsesCheckboxes = learnResponsesCheckboxes;
    this.learnResponsesAnswers = learnResponsesAnswers;
    this.currentProgression = currentProgression;
    this.learnResponsesCheckboxesCount = learnResponsesCheckboxesCount;
  }

  acquired() {
    return this.status === 'acquired';
  }

  progressionNbr() {
    if (this.learnResponsesCheckboxesCount === 0) return 0;

    return Math.round((this.currentProgression / this.learnResponsesCheckboxesCount) * 100);
  }

  zero() {
    return this.currentProgression === 0;
  }

  inProgress() {
    return (
      this.currentProgression !== 0 &&
      this.currentProgression !== this.learnResponsesCheckboxesCount
    );
  }

  completed() {
    return this.currentProgression === this.learnResponsesCheckboxesCount;
  }

  progressionToSvnProGraphTargetProgress() {
    if (this.zero()) {
      return SvnProGraphTargetProgress.notStarted;
    } else if (this.inProgress()) {
      return SvnProGraphTargetProgress.inProgress;
    } else if (this.completed()) {
      return SvnProGraphTargetProgress.achieved;
    }
  }

  getProgressionInfo() {
    if (this.zero()) {
      return 'Check your mistakes and try again';
    } else if (this.inProgress()) {
      return 'Keep going! Check your mistakes and try again';
    } else if (this.completed()) {
      return 'Only right answers ! Congratulations, 👏';
    }
  }

  getTitleTextCardSectionQuizResultLearn() {
    if (this.completed()) {
      return '👌 Here is one more module acquired';
    } else {
      return '💪 Ready to try again?';
    }
  }

  getDescTextCardSectionQuizResultLearn() {
    if (this.completed()) {
      return 'Congratulations, you just acquired a new module, keep it up! ';
    } else {
      return 'You can take this quiz as many times as needed. 100% of correct answers will mark this module as acquired.';
    }
  }

  static fromJson(data) {
    const json = {};

    json['id'] = data?.['id'];
    json['status'] = data?.['status'];
    json['expert'] = User.fromJson(data?.['expert']);
    json['learnResponsesCheckboxes'] =
      data?.['learn_responses_checkboxes']?.map(LearnResponse.fromJson) ?? [];
    json['learnResponsesAnswers'] =
      data?.['learn_responses_answers']?.map(LearnResponse.fromJson) ?? [];
    json['currentProgression'] = data['current_progression'];
    json['learnResponsesCheckboxesCount'] = data['learn_responses_checkboxes_count'];

    return new Submission(json);
  }
}
