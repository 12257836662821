<template>
  <div
    class="flex flex-col justify-start items-center gap-4 w-full h-full realtive pb-[80px] xl:!pb-[20px] xl:w-[580px]"
  >
    <!-- Close button -->
    <div
      class="absolute top-6 right-6"
      @click="closeDialog"
    >
      <Icon
        class="text-darkGrey cursor-pointer"
        icon="mdi-close"
        height="24"
      />
    </div>

    <!-- Title -->
    <div class="flex flex-col justify-center items-center stretch-center px-6 pt-[56px] xl:!pt[32px] pb-6">
      <p class="text-xl font-medium text-fakeBlack">
        {{ (selectedPlaylists.length === 0 || selectedPlaylists.length === 1) ?
          $t('Add this module to playlist ()', { selectedPlaylists: selectedPlaylists.length }) :
          $t('Add this modules to playlist ()', { selectedPlaylists: selectedPlaylists.length }) }} 
      </p>
    </div>

    <!-- Search -->
    <div class="w-full px-4">
      <v-text-field
        v-model="searchPlaylist"
        variant="outlined"
        density="comfortable"
        class="input"
        hide-details
        prepend-inner-icon="mdi-magnify"
        :placeholder="$t('Search')"
        @input="filterPlaylists()"
      />
    </div>

    <!-- Playlists list -->
    <div
      v-if="filterPlaylists.length"
      class="w-full h-full"
    >
      <div class="flex flex-col gap-y-1">
        <div
          v-for="(playlist, index) in filterPlaylists"
          :key="index"
          class="flex flex-row items-center px-4 py-2 gap-4 cursor-pointer"
          :class="selectedPlaylists.includes(playlist) ? 'bg-trainingOrangeSoft' : 'bg-white'"
          @click="toggleAddPlaylist(playlist)"
        >
          <div>
            <Icon
              :icon="selectedPlaylists.includes(playlist) ?
                'mdi-checkbox-marked' : 'mdi-checkbox-blank-outline'"
              height="18"
              width="18"
              :color="selectedPlaylists.includes(playlist) ?
                '#EF8C64' : 'black'"
            />
          </div>

          <playlist-card-list-item
            :playlist="playlist"
            class="w-full"
          />
        </div>
      </div>
    </div>

    <!-- No playlist -->
    <div
      v-else
      class="mx-auto my-auto flex flex-col justify-center items-center"
    >
      <p class="text-sm text-darkGrey font-medium">
        {{ $t('No Playlist for the moment.') }}
      </p>
    </div>

    <!-- Validate button -->
    <div class="px-4 w-full">
      <v-btn
        variant="flat"
        class="w-full normal-case"
        color="primary"
        :disabled="selectedPlaylists.length === 0"
        @click="addModuleToPlaylist(moduleId)"
      >
        <p class="text-white text-xs font-medium">
          {{ $t('Validate') }}
        </p>
      </v-btn>
    </div>
  </div>
</template>

<script setup>
import { Icon } from "@iconify/vue";
import PlaylistCardListItem from "../trainingApp/PlaylistCardListItem.vue";
import { ref, computed } from "vue";
import { storeToRefs } from "pinia";
import { usePopUpStore } from "@/store/pop-up";
import { useMobileStore } from "@/store/mobile";
import { useSnackbar } from "@/store/snackbar";

const props = defineProps({
  moduleId: { type: Number, default: null },
})

const emit = defineEmits(['close'])

const { isMobile , isXlScreen } = storeToRefs(useMobileStore())

const { closePopUp } = usePopUpStore()

const snackbar = useSnackbar()

const searchPlaylist = ref('')

const mockPlaylists = ref([
  {
    id: 0,
    playlistBackgrounds: [
      "https://www.powertrafic.fr/wp-content/uploads/2023/04/image-ia-exemple.png",
      "https://www.powertrafic.fr/wp-content/uploads/2023/04/image-ia-exemple.png",
      "https://www.powertrafic.fr/wp-content/uploads/2023/04/image-ia-exemple.png",
      "https://www.powertrafic.fr/wp-content/uploads/2023/04/image-ia-exemple.png",
      "https://www.powertrafic.fr/wp-content/uploads/2023/04/image-ia-exemple.png",
      "https://www.powertrafic.fr/wp-content/uploads/2023/04/image-ia-exemple.png",
      "https://www.powertrafic.fr/wp-content/uploads/2023/04/image-ia-exemple.png",
      "https://www.powertrafic.fr/wp-content/uploads/2023/04/image-ia-exemple.png",
      "https://www.powertrafic.fr/wp-content/uploads/2023/04/image-ia-exemple.png",
      "https://www.powertrafic.fr/wp-content/uploads/2023/04/image-ia-exemple.png",
      "https://www.powertrafic.fr/wp-content/uploads/2023/04/image-ia-exemple.png",
      "https://www.powertrafic.fr/wp-content/uploads/2023/04/image-ia-exemple.png",
    ],
    isFavorite: true,
    title: "Titre playlist #1",
    link: "https://www.google.com",
    themeList: ["Theme 1", "Theme 2", "Theme 3"],
    moduleCount: 12,
  },
  {
    id: 1,
    playlistBackgrounds: [
      "https://www.powertrafic.fr/wp-content/uploads/2023/04/image-ia-exemple.png",
    ],
    isFavorite: false,
    title: "Titre playlist #2",
    link: "https://www.google.com",
    themeList: ["Theme 1", "Theme 2"],
    moduleCount: 1,
  },
  {
    id: 2,
    playlistBackgrounds: [
      "https://www.powertrafic.fr/wp-content/uploads/2023/04/image-ia-exemple.png",
      "https://www.powertrafic.fr/wp-content/uploads/2023/04/image-ia-exemple.png",
    ],
    isFavorite: false,
    title: "Titre playlist #3",
    link: "https://www.google.com",
    themeList: ["Theme 1", "Theme 2", "Theme 3", "Theme 4", "Theme 5", "Theme 6"],
    moduleCount: 2,
  },
  {
    id: 3,
    playlistBackgrounds: [
      "https://www.powertrafic.fr/wp-content/uploads/2023/04/image-ia-exemple.png",
      "https://www.powertrafic.fr/wp-content/uploads/2023/04/image-ia-exemple.png",
      "https://www.powertrafic.fr/wp-content/uploads/2023/04/image-ia-exemple.png",
      "https://www.powertrafic.fr/wp-content/uploads/2023/04/image-ia-exemple.png",
    ],
    isFavorite: false,
    title: "Titre playlist #4",
    link: "https://www.google.com",
    themeList: ["Theme 1"],
    moduleCount: 4,
  },
  {
    id: 4,
    playlistBackgrounds: [],
    isFavorite: true,
    title: "Titre playlist #5",
    link: "https://www.google.com",
    themeList: ["Theme 1", "Theme 2"],
    moduleCount: 0,
  },
])

const selectedPlaylists = ref([])

const filterPlaylists = computed(() => {
  return mockPlaylists.value.filter((playlist) => playlist.title.includes(searchPlaylist.value.toLowerCase()))
})

const toggleAddPlaylist = (playlist) => {
  const found = selectedPlaylists.value.find((element) => element === playlist)

  if (found) {
    selectedPlaylists.value.splice(selectedPlaylists.value.findIndex((element) => element === playlist), 1)
  }
  else {
    selectedPlaylists.value.push(playlist)
  }
}

const closeDialog = () => {
  emit('close')

  if (isXlScreen.value) {
    closePopUp()
  }
}

const addModuleToPlaylist = (id) => {
  emit('close')

  if (isXlScreen.value) {
    closePopUp()
  }

  snackbar.setBgColor('positiveGreen')
  snackbar.setCustomClass(isMobile.value ? 'mb-[85px]' : 'mb-0')
  snackbar.setMsg((selectedPlaylists.value.length === 0 || selectedPlaylists.value.length === 1) ?
    `Module successfully added to ${selectedPlaylists.value.length} playlists.` :
    `Modules successfully added to ${selectedPlaylists.value.length} playlists.`)
  snackbar.displaySnackBar()

  selectedPlaylists.value = []
}
</script>