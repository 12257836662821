<template>
  <v-fade-transition>
    <!-- Skeleton loaders -->
    <div
      v-if="modulesPagesIsPending"
      class="grid grid-cols-1 mx-4 my-4 xl:!mx-0 gap-4 justify-center items-start self-stretch sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4"
    >
      <skeleton-loader-module-card v-for="item in 8" :key="item" />
    </div>

    <!-- Finished loading -->
    <div v-else class="mx-6 md:!mx-0">
      <!-- Search & Select -->
      <div class="w-full flex gap-4 my-4 flex-col md:!flex-row justify-start items-start md:!items-center">
        <!-- Search -->
        <svn-pro-text-field
          v-model="searchTermModules"
          :placeholder="$t('Search')"
          prepend-inner-icon="custom:mingcute:search-2-line"
          class="w-full"
          clearable
          clear-icon="custom:mingcute:close-line"
          @update:model-value="search"
        />

        <!-- Sort & Filter -->
        <div class="flex justify-center items-center">
          <!-- <svn-pro-select
              v-model="selectFilterModules"
              :label="$t('Sort by')"
              class="max-w[320px] min-w[112px]"
              :items="selectFilterList"
              item-title="display"
              item-value="value"
              @update:model-value="search"
            /> -->

          <!-- Filter -->
          <svn-pro-menu :close-on-content-click="false">
            <template #activator="{ props }">
              <svn-pro-button
                v-bind="props"
                class="w-fit"
                :active="selectedThemesListModules?.length > 0"
                :text="`${$t('Filter')} ${selectedThemesListModules?.length ? `(${selectedThemesListModules?.length})` : ''}`"
                prepend-icon="custom:mingcute:filter-2-line"
                variant="text"
              />
            </template>

            <template #dropdown-block>
              <div class="bg-surface rounded-[8px] shadow">
                <svn-pro-combobox
                  v-model="selectedThemesListModules"
                  :items="themes"
                  item-title="name"
                  :label="$t('Themes')"
                  class="!w-[210px] p-4"
                  :clearable="false"
                  multiple
                  return-object
                  hide-details
                  @update:model-value="search"
                />
              </div>
            </template>
          </svn-pro-menu>
        </div>
      </div>

      <!-- Filter results -->
      <div
        v-if="selectedThemesListModules?.length > 0"
        class="flex justify-between items-start self-stretch pt-4 xl:!px-0 xl:flex-wrap"
      >
        <!-- Themes list -->
        <div v-if="selectedThemesListModules?.length" class="flex flex-wrap gap-2">
          <svn-pro-chip
            v-for="theme in selectedThemesListModules"
            key="entityTag.id"
            class=""
            :text="theme.name"
            :is-slot-append="true"
          >
            <template #append>
              <Icon
                icon="mingcute:close-line"
                width="18"
                height="18"
                class="ml-2 cursor-pointer"
                @click="toggleThemeSelection(theme)"
              />
            </template>
          </svn-pro-chip>
        </div>

        <!-- Clear all themes selected -->
        <svn-pro-button
          v-if="selectedThemesListModules?.length > 1"
          @click="clearAllSelectedThemes"
          variant="text"
          :text="$t('Clear all')"
        />
      </div>

      <!-- Modules card list -->
      <v-infinite-scroll class="overflow-hidden w-full" color="primary" @load="loadMoreData">
        <div class="flex flex-wrap md:!grid md:!grid-cols-2 lg:!grid-cols-3 xl:!grid-cols-4 w-full gap-4 justify-center md:!justify-start items-center">
          <template v-for="page in moduleDraftsPages?.pages">
            <module-card
              v-for="learnModule in page?.learnModules"
              :learnModule="learnModule"
              :id="learnModule?.id"
              :key="learnModule?.id"
              :module-creator-id="learnModule?.creatorId"
              :background-image="learnModule?.coverUrl?.size500"
              :title="learnModule?.title"
              :reactions="learnModule?.userReactions"
              :is-favorite="learnModule?.connectedUserFavorited"
              :theme-list="learnModule?.themes?.map((theme) => theme?.name)"
              editable
              :duration="learnModule?.duration"
              :submission-status="learnModule?.submissionStatus"
              @toggle-favorite="toggleModuleFavorite(learnModule)"
              @go-to-item-show="goToModuleShowEdit(learnModule.id)"
              @duplicate-module="toggleDuplicateModule(learnModule?.id)"
              @delete-module="deleteLearnModule(learnModule?.id)"
            />
          </template>
        </div>

        <template #empty />
      </v-infinite-scroll>

      <!-- Empty states -->
      <div
        v-if="!moduleDraftsPages?.pages?.[0]?.learnModules?.length"
        class="flex text-center justify-center items-center"
      >
        <svn-pro-empty-states
          actions
          :variant="searchTermModules ? 'results' : 'index'"
          :title="$t('No module yet')"
          :size="isMdScreen ? 'default' : 'compact'"
          :action-primary-title="$t('New Module')"
          @click-primary="emit('goToNewModule')"
          prepend-primary="custom:mingcute:add-line"
          :supporting-text="emptyStateSupportingText"
        />
      </div>
    </div>
  </v-fade-transition>

  <!-- Dialog duplicate module -->
  <dialog-duplicate-module
    ref="duplicateModuleDialog"
    :loading="duplicateLoading"
    @duplicate-module="duplicateLearnModule"
  />
</template>

<script setup>
import router from '@/router';
import i18n from '@/plugins/i18n';
import { debounce } from 'lodash';
import { Icon } from '@iconify/vue';
import { storeToRefs } from 'pinia';
import { ref, computed } from 'vue';
import ModuleApi from '@/apis/module.api';
import { useSnackbar } from '@/store/snackbar';
import { useMobileStore } from '@/store/mobile';
import ModuleCard from '@/components/trainingApp/ModuleCard.vue';
import { useQueryClient, useInfiniteQuery } from '@tanstack/vue-query';
import SkeletonLoaderModuleCard from '@/components/skeletonLoaders/SkeletonLoaderModuleCard.vue';
import DialogDuplicateModule from '@/components/BktPopUp/Dialogs/learn/DialogDuplicateModule.vue';

const props = defineProps({
  themes: { type: Array, default: null },
});

const emit = defineEmits(['goToNewModule'])

const snackbar = useSnackbar();
const queryClient = useQueryClient();

const { isMdScreen } = storeToRefs(useMobileStore());

const selectFilterList = ref([
  {
    display: i18n.global.t('Decreasing publication date'),
    value: 'desc-publication',
  },
  {
    display: i18n.global.t('Increasing publication date'),
    value: 'asc-publication',
  },
  {
    display: i18n.global.t('Increasing acquisition date'),
    value: 'asc-acquisition',
  },
  {
    display: i18n.global.t('Decreasing acquisition date'),
    value: 'desc-acquisition',
  },
]);
const searchTermModules = ref('');
const duplicateLoading = ref(false);
const idModuleToDuplicate = ref(null);
const duplicateModuleDialog = ref(false);
const searchTermModulesDebounce = ref('');
const selectedThemesListModules = ref([]);
const selectedThemesListModulesDebounce = ref([]);
const selectFilterModules = ref(selectFilterList?.value[0].value);
const selectFilterModulesDebounce = ref(selectFilterModules.value);
const moduleCacheKey = computed(() => {
  return [
    'learn',
    'catalog',
    'draft',
    searchTermModulesDebounce.value,
    selectedThemesListModulesDebounce.value,
    selectFilterModulesDebounce.value,
  ];
});

const {
  data: moduleDraftsPages,
  error: modulesPagesError,
  fetchNextPage: moduleDraftsPagesFetchNextPage,
  hasNextPage: modulesPagesHasNextPage,
  isFetching: modulesPagesIsFetching,
  isFetchingNextPage: modulesPagesIsFetchingNextPage,
  isPending: modulesPagesIsPending,
  isError: modulesPagesIsError,
  refetch: moduleRefetch,
} = useInfiniteQuery({
  queryKey: moduleCacheKey,
  queryFn: ({ pageParam = 1, queryKey }) => {
    const [one, two, three, text, themeIds, sortBy] = queryKey;

    return ModuleApi.index({
      page: pageParam,
      text: text,
      themeIds: themeIds,
      sortBy: sortBy,
      draft: true
    });
  },
  getNextPageParam: (lastPage) => lastPage?.meta?.pagination?.nextPage,
});

const toggleThemeSelection = debounce(async (item) => {
  const found = selectedThemesListModules?.value?.find((theme) => theme?.id === item?.id);

  if (found) {
    selectedThemesListModules?.value?.splice(
      selectedThemesListModules?.value?.findIndex((theme) => theme?.id === item?.id),
      1,
    );
  } else {
    selectedThemesListModules?.value?.push(item);
  }
  search();
}, 300);

const toggleDuplicateModule = (id) => {
  idModuleToDuplicate.value = id;
  duplicateModuleDialog.value.dialogDuplicateModule = true;
};

const duplicateLearnModule = async (title) => {
  duplicateLoading.value = true
  try {
    const duplicated = await ModuleApi.duplicate(idModuleToDuplicate.value, title);

    snackbar.setMsg('Module duplicated.');
    snackbar.setCustomClass(isMdScreen.value ? 'mb-4' : 'mb-[72px]');
    snackbar.displaySnackBar();

    idModuleToDuplicate.value = null;
    duplicateModuleDialog.value.dialogDuplicateModule = false;

    setTimeout(() => {
      router.push({ name: 'module_edit', params: { id: duplicated.id } });
    }, 300);
  } catch (error) {
    snackbar.setMsg('Error duplicating module');
    snackbar.setCustomClass(isMdScreen.value ? 'mb-4' : 'mb-[72px]');
    snackbar.displaySnackBar();
  }
  duplicateLoading.value = false
};

const toggleModuleFavorite = debounce(async (module) => {
  try {
    if (!module) return;

    queryClient.setQueryData(moduleCacheKey.value, (oldData) => {
      // WARN: need to recreate objects otherwise vue query won't update cache !
      const tmpPages = [];

      for (const page of oldData?.pages) {
        const tmpLearnModuleDrafts = [];

        for (const learnModule of page?.learnModules) {
          if (learnModule?.id === module?.id) {
            tmpLearnModuleDrafts.push(
              learnModule.copyWith({
                connectedUserFavorited: !learnModule.connectedUserFavorited,
              }),
            );
          } else {
            tmpLearnModuleDrafts.push(learnModule);
          }
        }

        tmpPages.push(
          page.copyWith({
            learnModules: tmpLearnModuleDrafts,
          }),
        );
      }

      return {
        pageParams: tmpPages,
        pages: tmpPages,
      };
    })

    // Call api optimistic request
    module = await ModuleApi.toggleFavorite(module);

    snackbar.setBgColor('onSurface');

    if (module.connectedUserFavorited) {
      snackbar.setMsg('Module removed from favorites.');
    } else {
      snackbar.setMsg('Module added to favorites.');
    }
    snackbar.setCustomClass(isMdScreen.value ? 'mb-4' : 'mb-[72px]');
    snackbar.displaySnackBar();
  } catch (error) {
    console.log(error);
    snackbar.setBgColor('onSurface');
    snackbar.setMsg('Error toggling favorite');
    snackbar.setCustomClass(isMdScreen.value ? 'mb-4' : 'mb-[72px]');
    snackbar.displaySnackBar();
  }
}, 300);

const deleteLearnModule = async (id) => {
  try {
    await ModuleApi.delete(id);
    await moduleRefetch();

    snackbar.setMsg('Module has been deleted successfully.');
    snackbar.setCustomClass(isMdScreen.value ? 'mb-4' : 'mb-[72px]');
    snackbar.displaySnackBar();
  } catch (error) {
    snackbar.setMsg('Error deleting module');
    snackbar.setCustomClass(isMdScreen.value ? 'mb-4' : 'mb-[72px]');
    snackbar.displaySnackBar();
  }
};

const goToModuleShowEdit = (id) => {
  router.push({ name: 'module_show', params: { id: id } });
};

const clearAllSelectedThemes = () => {
  selectedThemesListModules.value = [];
  selectedThemesListModulesDebounce.value = []
};

const search = debounce(async () => {
  searchTermModulesDebounce.value = searchTermModules.value;
  selectedThemesListModulesDebounce.value = selectedThemesListModules?.value?.map((theme) => theme?.id);
  selectFilterModulesDebounce.value = selectFilterModules.value;
}, 300);

const loadMoreData = async ({ done }) => {
  if (modulesPagesHasNextPage.value) {
    await moduleDraftsPagesFetchNextPage();
    done('ok');
  } else {
    done('empty');
  }
};

const emptyStateSupportingText = computed(() => {
  return searchTermModules.value
    ? i18n.global.t('Oops, we didn’t find any results matching your search.')
    : i18n.global.t(`There are no modules. Create your first modules!`);
});
</script>