<template>
  <div class="flex flex-col gap-4">
    <div class="flex gap-2 md:!gap-3 items-center">
      <div>
        <Icon
          :height="isMdScreen ? 32 : 24"
          :width="isMdScreen ? 32 : 24"
          :icon="campaign_icon(campaignType)"
        />
      </div>

      <div class="flex flex-col">
        <svn-pro-title v-if="isMdScreen" h6 bold class="line-clamp-1">
          {{ campaignName }}
        </svn-pro-title>

        <svn-pro-text v-else subtitle-large bold class="line-clamp-1">
          {{ campaignName }}
        </svn-pro-text>

        <svn-pro-text
          :medium="isMdScreen"
          :body-medium="isMdScreen"
          :caption="!isMdScreen"
          color="onSurfaceVariant"
        >
          {{ $t('Deadline') }}: {{ $filters.formatDate(campaignDeadline, 'DD/MM/YYYY') }}
        </svn-pro-text>
      </div>
    </div>

    <v-divider class="border-opacity-100 border-[#767680]" />
  </div>
</template>

<script setup>
import { Icon } from "@iconify/vue";
import { storeToRefs } from "pinia";
import { useMobileStore } from "@/store/mobile";
import useCampaigns from "@/tools/useCampaigns.js";

const { campaign_icon } = useCampaigns();

const { isMdScreen } = storeToRefs(useMobileStore());

const props = defineProps({
  campaignName: { type: String, required: true },
  campaignType: { type: String, required: true },
  campaignDeadline: { type: String, required: true },
})
</script>
