<template>
  <div>
    <div class="flex-row-between-centered pb-3">
      <p class="fs-2rem font-medium bkt-fakeblack-grey mr-2rem" />
      <div
        class="cursor-pointer"
        @click="closePopUp()"
      >
        <Icon
          icon="charm:cross"
          width="25"
          height="25"
        />
      </div>
    </div>
    <div class="flex-row-center-centered">
      <Icon
        icon="noto:light-bulb"
        width="70"
        height="70"
      />
    </div>
    <div class="flex-row-center-centered text-center my-4 font-semibold">
      <p>
        {{ $t('Up to 3 interviews can be requiered to submit :') }}<br>
        {{ $t('Interviewee (IE), Interviewer (IR) and Cross Review (CR).') }}
      </p>
    </div>
    <div class="flex-row-center-centered text-center my-4 d-block">
      <p>{{ $t('Their different states are :') }}</p>
    </div>
    <div class="width-30 m-auto mb-4">
      <p class="flex-row-start-centered">
        <span class="h-2.5 w-2.5 inline-block mr-3 bkt-bg-light-grey" /> {{ $t('Not available yet') }}
      </p>
      <p class="flex-row-start-centered">
        <span class="h-2.5 w-2.5 inline-block mr-3 bkt-bg-red" /> {{ $t('Not Started') }}
      </p>
      <p class="flex-row-start-centered">
        <span class="h-2.5 w-2.5 inline-block mr-3 bkt-bg-yellow" /> {{ $t('In Progress') }}
      </p>
      <p class="flex-row-start-centered">
        <span class="h-2.5 w-2.5 inline-block mr-3 bkt-bg-green" /> {{ $t('Submitted') }}
      </p>
    </div>
  </div>
</template>

<script setup>
import { Icon } from "@iconify/vue";
import { usePopUpStore } from "../../store/pop-up.js";
const { closePopUp } = usePopUpStore();
</script>
