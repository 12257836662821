<template>
  <div class="w-full h-full flex flex-col bg-surface">
    <!-- Header with actions -->
    <div class="w-full h-[64px] sticky top-[64px] flex items-center justify-end gap-4 border-b border-borderColor border-opacity-100 px-5 md:!px-4 py-2 bg-surfaceBright z-[1]">  
      <!-- Summary button -->
      <svn-pro-icon-button
        variant="text"
        :color="(drawer || drawerLarge) ? 'primary' : 'onSurfaceVariant'"
        icon="custom:mingcute:list-ordered-line"
        @click="isLargeScreen ? openDrawer() : drawer = !drawer"
      />
  
      <!-- Download PDF button -->
      <bkt-download-button
        v-if="isMdScreen"
        :loading="downloadLoading"
        @click="clickDownload"
      />
    </div>

    <div class="w-full h-full flex">
      <div class="w-full flex flex-col gap-8 px-5 md:!px-0 py-5 md:!max-w-[674px] lg:!max-w-[614px] xl:!max-w-[752px] desktop:!max-w-[1136px] md:!py-6 mx-auto">
        <div class="flex flex-col gap-4">
          <!-- Campaign title -->
          <svn-pro-title :h5="!isMdScreen" :h4="isMdScreen" medium class="text-center">
            {{ campaign?.title }}
          </svn-pro-title>

          <!-- Question list -->
          <feedback-answer-list />
        </div>
      </div>

      <!-- Drawer Large -->
      <div
        class="h-full fixed right-0 overflow-y-auto flex flex-col border-l border-borderColor border-opacity-100 transition-all pb-6"
        :class="drawerLarge ? 'w-[320px]' : 'w-0'"
      >
        <!-- Header -->
        <div class="w-full sticky top-0 z-[5] flex justify-between items-center pl-6 pr-3 pt-3 pb-4 bg-surface">
          <svn-pro-title h6 regular color="onSurfaceVariant">
            {{ $t( 'Chapters' ) }}
          </svn-pro-title>

          <!-- Close button -->
          <svn-pro-icon-button
            variant="text"
            color="onSurfaceVariant"
            icon="custom:mingcute:close-line"
            @click.stop="drawerLarge = !drawerLarge"
          />
        </div>

        <div class="flex flex-col pb-[140px]">
          <!-- Questions List -->
          <div
            v-for="(item, i) in headlines"
            :key="i"
            class="w-full flex flex-col gap-2"
          >
            <template v-if="interviewKind === 'crossed' || item?.visible_for === interviewKind || item?.visible_for === 'all' && item?.type !== InterviewQuestionType?.PARAGRAPH">
              <svn-pro-text
                v-if="item?.type === InterviewQuestionType?.CHAPTER"
                body-large
                bold
                class="px-4"
                color="onSurface"
              >
                {{ item?.title }}
              </svn-pro-text>
  
              <v-list-item
                v-else-if="item.type !== InterviewQuestionType?.PARAGRAPH"
                :active="false"
                :value="item?.id"
                @click="scrollToQuestion(item?.id)"
              >
                <template #default>
                  <div class="flex items-center gap-4">
                    <div>
                      <Icon
                        :icon="item?.icon"
                        height="24"
                        width="24"
                        class="text-onSurfaceVariant"
                      />
                    </div>
  
                    <svn-pro-text subtitle-large regular class="line-clamp-1">
                      {{ item?.title }}
                    </svn-pro-text>
                  </div>
                </template>
              </v-list-item>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- FABs -->
  <div v-if="!isMdScreen" class="flex flex-col items-end gap-4 fixed bottom-4 right-4">
    <svn-pro-button
      :height="56"
      color="primary"
      :loading="downloadLoading"
      :text="$t('Download pdf')"
      prepend-icon="custom:mingcute:download-2-line"
      @click="clickDownload"
    />
  </div>

  <!-- Drawer mobile -->
  <v-navigation-drawer
    v-model="drawer"
    temporary
    location="right"
    :width="320"
    disable-resize-watcher
    class="!rounded-l-[16px] overflow-hidden"
  >
    <div class="w-full flex flex-col flex-1 pb-6">
      <!-- Header -->
      <div class="w-full flex justify-between sticky top-0 items-center pl-6 pr-3 pt-3 pb-4 bg-surface z-[1]">
        <svn-pro-title h6 regular color="onSurfaceVariant">
          {{ $t( 'Chapters' ) }}
        </svn-pro-title>

        <!-- Close button -->
        <svn-pro-icon-button
          variant="text"
          color="onSurfaceVariant"
          icon="custom:mingcute:close-line"
          @click.stop="drawer = !drawer"
        />
      </div>

      <!-- Questions List -->
      <div
        v-for="(item, i) in headlines"
        :key="i"
        class="w-full flex flex-col gap-2"
      >
        <template v-if="interviewKind === 'crossed' || item?.visible_for === interviewKind || item?.visible_for === 'all' && item?.type !== InterviewQuestionType?.PARAGRAPH">
          <svn-pro-text
            v-if="item?.type === InterviewQuestionType?.CHAPTER"
            body-large
            bold
            color="onSurface"
          >
            {{ item?.title }}
          </svn-pro-text>

          <v-list-item
            v-else-if="item.type !== InterviewQuestionType?.PARAGRAPH"
            :active="false"
            :value="item?.id"
            @click="scrollToQuestion(item?.id)"
          >
            <template #default>
              <div class="flex items-center gap-4">
                <div>
                  <Icon
                    :icon="item?.icon"
                    height="24"
                    width="24"
                    class="text-onSurfaceVariant"
                  />
                </div>

                <svn-pro-text subtitle-large regular class="line-clamp-1">
                  {{ item?.title }}
                </svn-pro-text>
              </div>
            </template>
          </v-list-item>
        </template>
      </div>
    </div>
  </v-navigation-drawer>
</template>

<script setup>
import moment from "moment";
import { storeToRefs } from "pinia";
import { Icon } from "@iconify/vue";
import { useRoute } from "vue-router";
import useTools from "@/tools/useTools.js";
import { useMobileStore } from "@/store/mobile.js";
import { useFeedbackStore } from "@/store/feedback.js";
import { useInterviewStore } from "@/store/interview.js";
import { InterviewQuestionType } from '@/constants/types';
import { downloadPdf } from "@/tools/pdf-proxy-service.js";
import { computed, ref, onMounted, onUnmounted } from "vue";
import BktDownloadButton from "@/components/button/BktDownloadButton.vue";
import FeedbackAnswerList from "@/components/interviewApp/feedback_answers/FeedbackAnswerList.vue";

const route = useRoute();
const { deduceMatIconFromInterviewQuestionType } = useTools();

const { isMdScreen, isLargeScreen } = storeToRefs(useMobileStore());
const { fetchFeedback, clearFeedback } = useFeedbackStore();
const { campaign, questions, participant } = storeToRefs(useFeedbackStore());
const { interviewKind } = storeToRefs(useInterviewStore());

const drawer = ref(false);
const drawerLarge = ref(false);
const downloadLoading = ref(false);

onMounted(async() => {
  window.addEventListener("resize", resizeFunction);
});

onUnmounted(() => {
  window.removeEventListener("resize", resizeFunction);
});

const resizeFunction = () => {
  if (window.innerWidth < 1440 && drawerLarge?.value) {
    drawerLarge.value = false
    drawer.value = true
  }
  if (window.innerWidth >= 1440 && drawer?.value) {
    drawer.value = false
    drawerLarge.value = true
  }
}

const openDrawer = () => {
  drawerLarge.value = !drawerLarge?.value;
}

const headlines = computed(() => {
  if (!questions.value) return [];

  let separator_position = 1;
  return questions.value.map((question) => ({
    id: question.id,
    title: question.title,
    type: question.type,
    separatorpos:
      question.type === InterviewQuestionType.CHAPTER ||
      question.type === InterviewQuestionType.CREATE_ROADMAP ||
      question.type === InterviewQuestionType.UPDATE_ROADMAP
            ? separator_position++
            : null,
    icon: deduceMatIconFromInterviewQuestionType(question.type),
    visible_for: question.visible_for,
  }));
});

const scrollToQuestion = (id) => {
  let element = document.getElementById(`${id}`);
  if (element) {
    drawer.value = false
    element.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }
}

const clickDownload = () => {
  downloadLoading.value = true;
  downloadPdf(`/pdf/feedback_answer/${route?.params?.id}`, `${participant?.value?.fullname}_${campaign?.value?.title}_${campaign?.value?.campaign_type}`.toUpperCase())
  downloadLoading.value = false;
};


clearFeedback();
fetchFeedback(route?.params?.id);
</script>

<style scoped>
.custom-active-class :deep(.v-list-item__overlay) {
  opacity: 0;
}

.v-btn :deep(.v-btn__content) {
  width: 100%;
}
</style>
