<template>
  <svn-pro-modal
    ref="manageCopilots"
    :width="480"
    :title="$t('Manage co-pilots')"
  >
    <template #activator="{ props }">
      <slot name="activator" :props="props" />
    </template>

    <template #text>
      <div class="w-full flex flex-col gap-5 md:!gap-6">
        <svn-pro-autocomplete
          item-value="id"
          :menu-icon="'custom:mingcute:down-small-fill'"
          item-title="fullname"
          :placeholder="$t('Search for co-pilots')"
          :items="searchManagersCreators"
          @input="customSearch($event)"
          @update:model-value="addCopilot"
        />

        <v-list v-if="copilots?.length" class="md:h-[344px]">
          <v-list-item
            v-for="(copilot, idx) in copilots"
            :active="false"
            :key="idx"
          >
            <div class="w-full flex items-center">
              <div class="flex gap-4 flex-1 items-center">
                <svn-pro-avatar
                  :size="40"
                  :firstname="copilot?.fullname"
                  :image="copilot?.avatar?.['50']"
                  :type="copilot?.avatar?.['50'] ? 'photo' : 'monogram'"
                />

                <div class="flex flex-col">
                  <svn-pro-text body-large regular class="line-clamp-1">
                    {{ copilot?.fullname }}
                  </svn-pro-text>

                  <svn-pro-text body-medium regular color="onSurfaceVariant">
                    {{ copilot?.job_title }}
                  </svn-pro-text>
                </div>
              </div>

              <svn-pro-button
                variant="text"
                :text="$t('Remove')"
                @click="removeCopilot(copilot?.id)"
              />
            </div>
          </v-list-item>
        </v-list>

        <div
          v-else
          class="w-full h-[344px] flex flex-col justify-center items-center gap-2"
        >
          <div>
            <Icon
              icon="noto:busts-in-silhouette"
              height="32"
              width="32"
            />
          </div>

          <div class="flex flex-col gap-1">
            <svn-pro-title h6 medium class="text-center">
              {{ $t('No co-pilots added') }}
            </svn-pro-title>

            <svn-pro-text body-medium regular color="onSurfaceVariant" class="text-center">
              {{ $t('It seems there is no co-pilots selected, search for co-pilots and add them.') }}
            </svn-pro-text>
          </div>
        </div>
      </div>
    </template>
  </svn-pro-modal>
</template>
<script setup>
import { debounce } from "lodash";
import { storeToRefs } from "pinia";
import { Icon } from "@iconify/vue";
import { onMounted, ref } from "vue";
import { useRoute } from "vue-router";
import { useSnackbar } from "@/store/snackbar.js";
import axiosService from "@/tools/axios-service.js";
import { useCompanyStore } from "@/store/company.js";

const route = useRoute();
const snackbar = useSnackbar();
const { searchCompanyManagersCreators } = useCompanyStore();

const { searchManagersCreators } = storeToRefs(useCompanyStore());

const props = defineProps({
  campaignId: { type: Number, default: null },
});

const copilots = ref([]);
const loading = ref(true);
const manageCopilots = ref(null);

onMounted(async() => {
  try {
    await searchCompanyManagersCreators('', 10);
  } catch (error) {
    snackbar.setBgColor('onSurface').displaySnackBar("Error fetching manager creators.")
  }
  try {
    const response = await axiosService.get(`/api/v2/interview_app/campaigns/${props?.campaignId || route?.params?.id}/copilots`)
    copilots.value = response?.data?.users
    loading.value = false;
  } catch (error) {
    snackbar.setBgColor('onSurface').displaySnackBar("Error fetching users.")
  }
});

const addCopilot = debounce(async(copilotId) => {
  try {
    if (copilotId) {
      await axiosService.post(`/api/v2/interview_app/campaigns/${props?.campaignId}/copilots`,
        {
          user_id: copilotId,
        }
      );
      const copilot = searchManagersCreators?.value?.find(user => user?.id === copilotId)
      copilots.value.push(copilot);
      snackbar.setBgColor('onSurface').setCustomClass('mb-4').displaySnackBar('Co-pilot added successfully.')
    }
  } catch (error) {
    snackbar.setBgColor('onSurface').setCustomClass('mb-4').displaySnackBar("Error adding copilot !")
  }
}, 300);

const removeCopilot = debounce(async(copilotId) => {
  try {
    await axiosService.delete(`/api/v2/interview_app/campaigns/${props?.campaignId}/copilots/${copilotId}`);
    copilots.value.splice(copilots?.value?.findIndex((item) => item?.id === copilotId), 1);
    snackbar.setBgColor('onSurface').setCustomClass('mb-4').displaySnackBar('Co-pilot removed successfully.')
  } catch (e) {
    snackbar.setBgColor('onSurface').setCustomClass('mb-4').displaySnackBar("Error removing copilot !")
  }
}, 300);

const customSearch = async (search, limit = 10) => {
  await searchCompanyManagersCreators(search, limit);
};

defineExpose({
  manageCopilots
})
</script>