<template>
  <svn-pro-dialog
    ref="modalSubmitWithoutRoadmap"
    :title="$t('Answers will be locked')"
    :action-one-title="$t('Submit')"
    :action-two-title="$t('Cancel')"
    :content-text="$t('Submitting this interview will lock your answers. Ask an admin to unlock them if needed.')"
    @click-primary-button="lockInterview"
    @click-secondary-button="modalSubmitWithoutRoadmap.dialog = false"
  >
    <template #activator="{ props }">
      {{ null }}
    </template>
  </svn-pro-dialog>
</template>

<script setup>
import { ref } from 'vue';
import { storeToRefs } from 'pinia';
import { useRouter } from 'vue-router';
import { useSnackbar } from '@/store/snackbar';
import { useInterviewStore } from "@/store/interview.js";

const router = useRouter();
const snackbar = useSnackbar();
const { clearInterview, lock } = useInterviewStore();

const { interviewKind, id } = storeToRefs(useInterviewStore());

const modalSubmitWithoutRoadmap = ref(false);

const lockInterview = async () => {
  try {
    await lock();
    snackbar.setBgColor("onSurface").setMsg("Your interview has been submitted, your answers are locked.").displaySnackBar();
  } catch (error) {
    snackbar.setBgColor("onSurface").setMsg("Your interview could not be submitted ! Please try again.").displaySnackBar();
  }

  modalSubmitWithoutRoadmap.value.dialog = false

  if (interviewKind?.value === "employee" || interviewKind?.value === 'feedback')
    await router.push({ name: "my_interviews" });
  else if (interviewKind?.value === "manager")
    await router.push({ name: "my_team_interviews" });
  else if (interviewKind?.value === "pairs")
    await router.push({ name: "my_interviews" });
  else if (interviewKind?.value === "survey")
    await router.push({name: "my_interviews"})
  else if (interviewKind?.value === "crossed")
    await router.push({ name: "my_team_interviews" });
  else await router.push({ name: "home" });

  clearInterview();
};

defineExpose({
  modalSubmitWithoutRoadmap
})
</script>