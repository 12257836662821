export class Pagination {
  constructor({ currentPage, nextPage, prevPage, totalCount, totalPages }) {
    this.currentPage = currentPage;
    this.nextPage = nextPage;
    this.prevPage = prevPage;
    this.totalCount = totalCount;
    this.totalPages = totalPages;
  }

  static fromJson(data) {
    return new Pagination({
      currentPage: data?.['current_page'],
      nextPage: data?.['next_page'],
      prevPage: data?.['prev_page'],
      totalCount: data?.['total_count'],
      totalPages: data?.['total_pages'],
    });
  }
}

export default class MetaPagination {
  constructor({ pagination }) {
    this.pagination = pagination;
  }

  static fromJson(data) {
    const json = {};

    json['pagination'] = Pagination.fromJson(data?.['pagination']);

    return new MetaPagination(json);
  }
}
