import EvaluationApi from './input.api';
import SubmissionApi from './submission.api';
import FaceToFaceEvaluation from '../models/face-to-face-evaluation';
import Submission from '@/models/submission';

class FaceToFaceEvaluationApi extends EvaluationApi {
  constructor() {
    super(FaceToFaceEvaluation, '/api/v1/learn/face_to_face_evaluations');
  }
}

export const faceToFaceEvaluationApi = new FaceToFaceEvaluationApi();

class FaceToFaceSubmissionApi extends SubmissionApi {
  constructor() {
    super(Submission, '/api/v1/learn/face_to_face_evaluations');
  }
}

export const faceToFaceSubmissionApi = new FaceToFaceSubmissionApi();
