<template>
  <div class="w-full flex flex-col rounded-[8px] border border-borderColor border-opacity-100">
    <div class="flex flex-col lg:!flex-row lg:!gap-3 gap-3 p-4 border-b border-b-borderColor border-opacity-100">
      <div class="w-full flex flex-col">
        <svn-pro-title h6 medium>
          {{ $t(completionCategoryText) }}
        </svn-pro-title>
  
        <svn-pro-text subtitle-medium regular color="onSurfaceVariant">
          {{ $t('Compare completion by categories') }}
        </svn-pro-text>
      </div>

      <svn-pro-select
        v-model="chartCompletionCategorySelected"
        item-title="name"
        item-value="id"
        :items="chartCompletionCategorySelectOptions"
        variant="outlined"
        class="w-full lg:!w-[250px]"
        :label="$t('Category')"
        @update:model-value="initCompletionCategory()"
      />
    </div>

    <div class="w-full h-full flex flex-col p-4">
      <bkt-bar
        v-if="chartCompletionCategory"
        :data="chartCompletionCategory"
        :options="chartCompletionCategoryOptions"
      />
    </div>
  </div>
</template>
<script setup>
import { useI18n } from "vue-i18n";
import { storeToRefs } from "pinia";
import { useRoute } from "vue-router";
import { ref, computed, onMounted } from "vue";
import { useCompanyStore } from "@/store/company";
import BktBar from "@/components/chart/BktBar.vue";
import { useCampaignStore } from "@/store/campaign.js";
import { CampaignTypeBackend } from "@/constants/CampaignTypeEnum.js";

onMounted(() => {
  chartCompletionCategorySelected.value = chartCompletionCategorySelectOptions?.value?.[0].id
  initCompletionCategory();
})

const { tagCategories } = storeToRefs(useCompanyStore());
const { completionByCategory } = storeToRefs(useCampaignStore());

const { t } = useI18n();
const route = useRoute();
const { fetchCompletionByCategory } = useCampaignStore();

const props = defineProps({
  campaign: { type: Object, default: () => {} }
})

const chartCompletionCategorySelected = ref();
const chartColors = ref(['#1C6D1E', '#CA8100', '#BA1A1A', '#46464F']);
const chartLabels = ref([t('Submitted'), t('In progress'), t('Not started'), t('Not available yet')]);

const initCompletionCategory = async () => {
  await fetchCompletionByCategory(props?.campaign?.id || route.params.id, chartCompletionCategorySelected?.value);
};

const chartCompletionCategorySelectOptions = computed(() => {
  return tagCategories?.value?.map(category => {
    return { id: category.id, name: category.name }
  })
})

const barClass = computed(() => {
  switch (props?.campaign?.campaign_type) {
    case CampaignTypeBackend.ONE_TO_ONE:
      return 'w-full'
    case CampaignTypeBackend.FEEDBACK_360 || CampaignTypeBackend.SURVEY:
      return 'lg:w-2/3 sm:w-full'
    case CampaignTypeBackend.SURVEY:
      return 'lg:w-2/3 sm:w-full'
    default:
      return ''
  }
})

const completionCategoryText = computed(() => {
  switch (props?.campaign?.campaign_type) {
    case CampaignTypeBackend.ONE_TO_ONE:
      return "Interview completion - Category"
    case CampaignTypeBackend.FEEDBACK_360:
      return "Feedback completion - Category"
    case CampaignTypeBackend.SURVEY:
      return "Survey completion - Category"
    default:
      return ''
  }
})

const chartCompletionCategoryOptions = {
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    y: {
      stacked: true
    },
    x: {
      stacked: true
    },
  },
  plugins: {
    legend: {
      position: "bottom",
      align: "start",
    }
  },
}

const chartCompletionCategory = computed(() => {
  if (completionByCategory.value && completionByCategory.value.tags) {
    return {
      labels: completionByCategory.value.tags.map(tag => tag.tag) || [],
      datasets: [
        {
          label: chartLabels.value[0],
          backgroundColor: chartColors.value[0],
          data: completionByCategory.value.tags.map(tag => tag.data.submitted)
        },
        {
          label: chartLabels.value[1],
          backgroundColor: chartColors.value[1],
          data: completionByCategory.value.tags.map(tag => tag.data.in_progress)
        },
        {
          label: chartLabels.value[2],
          backgroundColor: chartColors.value[2],
          data: completionByCategory.value.tags.map(tag => tag.data.not_started)
        }
      ]
    };
  } else {
    return null
  }
})
</script>
