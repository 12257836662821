<template>
  <svn-pro-modal
    ref="manageRecurrence"
    :width="600"
    :title="$t('Manage recurrence')"
    :persistent="true"
    no-click-animation
    :actionOneTitle="$t('Save')"
    :actionTwoTitle="$t('Cancel')"
    :sticky-bottom="true"
    :close-button-closes-modal="false"
    :primary-loading="formLoading"
    @click-close="resetFields"
    @click-primary-button="updateCampaignSettings"
    @click-secondary-button="cancelCampaignSettings"
    @click-outside="cancelCampaignSettings"
  >
    <template #activator="{ props }">
      <slot name="activator" :props="props" />
    </template>

    <template #text>
      <div
        class="flex flex-col justify-center items-start gap-4"
      >
        <svn-pro-text subtitle-large medium class="text-center">
          {{ $t('Recurrence settings') }}
        </svn-pro-text>

        <svn-pro-text subtitle-medium medium>
          {{ $t('Repeat') }}
        </svn-pro-text>

        <svn-pro-select
          v-model="recreateRepetition"
          item-title="text"
          item-value="value"
          class="w-full md:!w-[210px]"
          :label="''"
          :placeholder="repetition[1].text"
          :items="repetition"
          @update:model-value="updateRepetitionOptions"
        />

        <div
          class="flex w-full gap-x-3"
          :class="recreateRepetitionOptions === 'same_day' ? 'flex-row' : 'flex-row-reverse'"
        >
          <div
            :class="recreateRepetitionOptions === 'same_day' ||
              recreateRepetition === 'every_six_month' ||
              recreateRepetition === 'every_year' ? 'w-[60%]' : 'w-[100%]'"
          >
            <svn-pro-select
              v-if="recreateRepetition === 'every_month'"
              v-model="recreateRepetitionOptions"
                :disabled="campaignDraft?.startline?.split('-')[2] === '29' ||
                campaignDraft?.startline?.split('-')[2] === '30' ||
                campaignDraft?.startline?.split('-')[2] === '31'"
              :placeholder="$t('Select period')"
              item-title="text"
              item-value="value"
              :label="''"
              :items="periodicityMonth"
            />

            <svn-pro-select
              v-if="recreateRepetition === 'every_week'"
              v-model="recreateRepetitionOptions"
              :disabled="campaignDraft?.startline?.split('-')[2] === '29' ||
                campaignDraft?.startline?.split('-')[2] === '30' ||
                campaignDraft?.startline?.split('-')[2] === '31'"
              :placeholder="$t('Select period')"
              item-title="text"
              item-value="value"
              :label="''"
              :items="periodicityWeek"
            />

            <svn-pro-select
              v-if="recreateRepetition === 'every_six_month'"
              v-model="recreateRepetitionOptions"
              :disabled="campaignDraft?.startline?.split('-')[2] === '29' ||
                campaignDraft?.startline?.split('-')[2] === '30' ||
                campaignDraft?.startline?.split('-')[2] === '31'"
              :placeholder="$t('Select period')"
              item-title="text"
              item-value="value"
              :label="''"
              :items="periodicitySixMonths"
            />

            <svn-pro-select
              v-if="recreateRepetition === 'every_year'"
              v-model="recreateRepetitionOptions"
              :disabled="campaignDraft?.startline?.split('-')[2] === '29' ||
                campaignDraft?.startline?.split('-')[2] === '30' ||
                campaignDraft?.startline?.split('-')[2] === '31'"
              :placeholder="$t('Select period')"
              item-title="text"
              item-value="value"
              :label="''"
              :items="periodicityYear"
            />
          </div>

          <div
            v-if="recreateRepetition === 'every_month' && recreateRepetitionOptions === 'same_day'"
            class="w-[40%]"
          >

            <svn-pro-select
              v-model="recreateRepetitionDay"
              placeholder="Day"
              item-title="text"
              item-value="value"
              :label="''"
              :items="repetitionDays"
            />
          </div>

          <div
            v-if="recreateRepetition === 'every_six_month' || recreateRepetition === 'every_year'"
            class="w-[40%]"
          >

            <svn-pro-select
              v-model="recreateRepetitionDay"
              placeholder="Day"
              item-title="text"
              item-value="value"
              :label="''"
              :items="repetitionDays"
            />
          </div>
        </div>
      </div>

      <div
        class="flex flex-col justify-center items-start gap-4 mt-6"
      >
        <svn-pro-text subtitle-medium medium>
          {{ $t('Stop repetition on :') }}
        </svn-pro-text>
        <div class="w-full md:!w-[210px]">
          <pop-up-edit-date min @save="updateStartDate">
            <template #activator="{ props }">
              <svn-pro-text-field
                v-bind="props"
                variant="outlined"
                clearable
                clear-icon="mdi-close"
                v-model="recurrentStopAt"
                prepend-inner-icon="custom:mingcute:calendar-2-line"
                :label="$t('date')"
                @click:clear="recurrentStopAt = null"
              />
            </template>
          </pop-up-edit-date>
        </div>
      </div>

      <div
        class="flex flex-col justify-center items-start gap-4 mt-6"
      >
        <svn-pro-text subtitle-large medium class="text-center">
          {{ $t('Campaigns settings') }}
        </svn-pro-text>

        <svn-pro-text subtitle-medium medium>
          {{ $t('Will launch at :') }}
        </svn-pro-text>

        <div class="flex gap-3">
          <svn-pro-select
            v-model="willLaunchAt"
            item-title="text"
            item-value="value"
            class="w-[210px]"
            :label="''"
            :placeholder="time[1]"
            :items="time"
          />

          <svn-pro-combobox
            :key="generation"
            variant="outlined"
            v-model="recurrentTimeZone"
            :label="$t('Zone')"
            class="w-[330px]"
            :items="citiesList"
          >

            <template #selection="data">
              <!-- Do not display selected items in the input field -->
            </template>
          </svn-pro-combobox>
        </div>

        <svn-pro-text subtitle-medium medium>
          {{ $t('Duration :') }}
        </svn-pro-text>

        <div class="flex gap-3">
          <svn-pro-text-field
            v-model="deadlineNumber"
            color="primary"
            variant="outlined"
            class="w-[210px]"
            :placeholder="$t('Duration')"
            @update:model-value="search"
          />

          <svn-pro-select
            v-model="deadlineDurationUnit"
            item-title="text"
            :placeholder="$t('Duration unit')"
            item-value="value"
            class="w-[330px]"
            :label="''"
            :items="durationUnits"
          />
        </div>

      </div>
      <svn-pro-divider color="[#767680]" class="border-opacity-100 my-6 " />
      <svn-pro-text subtitle-large medium color="onSurface" class="text-center"  v-if="willLaunchAt">
        {{ $t('Campaigns will be launched at ') }}
        <span class="text-lighten2 font-medium">{{ willLaunchAt }}</span>.
        <span>{{ $t(' They will last ') }}</span>
        <span class="text-lighten2 font-medium">{{ deadlineNumber }}</span>
        <span>{{ ' ' }}</span>
        <span class="text-lighten2 font-medium">{{ deadlineDurationUnit ? $t(deadlineDurationUnit) : '' }}</span>
        <span>{{ $t(', repeating each ') }}</span>
        <span
          v-if="(recreateRepetitionOptions !== 'first_day' && recreateRepetitionOptions !== 'last_day')"
          class="text-lighten2 font-medium"
        >
          {{ recreateRepetition ? $t(recreateRepetition) : '' }}
        </span>
        <span
          v-else
          class="text-lighten2 font-medium"
        >
          {{ recreateRepetitionOptions ? $t(recreateRepetitionOptions) : '' }}
        </span>
        <span
          v-if="recreateRepetition !== 'every_week' &&
            recreateRepetitionOptions !== 'first_day' &&
            recreateRepetitionOptions !== 'last_day' &&
            recreateRepetition === 'every_month'"
        >
          {{ $t(' on the ') }}
        </span>
        <span
          v-if="recreateRepetition !== 'every_week' &&
            recreateRepetitionOptions !== 'first_day' &&
            recreateRepetitionOptions !== 'last_day' &&
            recreateRepetition === 'every_month'"
          class="text-lighten2 font-medium"
        >
          {{ recreateRepetitionDay }}
        </span>
        <span v-if="recurrentStopAt">{{ $t('. They will end on the ') }}</span>
        <span
          v-if="recurrentStopAt"
          class="text-lighten2 font-medium"
        >
          {{ recurrentStopAt }}
        </span>.
     </svn-pro-text>
    </template>
  </svn-pro-modal>

  <svn-pro-dialog-validation
    ref="launchCampaignDialogRef"
    icon="noto:warning"
    :action-two-title="$t('Quit without saving')"
    :action-one-title="$t('Save')"
    :title="$t('Changes will be lost')"
    :content-text="$t('Your changes will be lost. Save changes before closing?')"
    @click-primary-button="updateCampaignSettings"
    @click-secondary-button="launchCampaignDialogRef.value.dialoh = true"
  >
    <template #activator="{ props }">
      <div class="hidden"/>
    </template>
  </svn-pro-dialog-validation>
</template>

<script setup>
import { ref, watch } from 'vue';
import i18n from '@/plugins/i18n';
import { storeToRefs } from 'pinia';
import citiesList from '@/tools/world-cities';
import { usePopUpStore } from '@/store/pop-up';
import { useSnackbar } from '@/store/snackbar';
import { useCampaignDraftStore } from "@/store/campaign-draft.js";
import { onMounted } from 'vue';
import PopUpEditDate from "@/components/popUpComponents/PopUpEditDate.vue";
import moment from "moment";

const { campaignDraft } = storeToRefs(useCampaignDraftStore());

const { fetchCampaignDraftRecurrent, updateCampaignDraftRecurrent } = useCampaignDraftStore();

const props = defineProps({
  campaignId: { type: Number, default: null },
})


const snackbar = useSnackbar();

const { closePopUp } = usePopUpStore();

const saveChangesDialog = ref(false);
const recreateRepetition = ref(null);
const recreateRepetitionOptions = ref(null);
const recreateRepetitionDay = ref(null);
const recurrentStopAt = ref(null);
const willLaunchAt = ref(null);
const recurrentTimeZone = ref(null);
const deadlineDurationUnit = ref(null);
const deadlineNumber = ref(null);
const manageRecurrence = ref(null);
const launchCampaignDialogRef = ref(null);

const updateStartDate = (value) => {
  recurrentStopAt.value = moment(value).format('YYYY-MM-DD');
}
const time = ref([
  "12:00 AM",
  "12:15 AM",
  "12:30 AM",
  "12:45 AM",
  "01:00 AM",
  "01:15 AM",
  "01:30 AM",
  "01:45 AM",
  "02:00 AM",
  "02:15 AM",
  "02:30 AM",
  "02:45 AM",
  "03:00 AM",
  "03:15 AM",
  "03:30 AM",
  "03:45 AM",
  "04:00 AM",
  "04:15 AM",
  "04:30 AM",
  "04:45 AM",
  "05:00 AM",
  "05:15 AM",
  "05:30 AM",
  "05:45 AM",
  "06:00 AM",
  "06:15 AM",
  "06:30 AM",
  "06:45 AM",
  "07:00 AM",
  "07:15 AM",
  "07:30 AM",
  "07:45 AM",
  "08:00 AM",
  "08:15 AM",
  "08:30 AM",
  "08:45 AM",
  "09:00 AM",
  "09:15 AM",
  "09:30 AM",
  "09:45 AM",
  "10:00 AM",
  "10:15 AM",
  "10:30 AM",
  "10:45 AM",
  "11:00 AM",
  "11:15 AM",
  "11:30 AM",
  "11:45 AM",
  "12:00 PM",
  "12:15 PM",
  "12:30 PM",
  "12:45 PM",
  "01:00 PM",
  "01:15 PM",
  "01:30 PM",
  "01:45 PM",
  "02:00 PM",
  "02:15 PM",
  "02:30 PM",
  "02:45 PM",
  "03:00 PM",
  "03:15 PM",
  "03:30 PM",
  "03:45 PM",
  "04:00 PM",
  "04:15 PM",
  "04:30 PM",
  "04:45 PM",
  "05:00 PM",
  "05:15 PM",
  "05:30 PM",
  "05:45 PM",
  "06:00 PM",
  "06:15 PM",
  "06:30 PM",
  "06:45 PM",
  "07:00 PM",
  "07:15 PM",
  "07:30 PM",
  "07:45 PM",
  "08:00 PM",
  "08:15 PM",
  "08:30 PM",
  "08:45 PM",
  "09:00 PM",
  "09:15 PM",
  "09:30 PM",
  "09:45 PM",
  "10:00 PM",
  "10:15 PM",
  "10:30 PM",
  "10:45 PM",
  "11:00 PM",
  "11:15 PM",
  "11:30 PM",
  "11:45 PM",
])

onMounted(() => {

})

const updateRepetitionOptions = (e) => {
  recreateRepetition.value = e

  if (recreateRepetition.value === 'every_week') {
    recreateRepetitionOptions.value = 'monday'
  }
  else if (recreateRepetition.value === 'every_month') {
    recreateRepetitionOptions.value = 'same_day'
  }
  else if (recreateRepetition.value === 'every_six_month') {
    recreateRepetitionOptions.value = 'january_july'
  }
  else if (recreateRepetition.value === 'every_year') {
    recreateRepetitionOptions.value = 'january'
  }
}

const durationUnits = ref([
  {
    text: i18n.global.t("Days"),
    value: 'days'
  },
  {
    text: i18n.global.t("Weeks"),
    value: 'weeks'
  },
  {
    text: i18n.global.t("Months"),
    value: 'months'
  },
  {
    text: i18n.global.t("Years"),
    value: 'years'
  },
]);

const repetition = ref([
  {
    text: i18n.global.t("Every week"),
    value: 'every_week'
  },
  {
    text: i18n.global.t("Every month"),
    value: 'every_month'
  },
  {
    text: i18n.global.t("Every 6 months"),
    value: 'every_six_month'
  },
  {
    text: i18n.global.t("Every year"),
    value: 'every_year'
  },
]);

const periodicityWeek = [
  {
    text: i18n.global.t("Monday"),
    value: 'monday'
  },
  {
    text: i18n.global.t("Tuesday"),
    value: 'tuesday'
  },
  {
    text: i18n.global.t("Wednesday"),
    value: 'wednesday'
  },
  {
    text: i18n.global.t("Thursday"),
    value: 'thursday'
  },
  {
    text: i18n.global.t("Friday"),
    value: 'friday'
  },
  {
    text: i18n.global.t("Saturday"),
    value: 'saturday'
  },
  {
    text: i18n.global.t("Sunday"),
    value: 'sunday'
  },
];

const periodicityMonth = [
  {
    text: i18n.global.t("Same day each month"),
    value: 'same_day'
  },
  {
    text: i18n.global.t("First day of the month"),
    value: 'first_day'
  },
  {
    text: i18n.global.t("Last day of the month"),
    value: 'last_day'
  },
];

const periodicitySixMonths = [
  {
    text: i18n.global.t("January - July"),
    value: 'january_july'
  },
  {
    text: i18n.global.t("February - August"),
    value: 'february_august'
  },
  {
    text: i18n.global.t("March - September"),
    value: 'march_september'
  },
  {
    text: i18n.global.t("April - October"),
    value: 'april_october'
  },
  {
    text: i18n.global.t("May - November"),
    value: 'may_november'
  },
  {
    text: i18n.global.t("June - December"),
    value: 'june_december'
  },
  {
    text: i18n.global.t("July - January"),
    value: 'july_january'
  },
  {
    text: i18n.global.t("August - February"),
    value: 'august_february'
  },
  {
    text: i18n.global.t("September - March"),
    value: 'september_march'
  },
  {
    text: i18n.global.t("October - April"),
    value: 'october_april'
  },
  {
    text: i18n.global.t("November - May"),
    value: 'november_may'
  },
  {
    text: i18n.global.t("December - June"),
    value: 'december_june'
  },
];

const periodicityYear = [
  {
    text: i18n.global.t("January"),
    value: 'january'
  },
  {
    text: i18n.global.t("February"),
    value: 'februrary'
  },
  {
    text: i18n.global.t("March"),
    value: 'march'
  },
  {
    text: i18n.global.t("April"),
    value: 'april'
  },
  {
    text: i18n.global.t("May"),
    value: 'may'
  },
  {
    text: i18n.global.t("June"),
    value: 'june'
  },
  {
    text: i18n.global.t("July"),
    value: 'july'
  },
  {
    text: i18n.global.t("August"),
    value: 'august'
  },
  {
    text: i18n.global.t("September"),
    value: 'september'
  },
  {
    text: i18n.global.t("October"),
    value: 'october'
  },
  {
    text: i18n.global.t("November"),
    value: 'november'
  },
  {
    text: i18n.global.t("December"),
    value: 'december'
  },
];

const repetitionDays = ref([
  {
    text: i18n.global.t("1st"),
    value: 1,
  },
  {
    text: i18n.global.t("2nd"),
    value: 2,
  },
  {
    text: i18n.global.t("3rd"),
    value: 3,
  },
  {
    text: i18n.global.t("4th"),
    value: 4,
  },
  {
    text: i18n.global.t("5th"),
    value: 5,
  },
  {
    text: i18n.global.t("6th"),
    value: 6,
  },
  {
    text: i18n.global.t("7th"),
    value: 7,
  },
  {
    text: i18n.global.t("8th"),
    value: 8,
  },
  {
    text: i18n.global.t("9th"),
    value: 9,
  },
  {
    text: i18n.global.t("10th"),
    value: 10,
  },
  {
    text: i18n.global.t("11th"),
    value: 11,
  },
  {
    text: i18n.global.t("12th"),
    value: 12,
  },
  {
    text: i18n.global.t("13th"),
    value: 13,
  },
  {
    text: i18n.global.t("14th"),
    value: 14,
  },
  {
    text: i18n.global.t("15th"),
    value: 15,
  },
  {
    text: i18n.global.t("16th"),
    value: 16,
  },
  {
    text: i18n.global.t("17th"),
    value: 17,
  },
  {
    text: i18n.global.t("18th"),
    value: 18,
  },
  {
    text: i18n.global.t("19th"),
    value: 19,
  },
  {
    text: i18n.global.t("20th"),
    value: 20,
  },
  {
    text: i18n.global.t("21th"),
    value: 21,
  },
  {
    text: i18n.global.t("22th"),
    value: 22,
  },
  {
    text: i18n.global.t("23th"),
    value: 23,
  },
  {
    text: i18n.global.t("24th"),
    value: 24,
  },
  {
    text: i18n.global.t("25th"),
    value: 25,
  },
  {
    text: i18n.global.t("26th"),
    value: 26,
  },
  {
    text: i18n.global.t("27th"),
    value: 27,
  },
  {
    text: i18n.global.t("28th"),
    value: 28,
  },
  {
    text: i18n.global.t("29th"),
    value: 29,
  },
  {
    text: i18n.global.t("30th"),
    value: 30,
  },
  {
    text: i18n.global.t("31th"),
    value: 31,
  },
])

const cancelCampaignSettings = async() => {
  manageRecurrence.value.dialog = false
  launchCampaignDialogRef.value.dialogRef.dialog = true
}

const updateCampaignSettings = async() => {
  await updateCampaignDraftRecurrent(props.campaignId, {
    recreate_repetition: recreateRepetition.value,
    recreate_repetition_option: recreateRepetitionOptions.value,
    recreate_repetition_day: Number(recreateRepetitionDay.value),
    recurrent_stop_at: recurrentStopAt.value,
    will_launch_at: willLaunchAt.value,
    recurrent_time_zone: recurrentTimeZone.value,
    deadline_duration_unit: deadlineDurationUnit.value,
    deadline_number: Number(deadlineNumber.value),
  })
  manageRecurrence.value.dialog = false
  snackbar.setBgColor('onSurface')
  snackbar.setMsg('Your changes have been saved!')
  snackbar.displaySnackBar()
}

const mergeToCurrentCampignDraft = async() => {
  const currentCampaignDraft = await fetchCampaignDraftRecurrent(props?.campaignId);

  recreateRepetition.value = currentCampaignDraft?.recreate_repetition
  recreateRepetitionOptions.value = currentCampaignDraft?.recreate_repetition_option
  recreateRepetitionDay.value = currentCampaignDraft?.recreate_repetition_day
  willLaunchAt.value = currentCampaignDraft?.will_launch_at
  recurrentStopAt.value = currentCampaignDraft?.recurrent_stop_at
  recurrentTimeZone.value = currentCampaignDraft?.recurrent_time_zone
  deadlineDurationUnit.value = currentCampaignDraft?.deadline_duration_unit
  deadlineNumber.value = currentCampaignDraft?.deadline_number
}

const closePopUpWithoutSaving = () => {
  saveChangesDialog.value = !saveChangesDialog.value
  manageRecurrence.value.dialog = false
  snackbar.setBgColor('error')
  snackbar.setMsg('Your changes have not been saved !')
  snackbar.displaySnackBar()
}

const closePopUpAndSave = () => {
  saveChangesDialog.value = !saveChangesDialog.value
  updateCampaignSettings()
  manageRecurrence.value.dialog = false
  snackbar.setBgColor('onSurface')
  snackbar.setMsg('Your changes have been saved !')
  snackbar.displaySnackBar()
}

defineExpose({
  manageRecurrence
})

watch(props ,() => {
  mergeToCurrentCampignDraft()
})
</script>

<style scoped>
.picker :deep(.v-date-picker-month__days) {
  grid-row-gap: 24px !important;
}

.picker :deep(.v-date-picker-controls) {
  height: 64px;
}

.input :deep(.v-field__outline) {
  display: flex;
  border-radius: 8px;
}

.input :deep(.v-input__control) {
  height: 48px;
}

.input :deep(.v-field__input) {
  margin-top: 4px;
}
</style>
