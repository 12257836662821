<template>
  <div :id="question.id" class="w-full flex flex-col gap-6">
    <svn-pro-form-block
      type="textarea"
      :title="question?.text"
      :has-editable-area="false"
      :question-type="question?.type"
      :description="question?.description"
      :size="isMdScreen ? 'default' : 'compact'"
      :has-divider="question?.description?.length ? true : false"
      :has-description="question?.description?.length ? true : false"
    />

    <template v-if="snapshotsUpdated?.length">
      <svn-pro-card-interview-target
        v-for="(target, idx) in snapshotsUpdated"
        :key="idx"
        :linterview-locked="locked"
        type="update_show"
        :size="isMdScreen ? 'default' : 'compact'"
        tag-leading-item="point"
        :selected-value="target?.objective_indicator?.options?.current_value"
        :comments="target?.current_suggestion?.comment"
        :interview-kind="interviewKind"
        :current-user-id="currentUserId"
        :variable-label-two="$t('Suggested updates')"
        :tooltip-two-content="$t('Suggested updates')"
        :variable-label-three="$t('Final update')"
        :tooltip-three-content="$t('Final update')"
        :has-employee-suggestion="hasEmployeeSuggestion(target)"
        :has-manager-or-crossed-suggestion="hasManagerOrCrossedSuggestion(target)"
        :has-manager-or-crossed-suggestion-and-submitted="hasManagerOrCrossedSuggestionAndSubmitted(target)"
        :target="target"
        :target-title="target?.title"
        :target-deadline="target?.due_date"
        :target-description="target?.description"
        :target-target-value="getTagText(target?.objective_indicator?.status)"
        :target-objective-indicator="target?.objective_indicator"
        :interview-status="status"
        @update-snapshot="onUpdateSnapshot($event, target?.objective_indicator?.id)"
      >
        <template v-if="hasEmployeeSuggestion(target)" #employee-suggestion>
          <svn-pro-answer-bubble-interview
            :bubble-title="`${getSuggessedData('employee', target)?.user?.firstname} ${getSuggessedData('employee', target)?.user?.lastname}`"
            :user="getSuggessedData('employee', target)?.user"
            :question-type="target?.objective_indicator?.indicator_type"
            :selected-value="getSuggessedData('employee', target)?.proposal"
            :answer="getSuggessedData('employee', target)?.proposal"
            :comment="getSuggessedData('employee', target)?.comment"
          />
        </template>

        <template v-if="hasManagerOrCrossedSuggestion(target)" #manager-cross-suggestion>
          <div class="w-full flex flex-col gap-6">
            <svn-pro-answer-bubble-interview
              v-if="getSuggessedData('employee', target)"
              :bubble-title="`${getSuggessedData('employee', target)?.user?.firstname} ${getSuggessedData('employee', target)?.user?.lastname}`"
              :user="getSuggessedData('employee', target)?.user"
              :question-type="target?.objective_indicator?.indicator_type"
              :selected-value="getSuggessedData('employee', target)?.proposal"
              :answer="getSuggessedData('employee', target)?.proposal"
              :comment="getSuggessedData('employee', target)?.comment"
            />

            <svn-pro-answer-bubble-interview
              v-if="getSuggessedData('manager', target) && locked && !isLastInterviewCompletedAndLocked"
              :bubble-title="`${getSuggessedData('manager', target)?.user?.firstname} ${getSuggessedData('manager', target)?.user?.lastname}`"
              :side="false"
              :user="getSuggessedData('manager', target)?.user"
              :question-type="target?.objective_indicator?.indicator_type"
              :selected-value="getSuggessedData('manager', target)?.proposal"
              :answer="getSuggessedData('manager', target)?.proposal"
              :comment="getSuggessedData('manager', target)?.comment"
            />

            <svn-pro-answer-bubble-interview
              v-if="getSuggessedData('manager', target) && interviewKind === 'crossed'"
              :side="false"
              :bubble-title="`${getSuggessedData('manager', target)?.user?.firstname} ${getSuggessedData('manager', target)?.user?.lastname}`"
              :user="getSuggessedData('manager', target)?.user"
              :question-type="target?.objective_indicator?.indicator_type"
              :selected-value="getSuggessedData('manager', target)?.proposal"
              :answer="getSuggessedData('manager', target)?.proposal"
              :comment="getSuggessedData('manager', target)?.comment"
            />
          </div>
        </template>

        <template v-if="hasManagerOrCrossedSuggestionAndSubmitted(target)" #manager-cross-suggestion-submitted>
          <svn-pro-answer-bubble-interview
            bubble-title=""
            :crossed="isLastInterviewCompletedAndLocked"
            :is-last-interview-completed-and-locked="interviewKind === 'crossed' && isLastInterviewCompletedAndLocked"
            :user="getSuggessedData('manager', target)?.user"
            :question-type="target?.objective_indicator?.indicator_type"
            :selected-value="interviewKind === 'manager' ?
              getSuggessedData('manager', target)?.proposal : interviewKind === 'crossed' ?
                getSuggessedData('crossed', target)?.proposal : null"
            :answer="interviewKind === 'manager' ?
              getSuggessedData('manager', target)?.proposal : interviewKind === 'crossed' ?
                getSuggessedData('crossed', target)?.proposal : null"
            :comment="interviewKind === 'manager' ?
              getSuggessedData('manager', target)?.comment : interviewKind === 'crossed' ?
                getSuggessedData('crossed', target)?.comment : $t('Comment')"
          />
        </template>
      </svn-pro-card-interview-target>
    </template>

    <div
      v-else
      class="flex flex-col justify-center items-center mx-auto my-10"
    >
      <svn-pro-text subtitle-large color="onSurfaceVariant">
        {{ $t('You don\u0027t have any targets for the moment.') }}
      </svn-pro-text>
    </div>
  </div>
</template>

<script setup>
import { debounce } from 'lodash';
import { storeToRefs } from "pinia";
import { ref, onMounted, computed } from 'vue';
import { useSnackbar } from "@/store/snackbar";
import { useUserStore } from "@/store/user.js";
import { useMobileStore } from "@/store/mobile";
import axiosService from "@/tools/axios-service.js";
import { useInterviewStore } from "@/store/interview.js";
import { InterviewAppQuestionTypes } from 'svn-ui-library/components';
import { useTargetSuggestionsStore } from "@/store/targets-suggestions";

const props = defineProps(['question']);

const unarchived = ref([]);

const snackbar = useSnackbar();
const { fetchUpdatedSnapshots } = useTargetSuggestionsStore();

const { isMdScreen } = storeToRefs(useMobileStore());
const { id: currentUserId } = storeToRefs(useUserStore());
const { snapshotsUpdated } = storeToRefs(useTargetSuggestionsStore());
const { employee, campaignId, id, status, locked, isFinalUpdate, interviewKind, isLastInterviewCompletedAndLocked } = storeToRefs(useInterviewStore());

onMounted(async () => {
  try {
    await fetchTargets();
  } catch (error) {
    console.log(error)
  }
});

const getTagText = (status) => {
  switch (status) {
    case "completed":
      return "Completed";
    case "in_progress":
      return "In progress";
    case "uncompleted":
      return "Not validated";
    default:
      return "Not set yet";
  }
}

const getSuggessedData = (state, target) => {
  if (state === 'employee') {
    return target?.suggestions?.find((suggestion) => suggestion.state === 'employee')
  }
  else if (state === 'manager') {
    return target?.suggestions?.find((suggestion) => suggestion.state === 'manager')
  }
  else if (state === 'crossed') {
    return target?.suggestions?.find((suggestion) => suggestion.state === 'crossed')
  }
}

const hasEmployeeSuggestion = (target) => {
  return (props?.question?.type === InterviewAppQuestionTypes.roadmap_update &&
    locked?.value &&
      target?.suggestions?.length > 0 && interviewKind?.value === 'employee')
}

const hasManagerOrCrossedSuggestion = (target) => {
  return (props?.question?.type === InterviewAppQuestionTypes.roadmap_update &&
    target?.suggestions?.length && target?.suggestions?.length > 0 &&
      interviewKind?.value === 'manager' || interviewKind?.value === 'crossed')
}

const hasManagerOrCrossedSuggestionAndSubmitted = (target) => {
  return (isLastInterviewCompletedAndLocked?.value && (getSuggessedData('manager', target)?.proposal?.length > 0 || getSuggessedData('crossed', target)?.proposal?.length > 0))
}

const onUpdateSnapshot = debounce(async(event, indicatorId) => {
  try {
    await axiosService.patch(`/api/v1/roadmaps/indicators/${indicatorId}`,
      {
        current_value: event?.current_value,
        comment:  event?.comment,
        interview_id: id?.value
      }
    );
    await fetchUpdatedSnapshots(employee?.value?.id, campaignId?.value, id?.value)
  } catch (error) {
    console.log(error)
    snackbar.setBgColor("onSurface").displaySnackBar("Could not update suggestion ! Please try again.");
  }
}, 400)

const fetchTargets = async () => {
  try {
    await fetchUpdatedSnapshots(employee?.value?.id, campaignId?.value, id?.value)

    if (snapshotsUpdated?.value?.length === 0) {
      unarchived.value = null
    }
    else {
      unarchived.value = snapshotsUpdated.value
    }
  } catch (e) {
    snackbar.setBgColor('onSurface').setMsg('Error fetching targets !').displaySnackBar();
  }
}

const tooltipContent = computed (() => {
  if (interviewKind.value === 'employee') {
    return 'These are updates proposed by the Interviewee and Interviewer. Only the Value update below will be applied to the target after submitting all interviews of the set.'
  } else if (interviewKind.value === 'manager') {
    if (isFinalUpdate.value) {
      return 'This answer will be applied to the target when you submit this interview.'
    } else {
      return 'These are updates proposed by the Interviewee and Interviewer. Only the Value update below will be applied to the target after submitting all interviews of the set.'
    }
  } else {
    return 'This answer will be applied to the target when you submit this interview.'
  }
})
</script>