<template>
    <!-- Bandeau rouge si c'est trash -->
    <trash-warnning
      v-if="isDeleted"
      title="This template is in trash."
      @restore="restoreDeletedItems(interviewForm?.id)"
      @delete-permanently="deleteTargetRef.dialogRef.dialog  = true"
    />

  <div
    class="relative w-full h-[100px]"
  >
    <v-layout
      full-height
      :style="isDeleted ? 'height: calc(100% - 135px);' : ' height: calc(100% - 75px);'"
      style=" position: fixed; width: 100%; z-index: 0;"
    >
      <v-app-bar
        color="surface"
        elevation="0"
        class="border-b !border-surfaceBright border-opacity-100"
        prominent
      >

        <v-toolbar-title>
          <div class="flex w-full" v-if="isLargeScreen">
            <div style="width: 345px" />
            <svn-pro-title
              h6
              medium
              class="flex text-center justify-center w-full"
            >
              {{ title }}
            </svn-pro-title>
          </div>
        </v-toolbar-title>

        <!--      <v-spacer />-->

        <div class="flex justify-center items-center gap-4 pr-[16px]">
          <div v-if="!isDeleted" class="text-end">
            <svn-pro-info-tag
              v-if="isTemplateLoading"
              tag-type="info"
              :text="$t('Saving...')"
              class="w-fit h-fit !h-[32px]"
            />

            <svn-pro-info-tag
              v-else
              tag-type="info"
              :text="$t('Saved')"
              class="w-fit h-fit !h-[32px]"
            />
          </div>

          <v-app-bar-nav-icon
            variant="text"
            :disabled="isDeleted"
            :color="drawer ? 'primary' : 'onSurfaceVariant'"
            @click.stop="drawer = !drawer"
            icon="custom:mingcute:list-ordered-line"
          />

          <interview-template-preview :is-deleted="isDeleted"/>
        </div>
      </v-app-bar>

      <v-navigation-drawer
        v-model="drawer"
        width="320"
        disable-resize-watcher
        location="right"
        class="!h-auto"
      >
        <div class="w-full break-words">
          <div class="w-full text-start font-semibold text-gray-300 text-2xl ">
            <div class="flex justify-between items-center  pt-3 pr-3 pl-6">
              <svn-pro-title h5 large color="onSurfaceVariant">
                {{ $t( 'Chapters' ) }}
              </svn-pro-title>

              <div class="cursor-pointer">
                <svn-pro-icon-button
                  color="onSurfaceVariant"
                  variant="text"
                  @click.stop="drawer = !drawer"
                  icon="custom:mingcute:close-line"
                >
                </svn-pro-icon-button>
              </div>
            </div>
          </div>

          <div
            v-for="(item, i) in headlines"
            :key="i"
            class="w-full my-2"
            :class="i === headlines.length - 1 ? '!pb-[200px]' : ''"
          >
            <div
              v-if="item.type === InterviewQuestionType.CHAPTER"
              class="uppercase font-semibold break-words px-4 pb-2"
            >
            <svn-pro-text body-large bold color="onSurface">
              {{ item.title }}
            </svn-pro-text>
            </div>

            <svn-pro-list-item
              v-else-if="item.type !== InterviewQuestionType.PARAGRAPH"
              :title="item.title "
              class="uppercase font-semibold break-words px-4 pb-2"
              :leading-icon="item.icon"
              leading-color="onSurfaceVariant"
              leading="icon"
              @click="scrollToElement(item)"
              />
          </div>
        </div>
      </v-navigation-drawer>

      <v-main
        class="overflow-y-auto bg-neutral-100"
      >
        <div class="flex bg-neutral-100">
          <div class="w-full flex justify-center question-list-container">
            <template-question-list
              :is-deleted="isDeleted"
            />
          </div>
          <!-- <div class="w-2/6 flex flex-col xl:mr-28 mr-8">
            <div class="edit-tags-container">
              <template-edit-type
                v-if="!isFeedBack360() && !isSurvey()"
                :is-deleted="isDeleted"
              />
              <template-edit-tags
                :is-deleted="isDeleted"
              />
            </div>
          </div> -->
        </div>
      </v-main>
    </v-layout>
  </div>

  <svn-pro-dialog-validation
    ref="deleteTargetRef"
    icon="noto:warning"
    :action-two-title="$t('Cancel')"
    :action-one-title="$t('Delete')"
    :title="$t(`Template will be deleted`)"
    :content-text="$t('This is a permanent action.')"
    @click-primary-button="deletePermanentlyDeletedItems(interviewForm?.id)"
    @click-secondary-button="deleteTargetRef.dialogRef.dialog  = false"
  >
    <template #activator="{ props }">
      <div class="hidden"/>
    </template>
  </svn-pro-dialog-validation>
</template>

<script setup>
import TemplateQuestionList
  from "@/components/interviewApp/template/Edit/TemplateQuestionList.vue";
import {computed, onMounted, onUnmounted, ref, watch } from "vue";
import {useInterviewFormStore} from "@/store/interview-form.js";
import {useRoute, useRouter} from "vue-router";
import {storeToRefs} from "pinia";
import useTools from "@/tools/useTools.js";
import InterviewTemplatePreview
  from "@/components/interviewApp/template/Show/InterviewTemplatePreview.vue";
import useCampaigns from "@/tools/useCampaigns.js";
import {useMobileStore} from "@/store/mobile.js";
import { useSnackbar } from '@/store/snackbar';
import TrashWarnning from "@/components/TrashWarnning.vue"
import { InterviewQuestionType } from '@/constants/types';

const snackbar = useSnackbar()

const route = useRoute()
const router = useRouter()

const { deduceMatIconFromInterviewQuestionType } = useTools()
const { fetchInterviewForm, clearInterviewForm, isFeedBack360, isSurvey } = useInterviewFormStore()
const { title, isTemplateLoading, interviewQuestions, id, templateType, interviewForm } = storeToRefs(useInterviewFormStore())

const { restoreInterviewForm, deleteInterviewFormPermanently } =
 useInterviewFormStore();

const {isLargeScreen, isMobile} = storeToRefs(useMobileStore())

const { campaign_icon } = useCampaigns();

const drawer = ref(false);
const deleteTargetRef = ref(null);

const isDeleted = ref(false)

const headlines = computed(() => {

  let separator_position = 1

  return interviewQuestions.value.map((question) => ({
    id: question.id,
    title: question.text,
    type: question.type,
    icon: deduceMatIconFromInterviewQuestionType(question.type),
    separatorpos: question.type === InterviewQuestionType.CHAPTER ||
      question.type === InterviewQuestionType.CREATE_ROADMAP ||
      question.type === InterviewQuestionType.UPDATE_ROADMAP ?
      separator_position++ : null,
  }))
})

onMounted(async() => {
  await fetchInterviewForm(route.params.id)

  isDeleted.value = interviewForm.value.status === "trashed"
})

const restoreDeletedItems = async (id) => {
  try {
    await restoreInterviewForm(id)
    snackbar.setBgColor('onSurface')
    snackbar.setMsg('Template has been restored successfully.')
    snackbar.setCustomClass(isMobile.value ? 'mb-[96px]' : 'mb-[42px]')
    snackbar.displaySnackBar()

    isDeleted.value = false

  } catch (e) {
    snackbar.setBgColor('negativeRed')
    snackbar.setMsg('Error while restoring a interview form')
    snackbar.setCustomClass(isMobile.value ? 'mb-[96px]' : 'mb-[42px]')
    snackbar.displaySnackBar()
  }
}

const deletePermanentlyDeletedItems = async (id) => {
  try {
    await deleteInterviewFormPermanently(id)
    snackbar.setBgColor('onSurface')
    snackbar.setMsg('Template has been deleted successfully.')
    snackbar.setCustomClass(isMobile.value ? 'mb-[96px]' : 'mb-[42px]')
    snackbar.displaySnackBar()

    await router.push({ name: "interview_forms" });

  } catch (e) {
    snackbar.setBgColor('negativeRed')
    snackbar.setMsg('Error while deleting permanently a interview form')
    snackbar.setCustomClass(isMobile.value ? 'mb-[96px]' : 'mb-[42px]')
    snackbar.displaySnackBar()
  }
}

const scrollToElement = (item) => {
  router.push({ path: '', hash: `#${item.id}`})

}
onUnmounted(() => {
  clearInterviewForm()
})

fetchInterviewForm(route.params.id)

watch(isLargeScreen, (newValue) => {
  if (!newValue) {
    drawer.value = false
  }
})
</script>