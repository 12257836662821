<template>
  <svn-pro-comment-list
    ref="commentSection"
    :is-mobile="isMobile"
    :header-title="alephAi.name"
    :drawer-large="drawerLarge"
    :has-list-data="aiConversation?.length ? true : false"
    @add-comment-reply="$emit('create-ai-response', $event); scrollToBottom()"
    @close-drawer="closeDrawer"
  >
    <!-- AI Conversation Section -->
    <template #list-data>
      <!-- Display AI responses and user conversations -->
      <div
        v-for="(message, index) in aiConversation"
        :key="index"
      >
        <!-- Display user message -->
        <svn-pro-card-comment
          v-if="message.role === MessageRoleToStr.user"
          :comment="message?.text"
          :date="message?.created_at?.toString()"
          :hasCta="false"
          :username="message?.user?.fullname"
          :avatar="message?.user?.avatar?.['50']"
        />

        <!-- Display AI message -->
        <svn-pro-card-comment
          v-else-if="message.role === MessageRoleToStr.system"
          :comment="message?.text"
          :comment-loading="message?.comment_loading"
          :date="message?.created_at?.toString()"
          :hasCta="false"
          :username="alephAi.name"
          isAnonymous="anonym"
        />
      </div>
    </template>

    <!-- No conversation yet -->
    <template #empty-list>
      <!-- Loader while AI is processing -->
      <div v-if="loading" >
        <div v-for="i in [1,2,3]">
          <v-skeleton-loader
            type="list-item-avatar"
          />

          <v-skeleton-loader
            type="paragraph"
            class="h-fit"
          />
        </div>
      </div>

      <!-- Default welcome message -->
      <svn-pro-card-comment
        v-else
        class="px-5 pt-2 pb-6"
        :comment="$t(`Hello and welcome! I'm Aleph AI, your assistant for creating and enriching your training content. Whether it's writing your modules, exploring new ideas, or delving deeper into specific topics, I'm here to support you every step of the way. Don't hesitate to call on me to bring your educational projects to life!`)"
        :date="alephAi.date"
        :hasCta="false"
        :username="alephAi.name"
        isAnonymous="anonym"
      />
    </template>
  </svn-pro-comment-list>
</template>

<script setup>
import { ref, watch, nextTick, onMounted } from 'vue';
import { MessageRoleToStr } from '@/constants/types';

// Props definition
const props = defineProps({
  aiConversation: { type: Array, default: () => [] },
  isMobile: { type: Boolean, default: false },
  loading: { type: Boolean, default: false },
  drawerLarge: { type: Boolean, default: false },
})

// Define AI profile object
const alephAi = ref({
  name: 'ALEPH AI',
  date: new Date(),
})

// Event emitter setup
const emit = defineEmits(['create-ai-response', 'close-drawer', 'update:modelValue'])

// Reference to the scroll container
const commentSection = ref(null);

// Closes the drawer and AI thread
const closeDrawer = () => {
  emit('close-drawer');
}

// Automatically scroll to the bottom of the conversation
const scrollToBottom = () => {
  if (commentSection.value?.scrollContainer) {
    commentSection.value.scrollContainer.scrollTop = commentSection.value?.scrollContainer?.scrollHeight;
  }
}

// Watch for new AI conversation messages and scroll to the bottom
watch(() => props, async (newValue, oldValue) => {
  if (newValue.aiConversation) {
    await nextTick(); // Ensure DOM updates before scrolling
    scrollToBottom();
  }
}, { deep: true })

// Scroll to bottom when the component is mounted
onMounted(() => {
  scrollToBottom();
})
</script>