import { defineStore } from "pinia";
import { genericFunctions } from "@/tools/genericFunctions.js";
import axios from "@/tools/axios-service.js";

export const useImpersonateStore = defineStore('impersonate', {
  state: () => ({
    selectedUser: null,
    users: null,
    impersonateAdminUser: null,
  }),
  actions: {
    initImpersonate() {
      this.selectedUser = JSON.parse(localStorage.getItem('bktImpersonateUser'))
      this.impersonateAdminUser = JSON.parse(window.localStorage.getItem('bktAccessImpersonate'))
    },
    clearImpersonate() {
      this.setSelectedUser(null)
      this.setImpersonateAdminUser(null)
    },
    async impersonateUser(user) {
      await axios.impersonate(true, user.email)
      this.setImpersonateAdminUser(JSON.parse(window.localStorage.getItem('bktAccessImpersonate')))
      localStorage.setItem('bktImpersonateUser', JSON.stringify(user))
    },
    impersonateUserLogout() {
      this.clearImpersonate()

      if (window.localStorage.getItem('bktAccessImpersonate')) {
        window.localStorage.setItem('bktAccess', window.localStorage.getItem('bktAccessImpersonate'))
      }
      window.localStorage.removeItem('bktAccessImpersonate')
      window.localStorage.removeItem('bktImpersonateUser')
    },
    impersonateLogout(){
      window.localStorage.removeItem('bktAccessImpersonate')
      window.localStorage.removeItem('bktImpersonateUser')
    },
    ...genericFunctions,
  }
})