import LearnInput from './learn_input';

export default class BaseEvaluation {
  constructor({ id, learnInputs }) {
    this.id = id;
    this.learnInputs = learnInputs;
  }

  static fromJson(data) {
    const json = {};

    json['id'] = data?.id;
    json['learnInputs'] = data?.learn_inputs?.map(LearnInput.fromJson) ?? [];

    return new this(json);
  }
}
