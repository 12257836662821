<template>
  <svn-pro-modal
    ref="modalUserBadges"
    width="480"
    :title="$t('Badges')"
  >
    <template #activator>
      {{ null }}
    </template>

    <template #text>
      <div class="w-full flex flex-wrap justify-center items-center gap-8">
        <svn-pro-learn-badge
          v-for="badge in badges"
          :key="badge?.badgeType"
          :count="badge?.count"
          :link="false"
          :badge-type="badge?.badgeType"
          :levels="badge?.levels"
        />
      </div>
    </template>
  </svn-pro-modal>
</template>

<script setup>
import { ref, computed } from "vue";

const props = defineProps({
  userBadges: {type: Object, required: true}
});

const modalUserBadges = ref(null);
const badges = computed(() => {
  return [
    {
      badgeType: "training",
      count: props?.userBadges?.completeTrainingCount,
      levels: {
        levelOne: 1,
        levelTwo: 3,
        levelThree: 5,
        levelFour: 20,
        titleOne: "Accomplished Apprentice",
        titleTwo: "Trained and Competent",
        titleThree: "Certified Expert",
        titleFour: "Training Champion",
      }
    },
    {
      badgeType: "module",
      count: props?.userBadges?.acquireModuleCount,
      levels: {
        levelOne: 1,
        levelTwo: 5,
        levelThree: 10,
        levelFour: 25,
        titleOne: "Enlightened Novice",
        titleTwo: "Knowledge Explorer",
        titleThree: "Thirsty for Knowledge",
        titleFour: "Curriculum Master",
      }
    },
    {
      badgeType: "like",
      count: props?.userBadges?.favoriteModuleCount,
      levels: {
        levelOne: 1,
        levelTwo: 5,
        levelThree: 10,
        levelFour: 25,
        titleOne: "The Selector",
        titleTwo: "Content Lover",
        titleThree: "Informed Collector",
        titleFour: "Resource Guardian",
      }
    },
    {
      badgeType: "reaction",
      count: props?.userBadges?.reactModuleCount,
      levels: {
        levelOne: 1,
        levelTwo: 5,
        levelThree: 15,
        levelFour: 30,
        titleOne: "The Approver",
        titleTwo: "Positive Supporter",
        titleThree: "Enlightened Evaluator",
        titleFour: "Master of Emotions",
      }
    },
  ]
})

defineExpose({
  modalUserBadges
})
</script>