<template>
  <pop-up-edit-date
    ref="deadlineModal"
    :date-value="new Date(deadline)"
    @save="updateDeadline"
    min
  >
    <template #activator="{ props }">
      {{ null }}
    </template>
  </pop-up-edit-date>
</template>

<script setup>
import { ref, onMounted, watch } from "vue";
import { usePopUpStore } from "../../store/pop-up.js";
import axios from "../../tools/axios-service.js";
import filters from "../../tools/filters.js";
import { storeToRefs } from "pinia";
import PopUpEditDate from "@/components/popUpComponents/PopUpEditDate.vue";
import moment from "moment";

const { closePopUp } = usePopUpStore();

const { data } = storeToRefs(usePopUpStore());
const deadlineModal = ref(null);

onMounted(async () => {
  deadlineModal.value.dialog = true
})
const props = defineProps({
  campaignId: { type: String, default: null },
  deadline: { type: String, default: null },
});
const currentDeadline = ref(null);

currentDeadline.value = props.deadline ? props.deadline?.split("/").reverse().join("-") : data?.value?.deadline?.split("/").reverse().join("-");

const updateDeadline = async (date) => {
  const newDeadline = moment(date).format('DD/MM/YYYY')

  const year = filters.formatDate(newDeadline, "YYYY");
  const month = filters.formatDate(newDeadline, "M");
  const day = filters.formatDate(newDeadline, "DD");
  try {
    await axios.patch(`/api/v2/interview_app/campaigns/${props.campaignId}.json`, {
      campaign: {
        year: year,
        month: month,
        day: day
      }
    });
  } catch (e) {
  } finally {
    closePopUp();
  }
};

watch(deadlineModal, () => {
  if (!deadlineModal || !deadlineModal.value?.dialog) {
    closePopUp()
  }
})
</script>