import {defineStore} from "pinia";
import {ref} from "vue";
import axiosService from "@/tools/axios-service.js";

export const useAnalyticTrainingsStore = defineStore('analytic-trainings', () => {
    const trainingParticipantsList = ref([])
    const completionByModule = ref([])
    const trainingCompletionAll = ref([])
    const trainingCompletionAllByCategory = ref([])

    const getTrainingParticipantsList = async (id, search = "") => {
        const res = await axiosService.get(`/api/v1/learn/analytics/training/${id}/participants_list?search=${search}`)

        trainingParticipantsList.value = res.data
    }

    const getCompletionByModule = async (id) => {
        const res = await axiosService.get(`/api/v1/learn/analytics/training/${id}/completion_by_module`)

        completionByModule.value = res?.data?.completed_modules
    }

    const getTrainingCompletionAll = async (id) => {
        const res = await axiosService.get(`/api/v1/learn/analytics/training/${id}/training_completion_all`)

        trainingCompletionAll.value = res.data
    }

    const getTrainingCompletionAllByCategory = async (id, tagCategoryId) => {
        const res = await axiosService.get(`/api/v1/learn/analytics/training/${id}/training_completion_all_by_category?tag_category_id=${tagCategoryId}`)

        trainingCompletionAllByCategory.value = res.data
    }

    return {
        trainingParticipantsList,
        completionByModule,
        trainingCompletionAll,
        trainingCompletionAllByCategory,
        getTrainingParticipantsList,
        getCompletionByModule,
        getTrainingCompletionAll,
        getTrainingCompletionAllByCategory,
    }
})