<template>
  <svn-pro-data-table
    :headers="campaigns?.length ? headers : []"
    :items="campaigns ?? []"
    :items-per-page="-1"
    :page="pagination?.current_page"
    :loading="campaigns ? loading : true"
    item-value="id"
    :pagination-total-pages="campaignPagination?.total_pages"
    @update-pagination="fetchNewPage"
  >
    <template #item="{ item }">
      <tr
        class="hover:bg-[#46464F14] active:bg-[#46464F1F]/[0.12]"
        @click="goToCampaign(item?.title, item?.id)"
      >
        <td>
          <div class="w-[125px] flex flex-row items-center gap-2">
            <Icon
              :icon="types[item?.campaign_type]?.icon"
              width="16"
              height="16"
              class="mr-1"
            />

            <svn-pro-data-table-cell
              cell-type="text"
              :text="types[item?.campaign_type]?.title"
            />
          </div>
        </td>

        <td>
          <div class="max-w-[400px] flex flex-row items-center gap-2">
            <svn-pro-data-table-cell
              medium
              body-medium
              cell-type="text"
              :text=" item?.title"
            />
          </div>
        </td>

        <td>
          <div class="max-w-[400px] flex flex-row items-center gap-2">
              <svn-pro-text
                color="onSurface"
                class="text-nowrap"
              >
                {{ moment(item?.startline, 'YYYY-MM-DD').format('D MMM, YYYY') }}
              </svn-pro-text>
          </div>
        </td>

        <td>
          <div class="max-w-[400px] flex flex-row items-center gap-2">
            <svn-pro-text
              color="onSurface"
              class="text-nowrap"
            >
              {{ moment(item?.deadline, 'YYYY-MM-DD').format('D MMM, YYYY') }}
            </svn-pro-text>
          </div>
        </td>

        <td class="w-auto">
          <display-tag-in-index
            :tags="item?.categories"
          />
        </td>

        <td>
          <svn-pro-data-table-cell
            cell-type="text"
            :text="item?.employees_count"
          />
        </td>

        <td>
          <svn-pro-info-tag
            tag-style="filled"
            :tag-type="item?.completion === 0 ? 'error' : (item?.completion === 100 ? 'success' : 'warning' )"
            :text="`${ (item?.completion || 0) } %`"
            class="w-fit h-fit"
          />
        </td>

        <td>
          <svn-pro-menu>
            <template #activator>
              <svn-pro-icon-button
                icon="custom:mingcute:more-2-line"
                variant="flat"
                color="surface"
              />
            </template>

            <template #dropdown>
              <v-list-item
                value="edit_deadline"
                @click="openEditDeadline(item)"
              >
                {{ $t('Edit deadline') }}
              </v-list-item>

              <v-list-item
                value="relaunch_campaign"
                @click="relaunchCampaign(item?.id)"
              >
                {{ $t('Relaunch campaign') }}
              </v-list-item>

              <v-list-item
                v-if="item?.can_set_copilot === true"
                value="manage_copilots"
                @click="openManageCopilotsPopUp(item?.id)"
              >
                {{ $t('Manage Co-pilots') }}
              </v-list-item>

              <dialog-edit-template-tags
                fetch-tags-from-entity-path="categories_from_campaign"
                route="campaigns"
                :entity-id="item?.id"
                :template-categories="item.categories"
                @update-interview-forms-tags="updateInterviewFormsTags"
              >
                <template #activator="{ props }">
                  <v-list-item
                    v-bind="props"
                    value="edit_tags"
                  >
                    {{ $t('Edit campaign tags') }}
                  </v-list-item>
                </template>
              </dialog-edit-template-tags>

              <svn-pro-dialog-validation
                v-if="item?.completion !== 100"
                :action-two-title="$t('Cancel')"
                :action-one-title="$t('Send')"
                :title="$t('Participants will receive an invitation')"
                :content-text="$t('An email will be sent to all participants with a link to their interview.')"
                @click-primary-button="sendRemainder(item?.id)"
              >
                <template #activator="{ props }">
                  <v-list-item
                    value="send_emails"
                    v-bind="props"
                    :active="false"
                  >
                    {{ $t('Send email to participants') }}
                  </v-list-item>
                </template>
              </svn-pro-dialog-validation>


              <svn-pro-dialog-validation
                v-if="item?.completion !== 100"
                :action-two-title="$t('Cancel')"
                :action-one-title="$t('DELETE')"
                icon="noto:warning"
                :title="$t('Campaign will be deleted')"
                :content-text="$t('Deleted campaigns are stored for 30 days. After this period, they will be permanently deleted.')"
                @click-primary-button="deleteCampaign(item?.id)"
              >
                <template #activator="{ props }">
                  <v-list-item
                    class="text-error"
                    value="delete_campaign"
                    v-bind="props"
                    :active="false"
                  >
                    {{ $t('Delete campaign') }}
                  </v-list-item>
                </template>
              </svn-pro-dialog-validation>
            </template>
          </svn-pro-menu>
        </td>
      </tr>
    </template>

    <template #loading>
    </template>

    <template #no-data>
      <svn-pro-empty-states
        actions
        :variant="search ? 'results' : 'index'"
        :title="$t('Nothing to show for now')"
        :size="isMdScreen ? 'default' : 'compact'"
        :action-primary-title="$t('New Campaign')"
        prepend-primary="custom:mingcute:add-line"
        :supporting-text="search ? $t(`It seems you have no campaigns for the moment`) :
          $t(`All your current campaigns will show up here.`)"
        @click-primary="emit('go-to-new-campaign')"
      />
    </template>
  </svn-pro-data-table>
  <pop-up-edit-date
    ref="deadlineModal"
    min
    :date-value="new Date(selectedCampaign?.deadline)"
    @save="updateDate"
  >
    <template #activator="{ props }">
      {{ null }}
    </template>
  </pop-up-edit-date>
  </template>

<script setup>
import { ref } from "vue";
import DisplayTagInIndex from "../../../components/DisplayTagInIndex.vue";
import DialogEditTemplateTags from "@/components/BktPopUp/Dialogs/DialogEditTemplateTags.vue";
import { Icon } from "@iconify/vue";
import { useUserStore } from "@/store/user";
import { useCampaignStore } from "@/store/campaign.js";
import { storeToRefs } from "pinia";
import { usePopUpStore } from "@/store/pop-up.js";
import useTools from "@/tools/useTools.js";
import { useSnackbar } from "@/store/snackbar";
import axios from "@/tools/axios-service.js";
import i18n from "@/plugins/i18n";
import {useBreadcrumbsStore} from "@/store/breadcrumbs.js";
import { useRouter } from "vue-router";
import moment from "moment";
import {useMobileStore} from "@/store/mobile.js";
import PopUpEditDate from "@/components/popUpComponents/PopUpEditDate.vue";
import filters from "@/tools/filters";

const { isMdScreen } = storeToRefs(useMobileStore());
const props = defineProps({
  campaignPagination: { type: Object, default: () => [] },
  search: { type: String, default: '' },
})
const headers = [
  {
    align: "start",
    key: "campaign_type",
    title: i18n.global.t("Type"),
    icon: "icon-park-outline:down-c",
    sortable: false,
    width: 20
  },
  {
    align: "start",
    key: "campaign_title",
    title: i18n.global.t("Campaign title"),
    icon: "mdi-format-text",
    sortable: false,
  },
  {
    title: i18n.global.t("Start date"),
    key: "start_date",
    icon: "akar-icons:calendar",
    sortable: false,
  },
  {
    title: i18n.global.t("Deadline"),
    key: "deadline",
    icon: "akar-icons:calendar",
    sortable: false,
  },
  {
    align: "start",
    key: "tags",
    title: i18n.global.t("Tags"),
    icon: "fluent:tag-32-regular",
    sortable: false,
  },
  {
    align: "start",
    key: "participants", title: "Participants", icon: "mdi-account-outline", sortable: false,
  },
  {
    align: "start",
    key: "completion", title: "Completion", icon: "ic:baseline-percent", sortable: false,
  },
  {
    align: "start",
    key: "space", title: " ", icon: " ", sortable: false,
  }
];

const types = {
  Survey: {
    key: "survey",
    title: i18n.global.t("Survey"),
    icon: "noto:bar-chart"
  },
  OneToOne: {
    key: "one_to_one",
    title: i18n.global.t("One to One"),
    icon: "noto:handshake"
  },
  Feedback: {
    key: "feedback_360",
    title: i18n.global.t("Feedback"),
    icon: "noto:memo"
  }
}

const deadlineModal = ref(null)
const selectedCampaign = ref(null)

const router = useRouter()
const editModal = ref(false);
const sendModal = ref(false);
const deleteModal = ref(false);
const snackbar = useSnackbar();
const { accessLevel } = storeToRefs(useUserStore());
const { campaigns, pagination, loading } = storeToRefs(useCampaignStore());
const { updateParams, fetchCampaigns, setCampaigns } = useCampaignStore();
const { addNode, update } = useBreadcrumbsStore()
const { fetchPage } = useTools();
const { closePopUp, openPopUp } = usePopUpStore();

const emit = defineEmits(["delete", "go-to-new-campaign"])
const getClassByCompletion = (completion) => {
  if (completion === 0) {
    return "bkt-bg-light-grey";
  } else if (completion === 100) {
    return "bkt-bg-green";
  }
  return "bkt-bg-yellow";
};

const relaunchCampaign = async(campaignDraftId) => {
  try {
    await axios.post(`/api/v2/interview_app/campaign_drafts/${campaignDraftId}/relaunch_campaign`);
    router.push({ name: 'campaign_draft' });
  } catch (error) {
    snackbar.setMsg(i18n.global.t('Error relaunching campaign !'));
    snackbar.setBgColor('error');
    snackbar.displaySnackBar();
  }
}


const openEditDeadline = (campaign) => {
  selectedCampaign.value = campaign
  deadlineModal.value.dialog = true
}

const updateDate = async (date) => {
  const deadline = moment(date).format('DD/MM/YYYY')

  const year = filters.formatDate(deadline, "YYYY");
  const month = filters.formatDate(deadline, "M");
  const day = filters.formatDate(deadline, "DD");

  try {
    await axios.patch(`/api/v2/interview_app/campaigns/${selectedCampaign?.value?.id ? selectedCampaign?.value?.id : deadline}.json`, {
      campaign: {
        year: year,
        month: month,
        day: day,
      },
    });
    snackbar.setCustomClass('mb-4').setBgColor('onSurface').displaySnackBar('Deadline has been changed successfully.')
    fetchCampaigns();
  } catch (e) {
    snackbar.setCustomClass('mb-4').setBgColor('onSurface').displaySnackBar('Error updating campaign deadline.')
  }
}

const sendRemainder = async (id) => {
try {
    await axios.get(`/api/v2/interview_app/campaigns/${id}/send_notification_email?format=json`);
    snackbar.setCustomClass('mb-4').setBgColor('onSurface').displaySnackBar('Invitation is on its way!')
  } catch (error) {
    console.log(error)
  }
};

const deleteCampaign = async (id) => {
  emit('delete', id)
};

const fetchNewPage = (e) => {
  setCampaigns(null);
  updateParams({
    "page[number]": e,
  });
  fetchCampaigns();
};

const goToCampaign = (title, id) => {
  addNode(title, `/interviews/campaigns/${id}/overview`)
  update()
  router.push({
    name: 'campaign_overview',
    params: { id },
    query: { tab: 'overview' }
  })
}

const openManageCopilotsPopUp = (campaignId) => {
  openPopUp({
    componentName: "pop-up-manage-copilots-for-campaign-index",
    title: i18n.global.t('Manage co-pilots'),
    subtitle: '',
    icon: '',
    campaignId: campaignId
  });
}

const updateInterviewFormsTags = (id, tag) => {
  campaigns.value.forEach((campaign) => {
    if (campaign.id === id) {
      const formTags = campaign.categories
      const formTag = formTags.find(t => t.id === tag.id)
      if (formTag) {
        campaign.categories = campaign.categories.filter(t => t.id !== tag.id)
        console.log(formTag)
      } else {
        campaign.categories.push(tag)
      }
    }
  })
}
</script>
