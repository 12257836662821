
<template>
  <template-header-question
    :id="interviewQuestion.id"
    :is-deleted="isDeleted"
  >
    <template #title>
      <svn-pro-info-tag
        tag-type="primary"
        :text="$t('Chapter')"
        tag-style="filled"
        class="max-w-[900px] h-[32px] justify-self-start"
        leading-item="icon"
        icon="mingcute:list-check-3-line"
      />
    </template>

    <template #body>
      <div class="flex flex-col gap-4 pt-3">
        <svn-pro-text-field
          v-model="interviewQuestion.text"
          :label="$t('Chapter name')"
          variant="outlined"
          :disabled="isDeleted"
          @update:model-value="debounceUpdateQuestion(interviewQuestion.id, {text: $event})"
        />

        <div class="h-40 mb-16">
          <quill-editor
            v-model:content="interviewQuestion.description"
            content-type="html"
            :placeholder="$t('Description')"
            :enable="!isDeleted"
            @update:content="debounceUpdateQuestion(interviewQuestion.id, {description: $event})"
          />
        </div>
      </div>
    </template>
  </template-header-question>
</template>

<script setup>
import {ref} from "vue";
import TemplateHeaderQuestion from "@/components/interviewApp/template/Edit/TemplateHeaderQuestion.vue";
import {useInterviewFormStore} from "@/store/interview-form.js";
import {QuillEditor} from "@vueup/vue-quill";
import { debounce} from "lodash";

const props = defineProps({
  interviewQuestion: { type: Object, required: true },
  isDeleted: { type: Boolean, required: true }
})

const {updateQuestion } = useInterviewFormStore()

const debounceUpdateQuestion = debounce(async(id, data) => {
  await updateQuestion(id, data)
}, 300)
const content = ref('')
</script>
