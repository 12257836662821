import {defineStore} from "pinia";
import {ref, onMounted, onUnmounted, computed} from "vue";
import router from "@/router/index.js";

export const useBreadcrumbsStore = defineStore(
  "breadcrumbs",
  () => {
    const stack = ref({ head: null })
    const paths = ref([])
    const current = ref(stack.value.head)

    function updateStack(params) {
      reset()
      stack.value.head = params
      current.value = tail()
      update()
    }

    function handleBackAndForward() {
      for (let tmp = head(); tmp; tmp = tmp.next) {
        if (tmp.path === history.state.current) {
          current.value = tmp
          update()
          return
        }
      }
    }

    onMounted(() => {
      window.addEventListener('popstate', handleBackAndForward)
    })

    function head() {
      return stack.value.head
    }

    function tail() {
      let tmp = stack.value.head
      if (!tmp)
        return tmp
      while (tmp.next !== null) {
        tmp = tmp.next
      }
      return tmp
    }

    /**
     * Add a node to history
     * @param {String} title
     * @param {String} path
     */
    function addNode(title, path) {
      if (current.value?.next?.path === path) {
        current.value = current.value.next
        return
      }

      for (let tmp = stack.value.head; tmp; tmp = tmp.next) {
        if (current.value.path === tmp.path) {
          tmp.next = null
        }
      }

      let tmp = tail()

      if (!tmp) {
        stack.value.head = {
          title,
          path,
          next: null
        }
        current.value = stack.value.head
      } else {
        tmp.next = {
          title,
          path,
          next: null
        }
        current.value = tmp.next
        current.value.next = null
      }
    }

    function reset(path = "") {
      if (path !== "") {
        const stackObject = isPathInStack(path)

        if (stackObject) {
          current.value = stackObject
          return false
        }
      }
      stack.value = { head: null }
      return true
    }

    function displayHistory() {
      for (let tmp = stack.value.head; tmp !== null; tmp = tmp.next) {
        console.log("title:", tmp.title, "path:", tmp.path)
      }
    }

    function update() {
      const list = []

      for (let tmp = stack.value.head; tmp !== null && tmp !== current.value; tmp = tmp.next) {
        list.push({title: tmp.title, path: tmp.path})
      }
      paths.value = list
    }

    const displayedPaths = computed(() => {
      let tmp = head()
      let count = 0

      while (tmp && tmp !== current.value) {
        count++
        tmp = tmp.next
      }
      return paths.value
    })

    function backTo(o) {
      let tmp = stack.value.head;
      while (tmp && tmp.next && tmp.next.next && tmp.next.next.path !== o.path && tmp.next.next.title !== o.title) {
        tmp = tmp.next
      }
      current.value = tmp
      update()
    }


    async function goToPath(title, path) {
      addNode(title, path)
      update()
      await router.push(path)
    }

    const isPathInStack = (path) => {
      for (let tmp = head(); tmp; tmp = tmp.next) {
        if (tmp.path === path) {
          return tmp
        }
      }
      return null
    }

    return { addNode, reset, displayHistory, update, backTo, displayedPaths, goToPath, updateStack }
  },
  {
    persist: {
      storage: localStorage,
    }
  }
);
