<template>
  <div class="w-full flex flex-col gap-6 p-4 lg:!p-6 rounded-[12px] border border-borderColor border-opacity-100">
    <MyInterviewCardHeader
      :campaign-deadline="deadline"
      :campaign-type="campaignType"
      :campaign-name="title"
    />

    <div class="w-full flex flex-col lg:!flex-row gap-6 justify-center items-start lg:!items-center">
      <div class="flex lg:!flex-row gap-4 lg:!gap-6 items-center">
        <svn-pro-info-tag
          :tag-type="getTagTypeByCompletion(percentage)"
          leading-item="point"
          tag-size="default"
          tag-style="filled"
          :text="`${percentage}% ${$t('submitted')}`"
        />

        <svn-pro-text body-medium medium>
          {{ submittedAnswersCount }} / {{ totalAnswersCount }}
        </svn-pro-text>
      </div>
      
      <div class="w-full flex flex-1">
        <svn-pro-progress-linear :model-value="percentage" />
      </div>
    </div>

    <svn-pro-button
      variant="flat"
      class="lg:!w-fit lg:!self-end"
      prepend-icon="custom:mingcute:chart-bar-line"
      :text="$t('Consult answers')"
      :disabled="!submittedAnswers"
      @click="consultAnswers"
    />
  </div>
</template>

<script setup>
import { ref } from "vue";
import router from "@/router/index.js";
import MyInterviewCardHeader from "@/components/interviewApp/InterviewCardHeader.vue";

const props = defineProps({
  title: { type: String, required: true},
  campaignType: { type: String, required: true },
  deadline: { type: String, required: true },
  id: { type: Number, required: true },
  totalAnswers: { type: Number, required: true },
  submittedAnswers: { type: Number, required: true },
})

const submittedAnswersCount = props.submittedAnswers !== null &&
  props.submittedAnswers >= 0 && props.totalAnswers !== null &&
  props.totalAnswers >= 0 ? props.submittedAnswers : "#error"

const totalAnswersCount = props.totalAnswers !== null && props.totalAnswers >= 0 ? props.totalAnswers : "#error"

const percentage = ref( props.submittedAnswers >= 0 && props.totalAnswers >= 0 ? Math.round(100 * props.submittedAnswers / props.totalAnswers) : 0)

const getTagTypeByCompletion = (completion) => {
  if (completion === 0) {
    return 'error'
  } else if (completion >= 1 && completion < 100) {
    return 'warning'
  } else if (completion === 100) {
    return 'success'
  } else {
    return 'info'
  }
}

const consultAnswers = () => {
  router.push({ name: 'survey_answer', params: { id: props.id } })
}
</script>