<template>
  <div class="flex flex-col gap-4">
    <svn-pro-text-field
      v-model="question.title"
      :label="$t('Question')"
      variant="outlined"
      @update:model-value="updateQuestion"
    />

    <svn-pro-text-area
      :label="$t('Description')"
      v-model="question.description"
      :rows="6"
      :max-rows="6"
      class="w-full"
      @update:model-value="updateQuestion"
      />
  </div>
</template>

<script setup>

const props = defineProps({
  question: { type: Object, default: () => {} },
  canRemoveQuestion: { type: Boolean, default: true },
})

const emit = defineEmits(['update-question'])


const updateQuestion = () => {
  emit('update-question', props?.question);
}
</script>
