<template>
  <div class="flex flex-col gap-4">
    <v-combobox
      variant="outlined"
      :items="menuItems"
      :label="$t('Search and select by categories')"
      item-value="tagName"
      :item-title="
        (item) => {
          return item.tagName;
        }
      "
      hide-details="auto"
      prepend-inner-icon="mdi-magnify"
      color="primary"
      class="input w-full md:!w-1/2 mt-4"
      @input="updateTagSearch"
    >
      <template #item="{ props, item: { raw } }">
        <v-menu
          v-if="tagSearch === ''"
          location="end"
          content-class="max-h-[280px] rounded-xl"
          :open-on-hover="isMdScreen"
        >
          <template #activator="{ props }">
            <v-list-item
              v-bind="props"
              append-icon="custom:mingcute:right-small-fill"
              class="cursor-pointer bkt-bg-light-grey10-hover"
              @click="!isMdScreen ? (modal = true) : null"
            >
              <v-list-item-title class="text-darkGrey">
                {{ raw?.name }}
              </v-list-item-title>
            </v-list-item>
          </template>

          <svn-pro-modal v-model="modal" :title="raw?.name" @click-close="modal = false">
            <template #activator>
              {{ null }}
            </template>

            <template #text>
              <v-list>
                <v-list-item
                  v-for="(element, index) in raw?.tags"
                  :key="index"
                  :value="element?.id"
                  :active="false"
                >
                  <div
                    class="flex justify-between items-center gap-4"
                    @click.stop="toggleTagSelection(element)"
                  >
                    <svn-pro-text body-large regular class="line-clamp-1 break-all">
                      {{ element?.tagName }}
                    </svn-pro-text>

                    <div>
                      <Icon
                        :icon="
                          selectedTagsList?.find((tag) => tag?.id === element?.id) !== undefined
                            ? 'mdi-checkbox-marked'
                            : 'mdi-checkbox-blank-outline'
                        "
                        width="24"
                        height="24"
                        class="text-onSurfaceVariant"
                      />
                    </div>
                  </div>
                </v-list-item>
              </v-list>
            </template>
          </svn-pro-modal>

          <!-- Sub-menu on hover -->
          <v-list
            class="bg-surface w-[300px] max-h-[300px] overflow-y-auto rounded hidden md:!flex md:!flex-col"
            rounded="0"
          >
            <v-list-item
              v-for="(element, index) in raw?.tags"
              :key="index"
              :value="element?.id"
              class="h-[40px] px-3 py-2"
            >
              <div
                class="flex flex-row justify-start items-center gap-3"
                @click.stop="toggleTagSelection(element)"
              >
                <div>
                  <Icon
                    :icon="
                      selectedTagsList?.find((tag) => tag?.id === element?.id) !== undefined
                        ? 'mdi-checkbox-marked'
                        : 'mdi-checkbox-blank-outline'
                    "
                    width="24"
                    height="24"
                    class="text-onSurfaceVariant"
                  />
                </div>

                <svn-pro-text body-large regular class="line-clamp-1 break-all">
                  {{ element?.tagName }}
                </svn-pro-text>
              </div>
            </v-list-item>
          </v-list>
        </v-menu>

        <v-menu v-else location="end" class="max-h-[280px]">
          <template #activator="{ props }">
            <v-list-item v-bind="props" :value="raw?.id" class="cursor-pointer">
              <div
                class="flex flex-row justify-start items-center gap-3"
                @click.stop="toggleTagSelection(raw)"
              >
                <div>
                  <Icon
                    :icon="
                      selectedTagsList?.find((tag) => tag?.id === raw?.id) !== undefined
                        ? 'mdi-checkbox-marked'
                        : 'mdi-checkbox-blank-outline'
                    "
                    width="24"
                    height="24"
                    class="text-onSurfaceVariant"
                  />
                </div>

                <svn-pro-text body-large regular class="line-clamp-1 break-all">
                  {{ raw?.tagName }}
                </svn-pro-text>
              </div>
            </v-list-item>
          </template>
        </v-menu>
      </template>
    </v-combobox>

    <div class="flex flex-col gap-1">
      <svn-pro-text body-large medium>
        {{ $t('Selected participant(s): ') }} {{ usersSelected }}
      </svn-pro-text>

      <svn-pro-text body-large medium color="onSurfaceVariant">
        {{ $t('All employees in the categories below') }}
      </svn-pro-text>

      <!-- <v-btn
        v-if="selectedTagsList?.length >= 1"
        class="px-4 py-3 normal-case"
        variant="text"
        color="primary"
        :text="$t('Clear all')"
        @click="clearAllSelectedTags"
      /> -->
    </div>

    <div
      v-if="!selectedTagsList?.length"
      class="h-[240px] flex flex-col gap-2 items-center justify-center px-6 py-4"
    >
      <div>
        <Icon icon="noto-index-pointing-up" width="32" height="32" />
      </div>

      <svn-pro-text body-large regular color="onSurfaceVariant" class="text-center">
        {{
          $t(
            'It seems you have not selected a category yet. Search for a category in the search bar above.',
          )
        }}
      </svn-pro-text>
    </div>

    <div v-else class="w-full flex">
      <div
        v-if="selectedTagsList?.length !== allTagsList.length"
        class="w-full h-fit flex flex-wrap gap-2"
      >
        <svn-pro-chip
          v-for="tag in selectedTagsList"
          :key="tag?.id"
          :closable="true"
          :text="tag?.tagName"
          close-icon="custom:mingcute:close-line"
          @click:close="toggleTagSelection(tag)"
        />
      </div>

      <template v-else>
        <svn-pro-chip
          :closable="true"
          :text="$t('All categories')"
          close-icon="custom:mingcute:close-line"
          @click:close="toggleAllTagsSelection"
        />
      </template>
    </div>
  </div>
</template>

<script setup>
import { storeToRefs } from 'pinia';
import { Icon } from '@iconify/vue';
import { useCompanyStore } from '@/store/company.js';
import { useCampaignDraftStore } from '@/store/campaign-draft.js';
import { ref, onMounted, computed } from 'vue';
import axiosService from '@/tools/axios-service.js';
import { useMobileStore } from '@/store/mobile';

const { isMdScreen } = storeToRefs(useMobileStore());
const { tagCategories } = storeToRefs(useCompanyStore());
const { campaignDraft } = storeToRefs(useCampaignDraftStore());
const { fetchCompany } = useCompanyStore();

const modal = ref(false);
const allTagsList = ref([]);
const searchedTagsNames = ref([]);
const selectedTagsList = ref([]);
const tagSearch = ref('');
const tagCategoriesNames = ref(null);
const usersSelected = ref(null);
const menuItems = computed(() => {
  if (tagSearch?.value) {
    return searchedTagsNames?.value;
  }
  return tagCategories?.value;
});

onMounted(async () => {
  await fetchCompany();

  tagCategoriesNames.value = tagCategories.value.map((tagCategory) => tagCategory?.name);

  tagCategories.value.forEach((tagCategory) => allTagsList.value.push(...tagCategory.tags));

  selectedTagsList.value = campaignDraft.value.participant_filter_tag_ids?.map((id) =>
    allTagsList.value.find((element) => element.id === id),
  );
  updateTagsRequest();
});

const updateTagsRequest = async () => {
  try {
    const { data } = await axiosService.get('/api/v1/users/filter_by_tag_categories', {
      params: {
        tag_ids: campaignDraft.value.participant_filter_tag_ids,
      },
    });
    usersSelected.value = data.users;
  } catch (error) {
    console.log(error);
  }
};

const toggleTagSelection = (element) => {
  const found = selectedTagsList.value?.find((tag) => tag.id === element.id);

  if (found) {
    selectedTagsList.value = selectedTagsList.value?.filter((item) => item.id !== element.id) || [];
    campaignDraft.value.participant_filter_tag_ids =
      campaignDraft.value.participant_filter_tag_ids?.filter((tagId) => tagId !== element.id) || [];

    updateTagsRequest();
  } else {
    selectedTagsList.value?.push(element);
    campaignDraft.value.participant_filter_tag_ids?.push(element.id);

    updateTagsRequest();
  }
};

const clearAllSelectedTags = () => {
  selectedTagsList.value = [];
  campaignDraft.value.participant_filter_tag_ids = [];

  updateTagsRequest();
};

const updateTagSearch = (e) => {
  tagSearch.value = e.target.value;

  allTagsList.value.forEach((tag) => {
    if (tag?.tagName?.toLowerCase().startsWith(tagSearch.value?.toLowerCase())) {
      searchedTagsNames.value = allTagsList.value.filter((element) =>
        element?.tagName?.toLowerCase().startsWith(tagSearch.value?.toLowerCase()),
      );
    }
  });
};
</script>
