<template>
  <svn-pro-modal
    ref="editTargetIndicatorAnswer"
    :title="$t('Update answer')"
    :action-one-title="$t('Save')"
    :primary-loading="loading"
    @click-primary-button="postIndicator"
  >
    <template #activator>
      <slot name="button" />
    </template>

    <template #text>
      <div class="w-full flex flex-col gap-4">
        <svn-pro-text body-large medium color="onSurface">
          {{ title }}
        </svn-pro-text>

        <svn-pro-text-field
          v-if="indicatorFormatted.indicatorType === 'numeric_value' || indicatorFormatted.indicatorType === 'percentage'"
          v-model="indicatorFormatted.currentValue"
          variant="outlined"
          :label="$t('Current value')"
          type="number"
          @update:model-value="changed = true"
        />

        <v-radio-group
          v-else-if="indicatorFormatted.indicatorType === 'boolean'"
          v-model="indicatorFormatted.currentValue"
          hide-details
        >
          <v-radio
            color="primary"
            base-color="onSurfaceVariant"
            :label="indicatorFormatted.startingValue"
            :value="indicatorFormatted.startingValue"
          />
          
          <v-radio
            color="primary"
            base-color="onSurfaceVariant"
            :label="indicatorFormatted.targetValue"
            :value="indicatorFormatted.targetValue"
          />
        </v-radio-group>

        <v-radio-group
          v-else-if="indicatorFormatted.indicatorType === 'multi_choice'"
          v-model="indicatorFormatted.currentValue"
          hide-details
        >
          <v-radio
            v-for="(item, idx) in indicatorFormatted.multiChoiceList"
            :key="idx"
            :value="item"
            :label="item"
            color="primary"
            base-color="onSurfaceVariant"
          />
        </v-radio-group>

        <svn-pro-text-area
          v-model="toSendDesc"
          :placeholder="$t('Comment')"
          variant="outlined"
          :rows="5"
          :max-rows="5"
          @update:model-value="changed = true"
        />
      </div>
    </template>
  </svn-pro-modal>
</template>

<script setup>
import { ref }  from 'vue';
import { storeToRefs } from "pinia";
import { useRoute } from 'vue-router';
import { useSnackbar } from '@/store/snackbar';
import { useMobileStore } from "@/store/mobile";
import axiosService from "@/tools/axios-service.js";
import { useInterviewStore } from '@/store/interview';
import { useRoadmapTargetStore } from '@/store/roadmap-target';

const props = defineProps([
  "indicator",
  "title",
])

const { isMobile } = storeToRefs(useMobileStore());

const route = useRoute();
const snackbar = useSnackbar();
const { fetchInterview } = useInterviewStore();
const { fetchRoadmapTarget } = useRoadmapTargetStore();

const changed = ref(false);
const loading = ref(false);
const toSendDesc = ref(null);
const editTargetIndicatorAnswer = ref(null);

const indicatorFormatted = ref({
  id: props?.indicator?.id,
  indicatorType: props?.indicator?.indicator_type,
  startingValue: props?.indicator?.options?.starting_value,
  targetValue: props?.indicator?.options?.target_value,
  currentValue: props?.indicator?.options?.current_value,
  status: props?.indicator?.status,
  multiChoiceList: [],
});

if (indicatorFormatted.value.indicatorType === "multi_choice") {
  indicatorFormatted.value.multiChoiceList = props?.indicator?.options?.multi_choice_list.map((option) => {
    return option.option
  })
}

const postIndicator = async () => {
  loading.value = true
  try {
    await axiosService.patch(
      `/api/v1/roadmaps/indicators/${indicatorFormatted.value.id}`,
      {
        current_value: indicatorFormatted.value.currentValue,
        comment: toSendDesc.value,
      }
    );
    snackbar.setCustomClass(isMobile.value ? 'mb-[88px]' : '')
		snackbar.setMsg('Answer has been updated successfully.');
		snackbar.setBgColor('onSurface');
		snackbar.displaySnackBar();

    if (!history?.state?.current.split('/').includes('roadmaps')) {
      fetchInterview(route.params.id)
    } else {
      fetchRoadmapTarget(route.params.id);
    }
  } catch (e) {
    snackbar.setCustomClass(isMobile.value ? 'mb-[88px]' : '')
		snackbar.setMsg('Error! Your changes have not been saved.');
		snackbar.setBgColor('error');
		snackbar.displaySnackBar();
  }
  loading.value = false
  editTargetIndicatorAnswer.value.dialog = false
};

defineExpose({
  editTargetIndicatorAnswer,
})
</script>
