<template>
  <div class="flex flex-col w-full h-full overflow-y-auto">
    <!-- Header -->
    <div class="flex flex-col">
      <!-- Desktop -->
      <div
        class="hidden lg:flex h-[64px] w-full relative border-b border-middle-grey
        px-4 py-2 items-center justify-center gap-4"
      >
        <!-- Module title -->
        <p
          class="absolute font-semibold leading-6 text-2xl"
          :class="text ? 'text-fakeBlack' : 'text-middleGrey'"
        >
          {{ title ? title : $t('Module title') }}
        </p>

        <v-spacer />

        <!-- Preview module button -->
        <modal-preview-learn-module>
          <template #button>
            <div class="rounded-none px-4 h-[40px] flex items-center active:bg-trainingOrangeSoft active:text-trainingOrange">
              <Icon
                icon="ic:outline-remove-red-eye"
                class="h-6 w-6"
              />
            </div>
          </template>
        </modal-preview-learn-module>

        <!-- Three Dots button -->
        <div
          class="px-4 h-[40px] flex items-center"
        >
          <svn-dots-button
            circle
            variant="hoverNoPrimary"
          >
            <template #items>
              <v-list-item
                value="duplicate_module"
                @click="duplicateModule"
              >
                {{ $t('Duplicate module') }}
              </v-list-item>

              <v-list-item
                value="delete_module"
                class="text-negativeRed"
                @click="deleteModule"
              >
                {{ $t('Delete module') }}
              </v-list-item>
            </template>
          </svn-dots-button>
        </div>

        <!-- Save module as draft button -->
        <v-btn
          color="primary"
          variant="flat"
          class="normal-case text-xs font-medium !bg-red-200"
          :disabled="cannotBeSavedAsDraft"
          @click="saveAsDraft"
        >
          {{ $t("Save as draft") }}
        </v-btn>

        <!-- Publish module button -->
        <div>
          <publish-module-button
            :cannot-be-published="cannotBePublished"
            @publish-module="publishModule"
          />
        </div>

        <!-- Close button -->
        <div>
          <close-module-creation-button @save-draft-and-exit="saveAsDraft" />
        </div>
      </div>

      <!-- Mobile -->
      <div
        class="lg:hidden flex flex-col w-full relative border-b border-middle-grey
          items-center justify-center"
      >
        <div class="flex items-center pt-4 pr-4 w-full gap-2">
          <v-spacer />

          <!-- Preview module button -->
          <modal-preview-learn-module>
            <template #button>
              <div class="rounded-none flex items-center active:text-dark-grey cursor-pointer">
                <Icon
                  icon="ic:outline-remove-red-eye"
                  class="h-6 w-6"
                />
              </div>
            </template>
          </modal-preview-learn-module>

          <!-- Three Dots button -->
          <div
            class="flex items-center"
          >
            <svn-dots-button
              circle
              variant="hoverNoPrimary"
            >
              <template #items>
                <v-list-item
                  value="duplicate_module"
                  @click="duplicateModule"
                >
                  {{ $t('Duplicate module') }}
                </v-list-item>

                <v-list-item
                  value="delete_module"
                  class="text-negativeRed"
                  @click="deleteModule"
                >
                  {{ $t('Delete module') }}
                </v-list-item>
              </template>
            </svn-dots-button>
          </div>

          <!-- Close button -->
          <div>
            <close-module-creation-button @save-draft-and-exit="saveAsDraft" />
          </div>
        </div>

        <div class="h-[75px] px-4 gap-2 w-full flex items-center">
          <!-- Module title -->
          <p
            class="font-semibold text-xl truncate"
            :class="text ? 'text-fakeBlack' : 'text-middleGrey'"
          >
            {{ title ? title : $t('Module title') }}
          </p>

          <v-spacer />

          <!-- Save module as Draft button -->
          <v-btn
            color="primary"
            variant="flat"
            class="normal-case text-xs font-medium !bg-red-200"
            :disabled="cannotBeSavedAsDraft"
            @click="saveAsDraft"
          >
            {{ $t("Save as draft") }}
          </v-btn>

          <!-- Publish module button -->
          <div>
            <publish-module-button
              :cannot-be-published="cannotBePublished"
              @publish-module="publishModule"
            />
          </div>
        </div>
      </div>
    </div>

    <!-- Main content -->
    <div class="flex flex-col lg:w-[758px] lg:px-[180px] lg:py-8 mx-auto px-4 gap-6 lg:gap-8 w-full">
      <!-- Module Image section -->
      <div class="py-4 lg:!py-[0px]">
        <div
          id="img-preview"
          class="rounded-lg bg-middle-grey relative overflow-hidden"
          :class="isMobile ? 'h-[157px]' : 'h-[320px]'"
          :style="{'background-image': selectedFileImage ? `url('${selectedFileImage}')` : ''}"
        >
          <img
            id="preview-selected-image"
            :height="isMobile ? '157' : '320'"
          >

          <v-btn
            icon
            size="32"
            class="bg-white/50 absolute right-2 top-2 drop-shadow"
          >
            <v-file-input
              color="primary"
              accept="image/*"
              counter
              prepend-icon="mdi-pencil-outline"
              variant="outlined"
              class="file-selector text-white"
              @change="handleFileChange"
            />
          </v-btn>
        </div>
      </div>

      <div class="flex flex-col gap-6 !pb-8">
        <!-- Module title input -->
        <v-text-field
          v-model="title"
          :variant="variant"
          persistent-counter
          maxlength="70"
          counter
          :hide-details="variant === 'plain'"
          :onfocus="changeVariant"
          :onblur="changeVariant"
          class="text-fakeBlack text-2xl lg:!text-3xl font-semibold input relative"
          :placeholder="$t('Module title')"
          density="compact"
        >
          <template #counter="{ value }">
            <div
              v-if="variant === 'outlined'"
              class="absolute top-[9px] right-[14px] z-[20] pt-1 self-start"
            >
              <span class="text-middleGrey text-base font-medium">
                {{ 70 - value }}
              </span>
            </div>
          </template>
        </v-text-field>

        <div class="flex flex-col gap-2">
          <!-- Estimated duration text -->
          <div class="font-medium text-sm lg:text-xl flex items-center gap-1">
            <p>{{ $t('Estimated duration') }}</p>

            <Icon
              icon="ic:outline-info"
              class="h-4 w-4 text-darkGrey"
            />
          </div>

          <!-- Inputs -->
          <div class="flex gap-2 w-full">
            <!-- Time -->
            <div class="w-[104px] flex-items-center">
              <v-text-field
                v-model="time"
                hide-details
                density="compact"
                variant="outlined"
                placeholder="10"
                type="number"
              />
            </div>

            <!-- Time unit -->
            <div class="w-[214px]">
              <v-select
                v-model="timeUnit"
                :items="timeUnits"
                density="compact"
                hide-details
                variant="outlined"
              />
            </div>
          </div>
        </div>

        <!-- Theme block -->
        <div class="flex flex-col gap-2">
          <!-- Themes text -->
          <div class="font-medium text-sm flex items-center gap-1 lg:text-xl">
            <p>{{ $t('Themes') }}</p>

            <Icon
              icon="ic:outline-info"
              class="h-4 w-4 text-darkGrey"
            />
          </div>

          <div class="flex flex-col gap-2">
            <!-- Add theme button -->
            <div
              v-if="!newTheme"
              class="flex"
            >
              <div
                class="text-xs text-dark-grey flex gap-2 normal-case px-4 p-y-0 items-center font-medium hover:bg-light-grey rounded-lg hover:text-fake-black cursor-pointer"
                @click="newTheme = true"
              >
                <span>{{ $t('Add') }}</span>

                <div class="h-12 flex items-center ">
                  <Icon
                    icon="material-symbols:add"
                    class="h-4 w-4"
                  />
                </div>
              </div>
            </div>

            <!-- Input to add a new theme -->
            <div
              v-else
              class="w-full flex items-center gap-4 sm:!w-[258px]"
            >
              <v-text-field
                v-model="newThemeValue"
                :placeholder="$t('Enter a theme')"
                density="compact"
                variant="outlined"
                hide-details
                @keyup.enter="addTheme"
                @keyup.escape="clearAndClose"
              />

              <v-btn
                variant="flat"
                color="primary"
                height="44"
                :disabled="newThemeValue === ''"
                class="sm:hidden"
                :text="$t('Add')"
                @click="addTheme"
              />
            </div>

            <!-- Themes list -->
            <div
              v-if="themesList.length"
              class="flex flex-wrap gap-2"
            >
              <div
                v-for="(theme, index) in themesList"
                :key="theme"
                class="flex justify-center items-center px-2 py-[6px] gap-2 rounded-[8px] bg-trainingOrangeSoft"
              >
                <p class="flex text-xs font-normal text-fakeBlack items-center">
                  {{ theme }}
                </p>

                <div @click="deleteTheme(theme)">
                  <Icon
                    icon="mdi-close"
                    height="16"
                    width="16"
                    class="text-middleGrey"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Rich text editor -->
        <div class="flex items-center justify-center h-[216px] bg-light-grey">
          <p class="font-semibold">
            Text editor
          </p>
        </div>

        <!-- Evaluation block -->
        <div class="flex flex-col gap-2">
          <!-- Evaluation block title -->
          <div class="font-medium text-sm lg:text-xl flex items-center gap-1">
            <p>{{ $t('Evaluation') }}</p>
          </div>

          <!-- Evaluation main block -->
          <div class="relative flex flex-col lg:gap-4 gap-2 pb-32 lg:pb-0">
            <!-- Evalluation with a quiz if false -->
            <div class="w-full flex flex-col relative !rounded-xl border border-darkGrey">
              <div class="flex flex-col gap-4 p-4 lg:gap-6 lg:p-6 w-full">
                <!-- Header -->
                <div class="flex items-center w-full">
                  <!-- Text -->
                  <div class="font-medium text-sm flex items-center gap-1 grow">
                    <p>{{ $t('Evaluate this module with a quiz') }}</p>

                    <Icon
                      icon="ic:outline-info"
                      class="h-4 w-4 text-darkGrey"
                    />
                  </div>

                  <!-- Switch -->
                  <div>
                    <v-switch
                      v-model="doEvaluation"
                      hide-details
                      inset=""
                      color="primary"
                    />
                  </div>
                </div>

                <!-- Description if No evaluation -->
                <div
                  v-if="!doEvaluation"
                  class="text-darkGrey text-sm border-t border-light-grey pt-2 lg:!pt-4"
                >
                  {{ $t('An approval is required to acquire this module if you do not chose quiz evaluation. The learner will have to answer to the following question at the end of the module : “Did you fully understand this module ?”') }}
                </div>

                <!-- Question blocks -->
                <div
                  v-if="doEvaluation"
                  class="flex flex-col gap-2"
                >
                  <transition-group>
                    <draggable
                      key="draggable"
                      item-key="item"
                      :list="quizBlock"
                      v-bind="dragOptions"
                      class="flex flex-col gap-2"
                      :delay="100"
                      @start="drag = true"
                      @end="drag = false"
                    >
                      <template #item="{ element }">
                        <div
                          :key="element?.type"
                          class="rounded-lg border p-4"
                        >
                          <learn-edit-question-block v-if="element.type === 'question'" />

                          <learn-edit-free-content-block v-else-if="element.type === 'content'" />
                        </div>
                      </template>
                    </draggable>
                  </transition-group>
                </div>
              </div>
            </div>

            <!-- If Evaluation with quiz is true -->
            <div
              v-if="doEvaluation"
              class="relative 2xl:absolute flex flex-col 2xl:left-full bg-white"
            >
              <div class="2xl:pl-[18px] flex flex-col gap-2 lg:gap-4">
                <learn-add-question-block
                  @click="addQuizQuestionBlock()"
                />
                <learn-add-free-content-block
                  @click="addQuizContentBlock()"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Scroll to top button -->
    <svn-floating-action-button
      square
      to-top
    />
  </div>
</template>

<script setup>
import { Icon } from "@iconify/vue";
import draggable from "vuedraggable";
import CloseModuleCreationButton from "@/components/trainingApp/CloseModuleCreationButton.vue";
import ModalPreviewLearnModule from "@/components/BktPopUp/Modals/learn/ModalPreviewLearnModule.vue";
import PublishModuleButton from "@/components/trainingApp/PublishModuleButton.vue";
import { useLearnModuleStore } from "@/store/learn-module";
import LearnAddQuestionBlock from "@/components/learnApp/moduleBlock/createBlock/LearnAddQuestionBlock.vue";
import LearnAddFreeContentBlock from "@/components/learnApp/moduleBlock/createBlock/LearnAddFreeContentBlock.vue";
import LearnEditQuestionBlock from "@/components/learnApp/moduleBlock/editBlock/LearnEditQuestionBlock.vue";
import LearnEditFreeContentBlock from "@/components/learnApp/moduleBlock/editBlock/LearnEditFreeContentBlock.vue";
import { useMobileStore } from "@/store/mobile";
import { storeToRefs } from "pinia";
import { useSnackbar } from "@/store/snackbar";
import router from "@/router";

const title = ref('')

const cannotBeSavedAsDraft = ref(false)

const alignTop = ref(false)

const newTheme = ref(false)

const newThemeValue = ref('')

const drag = ref(false)

const snackbar = useSnackbar()

const cannotBePublished = ref(true)

const { updateModule } = useLearnModuleStore()

const { learnModule } = storeToRefs(useLearnModuleStore())

const dragOptions = ref({
  animation: 200,
  group: 'description',
  disabled: false,
  ghostClass: 'ghost',
});

const themesList = ref([
  "Theme de la tête",
  "Les enfants",
  "La santé"
])

const deleteTheme = (theme) => {
  themesList.value.splice(themesList.value.findIndex(el => el === theme), 1)
}

const clearAndClose = () => {
  newTheme.value = false
  newThemeValue.value = ''
}
const doEvaluation = ref(false)

const time = ref(null)

const timeUnit = ref('minutes')

const timeUnits = ref([
  'minutes',
  'hours',
])

const publishModule = () => {
  snackbar.setBgColor('positiveGreen')
  snackbar.setMsg('Module published!')
  snackbar.setCustomClass(isMobile.value ? 'mb-[80px]' : '')
  snackbar.displaySnackBar()

  router.push({ name: "module_show", params: { id: 456 } })
}

const saveAsDraft = () => {
  snackbar.setBgColor('fakeBlack')
  snackbar.setMsg('Your module has been saved in your drafts')
  snackbar.setCustomClass(isMobile.value ? 'mb-[80px]' : '')
  snackbar.displaySnackBar()

  router.push({ name: "module_show", params: { id: 456 } })
}

const selectedFileImage = ref([])

const variant = ref('plain')

const props = defineProps({
  icon: { type: Boolean, default: false }
})

const { isMobile } = storeToRefs(useMobileStore())

const changeVariant = () => {
  if (variant.value === 'plain') {
    variant.value = 'outlined'
  }
  else {
    variant.value = 'plain'
  }
}

const addTheme = () => {
  if (newThemeValue.value) {
    themesList.value.push(newThemeValue.value)
  }
  newTheme.value = false
  newThemeValue.value = ''
}

const handleFileChange = async(e) => {
  const file = e.target.files[0]

  if (file) {
    try {
      // await updateModule("Module title", "30 min", file)

      const imagePreviewElement = document.querySelector("#preview-selected-image");
      imagePreviewElement.src = learnModule?.value?.cover_url;
      imagePreviewElement.style.display = "block";
    } catch (error) {
      console.log(error)
    }
  }
}

const quizBlock = ref([
  {
    type: 'question'
  },
  {
    type: 'content'
  }
])

const addQuizQuestionBlock = () => {
  quizBlock.value.push({
    type: 'question'
  })
}

const addQuizContentBlock = () => {
  quizBlock.value.push({
    type: 'content'
  })
}

const duplicateModule = () => {

}

const deleteModule = () => {

}

</script>

<style scoped>
.input :deep(.v-input__details) {
  display: flex;
  flex-direction: row-reverse;
  padding: 0px;
}

.input :deep(.v-field__input) {
  padding-right: 40px;
}

* :deep(.v-field) {
  border-radius: 8px;
}

.file-selector :deep(.v-input__control) {
  display: none;
}

.file-selector :deep(.mdi-pencil-outline) {
  margin-left: 17px;
  margin-top: 3px;
}
</style>
