import { defineStore } from "pinia";
import { genericFunctions, genericStates } from "@/tools/genericFunctions.js";

export const useFilterStore = defineStore({
  id: "filter",
  state: () => ({
    columns: null,
    loading: true,
    ...genericStates,
  }),
  getters: {},
  actions: {
    ...genericFunctions,
    async fetchFilterMetadata(tableName) {
      const filterStore = useFilterStore();

      await filterStore.fetchEntity(`/api/v1/metadata/${tableName}`, "columns");
      this.loading = false;
    },
    async resetStates() {
      this.columns = null;
      this.loading = true;
    },
  }
});