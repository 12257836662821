export default function () {
  function campaign_icon(type) {
    const icon = {
      crossed: 'uil:exchange',
      simple: 'uil:exchange',
      OneToOne: 'noto:handshake',
      Feedback: 'noto:memo',
      Survey: 'noto:bar-chart'
    }
    return icon[type]
  }
  function campaign_type_str(type) {
    const types = {
      crossed: '1 to 1',
      simple: '1 to 1',
      one_to_one: '1 to 1',
      feedback_360: 'Feedback 360',
      survey: "Survey",
    }
    return types[type]
  }
  function campaign_status_str(status) {
    switch (status) {
      case "not_started":
        return "Not started"
      case "in_progress":
        return "In progress"
      case "submitted":
        return "Submitted"
      case "not_available_yet":
        return "Not available yet"
      default:
        return ""
    }
  }
  function getBgByCampaignStatus(status) {
    if (status === 'not_started') return 'bg-negativeRed'
    else if (status === 'in_progress') return 'bg-mediumOrange'
    else if (status === 'submitted') return 'bg-positiveGreen'
    else if (status === 'not_available_yet') return 'bg-darkGrey'
  }
  function getTextByCampaignStatus(status) {
    if (status === 'not_started') return 'Not started'
    else if (status === 'in_progress') return 'In progress'
    else if (status === 'submitted') return 'Submitted'
    else if (status === 'not_available_yet') return 'Not avaible yet'
  }
  function getColorByCampaignStatus(status) {
    if (status === 'not_started') return 'error'
    else if (status === 'in_progress') return 'warning'
    else if (status === 'submitted') return 'success'
    else if (status === 'not_available_yet') return 'info'
  }
  function getTagTypeByCampaignStatus(status) {
    if (status === 'not_started') return 'error'
    else if (status === 'in_progress') return 'warning'
    else if (status === 'submitted') return 'success'
    else if (status === 'not_available_yet') return 'info'
  }
  function getBgWithBorderByCampaignStatus(status) {
    if (status === 'not_started')
      return 'bg-transparentNegativeRed border-bkt-red text-negativeRed'
    else if (status === 'in_progress')
      return 'bg-transparentMediumOrange border-bkt-yellow text-mediumOrange'
    else if (status === 'submitted')
      return 'bg-transparentPositiveGreen border-bkt-green text-positiveGreen'
    else if (status === 'not_available_yet')
      return 'bkt-bg-light-grey5 border-bkt-light-grey6 text-middleGrey'
  }
  function getInfoTagType(startingValue, currentValue, targetValue, trueOptions) {

    if (startingValue == null || currentValue == null || targetValue == null) {
      return 'info';
    } else {
      if (startingValue === currentValue && trueOptions !== 'boolean')
        return 'error';
      else if (targetValue === currentValue && trueOptions === 'boolean')
        return 'success';
      else if (targetValue !== currentValue && trueOptions === 'boolean')
        return 'error';
      else if (trueOptions === 'multi_choice' && Array.isArray(targetValue) && targetValue?.some((x) => x.option === currentValue)) {
        return 'success';
      }
      else if ((trueOptions === 'numeric_value' || trueOptions === 'percentage') && Number(currentValue) >= Number(targetValue)) {
        return 'success';
      }
      else
        return 'warning';
    }
  }

  function getDotColorByCampaignStatus(status) {
    if (status === 'not_started') return 'bg-negativeRed'
    else if (status === 'in_progress') return 'bg-mediumOrange'
    else if (status === 'submitted') return 'bg-positiveGreen'
    else if (status === 'not_available_yet') return 'bg-middleGrey'
  }
  return {
    campaign_type_str,
    campaign_icon,
    getBgByCampaignStatus,
    getTextByCampaignStatus,
    getColorByCampaignStatus,
    getTagTypeByCampaignStatus,
    getDotColorByCampaignStatus,
    getBgWithBorderByCampaignStatus,
    getInfoTagType,
    campaign_status_str
  }
}
