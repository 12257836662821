<template>
  <svn-pro-modal
    v-model="dialogEditPlaylist"
    :width="isMdScreen ? '580' : ''"
    :title="$t('Update playlist')"
    no-click-animation
    :actionOneTitle="$t('Validate')"
    :primary-loading="loading"
    :sticky-bottom="isMdScreen ? true : false"
    :close-button-closes-modal="false"
    @click-close="dialogEditPlaylist = false"
    @click-primary-button="updatePlaylist"
    @click-outside="dialogEditPlaylist = false"
  >
    <template #activator="{ props }">
      {{ null }}
    </template>

    <template #text>
      <div class="flex flex-col gap-6">
        <!-- Title input -->
        <svn-pro-text-field
          v-model="playlistTitle"
          variant="outlined"
          color="onSurfaceVariant"
          :label="('Playlist title*')"
          :counter="true"
          maxlength="70"
          :errorMessages="errorMessage"
        />

        <!-- Description input -->
        <svn-pro-text-area
          v-model="playlistDescription"
          :label="$t('Description')"
          variant="outlined"
          :rows="6"
          :max-rows="6"
          :counter="true"
          :maxlength="200"
        />
      </div>
    </template>
  </svn-pro-modal>
</template>

<script setup>
import { ref, watch } from 'vue';
import { storeToRefs } from 'pinia';
import { useMobileStore } from '@/store/mobile';

const props = defineProps({
  title: {
    type: String,
    default: ''
  },
  description: {
    type: String,
    default: ''
  },
  loading: {
    type: Boolean,
    default: false
  }
})

const errorMessage = ref('');
const playlistTitle = ref('');
const playlistDescription = ref('');
const dialogEditPlaylist = ref(false);

const { isMdScreen } = storeToRefs(useMobileStore())

const emit = defineEmits(['update-playlist'])

const updatePlaylist = () => {
  errorMessage.value = !playlistTitle.value ? 'Required*' : ''
  if (errorMessage.value) return
  emit('update-playlist', playlistTitle.value, playlistDescription.value)

}

watch(props ,() => {
  playlistTitle.value = props.title
  playlistDescription.value = props.description
})

watch(playlistTitle, (newValue, oldValue) => {
  if (newValue) {
    errorMessage.value = ''
  }
})

defineExpose({
  dialogEditPlaylist
})
</script>