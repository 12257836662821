<template>
  <v-btn
    v-if="showResults"
    :color="selectedFilters ? 'primary' : activatorColor"
    v-bind="props"
    :variant="activatorVariant"
    :prepend-icon="activatorIcon"
    :text="activatorText"
    :height="activatorHeight"
    class="!normal-case !rounded-[8px]"
    @click="emit('activator-clicked')"
  >
    <template
      v-if="selectedFilters"
      #append
    >
      <div class="w-4 h-4 flex justify-center items-center !bg-primary-lighten-3 !p-1 rounded-full">
        <p class="text-primary">
          {{ selectedFilters }}
        </p>
      </div>
    </template>
  </v-btn>

  <v-menu
    v-else
    origin="top end"
    location="bottom end"
    :close-on-content-click="true"
  >
    <template #activator="{ props }">
      <v-btn
        :color="selectedFilters ? 'primary' : activatorColor"
        v-bind="props"
        :variant="activatorVariant"
        :prepend-icon="activatorIcon"
        :text="(activatorText)"
        :height="activatorHeight"
        class="!normal-case !rounded-[8px]"
        @click="getFilterColumns"
      >
        <template
          v-if="selectedFilters"
          #append
        >
          <div class="w-4 h-4 flex justify-center items-center !bg-primary-lighten-3 !p-1 rounded-full">
            <p class="text-primary">
              {{ selectedFilters }}
            </p>
          </div>
        </template>
      </v-btn>
    </template>

    <div
      v-if="loading"
      class="w-[200px] h-[200px] flex flex-col justify-center items-center rounded bg-white shadow-md"
    >
      <svn-loader loading-size="lg" />
    </div>

    <v-list
      v-else
      class="py-0 max-h-[250px]"
    >
      <v-list-item
        v-for="(column) in filterColumns"
        :key="column?.name"
        :value="column?.name"
        @click="addFilter(column)"
      >
        <div
          class="flex items-center gap-2 flex-1"
        >
          <!-- List item icon -->
          <div v-if="column?.icon">
            <Icon
              :icon="column?.icon"
              height="16"
              width="16"
              class="text-fakeBlack"
            />
          </div>

          <!-- List item text -->
          <p class="text-fakeBlack text-sm font-normal line-clamp-1">
            {{ column?.name }}
          </p>
        </div>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script setup>
import { onMounted } from 'vue';
import { Icon } from '@iconify/vue';
import filters from '@/tools/filters';
import { useFilterStore } from '@/store/filters';
import { useUserStore } from '@/store/user';
import { useCampaignStore } from '@/store/campaign';
import { useInterviewFormStore } from "@/store/interview-form.js";
import { useRoadmapTargetStore } from "@/store/roadmap-target.js";
import { useRoadmapEmployeeStore } from "@/store/roadmap-employee.js";
import { storeToRefs } from 'pinia';

const props = defineProps({
  tableName: { type: String, default: 'campaigns' },
  activatorText: { type: String, default: 'Filters' },
  activatorIcon: { type: String, default: 'mdi-filter-outline' },
  activatorVariant: { type: String, default: 'outlined' },
  activatorColor: { type: String, default: 'darkGrey' },
  activatorHeight: { type: Number, default: 48 },
  filterColumns: { type: Array, default: () => [] },
  selectedFilters: { type: Number, default: null },
  showResults: { type: Boolean, default: false },
})

const emit = defineEmits(['add-filter', 'activator-clicked', 'get-filter-columns']);

onMounted(() => {
  users.value = null;
  userParams.value = {};
  campaignParams.value = {};
  interviewFormParams.value = {};
  roadmapTargetsParams.value = {};
  roadmapEmployeeParams.value = {};
})

const { loading } = storeToRefs(useFilterStore());
const { params: userParams, users } = storeToRefs(useUserStore());
const { params: campaignParams } = storeToRefs(useCampaignStore());
const { params: interviewFormParams } = storeToRefs(useInterviewFormStore());
const { params: roadmapTargetsParams } = storeToRefs(useRoadmapTargetStore());
const { params: roadmapEmployeeParams, } = storeToRefs(useRoadmapEmployeeStore());

const getFilterColumns = async() => {
  emit('get-filter-columns')
}

const addFilter = (item) => {
  let newItem = { ...item, id: filters.generateUniqueId() }
  emit('add-filter', newItem)
}
</script>
