import SevenImgObject from '@/models/seven_img_object';
import Reaction from '@/models/reaction';
import MetaPagination from '@/models/meta_pagination';

class ModulePaginationByTraining {
  constructor({ currentModuleId, nextModuleId, prevModuleId }) {
    this.currentModuleId = currentModuleId;
    this.nextModuleId = nextModuleId;
    this.prevModuleId = prevModuleId;
  }

  static fromJson(data) {
    const json = {};

    json['currentModuleId'] = data?.['current_module_id'];
    json['nextModuleId'] = data?.['next_module_id'];
    json['prevModuleId'] = data?.['prev_module_id'];

    return new ModulePaginationByTraining(json);
  }
}

export default class LearnModule {
  constructor({
    id,
    pagination,
    userReactions,
    coverUrl,
    connectedUserFavorited,
    contentType,
    creatorId,
    deletedAt,
    duration,
    editorContentId,
    publishedAt,
    reactionType,
    status,
    playlistIds,
    submissionStatus,
    submissionUpdatedAt,
    themes,
    title,
    updatedAt,
  }) {
    this.id = id;
    this.pagination = pagination;
    this.userReactions = userReactions;
    this.coverUrl = coverUrl;
    this.connectedUserFavorited = connectedUserFavorited;
    this.contentType = contentType;
    this.creatorId = creatorId;
    this.deletedAt = deletedAt;
    this.duration = duration;
    this.editorContentId = editorContentId;
    this.publishedAt = publishedAt;
    this.reactionType = reactionType;
    this.status = status;
    this.playlistIds = playlistIds;
    this.submissionStatus = submissionStatus;
    this.submissionUpdatedAt = submissionUpdatedAt;
    this.themes = themes;
    this.title = title;
    this.updatedAt = updatedAt;
  }

  static fromJson(data) {
    const json = {};

    json['id'] = data?.['id'];
    json['pagination'] = ModulePaginationByTraining.fromJson(data?.['pagination']);
    json['userReactions'] = data?.['user_reactions']?.map(Reaction.fromJson) ?? [];
    json['coverUrl'] = SevenImgObject.fromJson(data?.['cover_url']);
    json['connectedUserFavorited'] = data?.['connected_user_favorited'];
    json['contentType'] = data?.['content_type'];
    json['creatorId'] = data?.['creator_id'];
    json['deletedAt'] = data?.['deleted_at'];
    json['duration'] = data?.['duration'];
    json['editorContentId'] = data?.['editor_content_id'];
    json['publishedAt'] = data?.['published_at'];
    json['reactionType'] = data?.['reaction_type'];
    json['status'] = data?.['status'];
    json['playlistIds'] = data?.['playlist_ids'];
    json['submissionStatus'] = data?.['submission_status'];
    json['submissionUpdatedAt'] = data?.['submission_updated_at'];
    json['themes'] = data?.['themes'];
    json['title'] = data?.['title'];
    json['updatedAt'] = data?.['updated_at'];

    return new LearnModule(json);
  }

  copyWith({
    id,
    pagination,
    userReactions,
    coverUrl,
    connectedUserFavorited,
    contentType,
    creatorId,
    deletedAt,
    duration,
    editorContentId,
    publishedAt,
    reactionType,
    status,
    playlistIds,
    submissionStatus,
    submissionUpdatedAt,
    themes,
    title,
    updatedAt,
  }) {
    return new LearnModule({
      id: id ?? this.id,
      pagination: pagination ?? this.pagination,
      userReactions: userReactions ?? this.userReactions,
      coverUrl: coverUrl ?? this.coverUrl,
      connectedUserFavorited: connectedUserFavorited ?? this.connectedUserFavorited,
      contentType: contentType ?? this.contentType,
      creatorId: creatorId ?? this.creatorId,
      deletedAt: deletedAt ?? this.deletedAt,
      duration: duration ?? this.duration,
      editorContentId: editorContentId ?? this.editorContentId,
      publishedAt: publishedAt ?? this.publishedAt,
      reactionType: reactionType ?? this.reactionType,
      status: status ?? this.status,
      playlistIds: playlistIds ?? this.playlistIds,
      submissionStatus: submissionStatus ?? this.submissionStatus,
      submissionUpdatedAt: submissionUpdatedAt ?? this.submissionUpdatedAt,
      themes: themes ?? this.themes,
      title: title ?? this.title,
      updatedAt: updatedAt ?? this.updatedAt,
    });
  }
}

export class LearnModulePagination {
  constructor({ learnModules, meta }) {
    this.learnModules = learnModules;
    this.meta = meta;
  }

  static fromJson(data) {
    const json = {};

    json['learnModules'] = data?.['learn_modules']?.map(LearnModule.fromJson) ?? [];
    json['meta'] = MetaPagination.fromJson(data?.['meta']);

    return new LearnModulePagination(json);
  }

  copyWith({ learnModules, meta }) {
    return new LearnModulePagination({
      learnModules: learnModules ?? this.learnModules,
      meta: meta ?? this.meta,
    });
  }
}
