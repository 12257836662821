<template>
  <svn-pro-info-tag
    v-if="(!objectiveIndicator?.options.current_value || (!objectiveIndicator?.options.target_value && objectiveIndicator.indicator_type !== 'multi_choice'))"
    :tag-type="getInfoTagType(objectiveIndicator?.options.starting_value, objectiveIndicator?.options.current_value, objectiveIndicator?.options.target_value, objectiveIndicator?.indicator_type)"
    :text="$t('Not yet set')"
    class="w-fit h-fit"
    tag-size="compact"
  />

  <svn-pro-info-tag
    v-else-if="objectiveIndicator.indicator_type === 'percentage'"
    :tag-type="getInfoTagType(objectiveIndicator?.options.starting_value, objectiveIndicator?.options.current_value, objectiveIndicator?.options.target_value, objectiveIndicator?.indicator_type)"
    :text="`${objectiveIndicator?.options.current_value + '%' + ' / ' + objectiveIndicator?.options.target_value + '%'}`"
    class="w-fit h-fit"
    tag-size="compact"
  />

  <svn-pro-info-tag
    v-else-if="objectiveIndicator.indicator_type === 'boolean'"
    :tag-type="getInfoTagType(objectiveIndicator?.options.starting_value, objectiveIndicator?.options.current_value, objectiveIndicator?.options.target_value, objectiveIndicator?.indicator_type)"
    :text="objectiveIndicator?.options.current_value"
    class="w-fit h-fit"
    tag-size="compact"
  />

  <svn-pro-info-tag
    v-else-if="objectiveIndicator.indicator_type === 'numeric_value'"
    :tag-type="getInfoTagType(objectiveIndicator?.options.starting_value, objectiveIndicator?.options.current_value, objectiveIndicator?.options.target_value, objectiveIndicator?.indicator_type)"
    :text="`${objectiveIndicator?.options.current_value + ' / ' + objectiveIndicator?.options.target_value}`"
    class="w-fit h-fit"
    tag-size="compact"
  />

  <svn-pro-info-tag
    v-else-if="objectiveIndicator.indicator_type === 'multi_choice'"
    :tag-type="getInfoTagType(0, objectiveIndicator?.options.current_value, objectiveIndicator?.options?.multi_choice_list?.filter(option => option.checkbox === true), objectiveIndicator?.indicator_type)"
    :text="objectiveIndicator?.options.current_value"
    class="w-fit h-fit"
    tag-size="compact"
  />
</template>

<script setup>
import useCampaigns from '@/tools/useCampaigns';

const { getInfoTagType } = useCampaigns();

const props = defineProps({
  objectiveIndicator: { type: Object, default: () => {} }
})
</script>
