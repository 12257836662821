<template>
    <svn-pro-modal
    :title="$t('Interview updates')"
    :width="isMdScreen ? 680 : ''"
    @click-close="emit('close')"
  >
    <template #activator="{ props }">
      <slot name="activator" :props="props" />
    </template>
    
    <template #text>
      <div
        v-if="history?.length"
        class="w-full h-full overflow-y-auto md:!px-5"
      >
        <v-infinite-scroll
          :items="history"
          :empty-text="$t('No more history !')"
          class="overflow-hidden"
          @load="loadMoreHistory"
        >
          <template #default>
            <div
              v-for="hist in history"
              :key="hist?.id"
              class="w-full flex flex-col gap-2 py-4 border-b border-borderColor border-opacity-100"
            >
              <div class="flex items-center gap-2">
                <!-- Avatar (person who made the update) -->
                <svn-pro-avatar
                  :size="40"
                  :firstname="hist?.user?.fullname"
                  :image="hist?.user?.avatar?.['50']"
                  :type="hist?.user?.avatar?.['50'] ? 'photo' : 'monogram'"
                />

                <div class="w-full flex flex-col">
                  <!-- Update message -->
                  <svn-pro-text body-large medium>
                    {{ hist?.title }}
                  </svn-pro-text>

                  <!-- Update date -->
                  <svn-pro-text body-medium regular color="onSurfaceVariant">
                    {{ moment(hist?.created_at).format('dddd DD MMMM, HH:mm') }}
                  </svn-pro-text>
                </div>
              </div>
              
              <div class="flex items-center gap-2">
                <!-- Old status -->
                <svn-pro-text body-medium medium color="primary">
                  {{ $t(campaign_status_str(hist?.old_value)) }}
                </svn-pro-text>

                <!-- Arrow -->
                <div>
                  <Icon
                    icon="mingcute:arrow-right-line"
                    height="16"
                    width="16"
                    class="text-primary"
                  />
                </div>

                <!-- New status -->
                <svn-pro-text body-medium medium color="primary">
                  {{ $t(campaign_status_str(hist?.new_value)) }}
                </svn-pro-text>
              </div>
            </div>
          </template>

          <template #loading>
            <svn-pro-progress-circular
              v-if="history.length !== pagination?.total_count"
              indeterminate
            />
          </template>
        </v-infinite-scroll>
      </div>
    </template>
  </svn-pro-modal>
</template>

<script setup>
import moment from "moment";
import { Icon } from "@iconify/vue";
import { storeToRefs } from "pinia";
import { onMounted, ref, watch } from "vue";
import { useMobileStore } from "@/store/mobile";
import useCampaigns from "@/tools/useCampaigns.js";

const props = defineProps({
  interviewId: { type: Number, required: true },
  history: { type: Array, default: () => [] },
  pagination: { type: Object, default: () => {} },
})

const { campaign_status_str } = useCampaigns();

const { isMdScreen } = storeToRefs(useMobileStore());

const historyRef = ref([]);
const paginationRef = ref(null);

const emit = defineEmits(['close', 'load-more'])

onMounted(async() => {
  historyRef.value = props?.history
  paginationRef.value = props?.pagination
});

const loadMoreHistory = async() => {
  emit('load-more')
}

watch(props, (newValue, oldValue) => {
  if (newValue?.history) {
    historyRef.value = newValue?.history
  }
  if (newValue?.pagination) {
    paginationRef.value = newValue?.pagination
  }
})
</script>