<template>
  <svn-pro-info-tag
    :tag-type="getTagTypeByCampaignStatus(status)"
    leading-item="point"
    tag-size="compact"
    tag-style="text"
    :text="$t(campaign_status_str(status))"
  />
</template>

<script setup>
import useCampaigns from "@/tools/useCampaigns.js";

const props = defineProps({
  status: {type: String, required: true},
})

const { campaign_status_str, getTagTypeByCampaignStatus } = useCampaigns();
</script>