<template>
  <div class="w-full flex justify-center items-center">
    <v-divider class="border-opacity-100 w-1/2 border-[#767680]" />

    <div v-if="isDeleted" class="h-[40px] text-primary flex items-center px-4 opacity-[.38]">
      <icon icon="mingcute:add-line" class="mr-2 text-onSurface" height="16" width="16"  /> {{ $t('ADD') }}
    </div>
    <svn-pro-menu v-else list-density="compact">
      <template #activator>
        <svn-pro-button
          color="primary"
          variant="text"
          :text="$t('Add')"
          :prepend-icon="'custom:mingcute:add-line'"
        />
      </template>

      <template #dropdown>
        <template v-for="(item, index) in filteredTypes">
          <v-list-item
            v-if="!item?.show"
            :key="item?.id"
            :value="item"
            :prepend-icon="item.prependIcon"
            :disabled="item?.disabled"
            @click="$emit('add', item.value)"
          >
            <svn-pro-text :color="item?.error ? 'error' : 'onSurface'">
              {{ item?.title }}
            </svn-pro-text>
          </v-list-item>
        </template>
      </template>
    </svn-pro-menu>
    <v-divider class="border-opacity-100 w-1/2 border-[#767680]" />
  </div>
</template>

<script setup>
import { computed, ref } from "vue";
import { storeToRefs } from "pinia";
import { useInterviewFormStore } from "@/store/interview-form.js";
import { useI18n } from "vue-i18n";
import { useCompanyStore } from "@/store/company.js";
import { InterviewQuestionType } from '@/constants/types';
import { Icon } from "@iconify/vue";

const { isAppAvailable } = useCompanyStore();
const { t } = useI18n();
const { interviewQuestions } = storeToRefs(useInterviewFormStore());
const {isFeedBack360, isSurvey} = useInterviewFormStore()

const props = defineProps({
  isDeleted: { type: Boolean, required: true }
});

const types = ref([
  {
    title: t("Chapter"),
    value: InterviewQuestionType.CHAPTER,
    prependIcon: "custom:mingcute:align-left-line",
    disabled: false,
  },
  {
    title: t("Paragraph"),
    value: InterviewQuestionType.PARAGRAPH,
    prependIcon: "custom:mingcute:align-left-line",
    disabled: false,
  },
  {
    title: t("Open question"),
    value: InterviewQuestionType.OPEN,
    prependIcon: "custom:mingcute:question-line",
    disabled: false,
  },
  {
    title: t("Rating"),
    value: InterviewQuestionType.RATING,
    prependIcon: "custom:mingcute:star-line",
    disabled: false,
  },
  {
    title: t("Multi-choice"),
    value: InterviewQuestionType.MCQ,
    prependIcon: "custom:mingcute:checkbox-line",
    disabled: false,
  },
  {
    title: t("Roadmap - Create target"),
    value: InterviewQuestionType.CREATE_ROADMAP,
    prependIcon: "custom:mingcute:target-line",
    disabled: !isAppAvailable("objectives"),
  },
  {
    title: t("Roadmap - Update target"),
    value: InterviewQuestionType.UPDATE_ROADMAP,
    prependIcon: "custom:mingcute:target-line",
    disabled: !isAppAvailable("objectives"),
  }
]);

const filteredTypes = computed(() => {
  const roadmapCreateCount = interviewQuestions.value.filter(
    (item) => item.type === InterviewQuestionType.CREATE_ROADMAP).length;

  const roadmapUpdateCount = interviewQuestions.value.filter(
    (item) => item.type === InterviewQuestionType.UPDATE_ROADMAP).length;

  let array = types.value.map((item) => {
    if (item.value === InterviewQuestionType.UPDATE_ROADMAP) {
      item.disabled = (roadmapUpdateCount > 0) ? true : false;
      return item;
    }
    if (item.value === InterviewQuestionType.CREATE_ROADMAP) {
      item.disabled = (roadmapCreateCount > 0) ? true : false;
      return item;
    }
    else {
      return item;
    }
  })

  return array.filter((item) => {
    if ( item.value === InterviewQuestionType.CREATE_ROADMAP) {
      const display = isFeedBack360() || isSurvey() ? false : true
      return display
    }
    if (item.value === InterviewQuestionType.UPDATE_ROADMAP) {
      const display = isFeedBack360() || isSurvey() ? false : true
      return display
    }
    else {
      return true;
    }
  });
});
</script>
