<template>
  <div
    v-if="logs.length"
    class="w-full h-full flex flex-col px-4"
  >
    <!-- If target is completed -->
    <div
      v-if="indicator?.status === 'completed'"
      class="pt-5 pb-4 flex flex-col gap-3 justify-center items-center border-b border-[#767680] border-opacity-100"
    >
      <div>
        <Icon
          icon="noto-trophy"
          height="48"
          width="48"
        />
      </div>

      <div class="flex flex-col justify-center items-center gap-1">
        <svn-pro-text body-medium medium>
          {{ $t('Target achieved!') }}
        </svn-pro-text>

        <svn-pro-text body-medium regular color="onSurfaceVariant">
          {{ moment(indicator.updated_at).format('ddd. DD MMM, HH:mm') }}
        </svn-pro-text>
      </div>
    </div>

    <!-- List of logs -->
    <div class="w-full h-full flex-1 flex flex-col">
      <div
        v-for="(log, idx) in logs"
        :key="idx"
        class="py-4 w-full flex flex-col gap-2"
        :class="idx + 1 !== logs.length ? 'border-b !border-[#767680]' : ''"
      >
        <div class="flex gap-2 items-center">
          <svn-pro-avatar
            :type="log.user.avatar?.['50'] ? 'photo' : 'monogram'"
            :image="log.user.avatar?.['50']"
            :firstname="log.user.firstname"
            :lastname="log.user.lastname"
            :size="40"
          />

          <div class="flex flex-col">
            <svn-pro-text body-large medium class="line-clamp-1 !break-all">
              {{
                log?.comment ?
                  $t("user commented", { user: log?.user?.firstname }) :
                  `${log?.title}`
              }}
            </svn-pro-text>

            <svn-pro-text body-medium regular color="onSurfaceVariant">
              <!-- {{ moment(log.created_at).format('dddd DD YYYY')  }} -->
              {{ $filters.formatSpecificDateCalendar(log.created_at, "YYYY-MM-DDTHH:mm:ss.sssZ") }}
            </svn-pro-text>
          </div>
        </div>

        <div
          v-if="log.old_value !== log.new_value &&
            log.state !== 'status_updated' &&
            log?.title !== 'Title updated' &&
            log?.title !== 'Description updated' &&
            log?.title !== 'Due date updated'"
          class="flex items-center gap-4"
        >
          <svn-pro-info-tag
            v-if="indicator?.indicator_type === 'percentage' || indicator?.indicator_type === 'numeric'"
            tag-style="filled"
            :tag-type="parseInt(log.new_value) - parseInt(log.old_value) > 0 ? 'success' : parseInt(log.new_value) - parseInt(log.old_value) < 0 ? 'error' : 'info'"
            :text="parseInt(log.new_value) - parseInt(log.old_value) > 0 ? `+${parseInt(log.new_value) - parseInt(log.old_value)}%` : parseInt(log.new_value) - parseInt(log.old_value) < 0 ? `${parseInt(log.new_value) - parseInt(log.old_value)}%` : `+/-${parseInt(log.new_value) - parseInt(log.old_value)}`"
          />

          <div class="flex items-center gap-2">
            <svn-pro-text body-medium medium color="primary" class="break-all">
              {{ getLogValue(indicator?.indicator_type, log.old_value) }}
            </svn-pro-text>
  
            <div>
              <Icon icon="mingcute:arrow-right-line" height="16" width="16" class="text-primary"/>
            </div>
  
            <svn-pro-text body-medium medium color="primary" class="break-all">
              {{ getLogValue(indicator?.indicator_type, log.new_value) }}
            </svn-pro-text>
          </div>
        </div>

        <div
          v-if="log?.title === 'Title updated' ||
            log?.title === 'Description updated' ||
            log?.title === 'Due date updated'"
          class="flex flex-row items-center rounded-[3px]"
        >
          <svn-pro-info-tag
            tag-style="filled"
            tag-type="info"
            :text="log?.title"
          />
        </div>

        <div
          v-if="log?.state === 'status_updated' ||
            log?.state === 'title_updated' ||
            log?.state === 'description_updated' ||
            log?.state === 'due_date_updated'"
          class="w-full flex flex-col gap-2 items-center"
        >
          <svn-pro-text  body-medium regular color="onSurfaceVariant">
            {{ log?.old_value || 'N/A' }}
          </svn-pro-text>

          <div>
            <Icon icon="mingcute:arrow-down-line" height="24" width="24" class="text-onSurface"/>
          </div>

          <svn-pro-text  body-medium regular>
            {{ log?.new_value }}
          </svn-pro-text>
        </div>

        <div v-else class="flex items-center gap-2">
          <div
            v-if="(log?.comment?.split(' ').filter((word) => { return word !== '' }).length) >= 35"
            class="w-full flex items-end overflow-hidden"
          >
            <svn-pro-text
              regular
              body-medium
              class="relative break-all"
              :class="log.displayed ? '' : 'line-clamp-6'"
            >
              {{ log?.comment }}

              <span
                class="absolute bg-surface pl-1 right-0.5 bottom-[0.5px] text-sm text-primary font-normal underline decoration-solid cursor-pointer w-18"
                @click="log.displayed = !log.displayed"
              >
                {{ log.displayed ? $t('See less') : $t('See all') }}
              </span>
            </svn-pro-text>
          </div>

          <svn-pro-text v-else regular body-medium class="break-all">
            {{ log?.comment }}
          </svn-pro-text>
        </div>
      </div>
    </div>
  </div>

  <!-- If there are no logs -->
  <div v-else class="w-full h-full">
    <svn-pro-empty-states
      :title="$t('Nothing to show for now')"
      :supporting-text="$t('All updates about this target will show up here.')"
    />
  </div>
</template>

<script setup>
import { Icon } from "@iconify/vue";
import { ref } from "vue";
import { useMobileStore } from "@/store/mobile";
import { storeToRefs } from "pinia";
import { usePopUpStore } from "@/store/pop-up";
import i18n from "@/plugins/i18n";
import moment from "moment";

const { openPopUp } = usePopUpStore();
const regex = new RegExp(/^choice_.*/);
const { isMobile } = storeToRefs(useMobileStore());
const props = defineProps(["indicator", "logs", "targetTitle"]);

const indicatorFormatted = ref({
  id: props.indicator.id,
  indicator_type: props.indicator.indicator_type,
  starting_value: props.indicator.options.starting_value,
  target_value: props.indicator.options.target_value,
  current_value: props.indicator.options.current_value,
  status: props.indicator.status,
  multiChoiceList: [],
});

for (const opt in props.indicator.options) {
  if (regex.test(opt))
    indicatorFormatted.value.multiChoiceList.push(props.indicator.options[opt]);
}

const getLogValue = (indicatorType, logValue) => {
  if (indicatorType === 'percentage') {
    return logValue + '%';
  }
  else {
    return logValue;
  }
}

const getStatusBorder = (indicatorType, initialLogValue, updatedLogValue) => {
  if (indicatorType === 'multi_choice') {
    return 'text-darkGrey border border-darkGrey bg-lightGrey';
  }
  else {
    if (parseInt(updatedLogValue) - parseInt(initialLogValue) < 0) {
      return 'text-negativeRed border border-negativeRed bg-transparentNegativeRed';
    }
    else {
      return 'text-positiveGreen border border-positiveGreen bg-transparentPositiveGreen';
    }
  }
}

const getLogStatusText = (indicatorType, initialLogValue, updatedLogValue) => {
  if (indicatorType === 'percentage') {
    return Math.abs(parseInt(updatedLogValue) - parseInt(initialLogValue)) + '%';
  }
  else if (indicatorType === 'numeric_value') {
    return Math.abs(parseInt(updatedLogValue) - parseInt(initialLogValue));
  }
  else if (indicatorType === 'boolean') {
    return i18n.global.t('Switch');
  }
  else {
    return i18n.global.t('Option');
  }
}

const getStatusIcon = (indicatorType, initialLogValue, updatedLogValue) => {
  if (indicatorType === 'percentage' || indicatorType === 'numeric_value') {
    if (parseInt(updatedLogValue) - parseInt(initialLogValue) > 0) {
      return 'mdi-plus';
    }
    else {
      return 'mdi-minus';
    }
  }
  else if (indicatorType === 'boolean') {
    return '';
  }
  else {
    return 'mdi-swap-horizontal';
  }
}

const openPopUpSeeTargetFieldUpdate = (targetField, initialValue, updatedValue) => {
  openPopUp({
    componentName: 'pop-up-see-target-field-update',
    title: '',
    subtitle: '',
    icon: '',
    targetField: targetField,
    initialValue: initialValue,
    updatedValue: updatedValue,
    customClass: 'max-w-[600px] max-h-[600px]',
  })
}
</script>