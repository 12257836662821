<template>
  <svn-pro-data-table
    :headers="data?.length ? headers : []"
    :items="data ?? []"
    :items-per-page="-1"
    :loading="data ? loading : true"
    item-value="id"
    :pagination-total-pages="paginationData?.total_pages"
    @update-pagination="fetchNewPage"
  >
    <template #item="{ item }">
      <tr class="hover:bg-[#46464F14] active:bg-[#46464F1F]/[0.12]" @click="goToTraining(item?.id)">
        <td>
          <svn-pro-data-table-cell
            regular
            body-medium
            cell-type="text"
            :text="item?.title"
          />
        </td>

        <td>
          <my-trainings-completion-indicator
            :acquired="item?.module_completion"
            :total="item?.module_count"
          />
        </td>

        <td>
          {{ $filters.formatDate(item?.last_module_acquired_on?.updated_at) || $t('Not completed') }}
        </td>
      </tr>
    </template>

    <template #no-data>
      <svn-pro-empty-states
        :variant="searchText ? 'results' : 'index'"
        :title="$t('Nothing to show for now')"
        :size="isMdScreen ? 'default' : 'compact'"
        :supporting-text="searchText ? $t('Oops, we didn’t find any results matching your search.') :
          $t(`Hmm, it seems you have no trainings.`)"
      />
    </template>

    <template #loading>
    </template>
  </svn-pro-data-table>
</template>

<script setup>
import { toRefs } from "vue";
import { storeToRefs } from "pinia";
import { useRouter } from "vue-router";
import { useMobileStore } from "@/store/mobile";
import { useLearnTrainingStore } from "@/store/learn-trainings.js";
import MyTrainingsCompletionIndicator from "@/components/trainingApp/MyTrainingsCompletionIndicator.vue";

const props = defineProps({
  user: { type: Object, required: true },
  searchText: {type: String, default: ""},
  headers: { type: Array, required: true },
  data: { type: Array, default: () => [] },
  paginationData: { type: Object, default: () => {} },
})

const emit = defineEmits(['refetch-trainings']);

const { isMdScreen } = storeToRefs(useMobileStore());
const { loading } = storeToRefs(useLearnTrainingStore());

const router = useRouter();
const { searchText } = toRefs(props);
const { setTrainingsByUser } = useLearnTrainingStore();

const goToTraining = (trainingId) => {
  router.push({ name: 'training_show', params: { id: trainingId } })
}

const fetchNewPage = (page) => {
  setTrainingsByUser([])
  emit('refetch-trainings', { userId: props.user.id, text: searchText?.value, page: page })
};
</script>