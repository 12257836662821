<template>
  <div class="w-full flex-1 flex flex-col border border-borderColor rounded-[8px] border-opacity-100 mx-auto lg:!mx-0">
    <div class="w-full flex flex-col md:!flex-row items-start content-start px-6 py-4 gap-4">
      <div class="w-full flex flex-col">
        <svn-pro-title h6 medium>
          {{ $t('Training sets completion - Category') }}
        </svn-pro-title>
  
        <svn-pro-text subtitle-medium regular color="onSurfaceVariant">
          {{ $t('Compare completion by category') }}
        </svn-pro-text>
      </div>

      <svn-pro-select
        v-model="chartCompletionCategorySelected"
        item-title="name"
        item-value="id"
        class="w-full md:!max-w-[190px]"
        :items="tagCategories"
        variant="outlined"
        :label="$t('Category')"
        @update:model-value="changeCompletionCategory"
      />
    </div>

    <svn-pro-divider color="[#767680]" class="border-opacity-100" />

    <div class="flex flex-col p-4 self-stretch">
      <bkt-bar
        :data="completionCategoryData"
        :options="completionCategoryOptions"
      />
    </div>
  </div>
</template>

<script setup>
import { storeToRefs } from "pinia";
import { onMounted, ref } from "vue";
import i18n from "@/plugins/i18n.js";
import BktBar from "@/components/chart/BktBar.vue";
import { useCompanyStore } from "@/store/company.js";
import { useAnalyticTrainingsStore } from "@/store/analytic-trainings.js";

const { getTrainingCompletionAllByCategory } = useAnalyticTrainingsStore();

const { tagCategories } = storeToRefs(useCompanyStore());
const { trainingCompletionAllByCategory } = storeToRefs(useAnalyticTrainingsStore());

const props = defineProps({
  trainingId: { type: String, required: true },
  trainingParticipantsList: { type: Array, required: true },
})

const chartCompletionCategorySelected = ref(null);

const completionCategoryData = ref({
  labels: trainingCompletionAllByCategory?.value ? trainingCompletionAllByCategory?.value.map(tag => tag?.tag_title) : [],
  datasets: [
    {
      label: i18n.global.t('Completed'),
      borderRadius: 4,
      backgroundColor: '#1C6D1E',
      data: trainingCompletionAllByCategory?.value.map(tag => tag?.acquired)
    },
    {
      label: i18n.global.t('In progress'),
      borderRadius: 4,
      backgroundColor: '#CA8100',
      data: trainingCompletionAllByCategory?.value.map(tag => tag?.in_progress)
    },
    {
      label: i18n.global.t('Not started'),
      borderRadius: 4,
      backgroundColor: '#BA1A1A',
      data: trainingCompletionAllByCategory?.value.map(tag => tag?.not_started)
    }
  ]
})

const completionCategoryOptions = {
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    y: {
      stacked: true,
      suggestedMax: props?.trainingParticipantsList?.length + 1,
    },
    x: {
      stacked: true
    },
  },
  plugins: {
    legend: {
      position: "bottom",
      align: "start",
      labels: {
        boxWidth: 21,
        boxHeight: 8,
        color: '#4F4F4F',
        font: {
          weight: 400,
        }
      },
    }
  },
}

const changeCompletionCategory = (value) => {
  chartCompletionCategorySelected.value = value

  getTrainingCompletionAllByCategory(props?.trainingId, value).then(() => {
    completionCategoryData.value = {
      labels: trainingCompletionAllByCategory?.value ? trainingCompletionAllByCategory?.value.map(tag => tag?.tag_title) : [],
      datasets: [
        {
          label: i18n.global.t('Completed'),
          borderRadius: 4,
          backgroundColor: '#1C6D1E',
          data: trainingCompletionAllByCategory?.value.map(tag => tag?.acquired)
        },
        {
          label: i18n.global.t('In progress'),
          borderRadius: 4,
          backgroundColor: '#CA8100',
          data: trainingCompletionAllByCategory?.value.map(tag => tag?.in_progress)
        },
        {
          label: i18n.global.t('Not started'),
          borderRadius: 4,
          backgroundColor: '#BA1A1A',
          data: trainingCompletionAllByCategory?.value.map(tag => tag?.not_started)
        }
      ]
    }
  })
}

onMounted(() => {
  chartCompletionCategorySelected.value = tagCategories.value?.[0].id

  getTrainingCompletionAllByCategory(props?.trainingId, chartCompletionCategorySelected?.value).then(() => {
    completionCategoryData.value = {
      labels: trainingCompletionAllByCategory?.value ? trainingCompletionAllByCategory?.value?.map(tag => tag.tag_title) : [],
      datasets: [
        {
          label: i18n.global.t('Completed'),
          borderRadius: 4,
          backgroundColor: '#1C6D1E',
          data: trainingCompletionAllByCategory?.value?.map(tag => tag?.acquired)
        },
        {
          label: i18n.global.t('In progress'),
          borderRadius: 4,
          backgroundColor: '#CA8100',
          data: trainingCompletionAllByCategory?.value?.map(tag => tag?.in_progress)
        },
        {
          label: i18n.global.t('Not started'),
          borderRadius: 4,
          backgroundColor: '#BA1A1A',
          data: trainingCompletionAllByCategory?.value?.map(tag => tag?.not_started)
        }
      ]
    }
  })
})

</script>