<template>
  <div :class="props.innerClass">
    <Doughnut
      :class="props.chartClass"
      :style="props.chartStyle"
      :data="chartData"
      :options="props.chartOptions"
      :plugins="props.plugins"
    />
  </div>
</template>
<script setup>
import { computed } from "vue";
import { Doughnut } from "vue-chartjs";
import { ArcElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip } from "chart.js";

ChartJS.register(Title, Tooltip, Legend, ArcElement, CategoryScale, LinearScale);

const props = defineProps({
  chartClass: {
    type: String,
    default: null
  },
  chartOptions: {
    type: Object,
    default: () => {
      return {
        cutout: "80%",
        plugins: {
          legend: {
            position: "right",
            align: "center",
            display: false,
            title: {
              display: false,
            },
          },
        },
        elements: {
          arc: {
            borderRadius: 15,
          },
        },
      }
    },
  },
  chartStyle: {
    type: Object,
    default: () => {
      return {
        width: "100%",
        height: "100%",
      }
    }
  },
  plugins: { type: Array, default: null },
  innerClass: {type: String, default: "h-24 w-24 m-auto"},
/**
 * Labels, colors, and datas arrays are linked.
 * First elements of each are the label, the color and the data of the first data to be displayed
 * Other indexes works the same way as the first
 * Exemple: labels: ['Done', 'Not started'], colors: ['green', 'red'], datas: [1, 2]
 * \=> First part of the chart will be green with the 'Done' label and will be 1/3 of the chart
 * \=> Second part of the chart will be green with the 'Not started' label and will be 2/3 of the chart
 * */
  labels: {type: Array, required: true},
  colors: {type: Array, required: true},
  datas: {type: Array, required: true},
})

const chartData = computed(() => {
  return {
    labels: props.labels,
    datasets: [
      {
        backgroundColor: props.colors,
        data: props.datas,
      },
    ],
  }
})
</script>
