<template>
  <div class="w-full flex items-center gap-4">
    <svn-pro-autocomplete
      v-model="campaignDraft.default_template_id"
      :label="$t('Select a template*')"
      :items="interviewForms"
      :error="error"
      item-title="title"
      item-value="id"
      :infiniteScrollLoading="infiniteScrollLoading"
      :class="['flex-1', fullWidth ? '!w-full' : 'sm:!w-1/2']"
      @intersect="loadMoreData"
    />

    <v-spacer :class="['hidden', fullWidth ? '' : 'sm:!flex']" />
  </div>
</template>

<script setup>
import { onMounted, ref } from "vue";
import { storeToRefs } from "pinia";
import { useCampaignDraftStore } from "@/store/campaign-draft.js";
import { interviewFormApi } from '@/apis/interview_form.api';

const props = defineProps({
  fullWidth: { type: Boolean, default: false },
  error: { type: Boolean, default: false },
})


const { campaignDraft } = storeToRefs(useCampaignDraftStore());

const loading = ref(true);
const infiniteScrollLoading = ref(false);
const pagination = ref({})
const interviewForms = ref([])

onMounted(async () => {
  const data = await fetchInterviewForms(1)
  interviewForms.value = data.interview_app_forms
  pagination.value = data.meta.pagination
  loading.value = false;
});

const fetchInterviewForms = async(page = 1) => {
  let formType = ''
  if (campaignDraft?.value?.campaign_type == 'Survey') {
    formType = 'InterviewApp::Forms::Templates::Survey'
  } else if (campaignDraft?.value?.campaign_type == 'Feedback') {
    formType = 'InterviewApp::Forms::Templates::Feedback'
  } else {
    formType = 'InterviewApp::Forms::Templates::OneToOne'
  }
  return await interviewFormApi.fetch(formType, page);
}

const loadMoreData = async() => {
  if (pagination.value?.current_page < pagination.value?.total_pages) {
    infiniteScrollLoading.value = true
    pagination.value.current_page = pagination.value.current_page + 1
    const data = await fetchInterviewForms(pagination.value.current_page)
    interviewForms.value = [...interviewForms.value, ...data.interview_app_forms]
    pagination.value = data.meta.pagination
    infiniteScrollLoading.value = false;
  }
}

</script>