<template>
  <div
    v-if="toasts.length"
    class="fixed z-[9999] w-fit flex justify-end top-0 right-0 flex-col"
    data-testid="container"
  >
    <v-slide-x-reverse-transition
      v-for="(toast, index) in toasts.slice(0, ITEMS_LENGTH)"
      :key="index"
      @after-leave="afterTransitionLeave(index)"
    >
      <svn-pro-card
        v-show="show[index]"
        :link="false"
        elevation="3"
        :rounded="'xs'"
        color="onSurface"
        class="relative mt-4 mx-4 md:!mx-0 md:!mr-4"
        :data-testid="`card-${index}`"
      >
        <div
          class="max-w-[475px] h-full inline-flex pl-6 pr-4 py-4 items-start bg-onSurface"
          :class="toast?.progress ? 'gap-8' : 'gap-6'"
        >
          <div class="flex flex-col items-start gap-4">
            <div class="flex flex-col gap-1 items-start">
              <div class="flex gap-1 items-center">
                <div v-if="toast?.notificationType !== 'badge'">
                  <Icon
                    :icon="ICONS?.[toast?.type]"
                    height="20"
                    width="20"
                    class="text-onPrimary"
                  />
                </div>

                <!-- Badge -->
                <v-img
                  v-else
                  class="w-10 h-10"
                  cover
                  :src="BADGES?.[toast?.badge]"
                />

                <!-- Toast title -->
                <svn-pro-text v-if="toast?.title" subtitle-large bold color="onPrimary">
                  {{ toast?.title }}
                </svn-pro-text>
              </div>

              <!-- Toast description -->
              <svn-pro-text v-if="toast?.body" body-medium regular color="onPrimary">
                <span v-html="toast?.body"/>
              </svn-pro-text>
            </div>

            <!-- Toast Action -->
            <svn-pro-button
              v-if="toast?.action"
              color="surface"
              variant="elevated"
              :text="toast?.action?.name"
              @click="clickAction(index)"
            />
          </div>

          <!-- Close toast -->
          <svn-pro-icon-button
            v-if="toast?.withClose"
            variant="text"
            color="onPrimary"
            icon="custom:mingcute:close-line"
            @click="removeToast"
          />
        </div>

        <!-- Progress indicator -->
        <svn-pro-progress-linear
          v-if="toast?.progress"
          color="onPrimary"
          bg-color="transparent"
          indeterminate
          class="-mt-1"
        />
      </svn-pro-card>
    </v-slide-x-reverse-transition>
  </div>
</template>

<script setup>
import { Icon } from "@iconify/vue";
import { onBeforeUnmount, ref, watch } from "vue"
import { useToastStore } from "@/store/toast.js";
import { storeToRefs } from "pinia";
import router from "@/router/index.js";
import { useMobileStore } from "@/store/mobile.js";
import { useActionCable } from "@/store/cable.js";
import { useUserStore } from "@/store/user.js";
import BadgeHeart from '@/assets/images/badges/badge_heart.svg';
import BadgeThumb from '@/assets/images/badges/badge_thumb.svg';
import BadgeBook from '@/assets/images/badges/badge_book.svg';
import BadgePuzzle from '@/assets/images/badges/badge_puzzle.svg';

const { isMobile } = storeToRefs(useMobileStore());

// Constants
const ICONS = {
  'info': 'mingcute:information-line',
  'success': 'mingcute:check-circle-fill',
  'warning': 'mingcute:alert-fill',
  'error': 'mingcute:close-circle-fill',
}

const COLORS = {
  'info': 'fakeBlack',
  'success': 'positiveGreen',
  'warning': 'mediumOrange',
  'error': 'negativeRed',
}

const BADGES = {
  'badge_heart': BadgeHeart,
  'badge_thumb': BadgeThumb,
  'badge_book': BadgeBook,
  'badge_puzzle': BadgePuzzle,
}

const ITEMS_LENGTH = isMobile.value ? 1 : 3

// Refs
const { toasts, forceChange } = storeToRefs(useToastStore())
const show = ref([true, true, true])
const transition = ref(false)

// Functions
const { removeToast } = useToastStore()

const clickClose = (idx) => {
  show.value[idx] = false
}

const clickAction = (idx) => {
  if (!toasts.value[idx].action.link)
    return
  if (typeof toasts.value[idx].action.link === 'object') {
    toasts.value[idx].action.link.click()
    URL.revokeObjectURL(toasts.value[idx].action.link)
  } else {
    router.push(toasts.value[idx].action.link).then(() => {
      router.go(0)
    })
  }

  toasts.value.splice(idx, 1)
}

const afterTransitionLeave = (idx) => {
  show.value[idx] = true
  removeToast(idx)
}

watch(toasts, (newVal, _) => {
  if (newVal.length === 0)
    return
  for (let i = 0; i < ITEMS_LENGTH ; i++) {
    if (!show.value[i]) {
      show.value[i] = true
    }
  }
})

watch(forceChange, (newVal, _) => {
  for (let i = 0; i < ITEMS_LENGTH ; i++) {
    if (newVal[i]) {
      clickClose(i)
      forceChange.value[i] = false
    }
  }
}, { deep: true })
</script>