export default function () {
  return {
    alphabetFromIndex(index: number): string {
      const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
      if (index >= 0 && index < alphabet.length) {
        return alphabet[index];
      } else {
        return '-'; // Return null if the index is out of bounds
      }
    },
  };
}
