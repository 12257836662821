<template>
  <div class="w-screen h-screen flex items-center justify-center px-5 md:!px-12 py-16 lg:!px-0 lg:!py-0 overflow-y-auto">
    <div class="w-full max-w-[328px] flex h-[608px] md:h-[718px] lg:h-[536px] xl:max-w-[472px] xl:h-[592px] desktop:h-[712px] flex-col relative justify-center items-center mx-auto">
      <div class="flex flex-col items-start gap-4 self-stretch">
        <div class="flex flex-col items-start gap-6 self-stretch">
          <!-- Welcome text -->
          <div class="w-full flex flex-col gap-2 items-start">
            <svn-pro-title :h5="!isMobile" :h6="isMobile" medium color="onSurface">
              {{ $t("Oops, it seems this email is not registered 🧐") }}
            </svn-pro-title>

            <svn-pro-text body-medium regular color="onSurface">
              {{ $t("You may have miswritten your email, please try again.") }}
            </svn-pro-text>
          </div>

          <!-- Email input -->
          <svn-pro-text-field
            v-model="email"
            :label="$t('Email')"
            error
            type="text"
            class="w-full"
            variant="outlined"
          />

          <svn-pro-button
            :text="$t('Try again')"
            prepend-icon="custom:mingcute:arrow-left-line"
            variant="flat"
            block
            @click="backToSignIn"
          />
        </div>
      </div>

      <!-- Aleph Logo -->
      <div class="w-full flex flex-col items-start absolute top-0">
        <div
          class="flex justify-center items-center bg-cover bg-center overflow-hidden w-[120px] h-[34.5px]"
          :style="{'background-image': `url('${logo}')`}"
        />
      </div>
    </div>

    <div class="w-1/2 h-screen  hidden xl:flex">
      <div
        class="flex justify-center items-center bg-cover bg-center overflow-hidden w-full h-full"
        :style="{'background-image': `url('${bgHome}')`}"
      />
    </div>
  </div>
</template>

<script setup>
import { storeToRefs } from "pinia";
import { useRouter } from 'vue-router';
import bgHome from "@/assets/bghome.png";
import { useUserStore } from "@/store/user.js";
import logo from "@/assets/logo_aleph_white.svg";
import { useMobileStore } from '@/store/mobile.js';

const router = useRouter()

const { email } = storeToRefs(useUserStore());
const { isMobile, isMdScreen } = storeToRefs(useMobileStore());

const backToSignIn = () => {
  router.back()
}
</script>

<style scoped>
.v-text-field :deep(.v-input__details) {
  padding-inline: 0px;
}

:deep(.v-field) {
  border-radius: 8px!important;
}
</style>
