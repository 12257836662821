<template>
  <div class="w-full h-full max-w-[368px] flex flex-col gap-[19px] p-6 rounded-[8px] border border-borderColor border-opacity-100">
    <div class="flex flex-col">
      <svn-pro-title h6 medium>
        {{ $t(completionAllText) }}
      </svn-pro-title>

      <svn-pro-text subtitle-medium regular color="onSurfaceVariant">
        {{ $t('Quick view of your campaign completion') }}
      </svn-pro-text>
    </div>

    <v-divider class="border-[#767680] border-opacity-100" />

    <BktDoughnut
      :inner-class="'w-full'"
      :chart-class="'w-full'"
      :datas="completionAllData"
      :labels="[$t('Submitted'), $t('In progress'), $t('Not started')]"
      :colors="chartColors"
      :chart-options="chartCompletionAllOptions"
    />
  </div>
</template>

<script setup>
import { computed } from "vue";
import BktDoughnut from "@/components/chart/BktDoughnut.vue";
import { CampaignTypeBackend } from "@/constants/CampaignTypeEnum.js";

const props = defineProps({
  loading: { type: Boolean, default: false },
  campaign: { type: Object, default: () => {} },
  chartColors: { type: Array, default: () => [] },
  completionAllData: { type: Array, default: () => [] },
  chartCompletionAllOptions: { type: Object, default: () => {} },
})

const completionAllText = computed(() => {
  switch (props?.campaign?.campaign_type) {
    case CampaignTypeBackend.ONE_TO_ONE:
      return "Interview sets completion - All"
    case CampaignTypeBackend.FEEDBACK_360:
      return "Feedback completion - All"
    case CampaignTypeBackend.SURVEY:
      return "Survey completion - All"
    default:
      return ''
  }
})
</script>