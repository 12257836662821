<template>
  <div class="w-full h-fit flex flex-col gap-4">
    <v-tabs
      grow
      v-model="tab"
      color="primary"
      align-tabs="center"
    >
      <v-tab
        v-for="(detail, index) in details"
        :key="index"
        :value="detail?.type"
        :text="$t(detail?.type) + ` (${detail?.changes?.length || 0})`"
        :prepend-icon="getTabIcon(detail?.type)"
      />
    </v-tabs>

    <v-window v-model="tab">
      <v-window-item
        v-for="(detail, index) in details"
        :key="index"
        :value="detail?.type"
      >
        <div v-if="detail?.changes?.length" class="w-full h-full sm:h-[450px] flex flex-col items-start overflow-y-auto">
          <v-list class="w-full">
            <v-list-item
              v-for="(change, index) in detail?.changes"
              :key="index"
              :active="false"
            >
              <div class="flex items-center gap-4">
                <div>
                  <Icon
                    :icon="getDetailIcon(change?.key)"
                    height="24"
                    width="24"
                    class="text-onSurfaceVariant"
                  />
                </div>

                <svn-pro-text subtitle-large medium>
                  {{ change?.fullname || change?.name }}
                </svn-pro-text>
              </div>
            </v-list-item>
          </v-list>
        </div>

        <div v-else class="w-full h-[216px] md:!h-[450px] flex-1 flex flex-col justify-center items-center px-6 py-4 gap-2 overflow-y-auto">
          <div>
            <Icon
              icon="noto:winking-face"
              height="32"
              width="32"
            />
          </div>

          <svn-pro-text body-medium regular>
            {{ detail.type == 'created' ?
              'No new content added during import.' : detail.type == 'updated' ?
              'No content modified during import.' : detail.type == 'deleted' ?
              'No content deleted during import.' :  ''
            }}
          </svn-pro-text>
        </div>
      </v-window-item>
    </v-window>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import { Icon } from '@iconify/vue';

const props = defineProps({
  details: { type: Object, default: () => {} }
})

const tab = ref(null)

const getDetailIcon = (key) => {
  if (key === 'user') {
    return 'mingcute:user-4-line'
  }
  else if (key === 'tag_category') {
    return 'mingcute:folder-open-line'
  }
  else if (key === 'tag') {
    return 'mingcute:tag-line'
  }
}

const getTabIcon = (type) => {
  if (type === 'created') {
    return 'mingcute:add-line'
  }
  else if (type === 'updated') {
    return 'mingcute:refresh-3-line'
  }
  else if (type === 'deleted') {
    return 'mingcute:delete-2-line'
  }
}
</script>
