<template>
  <div
    v-if="user.id"
    class="w-full flex flex-col max-w-[928px] md:!px-12 xl:!max-w-[1136px] mx-auto items-start gap-5 bg-surface"
  >
    <div />

    <svn-pro-profile
      class="px-5 md:!px-0"
      :firstname="user?.firstname"
      :lastname="user?.lastname"
      :email="user?.email"
      :avatar="user?.avatar?.['500']"
      :size="isMobile ? 'compact' : 'default'"
      :tags="userTags"
      :picture-editable="(storeUser?.access_level === 'admin' || storeUser?.access_level === 'super_admin' || storeUser?.id === parseInt(route?.params?.id))"
      :badge-types="badges"
      :showBadges="applications?.includes('trainings')"
      @edit-picture="openEditPictureModal"
      @open-badges-modal="openModalUserBadges"
    >
      <template #settings>
        <svn-pro-menu>
          <template #activator>
            <svn-pro-button
              text="Settings"
              variant="outlined"
              prepend-icon="custom:mingcute:settings-3-line"
            />
          </template>
  
          <template #dropdown>
            <svn-pro-list-item
              start
              value="details"
              leading="icon"
              :active="false"
              leading-icon="mingcute:user-4-line"
              leading-color="onSurfaceVariant"
              :title="$t('Profile details')"
              @click="openModalDetails"
            />

            <svn-pro-modal ref="modalSettings" :title="$t('Settings')" width="480">
              <template #activator="{ props }">
                <svn-pro-list-item
                  v-bind="props"
                  start
                  value="details"
                  leading="icon"
                  :active="false"
                  leading-icon="mingcute:settings-6-line"
                  leading-color="onSurfaceVariant"
                  :title="$t('Settings')"
                />
              </template>

              <template #text>
                <div class="flex flex-col gap-6">
                  <div class="flex flex-col gap-4">
                    <svn-pro-text subtitle-large medium>
                      {{ $t('Aleph Language') }}
                    </svn-pro-text>

                    <svn-pro-select
                      v-model="language"
                      :label="$t('Language')"
                      :items="languages"
                      @update:model-value="changeLanguage"
                    />
                  </div>

                  <div class="flex flex-col items-start gap-4">
                    <svn-pro-text subtitle-large medium>
                      {{ $t('Password') }}
                    </svn-pro-text>

                    <svn-pro-button
                      :text="$t('Change password')"
                      @click="openChangePasswordModal"
                    />
                  </div>
                </div>
              </template>
            </svn-pro-modal>
  
          </template>
        </svn-pro-menu>
      </template>
    </svn-pro-profile>

    <div class="w-full flex flex-col items-start gap-3">
      <div class="w-full flex px-5 md:!px-0 items-start">
        <svn-pro-title h5 medium>
          {{ $t('Dashboard') }}
        </svn-pro-title>
      </div>

      <v-tabs
        v-if="applications?.includes('interviews') && applications?.length > 1"
        v-model="tab"
        color="primary"
        class="w-full md:!w-fit"
      >
        <v-tab
          value="interviews"
          :prepend-icon="isMdScreen ? 'custom:mingcute:chat-3-line' : undefined"
          :text="$t('Interviews')"
          class="w-[140px] md:!w-[180px]"
        />

        <v-tab
          v-if="applications?.includes('trainings')"
          value="learnings"
          :prepend-icon="isMdScreen ? 'custom:mingcute:mortarboard-line' : undefined"
          :text="$t('Learnings')"
          class="w-[140px] md:!w-[180px]"
        />

        <v-tab
          v-if="applications?.includes('objectives')"
          value="targets"
          :prepend-icon="isMdScreen ? 'custom:mingcute:target-line' : undefined"
          :text="$t('Targets')"
          class="w-[140px] md:!w-[180px]"
        />
      </v-tabs>

      <div class="w-full flex flex-col max-w-[928px] xl:!max-w-[1136px] mx-auto px-5 md:!px-0">        
        <v-window v-model="tab" class="pt-3">
          <v-window-item
            value="interviews"
            v-if="applications?.includes('interviews')"
          >
            <div class="w-full h-full flex flex-col gap-6">
              <bkt-interviews-in-profile :user="user"/>
            </div>
          </v-window-item>

          <v-window-item
            value="learnings"
            v-if="applications?.includes('trainings')"
          >
            <div class="w-full h-full flex flex-col gap-6">
              <bkt-learnings-in-profile :user="user"/>
            </div>
          </v-window-item>

          <v-window-item
            value="targets"
            v-if="applications?.includes('objectives')"
          >
            <div class="w-full h-full flex flex-col gap-6">
              <bkt-targets-in-profile :user="user"/>
            </div>
          </v-window-item>
        </v-window>
      </div>
    </div>

    <modal-user-badges ref="modalUserBadges" :user-badges="user.badges"/>

    <modal-user-settings ref="modalDetails" :user="user" :update-user-profile="updateUserProfile" />

    <!-- Modal edit picture -->
    <svn-pro-modal
      ref="editPicture"
      width="480"
      :title="$t('Edit photo')"
    >
      <template #activator>
        {{ null }}
      </template>

      <template #text>
        <div class="w-full flex flex-col gap-8 items-center">
          <div
            class="w-full h-[256px] flex flex-col p-4 gap-4 justify-center items-center border border-dashed rounded-[8px]"
            :class="dragActive ? 'bg-surfaceLight border-onSurfaceVariant' : 'bg-surface border-borderColor'"
            @drop.prevent="onDrop"
            @dragenter.prevent="setActiveDrag"
            @dragover.prevent="setActiveDrag"
            @dragleave.prevent="setInactiveDrag"
          >
            <div v-if="!dragActive">
              <Icon icon="mingcute:upload-2-line" height="24" width="24" class="text-onSurfaceVariant" />
            </div>
            
            <svn-pro-text body-medium regular class="text-center w-fit max-w-[400px]">
              {{ dragActive ? $t('Drop the file here') : $t('Drag & drop here or upload a file. Only .jpg, .png and .jpeg file formats are supported, max 10 Mb.') }}
            </svn-pro-text>

            <svn-pro-button
              v-if="!dragActive"
              :text="$t('Upload a file')"
              @click="openFilePicker"
            />
          </div>

          <svn-pro-button
            variant="text"
            class="w-fit"
            :disabled="!user?.avatar"
            :loading="removePictureLoading"
            prepend-icon="custom:mingcute:delete-2-line"
            :text="$t('Remove current photo')"
            @click="deletePicture"
          />
        </div>
      </template>
    </svn-pro-modal>

    <!-- Modal change password -->
    <modal-set-another-password
      ref="modalChangePassword"
      @change-password="changePassword"
    />  
  </div>

  <!-- Hidden File Picker -->
  <input
    id="file_picker"
    class="w-0 h-0 overflow-hidden opacity-0"
    type="file"
    accept=".jpg,.jpeg,.png,.webp,.gif"
    @change="onFileChange"
  />
</template>

<script setup>
import { onMounted, ref, watch, computed } from "vue";
import axios from "../../tools/axios-service.js";
import { useUserStore } from "@/store/user.js";
import { useMobileStore } from "@/store/mobile";
import { useSnackbar } from "@/store/snackbar.js";
import BktInterviewsInProfile from "@/components/BktInterviewsInProfile.vue";
import BktLearningsInProfile from "@/components/BktLearningsInProfile.vue";
import BktTargetsInProfile from "@/components/BktTargetsInProfile.vue";
import caseStyles from "@/tools/caseStyles.js";
import { useCompanyStore } from "@/store/company.js";
import { Icon } from "@iconify/vue";
import { storeToRefs } from "pinia";
import i18n from "@/plugins/i18n";
import { useRoute, useRouter } from "vue-router";
import ModalUserSettings from "@/components/BktPopUp/Modals/ModalUserSettings.vue";
import ModalUserBadges from "@/components/BktPopUp/Modals/ModalUserBadges.vue";
import ModalSetAnotherPassword from "../../components/BktPopUp/Modals/ModalSetAnotherPassword.vue";

onMounted(async () => {
  page.value = route?.query?.page
  await updateUserProfile()
  language.value = storeUser?.value?.lang
  userTags.value = user?.value?.tags?.map(tag => tag.tagName)
});

const { applications } = storeToRefs(useCompanyStore());
const { user: storeUser } = storeToRefs(useUserStore());
const { isMobile, isMdScreen } = storeToRefs(useMobileStore());

const route = useRoute();
const router = useRouter();
const snackbar = useSnackbar();
const { getUserById } = useUserStore();

const page = ref("interviews")
const dragActive = ref(false)
const draggedFile = ref(null)
const dragTimeout = ref(null)
const editPicture = ref(null)
const modalUserBadges = ref(null);
const modalDetails = ref(null);
const modalSettings = ref(null);
const modalChangePassword = ref(null);
const userTags = ref([]);
const language = ref(null);
const removePictureLoading = ref(false)
const tab = ref(null);
const user = ref({
  id: null,
  accessLevel: null,
  accessLevelPreset: null,
  firstname: null,
  lastname: null,
  email: null,
  manager: null,
  birthdate: null,
  hiredate: null,
  gender: null,
  avatar: null,
  avatars: null,
  lang: null,
  tags: [],
  jobTitle: null,
  managerId: null,
  timezone: null,
  badges: {} // On n'a pas mis null sinon ça plante
});
const languages = ref([
  {
    value: "en",
    title: "English",
  },
  {
    value: "fr",
    title: "Français",
  },
])
const badges = computed(() => {
  return [
    {
      name: "trainings",
      count: storeUser?.value?.completed_learn_training_count,
    },
    {
      name: "modules",
      count: storeUser?.value?.completed_learn_modules_count,
    },
    {
      name: "likes",
      count: storeUser?.value?.favorite_learn_module_count,
    },
    {
      name: "reactions",
      count: storeUser?.value?.reaction_learn_module_count,
    }
  ]
})

const updateUserProfile = async () => {
  await getUserById(route.params.id).then((res) => {
    user.value = caseStyles.convertKeysToCamel(res);
    user.value.lang = storeUser?.value?.lang
    user.value.badges = {
      acquireModuleCount: storeUser?.value?.completed_learn_modules_count,
      reactModuleCount: storeUser?.value?.reaction_learn_module_count,
      completeTrainingCount: storeUser?.value?.completed_learn_training_count,
      favoriteModuleCount: storeUser?.value?.favorite_learn_module_count,
    }
    userTags.value = user?.value?.tags?.map(tag => tag.tagName)
    i18n.global.locale.value = user.value.lang;
  });
};

const openEditPictureModal = () => {
  editPicture.value.dialog = true
}

const onFileChange = (e) => {
  const { files } = e.target;

  if (files && files[0]) {
    uploadAvatar(files[0])
  }
}

const openFilePicker = () => {
  let element = document.getElementById('file_picker')
  if (element) {
    element.click()
  }
}

const onDrop = (e) => {
  draggedFile.value = e.dataTransfer.files?.[0]

  if (draggedFile.value) {
    if (new Set(["image/png", "image/jpg", "image/jpeg"]).has(draggedFile.value?.type)) {
      uploadAvatar(draggedFile?.value)
    }
    else {
      snackbar.setBgColor("error").displaySnackBar("Error uploading profile picture, please try again.");
    }
  }
  setInactiveDrag()
  draggedFile.value = null
}

const setActiveDrag = () => {
  dragActive.value = true
  clearTimeout(dragTimeout.value)
}

const setInactiveDrag = () => {
  dragActive.value = false
  dragTimeout.value = setTimeout(() => {
    dragActive.value = false
  }, 250)
}

const uploadAvatar = async (data) => {
  try {
    user.value.uploadedAvatar = URL.createObjectURL(data);
    const formData = new FormData();
    if (user.value.uploadedAvatar) formData.append("user[avatar]", data);
    const headers = { "Content-Type": "multipart/form-data" };
    editPicture.value.dialog = false
    
    await axios.patch(`/api/v1/users/${user.value.id}`, formData, { headers });
    user.value.avatar = user.value.uploadedAvatar
    userTags.value = user?.value?.tags?.map(tag => tag.tagName)

    snackbar.setBgColor("onSurface").displaySnackBar("Profile picture has been updated.");
  } catch (e) {
    snackbar.setBgColor("error").displaySnackBar("Error uploading profile picture, please try again.");
  }
}

const deletePicture = async () => {
  removePictureLoading.value = true
  await axios.patch(`/api/v1/users/${user.value.id}`, {
    user: { remove_avatar: true }
  });
  await updateUserProfile();

  removePictureLoading.value = false
  userTags.value = user?.value?.tags?.map(tag => tag.tagName)
  editPicture.value.dialog = false

  snackbar.setBgColor("onSurface").displaySnackBar("Profile picture has been removed.");
}

const openModalDetails = () => {
  modalDetails.value.modalSettings.dialog = true;
}

const openModalUserBadges = () => {
  modalUserBadges.value.modalUserBadges.dialog = true;
}

const openChangePasswordModal = () => {
  modalSettings.value.dialog = false
  modalChangePassword.value.dialogRef.dialog = true
}

const changeLanguage = async () => {
  const editUser = {};
  editUser.lang = language?.value;
  try {
    await axios.patch(`/api/v1/users/${route?.params?.id}`, {
      user: editUser,
    });
    router.go(0)
    snackbar.setBgColor('onSurface').setMsg('Language has been updated.').setCustomClass('mb-5').displaySnackBar()
  } catch (error) {
    snackbar.setBgColor('error').setMsg('Error ! Language has not been updated').setCustomClass('mb-5').displaySnackBar()
  }
}

const changePassword = async (password) => {
  const editUser = {};
  editUser.password = password;
  try {
    await axios.patch(`/api/v1/users/${route?.params?.id}`, {
      user: editUser,
    });
    modalChangePassword.value.dialogRef.dialog = false
    snackbar.setBgColor('onSurface').setMsg('Password has been updated.').setCustomClass('mb-5').displaySnackBar()
  } catch (error) {
    snackbar.setBgColor('error').setMsg('Error ! Your password has not been updated').setCustomClass('mb-5').displaySnackBar()
  }
}

watch(page, (newVal, _) => {
  const id = user.value?.id

  router.push({
    name: 'people_show',
    params: { id },
    query: { page: newVal }
  })
})

watch(route, () => {
  if (route.name == 'people_show') {
    page.value = route?.query?.page
  }
})

watch(storeUser, (newVal, _) => {
  if (newVal) {
    userTags.value = newVal?.tags?.map(tag => tag.tagName)
  }
})
</script>

<style scoped>
.v-slide-group, .v-slide-group__container {
  width: 100%;
}

:deep(.v-slide-group__content) {
  justify-content: space-between;
}

:deep(.v-field--variant-filled .v-field__overlay) {
  background-color: transparent;
}

:deep(.v-slide-group__prev),
:deep(.v-slide-group__next) {
  display: none;
}

</style>
