<template>
  <svn-pro-button
    :variant="variant"
    :text="$t(title)"
    :loading="loading"
    color="primary"
    :prepend-icon="prependIcon"
  />
</template>

<script setup>
import { storeToRefs } from "pinia";
import { useMobileStore } from "@/store/mobile";

const { isMobile } = storeToRefs(useMobileStore());

const props = defineProps({
  variant: { type: String, default: "flat" },
  title: { type: String, default: "Submit" },
  loading: { type: Boolean, default: false },
  disabled: { type: Boolean, default: false },
  prependIcon: { type: String, default: "custom:mingcute:send-line" },
});
</script>