import { nextTick, ref } from 'vue';

export function useFormErrorScroll() {
  const fieldRefs = ref([]);

  /**
   * Scrolls to the first field that has an error.
   * @param {Array} errorStates - An array representing the states of the fields (e.g., 'error' or 'default').
   * @param {Array} additionalRefs - An optional array of additional refs to check for scrolling (e.g., expertRef).
   */

  const scrollToFirstError = async (errorStates, additionalRefs = []) => {
    await nextTick();

    for (let i = 0; i < fieldRefs.value.length; i++) {
      const fieldRef = fieldRefs.value[i];

      if (errorStates[i] === 'error' && fieldRef?.$el) {
        fieldRef.$el.scrollIntoView({ behavior: 'smooth', block: 'center' });
        return;
      }
    }

    for (let ref of additionalRefs) {
      if (ref?.$el) {
        ref.$el.scrollIntoView({ behavior: 'smooth', block: 'center' });
        return;
      }
    }
  };

  return {
    fieldRefs,
    scrollToFirstError,
  };
}
