<template>
  <div class="w-full flex justify-center items-center">
    <v-divider class="border-opacity-100 w-1/2 border-[#767680]" />

    <svn-pro-button
      color="primary"
      variant="text"
      :text="$t('Add')"
      :prepend-icon="'custom:mingcute:add-line'"
    />

    <v-divider class="border-opacity-100 w-1/2 border-[#767680]" />
  </div>
</template>

<script setup>

</script>
