<template>
  <div
    v-if="feedback"
    class="w-full flex flex-col gap-6 p-4 lg:!p-6 rounded-[12px] border border-borderColor border-opacity-100"
  >
    <MyInterviewCardHeader
      :campaign-deadline="campaign.deadline"
      :campaign-type="campaign.campaign_type"
      :campaign-name="campaign.title"
    />

    <div
      v-if="'interview_reviewers' in feedback"
      class="flex flex-col gap-8"
    >
      <template v-if="feedback.interview_reviewers.length === 0 && canManageReviewers || feedback.interview_reviewers.length === 1 && feedback.interview_reviewers?.[0].employee === null && canManageReviewers">
        <svn-pro-button
          class="w-full lg:!w-fit lg:!self-end"
          variant="flat"
          :text="$t('Choose reviewers')"
          @click="managePairs"
          prepend-icon="custom:mingcute:add-line"
        />
      </template>

      <div
        v-else-if="feedback.interview_reviewers.length > 0"
        class="w-full flex flex-col gap-4 items-start"
      >
        <div class="flex gap-2 items-center">
          <svn-pro-avatar
            :size="40"
            :firstname="feedback?.participant?.firstname"
            :image="feedback?.participant?.avatar?.['50']"
            :type="feedback?.participant?.avatar?.['50'] ? 'photo' : 'monogram'"
          />

          <div class="flex flex-col">
            <svn-pro-text subtitle-large medium class="line-clamp-1">
              {{ feedback?.participant?.fullname }}
            </svn-pro-text>

            <svn-pro-text body-medium medium color="onSurfaceVariant" class="line-clamp-1 break-all">
              {{ feedback?.participant?.job_title }}
            </svn-pro-text>
          </div>
        </div>

        <div class="w-full h-fit flex flex-col gap-3 items-center lg:!flex-row lg:!justify-end">
          <pop-up-manage-pairs
            v-if="canManageReviewers"
            :participant-id="feedback?.participant?.id"
            :feedback-id="feedback?.id"
            :feedback="feedback"
            :campaign-id="campaign?.id"
            :can-auto-evaluation="canAutoEvaluation"
            :feedback-interviews="feedback?.interview_reviewers"
          >
            <template #activator="{ props }">
              <svn-pro-button
                v-bind="props"
                class="w-full lg:!w-fit"
                variant="outlined"
                :text="$t('Manage reviewers')"
                prepend-icon="custom:mingcute:user-add-2-line"
              />
            </template>
          </pop-up-manage-pairs>
  
          <svn-pro-button
            v-if="canConsultAnswers"
            class="w-full lg:!w-fit"
            variant="outlined"
            :disabled="feedback.interview_reviewers.filter(interview => interview.status === 'submitted').length === 0"
            :text="$t('Consult answers') + ` (${feedback.interview_reviewers.filter(interview => interview.status === 'submitted').length})`"
            @click="consultAnswers"
          />
  
          <svn-pro-button
            v-if="selfReview"
            class="w-full lg:!w-fit"
            :prepend-icon="selfReview?.status !== 'submitted' ? 'custom:mingcute:arrow-right-line' : undefined"
            :variant="selfReview?.status !== 'submitted' ? 'flat' : 'outlined'"
            :text="selfAnswerButtonText()"
            @click="selfEvaluation()"
          />
        </div>
      </div>

      <div class="w-full flex flex-col md!:flex-row lg:!gap-3">
        <svn-pro-text v-if="feedback.interview_reviewers.length === 0 && !canManageReviewers" subtitle-medium regular>
          {{ $t('No reviewer for the moment') }}
        </svn-pro-text>

        <svn-pro-text v-else-if="feedback.interview_reviewers.length === 0 && canManageReviewers || feedback.interview_reviewers.length === 1 && feedback.interview_reviewers?.[0].employee === null && canManageReviewers">
          {{ $t('Choose reviewers to continue') }}
        </svn-pro-text>

        <div
          v-else
          class="flex flex-wrap gap-3"
          data-testid="reviewers"
        >
          <my-interview-feedback-card-people
            v-for="(reviewer, index) in feedback.interview_reviewers.filter(inter => inter.employee !== null)"
            :key="index"
            :status="reviewer.status"
            :user="reviewer.employee"
          />
        </div>
      </div>
    </div>

    <template v-else>
      <div
        v-for="(feedback_participant, index) in feedback?.feedback_participants"
        :key="index"
        class="flex flex-col gap-6"
      >
        <div class="w-full flex flex-col gap-4 items-start lg:!items-center lg:!flex-row lg:!justify-between">
          <my-interview-feedback-card-people
            :user="feedback_participant?.participant"
            :is-participant="true"
          />
  
          <svn-pro-button
            class="w-full lg:!w-fit"
            :variant="feedback_participant?.interview_employee?.status === 'submitted' ? 'outlined' : 'flat'"
            :text="feedbackAnswerButtonText(feedback_participant?.interview_employee?.status, feedback_participant?.participant)"
            :prepend-icon="feedback_participant?.interview_employee?.status !== 'submitted' ? 'custom:mingcute:arrow-right-line' : undefined"
            @click="giveFeedback(feedback_participant?.interview_employee?.id)"
          />
        </div>
        
        <v-divider v-if="index !== feedback?.feedback_participants?.length - 1" class="border-opacity-100 border-[#767680]" />
      </div>
    </template>
  </div>
</template>

<script setup>
import { computed } from "vue";
import i18n from "@/plugins/i18n";
import { useI18n } from "vue-i18n";
import { storeToRefs } from "pinia";
import router from "@/router/index.js";
import { useUserStore } from "@/store/user.js";
import { usePopUpStore } from "@/store/pop-up.js";
import { useMobileStore } from "@/store/mobile.js";
import PopUpManagePairs from "../../popUpComponents/PopUpManagePairs.vue";
import MyInterviewCardHeader from "@/components/interviewApp/InterviewCardHeader.vue";
import MyInterviewFeedbackCardPeople from "@/components/interviewApp/MyInterview/MyInterviewFeedbackCardPeople.vue";

const props = defineProps({
  id: { type: Number, required: true },
  campaign: { type: Object, required: true },
  feedback: { type: Object, required: true },
  canManageReviewers: { type: Boolean, required: true },
  canConsultAnswers: { type: Boolean, required: true },
  canAutoEvaluation: { type: Boolean, required: true },
})

const { id } = storeToRefs(useUserStore());
const { isMobile } = storeToRefs(useMobileStore());

const { t } = useI18n();
const { openPopUp } = usePopUpStore();

// little trick to mix interviews and feedbacks
const selfReview = computed(() =>  props.feedback?.interview_reviewers?.find(
  review => review.employee?.id === props.feedback.participant.id || review.label === "PairsShadow"
))

const managePairs = () => {
  openPopUp({
    componentName: "PopUpManagePairs",
    title: i18n.global.t('Manage reviewers'),
    participantId: props.feedback.participant.id,
    feedbackId: props.feedback.id,
    feedback: props.feedback,
    canAutoEvaluation: props?.canAutoEvaluation,
    campaignId: props.campaign.id,
    icon: '',
    feedbackInterviews: props.feedback.interview_reviewers,
    customClass: "h-[573px] lg:w-[800px] !justify-normal",
    close() {},
  });
};

const consultAnswers = async () => {
  await router.push({name: 'feedback_answer', params: {id: props.feedback.id}})
}

const selfEvaluation = async () => {
  await router.push({name: 'interview_answer', params: {id: props.id}})
}

const selfAnswerButtonText = () => {
  switch (selfReview.value?.status) {
    case "not_started":
      return t('Start self-evaluation')
    case "in_progress":
      return t('Continue self evaluation')
    case "submitted":
      return t('View my answers')
    default:
      return "error"
  }
}

const feedbackAnswerButtonText = (status) => {
  switch (status) {
    case "not_started":
      return t('Start my feedback')
    case "in_progress":
      return t('Continue my feedback')
    case "submitted":
      return t('View my answers')
  }
}

const giveFeedback = (id) => {
  router.push({
    name: 'interview_answer',
    params: { id: id }
  })
}
</script>