<template>
  <svn-pro-modal
    ref="dialogRef"
    :title="$t('Help & Support')"
    :actionOneTitle="$t('Send')"
    :primary-loading="loading"
    @click-primary-button="clickPrimaryButton"
  >
    <template #activator>
      <slot name="button" />
    </template>

    <template #text>
      <div class="w-full flex flex-col gap-6">
        <svn-pro-text body-medium regular color="onSurfaceVariant">
          {{ $t('If you have any questions or require assistance, please feel free to reach out to the Aleph team by filling out this form. We will treat and respond to your message very soon!') }}
        </svn-pro-text>

        <svn-pro-text-area
          v-model="message"
          :placeholder="$t('Your issue*')"
          variant="outlined"
          :rows="6"
          :max-rows="6"
          :error="messageError.error"
          :error-messages="messageError.errorMessage"
        />
      </div>
    </template>
  </svn-pro-modal>
</template>

<script setup>
import { ref, watch } from "vue";
import { storeToRefs } from "pinia";
import { useSnackbar } from "@/store/snackbar.js";
import axiosService from "@/tools/axios-service.js";
import { useUserStore } from "../../../store/user";

const snackbar = useSnackbar();

const { email: userEmail, firstname, lastname } = storeToRefs(useUserStore());

const message = ref('');
const loading = ref(false);
const dialogRef = ref(null);
const primaryButtonLoading = ref(false);
const messageError = ref({ error: false, errorMessage: '', hideDetails: true });

const clickPrimaryButton = async () => {
  loading.value = true
  if (!message?.value) {
    messageError.value = { error: true, errorMessage: "*required", hideDetails: true };
  } else {
    primaryButtonLoading.value = true;
    try {
      await axiosService.post('api/v1/global_mailer/comp_support_help_request', {
        user_firstname: firstname?.value,
        user_lastname: lastname?.value,
        user_email: userEmail?.value,
        user_message: message?.value,
      });

      await axiosService.post('api/v1/global_mailer/comp_support_you_requested_help', {
        user_firstname: firstname?.value,
        user_email: userEmail?.value,
        user_message: message?.value,
      });
      dialogRef.value.dialog = false;
      primaryButtonLoading.value = false;

      snackbar.setMsg('Your form has been sent. Thank you!');
      snackbar.setBgColor('onSurface');
      snackbar.setCustomClass('mb-4');
      snackbar.displaySnackBar();
  
      resetForm();
    } catch (error) {
      primaryButtonLoading.value = false;

      console.log(error);
      snackbar.setMsg('Error sending form, please try again.');
      snackbar.setBgColor('error');
      snackbar.setCustomClass('mb-4');
      snackbar.displaySnackBar();
    }
  }
  loading.value = false
}

const resetForm = () => {
  message.value = '';
}

watch(message, (newValue, oldValue) => {
  if (newValue !== "" && messageError?.value?.error === true) {
    messageError.value = { error: false, errorMessage: "", hideDetails: true }
  }
});

defineExpose({
  dialogRef
})
</script>

<style scoped>
* :deep(.v-field) {
  border-radius: 8px;
}

* :deep(.v-text-field .v-input__details) {
  padding-inline-start: 0px;
  padding-inline-end: 0px;
}
</style>
