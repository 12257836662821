<template>
  <svn-pro-modal
    ref="dialogRef"
    width="480"
    :title="$t('Change password')"
    :actionOneTitle="$t('Save')"
    :primary-loading="primaryButtonLoading"
    @click-primary-button="changePassword"
    @click-outside="resetInputs"
    @click-close="resetInputs"
  >
    <template #activator>
      {{ null }}
    </template>

    <template #text>
      <v-form
        ref="form"
        v-model="valid"
        class="w-full flex flex-col gap-6"
        lazy-validation
      >
        <svn-pro-text-field
          v-model="newPassword"
          :rules="passwordRules"
          class="w-full"
          :label="$t('New password')"
          type="password"
          variant="outlined"
        />

        <svn-pro-text-field
          v-model="confirmPassword"
          :rules="confirmRules"
          :label="$t('Confirm your new password')"
          type="password"
          variant="outlined"
        />
      </v-form>
    </template>
  </svn-pro-modal>
</template>

<script setup>
import { ref } from "vue";

const emit = defineEmits(['change-password']);

const form = ref();
const valid = ref(false);
const dialogRef = ref(null);
const newPassword = ref(null);
const confirmPassword = ref(null);
const primaryButtonLoading = ref(false);

const passwordRules = [
  (v) => !!v || "",
  (v) => (v && v.length >= 8) || "Must contain at least 8 characters",
];

const confirmRules = [
  (v) => !!v || "",
  (v) => (v && v === newPassword.value) || "Confirmation and password must match",
];

const changePassword = async () => {
  try {
    const { valid } = await form.value.validate();

    if (valid) {
      emit('change-password', newPassword.value);
      newPassword.value = null;
      confirmPassword.value = null;
    }
  } catch (e) {
    console.log(e)
  }
}

const resetInputs = () => {
  newPassword.value = null
  confirmPassword.value = null
  passwordRules.value = []
  confirmRules.value = []
}

defineExpose({
  dialogRef
})
</script>

<style scoped>
* :deep(.v-field) {
  border-radius: 8px;
}

* :deep(.v-text-field .v-input__details) {
  padding-inline-start: 0px;
  padding-inline-end: 0px;
}
</style>
