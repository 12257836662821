import {defineStore} from "pinia";
import axiosService from "@/tools/axios-service.js";
import {genericFunctions, genericStates} from "@/tools/genericFunctions.js";

export const useInterviewFormStore = defineStore({
  id: "interview_form",
  state: () => ({
    interviewForm: null,
    isTemplateLoading: false,
    id: null,
    title: null,
    description: null,
    video: null,
    templateType: null,
    tags: [],
    interviewQuestionCount: 0,
    templatePagination: {},
    templateTrashesPagination: {},
    interviewQuestions: [],
    interviewForms: [],
    search: null,
    trashInterviewForms: [],
    ...genericStates
  }),
  getters: {},
  actions: {
    ...genericFunctions,
    async createInterviewForm() {
    },
    async fetchInterviewForm(id) {
      try {
        this.isTemplateLoading = true
        const res = await this.fetchEntity(`/api/v2/interview_app/forms/templates/${id}`, 'interview_app_form', 'interviewForm')
        this.interviewForm = res

        this.id = res?.id
        this.title = res?.title
        this.description = res?.description
        this.video = res?.video
        this.templateType = res?.template_type
        this.tags = res?.categories
        this.interviewQuestionCount = res?.question_count
        this.interviewQuestions = null
        this.interviewQuestions = res?.questions

      } catch (e) {
        console.log('fail while fetching template', e)
      } finally {
        this.isTemplateLoading = false
      }
    },
    async updateInterviewForm(opt) {
      try {
        this.isTemplateLoading = true

        await axiosService.put(`/api/v2/interview_app/forms/templates/${this.id}`, opt)
      } catch (e) {
        console.log("couldn't update interview form ", e)
      } finally {
        this.isTemplateLoading = false
      }
    },
    async updateInterviewFormTags(tagIds) {
      try {
        await axiosService.put(`/api/v2/interview_app/forms/templates/${this.id}/tags`, {tag_ids: tagIds})
      } catch (e) {
        console.log("couldn't update interview form ", e)
      }
    },
    async createQuestion(position, type) {
      try {
        const res = await axiosService.post(`/api/v2/interview_app/forms/templates/${this.id}/questions`, {position, type})
        const interviewFormStore = useInterviewFormStore()

        await interviewFormStore.fetchInterviewForm(this.id)
        return res.data
      } catch (e) {
        console.log("couldn't create interview form ", e)
      }
    },
    async updateQuestion(questionId, opt) {
      try {
        this.isTemplateLoading = true

        await axiosService.put(`/api/v2/interview_app/forms/templates/${this.id}/questions/${questionId}`, opt)
      } catch (e) {
        console.log("couldn't update interview question ", e)
      } finally {
        this.isTemplateLoading = false
      }
    },
    async deleteQuestion(questionId) {
      try {
        this.isTemplateLoading = true

        await axiosService.delete(`/api/v2/interview_app/forms/templates/${this.id}/questions/${questionId}`)
        const interviewFormStore = useInterviewFormStore()

        await interviewFormStore.fetchInterviewForm(this.id)
      } catch (e) {
        console.log("couldn't update interview question ", e)
      } finally {
        this.isTemplateLoading = false
      }
    },
    async duplicateQuestion(questionId) {
      try {
        this.isTemplateLoading = true

        await axiosService.post(`/api/v2/interview_app/forms/templates/${this.id}/questions/${questionId}/duplicate`)
        const interviewFormStore = useInterviewFormStore()

        await interviewFormStore.fetchInterviewForm(this.id)
      } catch (e) {
        console.log("couldn't update interview question ", e)
      } finally {
        this.isTemplateLoading = false
      }
    },
    async increaseQuestionPos(questionId, opt) {
      try {
        this.isTemplateLoading = true

        await this.updateQuestion(questionId, {move_up: true})
        await this.fetchInterviewForm(this.id)
      } catch (e) {
        console.log("couldn't update interview question ", e)
      } finally {
        this.isTemplateLoading = false
      }
    },
    async decreaseQuestionPos(questionId, opt) {
      try {
        this.isTemplateLoading = true

        await this.updateQuestion(questionId, {move_down: true})
        await this.fetchInterviewForm(this.id)

      } catch (e) {
        console.log("couldn't update interview question ", e)
      } finally {
        this.isTemplateLoading = false
      }
    },
    async updateQuestionOptions(questionId, optionPos, params) {
      try {
        this.isTemplateLoading = true

        await axiosService.put(
          `/api/v2/interview_app/forms/templates/${this.id}/questions/${questionId}/options/${optionPos}`,
          params
        )
      } catch (e) {
        console.log("couldn't update interview question ", e)
      } finally {
        this.isTemplateLoading = false
      }
    },
    async deleteQuestionOptions(questionId, optionPos) {
      try {
        this.isTemplateLoading = true

        await axiosService.delete(
          `/api/v2/interview_app/forms/templates/${this.id}/questions/${questionId}/options/${optionPos}`
        )
      } catch (e) {
        console.log("couldn't update interview question ", e)
      } finally {
        this.isTemplateLoading = false
      }
    },
    async createQuestionOptions(questionId, optionPos) {
      try {
        this.isTemplateLoading = true

        await axiosService.post(
          `/api/v2/interview_app/forms/templates/${this.id}/questions/${questionId}/options?position=${optionPos}`
        )
      } catch (e) {
        console.log("couldn't update interview question ", e)
      } finally {
        this.isTemplateLoading = false
      }
    },
    async createRoadmap() {
      try {
        this.isTemplateLoading = true

        const res = await axiosService.post(
          `/api/v2/interview_app/forms/templates/${this.id}/roadmaps`
        )

        this.roadmap = res.data['objective/roadmap']
      } catch (e) {
        console.log("couldn't update interview question ", e)
      } finally {
        this.isTemplateLoading = false
      }
    },
    async removeRoadmap() {
      try {
        this.isTemplateLoading = true

        await axiosService.delete(
          `/api/v2/interview_app/forms/templates/${this.id}/roadmaps`
        )

        this.roadmap = null
      } catch (e) {
        console.log("couldn't update interview question ", e)
      } finally {
        this.isTemplateLoading = false
      }
    },
    async updateRoadmap(opt) {
      try {
        this.isTemplateLoading = true

        await axiosService.put(`/api/v2/interview_app/forms/templates/${this.id}/roadmaps`, opt)
      } catch (e) {
        console.log("couldn't update interview form ", e)
      } finally {
        this.isTemplateLoading = false
      }
    },
    async clearInterviewForm() {
      this.isTemplateLoading = false
      this.id = null
      this.title = null
      this.description = null
      this.video = null
      this.templateType = null
      this.tags = []
      this.interviewQuestionCount = 0
      this.interviewQuestions = []
      this.roadmap = null
    },
    isFeedBack360() {
      return this.templateType === 'pairs'
    },
    isSurvey() {
      return this.templateType === 'survey'
    },
    async fetchInterviewForms() {
      try {
        await this.fetchEntity('/api/v2/interview_app/forms/templates', 'interview_app_forms', 'interviewForms')
        this.templatePagination = this.pagination
      } catch (error) {
        console.log(error)
      }
    },
    async fetchTrashInterviewForms(text = '') {
      try {
        this.updateParams({ "title": text })
        await this.fetchEntity('api/v2/interview_app/forms/templates/trashes', 'interview_app_forms', 'trashInterviewForms')
        this.templateTrashesPagination = this.pagination
      } catch (error) {
        console.log(error)
      }
    },
    async fetchCampaignDraftsExist(id) {
      try {
        return await this.fetchEntity(`/api/v2/interview_app/forms/templates/${id}/any_programmed_campaigns`, 'any_programmed_campaigns')
      } catch (error) {
        console.log(error)
      }
    },
    async deleteInterviewForm(id) {
      try {
        const interviewFormStore = useInterviewFormStore()

        await interviewFormStore.deleteEntity(`/api/v2/interview_app/forms/templates/${id}`, 'interview_app_form')

        const trashInterviewForm = this.interviewForms.find(trash => trash?.id === id)
        trashInterviewForm.deleted_at = new Date()

        this.trashInterviewForms.push(trashInterviewForm)
        this.interviewForms = this.interviewForms.filter(trash => trash?.id !== id)

      } catch (error) {
        console.log(error)
      }
    },
    async restoreInterviewForm(id) {
      try {
        await this.updateEntity(`api/v2/interview_app/forms/templates/${id}/restore`)
        this.interviewForms.push(this.trashInterviewForms.find(trash => trash?.id === id))
        this.removeTemplatesfromTrashes(id)

      } catch (error) {
        console.error(error)
      }
    },
    async deleteInterviewFormPermanently(id) {
      try {
        await this.deleteEntity(`api/v2/interview_app/forms/templates/${id}/destroy_permanently`)
        this.removeTemplatesfromTrashes(id)
      } catch (error) {
        console.error(error)
      }
    },
    removeTemplatesfromTrashes (id) {
      this.trashInterviewForms = this.trashInterviewForms.filter(trash => trash?.id !== id)
    }
  },
});
