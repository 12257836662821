<template>
  <svn-pro-data-table
    :headers="interviewData?.length ? headers : []"
    :items="interviewData ?? []"
    :items-per-page="-1"
    :loading="interviewData ? loading : true"
    item-value="id"
    :pagination-total-pages="paginationData?.total_pages"
    @update-pagination="fetchNewPage"
  >
    <template #item="{ item }">
      <tr class="hover:bg-[#46464F14] active:bg-[#46464F1F]/[0.12]" @click="goToSpecificInterview(item?.id)">
        <td>
          <svn-pro-data-table-cell
            regular
            body-medium
            :emoji="campaign_icon(item.campaign.campaign_type)"
            cell-type="emoji_text"
            :text="item?.title"
          />
        </td>

        <td>
          <svn-pro-data-table-cell
            regular
            body-medium
            :avatar="getAvatar(item)"
            cell-type="avatar_text"
            :text="getText(item)"
          />
        </td>

        <td>
          <svn-pro-data-table-cell
            regular
            body-medium
            :tag-type="getTagType(item?.campaign?.status)"
            tag-size="compact"
            leading-item="point"
            cell-type="info_tag"
            :text="$t(campaign_status_str(item?.campaign?.status))"
          />
        </td>
      </tr>
    </template>

    <template #no-data>
      <svn-pro-empty-states
        :variant="searchText ? 'results' : 'index'"
        :title="$t('Nothing to show for now')"
        :size="isMdScreen ? 'default' : 'compact'"
        :supporting-text="searchText ? $t('Oops, we didn’t find any results matching your search.') :
          $t(`Hmm, it seems you have no campaigns ${getMention(props.headers[1].title)}`)"
      />
    </template>

    <template #loading>
    </template>
  </svn-pro-data-table>
</template>

<script setup>
import { toRefs } from "vue";
import { storeToRefs } from "pinia";
import { useRouter } from "vue-router";
import { useMobileStore } from "@/store/mobile";
import useCampaigns from '@/tools/useCampaigns';
import { useInterviewStore } from "@/store/interview.js";

const props = defineProps({
  headers: { type: Array, required: true },
  tab: { type: String, default: "as_participant" },
  interviewData: { type: Array, default: () => [] },
  paginationData: { type: Object, default: () => {} },
  user: { type: Object, required: true },
  searchText: { type: String, default: "" }
})

const emit = defineEmits(['refetch-interviews'])

const router = useRouter();
const { campaign_status_str, campaign_icon } = useCampaigns();
const { setInterviewsByUser } = useInterviewStore();

const { isMdScreen } = storeToRefs(useMobileStore());
const { loading } = storeToRefs(useInterviewStore());

const { searchText, tab } = toRefs(props);

const fetchNewPage = (page) => {
  setInterviewsByUser([])
  emit('refetch-interviews', { userId: props.user.id, as: tab?.value, text: searchText.value, page: page })
};

 const getMention = (person) => {
  if ( person === "Person in charge") {
    return 'as participant'
  }
  else if (person === "Review of") {
    return 'as reviewer'
  }
  else if (person === "In charge of") {
    return 'as person in charge'
  }
}

const goToSpecificInterview = (InterviewId) => {
  if (props?.tab === "as_participant" || props?.tab === "as_person_in_charge") {
    return goToInterview(InterviewId);
  } else if (props?.tab === "as_reviewer") {
    return goToInterviewIfFeedback(InterviewId);
  } else {
    return ""
  }
}

const goToInterview = (InterviewId) => {
  router.push({ name: 'interview_answer', params: { id: InterviewId } })
}

const goToInterviewIfFeedback = (InterviewId) => {
  router.push({ name: 'feedback_answer', params: { id: InterviewId } })
}

const getAvatar = (item) => {
  if (props?.tab === "as_participant" && item?.employee?.avatar) {
    return item?.employee?.avatar["30"]
  } else if (props?.tab === "as_reviewer" && item?.participant?.avatar) {
    return item?.participant?.avatar["30"]
  } else if (props?.tab === "as_person_in_charge" && item?.interviewer?.avatar) {
    return item?.interviewer?.avatar["30"]
  } else {
    return null
  }
}

const getText = (item) => {
  if (props?.tab === "as_participant" && item?.employee) {
    return item?.employee?.fullname
  } else if (props?.tab === "as_reviewer" && item?.participant) {
    return item?.participant?.fullname
  } else if (props?.tab === "as_person_in_charge" && item?.interviewer) {
    return item?.interviewer?.fullname
  } else {
    return null
  }
}

const getTagType = (status) => {
  switch (status) {
    case "not_started":
      return 'error'
    case "in_progress":
      return 'warning'
    case "submitted":
      return 'success'
  }
}
</script>
