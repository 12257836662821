<template>
  <!-- Module Card -->
  <div
    class="w-full flex items-center gap-4"
    @click="toggleContent"
  >
    <!-- Module images -->
    <div
      v-if="content?.content_type === 'Learn::Module'"
      class="w-[56px] h-[56px]"
    >
      <img
        :src="content?.cover_url?.['500']"
        draggable="false"
        style="width: 56px; height: 56px; object-fit: cover;"
      >
    </div>

    <!-- Playlists images -->
    <div
      v-else
      class="flex flex-col justify-center items-center h-[56px] w-[56px] bg-cover"
      :style="{'background-image': content?.cover_urls?.length === 1 ?
        `url('${content?.cover_urls?.[0]?.['500']}')` : ''}"
    >
      <!-- 2 modules in the playlist -->
      <div
        v-if="content?.cover_urls?.length === 2"
        class="w-full h-full flex flex-row overflow-hidden"
      >
        <div
          class="flex flex-col justify-center items-center w-1/2 h-full bg-cover"
          :style="{'background-image': `url('${content?.cover_urls?.[0]?.['500']}')`}"
        />

        <div
          class="flex flex-col justify-center items-center w-1/2 h-full bg-cover"
          :style="{'background-image': `url('${content?.cover_urls?.[1]?.['500']}')`}"
        />
      </div>

      <!-- 3 modules in the playlist -->
      <div
        v-if="content?.cover_urls?.length === 3"
        class="w-full h-full flex flex-col overflow-hidden"
      >
        <div class="flex w-full h-1/2">
          <div
            class="flex flex-col justify-center items-center w-1/2 h-full bg-cover"
            :style="{'background-image': `url('${content?.cover_urls?.[0]?.['500']}')`}"
          />

          <div
            class="flex flex-col justify-center items-center w-1/2 h-full bg-cover"
            :style="{'background-image': `url('${content?.cover_urls?.[1]?.['500']}')`}"
          />
        </div>

        <div
          class="flex flex-col justify-center items-center w-full h-1/2 bg-cover"
          :style="{'background-image': `url('${content?.cover_urls?.[2]?.['500']}')`}"
        />
      </div>

      <!-- At least 4 modules in the playlist -->
      <div
        v-if="content?.cover_urls?.length >= 4"
        class="w-full h-full flex flex-wrap overflow-hidden"
      >
        <div
          class="flex flex-col justify-center items-center w-1/2 h-1/2 bg-cover"
          :style="{'background-image': `url('${content?.cover_urls?.[0]?.['500']}')`}"
        />

        <div
          class="flex flex-col justify-center items-center w-1/2 h-1/2 bg-cover"
          :style="{'background-image': `url('${content?.cover_urls?.[1]?.['500']}')`}"
        />

        <div
          class="flex flex-col justify-center items-center w-1/2 h-1/2 bg-cover"
          :style="{'background-image': `url('${content?.cover_urls?.[2]?.['500']}')`}"
        />

        <div
          class="flex flex-col justify-center items-center w-1/2 h-1/2 bg-cover relative bg-[50%] grayscale"
          :style="{'background-image': `url('${content?.cover_urls?.[3]?.['500']}')`}"
        >
          <div
            v-if="content?.cover_urls?.length > 4"
            class="absolute left-0 top-0 h-full w-full overflow-hidden bg-surface bg-fixed opacity-[0.5]"
          />

          <p
            v-if="content?.cover_urls?.length > 4"
            class="!text-[#FFFFFF] text-base font-medium z-[9999]"
          >
            +{{ content?.cover_urls?.length - 3 }}
          </p>
        </div>
      </div>

      <!-- No module in the playlist -->
      <div v-if="!content?.cover_urls?.length">
        <Icon
          icon="ic:outline-play-circle"
          height="32"
          width="32"
          class="text-onSurfaceVariant"
        />
      </div>
    </div>

    <div class="w-full flex-1 flex flex-col">
      <svn-pro-text subtitle-large regular class="line-clamp-1">
        {{ content?.title }}
      </svn-pro-text>

      <svn-pro-text body-medium regular color="onSurfaceVariant">
        {{ content?.content_type === 'Learn::Module' ? content?.duration : `${content?.cover_urls?.length} ${content?.cover_urls?.length > 1 ? $t('modules') : $t('module')}` }}
      </svn-pro-text>
    </div>
  </div>
</template>

<script setup>
import { Icon } from "@iconify/vue";

const props = defineProps ({
  content: { type: Object, default: () => {} },
});

const emit = defineEmits(['toggle-content'])

const toggleContent = () => {
  emit('toggle-content', props?.content)
}
</script>