import { QuillEditor } from "@vueup/vue-quill";

import "@vueup/vue-quill/dist/vue-quill.snow.css";

export default function loadQuillEditorOptions(app) {
  const globalOptions = {
    debug: 'warn',
    modules: {
    },
    placeholder: 'description',
    theme: 'snow',
    contentType: 'html',
  }

  QuillEditor.props.globalOptions.default = () => globalOptions
  app.component("QuillEditor", QuillEditor);
}