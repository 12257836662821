<template>
  <div class="!rounded-xl shadow-none border border-borderColor border-opacity-100 w-full flex flex-col pb-1.5">
    <div class="w-full flex-none flex border-b">
      <div class="w-full p-4 flex gap-1 items-center">
        <div>
          <Icon
            icon="ic:outline-info"
            height="20"
            width="20"
            class="text-onSurfaceVariant"
          />
        </div>

        <svn-pro-title h6 medium>
          {{ $t('Shared information') }}
        </svn-pro-title>
      </div>
    </div>

    <div class="text-sm font-medium  overflow-y-auto h-full max-h-[222px]">
      <div class="overflow-hidden">
        <v-list v-if="links?.length" :items="links">
          <svn-pro-list-item
            v-for="link in links"
            :key="link"
            :value="link"
            density="default"
            :active="false"
            :href="link?.link"
            :target="'_blank'"
            :title="link?.title"
            trailing="icon"
            trailing-color="onSurfaceVariant"
            trailing-icon="ic:twotone-open-in-new"
          />
        </v-list>
      </div>
    </div>
  </div>
</template>

<script setup>
import { storeToRefs } from "pinia";
import { Icon } from "@iconify/vue";
import { useCompanyStore } from "@/store/company";

const props = defineProps({
  companyId: {
    type: Number,
    required: true
  }
})

const { links } = storeToRefs(useCompanyStore())
</script>