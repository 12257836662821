<template>
  <dialog-validation
    ref="publishDialog"
    icon="mdi:publish"
    :bind="cannotBePublished ? false : true"
    title="Are you sure you want to publish ?"
    description="Your module will be added to the Catalog and will be visible to everyone."
    button-primary-color="primary"
    :display-close="false"
    :vertical="isMobile ? true : false"
    secondary-button-text="No, cancel"
    primary-button-text="Yes, publish"
    @click-secondary-button="closeAndGoBack"
    @click-primary-button="publishModule"
  >
    <template #button>
      <svn-pro-button
        variant="elevated"
        class="w-[116px]"
        :text="$t('Publish')"
        :disable="cannotBePublished"
      />
    </template>

    <template #close="{ close }">
      <div
        class="self-end"
        @click="close"
      >
        <Icon
          :icon="'material-symbols:close'"
          class="h-6 w-6 text-dark-grey hover:text-fake-black cursor-pointer"
        />
      </div>
    </template>
  </dialog-validation>
</template>

<script setup>
import { ref } from "vue";
import { Icon } from "@iconify/vue";
import DialogValidation from "@/components/BktPopUp/Dialogs/learn/DialogValidation.vue";
import { storeToRefs } from "pinia";
import { useMobileStore } from "@/store/mobile";

const props = defineProps({
  cannotBePublished: { type: Boolean, default: true },
})

const emit = defineEmits(['publish-module'])

const publishDialog = ref(null)

const { isMobile } = storeToRefs(useMobileStore())

const closeAndGoBack = () => {
  publishDialog.value.dialog = false
}

const publishModule = () => {
  emit('publish-module')
}
</script>