<template>
  <div class="w-full flex flex-col gap-4 px-5 md:!px-0">
    <svn-pro-title h6 medium>
      {{ $t('Participants list') }}
    </svn-pro-title>

    <svn-pro-text-field
      v-model="search"
      variant="outlined"
      :label="$t('Search')"
    />

    <v-data-table
      :headers="trainingParticipantsList?.length ? headers : []"
      :items="trainingParticipantsList ?? []"
      :items-per-page="10"
      :page="page"
      :custom-filter="filterByFullname"
      :search="search"
      :loading="trainingParticipantsList ? loading : true"
      :show-select="false"
      :pagination-total-pages="totalPages"
    >
      <template #item="{ item }">
        <tr class="hover:bg-[#46464F14] active:bg-[#46464F1F]/[0.12]">
          <!-- User fullname -->
          <td>
            <svn-pro-data-table-cell
              medium
              body-medium
              cell-type="avatar_text"
              :avatar="item?.user?.avatar?.['50']"
              :text="`${item?.user?.firstname} ${item?.user?.lastname}`"
            />
          </td>

          <!-- Completion -->
          <td>
            <my-trainings-completion-indicator
              :acquired="item?.module_completion"
              :total="item?.module_count"
            />
          </td>

          <!-- Last module acquired on -->
          <td>
            <svn-pro-data-table-cell
              regular
              body-medium
              cell-type="text"
              :text="item?.last_module_acquired_on ? moment(item?.last_module_acquired_on).format('DD MMM YYYY h:mm a') : '-'"
            />
          </td>

          <!-- Training acquired on -->
          <td>
            <svn-pro-data-table-cell
              regular
              body-medium
              cell-type="text"
              :text="item?.training_acquired_on ? moment(item?.training_acquired_on).format('DD MMM YYYY h:mm a') : '-'"
            />
          </td>
        </tr>
      </template>

      <template #bottom="{ pageCount }">
        <svn-pro-pagination
          v-if="trainingParticipantsList?.length > 10"
          v-model="page"
          :length="pageCount"
          class="mt-6"
        />
      </template>

      <template #no-data>
        <svn-pro-empty-states
          :variant="search ? 'results' : 'index'"
          :title="$t('Nothing to show for now')"
          :size="isMdScreen ? 'default' : 'compact'"
          :supporting-text="search ? $t('Oops, we didn’t find any results matching your search.') : $t('Add participants to follow their progression on this training!')"
        />
      </template>

      <template #loading>
        <div class="w-full h-[693px] flex justify-center items-center">
          <svn-pro-progress-circular
            :size="48"
            :width="4"
            indeterminate
          />
        </div>
      </template>
    </v-data-table>
  </div>
</template>

<script setup>
import i18n from "@/plugins/i18n";
import { ref, computed } from "vue";
import { storeToRefs } from "pinia";
import moment from "moment/moment.js";
import { useMobileStore } from "@/store/mobile";
import MyTrainingsCompletionIndicator from "@/components/trainingApp/MyTrainingsCompletionIndicator.vue";

const { isMdScreen } = storeToRefs(useMobileStore());

const props = defineProps({
  loading: { type: Boolean, default: false },
  trainingParticipantsList: { type: Array, required: true },
});

const page = ref(1);
const search = ref('');
const headers = ref([
  {
    align: "start",
    key: "fullname",
    sortRaw (a, b) {
      if (b?.user?.fullname < a?.user?.fullname) {
        return 1
      } else {
        return -1
      }
    },
    minWidth: 300,
    title: i18n.global.t('Participants'),
  },
  {
    align: "start",
    key: "modules_acquired",
    sortable: false,
    minWidth: 170,
    title: i18n.global.t('Modules acquired'),
  },
  {
    align: "start",
    key: "last_module_acquired_on",
    sortable: false,
    minWidth: 225,
    title: i18n.global.t('Last module acquired on'),
  },
  {
    align: "start",
    key: "training_acquired_on",
    sortable: false,
    minWidth: 200,
    title: i18n.global.t('Training acquired on'),
  },
]);
const totalPages = computed(() => {
  return (props?.trainingParticipantsList?.length / 10)
})

const filterByFullname = (value, query, item) => {
  return (item?.raw?.user?.fullname?.toLowerCase()?.includes(query?.toLowerCase()))
}
</script>