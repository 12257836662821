import axiosService from '@/tools/axios-service';
import LearnPlaylist, { LearnPlaylistPagination } from '@/models/learn_playlist';

class PlaylistApi {
  async index({ page = 1, text = '', themeIds = [] }) {
    const res = await axiosService.get(`/api/v1/learn/playlists`, {
      params: {
        'page[number]': page,
        text,
        theme_ids: themeIds,
        'page[size]': 10,
      },
    });

    return LearnPlaylistPagination.fromJson(res.data);
  }

  async get(id) {
    const res = await axiosService.get(`/api/v1/learn/playlists/${id}`);

    return LearnPlaylist.fromJson(res.data);
  }

  async updateModuleIds(playlistId, moduleIds) {
    const res = await axiosService.patch(`/api/v1/learn/playlists/${playlistId}`, {
      learn_module_ids: moduleIds,
    })

    return LearnPlaylist.fromJson(res.data);
  }

  async update(playlistId, title, description) {
    const res = await axiosService.patch(`/api/v1/learn/playlists/${playlistId}`, {
      title: title,
      description: description,
    })

    return LearnPlaylist.fromJson(res.data);
  }

  async delete(id) {
    const res = await axiosService.delete(`/api/v1/learn/playlists/${id}`);

    return LearnPlaylist.fromJson(res.data);
  }

  async toggleFavorite(playlist) {
    if (!playlist) return null;
    const res = await axiosService.post(`api/v1/learn/playlists/${playlist.id}/favorite`);

    // Create a shallow copy
    return playlist?.copyWith({
      connectedUserFavorited: playlist.connectedUserFavorited,
    });
  }
}

const instance = new PlaylistApi();

export default instance;