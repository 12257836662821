<template>
  <div class="flex flex-col gap-3 items-center justify-start max-w-[752px] mb-4 w-full">
    <!--  main template card-->
    <svn-pro-card
      variant="outlined"
      :link="false"
      :rounded="isMobile ? '0' : 'lg'"
      :class="isMobile ? 'border-0' : ''"
      class="sm:flex mt-10 mb-1 flex flex-col w-full border-[0.5px]"
    >
      <div class="flex justify-between p-4 !pb-3">
        <v-card-title class="!px-0">
          <svn-pro-info-tag
            tag-type="primary"
            :text="$t('Template')"
            tag-style="filled"
            :disabled="isDeleted"
            class="max-w-[112px] h-[32px]"
            leading-item="icon"
            icon="mingcute:pencil-ruler-line"
          />
        </v-card-title>

        <dialog-edit-template-tags
          fetch-tags-from-entity-path="categories_from_template"
          route="forms/templates"
          :entity-id="id"
          :template-categories="tags"
        >
          <template #activator="{ props }">
            <svn-pro-icon-button
              icon="custom:mingcute:tag-line"
              color="onSurfaceVariant"
              :disabled="isDeleted"
              variant="text"
              v-bind="props"
            />
          </template>
        </dialog-edit-template-tags>
      </div>

      <v-card-text class="flex flex-col gap-4">
        <svn-pro-select
          v-if="!isFeedBack360() && !isSurvey()"
          class="md:w-[304px] w-full"
          v-model="templateType"
          :items="tagCategories"
          :label="$t('Template type')"
          @update:model-value="updateInterviewForm({ answerable_by: $event })"
          item-text="title"
          :disabled="isDeleted"
          item-value="value"
          persistent-hint
        >
          <template
            #selection="{item}"
          >
            <div class="w-full truncate">
              {{ item.title }}
            </div>
          </template>
        </svn-pro-select>

        <svn-pro-text-field
          v-model="title"
          :label="$t('Template name')"
          variant="outlined"
          :disabled="isDeleted"
          @update:model-value="debounceUpdateInterviewForm({title: $event})"
        />

        <!-- <svn-pro-text-area
          v-if="description !== null"
          v-model="description"
          :placeholder="$t('Description')"
          :disabled="isDeleted"
          variant="outlined"
          :rows="6"
          :max-rows="6"
          :label="$t('Description')"
          :textarea="true"
        /> -->

        <div>
          <quill-editor
            v-if="description !== null"
            v-model:content="description"
            content-type="html"
            :placeholder="$t('Description')"
            :enable="!isDeleted"
            @update:content="debounceUpdateInterviewForm({ description: $event })"
            :style="{ height: '160px' }"
          />
        </div>

        <svn-pro-text-field
          v-model="video"
          :label="$t('Video link (Youtube)')"
          variant="outlined"
          :disabled="isDeleted"
          @update:model-value="debounceUpdateInterviewForm({video: $event})"
        />

      </v-card-text>
    </svn-pro-card>

    <!-- Le bouton + pour ajouter un champ -->
    <bkt-interview-add-menu
      :is-deleted="isDeleted"
      @add="createAndScrollToQuestion(1, $event)"
    />

    <template
      v-for="(question) in interviewQuestions"
      :key="question.id"
    >
      <template-edit-chapter
        v-if="question.type === InterviewQuestionType.CHAPTER"
        class="!w-full overflow-visible"
        :interview-question="question"
        :is-deleted="isDeleted"
        @delete="deleteQuestion(question.id)"
        @move-up="increaseQuestionPos(question.id)"
        @move-down="decreaseQuestionPos(question.id)"
        @copy-bkt="duplicateQuestion(question.id)"
      />

      <template-edit-paragraph
        v-if="question.type === InterviewQuestionType.PARAGRAPH"
        class="!w-full overflow-visible"
        :interview-question="question"
        :is-deleted="isDeleted"
        @input="questionInputEvent(question.id, $event)"
        @delete="deleteQuestion(question.id)"
        @move-up="increaseQuestionPos(question.id)"
        @move-down="decreaseQuestionPos(question.id)"
        @copy-bkt="duplicateQuestion(question.id)"
      />

      <template-edit-open-question
        v-else-if="question.type === InterviewQuestionType.OPEN"
        class="!w-full overflow-visible"
        :interview-question="question"
        :is-deleted="isDeleted"
        @delete="deleteQuestion(question.id)"
        @move-up="increaseQuestionPos(question.id)"
        @move-down="decreaseQuestionPos(question.id)"
        @copy-bkt="duplicateQuestion(question.id)"
      />

      <template-edit-rating
        v-else-if="question.type === InterviewQuestionType.RATING"
        class="!w-full overflow-visible"
        :interview-question="question"
        :is-deleted="isDeleted"
        @delete="deleteQuestion(question.id)"
        @move-up="increaseQuestionPos(question.id)"
        @move-down="decreaseQuestionPos(question.id)"
        @copy-bkt="duplicateQuestion(question.id)"
      />

      <template-edit-multi-choice
        v-else-if="question.type === InterviewQuestionType.MCQ"
        class="!w-full !overflow-visible"
        :interview-question="question"
        :is-deleted="isDeleted"
        @delete="deleteQuestion(question.id)"
        @move-up="increaseQuestionPos(question.id)"
        @move-down="decreaseQuestionPos(question.id)"
        @copy-bkt="duplicateQuestion(question.id)"
      />

      <template-roadmap-block
        v-else-if="question.type === InterviewQuestionType.CREATE_ROADMAP || question.type === InterviewQuestionType.UPDATE_ROADMAP"
        class="!w-full !overflow-visible"
        :title="question.type === InterviewQuestionType.CREATE_ROADMAP ? 'Create target' :
          'Update target'"
        :interview-question="question"
        :is-deleted="isDeleted"
        @delete="deleteQuestion(question.id)"
        @move-up="increaseQuestionPos(question.id)"
        @move-down="decreaseQuestionPos(question.id)"
        @copy-bkt="duplicateQuestion(question.id)"
      />

      <bkt-interview-add-menu
        class="!w-full"
        :is-deleted="isDeleted"
        @add="createAndScrollToQuestion(question.position + 1, $event)"
      />
    </template>
  </div>
</template>

<script setup>
import { ref, computed } from "vue";
import BktInterviewAddMenu from "@/components/interviewApp/template/Edit/BktInterviewAddMenu.vue";
import TemplateEditChapter from "@/components/interviewApp/template/Edit/TemplateEditChapter.vue";
import TemplateEditParagraph from "@/components/interviewApp/template/Edit/TemplateEditParagraph.vue";
import TemplateEditOpenQuestion from "@/components/interviewApp/template/Edit/TemplateEditOpenQuestion.vue";
import TemplateEditRating from "@/components/interviewApp/template/Edit/TemplateEditRating.vue";
import TemplateEditMultiChoice from "@/components/interviewApp/template/Edit/TemplateEditMultiChoice.vue";
import {useInterviewFormStore} from "@/store/interview-form.js";
import {storeToRefs} from "pinia";
import TemplateRoadmapBlock from "@/components/interviewApp/template/Edit/TemplateRoadmapBlock.vue";
import {QuillEditor} from "@vueup/vue-quill";
import { InterviewQuestionType } from '@/constants/types';
import { debounce} from "lodash";
import DialogEditTemplateTags from "@/components/BktPopUp/Dialogs/DialogEditTemplateTags.vue";
import {useI18n} from "vue-i18n";
import {useMobileStore} from "@/store/mobile.js";

const {updateQuestion } = useInterviewFormStore()
const { t } = useI18n();
const {isMobile} = storeToRefs(useMobileStore())

const props = defineProps({
  isDeleted: { type: Boolean, required: true }
});

const {
  updateInterviewForm,
  createQuestion,
  increaseQuestionPos,
  decreaseQuestionPos,
  deleteQuestion,
  duplicateQuestion,
  isFeedBack360,
  isSurvey
} = useInterviewFormStore()
const {
  id,
  title,
  description,
  video,
  tags,
  templateType,
  interviewQuestions,
} = storeToRefs(useInterviewFormStore())

const selectedCategory = ref(null)
const tagCategories = ref([
  {
    title: t("Interviewee (IE)"),
    desc: t("Only the interviewee will answer this template. The interviewer can still view the answers."),
    value: "employee",
  },
  {
    title: t("Interviewer (IR)"),
    desc: t("Only the interviewer will answer this template."),
    value: "manager",
  },
  {
    title: t("Both"),
    desc: t("Both can answer this template. Only the interviewer can view the interviewee's answers."),
    value: "both",
  },
  {
    title: t("Cross Review"),
    desc: t("In addition of the both template, the Cross Review is a last step for a final version."),
    value: "cross",
  }
])

const createAndScrollToQuestion = async (position, type) => {
  const question = await createQuestion(position, type);
  let element = document.getElementById(question.id);
  if (element) element.scrollIntoView({ behavior: 'smooth', block: 'center' });
}

const debounceUpdateInterviewForm = debounce(async(data) => {
  await updateInterviewForm(data)
}, 200)
const isValidLink = computed(() => {
  if (!video.value || video.value.length === 0 ) return true
  return video.value && video.value.match(/youtube\.com/)
})

const questionInputEvent = (id, value) => {
  updateQuestion(id, { text: value })
};
</script>

<style>
.ql-editor{
  height: 250px;
  max-height: 250px;
  overflow: auto;
}
</style>