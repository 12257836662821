<template>
  <div class="flex flex-col gap-4 p-4">
    <div class="flex items-center !w-full gap-4 sm:p-0">
      <v-text-field
        v-model="text"
        class="grow w-full text-dark-grey input"
        :variant="variant"
        color="primary"
        clearable
        :prepend-inner-icon="icon"
        hide-details
        :label="$t('Search')"
        @update:model-value="updateText"
      />

      <svn-filter
        v-if="headers"
        :table-name="tableName"
        :filter-columns="filterList"
        :show-results="showResults"
        :selected-filters="getActiveFiltersCount()"
        @add-filter="addFilter"
        @activator-clicked="displayOrHideFilterResulsts"
        @get-filter-columns="getFilterColumns"
      />

      <v-btn
        v-if="withReset"
        variant="text"
        class="sm:ml-5 font-medium text-base self-start sm:self-center"
        @click="reset"
      >
        {{ $t("Reset") }}
      </v-btn>
    </div>

    <svn-filter-results
      :data="roadmapEmployees"
      :table-name="tableName"
      :headers="headers"
      :show-results="showResults"
      :filter-list="filterList"
      :selected-filters="selectedFilters"
      :show-filter-resulsts="showFilterResulsts"
      @add-filter="addFilter"
      @delete-filter="deleteFilter"
      @clear-selected-filters="clearSelectedFilters"
      @remove-empty-filter="removeEmptyFilter"
      @update-selected-data-filters="updateSelectedDataFilters"
    />
  </div>
</template>

<script setup>
import { ref, watch } from "vue";
import { storeToRefs } from "pinia";
import { useRoadmapEmployeeStore } from "@/store/roadmap-employee.js";
import { debounce } from "lodash";
import { useFilterStore } from "@/store/filters";
import i18n from "@/plugins/i18n";
import SvnFilter from "@/components/SvnFilter.vue";
import SvnFilterResults from "@/components/SvnFilterResults.vue";

const props = defineProps({
  variant: { type: String, default: 'default' },
  icon: { type: String, default: '' },
  withReset: { type: Boolean, default: true }
});

const emit = defineEmits(["fetch"]);

const { fetchRoadmapEmployees } = useRoadmapEmployeeStore();
const { fetchFilterMetadata } = useFilterStore();

const { columns } = storeToRefs(useFilterStore());
const { params } = storeToRefs(useRoadmapEmployeeStore());
const { roadmapEmployees } = storeToRefs(useRoadmapEmployeeStore());

const text = ref(params.value.title);
const tableName = ref('users');
const showResults = ref(false);
const filterList = ref([]);
const filterData = ref([]);
const selectedFilters = ref([]);
const showFilterResulsts = ref(true);
const headers = ref([
  {
    name: "firstname",
    key: "firstname",
    title: i18n.global.t("Name"),
    icon: "",
  },
  {
    name: "access_level",
    key: "access_level",
    title: i18n.global.t("Access level"),
    icon: "",
  },
  {
    name: "manager_id",
    key: "manager_id",
    title: i18n.global.t("Manager"),
    icon: "",
  },
  {
    name: "target_count",
    key: "target_count",
    title: i18n.global.t("Objectifs"),
    icon: "",
  },
]);

const updateText = debounce((e) => {
  text.value = e;
  emit("fetch", {
    title: text.value,
  });
}, 300)

const reset = () => {
  text.value = null;
  emit('fetch', {
    title: null,
  })
}

const getFilterColumns = async() => {
  if (!columns?.value) {
    try {
      await fetchFilterMetadata(tableName?.value)
      for (let column of columns?.value) {
        let foundCol = headers?.value?.find(el => el?.name === column?.name)
        if (foundCol) {
          let newCol = { ...column, key: foundCol?.key, name: foundCol?.title, icon: foundCol?.icon || "" }
          filterList?.value?.push(newCol)
        }
      }
    } catch (error) {
      console.log('Error')
    }
  }
}

const addFilter = (item) => {
  selectedFilters.value.push({ ...item, active: false })
  showFilterResulsts.value = true
}

const removeEmptyFilter = (item) => {
  selectedFilters?.value?.splice(selectedFilters.value.findIndex(el => el?.id === item?.id), 1)
}

const deleteFilter = async(id) => {
  selectedFilters?.value?.splice(selectedFilters.value.findIndex(el => el?.id === id), 1)
  filterData?.value?.splice(filterData?.value?.findIndex(el => el?.id === id), 1)
  checkShowResulsts()

  if (filterData?.value?.length) {
    params.value = {
      filter_data: JSON.stringify(filterData?.value),
    }
  } else {
    params.value = {}
  }

  await fetchRoadmapEmployeesAfterFilter()
}

const clearSelectedFilters = async() => {
  selectedFilters.value = []
  showFilterResulsts.value = false
  showResults.value = false
  
  params.value = {}
  if (filterData?.value?.length) {
    filterData.value = []
    await fetchRoadmapEmployeesAfterFilter()
  }
}

const updateSelectedDataFilters = async(data) => {
  let found = selectedFilters?.value?.find(el => el?.id === data?.id)
  let filter = filterData?.value?.find(element => element?.id === data?.event?.id)

  if (filter) {
    filter.operator = data?.event?.operator
    filter.value = data?.event?.value
  } else {
    filterData?.value?.push({
      id: data?.event?.id,
      name: data?.event?.name,
      operator: data?.event?.operator?.toLowerCase(),
      value: data?.event?.value,
    })
  }

  if (data?.event?.value?.length) {
    found.active = true
    checkShowResulsts()
    params.value = {
      filter_data: JSON.stringify(filterData?.value),
    }
  }
  else {
    found.active = false
    checkShowResulsts()
    filterData?.value?.splice(filterData?.value?.findIndex(el => el?.id === data?.id), 1)
    if (filterData?.value?.length) {
      params.value = {
        filter_data: JSON.stringify(filterData?.value),
      }
    } else {
      params.value = {}
    }
  }

  await fetchRoadmapEmployeesAfterFilter()
}

const getActiveFiltersCount = () => {
  return selectedFilters?.value?.filter(el => el.active === true).length
}

const displayOrHideFilterResulsts = () => {
  showFilterResulsts.value = !showFilterResulsts.value
}

const checkShowResulsts = () => {
  if (selectedFilters?.value?.filter(el => el.active === true).length) {
    showResults.value = true
  }
  else {
    showResults.value = false
  }
}

const fetchRoadmapEmployeesAfterFilter = async() => {
  try {
    await fetchRoadmapEmployees()
  } catch (error) {
    console.log(error)
  }
}

watch(selectedFilters.value, (newValue, oldValue) => {
  if (newValue.length > 0) {
    showFilterResulsts.value = true
  }
  else {
    showFilterResulsts.value = false
  }
})
</script>

<style scoped>
.input :deep(.v-field) {
  height: 48px;
  border-radius: 8px;
}
</style>
