import { defineStore } from "pinia";
import { genericFunctions, genericStates } from "@/tools/genericFunctions.js";
import axiosService from "@/tools/axios-service.js";

export const useLearnTrainingStore = defineStore({
  id: "learn-training",
  state: () => ({
    id: null,
    learnTraining: {
      cover_url: '',
      description: '',
      title: '',
      learn_contents: [],
      launched_at_date: '',
      launched_at_time: '12:00',
      participant_ids: [],
      participant_filter_tag_ids: [],
      modules_and_playlist: [],
      cover_offset_left: 0,
      cover_offset_top: 0
    },
    learnTrainings: [],
    learnTrainingsLoading: false,
    learnUpcomingTrainings: [],
    trashTrainings: [],
    trainingsByUser: [],
    ...genericStates,
  }),
  getters: {},
  actions: {
    ...genericFunctions,
    resetTraining() {
      this.id = null
      this.learnTraining = {
        cover_url: '',
        description: '',
        title: '',
        learn_contents: [],
        launched_at_date: '',
        launched_at_time: '12:00',
        participant_ids: [],
        participant_filter_tag_ids: [],
        modules_and_playlist: [],
        cover_offset_left: 0,
        cover_offset_top: 0
      }
      this.learnTrainings =  []
      this.learnUpcomingTrainings = null
      this.trashTrainings = []
    },
    async fetchTrainings(text = '') {
      this.learnTrainingsLoading = true
      this.params = {
          text: text,
        }
      this.learnTrainings = await this.fetchEntity('api/v1/learn/trainings', 'learn_trainings', 'learnTrainings')
      this.learnTrainingsLoading = false
    },
    async fetchUpcomingTrainings(text = '') {
      const res = await axiosService.get(`api/v1/learn/trainings/scheduled_trainings`, {
        params: {
          text: text,
        }
      })
      this.learnUpcomingTrainings = res.data.learn_trainings
    },
    async fetchTrashTrainings(text = '') {
      const res = await axiosService.get(`api/v1/learn/trainings/trash_trainings`, {
        params: {
          text: text,
        }
      })

      this.trashTrainings = res.data.learn_trainings
    },
    async fetchTraining(id) {
      const res = await axiosService.get(`api/v1/learn/trainings/${id}`, {
        params: {
          time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone
        }
      })
      this.learnTraining = res.data.learn_training
    },
    async getTrainingsByUser(userId, searchText = "", page = 1) {

      this.loading = true
      try {
        const res = await axiosService.get(`/api/v1/users/${userId}/learnings`, {
          params: {
            learn: "trainings",
            search: searchText,
            "page[number]": page
          }
        })

        this.pagination = res.data.meta.pagination
        this.trainingsByUser = res.data.learn_trainings

        return this.trainingsByUser

      } catch (error) {
        console.log(error);
      } finally {

        this.loading = false
      }
    },
    async getAdditionalTrainingsByUser(userId, searchText = "", page = 1) {
      try {
        const res = await axiosService.get(`/api/v1/users/${userId}/learnings`, {
          params: {
            learn: "trainings",
            search: searchText,
            "page[number]": page
          }
        })

        this.pagination = res.data.meta.pagination

        if (res.data.learn_trainings.length) {
          this.trainingsByUser = [...this.trainingsByUser, ...res.data.learn_trainings]
        }

      } catch (error) {
        console.log(error);
      }
    },
    async toggleContentInTraining(trainingId, content, send_invitation = false) {
      const res = await axiosService.put(`api/v1/learn/trainings/${trainingId}`, {
        learn_contents: content,
        send_invitation: send_invitation
      })
      this.learnTraining = res.data.learn_training
    },
    async updateModuleAndPlaylistData(list) {
      const res = await axiosService.put(`api/v1/learn/trainings/${this.learnTraining.id}`, {
        learn_contents: list,
      })
      this.learnTraining = res.data.learn_training
    },
    async updateParticipantsOfTraining(trainingId, participantsIds, sendInvitation = false) {
      await axiosService.put(`api/v1/learn/trainings/${trainingId}/update_participants`, {
        participant_ids: participantsIds,
        send_invitation: sendInvitation,
        time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone
      })
    },
    async updateParticipantsTagCategoryOfTraining(trainingId, participantTagIds, sendInvitation = false) {
      await axiosService.put(`api/v1/learn/trainings/${trainingId}/update_participants_tags`, {
        participant_tag_ids: participantTagIds,
        send_invitation: sendInvitation,
        time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone
      })
    },
    async createTraining(cover_offset_left, cover_offset_top, title, description, content, cover, launchDate = Date.now(), timeZone, sendInvitation = false) {
      const headers = { "Content-Type": "multipart/form-data" };

      const res = await axiosService.post(`api/v1/learn/trainings`, {
        send_invitation: sendInvitation,
        title: title,
        description: description,
        launched_at: launchDate,
        time_zone: timeZone,
        learn_contents: content,
        cover: cover,
        cover_offset_left,
        cover_offset_top
      }, { headers })

      return res.data.learn_training
    },
    async updateTraining(id, cover_offset_left, cover_offset_top, title, description, content, cover, launchDate = Date.now(), timeZone, sendInvitation = false) {
      const headers = { "Content-Type": "multipart/form-data" };
      const res = await axiosService.put(`api/v1/learn/trainings/${id}`, {
        send_invitation: sendInvitation,
        description: description,
        title: title,
        launched_at: launchDate,
        time_zone: timeZone,
        learn_contents: content,
        cover: cover,
        cover_offset_left,
        cover_offset_top
      }, { headers })
    },
    async restoreTraining(id) {
      const { data } = await this.updateEntity(`api/v1/learn/trainings/${id}/restore`)

      this.learnTrainings.push(this.trashTrainings.find(training => training?.id === id))
      this.removeTrainingFromTrash(id)

      return data
    },
    async deleteTraining(id) {
      await this.deleteEntity(`api/v1/learn/trainings/${id}`)
    },
    async deleteTrainingPermanently(id) {
      await this.deleteEntity(`api/v1/learn/trainings/${id}/destroy_permanently`)
      this.removeTrainingFromTrash(id)

    },
    removeTrainingFromTrash (id) {
      this.trashTrainings = this.trashTrainings.filter(trash => trash?.id !== id)
    }
    ,
    async sendReminder(id) {
      await axiosService.post(`api/v1/learn/trainings/${id}/reminder`)
    }
  },
});
