<template>
  <div class="w-full h-full relative bg-surface ">
    <trash-warnning
      v-if="isTrashed"
      title="This template is in trash."
      @restore="restoreDeletedItem"
      @delete-permanently="deleteTemplate"
    />

    <div class="w-full h-full md:max-w-[500px] lg:max-w-[752px] xl:max-w-[752px] mx-auto flex flex-col gap-6 md:gap-6 xl:gap-10 px-5 md:!px-0 pt-5 md:pt-6 desktop:pt-10">
      <div class="w-full flex items-center justify-between">
        <div class="flex items-center gap-1">
          <div v-if="route?.params?.id" >
            <Icon
              icon="mingcute:edit-2-line"
              width="24"
              height="24"
              class="text-onSurface"
            />
          </div>

          <svn-pro-title h4 medium>
            {{ $t('Create report') }}
          </svn-pro-title>
        </div>

        <!-- Close template creation/edition & go back -->
        <svn-pro-icon-button
          icon="custom:mingcute:close-line"
          variant="text"
          color="onSurfaceVariant"
          @click="$router.push({name: 'reports'})"
          />
      </div>

      <div>
        <svn-pro-title h6 medium>
          {{ $t('Choose report type') }}
        </svn-pro-title>

        <div class="flex gap-2 items-start mt-5">
          <v-item-group mandatory class="w-full">
            <div class="w-full flex flex-col md:grid md:grid-cols-2 md:grid-rows-2 gap-4">
              <v-item
                v-for="item in getBoxes"
                :key="item?.title"
                v-slot="{ isSelected, toggle }"
                :value="item?.type"
              >
                <v-card
                  link
                  color="primary"
                  variant="outlined"
                  :disabled="item?.disabled"
                  class="w-full flex-1 border border-borderColor border-opacity-100"
                  :class="item?.selected ? 'border-primary bgOpacity' : 'border-corderColor'"
                  @click="updateReportType(toggle, item)"
                >
                  <template #default>
                    <div class="w-full h-full flex items-center gap-2 p-4 rounded">
                      <div class="flex w-full items-center gap-2">
                        <div>
                          <Icon
                            :icon="item?.icon"
                            height="40"
                            width="40"
                            class="text-onSurface"
                          />
                        </div>

                        <div class="flex flex-col items-start">
                          <svn-pro-text body-large medium>
                            {{ item?.title }}
                          </svn-pro-text>

                          <svn-pro-text caption color="onSurfaceVariant">
                            {{ item?.shortDesc }}
                          </svn-pro-text>
                        </div>
                      </div>

                      <div class="flex items-start self-stretch">
                        <svn-pro-radio-button :model-value="true" :value="item?.selected" color="primary"/>
                      </div>
                    </div>
                  </template>
                </v-card>
              </v-item>
            </div>
          </v-item-group>
        </div>
      </div>

      <div v-if="mode === 'analytics'" id="select_category_for_analyse" class="flex flex-col gap-4">
        <svn-pro-title h6 medium>
          {{ $t('Choose a category to analyse') }}
        </svn-pro-title>

        <svn-pro-select
          class="md:w-[368px] w-full"
          :error="isSelectedCategoryFilled"
          v-model="selectedCategory"
          :items="tagCategories"
          item-title="name"
          item-value="id"
          :label="$t('Select tag category*')"
          @update:model-value="selectCategory"
        >
          <template
            #selection="{item}"
          >
            <div class="w-full truncate">
              {{ item.title }}
            </div>
          </template>
        </svn-pro-select>
      </div>

      <div class="flex flex-col gap-4">
        <svn-pro-title h6 medium>
          {{ $t('Select campaign from following period') }}
        </svn-pro-title>

        <svn-pro-select
          class="md:w-[368px] w-full"
          v-model="selectedPeriod"
          :items="period"
          label=""
          item-title="display"
          item-value="value"
          :value="period[0]"
          @update:model-value="fetchCampaigns"
        >
          <template
            #selection="{item}"
          >
            <div class="w-full truncate">
              {{ item.title }}
            </div>
          </template>
        </svn-pro-select>
      </div>

      <div class="flex flex-col gap-10 ">
        <div class="flex flex-col gap-4">
          <svn-pro-title h6 medium>
            {{ $t('Select campaign(s)') }}
          </svn-pro-title>

          <!-- Search -->
            <svn-pro-text-field
            v-model="searchText"
            variant="outlined"
            :placeholder="$t('Search')"
            prepend-inner-icon="custom:mingcute:search-2-line"
            @update:model-value="fetchCampaigns"

          />

          <!-- Targets table -->
          <campaign-table
            :loading="loader"
            :search="searchText"
            :pagination="campaignPagination"
            :campaigns="campaigns"
            @fetch-new-page="fetchCampaigns"
          />
        </div>

        <div class="flex justify-content-center items-center">
          <svn-pro-button
            variant="flat"
            prepend-icon="custom:fluent-mdl2:generate"
            :text="$t('Generate Report')"
            @click="createReport"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, watch } from 'vue';
import {useReportStore} from "@/store/reports.js";
import {storeToRefs} from "pinia";
import {Icon} from "@iconify/vue";
import axios from "@/tools/axios-service.js";
import useDate from "../../../tools/useDate.js";
import {useRouter} from 'vue-router'
import i18n from '@/plugins/i18n';
import CampaignTable from "@/components/interviewApp/reports/InterviewReportCampaignTable.vue";
import { useSnackbar } from "@/store/snackbar.js";
import { debounce } from 'lodash';

const {subtractMonths} = useDate();
const { selectedCampaigns } = storeToRefs(useReportStore());
const snackbar = useSnackbar();

const loader = ref(false)

const boxes = ref([
  {
    icon: "noto:chart-increasing",
    mode: 'analytics',
    title: "Analytics",
    shortDesc: i18n.global.t("Selected datas already analysed with ratios."),
    selected: false,
  },
  {
    icon: "noto:card-index-dividers",
    mode: 'data',
    title: "Global datas",
    shortDesc: i18n.global.t("All informations about interviews in a campaign."),
    selected: false,
  },
  {
    icon: "noto:fountain-pen",
    mode: 'answers',
    title: "Answers",
    shortDesc: i18n.global.t("All answers submitted in campaigns."),
    selected: false,
  },
  {
    mode: 'roadmap',
    icon: "noto:bullseye",
    title: "Roadmap",
    shortDesc: i18n.global.t("Analyse completions of targets in your campaigns."),
    selected: false,
  },
]);

const getBoxes = computed(() => {
  return boxes.value.map((box) => {
    return {
      ...box,
      selected: mode.value == box.mode,
    };
  });
});

const getPassedYears = () => {
  let d = new Date("01 " + "January 2021");
  let first = d.getFullYear();

  let s = new Date();
  let second = s.getFullYear();
  let arr = Array();

  for (let i = first; i <= second; i++) {
    let val = {display: i, value: `${new Date("01 January " + i)}|${new Date("31 December " + i)}`}
    arr.push(val)
  }

  return arr.reverse()
}

const router = useRouter()
const mode = ref('analytics')
const tagCategories = ref()
const selectedCategory = ref(null)
const isSelectedCategoryFilled = ref(false)
const period = [
  {display: i18n.global.t("Ongoing"), value: new Date()},
  {display: i18n.global.t("Last 6 months"), value: `${subtractMonths(6)}|${new Date()}`}
]
  .concat(getPassedYears())
  .concat([{display: i18n.global.t("All time"), value: ""}])

const selectedPeriod = ref('')
const campaigns = ref(new Array())
const campaignPagination = ref({})
const searchText = ref('')
let processing = false

const updateReportType = (toggle, item) => {
  toggle()
  mode.value = item.mode
}

const getTagCategories = async () => {
  const res = await axios.get('/api/v1/companies/tag_categories', {
    params: {
      job_title: true,
      format: 'json'
    }
  })
  tagCategories.value = res.data.tag_categories
}

const fetchCampaigns = debounce(async (page = 1) => {
  loader.value = true
  const res = await axios.get('/api/v2/interview_app/campaigns', {
    params: {
      title: searchText.value,
      date: selectedPeriod.value,
      campaign_type: 'OneToOne',
      'page[number]': page
    }
  })
  campaigns.value = res.data.campaigns
  campaignPagination.value = res.data?.meta?.pagination
  loader.value = false
}, 300)


const selectCategory = (e) => {
  selectedCategory.value = e
}

const createReport = async () => {
  if ((mode.value === 'analytics' && (selectedCategory.value === null))) {
    isSelectedCategoryFilled.value = true
    document.getElementById('select_category_for_analyse').scrollIntoView({ behavior: 'smooth', block: 'center' })
    snackbar.setBgColor('onSurface')
    snackbar.setMsg(i18n.global.t('Please fill all required fields.'))
    snackbar.displaySnackBar()
    return;
  } else if (selectedCampaigns.value.length === 0) {
    snackbar.setBgColor('onSurface')
    snackbar.setMsg(i18n.global.t('Please select at least one campaign.'))
    snackbar.displaySnackBar()
    return;
  }
  if (processing === true) {
    return;
  }

  try {
    await axios.post('/api/v2/interview_app/reports', {
      interview_report: {
        mode: mode.value,
        tag_category_id: selectedCategory.value || '',
        campaign_ids: selectedCampaigns.value.join(',')
      }
    })

    router.push({name: 'reports'})
  } catch (e) {
  } finally {
    processing = true
  }
}
watch(selectedCategory, (newValue) => {
  if (newValue) {
    isSelectedCategoryFilled.value = false
  }
});

getTagCategories()
fetchCampaigns()
</script>
