export const fr = {
  '': '',
  'Coming soon': 'Bientôt disponible',
  'Your suggestion could not be created ! Please try again':
    "Votre suggestion n'a pas pu être créée ! Veuillez réessayer",
  'A few clicks away from accessing all your apps ✨':
    'À quelques clics de toutes vos applications ✨',
  'Loading ...': 'Chargement ...',
  Interviews: 'Interviews',
  'editor is empty': "l'éditeur est vide",
  'unauthorized duplicate option': 'option de duplication non autorisée',
  'editor is empty': "l'éditeur est vide",
  'unauthorized duplicate option': 'option de duplication non autorisée',
  'Select a manager': 'Sélectionnez un responsable',
  Deadline: 'Date limite',
  'Welcome on board !': 'Bienvenue à bord !',
  'Please enter your email.': 'Veuillez entrer votre email.',
  Email: 'Email',
  Continue: 'Continuer',
  'Connect with Google': 'Se connecter avec Google',
  Password: 'Mot de passe',
  'Reset password': 'Réinitialiser le mot de passe',
  'Fill the fields please': "Remplissez les champs s'il vous plaît",
  "This password doesn't match this email": 'Ce mot de passe ne correspond pas à cet email',
  "Email can't be blank": 'Email ne peut pas être vide',
  "Passwords fields aren't the same": 'Les champs de mots de passe ne sont pas identiques',
  'Sign in': 'Se connecter',
  'Oops, it seems this email is not registered 🧐':
    'Oups, il semble que cet email ne soit pas enregistré 🧐',
  'You may have miswritten your email, please try again.':
    'Vous avez peut-être mal orthographié votre email, veuillez réessayer.!',
  'Try again': 'Réessayer',
  'Contact Aleph support': "Contacter le support d'Aleph",
  Profile: 'Profil',
  Logout: 'Se déconnecter',
  Home: 'Accueil',
  'My interviews': 'Mes interviews',
  'My team interviews': 'Interviews de mon équipe',
  'My Team Interviews': 'Interviews de mon équipe',
  Campaigns: 'Campagnes',
  Templates: 'Modèles',
  Reports: 'Rapports',
  Learn: 'Learn',
  'no target value': 'pas de valeur cible',
  'possible answers': 'réponses possibles',
  Roadmaps: 'Roadmaps',
  'My roadmaps': 'Mes roadmaps',
  'My team roadmaps': 'Roadmaps de mon équipe',
  'My learn - coming soon': 'Mon apprentissage - Bientôt disponible',
  'My team learn - coming soon': "L'apprentissage de mon équipe - Bientôt disponible",
  People: 'Utilisateurs',
  'Welcome on Booklet': 'Bienvenue sur Booklet',
  "You are currently logged in company's workspace":
    "Vous êtes actuellement connecté sur l'espace de travail de {company}",
  Interview: 'Interview',
  'Annual reviews, onboardings, discovery reports and many more are all processed here.':
    'Les reviews annuels, onboardings, découvertes, rapports et bien plus sont répertoriés ici.',
  'Go to Interview': 'Aller à Interview',
  'Professional trainings, skill upgrades, and all kinds of contents in a single app.':
    'Formations professionnelles, amélioration des compétences, et toutes sortes de contenus dans une seule application.',
  Roadmap: 'Roadmap',
  'View, create and update all your targets and team targets. Follow their completion here.':
    'Voir, créer et mettre à jour tous vos objectifs et ceux de votre équipe. Suivez leur progression ici.',
  'Go to Roadmap': 'Aller à Roadmap',
  Information: 'Information',
  'Looking for tutos or information ?\n Find it all here.':
    "À la recherche de tutos ou d'information ?\n Retrouvez tout ici.",
  'Go to Information': 'Aller à Information',
  Reset: 'Réinitialiser',
  Next: 'Suivant',
  'Export all users': 'Exporter tous les utilisateurs',
  Search: 'Rechercher',
  'Default manager': 'Responsable par défaut',
  'Search tags': 'Rechercher des tags',
  'Add users': 'Ajouter des utilisateurs',
  'To do': 'À faire',
  'Access level': "Niveau d'accès",
  Firstname: 'Prénom',
  Lastname: 'Nom',
  Manager: 'Responsable',
  'Birth date': 'Date de naissance',
  'Hire date': "Date d'embauche",
  Gender: 'Sexe',
  'Gender-female': 'Femme',
  'Gender-male': 'Homme',
  'Gender-other': 'Autre',
  'Upload photo': 'Importer une photo',
  'Save changes': 'Sauvegarder les modifications',
  Born: 'Naissance',
  'Interviews done': 'Interviews faites',
  'Interviews todo': 'Interviews à faire',
  Completed: 'Complété',
  "Access to 'my interviews'": "Accès à 'mes interviews'",
  "Access to 'my team interviews'": "Accès à 'interviews de mon équipe'",
  'Can create campaigns': 'Peut créer une campagne',
  'Can view/edit campaigns': 'Peut voir/modifier des campagnes',
  'Only campaigns he/she created': "Seulement les campagnes qu'il/elle a créées",
  'Can create templates': 'Peut créer des templates',
  'Can view/edit templates': 'Peut voir/modifier des templates',
  'Only templates he/she created': "Uniquement les templates qu'il/elle a créés",
  'Can create reports': 'Peut créer des rapports',
  'Can view reports': 'Peut voir les rapports',
  'Only reports he/she created': "Uniquement les rapports qu'il/elle a créés",
  'Only for himself/herself': 'Uniquement pour lui-même/elle-même',
  'Only for himself/herself or team': 'Uniquement pour lui-même/elle-même ou son équipe',
  "Access to 'target'": "Accès à 'objectif'",
  'Only targets he/she created': "Uniquement les objectifs qu'il/elle à créés",
  "Access to 'employees'": 'Accès aux employés',
  'Can create targets': 'Peut créer des objectifs',
  "Access to 'my roadmaps'": "Accès à 'mes roadmaps'",
  "Access to 'my team roadmaps'": "Accès à 'roadmaps de mon équipe'",
  'View/edit his profile': 'Voir/modifier son profil',
  'View/edit other profile': "Voir/modifier d'autres profils",
  'Access to employee list': "Accès à la liste d'employés",
  'Only for his/her team': 'Uniquement pour son équipe',
  'Can create employees': 'Peut créer des employés',
  'Can remove employees': 'Peut supprimer des employés',
  'Can export employees': 'Peut exporter des employés',
  'Can edit access level': "Peut modifier le niveau d'accès",
  'No status to show': 'Aucun status à afficher',
  'Hello,': 'Bonjour,',
  'Here are your interviews as interviewee.':
    "Ici se trouvent vos interviews en tant qu'interviewé",
  'Interviews to submit': 'Interviews à envoyer',
  'Campaigns to submit': 'Campagnes à envoyer',
  'Ongoing to submit': "En cours d'envoi",
  'View my answers': 'Voir mes réponses',
  'Continue my interview': 'Continuer mon interview',
  'Continue self evaluation': 'Continuer mon auto évaluation',
  'Employee has not submitted interview': "L'employé n'a pas envoyé l'interview",
  'View employee answer': "Voir les réponses de l'employé",
  'Start Cross Review': 'Commencer la Cross Review',
  'Continue Cross Review': 'Continuer la Cross Review',
  'All interviews submitted': 'Toutes les interviews ont été envoyées',
  '2 interviews submitted, Cross Review in progress':
    '2 interviews envoyées, Cross Review en cours',
  '2 interviews submitted, Cross Review available':
    '2 interviews envoyées, Cross Review disponible',
  '1 interview submitted': '1 interview envoyée',
  '1 interview submitted and 1 interview en cours': '1 interview envoyée and 1 interview en cours',
  '1 interview in progress': '1 interview en cours',
  '2 interviews in progress': '2 interviews en cours',
  'No interview started': 'Aucune interview commencée',
  Now: 'Maintenant',
  'Last 6 months': '6 derniers mois',
  'This year': 'Cette année',
  'All time': 'Toutes',
  during: 'sur la période',
  Period: 'Période',
  Developer: 'Développeur',
  Chapters: 'Chapitres',
  'No deadline': 'Pas de date limite',
  'Edit deadline': 'Modifier la date limite',
  'Choose option': 'Choisir une option',
  'download pdf': 'Télécharger PDF',
  'Template used': 'Template utilisé',
  'View interviewee answers': "Voir les réponses de l'interviewé",
  Answers: 'Réponses',
  'In progress': 'En cours',
  'Not Completed': 'Non complété',
  From: 'De',
  To: 'À',
  'Not started': 'Non commencé',
  Submitted: 'Envoyé',
  'Not available yet': 'Pas encore disponible',
  'Not Available': 'Non Disponible',
  'Their different states are :': 'Leurs différents états sont :',
  'Up to 3 interviews can be requiered to submit :':
    "Jusqu'à 3 interviews peuvent être requises pour envoyer :",
  'Interviewee (IE), Interviewer (IR) and Cross Review (CR).':
    'Interviewé (IE), Intervieweur (IR) et Cross Review (CR).',
  Started: 'Commencé',
  'View answers': 'Voir les réponses',
  'Schedule a meeting': 'Plannifier un meeting',
  'Create a whole new': 'Créer un nouveau',
  'Add target': 'Ajouter un objectif',
  'Choose a target template': "Choisir un template d'objectif",
  'Use existing template': 'Utiliser un template existant',
  'New Target': 'Nouvel Objectif',
  '500 internal error please contact support': 'Erreur 500. Merci de contacter le support',
  'Change password': 'Changer le mot de passe',
  'Confirm your new password': 'Confirmer le nouveau mot de passe',
  'Remove selected users': 'Retirer les utilisateurs sélectionnés',
  'Are you sure you want to remove these People ?':
    'Êtes-vous sûr(e) de vouloir retirer ces utilisateurs ?',
  'This is a permanent action': 'Cette action est irréversible',
  'Yes, delete': 'Oui, supprimer',
  Yes: 'Oui',
  No: 'Non',
  'See interviewee profile': "Voir le profil de l'interviewé",
  'Set another interviewer': "Changer l'intervieweur",
  'Send invitation email': "Envoyer un email d'invitation",
  'Send reminder email': 'Envoyer un email de rappel',
  Unlock: 'Déverrouiller',
  Review: 'Review',
  'Edit campaign tags': 'Éditer les tags de la campagne',
  'Send email to participants': 'Envoyer un email aux participants',
  Delete: 'Supprimer',
  'My team interview': 'Interviews de mon équipe',
  'Add participant': 'Ajouter un participant',
  'Send invitation to all': 'Envoyer une invitation à tous',
  'Create campaign': 'Créer une campagne',
  'Do you wish to send invitations by email for this campaign ?':
    "Voulez-vous envoyer des emails d'invitation pour cette campagne ?",
  'Send invitations emails': "Envoyer des emails d'invitation",
  'No, thanks': 'Non, merci',
  'Yes, send by emails': 'Oui, envoyer',
  'Campaign dates': 'Dates de la campagne',
  'Campaign Participants': 'Participants',
  'Template(s) used': 'Modèle(s) utilisé(s)',
  'New report': 'Nouveau rapport',
  'Choose report type': 'Choisir le type de rapport',
  'Global data': 'Informations globales',
  'Global data contains all information about interviews (name, job title, completion, deadlines ...). You can handle results as you wish.':
    'Les informations globables contiennent toutes les données à propos des interviews (nom, poste, complétion, ...). Vous pouvez gérer les résultats à votre convenance.',
  'Analytics are selected datas already analysed with ratios. Very useful to see quickly how your campaigns are progressing.':
    "Les analytiques sont des données chiffrées avec des ratios. C'est très utile pour voir rapidement l'avancement de vos campagnes",
  'Create report': 'Créer un rapport',
  report: 'rapport',
  'Saving...': 'Mise à jour...',
  Saved: 'Sauvegardé',
  Template: 'Modèle',
  'Create template': 'Créer un modèle',
  'What type of template you want to create ?': 'Quel type de modèle souhaitez-vous créer ?',
  template: 'modèle',
  'All roadmaps': 'Objectifs des employés',
  'employee target': 'objectif associé à un employé',
  Targets: 'Objectifs',
  save: 'sauvegarder',
  'Target templates': "Modèles d'objectif",
  'New template': 'Nouveau modèle',
  'Create a template': 'Créer un modèle',
  Title: 'Titre',
  'Fill the title please': "Remplissez le titre s'il vous plait",
  Description: 'Description',
  'Type your text here': 'Écrivez votre texte ici',
  Indicator: 'Indicateur',
  'Create your password': 'Créez votre mot de passe',
  "Let's start": "C'est parti !",
  'Create password': 'Créer un mot de passe',
  'Check your mail 📩': 'Regardez votre mail 📩',
  'We just sent an email with your new password to the address :':
    "Nous venons d'envoyer un email avec votre nouveau mot de passe à l'adresse :",
  'Use it to create a new password. If you did not receive it, check your spams, or click on the button below to send it again.':
    "Utilisez le pour créer un nouveau mot de passe. Si vous ne l'avez pas reçu, vérifiez vos spams, ou cliquez sur le bouton ci-dessous pour l'envoyer à nouveau.",
  'Try Again': 'Réessayer',
  'Reset your password': 'Réinitialiser votre mot de passe',
  'Password confirmation': 'Confirmer le mot de passe',
  Menu: 'Menu',
  'Filter by tags': 'Filtrer par tags',
  'Tags are used to filter your index.': "Les tags sont utilisés pour filtrer l'index.",
  'No user available': 'Aucun utilisateur disponible',
  'Add new participant': 'Ajouter un nouveau participant',
  'Search the participant you wish to add': 'Recherchez le participant que vous souhaitez ajouter',
  'You can create, modify and delete tags to classify your items.':
    'Vous pouvez créer, modifier et supprimer des tags pour classer les éléments.',
  'Each tags can be contained in a category, click on':
    'Chaque tag peut être mis dans une catégorie, cliquez sur',
  'Create category': 'Créer une catégorie',
  'to create one.': 'pour en créer un.',
  'Click on': 'Cliquez sur',
  'on an item to manage its tags.': 'sur un élément pour gérer ses tags.',
  Back: 'Retour',
  Close: 'Fermer',
  'Move tags in category': 'Déplacer les tags dans la catégorie',
  'When a tag just has been created and add to an item,':
    "Quand un tag vient d'être créé et ajouté à un élément,",
  'it will appear in the category': 'il apparaitera dans la catégorie',
  Others: 'Autre',
  'You can change its category by clicking on the tag list':
    'Vous pouvez changer sa catégorie en cliquant sur la liste des tags',
  'you want. Then type the tag you want to move and select it.':
    'que vous voulez. Écrivez ensuite le tag que vous voulez déplacer et sélectionnez-le',
  "'s Interview with": 'Interview de {interviewee} avec {interviewer}',
  'Feedback of ': 'Feedback de {interviewee}',
  Interviewer: 'Intervieweur',
  Interviewee: 'Interviewé',
  Comments: 'Commentaires',
  Answer: 'Réponse',
  'no deadline': 'pas de date limite',
  'New target': 'Nouvel objectif',
  'Start my interview': 'Commencer mon interview',
  'No answer yet': 'Pas de réponse pour le moment',
  'View interviewer answer': "Voir les réponses de l'intervieweur",
  IE: 'IE',
  IR: 'IR',
  CR: 'CR',
  display: 'display',
  'Manage interviews': 'Gérer les interviews',
  Creator: 'Créateur',
  'Created at': 'Créé le',
  Type: 'Type',
  Downloads: 'Téléchargements',
  'Are you sure you want to delete this report ?<br/>(This is a permanent action)':
    'Êtes-vous sûr(e) de vouloir supprimer ce rapport ?<br/>(Cette action est irréversible)',
  'Deleting ...': 'Suppression ...',
  'InterviewApp::Questions::Chapter': 'Chapitre',
  'Chapter name': 'Nom du chapitre',
  'Multi choice': 'Choix multiple',
  'Multi choice name': 'Titre de la question',
  'Answer options': 'Options de réponses',
  Option: 'Option',
  'Add an option': 'Ajouter une option',
  'Allow comments': 'Autoriser les commentaires',
  'Open question': 'Question ouverte',
  'Open question name': 'Titre de la question',
  'InterviewApp::Questions::Rating': 'Notation',
  'Max rating': 'Note maximale',
  'Template tags': 'Tag du modèle',
  'Add tags to your template in order to search through templates efficiently and create accurate reports':
    'Ajoutez des tags à votre modèle pour pouvoir les rechercher efficacement dans les indexs et les rapports',
  'create the following tag': 'Créer le tag suivant',
  'Template type': 'Type de template',
  'Select the template type': 'Sélectionner le type de template',
  'These are people who have to answer this form during a campaign. Cross review is an additional step':
    'Ce sont les employés qui doivent répondre à ce formulaire durant une campagne. La Cross Review est une étape additionnelle',
  'Template name': 'Titre du template',
  'Video link (Youtube)': 'Lien vidéo (Youtube)',
  'This question is visible for': 'Cette question est visible pour',
  'Roadmap block name': 'Titre du bloc Roadmap',
  'Roadmap block': 'Bloc Roadmap',
  'Add this block so participants could create, edit and update their targets.':
    'Ajoutez ce bloc pour que les participants puissent créer, éditer et mettre à jour leurs objectifs.',
  'add Roadmap block': 'Ajouter un bloc Roadmap',
  'You can add only one Roadmap bloc in your template.':
    "Vous ne pouvez ajouter qu'un seul bloc Roadmap par modèle.",
  'It will appear at the end of your template.': 'Cela apparaitra en bas de votre modèle.',
  'Got it': "C'est compris",
  'remove Roadmap block': 'Retirer le bloc Roadmap',
  'see template preview': "Voir l'aperçu du modèle",
  'Template used: Interview title': "Modèle utilisé : Titre de l'interview",
  "employee name's Interview with interviewer name":
    "Interview de (nom de l'employé) avec (nom de l'intervieweur)",
  'Deadline : 00/00/0000': 'Date limite : 00/00/0000',
  'Edit template tags': 'Modifier les tags du modèle',
  Duplicate: 'Dupliquer',
  'Template title': 'Titre du modèle',
  Questions: 'Questions',
  'Last Update': 'Dernière modification',
  Tags: 'Tags',
  'Are you sure you want to delete this template ?<br/>(This is a permanent action)':
    'Êtes-vous sûr(e) de vouloir supprimer ce modèle ?<br/>(Cette action est irréversible)',
  Previous: 'Précédent',
  'Select a tag or create new one': 'Sélectionnez un tag ou créez-en un nouveau',
  Create: 'Créer',
  Save: 'Enregistrer',
  'Export users': 'Exporter les utilisateurs',
  Export: 'Exporter',
  'Downloading CSV file !': 'Téléchargement du fichier CSV !',
  'Your export file will be available in a few moments.':
    'Your export file will be available in a few moments.',
  'Great !': 'Super !',
  'Add a new user': 'Ajouter un nouvel utilisateur',
  Employee: 'Employé',
  'Manager Creator': 'Responsable Créateur',
  Admin: 'Admin',
  'Access Level': "Niveau d'accès",
  'Create & Send Invitation': "Créer et envoyer un mail d'invitation",
  'Please fill the firstname.': 'Veuillez remplir le prénom.',
  'Please fill the firstname, the lastname.': 'Veuillez remplir le prénom, le nom de famille',
  'Please fill the firstname, the email.': "Veuillez remplir le prénom, l'email.",
  'Please fill the lastname.': 'Veuillez remplir le nom de famille.',
  'Please fill the lastname, the email.': "Veuillez remplir le nom de famille, l'email.",
  'Please fill the email.': "Veuillez remplir l'email.",
  'User created successfully': 'Utilisateur créé avec succès',
  'Select another participant': 'Sélectionner un autre participant',
  'Choose the people you wish to add': 'Choisissez la personne que vous souhaitez ajouter',
  'Choose an interviewer from this campaign': 'Choisissez un intervieweur de cette campagne',
  'Choose an interviewer': 'Choisissez un intervieweur',
  'Select a people': 'Sélectionner des personnes',
  'Select an interviewer': 'Sélectionner un intervieweur',
  'Add people': 'Ajouter des personnes',
  'Select another interviewer': 'Sélectionnez un autre intervieweur',
  'Select another interviewee': 'Sélectionnez un autre interviewé',
  Apply: 'Appliquer',
  to: 'à',
  'Date and time': 'Date et heure',
  'Send an email to notify people and schedule meeting in Agenda':
    "Envoyer un email pour notifier la personne et créer un rendez-vous dans l'agenda",
  employee: 'employé',
  manager: 'responsable',
  'manager - creator': 'responsable - créateur',
  administrator: 'administrateur',
  'View Team Roadmap': 'Voir la Roadmap de son équipe',
  'View Team Roadmap of his/her manager': "Voir 'Roadmap de mon équipe' de son/sa responsable",
  'View Targets': "Voir 'Objectifs'",
  'actual value': 'Valeur actuelle',
  'target value': 'Valeur cible',
  'Archive target': "Archiver l'objectif",
  'Unarchive target': "Désarchiver l'objectif",
  Completion: 'Complétion',
  'target has been archived': "l'objectif a été archivé",
  'target has been unarchived': "l'objectif a été désarchivé",
  'target as been deleted': 'objectif a été supprimé',
  'Starting value': 'Valeur de départ',
  'Current value': 'Valeur actuelle',
  'Target value': 'Valeur cible',
  'Update answer': 'Mettre à jour la réponse',
  'Target title': "Titre de l'objectif",
  'See details': 'Voir les détails',
  'True/False': 'Vrai/Faux',
  'Numeric value': 'Valeur numérique',
  Percentage: 'Pourcentage',
  'Last events': 'Dernières modifications',
  Switch: 'Changement',
  Validated: 'Validé',
  'Not validated': 'Non validé',
  'Option changed': 'Option changée',
  'View changes': 'Voir les changements',
  'See all updates': 'Afficher toutes les modifications',
  'Hide all updates': 'Cacher toutes les mises à jours',
  'Due date': 'Date butoire',
  'Last update': 'Dernière modification',
  'For non quantifiable objectives, you can choose the value of the two booleans.':
    'Pour les objectifs non quantifiables, vous pouvez choisir les valeurs du vrai/faux.',
  'Define the two words you want to measure the objective. Word 1 will be the\ndefault value, then you could switch to word 2.':
    "Définissez les 2 mots pour mesurer l'objectif. Le mot 1 sera\nla valeur par défaut, puis vous pourrez le changer pour le mot 2.",
  'For quantifiable objectives, choose a value (persons, money, apples, ...), a start and target value.':
    "Pour les objectifs quantifiables, choisissez une valeur (employés, argent, pommes, ...), une valeur de départ et une valeur d'arrivée.",
  'Define a starting and a target value to measure the objective completion.':
    "Définissez une valeur de départ et d'arrivée pour mesurer la complétion de l'objectif.",
  'Useful to measure easily a quantifiable objective.':
    'Utile pour mesurer facilement un objectif quantifiable.',
  'Reset password': 'Réinitialiser le mot de passe',
  'Hey, password and password confirmation must be equal':
    'Le mot de passe et sa confirmation doivent être identiques',
  'Use a percentage based value to measure the objective completion.':
    "Utilisez une valeur en pourcentage pour mesurer la complétion de l'objectif.",
  'Please type and confirm your new password.':
    'Merci de définir et confirmer votre nouveau mot de passe.',
  'For non quantifiable objectives, you can add and edit choices to rate or qualify your objective.':
    'Pour les objectifs non quantifiables; vous pouvez ajouter et éditer des options de réponses pour noter ou qualifier un objectif.',
  'Add options to qualify the objective completion.':
    "Ajoutez des options pour qualifier la complétion de l'objectif.",
  'Processing...': 'Traitement...',
  'Hey ! It seems there is no': 'Hey ! Il semble qu’il n’y ait aucun',
  'to show,': 'à afficher,',
  'how about create your first ?': 'voulez-vous en créer un premier ?',
  'Create my first': 'Créer mon premier',
  'Oops, it seems no item can be found with the tags ':
    "Oups, il semble qu'aucun élément ne puisse être trouvé avec les tags",
  'Oops, it seems no item can be found with the status':
    "Oups, il semble qu'aucun élément ne puisse être trouvé avec le status",
  'Oops, it seems no item can be found with the user categories.':
    "Oups, il semble qu'aucun élément ne puisse être trouvé avec la catégorie d'utilisateur.",
  'Oops, it seems no item can be found with the search':
    "Oups, il semble qu'aucun élément ne puisse être trouvé avec la recherche",
  'Please try something else.': 'Veuillez essayer autre chose.',
  'Archived targets': 'Objectifs archivés',
  'Current targets': 'Objectifs en cours',
  'Move an existing tag to this category': 'Déplacer un tag existant dans cette catégorie',
  "This tag doesn't exist.": "Ce tag n'existe pas.",
  'Select existing tag': 'Sélectionnez un tag existant',
  Rename: 'Renommer',
  'Are you sure you want to delete this category ?':
    'Êtes-vous sûr(e) de vouloir supprimer cette catégorie ?',
  "Tags from this category won't be delete, they will move to the category 'Other'.":
    "Les tags de cette catégorie ne seront pas supprimés, mais déplacés dans la catégorie 'Autres'.",
  'Interviewee (IE)': 'Interviewé (IE)',
  'Interviewer (IR)': 'Intervieweur (IR)',
  Both: 'Tous les deux',
  'Cross review': 'Cross review',
  'My Roadmap': 'Ma Roadmap',
  'My Team Roadmaps': 'Roadmaps de mon équipe',
  'Information updated': 'Information(s) mise(s) à jour',
  'This email address already exist in our database':
    'Cet email existe déjà dans notre base de données',
  'Your password have to be at least 8 characters long.':
    'Votre mot de passe doit faire au minimum 8 caractères de long.',
  'You must confirm your password !': 'Vous devez confirmer votre mot de passe !',
  "Passwords aren't the same !": 'Les mots de passe ne sont pas les mêmes !',
  Name: 'Nom',
  'Job Title': 'Poste',
  'job title': 'poste',
  'All employees in the categories below': 'Tous les employés dans les catégories ci-dessous',
  'Access Preset': 'Accès',
  'Choose interviewee(s) you want to participate on this campaign':
    'Choisissez le(s) interviewé(s) qui participent à cette campagne',
  'Selected People': 'Personnes sélectionnées',
  'Choose how you want to add interviewer': 'Choisissez comment ajouter les intervieweurs',
  Manual: 'Manuel',
  Auto: 'Automatique',
  'Choose participants from your people list': 'Choisissez les participants dans la liste',
  'Direct manager - Select a default interviewer':
    'Responsable direct - Choisissez un intervieweur par défaut',
  'In case of interviewees not linked to a manager in the database,':
    'Dans le cas où certains interviewés ne sont liés à aucun responsable dans la base de données,',
  'please choose a default interviewer': 'veuillez choisir un intervieweur par défaut',
  'The interviewer will handle all interviews for selected participants':
    'Cet intervieweur va faire tout les entretiens pour les ',
  'Select the Interviewer': "Sélectionnez l'intervieweur",
  'Direct manager': 'Responsable direct',
  'Choose how you want to display template(s)': 'Choisissez comment répartir les modèles',
  'Some tags have no template assigned.': "Des tags n'ont pas de modèles assigné.",
  'If you continue, the default template will be assigned to those tags':
    'Si vous continuez, un modèle par défaut leur sera assigné',
  'Assign templates': 'Assigner les modèles',
  'Select a default template': 'Sélectionnez un modèle par défaut',
  'Choose a specific user as the campaign sole interviewer':
    'Choisissez un utilisateur en particulier qui sera le seul intervieweur cette campagne',
  'Choose how you want to add participants':
    'Choisissez comment vous souhaitez ajouter les participants',
  'Select participants from your people list': 'Sélectionnez les participants dans la liste',
  'Multiple templates - Category': 'Modèles multiples - Catégories',
  'Choose a category to assign different templates to this campaign.':
    "Choisissez d'abord une catégorie",
  'Select a category': 'Sélectionner une catégorie',
  'Multiple templates - Assign templates': 'Modèles multiples - Assigner les modèles',
  'Select a template for each tag. You also have to choose a default template for participants not assigned to either of these tags.':
    'Choisissez un modèle par tag, les tags non assignés auront le modèle par défaut',
  'Add participants by their arrival date in the company':
    "Ajouter les participants par leurs dates d'arrivée",
  'Please choose a category first': 'Choisissez une categorie',
  'Your campaign summary': 'Sommaire de la campagne',
  'In case of interviewees not linked to a manager in the database, please choose a default interviewer':
    "Dans le cas où un interviewé n'a pas de responsable, sélectionnez un intervieweur par défaut",
  'Apply dates for your campaign': 'Déterminer les dates de la campagne',
  'Deadline is only informative, reviews can be done even if the deadline is outdated.':
    'La date limite est seulement à titre indicatif, les entretiens peuvent être faits même si la date limite est dépassée.',
  'Campaign deadline :': 'Date limite de la campagne :',
  Settings: 'Paramètres',
  Participants: 'Participants',
  'Review & Launch': 'Revue & Lancement',
  'Looking for interviewer': "Recherche de l'intervieweur",
  'Multiples templates': 'Modèles multiples',
  'Are you sure you want to delete this campaign draft ?<br/>(This is a permanent action)':
    'Êtes-vous sûr(e) de vouloir supprimer ce brouillon ? <br/>(Cette action est irréversible)',
  'Choose a campaign name': 'Choisissez un titre pour la campagne',
  'Define your campaign period': 'Choisissez la période',
  'Starts on': 'Démarre le',
  'The campaign will become available from the start date.': 'La campagne démarrera à cette date.',
  'Pictures and names of participants are anonymised.':
    'Les photos et les noms des participants sont anonymisés.',
  'Ends on': 'Finit le',
  'The end date is purely informative and the campaign will remain available thereafter.':
    "Le date de fin est à titre d'information; la campagne restera ouverte",
  'End date cannot be anterior to start date !':
    'La date de fin ne peut pas être antérieure à la date de début !',
  'Choose interviewee(s) you want to participate this campaign': 'Choisissez les participant(s)',
  'Select users': 'Sélectionnez les utilisateurs',
  'Users are loading...': 'Chargement des utilisateurs...',
  'New campaign': 'Nouvelle campagne',
  Current: 'Actuel',
  'Unlock Cross Review (CR)': 'Déverrouiller la Cross Review (CR)',
  'Remove from campaign': 'Retirer de la campagne',
  'Invitation is on its way !': "L'invitation est en chemin !",
  'The employee will receive an email in a few moments.':
    "L'employer va recevoir un email dans un instant.",
  'Reminder is on its way !': 'Le rappel est en chemin !',
  'Are you sure you want to remove this participant ?':
    'Êtes-vous sûr(e) de vouloir retirer cet utilisateur ?',
  'Are you sure you want to remove this participant ?<br/>(This is a permanent action)':
    'Êtes-vous sûr(e) de vouloir retirer cet utilisateur ? <br/>(Cette action est irréversible)',
  'Campaign title': 'Titre de campagne',
  'Are you sure you want to send emails to all participants ?':
    'Êtes-vous sûr(e) de vouloir envoyer des emails à tous les utilisateurs ?',
  'All participants will receive an email about this campaign.':
    'Tous les utilisateurs vont recevoir un email concernant cette campagne.',
  'Sending ...': "En cours d'envoi ...",
  'Yes, do it': 'Oui !',
  'The participants will receive an email in a few moments.':
    "L'utilisateur va recevoir un email dans un instant.",
  submit: 'envoyer',
  'Submit confirmation': 'Envoyer la confirmation',
  'Are you sure to submit your answers?': 'Êtes-vous sûr(e) de vouloir envoyer vos réponses ?',
  'Your answers are going to be locked!': 'Votre intretien va être bloqué!',
  'I accept': "J'accepte",
  'Your interview has been submitted, your answers are locked.':
    'Votre interview a été envoyée, vos réponses sont bloquées.',
  "See 's profile": 'Profil de {reviewers}',
  Analytics: 'Analytiques',
  'Answers are all answers submited in campaigns': 'Les réponses sont toutes envoyées',
  'Choose a Category to analyze': 'Choisissez une catégorie à analyser',
  'Select tag category*': 'Sélectionnez un tag de catégorie*',
  'Select campaign from following period': 'Sélectionner une campagne',
  'Select All': 'Tout selectionner',
  'Create Report': 'Créer un raport',
  'look at the tags you selected': 'Inspectez les tags que vous avez sélectionnés',
  'Only the interviewee will answer this template. The interviewer can still view the answers.':
    "Seul les interviewés répondront. L'intervieweur pourra toujours voir les réponses.",
  'Only the interviewer will answer this template.':
    "Seul l'intervieweur répondra. L'interviewé pourra toujours voir les réponses.",
  "Both can answer this template. Only the interviewer can view the interviewee's answers.":
    "Les deux peuvent répondre à ce modèle. Seul l'intervieweur peut voir les réponses de l'interviewé",
  'Interviewee + Interviewer': 'Interviewé + Intervieweur',
  'Cross Review': 'Cross Review',
  'In addition of the both template, the Cross Review is a last step for a final version.':
    "En plus du modèle interviewé + intervieweur, la Cross Review est la dernière étape où les deux utilisateurs peuvent se mettre d'accord sur la version finale.",
  'Here are your interviews as interviewer.':
    "Vos interviews en tant qu'intervieweur se trouvent ici.",
  'Edit target': 'Éditer objectif',
  'Create a target': 'Créer un objectif',
  'Target informations': "Information d'objectif",
  'Target Indicator': "Indicateur d'objectif",
  'Option 1 (target value)': "Option 1 (valeur d'objectif)",
  'You need to set at least one employee': 'Vous devez selectionner au moins un employé',
  Target: 'Objectif',
  'Employee Roadmap': "Roadmap de l'employé",
  'My team Roadmap': 'Roadmaps de mon équipe',
  'Team Roadmap': 'Roadmap équipe',
  'An error occurred when google gave us back your data': "Une erreur s'est produite",
  "Couldn't connect sorry...": "Nous n'arrivons pas à nous connecter, désolé...",
  'You have no token come back later': "Vous n'avez pas de token, réessayez plus tard",
  'Hey, password and password confirmation must be equal':
    'Hey, les deux mots de passe doivent être identiques',
  'Send it again': 'Renvoyer',
  'No email mode': 'Pas de mode email',
  "UH OH! You're lost.": 'UH OH! Vous êtes perdu.',
  HOME: 'ACCUEIL',
  'The page you are looking for does not exist.': "La page que vous recherchez n'existe pas.",
  'How you got here is a mystery. But you can click the button below':
    'Vous êtes arrivé pas hasard. Mais cliquez sur le bouton en dessous',
  'to go back to the homepage.': 'pour retourner en arrière.',
  Language: 'Langue',
  'Try to finish this step first': 'Il faut remplir toutes les étapes',
  Data: 'Données',
  'AS INTERVIEWEE': "EN TANT QU'INTERVIEWÉ",
  'Total number of interviews as interviewee done':
    "Nombre total d'interviews terminées en tant qu'interviewé",
  'AS INTERVIEWER': "EN TANT QU'INTERVIEWEUR",
  'Total number of interviews as interviewer done':
    "Nombre total d'interviews terminées en tant qu'intervieweur",
  'Interviews in progress': 'Interviews en cours',
  'Interviews not started': 'Interviews pas démarrés',
  'AS EMPLOYEE': "EN TANT QU'EMPLOYÉ",
  'Total number of targets as employee done': "Nombre total d'objectifs faits par l'employé",
  'AS MANAGER': 'EN TANT QUE RESPONSABLE',
  'Total number of targets as manager done': "Nombre total d'objectifs faits par le responsable",
  'Targets completed': 'Objectifs complétés',
  'Targets not completed': 'Objectifs incomplets',
  'GLOBAL INFORMATIONS': 'INFORMATIONS GLOBALES',
  'create +': 'créer +',
  PASSWORD: 'MOTS DE PASSE',
  'Set another password': 'Définir un nouveau mot de passe',
  'New password': 'Nouveau mot de passe',
  Cancel: 'Annuler',
  'Ignore modifications ?': 'Igrorer les modifications ?',
  'If you continue,': 'Si vous poursuivez,',
  'you will lose them.': 'vous allez tout perdre.',
  Leave: 'Quitter',
  'Keep editing': 'Continuer à éditer',
  'This question is required for': 'Cette question est obligatoire pour',
  'Type your campaign name here': 'Écrivez nom de votre campagne ici',
  comments: 'commentaires',
  Uncompleted: 'Incomplet',
  'Completion updated': 'Complétion mise à jour',
  'Every participants have the same template.': 'Tout les participants ont le même modèle',
  'Define dynamic templates depending on participants informations':
    'Définir des modèles dynamiques en fonction des informations des participants',
  'Single template': 'Modèle unique',
  'Multiple templates': 'Plusieurs modèles',
  'You will see indicator once the target is created':
    "L'indicateur de complétion sera visible après la création de l'objectif",
  Add: 'Ajouter',
  Added: 'Ajouté(e)',
  Remove: 'Supprimer',
  'Manage reviewers': 'Gérer les évaluateurs',
  not_started: 'non commencé',
  in_progress: 'en cours',
  submitted: 'envoyé',
  not_available_yet: 'pas encore disponible',
  'See users profile': "Voir le profil de l'utilisateur",
  'Choose reviewer': 'Choisir le(s) évaluateur(s)',
  'Choose reviewers before continue.': 'Choisir des évaluateurs avant de continuer.',
  'Consult answers': 'Consulter les réponses',
  'Start answering': 'Commencer à répondre',
  'Manage reviewer': "Gérer l'évaluateur",
  'Select another person in charge': 'Sélectionner un autre responsable',
  'Delete content': 'Supprimer le contenu',
  'New contents to discover !': 'De nouveaux contenus à découvrir !',
  'New contents have been added to the catalog, go take a look to it.':
    'De nouveaux contenus ont été ajoutés au catalogue, jetez-y un oeil.',
  'Check new content': 'Voir les nouveaux contenus',
  'Must do contents': 'Contenus à faire obligatoirement',
  'You have - more must do content, remember to complete them !':
    'Vous avez encore {count} contenus à faire, pensez à les compléter !',
  'See my must do': 'Voir mes contenus à faire',
  'Contents acquired': 'Contenus acquis',
  Favorites: 'Favoris',
  'Want to learn more ?': "Envie d'apprendre davantage ?",
  'Find a content': 'Trouver un contenu',
  'Must do': 'À faire',
  Acquired: 'Acquis',
  Drafts: 'Brouillons',
  Catalog: 'Catalogue',
  'What will be your next skill, User ? 😎':
    'Quelles seront vos prochaines compétences, {firstname} ? 😎',
  'Link copied to clipboard !': 'Lien copié dans le presse-papier !',
  'Search content': 'Chercher un contenu',
  'Name your content': 'Nommez votre contenu',
  'Back to catalog': 'Retourner au catalogue',
  Confirm: 'Confirmer',
  "You can try this quiz as much as you want. Having all correct answers will make this content acquired for you. Let's up your right answers rate at 100% !":
    'Vous pouvez réaliser ce quiz autant de fois que vous le souhaitez. Le contenu sera considéré comme acquis une fois toutes les bonnes réponses trouvées. Atteignons 100% de bonnes réponses !',
  'Maybe later': 'Peut-être plus tard',
  "Let's retry now !": 'Réessayer !',
  Publish: 'Publier',
  Module: 'Module',
  'New content': 'Nouveau contenu',
  'You can still access every other contents': 'Vous pouvez toujours accéder aux autres contenus',
  Upload: 'Télécharger',
  'Or drag the file here': 'Ou déposez un fichier ici',
  'Create a module': 'Créer un module',
  Start: 'Commencer',
  'Choose an evaluation type': "Choisissez un type d'évaluation",
  'Must do content': 'Contenu à faire obligatoirement',
  "Choose a population who will see this content in their 'Must know' section.":
    "Choisissez les personnes qui verront ce contenu dans leur section 'À connaitre impérativement'",
  'Specific people': 'Utilisateurs spécifiques',
  Category: 'Catégorie',
  'Choose or create the categories this content is from.':
    "Choisissez ou créez les catégories d'où vient ce contenu",
  'Acquired this month': 'Acquis ce mois',
  'Understand eveything about modules': 'Tout comprendre sur les modules',
  'A module is a page where all informations about the content is available. Select differents elements like':
    "Un module est une page dans laquelle toutes les informations à propos d'un contenu sont disponibles. Séléctionnez des éléments comme",
  titles: 'titres',
  Titles: 'Titres',
  texts: 'textes',
  Text: 'Texte',
  pictures: 'images',
  Pictures: 'Images',
  videos: 'vidéos',
  Videos: 'Vidéos',
  'to build it': 'pour le construire',
  'Are you sure you want to delete this content ?':
    'Êtes-vous sûr(e) de vouloir supprimper ce contenu ?',
  '(This is a permanent action)': '(Cette action est irréversible)',
  'How do I choose an evaluation for my content ?':
    'Comment choisir une évaluation pour mon contenu ?',
  'You have 2 options to evaluate your content.': 'Vous avez 2 options pour évaluer votre contenu.',
  'First option is': 'La première option est',
  Approval: 'Approbation',
  "Employee must answer 'Do you fully learned this content ?' by Yes or No":
    "L'employé doit répondre à 'Avez-vous complètement appris ce contenu ?' par Oui ou Non",
  'Second option is a': 'La seconde option est un',
  Quiz: 'Quiz',
  'you created.': 'que vous avez créé.',
  'Content is considere as acquired if employee reach 100% of right answers':
    "Le contenu est considéré comme aquis si l'employé atteint 100% de bonnes réponses",
  'Read the module and tell us if it is acquired here :':
    "Lisez le module et dites-nous s'il a été aquis ici :",
  'Created by': 'Créé par',
  'Last update on _ at _': 'Dernière mise à jour le {date} à {time}',
  'Start the quiz': 'Commencer le quiz',
  'Edit quiz': 'Modifier le quiz',
  'Change for Approval': 'Approbation',
  'You have chosen Approval evaluation.': "Vous avez choisi l'évaluation Approbation.",
  'You have chosen Quiz evaluation.': "Vous avez choisi l'évaluation Quiz.",
  'Change for Quiz': 'Choisir le Quiz',
  'Answer this quiz to acquire this content': 'Répondez à ce quiz pour acquérir ce contenu',
  'Access the module': 'Accéder au module',
  '🙌 You’ve reached the end !': '🙌 Vous avez atteint la fin!',
  'You can now evaluate yourself with a quiz on the module. Are you ready yet ?':
    'Vous pouvez maintenant vous évaluer grâce au quiz sur le module. Êtes-vous prêt ?',
  'Close module': 'Fermer le module',
  'I did not learn this module yet': "J'ai déjà appris ce module",
  "I didn't learned this module yet": "Je n'ai pas encore appris ce module",
  'I fully understood it !': "Je l'ai complètement compris !",
  'Do you fully learned this module ?': 'Avez-vous complètement appris ce module ?',
  'Tell us if you considere this content acquiered, or if you need to take another look at the content. Of course you will still have access to this module.':
    "Dites-nous si vous considérez que vous avez aquis ce module, ou si avez besoin d'y jeter un autre coup d'oeil. Vous aurez évidement encore accès à ce module.",
  'Of course !': 'Bien sûr !',
  'Manage Reviewers': 'Gérer les évaluateurs',
  'Display all managers...': 'Afficher tous les responsables...',
  Summary: 'Sommaire',
  'Right answer(s)': 'Bonne(s) Réponse(s)',
  'Confirm quiz and see answers': 'Confirmer le quiz et voir les réponses',
  'Missing answer': 'Il manques la réponse',
  '👌 Here is one more acquired content': '👌 Il y a un ou plusieurs contenu(s) acquis',
  'Congratulations, you just acquiere a new content, keep going like this ! It will still be accessible in your acquired contents section.':
    "Félicitations, vous venez d'acquérir un nouveau contenu, continuez comme ça ! Il sera toujours accessible depuis la section des contenu acquis.",
  'Back to content': 'Retour au contenu',
  '💪 Would you like to try again ?': '💪 Voulez vous réessayer ?',
  'You have no must do for the moment, come later to see what’s new !':
    "Vous n'avez pas de contenus à faire obligatoirement pour le moment, revenez plus tard pour voir ce qu'il y a de nouveau !",
  'Sort by': 'Trier par',
  'Enter a title': 'Entrer un titre',
  'Edit the module': 'Modifier le module',
  'Choose parameters': 'Choisissez les paramètres',
  'Participants can choose who will give feedback during this campaign. The person in charge of the Feedback 360 can manage reviewers at any time.':
    'Les participants peuvent choisir qui leur donnera des feedbacks durant cette campagne. La personne en charge de Feedback 360 peut modifier les évaluateurs à tout moment.',
  'Self evaluation': 'Auto-évaluation',
  'Participants can answer questions too.':
    'Les participants peuvent aussi répondre aux questions.',
  'Participants view reviewers answers':
    'Les participants peuvent voir les réponses des évaluateurs',
  'Participants is able to view answers from their reviewers. Otherwise, only the person in charge of the feedback could see answers.':
    'Les participants peuvent voir les réponses de leurs évaluateurs. Autrement, seule la personne en charge des feedbacks peut voir les réponses.',
  'Campaign will end on :': 'La campagne se terminera le :',
  'Campaign will start on :': 'La campagne commencera le :',
  'Select a date': 'Choisissez une date',
  'Participants receive feedbacks from their reviewers':
    'Les participants reçoivents des feedbacks de leurs évaluateurs',
  'Interview between in interviewer and interviewee':
    'Interview entre un intervieweur et un interviewé',
  'Choose a campaign type': 'Choisissez un type de campagne',
  'Set your campaign': 'Configurez votre campagne',
  'Select the participants': 'Sélectionner les participants',
  'Set your template(s)': 'Sélectionnez le(s) modèle(s)',
  'Launch your campaign !': 'Lancer votre campagne !',
  'Choose participants': 'Choisissez les participants',
  'Search for participants': 'Rechercher des participants',
  'Selected participants : ': 'Participants séléctionnés : ',
  'Choose who will be in charge of the Feedback 360':
    'Choisissez qui sera en charge de ce Feedback 360',
  'Choose who will be in charge of the Survey': 'Choisissez qui sera en charge de ce Sondage',
  'Answers will be visible in the campaign in the section ‘Answers’':
    'Les réponses seront visibles dans la campagne dans la section ‘Réponses’',
  'All participants will have this template for the survey':
    'Tous les participants auront ce modèle pour le sondage',
  'Designate any user that will be in charge of the campaign.':
    'Désignez un utilisateur qui sera en charge de la campagne.',
  'Specific person': 'Personne spécifique',
  'Direct Manager': 'Responsable direct',
  'Managers from database are in charge of the interview.':
    "Les responsables dans la base de données de seront en charge de l'interview",
  'Choose a default manager': 'Choisissez un responsable par défaut',
  'Select any employee': "Sélectionnez l'employé de votre choix",
  'In case of interviewees are not linked to a manager in the database, please choose a default interviewer':
    'Dans le case où des interviewés ne sont pas liés à des managers dans la base de données, veuillez sélectionner un intervieweur par défaut',
  'Default template': 'Modèle par défaut',
  'Summary of your campaign': 'Résumé de votre campagne',
  'Campaign Type': 'Type de campagne',
  'Launch campaign': 'Lancer la campagne',
  'Campaign period': 'Période de campagne',
  'Person in charge': 'Personne en charge',
  'Default person in charge': 'Personne en charge par défaut',
  'Participants choose their reviewers': 'Les participants choisissent leurs évaluateurs',
  'Question between an interviewer and interviewee.':
    'Questions entre un intervieweur et un interviewé.',
  'Collect feedbacks from employees pairs.': 'Récupérer les feedbacks des employées évaluateurs',
  'Who will answer this template?': 'Qui répondra à ce template',
  'Interviewee only': "Seulement l'interviewé",
  'Interviewer only': "Seulement l'intervieweur",
  'Both (Interviewee + Interviewer)': 'Les deux (Interviewé + Intervieweur)',
  'Cross Review (Both can answer + a conclusion step for final answers)':
    'Crosse Review (Les deux peuvent répondre + une étape de conclusion pour les réponses finales)',
  Reviewers: 'Évaluateurs',
  'If requirement is enable, participants will have to answer it before submit':
    "Si 'requis' est actif, les participants devront répondre avant de pouvoir l'envoyer",
  Required: 'Requis',
  'No reviewers selected': 'Aucun évaluateur sélectionné',
  'Hmm, It seems there is no reviewers selected, search for pairs and add them.':
    "Hmm, il semblerait qu'il n'y ait pas d'évaluateur sélectionné. Recherchez des évaluateurs et ajoutez les.",
  'Display all users...': 'Afficher tous les utilisateurs',
  'The participant are able to view answers from their reviewers. Otherwise, only the person in charge of the feedback could see answers.':
    'Les participants sont capables de voir les réponses de leurs évaluateurs. Autrement, uniqument la personne en charge du feedback peut voir les réponses.',
  'The participant view reviewers answers': 'Le participant voit les réponses des évaluateurs',
  'The participant can choose who will give feedback during this campaign. The person in charge of the Feedback 360 can manage reviewers at any time.':
    "Le participant peut choisir ceux qui vont donner un feedback durant cette campagne. La personne en charge du Feedback 360 peut gérer les évaluateurs à n'importe quel moment.",
  'The participant has to answer the feedback questions too.':
    'Le participant doit également répondre aux questions du feedback.',
  'Enter a text': 'Entrez un texte',
  'Allow the participant to view reviewer answers':
    'Autorise le participant à voir les réponses des évaluateurs',
  'The participant is able to view answers from his/her reviewers. Otherwise, only the person in charge of the feedback could see answers.':
    'Le participant peut voir les réponses de ses évaluateurs. Autrement, uniquement la personne en charge du feedback peut voir les réponses',
  'Allow the participant to choose his/her reviewer':
    'Autorise le participant à choisir son évaluateur',
  'Anonymise answers': 'Anonymiser les réponses',
  'People viewing answers': 'Utilisateurs qui consultent les réponses',
  'In case of participants are not linked to a manager in the database, please choose a default person in charge':
    'Dans le cas où des participants ne sont pas liés à un manager dans la base de données, veuillez choisir une personne en charge par défaut',
  'Set another person in charge': 'Sélectionner une personne en charge',
  'Choose reviewers': 'Choisir des évaluateurs',
  'Hey, please fill, default interviewer': 'Hey, choisissez un intervieweur par défaut',
  Overview: "Vue d'ensemble",
  'Interview sets completion - All': 'Complétion des Interviews - Tous',
  'Feedback completion - All': 'Complétion des Feedbacks - Tous',
  'Survey completion - All': 'Complétion des Sondages - Tous',
  'Quick view of your campaign completion': 'Aperçu de la complétion de votre campagne',
  'Interview completion - Category': 'Complétion des Interviews - Catégorie',
  'Feedback completion - Category': 'Complétion des Feedbacks - Catégorie',
  'Survey completion - Category': 'Complétion des Sondages - Catégorie',
  'Compare completion by categories': 'Comparez la complétion par catégories',
  'Interview sets completion - Individual': 'Complétion des Interviews - Individuel',
  'Secondary text': 'Texte secondaire',
  'Send reminder to all': 'Envoyer un rappel à tous',
  'See participant profile': 'Voir le profil du participant',
  '+ Add participant': '+ Ajouter un participant',
  Survey: 'Sondage',
  'Do not participate': 'Ne pas participer',
  'Start the survey': 'Commencer le sondage',
  'Continue the survey': 'Continuer le sondage',
  'If you confirm, you will not be able to participate anymore.':
    'Si vous confirmez, vous ne serez plus en mesure de participer.',
  'This survey will no longer appear in your ': "Ce sondage n'apparaîtra plus dans votre section ",
  'section.': 'section',
  'Yes, confirm': 'Oui, confirmer',
  'Deadline : ': 'Date limite : {date}',
  See: 'Voir le profil de {pairs}',
  'Error deleting interview !': "Erreur lors de la suppression de l'interview !",
  Pinned: 'Attaché',
  answer: 'réponse',
  answers: 'réponses',
  'Add a comment': 'Ajouter un commentaire',
  You: 'Vous',
  'No answers yet': 'Pas encore de réponses',
  'No comments yet': 'Pas encore de commentaires',
  'Survey - x participants':
    'Sondage - 0 participant | Sondage - 1 participant | Sondage - {count} participants',
  'Completion of each steps of the interview': "Complétion de chaque étape de l'interview",
  'This survey will no longer appear in your My interviews section.':
    "Ce sondage n'apparaîtra plus dans la section <strong>My interviews</strong>.",
  'Successfully added reviewer': 'Évaluateur ajouté avec succès!',
  'You have no action to do on this interview.':
    "Vous n'avez pas à intervenir sur cette interview.",
  'Here are all the interviews you are in charge of.':
    'Voici toutes les interviews dont vous êtes responsable.',
  'Start my feedback on': 'Commencer mon feedback sur {firstname}',
  'Continue my feedback on': 'Continuer mon feedback sur {firstname}',
  'No reviewer for the moment': 'Aucun évaluateur pour le moment',
  'Start self evaluation': 'Commencer mon auto-évaluation',
  Cross: 'Cross',
  Participant: 'Participant',
  'Continue my survey': 'Continuer mon sondage',
  'Start survey': 'Commencer le sondage',
  'Hmm, it seems you have no users yet.':
    "Hmm, il semblerait qu'il n'y ait pas encore d'utilisateur",
  'Create your first user !': 'Créer un utilisateur',
  'Hmm, it seems you have no campaigns yet.':
    "Hmm, il semble qu'il n'y ait aucune campagne à afficher,",
  'Create your first campaign !': 'voulez-vous créer votre première ?',
  'Add new feedback': 'Ajouter un feedback',
  'Are you sure you want to delete this campaign ?':
    'Êtes-vous sûr(e) de vouloir supprimer cette campagne ?',
  "View interviewee's answers": "Voir les réponses de l'interviewé",
  'No interview': "Pas d'interview",
  'Interviewer (you)': 'Intervieweur (vous)',
  'Downloading PDF...': 'Téléchargement PDF...',
  'Delete target': "Supprimer l'objectif",
  'Are you sure you want to delete this target ?<br/>(This is a permanent action)':
    'Êtes-vous sûr(e) de vouloir supprimer cet objectif ?<br/>(Cette action est irreversible)',
  'No participant found !': 'Aucun participant trouvé !',
  'Review has been unlocked.': "L'interview {role} a été déverrouillée.",
  'Review has been submitted.': "L'interview {role} a été verrouillée.",
  'Submit Cross Review (CR)': 'Verrouiller la Cross Review (CR)',
  Submit: 'Envoyer',
  'Updates and comments': 'Mise à jours et commentaires',
  'has updated value to': 'a mis à jour la valeur à',
  'No updates and comments yet.': 'Pas encore de mise à jour et de commentaires.',
  'Not set yet': 'Pas encore défini',
  reviewer: 'évaluateur',
  'Survey of': 'Survey de',
  Done: 'Fait',
  'Are you sure you want to delete this target ?':
    'Êtes-vous sur de vouloir supprimer cet objectif ?',
  'Status updated': 'Statut mis à jour',
  'Title updated': 'Titre mis à jour',
  'Description updated': 'Description mise à jour',
  'Your target has been updated!': 'Votre objectif a été mis à jour!',
  'Error! Your changes have not been saved.':
    "Erreur! Vos modifications n'ont pas été enregistrées.",
  'Your changes have not been saved!': "Vos modifications n'ont pas été enregistrées!",
  Retry: 'Réessayer',
  'Do you want to save your changes ?': 'Enregistrer vos modifications ?',
  'You forgot to save your changes.': "Vous avez oublié d'enregistrer vos modifications.",
  Discard: 'Abandonner',
  'Delete campaign': 'Supprimer la campagne',
  'No, cancel': 'Non, annuler',
  'Yes, delete this campaign': 'Oui, supprimer cette campagne',
  'This is a permanent action.': 'Cette action est irréversible.',
  'Yes, delete this tag': 'Oui, supprimer ce tag',
  'Yes, delete this category': 'Oui, supprimer cette catégorie',
  'Delete tag': 'Supprimez ce tag',
  'Yes, delete this content': 'Oui, supprimer ce contenu',
  'Yes, delete this report': 'Oui, supprimer ce rapport',
  'Yes, delete this template': 'Oui, supprimer ce modèle',
  'Are you sure you want to delete this report ?':
    'Êtes-vous sûr(e) de vouloir supprimer ce rapport ?',
  'Are you sure you want to delete this template ?':
    'Êtes-vous sûr(e) de vouloir supprimer ce modèle ?',
  'Slack notifications have been redirected to the channel':
    "Les notifications Slack ont été redirigées vers le canal '{channelName}'",
  'submitted his answers for the interview.':
    '{ name } a soumis ses réponses pour l\u0027interview "{ campaignName }".',
  'unlocked your interview.': '{ name } a déverouillé votre interview "{ campaignName }".',
  'You have started the interview.': 'Vous avez débuté l\u0027interview "{ name }".',
  'You have submitted the interview.': 'Vous avez soumis l\u0027interview "{ name }".',
  Updates: 'Mises à jour',
  'No history for the moment.': 'Aucun historique pour le moment.',
  'You have unlocked the interview.': 'Vous avez débloqué l\u0027interview "{ name }".',
  'Consult answers ()': 'Consulter les réponses ({answers})',
  'Survey participants': 'Participants au sondage',
  'Collect feedbacks from employees reviewers.':
    'Collecter les retours des évaluateurs sur leurs employés',
  'Collect data to take the temperature of employees.':
    'Collecter des informations pour prendre le pouls des employés',
  'Feedback 360': 'Feedback 360',
  'One to one': 'One to one',
  'InterviewApp::Questions::Paragraph': 'Paragraphe',
  interview: 'interview',
  roadmap: 'roadmap',
  people: 'utilisateurs',
  'Submit Interviewee review (IE)': "Verrouiller l'interview Interviewé (IE)",
  'Unlock Interviewee review (IE)': "Déverrouiller l'interview Interviewé (IE)",
  'Submit Interviewer review (IR)': "Verrouiller l'interview Intervieweur (IR)",
  'Unlock Interviewer review (IR)': "Déverrouiller l'interview Intervieweur (IR)",
  'Fill this field if you have registration number for your employee. This field is optional.':
    'Remplissez ce champ si votre employé possède des matricules. Ce champ est optionnel.',
  'Employee registration number': "Matricule de l'employé",
  "There can't be two identical options!": 'Il ne peut pas y avoir deux options identiques !',
  'Manager team roadmaps': "Roadmaps de l'équipe de {fullname}",
  'Attention: Deleting the template will result in the deletion of all campaign drafts linked to this template.':
    'Attention : Supprimer le modèle entraînera la suppression de tous les brouillons de campagne liés à ce modèle.',
  'Will start': 'Commencera',
  Incoming: 'À venir',
  Upcoming: 'À venir',
  Tomorrow: 'Demain',
  Today: "Aujourd'hui",
  'In day(s)': 'Dans 0 jour | Dans 1 jour | Dans {n} jours',
  'In week(s)': 'Dans 0 semaine | Dans 1 semaine | Dans {n} semaines',
  'In month(s)': 'Dans 0 mois | Dans 1 mois | Dans {n} mois',
  'In over a year': "Dans plus d'un an",
  'Not launched yet': 'Pas encore lancée',
  'Search the participant you wish to add to the campaign.':
    'Recherchez le participant que vous souhaitez ajouter à la campagne.',
  'Hmm, It seems there is no reviewers selected, search for reviewers and add them.':
    "Hmm, il semblerait qu'il n'y ait aucun évaluateur sélectionné. Recherchez des évaluateurs et ajoutez-les.",
  'Error adding participant !': "Erreur d'ajout de participant !",
  'Error adding participants !': "Erreur d'ajout de participants !",
  'Successfully added participant !': 'Participant ajouté avec succès !',
  'Successfully added participants !': 'Participants ajoutés avec succès !',
  'No user found, please try again !': 'Aucun utilisateur trouvé, veuillez réessayer !',
  'Search the participants you wish to add to the campaign.':
    'Recherchez le(s) participant(s) à ajouter à la campagne.',
  'Manual selection': 'Sélection manuelle',
  'Category...': 'Catégories...',
  'By category': 'Par catégorie',
  'Selected participants (all employees in the categories below) : ':
    'Participants sélectionnés (tous les employés avec les tags suivants) : ',
  'Hmm, it seems you have not selected a category yet. Search for a category in the search bar above.':
    "Hmm, il semblerait que vous n'ayez pas encore sélectionné de tag. Recherchez un tag à partir de la barre de recherche ci-dessus.",
  'All categories': 'Toutes les categories',
  'No tag matching was found.': "Aucun tag correspondant au terme '{tagName}' n'a été trouvé.",
  'Clear all': 'Tout supprimer',
  'Your pdf is ready.': 'Votre pdf est prêt.',
  'Click here to download your pdf.': 'Cliquez ici pour télécharger votre pdf.',
  'Your pdf is being generated.': 'Votre pdf est en cours de génération.',
  'Wait a few seconds to download it. You will get notified when file generation is complete.':
    'Patientez quelques secondes pour le télécharger. Vous serez notifié lorsque la génération sera terminée.',
  'An error occurred': "Une erreur s'est produite",
  'An error occurred while downloading your pdf, please try again later':
    "Une erreur s'est produite pendant le téléchargement de votre pdf, veuillez réessayer plus tard",
  'Download pdf': 'Télécharger le pdf',
  'Error deleting campaign !': 'Erreur de suppression de la campagne !',
  'Error relaunching campaign !': 'Erreur de relance de la campagne !',
  'Relaunch campaign': 'Relancer la campagne',
  'Delete campaign and stop recurrence': 'Supprimer et arrêter  la(les) récurrence(s)',
  'Choose your settings': 'Choisissez vos réglages',
  'Recurrent campaign': 'Campagne récurrente',
  'A recurrent campaign is a campaign which is repeated several times.':
    'Une campagne récurrente est une campagne qui se lance plusieurs fois automatiquement.',
  'Campaign will last : ': 'La campaign durera :',
  'Enter a duration': 'Entrez une durée',
  'Duration unit': 'Unité de durée',
  Days: 'Jour(s)',
  Weeks: 'Semaine(s)',
  Months: 'Mois',
  Years: 'Année(s)',
  Repeat: 'Répéter',
  'Every week': 'Toutes les semaines',
  'Every month': 'Tous les mois',
  'Every 6 months': 'Tous les 6 mois',
  'Every year': 'Tous les ans',
  'Select period': 'Sélectionnez la période',
  'The duration of the campaign.': 'La durée de la campagne.',
  'The periodicity of the campaign.': 'La périodicité de la campagne.',
  'Same day each month': 'Même jour tous les mois',
  'First day of the month': 'Premier jour du mois',
  'Last day of the month': 'Dernier jour du mois',
  'Same day each 6 months': 'Même jour tous les 6 mois',
  'Same day each year': 'Même jour tous les ans',
  'Hey, please fill, starting date': 'Hey, merci de remplir la date de début de la campagne',
  'Hello,': 'Hello, {firstname}',
  'Tag to move to this category': 'Tag à déplacer dans cette catégorie',
  'New category name': 'Nouveau nom de la catégorie',
  'Enter a category name': 'Entrez un nom de catégorie',
  'View targets': 'Voir les objectifs',
  'Not yet set': 'Pas encore assigné',
  'Here you can find your targets.': 'Vous pouvez retrouver vos objectifs ici.',
  'Here you can find all the roadmaps you are in charge of.':
    'Vous pouvez retrouver ici tous les objectifs dont vous êtes le responsable.',
  'Hmm, it seems you have no targets yet. Create your first target !':
    "Hmm, il semblerait que vous n'ayez pas encore d'objectif. Créez votre premier objectif !",
  'Delete template': 'Supprimer le modèle',
  'Your template has been deleted !': 'Votre modèle a bien été supprimé !',
  'Error deleting your template !': 'Erreur de suppression de votre modèle !',
  'Due date : ': 'Date limite : {dueDate}',
  'Last update : ': 'Dernière modification : {lastUpdate}',
  'Update template': 'Modifier modèle',
  'Congratulations! You have reached your target ':
    'Félicitations! Vous avez atteint votre objectif ',
  'Hmm, it seems you have no archived targets.':
    "Hmm, il semblerait que vous n'ayez pas d'objectif archivé.",
  'left to complete your target!': '{ value } nécessaire(s) pour terminer votre tobjectif!',
  'percent left to complete your target!':
    '{ value }% nécessaire(s) pour terminer votre tobjectif!',
  'All Roadmaps': 'Toutes les roadmaps',
  Informations: 'Informations',
  'Title*': 'Titre*',
  '*Mandatory fields': '*Champs obligatoires',
  'Are you sure you want to archive this target ?':
    'Êtes-vous sûr(e) de vouloir archiver cet objectif ?',
  'You will find this target in the "Archived targets" tab.':
    "Vous retrouverez cet objectif dans l'onglet 'Objectifs archivés'.",
  'Yes, archive this target': 'Oui, archiver cet objectif',
  'Your target has been archived.': 'Votre objectif a été archivé.',
  'Are you sure you want to unarchive this target ?':
    'Êtes-vous sûr(e) de vouloir désarchiver cet objectif ?',
  'You will find this target in the "Current targets" tab.':
    "Vous retrouverez cet objectif dans l'onglet 'Objectifs en cours'.",
  'Yes, unarchive this target': 'Oui, désarchiver cet objectif',
  'Your target has been unarchived.': 'Votre objectif a été désarchivé.',
  'Your target has been deleted.': 'Votre objectif a été supprimé.',
  'Error while deleting your target !': 'Erreur lors de la suppression de votre objectif !',
  'Error while archiving your target !': "Erreur lors de l'archivage de votre objectif !",
  'Error while unarchiving your target !': 'Erreur lors du désarchivage de votre objectif !',
  'Create your own target': 'Créer votre propre objectif',
  'Hmm, it seems you have no template yet.':
    "Hmm, il semblerait que vous n'ayez pas encore de modèles.",
  'Start from scratch': 'Commencer depuis le début',
  'Begin with a blank slate': 'Commencer depuis une base vide',
  All: 'Tous',
  Boolean: 'Vrai/Faux',
  Number: 'Valeur numérique',
  Percent: 'Pourcentage',
  'Multi-choice': 'Choix multiple',
  'When creating or updating a target, this field will be editable.':
    "Lors de la création ou la modification d'un objectif, ce champ sera modifiable.",
  'When creating or updating a target, this field will not be editable. Whereas, you can still edit it here.':
    "Lors de la création ou la modification d'un objectif, ce champ ne sera pas modifiable. Néanmoins, vous pouvez encore le modifier ici.",
  'Targets templates': "Modèles d'objectif",
  'Numeric Value': 'Valeur numérique',
  'Target Value': 'Valeur cible',
  'Current Value': 'Valeur actuelle',
  'Starting Value': 'Valeur initiale',
  'See more': 'Voir plus',
  'Edit title': 'Modifier le titre',
  'Edit description': 'Modifier la description',
  'Edit due date': 'Modifier la date limite',
  'View update': 'Voir modifications',
  'Add comment': 'Ajouter un commentaire',
  'Update completion': 'Modifier la complétion',
  'Edit Title': 'Modifier le titre',
  'Edit Description': 'Modifier la description',
  'Edit Due date': 'Modifier la date limite',
  'New Title': 'Nouveau titre',
  'New Description': 'Nouvelle description',
  'The current answer does not correspond to the target value.':
    'La réponse actuelle ne correspond pas à la valeur cible.',
  'Target completed!': 'Objectif complété!',
  'Target achieved on': 'Objectif complété le',
  'You have not replied yet. Start answering by clicking on "Update answer".':
    "Vous n'avez pas encore répondu. Commencez à répondre en cliquant sur le bouton 'Mettre à jour la réponse'.",
  'No recent events yet.': 'Aucun évènement récent.',
  'Duration number should be greater than 0': 'La durée doit être un nombre supérieur à 0',
  'You have submitted answers to the interview.':
    'Vous avez soumis les réponses de l\u0027interviewé pour l\u0027interview "{ name }".',
  'Manage co-pilots': 'Gérer les co-pilotes',
  'Manage Co-pilots': 'Gérer les Co-pilotes',
  'Copilot added successfully': 'Co-pilote ajouté avec succès !',
  'Error while adding copilot !': "Erreur lors de l'ajout du co-pilote !",
  'Copilot removed successfully': 'Co-pilot supprimé avec succès',
  'Error while removing copilot !': 'Erreur lors de la suppression du co-pilote !',
  'Error getting copilots list !': 'Erreur lors de la récupération de la liste des co-pilotes !',
  'The co-pilot will have the same rights as you on this campaign. However, he will not be able to share access with anyone else.':
    'Le co-pilote aura les mêmes droits que vous sur cette campagne. Néanmoins, il ne pourra pas lui-même désigner des co-pilotes.',
  'Hmm, It seems there is no co-pilots selected, search for co-pilots and add them.':
    "Hmm, il semblerait qu'il n'y ait pas de co-pilote pour l'instant. Recherchez des co-pilotes et ajoutez-les.",
  'Error getting Manager creators list !':
    'Erreur de récupération de la liste des responsables créateurs !',
  'When do you want to launch this campaign ?': 'Quand souhaitez-vous démarrer la campagne ?',
  'The campaign start date is later than the current date.':
    'La date de début de campagne est postérieure à la date du jour',
  'Launch now': 'Démarrer maintenant',
  'Launch on': 'Démarrer le {date}',
  'Start date': 'Date de début',
  'Items per page': 'Eléments par page',
  'Send invitation emails': 'Envoyer les invitations par email',
  'Do you wish to send invitations by email for this campaign ? They will be sent now.':
    'Souhaitez-vous envoyer les invitations par email pour cette campagne? Si oui, elles seront envoyées dès maintenant',
  'Yes, send emails': 'Oui, envoyer les emails',
  'Do you wish to send invitations by email for this campaign ? They will be sent on':
    'Souhaitez-vous envoyer les invitations par email pour cette campagne? Si oui, elles seront envoyées le {date}',
  'Select a day between 1st and 28th to be able to select the monthly repetition':
    'Le jour de la campagne doit être compris entre 1 et 28 pour pouvoir sélectionner une fréquence mensuelle',
  'Define the two words you want to measure the objective. False will be the default value, then you could switch to True value.':
    "Définissez les deux mots que vous souhaitez utiliser pour mesurer l'objectif. Faux sera la valeur par défaut, puis vous pourrez passer à la valeur Vrai.",
  "Add options to qualify the objective completion. You can choose which option will be considered a target value by ticking 'target value'.":
    "Ajoutez des options pour qualifier la réalisation de l'objectif. Vous pouvez choisir l'option qui sera considérée comme une valeur cible en cochant 'valeur cible'.",
  'Report type affect the way datas are displayed one the file you extract':
    'Le type de rapport affectera la façon dont les données seront affichées dans votre rapport',
  'Global datas': 'Données globales',
  'All informations about interviews in a campaign.':
    "Toutes les informations des interviews d'une campagne.",
  'Selected datas already analysed with ratios.': 'Données chiffrées avec des ratios.',
  'All answers submitted in campaigns.':
    'Toutes les réponses envoyées dans le cadre de votre campagne.',
  'Analyse completions of targets in your campaigns.':
    'Analyse de la complétions des objectifs de vos campagnes.',
  'Choose a category to analyse': 'Choisir une catégorie à analyser',
  'Each tag of the selected category will be displayed with completion pourcentages.':
    'Chaque tag de la catégorie sélectionnée sera affiché en pourcentage de complétion.',
  Generate: 'Générer le rapport',
  'Select campaign(s)': 'Sélectionner la ou les campagne(s)',
  'Roadmap - Update target': "Roadmap - Mettre à jour d'objectif",
  'Roadmap - Create target': 'Roadmap - Créer des objectif(s)',
  'Roadmap - Update Target(s)': 'Roadmap - Mettre à jour un/des objectif(s)',
  'Roadmap - Create Target(s)': 'Roadmap - Créer un/des objectif(s)',
  'Your target has been created successfully.': 'Votre objectif a été créé avec succès.',
  'Your target has been updated successfully.': 'Votre objectif a été modifié avec succès.',
  'Error while creating your target !': 'Erreur lors de la création de votre objectif !',
  'No due date': 'Pas de date',
  true: 'Oui',
  false: 'Non',
  'We just sent an email to reset your password to the address':
    'Nous venons de vous envoyer un courriel pour réinitialiser votre mot de passe.',
  'No more history !': 'Plus aucun historique !',
  Updated: 'Mis à jour',
  Created: 'Créé',
  'If you confirm, all target creations and updates will be applied. You can follow up this in Roadmap app.':
    "Si vous confirmez, toutes les mises à jour et créations d'objectifs seront appliquées. Vous pouvez les retrouver dans l'App Roadmap.",
  'These targets will be updated and/or created': 'Les objectifs suivants seront mis à jour/créés',
  'No suggestion to show for the moment.': 'Aucune suggestion à afficher pour le moment.',
  'These are updates proposed by the Interviewee and Interviewer. Only the Value update below will be applied to the target after submitting all interviews of the set.':
    "Il y a des mises à jour suggerées par l'interviewé et l'intervieweur. Seule la valeur ci-dessous sera appliquée à l'objectif lorsque toutes les interviews seront soumises.",
  'This answer will be applied to the target when you submit this interview.':
    "Cette réponse sera appliquée à l'objectif quand vous soumettrez cette interview.",
  'Suggested updates': 'Mises à jour suggerées',
  'Suggested value': 'Valeur suggerée',
  'Suggested value :': 'Valeur suggerée : { value }',
  'Target info': "Informations d'objectif",
  'Final update': 'Mise à jour finale',
  'Answers will be locked': 'Vos réponses seront verouillées',
  'Submit this interview will lock your answers. Ask to an admin to unlock it if needed.':
    'Soumettre cette interview bloquera vos réponses. Demandez à un admin de les débloquer si nécessaire',
  'Error fetching targets !': 'Erreur lors de la récupération des objectifs !',
  'Error fetching target draft': "Erreur lors de la récupération du brouillon de l'objectif !",
  'Error updating suggestion !': 'Erreur lors de la mise à jour de la suggestion !',
  'This target is still a draft. Complete all interviews of the set to create it.':
    'Cet objectif est encore un brouillon. Complétez toutes les interviews du set afin de le créer.',
  "You are currently logged on company's workspace":
    "Vous êtes actuellement connecté dans l'espace de travail de {company}",
  'Need any help from Aleph ?': "Besoin d'aide d'Aleph ?",
  'Contact our team !': 'Contactez notre équipe !',
  'Campaign recurrences will end on :':
    'La(les) récurrence(s) de la campagne cessera/cesseront le:',
  'At :': 'À :',
  'The recurrent campaign will start at the time defined below.':
    "La campagne récurrente débutera à l'heure ci-dessous",
  Zone: 'Zone',
  'No template used': 'Aucun modèle utilisé',
  'Edit starting date': 'Modifier la date de début',
  'Manage recurrence': 'Modifier la(les) récurrences',
  'Duration :': 'Durée :',
  Duration: 'Durée',
  'How long the campaign will last.': 'La durée de la campagne.',
  'Will launch at :': 'Débutera le :',
  'Campaign settings': 'Réglages de la campagne',
  'Stop repetition on :': 'Arrêter la(les) repetition(s) le :',
  'Repeat :': 'Répéter :',
  'Recurrence settings': 'Réglages de la récurrence',
  Time: 'Heure',
  January: 'Janvier',
  February: 'Février',
  March: 'Mars',
  April: 'Avril',
  May: 'Mai',
  June: 'Juin',
  July: 'Juillet',
  August: 'Août',
  September: 'Septembre',
  October: 'Octobre',
  November: 'Novembre',
  December: 'Décembre',
  Monday: 'Lundi',
  Tuesday: 'Mardi',
  Wednesday: 'Mercredi',
  Thursday: 'Jeudi',
  Friday: 'Vendredi',
  Saturday: 'Samedi',
  Sunday: 'Dimanche',
  '1st': '1er',
  '2nd': '2',
  '3rd': '3',
  '4th': '4',
  '5th': '5',
  '6th': '6',
  '7th': '7',
  '8th': '8',
  '9th': '9',
  '10th': '10',
  '11th': '11',
  '12th': '12',
  '13th': '13',
  '14th': '14',
  '15th': '15',
  '16th': '16',
  '17th': '17',
  '18th': '18',
  '19th': '19',
  '20th': '20',
  '21th': '21',
  '22th': '22',
  '23th': '23',
  '24th': '24',
  '25th': '25',
  '26th': '26',
  '27th': '27',
  '28th': '28',
  '29th': '29',
  '30th': '30',
  '31th': '31',
  'January - July': 'Janvier - Juillet',
  'February - August': 'Février - Août',
  'March - September': 'Mars - September',
  'April - October': 'Avril - Octobre',
  'May - November': 'Mai - Novembre',
  'June - December': 'Juin - Décembre',
  'July - January': 'Juillet - Janvier',
  'August - February': 'Août - Février',
  'September - March': 'September - Mars',
  'October - April': 'Octobre - Avril',
  'November - May': 'Novembre - Mai',
  'December - June': 'Décembre - Juin',
  every_week: 'semaine',
  every_month: 'mois',
  every_six_month: '6 mois',
  every_year: 'année',
  days: 'jour(s)',
  weeks: 'semaine(s)',
  months: 'mois',
  years: 'an(s)',
  'Campaigns will be launched at ': 'Les campagnes seront lancées à ',
  ' They will last ': ' Elles vont durer ',
  ', repeating each ': ', se répéteront chaque ',
  ' on the ': ' le ',
  '. They will end on the ': '. Elles prendront fin le ',
  'Your changes have not been saved !': "Vos modifications n'ont pas été enregistrées !",
  'Your changes have been saved !': 'Vos modifications ont été enregistrées !',
  'Quit without saving': 'Quitter sans enregistrer',
  'Your changes will be lost. Save changes before closing ?':
    'Vos modifications seront perdues. Enregistrer vos modifications avant de quitter ?',
  'Save changes before closing ?': 'Enregistrer les modifications avant de quitter ?',
  'Do you want to save you changes ?': 'Voulez-vous enregistrer vos modifications ?',
  'Go back': 'Retour',
  'user commented': '{user} a commenté',
  "user's roadmaps": 'My Roadmap de {user}',
  'Comment added - ': 'Commentaire ajouté - ',
  'It seems that we cannot update the list. Reload the page or contact the support':
    'Il semblerait que la liste ne puisse pas être rechargée. Rechargez la page ou contactez le support',
  'The interviewer has not been updated. Please try again.':
    "L'intervieweur n'a pas pu être mis à jour. Merci de réessayer.",
  'No manager': 'Aucun manager',
  'Final value': 'Valeur finale',
  'Final value :': 'Valeur finale : { value }',
  'This interview is already submitted, so you cannot add any new targets for the moment.':
    'Cette interview est déjà soumise, vous ne pouvez plus y ajouter de nouveaux objectifs pour le moment.',
  'Could not update suggestion ! Please try again.':
    'Impossible de modifier la suggestion ! Veuillez réessayer.',
  'See all': 'Voir tout',
  'See less': 'Voir moins',
  'No description': 'Aucune description',
  'Target not achieved yet': "L'objectif n'est pas encore completé",
  'Indicator type': "Type d'indicateur",
  'Select all': 'Tous',
  Company: 'Organisation',
  Personnalization: 'Personnalisation',
  Labels: 'Étiquettes',
  'Global informations': 'Informations globales',
  'Name of your company': 'Nom de votre entreprise',
  'Shared Informations': 'Informations partagées',
  'Add information': 'Ajouter information',
  Link: 'Lien',
  'Shared information has been deleted.': "L'information partagée a été supprimée.",
  'Add category': 'Ajouter catégorie',
  'Are you sure you want to remove this shared information ?':
    'Êtes-vous sûr de vouloir supprimer cette information partagée ?',
  'This information will no longer be accessible for employees.':
    'Cette information ne sera plus accessible par les employé(e)s.',
  'Yes, remove': 'Oui, supprimer',
  Logos: 'Logos',
  Photos: 'Photos',
  'Homepage logo': "Page d'Accueil",
  'Navbar logo': 'Barre de navigation',
  'Drag and drop an image here or': 'Glissez et déposez une image ici ou',
  'Choose photo': 'Choisissez une photo',
  'My Team Interviews': "Mes interviews d'équipe",
  'Home Page': "Page d'Accueil",
  'Import users': 'Importer des utilisateurs',
  '*Required fields': '*Champs obligatoires',
  'Create and send invitation': 'Créer et envoyer une invitation',
  'Please fill the firstname, the lastname, the email.':
    "S'il vous plaît remplissez le prénom, le nom, l'e-mail.",
  'The user has been successfully created and has received an invitation e-mail.':
    "L'utilisateur a été crée et a reçu un e-mail d'invitation.",
  'The user has been successfully created.': "L'utilisateur a été crée avec succès.",
  'Hmm, it seems there are no employees in your company yet. Add your first employees!':
    "Hmm, il semblerait qu'il n'y ait pas encore d'employé dans pvotre entreprise. Ajoutez votre premier employé!",
  'Are you sure you want to remove these users from your company ?':
    'Êtes-vous sûr(e) de vouloire supprimer ce(s) utilisateur(s) de votre entreprise ?',
  'The selected user(s) has/have been removed from company.':
    'Le(s) utilisateur(s) sélectionné(s) a(ont) été supprimé(s) de votre entreprise.',
  'Drag & drop or': 'Glissez et déposez ou',
  'chose file': 'choississez un fichier',
  'to upload': 'à télécharger',
  'Only .xlsx format is accepted': 'Uniquement le format .xlsx est accepté',
  "You don't have a file ?": "Vous n'avez pas de fichier ?",
  'Download and complete the template provided.': 'Téléchargez et remplissez le modèle fourni.',
  "You can use the 'Help' tab in the file to fill in the template.":
    "Vous pouvez utiliser l'onglet 'Aide' dans le fichier afin de compléter le modèle.",
  'Download template': 'Télécharger le modèle',
  'Inspecting file...': 'Analyse du fichier...',
  'File ready for import': 'Le fichier est prêt à être importé',
  'No errors detected.': 'Aucune erreur détectée.',
  Modifications: 'Modifications',
  'Send invitation emails to all new users':
    "Envoyer des e-mails d'invitation à tous les nouveaux utilisateurs",
  'Apply changes and import file': 'Appliquer les changements et importer le fichier',
  'Are you sure you want to leave': 'Êtes-vous sûr(e) de vouloir partir ?',
  'The import operation will be abandonned and you will have to start it over.':
    "L'opération d'import sera annulée et vous devrez la recommencer depuis le début.",
  'Yes, leave': 'Oui, partir',
  'Importing...': 'Importation en cours...',
  'Import completed !': 'Importation terminée !',
  Finish: 'Terminer',
  'Drop your file here !': 'Déposez votre fichier ici !',
  ' imported': ' importé/e(s)',
  Users: 'Utilisateurs',
  'Tag categories': 'Catégories de tags',
  created: 'crées',
  updated: 'modifiés',
  deleted: 'supprimés',
  'Add single user': 'Ajouter un utilisateur',
  'Error during import': "Erreur d'importation",
  'An error occured during import. Please try again.':
    "Une erreur est survenue pendant l'importation. Veuillez réessayer s'il vous plaît.",
  'Your file contains errors': 'Votre fichier contient des erreurs',
  'Please correct the errors below and load the file again.':
    "Veuillez s'il vous plaît corriger les erreurs suivantes et retélécharger le fichier.",
  'Upload another file': 'Télécharger un autre fichier',
  'Tag category': 'Catégorie de tag',
  Tag: 'Tag',
  'Are you sure you want to delete this label ?':
    'Êtes-vous sûr de vouloir supprimer cette étiquette ?',
  'Your Tag has been deleted.': 'Votre tag a été supprimée.',
  'Your Tag category has been deleted.': 'Votre catégorie de tag a été supprimée.',
  'Enlightened Novice': 'Novice Éclairé',
  'Knowledge Explorer': 'Explorateur du Savoir',
  'Thirsty for Knowledge': 'Assoiffé de Connaissance',
  'Curriculum Master': 'Maître du Curriculum',
  'The Approver': "L'Approbateur",
  'Positive Supporter': 'Le Soutien Positif',
  'Enlightened Evaluator': "L'Évaluateur Éclairé",
  'Master of Emotions': 'Le Maître des Émotions',
  'Accomplished Apprentice': 'Apprenti Accompli',
  'Trained and Competent': 'Formé et Compétent',
  'Certified Expert': 'Certifié Connaisseur',
  'Training Champion': 'Champion de la Formation',
  'The Selector': 'Le Sélectionneur',
  'Content Lover': 'Amateur de Contenus',
  'Informed Collector': 'Collectionneur Averti',
  'Resource Guardian': 'Gardien des Ressources',
  Badges: 'Badges',
  'Acquire a module': 'Acquérir un module',
  'React to a module': 'Mettre une réaction à un module',
  'Complete a training': 'Compléter une formation',
  'Favorite a module': 'Mettre un module en favori',
  Trainings: 'Formations',
  'Not yet': 'Pas encore',
  'Create training': 'Créer une formation',
  'New training': 'Créer une formation',
  Launched: 'Démarré',
  'Hmm, it seems you have no training yet. Create your first training !':
    "Hmm, il semblerait que vous n'avez pas encore de formation. Créez votre première formation !",
  'Hmm, it seems you have no upcoming training.':
    "Hmm, il semblerait que vous n'aiez pas de formation à venir",
  'Launch date': 'Date de lancement',
  Edit: 'Modifier',
  'Edit training': 'Modifier la formation',
  'Delete training': 'Supprimer la formation',
  'Are you sure you want to delete this training ?':
    'Êtes-vous sur de vouloir supprimer cette formation ?',
  'Participants : ': 'Participants : {participants}',
  "user's trainings": 'Formations de {user}',
  'My Trainings': 'Mes Formations',
  Welcome: 'Bienvenue { firstname }',
  'Trainings completed': '{ contentAquiredCount } formations completées',
  'Modules acquired': '{ modulesAquiredCount } Modules acquis',
  'You have more must do trainings, remember to complete them !':
    'Vous avez aucune formation à faire | Vous avez 1 formation à faire, pensez à la terminer ! | Vous avez {count} formations à faire, pensez à les terminer !',
  'Add this module to playlist ()': 'Ajouter ce module à la playlist ({ selectedPlaylists })',
  'Add this modules to playlist ()': 'Ajouter ces modules à la playlist ({ selectedPlaylists })',
  'New module': 'Nouveau module',
  'Module Title': 'Titre du module',
  'Want to learn ? Take a look at our contents!':
    "Envie d'apprendre ? Jetez un oeil à nos contenus!",
  Playlists: 'Playlists',
  Modules: 'Modules',
  modules: 'modules',
  'Select module to add ()': 'Sélectionner un module à ajouter ({selectedModules})',
  'Select modules to add ()': 'Sélectionner des modules à ajouter ({selectedModules})',
  'Select playlist to add ()': 'Sélectionner une playlist à ajouter ({selectedPlaylists})',
  'Select playlists to add ()': 'Sélectionner des playlists à ajouter ({selectedPlaylists})',
  'Comments ()': 'Commentaires ({count})',
  'Estimated duration': 'Durée estimée',
  'No theme': 'Aucun thème',
  Evaluation: 'Évaluation',
  'Evaluate this module with a quiz': 'Évaluer ce module avec un quiz',
  "Don't forget to take the quiz!": "N'oubliez pas de faire le quiz!",
  'To complete this module, evaluate yourself with a quiz.':
    'Pour compléter ce module, évaluez-vous avec un quiz.',
  "Yes, it's all clear to me": 'Oui, tout est clair pour moi',
  "Yes, it's all clear to me!": 'Oui, tout est clair pour moi!',
  'I will do it later': 'Je le ferai plus tard',
  'Start the quiz now': 'Démarrer le quiz',
  'No, I will come back later': 'Non, je reviendrai plus tard',
  'Did you learn all the following learning objectives?':
    "Avez-vous compris tous les objectifs d'apprentissage suivants ?",
  'To mark this module as “acquired”, you must pass the assessment quiz.':
    'Pour marquer ce modèle comme acquis, vous devez réussir le quiz.',
  Themes: 'Thèmes',
  'An approval is required to acquire this module if you do not choose quiz evaluation.The learner will have to answer to the following question at the end of the module: “Did you fully understand this module ?”':
    "Une confirmation est requise pour acquérir ce module si vous ne choisissez pas l'évaluation par quiz. L'apprenti devra répondre à la question suivante à la fin du module: “Avez-vous entièrement compris ce module ?”",
  'Duplicate module': 'Dupliquer le module',
  'Module duplicated.': 'Module dupliqué',
  'Delete module': 'Supprimer le module',
  'Save as draft': 'Sauvegarder comme brouillon ',
  'Module title': 'Titre du module',
  'Select a theme from the list': 'Selectionnez un thème de la liste',
  'Enter a theme': 'Enter a theme',
  'Theme removed from module': 'Theme removed from module',
  'Theme added to module': 'Theme added to module',
  "🙌 You've reached the end! Did you learn all the following learning objectives?":
    '🙌 Vous êtes arrivé au bout ! Avez-vous appris tous les objectifs d’apprentissage suivants ?',
  'Leave a comment': 'Laisser un commentaire',
  'Hmmm, it seems there is no comment yet. Be the first !':
    "Hmmm, il semble qu'il n'y ait pas encore de commentaire. Soyez le premier !",
  'Next module': 'Passer au module suivant',
  'Go to previous module': 'Retourner au module précédent',
  'Your feedback helps us improve this module.':
    'Vos commentaires nous aident à améliorer ce module.',
  'Hmm, it seems there is no comment yet. Be the first !':
    "Hmm, il semble qu'il n'y ait pas encore de commentaires. Soyez le premier !",
  'This module has no more things to teach you. We hope you have learnt the best from it ! To acquire this module, evaluate yourself with a quiz. Are you ready yet ?':
    "Ce module n'a plus rien à vous apprendre. Nous espérons que vous en avez tiré le meilleur parti! Pour obtenir ce module, évaluez-vous avec un quiz. Êtes-vous prêt ?",
  'No duration': 'Pas de durée',
  '🙌 You’ve reached the end! Ready for the quiz?':
    '🙌 Vous avez atteint la fin! Prêt pour le quiz?',
  'What did you think of the module ?': "Qu'avez-vous pensé du module ?",
  'I did not understand this module': "Je n'ai pas compris ce module",
  'I have a question about...': "J'ai une question à propos de...",
  'I have a remark': "J'ai une remarque",
  'Do you really want to leave ?': 'Voulez-vous vraiment partir ?',
  'If you quit now, your reaction will not be taken into account and your draft will be deleted.':
    'Si vous quittez maintenant, votre réaction ne sera pas prise en compte et votre brouillon sera supprimé.',
  'My Learnings': 'Mes apprentissages',
  Trainings: 'Formations',
  'New modules to discover': 'Nouveaux modules à découvrir',
  'New modules have been added to the catalog, go take a look to it.':
    'De nouveaux modules ont été ajoutés au catalogue, jetez-y un oeil',
  "You don't have any training to do yet. Come back later to see your trainings !":
    "Vous n'avez aucun cours à suivre pour le moment. Revenez plus tard pour découvrir vos cours !",
  "You haven't completed any training yet.": "Vous n'avez terminé aucun cours pour le moment.",
  Validate: 'Valider',
  'New playlist': 'Nouvelle playlist',
  'Increasing publication date': 'Date de publication croissante',
  'Decreasing publication date': 'Date de publication décroissante',
  'Increasing acquisition date': "Date d'acquisition croissante",
  'Decreasing acquisition date': "Date d'acquisition décroissante",
  'There are no modules. Create your first module !':
    "Il n'y a aucun module. Créer votre premier module !",
  'There are no modules.': "Il n'y a aucun module.",
  'There are no playlists. Create your first playlist !':
    "Il n'y a aucune playlist. Créer votre première playlist !",
  'There are no playlists.': "Il n'y a aucune playlist.",
  'Add module to playlist': 'Ajouter un module à une playlist',
  'Create playlist': 'Créer une playlist',
  'Create new playlist': 'Créer une nouvelle playlist',
  'Playlist title': 'Titre de la playlist',
  Draft: 'Brouillon',
  Content: 'Contenu',
  'No content in the training for the moment.': 'Pas de contenu dans la formation pour le moment.',
  'Are you sure you want to send reminder ?': 'Êtes-vous sûr(e) de vouloir envoyer un rappel ?',
  'Yes, send reminder': 'Oui, envoyer un rappel',
  'Anyone who has not finished the training will receive an e-mail reminder.':
    "Tous ceux qui n'ont pas terminé la formation recevront un e-mail de rappel.",
  'Yes, delete this training': 'Oui, supprimer',
  'No data available yet as training has not yet begun.':
    "Aucune donnée disponible car la formation n'a pas encore débuté.",
  'Number of people completing the training': 'Nombre de personnes qui suivent la formation',
  'Training completion - All': 'Complétion de la formation - Tous',
  'This module has just been updated !': "Ce module vient d'être mis à jour !",
  'The lastest version of this content will be visible if you reload this page.':
    'La dernière version de ce contenu sera visible si vous rechargez cette page.',
  'Reload this page': 'Recharger la page',
  'Quick view of your training completion': 'Apperçu rapide de la complétion de la formation',
  'Training completion - Category': 'Complétion de la formation - Categorie',
  'Training completion - Module': 'Complétion de la formation - Module',
  'Compare completion by module': 'Comparer la complétion par module',
  'Compare completion by category': 'Comparer la complétion par categorie',
  'Participants list': 'Liste des participants',
  'Last module acquired on': 'Dernier module acquis le',
  'Training acquired on': 'Formation terminée le',
  Trained: 'En formation',
  Update: 'Mettre à jour',
  'Chose your title :': 'Choisissez votre titre :',
  'Copy of content': 'Titre du module',
  'Are you sure you want to delete this module ?':
    'Êtes-vous sûr(e) de vouloir supprimer ce module ?',
  'Yes, delete this module': 'Oui, supprimer ce module',
  "Add content to the editor using the '+' button":
    "Ajouter du contenu à l'éditeur avec le bouton '+'",
  'No modules in the playlist, add some with the button below':
    'Aucun module dans la playlist, ajoutez-en avec le bouton ci-dessous',
  'Add modules': 'Ajouter module(s)',
  'Update playlist title & description': 'Mettre à jour le titre et la description de la playlist',
  'Link copied to clipboard.': 'Lien copié dans le presse-papiers.',
  'Module added to favorites': 'Module ajouté aux favoris',
  'Module removed from favorites': 'Module retiré des favoris',
  'Edit playlist': 'Modifier la playlist',
  'Delete playlist': 'Supprimer la playlist',
  'Are you sure you want to delete this playlist ?':
    'Êtes-vous sûr(e) de vouloir supprimer cette playlist ?',
  'Yes, delete this playlist': 'Oui, supprimer cette playlist',
  'No theme to display': 'Aucun theme à afficher',
  'Add a question block': 'Ajouter un bloc question',
  'Learners needs to answer right to each question to acquire the module':
    "Les apprentis devront avoir une bonne réponse à chaque question afin d'acquérir le module",
  'Add a free content block': 'Ajouter un bloc contenu libre',
  'Use this block to put titles, paragraphs, pictures and more in your quiz':
    'Utilisez ce bloc pour ajouter des titres, des paragraphes, des images et plus encore dans votre quiz',
  'Add answer option': 'Ajouter une option',
  Date: 'Date',
  'No module or playlist in training for the moment, add some with the buttons below':
    "Aucun module ou playlist dans la formation pour le moment, ajoutez-en à l'aide des boutons ci-dessous",
  'Add playlists': 'Ajouter playlist(s)',
  'Training title': 'Titre de la formation',
  'Create your first piece of content by modifying the title, description and image. You can also add an estimated duration, objectives and one or more themes just below.':
    "Créez votre tout premier contenu en modifiant le titre, la description et l'image. Vous pouvez également ajouter une durée estimée pour la completion, des objectifs et un ou plusieurs thèmes.",
  'Update training': 'Mettre à jour la formation',
  'Search for participants...': 'Recherche de participants...',
  'Launch training': 'Lancer la formation',
  'Edit module': 'Modifier le module',
  'Content added': 'Contenu ajouté',
  'Remove from training': 'Supprimer de la formation',
  'Are you sure you want to leave ?': 'Êtes-vous sûr(e) de vouloir quitter ?',
  'Yes, leave': 'Oui, quitter',
  'By leaving your changes will be lost.': 'En quittant, vos changements seront perdus.',
  'Your module has been deleted': 'Votre module a été supprimé',
  'Are you sure you want to publish ?': 'Êtes-vous sûr(e) de vouloir publier ce module ?',
  'Your module will be added to the Catalog and will be visible to everyone.':
    'Votre module sera ajouté au catalogue et sera visible par tous.',
  'Yes, publish': 'Oui, publier',
  'Module published!': 'Module publié!',
  'Send invitation emails ?': "Envoyer des emails d'invitation ?",
  'Do you wish to send invitation emails for this training ? They will be sent as soon as the training will be launched.':
    "Voulez-vous envoyer des emails d'invitation pour la formation ? Ils seront envoyés aussitôt que la formation sera lancée.",
  'Yes, send them': 'Oui, envoyer',
  'Training saved successfully !': 'Formation enregistrée avec succès !',
  'Your changes have been saved and your training has been launched':
    'Vos changements ont été enregistrés et la formationa été lancée',
  'Error updating the playlist': 'Erreur de mise à jour de la playlist',
  'Remove module from playlist': 'Retirer le module de la playlist',
  'I need more time': "J'ai besoin de plus de temps",
  "There's a problem with the content": 'Il y a un problème avec le contenu',
  "What's wrong with this module?": 'Quelle est le problème avec ce module ?',
  'Congratulations, name, keep it up!': 'Félicitations, {name}, continuez comme ça!',
  'One more module acquired !👏': 'Un module de plus acquis !👏',
  Send: 'Envoyer',
  'Tell us about the issue you have encounter':
    'Faites-nous savoir le problème que vous avez rencontré',
  'Update acquisition date': "Mise à jour de la date d'acquisition",
  'Your question is now visible in the comment section.':
    'Votre question est désormais visible dans la section commentaires.',
  'Your remark is now visible in the comment section.':
    'Votre remarque est désormais visible dans la section commentaires.',
  'I have a remark about...': "J'ai une remarque à propos de...",
  'Delete comment': 'Supprimer le commentaire',
  'Module author': 'Auteur du module',
  'See comments': 'Voir les commentaires',
  'Comment deleted.': 'Commentaire supprimé.',
  'Error adding comment': "Erreur lors de l'ajout du commentaire",
  'Error deleting comment.': 'Erreur lors de la suppression du commentaire.',
  'By deleting it, your reaction will also be deleted.':
    'En le supprimant, votre réaction sera également supprimée.',
  'Are you sure you want to delete this comment ?':
    'Êtes-vous sûr de vouloir supprimer ce commentaire ?',
  'Yes, delete this comment': 'Oui, le supprimer',
  'Are you sure you want to remove this reaction ?':
    'Êtes-vous sûr de vouloir supprimer cette réaction ?',
  'Yes, remove reaction': 'Yes, supprimer la réaction',
  'By deleting it, the associated comment will also be deleted.':
    'En la supprimant, le commentaire associé sera également supprimé.',
  'Ask your question below': 'Saisissez votre question ci-dessous',
  'Make a remark below': 'Saisissez votre remarque ci-dessous',
  'I like': "J'aime",
  'I recommend': 'Je recommande',
  'I have a question': "J'ai une question",
  'Start the quiz': 'Commencer le quiz !',
  'Validate answers': 'Valider mes réponses',
  'View quiz results': 'Voir les résultats du quiz',
  'Acquired on': 'Acquis le',
  'What happened ?': "Que s'est-il passé ?",
  'Keep going ! Check your mistakes and try again 💪':
    'Continuez de réessayer ! Regardez vos erreurs et réessayez 💪',
  'Only right answers ! Congratulations, 👏':
    'Que des bonnes réponses ! Félicitations, { firstname } 👏',
  '👌 Here is one more module acquired': '👌 Et un module de plus acquis !',
  'Back to module': 'Retour au module',
  'Back to login': 'Retour à la page de connexion',
  acquired: 'acquis',
  'Congratulations, you just acquired a new module, keep going like this !':
    'Félicitations, vous avez aquis un nouveau module, continuez sur cette lancée !',
  'You can try this quiz as much as you want. Having all correct answers will make this module':
    'Vous pouvez faire ce quiz autant que vous le voulez. Avoir toutes les réponses correctes marquera ce module comme étant ',
  "for you. Let's up your right answers rate to 100% !": "pour vous. Essayons d'avoir 100% !",
  'Retry quiz': 'Réessayer le quiz',
  'Resume the quiz !': 'Continuer le quiz !',
  'Right answer': 'Bonne réponse',
  'Partially right answer': 'Réponse à moitié correcte',
  'Wrong answer': 'Mauvaise réponse',
  'Error sending message.': "Erreur lors de l'envoi du message.",
  'View my badges': 'Voir mes badges',
  'Message sent successfully.': 'Message envoyé avec succès.',
  module: 'module',
  'Hmm, it seems we can’t find any results based on your search.':
    'Hmm, il semblerait que nous ne pouvons pas trouver de résultats qui correspondent à votre recherche.',
  'Retake quiz': 'Réessayer le quiz',
  'Go back to the module': 'Retourner au module',
  'Back to My Learnings': 'Retour à mes apprentissages',
  'Embed link': 'Lien',
  Unsplash: 'Unsplash',
  'Upload a file': 'Télécharger un fichier',
  'Paste the image link here': "Collez le lien de l'image ici",
  'Embed image': "Ajouter l'image",
  'Search for an image': 'Recherchez une image',
  'Drag & drop an image or': 'Déplacez une image ici ou',
  'choose file': 'choisissez un fichier ',
  'to upload': 'à télécharger',
  By: 'Par',
  'Module image changed successfully': 'Image du module modifiée avec succès',
  'Training image changed successfully': 'Image de la formation modifiée avec succès',
  'Error uploading training image': "Erreur de modification de l'image de la formation",
  'No playlists to add for the moment': 'Aucun playlist à ajouter pour le moment',
  'Module successfully added to playlists.': 'Module ajouté avec succès aux playlists.',
  'Error fetching playlists': 'Erreur de récupération des playlists',
  'Add this module to playlists': 'Ajouter ce module aux playlists',
  'Deleted Modules': 'Modules supprimés',
  'Deleted Playlists': 'Playlists supprimées',
  'Deleted Trainings': 'Formations supprimées',
  'Deleted Templates': 'Modèles supprimés',
  'Deleted modules are stored here for 30 days. After this period, they will be permanently deleted.':
    'Les modules supprimés sont conservés pendant 30 jours. Après cette période, ils seront supprimés définitivement.',
  'Deleted templates are stored here for 30 days. After this period, they will be permanently deleted.':
    'Les modèles supprimés sont conservés pendant 30 jours. Après cette période, ils seront supprimés définitivement.',
  'Deleted playlists are stored here for 30 days. After this period, they will be permanently deleted.':
    'Les playlists supprimées sont conservées pendant 30 jours. Après cette période, elles seront supprimées définitivement.',
  'Deleted trainings are stored here for 30 days. After this period, they will be permanently deleted.':
    'Les formations supprimées sont conservées pendant 30 jours. Après cette période, elles seront supprimées définitivement.',
  'Delete permanently': 'Supprimer définitivement',
  'Restore module': 'Restaurer module',
  Restore: 'Restaurer',
  'Deletion date': 'Date de suppression',
  'Do you really want to delete this module permanently ?':
    'Souhaitez-vous vraiment supprimer ce module de manière définitive ?',
  'Do you really want to delete this playlist permanently ?':
    'Souhaitez-vous vraiment supprimer cette playlist de manière définitive ?',
  'Do you really want to delete this template permanently ?':
    'Souhaitez-vous vraiment supprimer ce modèle de manière définitive ?',
  'Do you really want to delete this training permanently ?':
    'Souhaitez-vous vraiment supprimer cette formation de manière définitive ?',
  'Yes, delete it': 'Oui, supprimer',
  'View deleted trainings': 'Voir les formations supprimées',
  'View deleted playlists': 'Voir les playlists supprimées',
  'View deleted modules': 'Voir les modules supprimés',
  'View deleted templates': 'Voir les modèles supprimés',
  'There are no deleted items for the moment.': "Il n'y a pas d'éléments supprimés pour le moment.",
  Homepage: "Page d'accueil",
  'View deleted modules drafts': 'Voir les brouillons supprimés',
  draft: 'brouillon',
  'Restore playlist': 'Restaurer la playlist',
  'Restore template': 'Restaurer le modèle',
  'Playlist restored successfully': 'Playlist restaurée avec succès',
  'This playlist has been deleted': 'Playlist supprimée avec succès',
  'Deleted Drafts': 'Brouillons supprimés',
  'Deleted drafts are stored here for 30 days. After this period, they will be permanently deleted.':
    'Les brouillons supprimés sont conservés pendant 30 jours. Après cette période, ils seront supprimés définitivement.',
  'Go to learn': 'Aller à Learn',
  'Your training has been deleted.': 'La formation a été supprimée.',
  'Error fetching categories': 'Une erreur est survenue lors de la récupération des catégories',
  'Back to the module': 'Retour au module',
  'Restore draft': 'Restaurer le brouillon',
  'Do you really want to delete this draft permanently ?':
    'Voulez-vous vraiment supprimer ce brouillon de façon permanente ?',
  'Archive selected targets': 'Archiver les objectifs selectionnés ({ selected })',
  'Delete selected targets': 'Supprimer les objectifs selectionnés ({ selected })',
  'Are you sure you want to archive this/these target(s) ?':
    'Êtes-vous sûr(e) de vouloir archiver cet/ces objectif(s) ?',
  "You will find this/these target(s) in the 'Archived targets' tab.":
    "Vous retrouverez cet/ces objectif(s) dans l'onglet 'Objectifs archivés'.",
  'Yes, archive': 'Oui, archiver',
  'Yes, delete this target': "Oui, supprimer l'objectif",
  'Yes, delete these targets': 'Oui, supprimer les objectifs',
  'Your target(s) has/have been deleted.': 'Votre/Vos objectif(s) a/ont été supprimé(s).',
  'Your target(s) has/have been archieved successfully.':
    'Votre/Vos objectif(s) a/ont été archivé(s).',
  'Clear selected date': 'Effacer la date sélectionnée',
  'Custom and accessible progress for life.':
    'Des progrès personnalisés et accessibles pour la vie.',
  or: 'ou',
  'Please enter your password.': 'Veuillez entrer votre mot de passe.',
  'Forgot my password': 'Mot de passe oublié',
  'Wrong password': 'Mot de passe incorrect',
  'Please type and confirm your password.': 'Veuillez taper et confirmer votre mot de passe.',
  "Passwords fields aren't the same": 'Les champs des mots de passe ne sont pas identiques',
  'Error setting event': "Erreur, l'évennement n'a pas pu être assigné",
  first_day: 'premier jour du mois',
  same_day: 'chaque mois le même jour',
  last_day: 'dernier jour du mois',
  'Training restored successfully': 'Formation restaurée avec succès',
  'Restore training': 'Restauration de formation',
  'The date when the training is launched.': 'La date de lancement de la formation.',
  'Acquisition date has been updated': "La date d'acquisition a été mise à jour.",
  'Error updating acquisition date': "Erreur de modification de la date d'acquisition",
  'Training(s) containing only this module :': 'Formation(s) contenant uniquement ce module :',
  'Manager & Creator': 'Manager & Créateur',
  'Employee & Creator': 'Employé & Créateur',
  'No name available': 'Pas de nom disponible',
  Role: 'Rôle',
  Learnings: 'Apprentissages',
  'Hmm, it seems you have no training yet':
    "Hmm, il semblerait que vous n'ayez pas encore de formation",
  'Hmm, it seems you have no module yet': "Hmm, il semblerait que vous n'ayez pas encore de module",
  'Hmm, it seems you have no target yet':
    "Hmm, il semblerait que vous n'ayez pas encore d'objectif",
  'As participant': 'Comme participant',
  'As reviewer': 'Comme reviewer',
  'As person in charge': 'Comme personne en charge',
  Progress: 'Progress',
  Campaign: 'Campagne',
  'Hmm, it seems you have no campaigns as participant':
    "Hmm, il semblerait que vous n'ayez pas de campagne en tant que participant",
  'Hmm, it seems you have no campaigns as reviewer':
    "Hmm, il semblerait que vous n'ayez pas de campagne en tant que reviewer",
  'Hmm, it seems you have no campaigns as person in charge':
    "Hmm, il semblerait que vous n'ayez pas de campagne en tant que personne en charge",
  'Review of': 'Revue de',
  'In charge of': 'En charge de',
  Departement: 'Département',
  Langue: 'Langue',
  Pays: 'Pays',
  Établissement: 'Établissement',
  'Drop your file here': 'Déposez votre fichier ici',
  'Please choose another file. Only .jpeg, .jpg, or .png file formats are supported.':
    'Veuillez choisir un autre fichier. Seuls les formats .jpeg, .jpg, ou .png sont acceptés.',
  'In early 2024, Booklet will become Aleph.': 'Début 2024, Booklet deviendra Aleph.',
  'Know more': 'En savoir plus',
  'Booklet will become Aleph': 'Booklet va devenir Aleph',
  "What's new ?": 'Quoi de neuf ?',
  'Booklet will become Aleph! The new branding identity includes a refreshed logo, color scheme, and overall aesthetic to convey this new direction and vision. Your login credentials will remain the same, and you will be able to access Aleph with the same username and password you used for Booklet.':
    "Booklet deviendra Aleph! La nouvelle identité de marque comprend un logo rafraîchi, une palette de couleurs et une esthétique générale qui traduisent cette nouvelle orientation et cette nouvelle vision. Vos identifiants de connexion resteront les mêmes, et vous pourrez accéder à Aleph avec le même nom d'utilisateur et le même mot de passe que ceux que vous avez utilisés pour Booklet.",
  'Why Aleph ?': 'Pourquoi Aleph ?',
  "Aleph is a reference to the first letter of the alphabet, and reflects the company's goal to provide a tool that offers customized and accessible progress for life.":
    "Aleph est une référence à la première lettre de l'alphabet et reflète l'objectif de l'entreprise de fournir un outil qui offre du progrès personnalisé et accessible pour la vie.",
  'Will my data still be accessible ?': 'Mes données seront-elles toujours accessibles ?',
  'Yes, all information, data, and action previously done on Booklet will still be available on Aleph. Your data will still remain safe and secure with Aleph. This rebranding does not affect our commitment to protect your data with the highest standards of security and privacy.':
    "Oui, toutes les informations, données et actions effectuées précédemment sur Booklet seront toujours disponibles sur Aleph. Vos données resteront en sécurité chez Aleph. Ce changement de marque n'affecte en rien notre engagement à protéger vos données en appliquant les normes les plus strictes en matière de sécurité et de respect de la vie privée.",
  'Please note that emails will be adressed by Aleph Team':
    "Veuillez noter que les courriels seront adressés par l'équipe d'Aleph",
  'early 2024': 'début 2024',
  'Your profile photo has been updated': 'Votre photo de profil a été mise à jour',
  'Your profile photo has been removed': 'Votre photo de profil a été supprimée',
  'Edit profile photo': 'Modifier la photo de profil',
  'Delete current photo': 'Supprimer la photo',
  'Import photo': 'Importer une photo',
  'To submit the form, all required fields must be completed.':
    "pour soumettre l'interview, tous les champs requis doivent être remplis.",
  'Tag category': 'Catégorie de tags',
  'Happy Monday, !': 'Joyeux lundi, {firstname}!',
  'Hello , have a great Tuesday!': 'Bonjour {firstname}, passez un excellent mardi !',
  'Hey, . Happy Wednesday!': 'Bonjour, {firstname}. Joyeux mercredi !',
  'Greetings, . Enjoy your Thursday!': 'Salutations, {firstname}. Profitez de votre jeudi !',
  "Hello , it's Friday!": "Bonjour {firstname}, c'est vendredi !",
  'Happy Saturday, !': 'Joyeux samedi, {firstname}!',
  'Good day, . Have a peaceful Sunday!':
    'Bonne journée, {firstname}. Passez un dimanche paisible !',
  "You are currently logged in company's workspace":
    "Vous êtes actuellement connecté dans l'espace de travail de {company}",
  'Error while deleting shared information.':
    "Erreur lors de la suppression de l'information partagée.",
  'Shared information has been saved.': "L'information partagée a été sauvegardée.",
  'Change photo': 'Modifier image',
  'Image updated successfully.': 'Image modifiée avec succès.',
  'Error updating image.': "Erreur de modification de l'image.",
  'Tag Category name has been saved.': 'Le nom de la catégorie de tags a été modifié.',
  'Tag Category has been created.': 'La catégorie de tags a été crée.',
  'Your file has the incorrect format, please select a file with the .xlsx format':
    "Votre fichier n'a pas le bon format, le format attendu est .xlsx.",
  'Error, the selected file format is not correct. Accepted formats are: jpg, jpeg and png.':
    'Erreur, le format du fichier selectionné est incorrect. Les formats acceptés sont: jpg, jpeg et png.',
  'Error updating Company name.': "Erreur de modification du nom de l'entreprise.",
  'Company name updated.': "La dénomination de l'entreprise a été modifiée.",
  'New tag': 'Nouveau tag',
  'Do you really want to delete this target permanently ?':
    'Voulez-vous vraiment supprimer définitivement cette objectif ?',
  'Do you really want to delete this campaign permanently ?':
    'Voulez-vous vraiment supprimer définitivement cette campagne ?',
  'Do you really want to delete this report permanently ?':
    'Voulez-vous vraiment supprimer définitivement ce rapport ?',
  'Do you really want to delete this template permanently ?':
    'Voulez-vous vraiment supprimer définitivement ce modèle ?',
  'This tag already exists in this category': 'Ce tag existe déjà dans cette catégorie.',
  'The tag name cannot be empty.': 'Le nom du tag ne peut être vide.',
  'Error creating Tag category.': 'Erreur de création de la catégorie de tags.',
  'Error deleting Tag category': 'Erreur de suppression de la catégorie de tags.',
  'Error updating Tag name.': 'Erreur lors de la modification du nom du tag.',
  'Error updating category name.': 'Erreur lors de la modification du nom de la catégorie.',
  'Error creating new Tag.': "Erreur lors de la création d'un nouveau tag.",
  'Error deleting Tag.': 'Erreur lors de la suppression du tag.',
  'Email not found': 'Email introuvable',
  'Log in': 'Connexion',
  'This question requires an answer.': 'Cette question nécessite une réponse.',
  'Error updating user data': "Erreur lors de la modification des informations de l'utilisateur",
  'This module is in trash.': 'Ce module est à la corbeille.',
  'This template is in trash.': 'Ce modèle est à la corbeille.',
  'This playlist is in trash.': 'Cette playlist est à la corbeille.',
  'This module draft is in trash.': 'Ce brouillon de module est à la corbeille.',
  'This training is in trash.': 'Cette formation est à la corbeille.',
  'Module deleted successfully': 'Module supprimé avec succès',
  'Module draft deleted successfully': 'Brouillon de module supprimé avec succès',
  'Playlist deleted successfully': 'Playlist supprimée avec succès',
  'Training deleted successfully': 'Formation supprimée avec succès',
  'Interview form deleted successfully': 'Modèle supprimé avec succès',
  'Module restored successfully, module has been moved to draft':
    'Le module a été correctement restauré et déplacé dans les brouillons',
  'Interview form restored successfully': 'Le modèle a été correctement restauré',
  'Error while showing deleted training':
    'Une erreur est survenue lors de la redirection vers la formation supprimée',
  'Error while showing deleted module':
    'Une erreur est survenue lors de la redirection vers le module supprimé',
  'Error while showing deleted playlist':
    'Une erreur est survenue lors de la redirection vers la playlist supprimée',
  'Error while showing deleted interview form':
    'Une erreur est survenue lors de la redirection vers le modèle supprimé',
  'Error while deleting permanently a training':
    "Une erreur est survenue lors de la suppression définitive d'une formation",
  'Error while deleting permanently a playlist':
    "Une erreur est survenue lors de la suppression définitive d'une playlist",
  'Error while deleting permanently a module':
    "Une erreur est survenue lors de la suppression définitive d'un module",
  'Error while deleting permanently a interview form':
    "Une erreur est survenue lors de la suppression définitive d'un modèle",
  'Error while restoring a training':
    "Une erreur est survenue lors de la restauration d'une formation",
  'Error while restoring a playlist':
    "Une erreur est survenue lors de la restauration d'une playlist",
  'Error while restoring a module': "Une erreur est survenue lors de la restauration d'un module",
  'Error while restoring a interview form':
    "Une erreur est survenue lors de la restauration d'un modèle",
  'Souhaitez-vous vraiment supprimer ce modèle de manière définitive ?':
    'Souhaitez-vous vraiment supprimer ce modèle de manière définitive ?',
  'Restore campaigsn': 'Restaurer la campagne',
  'Restore report': 'Restaurer le rapport',
  'Restore target': "Restaurer l'objectif",
  'Restore template': 'Restaurer le modèle.',
  'Target restored successfully': 'Objectif restaurée avec succès',
  'Target deleted successfully': 'Objectif supprimée avec succès',
  'Template restored successfully': 'Modèle restauré avec succès',
  'Template deleted successfully': 'Modèle supprimé avec succès',
  'Report restored successfully': 'Rapport restauré avec succès',
  'Report successfully permanently deleted': 'Rapport supprimé définitivement avec succès',
  'View deleted campaigns': 'Voir les campagnes supprimées',
  'View deleted templates': 'Voir les modèles supprimés',
  'view deleted campaigns': 'voir les campagnes supprimées',
  'View deleted reports': 'voir les rapports supprimés',
  'Deleted Templates': 'Modèles supprimés',
  'Deleted Campaigns': 'Campagnes supprimées',
  'Deleted Reports': 'Rapports supprimés',
  'Deleted Targets': 'Objectifs supprimées',
  'Deleted targets are stored here for 30 days. After this period, they will be permanently deleted.':
    'Le objectifs supprimées sont stockées ici pendant 30 jours. Passé ce délai, ils seront définitivement supprimés.',
  'Deleted reports are stored here for 30 days. After this period, they will be permanently deleted.':
    'Les rapports supprimés sont stockés ici pendant 30 jours. Passé ce délai, ils seront définitivement supprimés.',
  'Deleted templates are stored here for 30 days. After this period, they will be permanently deleted.':
    'Les modèles supprimés sont stockés ici pendant 30 jours. Passé ce délai, ils seront définitivement supprimés.',
  'Deleted campaigns are stored here for 30 days. After this period, they will be permanently deleted.':
    'Les campagnes supprimées sont stockées ici pendant 30 jours. Passé ce délai, elles seront définitivement supprimées.',
  'You must restore the report to download it':
    'Vous devez restaurer le rapport pour le télécharger',
  'This template is in trash.': 'Ceci est un modèle dans la corbeille.',
  'Error while restoring this template': 'Erreur lors de la restauration de ce modèle',
  'Error while deleting permanently a template':
    "Erreur lors de la suppression définitive d'un modèle",
  'Error while restoring this target': 'Erreur lors de la restauration de cette objectif',
  'Error while deleting permanently a target':
    "Erreur lors de la suppression définitive d'une objectif",
  'Campaign restored successfully': 'Campagne restaurée avec succès',
  'Error while restoring a campaign': "Erreur lors de la restauration d'une campagne",
  'Campaign has been permanently deleted': 'La campagne a été définitivement supprimée',
  'Error while deleting permanently a campaign':
    "Erreur lors de la suppression définitive d'une campagne",
  'This campaign is in trash.': 'Cette campagne est dans la corbeille.',
  'This template is in trash.': 'Ce modèle est dans la corbeille.',
  'This target is in trash.': 'Cette objectif est dans la corbeille.',
  'View deleted targets': 'Afficher les objectifs supprimées',
  'Hmm, it seems you have no deleted trainings.':
    "Hmm, il semble que vous n'ayez aucune formation supprimée.",
  'Hmm, it seems you have no deleted modules.':
    "Hmm, il semble que vous n'ayez aucun module supprimé.",
  'Hmm, it seems you have no deleted playlists.':
    "Hmm, il semble que vous n'ayez aucune playlist supprimée.",
  'Hmm, it seems you have no deleted reports.':
    "Hmm, il semble que vous n'ayez aucun rapport supprimé.",
  'Hmm, it seems you have no deleted templates.':
    "Hmm, il semble que vous n'ayez aucun modèle supprimé.",
  'Hmm, it seems you have no deleted campaigns.':
    "Hmm, il semble que vous n'ayez aucune campagne supprimée.",
  'Hmm, it seems you have no deleted targets.':
    "Hmm, il semble que vous n'ayez aucune cible supprimée.",
  "Hmm, it seems we can't find any results based on your search.":
    'Hmm, il semble que nous ne trouvions aucun résultat basé sur votre recherche.',
  'Link expired': 'Lien expiré',
  'Date & Time': 'Date et heure',
  'Time Zone detected : ': 'Fuseau horaire détecté : ',
  'Time Zone: ': 'Fuseau horaire : ',
  'Add to favorites': 'Ajouter aux favoris',
  'Copy link': 'Copier le lien',
  'Module duplicated.': 'Module dupliqué',
  'This module has been deleted': 'Ce module a été supprimé',
  'Your password has been reset': 'Votre mot de passe a été modifié',
  'This field is required': 'Ce champ est requis',
  'The email is not valid': "L'email n'est pas valide",
  'This email is already taken': 'Cet email est déjà utilisé',
  'Some fields contain errors': 'Certains champs contiennent des erreurs',
  'Please fill the firstname, the lastname and the email':
    "Merci de remplir le prénom, le nom et l'email",
  'Please fill the firstname and the email': "Merci de remplir le prénom et l'email",
  'Please fill the lastname and the email': "Merci de remplir le nom et l'email",
  'Please fill the email': "Merci de remplir l'email",
  'No new content added during import.': "Pas de nouveau contenu ajouté pendant l'import",
  'On line': 'Sur la ligne ',
  'Add filter': 'Ajouter un filtre',
  Filters: 'Filtres',
  contains: 'contient',
  'Restore campaign': 'Restaurer la campagne',
  'One to One': 'One to One',
  Feedback: 'Feedback',
  'View deactivated users': 'Voir les utilisateurs désactivés',
  'Deactivated users': 'Utilisateurs désactivés ({ deactivated })',
  'Deactivated users data still remains on Aleph, but these accounts are no longer accessible by their owner. Their email address can not be used for another account.':
    'Les données des utilisateurs désactivés restent sur Aleph, mais ces comptes ne sont plus accessibles par leur propriétaire. Leur adresse électronique ne peut pas être utilisée pour un autre compte.',
  'User has been reactivated successfully.': "L'utilisateur a été réactivé avec succès.",
  'Reactivate user': "Réactiver l'utilisateur",
  'Hmm, it seems you have no inactive users yet.':
    "Hmm, il semble que vous n'ayez aucun utilisateur désactivé pour moment.",
  'Deactivate selected users': 'Désactiver les utilisateurs sélectionnés',
  'Campaign type': 'Type de campagne',
  is: 'est',
  contains: 'contient',
  'This email is not recognized. Please try again.':
    "Cet email n'est pas reconnue. Veuillez réessayer.",
  'Target description': "Description d'objectif",
  'Impossible to import': "Impossible d'importer",
  'Your file format is not accepted. Only .xlsx format is accepted.':
    "Votre format de fichier n'est pas accepté. Seul le format .xlsx est accepté.",
  'Back to People': 'Retour vers utilisateurs',
  'An approval is required to acquire this module if you do not chose quiz evaluation.The learner will have to answer to the following question at the end of the module: “Did you fully understand this module ?”':
    'Une approbation est nécessaire pour acquérir ce module si vous ne choisissez pas l’évaluation par quiz : "Avez-vous bien compris ce module ?"',
  'Select one or many managers': 'Sélectionnez un ou plusieurs responsables',
  'Your uggested updates': 'Votre mise à jour suggérée',
  Comment: 'Commentaire',
  'Add participants': 'Ajouter des participants',
  'Select a template': 'Sélectionnez un modèle',
  'Hmm, it seems there are no employees in your company yet.':
    "Hmm, il semblerait qu'il n'y ait pas encore d'employé dans votre entreprise.",
  'Add your first employees!': 'Ajouter vos premiers employés!',
  'Remove participant': 'Retirer le participant',

  'Request a demo': 'Demander une démo',
  "Would you like to learn about Aleph's features and how it can benefit your company? Our team is ready to show you with a demo!":
    "Vous souhaitez en savoir plus sur les fonctionnalités d'Aleph et sur les avantages qu'il peut apporter à votre entreprise ? Notre équipe est prête à vous faire une démonstration !",
  Firstname: 'Prénom',
  Lastname: 'Nom',
  'Email*': 'Email*',
  Send: 'Envoyer',
  'Your message*': 'Votre message*',
  'Recommend Aleph': 'Recommander Aleph',
  'Do you want to recommend Aleph to someone? Please fill out this form, our team will contact the interested person very soon!':
    "Vous souhaitez recommander Aleph à quelqu'un ? Remplissez ce formulaire, notre équipe prendra contact avec la personne intéressée dans les plus brefs délais !",
  'Wrong email format !': "Mauvais format d'email !",
  'Add user': 'Ajouter un utilisateur',
  'This module has no more things to teach you. We hope you have learnt the best from it !':
    "Ce module n'a plus rien à vous apprendre. Nous espérons que vous en avez tiré le meilleur parti !",
  'assessment by': 'évaluation par',
  'started at': 'a démarré à',
  on: 'le',
  'validated answers at': 'a validé sees réponses à',
  '🙌 You’ve reached the end! Please notify your expert before starting the evaluation.':
    "🙌 Vous avez atteint la fin ! Veuillez avertir votre expert avant de commencer l'évaluation.",
  '🙌 You’ve reached the end! Validate your evaluation to complete this module.':
    '🙌 Vous avez atteint la fin ! Validez votre évaluation pour terminer ce module.',
  '✅ Evaluation done, you have acquired this module!':
    '✅ Évaluation terminée, vous avez acquis ce module !',
  'To successfully complete this module, your answers must be reviewed in real time by your expert.':
    'Pour terminer ce module, vos réponses doivent être examinées en temps réel par votre expert.',
  'This module has been acquired, but you can still restart the evaluation.':
    "Ce module a été acquis, mais vous pouvez toujours redémarrer l'évaluation.",
  'Start evaluation': "Démarrer l'évaluation",
  'Continue evaluation': "Continuer l'évaluation",
  'Restart evaluation': "Redémarrer l'évaluation",
  'Face-to-face evaluation': 'Évaluation en face-à-face',
  'Select your expert': 'Sélectionnez votre expert',
  'Please select the expert who will review your answers. After validation, they will receive a copy of this evaluation by email.':
    "Veuillez sélectionner l'expert qui examinera vos réponses. Après validation, il recevra une copie de cette évaluation par e-mail.",
  'My expert': 'Mon expert',
  'One more module acquired 👏': 'Un module de plus acquis 👏',
  'Validate answers': 'Valider les réponses',
  'Congratulations, keep it up! Your answers have been sent to':
    'Félicitations, {user}, continuez comme ça ! Vos réponses ont été envoyées à {expertFirstname} {expertLastname}.',
  'Go to catalog': 'Retourner au catalogue',
  'Please fill all the required (*) fields.': 'Veuillez remplir tous les champs requis (*)',
  'Question title': 'Titre de la question',
  'Add an open question block': 'Ajouter un bloc de question ouverte',
  'Learners can answer your question in an open field':
    'Les apprenants peuvent répondre à votre question dans un champ libre',
  "The learner's answers will be evaluated in real time by an expert, ensuring accurate assessment and personalized feedback.":
    "Les réponses de l'apprenant seront évaluées en temps réel par un expert, garantissant une évaluation précise et un retour personnalisé.",
  'The learner must pass the quiz with a score of 100% correct answers to successfully complete this section.':
    "L'apprenant doit réussir le quiz avec un score de 100% de bonnes réponses pour terminer cette section avec succès.",
  'The learner must confirm they have fully understood the module before proceeding.':
    "L'apprenant doit confirmer qu'il a pleinement compris le module avant de continuer.",
  'Evaluation type': "Type d'évaluation",
  'An error occurred, please try again': 'Une erreur est survenue, veuillez réessayer',
  'Error uploading training image, file size exceeding 10Mb.':
    "Erreur lors de la mise à jour de l'image de la formation, la taille du fichier dépasse 10Mb.",
  'Training updated successfully.': 'Formation mise à jour avec succès.',
  'Training created successfully.': 'Formation crée avec succès.',
  "Don't forget the face-to-face evaluation!": 'Nsoubliez pas lsévaluation en face à face !',
  'Start evaluation now': "Commencez l'évaluation maintenant",
  'To mark this module as “acquired”, you must pass the face-to-face evaluation.':
    "Pour marquer ce module comme “acquis”, vous devez réussir l'évaluation en face-à-face",
  'Evaluation type must be set.': "Le type d'évaluation doit être défini.",
  'Target completed': 'Objectif complété',
  'Current targets': 'Objectif en cours',
  'Training completed': 'Formation completée',
  'Module acquired': 'Module acquis',
  'Shared information': 'Informations partagées',
  'Help & support': 'Aide & support',
  'If you have any questions or require assistance, please feel free to reach out to the Aleph team by filling out this form. We will treat and respond to your message very soon!':
    "Si vous avez des questions ou si vous avez besoin d'aide, n'hésitez pas à contacter l'équipe Aleph en remplissant ce formulaire. Nous traiterons et répondrons à votre message dans les plus brefs délais !",
  'Your issue*': 'Votre requête*',
  'Please fill out all the required(*) fields.':
    'Veuillez remplir tous les champs obligatoires(*).',
  'Your form has been sent. Thank you!':
    'Votre formulaire a été envoyé. Merci de votre attention !',
  'Error sending form, please try again.': "Erreur d'envoi du formulaire, veuillez réessayer.",
  interviews_submitted_count: '{ count } Interviews soumises',
  interviews_submitted_no_count: '{ count } Interview soumise',
  modules_acquired_count: '{ count } Modules acquis',
  modules_acquired_no_count: '{ count } Module acquis',
  current_targets_count: '{ count } Objectifs en cours',
  current_targets_no_count: '{ count } Objectif en cours',
  'Do you wish to send invitation emails for this training ? They will be sent as soon as the training will be launched.':
    "Souhaitez-vous recevoir des courriels d'invitation pour cette formation ? Ils seront envoyés dès que la formation sera lancée.",
  Customization: 'Personnalisation',
  'Company name': "Nom de l'entreprise",
  'Shared Information': 'Informations partagées',
  'Links you put as SHARED INFORMATION will show up on the homepage and be accessible to all users. Use it to share guidelines or global information to your employees.':
    "Les liens que vous mettez en tant qu'INFORMATIONS PARTAGÉES apparaîtront sur la page d'accueil et seront accessibles à tous les utilisateurs. Utilisez-les pour partager des lignes directrices ou des informations générales avec vos employés.",
  'COMPANY LOGO': "LOGO DE L'ENTREPRISE",
  BANNER: 'BANNIERE',
  'Enhance the identity of your workspace by uploading your company logo in  Aleph. Make your mark in the navbar, creating a branded and  professional look for the tool.':
    'Enhance the identity of your workspace by uploading your company logo in  Aleph. Make your mark in the navbar, creating a branded and  professional look for the tool.',
  'My company logo': 'Logo de mon entreprise',
  'Only *.jpg, *.png and *.jpeg file formats are supported. The newly uploaded image will replace the current logo.':
    "Seuls les formats de fichiers *.jpg, *.png et *.jpeg sont pris en charge. L'image nouvellement téléchargée remplacera le logo actuel.",
  'Elevate collaboration and visual communication with our new Banner  Upload feature in Aleph. Share event highlights, announcements, and team  achievements through eye-catching banners visible to all employees.  Simply upload and engage with your colleagues in this dynamic space.':
    "Améliorez la collaboration et la communication visuelle avec notre nouvelle fonctionnalité de téléchargement de bannières dans Aleph. Partagez les faits marquants d'un événement, les annonces et les réalisations d'une équipe grâce à des bannières accrocheuses visibles par tous les employés. Il vous suffit de télécharger et d'échanger avec vos collègues dans cet espace dynamique.",
  'Homepage banner': "Bannière de la page d'accueil",
  'My Interviews banner': 'Bannière mes interviews',
  'My Team Interviews banner': "Bannière mes interviews d'équipe",
  'Users list': 'Liste des utilisateurs',
  Categories: 'Catégories',
  'CATEGORIES AND TAGS': 'CATÉGORIES ET TAGS',
  'Edit your categories and tags Aleph to fit your needs by easily editing employee data. Keep things organized and up-to-date with a few simple clicks.':
    'Modifiez vos catégories et vos tags Aleph pour les adapter à vos besoins en éditant facilement les données des employés. Il suffit de quelques clics pour que tout soit organisé et mis à jour.',
  'Show all': 'Tout afficher',
  'Show less': 'Afficher moins',
  'Your modifications will be lost.': 'Vos modifications seront perdues.',
  'If you leave without save, your modifications will be lost.':
    'Si vous partez sans sauvegarder, vos modifications seront perdues.',
  'Save and quit': 'Enregistrer et quitter',
  'Personal information': 'Informations personnelles',
  'Hr information': 'Informations RH',
  'Date & time': 'Date et heure',
  'Your settings have been updated': 'Vos paramètres ont été mis à jour',
  'First name': 'Prénom',
  'Last name': 'Nom',
  'Deleted reports are stored for 30 days. After this period, they will be permanently deleted.':
    'Les rapports supprimés sont conservés pendant 30 jours. Passé ce délai, ils seront définitivement supprimés.',
  'Report will be deleted': 'Le rapport sera supprimé',
  'It seems you have no report for the moment.':
    "Il semble que vous n'ayez aucun rapport pour le moment.",
  'Oops, we didn’t find any results matching your search.':
    "Oups, nous n'avons trouvé aucun résultat correspondant à votre recherche.",
  'Nothing to show for now': "Rien à montrer pour l'instant",
  'Report has been deleted successfully.': 'Le rapport a été supprimé avec succès.',
  'Error while deleting permanently a report':
    "Erreur lors de la suppression définitive d'un rapport",
  'Report has been restored successfully.': 'Le rapport a été restauré avec succès.',
  'Error while restoring a report': "Erreur lors de la restauration d'un rapport",
  'You must restore the report to download it':
    'Vous devez restaurer le rapport pour le télécharger',
  'Error deleting your report !': 'Erreur lors de la suppression de votre rapport !',
  'Please fill all required fields.': 'Veuillez remplir tous les champs obligatoires.',
  'Please select at least one campaign.': 'Veuillez sélectionner au moins une campagne.',
  'First name*': 'Prénom*',
  'Last name*': 'Nom*',
  Male: 'Masculin',
  Female: 'Féminin',
  Other: 'Autre',
  'HR information': 'Informations RH',
  'Create user': 'Créer un utilisateur',
  'New user': 'Nouvel utilisateur',
  'Help & Support': 'Aide & Support',
  'Here you can manage all Aleph accounts within your company.':
    'Ici vous pouvez gérer tous les comptes Aleph de votre entreprise.',
  User: 'Utilisateur',
  'Profile picture': 'Photo de profil',
  'No tags added': 'Aucune balise ajoutée',
  'It seems there is no tags added, search for tags and add or create them.':
    "Il semble qu'aucune balise n'ait été ajoutée, recherchez des balises et ajoutez-les ou créez-les.",
  'Search for tags': 'Rechercher des balises',
  'Visible for interviewer': "Visible pour l'intervieweur",
  'Required for interviewer': "Obligatoire pour l'intervieweur",
  'Visible for interviewee': 'Visible pour la personne interrogée',
  'Required for interviewee': 'Obligatoire pour la personne interviewée',
  comment: 'commentaire | commentaires',
  Aleph: 'Aleph',
  'Edit photo': 'Modifier photo',
  Dashboard: 'Tableau de bord',
  'Profile details': 'Détails du profil',
  'Updates will be lost': 'Les changements seront perdus',
  'If you quit without saving, your updates will be lost.':
    'Si vous quittez sans sauvegarder, vos changements seront perdus.',
  'Quit anyway': 'Quitter quand même',
  'Hmm, it seems you have no modules.': "Hmm, il semblerait que vous n'ayez pas de modules.",
  'Remove current photo': 'Retirer la photo actuelle',
  'Drag & drop here or upload a file. Only .jpg, .png and .jpeg file formats are supported, max 10 Mb.':
    'Glissez et déposez ici ou télécharger un fichier. Uniquement les formats .jpg, .png et .jpeg sont suppotés, max 10 Mb.',
  'Aleph Language': 'Langue Aleph',
  'Deleted on': 'Supprimé le',
  Filter: 'Filtre',
  "Import won't be done": "L'import ne sera pas terminé",
  'Quit import': "Quitter l'import",
  'If you quit, you will have to restart the import again.':
    "Si vous quitter, vous devrez recommencer l'import entièrement.",
  'user will be deleted': "l'utilisateur sera supprimé",
  'Restore user': "Restaurer l'utilisateur",
  'Drag & drop here or upload a file.': 'Glissez et déposz ici ou téléchargez un fichier.',
  'Only .xlsx format is accepted.': 'Uniquement le format .xlsx est accepté.',
  'New category': 'Nouvelle catégorie',
  'Categories and Tags': 'Categories et tags',
  'Use categories such as Country, Department... to organize Users data. Inside each category, you can create as many item (tags) as needed.':
    "Utilisez des catégories telles que pays, département... pour organiser les données des utilisateurs. Dans chaque catégorie, vous pouvez créer autant d'éléments (tags) que nécessaire.",
  'Delete category?': 'Supprimer la categorie?',
  'This category and all the associated tags will be permanently lost.':
    'Cette catégorie et tous les tags associés seront perdus.',
  'Category name': 'Nom de la catégorie',
  reply: 'réponse',
  replies: 'réponses',
  'Total duration': 'Durée totale',
  'Comment will be deleted': 'Le commentaire sera supprimé',
  'Do it later': 'Le faire plus tard',
  'I like it': "J'aime",
  'Module will be acquired': 'Le module sera acquis',
  'To mark this module as “acquired”, you must confirm that you have understood it.':
    "pour marquer ce module comme “acquis”, vous devrez confirmer que vous l'avez bien compris.",
  'What did you think of the module?': "Qu'avez-vous pensé du module?",
  'Module acquired!': 'Module acquis!',
  'Comment deleted successfully.': 'Commentaire supprimé avec succès.',
  "Hello and welcome! I'm Aleph AI, your assistant for creating and enriching your training content. Whether it's writing your modules, exploring new ideas, or delving deeper into specific topics, I'm here to support you every step of the way. Don't hesitate to call on me to bring your educational projects to life!":
    'Bonjour et bienvenue ! Je suis Aleph AI, votre assistant pour créer et enrichir vos contenus de formation. Que ce soit pour rédiger vos modules, explorer de nouvelles idées, ou approfondir des sujets spécifiques, je suis là pour vous accompagner à chaque étape. N’hésitez pas à me solliciter pour donner vie à vos projets pédagogiques !',
  'An issue occurred with the AI response': "Un problème est survenu avec la réponse de l'IA",
  'Search and select by categories': 'Chercher et sélectionner par catégories',
};
