<template>
  <div class="w-full py-2 flex flex-col items-center justify-center sm:!flex-row gap-4 sm:!gap-8 bg-surfaceLight">
    <svn-pro-text body-large regular class="text-center">
      {{ title }}
    </svn-pro-text>

    <div class="w-full sm:!w-fit flex flex-col px-5 sm:!px-0 sm:!flex-row gap-2">
      <svn-pro-button
        variant="outlined"
        :text="$t('Restore')"
        @click="emit('restore')"
      />

      <svn-pro-button
        variant="outlined"
        :text="$t('Delete permanently')"
        @click="emit('delete-permanently')"
      />
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
  title: { type: String, default: 'This is in trash.' }
})

const emit = defineEmits(["restore", "delete-permanently"]);
</script>