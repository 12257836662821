<template>
  <pop-up-skeleton
    :title="$t('This campaign will not notify this Slack channel anymore')"
    icon="mdi-slack"
  >
    <template #body>
      <p
        class="mr-1"
        v-html="$t('Notifications from the campaign will be turned off.')"
      />
    </template>

    <template #buttons>
      <div class="w-full flex flex-col justify-center sm:flex-row">
        <v-btn
          variant="outlined"
          color="black"
          class="sm:w-1/2 text-fakeBlack border-darkGrey border-1 normal-case font-normal mb-3 sm:mb-0 sm:mr-4"
          @click="closePopUp"
        >
          {{ $t("Cancel") }}
        </v-btn>
        <v-btn
          variant="outlined"
          class="sm:w-1/2 text-white bg-negativeRed normal-case font-normal mb-3 sm:mb-0"
          @click="confirmPopUp"
        >
          {{ $t("Yes, confirm") }}
        </v-btn>
      </div>
    </template>
  </pop-up-skeleton>
</template>

<script setup>
import {usePopUpStore} from "@/store/pop-up.js";
import PopUpSkeleton from "./PopUpSkeleton.vue";

const { closePopUp, confirmPopUp } = usePopUpStore();

</script>

