export const CampaignTypeBackend = Object.freeze({
  ONE_TO_ONE: "OneToOne",
  FEEDBACK_360: "Feedback",
  SURVEY: "Survey",
});

export const CampaignTypeToStr = ["One to one", "Feedback 360", "Survey"];

export const CampaignTypeToKey = ["one_to_one", "feedback_360", "survey"];

const CampaignTypeEnum = Object.freeze({
  ONE_TO_ONE: 'OneToOne',
  FEEDBACK_360: 'Feedback',
  SURVEY: 'Survey'
});
export default CampaignTypeEnum;
