<template>
  <div
    v-if="answers?.length"
    class="w-full flex flex-col gap-4 items-center"
  >
    <div
      v-for="(item, idx) in answers"
      :key="item"
      class="w-full flex flex-col gap-2 px-6 md:!px-8 pt-4 md:!pt-6 pb-3 md:!pb-6 items-start rounded-[16px] border border-opacity-100 border-borderColor"
    >
      <div class="w-full flex flex-col gap-2">
        <div class="w-full flex flex-col">
          <!-- Avatar & name -->
          <div class="w-full flex justify-between items-center gap-2">
            <div class="flex items-center gap-2">
              <svn-pro-avatar
                :size="isMdScreen ? 32 : 24"
                :firstname="item?.user?.fullname.split(' ')[0]"
                :image="item?.user?.avatar?.['50']"
                :type="isAnonymous ? 'anonym' : item?.user?.avatar?.['50'] ? 'photo' : 'monogram'"
              />

              <svn-pro-text :body-medium="!isMdScreen" :body-large="isMdScreen" medium class="line-clamp-1">
                {{ isAnonymous ? `Participant ${idx}` : item?.user?.fullname }}
              </svn-pro-text>
            </div>

            <svn-pro-icon-button
              variant="text"
              :disabled="isTrashed"
              :color="item?.pinned ? 'primary' : 'onSurfaceVariant'"
              :icon="item?.pinned ? 'custom:mingcute:pin-2-fill' : 'custom:mingcute:pin-2-line'"
              @click="pin(idx, answer?.id, interviewFormId, item?.id, !item?.pinned)"
            />
          </div>

          <!-- Open question -->
          <div v-if="answer?.type !== InterviewQuestionType?.MCQ && answer?.type !== InterviewQuestionType?.RATING">
            <svn-pro-text
              :body-large="isMdScreen"
              :body-medium="!isMdScreen"
              regular
            >
              {{ item?.answer }}
            </svn-pro-text>
          </div>

          <!-- MCQ -->
          <div
            v-if="answer?.type === InterviewQuestionType?.MCQ"
            class="w-fit flex items-center justify-center md:!ml-10 rounded-[8px] border border-opacity-100 border-borderColor px-3 py-1.5"
          >
            <svn-pro-text
              :body-large="isMdScreen"
              :body-medium="!isMdScreen"
              regular
            >
              {{ item?.answer }}
            </svn-pro-text>
          </div>

          <!-- Rating -->
          <div
            v-if="answer?.type === InterviewQuestionType?.RATING"
            class="flex items-center gap-2"
          >
            <template v-if="Number(item?.answer).toFixed(0) !== item?.answer">
              <!-- Full stars -->
              <div
                v-for="i of range(1, Math.floor(item?.answer))"
                :key="i"
              >
                <Icon
                  icon="mingcute:star-fill"
                  height="16"
                  width="16"
                  class="text-onSurface"
                />
              </div>

              <!-- Half star -->
              <div>
                <Icon
                  icon="mingcute:star-half-fill"
                  height="16"
                  width="16"
                  class="text-onSurface"
                />
              </div>

              <!-- Empty star -->
              <div
                v-for="i of range(1, answer?.options - Math.round(item?.answer))"
                :key="i"
              >
                <Icon
                  icon="mingcute:star-line"
                  height="16"
                  width="16"
                  class="text-onSurface"
                />
              </div>
            </template>

            <template v-else>
              <!-- Full stars -->
              <div
                v-for="i of range(1, item?.answer)"
                :key="i"
              >
                <Icon
                  icon="mingcute:star-fill"
                  height="16"
                  width="16"
                  class="text-onSurface"
                />
              </div>

              <!-- Empty star -->
              <div
                v-for="i of range(1, answer?.options - item?.answer)"
                :key="i"
              >
                <Icon
                  icon="mingcute:star-line"
                  height="16"
                  width="16"
                  class="text-onSurface"
                />
              </div>
            </template>
          </div>
        </div>

        <!-- Text/Comments -->
        <svn-pro-text
          v-if="item?.comments"
          body-medium
          regular
        >
          {{ item?.comments }}
        </svn-pro-text>

        <!-- Tags -->
        <div v-if="item?.user?.tags?.length" class="flex items-center flex-wrap gap-2">
          <svn-pro-info-tag
            v-for="tag in item?.user?.tags"
            :key="tag"
            tag-type="info"
            tag-style="filled"
            tag-size="default"
            :text="tag?.tag_name"
            :break-all="true"
          />
        </div>
      </div>

      <!-- Comments drawer button -->
      <svn-pro-button
        variant="text"
        :disabled="isTrashed"
        :text="$t('Comments')"
        @click="setSelectedComment(item, idx)"
      />
    </div>
  </div>

  <div v-else class="mx-auto w-full p-10">
    <svn-pro-text body-medium medium class="text-center">
      {{ $t('No answers yet') }}
    </svn-pro-text>
  </div>

  <bkt-survey-comments
    v-model="drawer"
    :comment="selectedComment?.comment"
    :answer="answer"
    :show-pinned="showPinned"
    :comments="selectedComment?.comment?.messages"
    :answer-interview-id="selectedComment?.comment?.id"
    :is-trashed="isTrashed"
    :current-idx="selectedComment?.idx"
    :is-anonymous="isAnonymous"
    @close="closeAndReset"
    @refetch-answers="refetchAnswers"
    @pin="pin"
  />
</template>

<script setup>
import { ref } from "vue";
import { Icon } from "@iconify/vue";
import { storeToRefs } from "pinia";
import { useMobileStore } from "@/store/mobile.js";
import { InterviewQuestionType } from '@/constants/types';
import BktSurveyComments from "@/components/surveyApp/BktSurveyComments.vue";

const props = defineProps({
  answers: { type: Array, required: true },
  answer: { type: Object, default: () => {} },
  showPinned: { type: Boolean, default: false },
  isTrashed: { type: Boolean, default: true },
  isAnonymous: { type: Boolean, default: false },
  interviewFormId: { type: Number, default: null },
})

const { isMdScreen } = storeToRefs(useMobileStore());

const emit = defineEmits(["pin", "add_comment", "refetch-answers"]);

const drawer = ref(false);
const selectedComment = ref({});

const setSelectedComment = (item, idx) => {
  selectedComment.value.comment = item
  selectedComment.value.idx = idx
  selectedComment.value.answer = props?.answer

  drawer.value = true
}

const closeAndReset = () => {
  selectedComment.value = {}
  drawer.value = false
}

const refetchAnswers = () => {
  emit('refetch-answers')
}

const pin = (idx, question_id, interview_form_id, interview_answer_id, pin_answer) => {
  emit("pin", idx, question_id, interview_form_id, interview_answer_id, pin_answer)
  selectedComment.value.comment = props?.answers?.find(el => el?.id === interview_answer_id)
}

const range = (start, end) => {
  let arr = []
  for (let i = start; i <= end; i++) {
    arr?.push(i)
  }
  return arr
}
</script>