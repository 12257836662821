<template>
  <div class="flex-column-centered font-medium bkt-fakeblack-grey">
    <h1 class="fs-2_4rem font-medium -mt-10">
      {{ $t('Move tags in category') }}
    </h1>
    <p
      class="mb-3"
      style="line-height: 3em"
    >
      {{ $t('When a tag just has been created and add to an item,') }}<br>
      {{ $t('it will appear in the category') }} ‘<span class="font-semibold">{{ $t('Others') }}</span>’.<br>
      {{ $t('You can change its category by clicking on the tag list') }}<br>
      {{ $t('you want. Then type the tag you want to move and select it.') }}<br>
    </p>
  </div>
</template>
