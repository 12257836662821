<template>
  <svn-pro-button
    :variant="variant"
    :text="$t(title)"
    :loading="loading"
    color="primary"
    :prepend-icon="prependIcon"
  />
</template>

<script setup>
import { storeToRefs } from "pinia";
import { useMobileStore } from "@/store/mobile";

const { isMobile } = storeToRefs(useMobileStore());

const props = defineProps({
  loading: { type: Boolean, default: false },
  variant: { type: String, default: "outlined" },
  title: { type: String, default: "Download pdf" },
  prependIcon: { type: String, default: "custom:mingcute:download-2-line" },
});
</script>