export default class Reaction {
  constructor({ classType, count, selected, type }) {
    this.classType = classType;
    this.count = count;
    this.selected = selected;
    this.type = type;
  }

  static fromJson(data) {
    return new Reaction({
      classType: data?.['class_type'],
      count: data?.['count'],
      selected: data?.['selected'],
      type: data?.['type'],
    });
  }
}
