<template>
  <pop-up-skeleton
    :title="$t(data.title)"
    :icon="data.icon"
  >
    <template #body>
      <p class="text-sm  font-normal text-darkGrey">
        {{ data.subtitle }}
      </p>
    </template>

    <template #buttons>
      <div class="flex flex-col sm:flex-row">
        <v-btn
          variant="outlined"
          color="black"
          class="sm:w-1/2 text-fakeBlack border-darkGrey border-1 normal-case font-normal mb-3 sm:mb-0 sm:mr-4"
          @click="closePopUp"
        >
          {{ $t(data.textClose) }}
        </v-btn>
        <v-btn
          variant="outlined"
          class="sm:w-1/2 text-white bg-primary normal-case font-normal mb-3 sm:mb-0"
          @click="confirmPopUp"
        >
          <bkt-spinner
            v-show="data.loading"
            :color="data.color"
          >
            {{ $t(data.textLoading) }}
          </bkt-spinner>
          <span v-show="!data.loading">{{ $t(data.textConfirm) }}</span>
        </v-btn>
      </div>
    </template>
  </pop-up-skeleton>
</template>

<script setup>
import { storeToRefs } from "pinia";
import PopUpSkeleton from "./PopUpSkeleton.vue";
import { usePopUpStore } from "@/store/pop-up.js";
import BktSpinner from "../BktSpinner.vue";

const { data } = storeToRefs(usePopUpStore());
const { closePopUp, confirmPopUp } = usePopUpStore();
</script>
