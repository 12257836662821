<template>
  <div class="!w-full h-30vh flex-column-centered justify-content-center">
    <div class="fs-3_5rem">
      🤔
    </div>
    <p
      v-if="genericFetchEntity.tags && genericFetchEntity.tags.length > 0"
      class="text-center"
    >
      {{ $t('Oops, it seems no item can be found with the tags ') }}
      ‘<span>{{ genericFetchEntity.tags.map(tag => tag.title).join(', ') }}</span>’.<br>
    </p>
    <p
      v-else-if="!search && status"
      class="text-center"
    >
      {{ $t('Oops, it seems no item can be found with the status') }}
      ‘<span>{{ $t(status === "submitted" ? "Submitted" :
        status === "in_progress" ? "In progress" :
        status === "not_started" ? "Not started" :
        "")
      }}</span>’.<br>
    </p>
    <p
      v-else-if="genericFetchEntity.search && checkUserCategories(genericFetchEntity.search.userCategories)"
      class="text-center"
    >
      {{ $t('Oops, it seems no item can be found with the user categories.') }}<br>
    </p>
    <p
      v-else
      class="text-center"
    >
      {{ $t('Oops, it seems no item can be found with the search') }}<span v-if="search !== ''"> '{{ genericFetchEntity?.search?.title || search }}'</span>.<br>
    </p>
    <p class="text-center">
      {{ $t('Please try something else.') }}
    </p>
  </div>
</template>

<script setup>
  defineProps({
    genericFetchEntity: {
      type: Object,
      default: () => ({})
    },
    status: {
      type: String,
      default: ''
    },
    search: {
      type: String,
      default() {
        return ''
    }
  }
  })
  const checkUserCategories = (userCategories) => {
    let ret = false
    if (userCategories == undefined || userCategories.length < 1) return false
    userCategories.forEach(userCat => {
      if (userCat.selectedValue !== '') ret = true
    })
    return ret
  }
</script>
