import { acceptHMRUpdate, defineStore } from 'pinia';
import { genericFunctions, genericStates } from "@/tools/genericFunctions.js";
import axiosService from "@/tools/axios-service.js";

export const useCampaignStore = defineStore({
  id: "campaign",
  state: () => ({
    searchCompanyUsersExcludeCurrentCampaignUsers: [],
    campaign: null,
    campaigns: null,
    campaignOverview: null,
    completionAll: null,
    meta: null,
    set: null,
    dataShow: null,
    completionIndividual: null,
    completionByCategory: null,
    loadingCompletionAll: false,
    laodingCompletionIndividual: false,
    loadingCompletionByCategory: false,
    ...genericStates,
  }),
  getters: {},
  actions: {
    ...genericFunctions,
    async fetchCampaign(id) {
      const campaignStore = useCampaignStore();

      await campaignStore.fetchEntity(`/api/v2/interview_app/campaigns/${id}.json`, "campaign");
    },
    async fetchCampaigns() {
      await this.fetchEntity("/api/v2/interview_app/campaigns.json", "campaigns");
    },
    async resetStates() {
      this.campaigns = null
    },
    async fetchCampaignInterview(id) {
      try {
        const campaignStore = useCampaignStore();

        this.set = null
        await campaignStore.fetchEntity(`/api/v2/interview_app/campaigns/${id}/campaign_interviews_set`, "set_interviews", "set");
      } catch (e) {
        console.log(e)
      }
    },
    async fetchCompletionIndividual(id) {
      try {
        this.laodingCompletionIndividual = true
        const campaignStore = useCampaignStore();

        this.completionIndividual = null
        await campaignStore.fetchEntity(`/api/v2/interview_app/campaigns/${id}/completion_by_individual`, "completion_by_individual", "completionIndividual");
        this.laodingCompletionIndividual = false
      } catch (e) {
        console.log(e)
      }
    },
    async fetchCompletionAll(id) {
      try {
        this.loadingCompletionAll = true
        const campaignStore = useCampaignStore();
        await campaignStore.fetchEntity(`/api/v2/interview_app/campaigns/${id}/completion_all`, "completion_all", "completionAll");
        this.loadingCompletionAll = false
      } catch (e) {
        console.log(e)
      }
    },
    async fetchCompletionByCategory(id, tagCategoryId) {
      try {
        this.loadingCompletionByCategory = true
        const campaignStore = useCampaignStore();

        this.completionByCategory = null
        await campaignStore.fetchEntity(`/api/v2/interview_app/campaigns/${id}/completion_by_category?tag_category_id=${tagCategoryId}`, "completion_by_category", "completionByCategory");
        this.loadingCompletionByCategory = false
      } catch (e) {
        console.log(e)
      }
    },
    async fetchCampaignFeedback(id) {
      try {
        const campaignStore = useCampaignStore();
        await campaignStore.fetchEntity(`/api/v2/interview_app/campaigns/${id}/campaign_feedbacks_set`, "set_feedbacks", "set");
      } catch (e) {
        console.log(e)
      }
    },
    async fetchCampaignSurvey(id) {
      try {
        const campaignStore = useCampaignStore();

        await campaignStore.fetchEntity(`/api/v2/interview_app/campaigns/${id}/campaign_surveys_set`, "survey_interviews", "set");
      } catch (e) {
        console.log(e)
      }
    },
    async resetStates() {
      this.searchCompanyUsersExcludeCurrentCampaignUsers = []
      this.campaign = null
      this.campaignOverview = null
      this.completionAll = null
      this.set = null
      this.dataShow = null
      this.search = null
      this.completionIndividual = null
      this.completionByCategory = null
      this.pagination = null
      this.params = null
    },
    async getSearchCompanyUsersExcludeCurrentCampaignUsers(campaignId, search) {
      var query = "/api/v1/users/users_search?not_campaign_id=" + campaignId + "&page[per]=5"
      if (search) query = query + "&text=" + search
      const res = await axiosService.get(
        query
      );
      this.searchCompanyUsersExcludeCurrentCampaignUsers = res.data.users;
    },
    async updateCampaign(id, params) {
      try {
        const res = await this.updateEntity(`/api/v2/interview_app/campaigns/${id}`, params)

        this.campaign = res.data
        return true
      } catch (e) {
        return false
      }
    },
    async destroyCampaign(id) {
      try {
        await this.deleteEntity(`/api/v2/interview_app/campaigns/${id}`)
      } catch (e) {
        console.log(e)
      }
    }
  }
});


if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useCampaignStore, import.meta.hot));
}
