<template>
  <pop-up-skeleton>
    <template #title>
      <p
        v-for="text in title"
        :key="text"
        class="text-center font-medium text-xl"
      >
        {{ $t(text) }}
      </p>
    </template>

    <template #subtitle>
      <p
        v-for="text in subtitle"
        :key="text"
      >
        {{ $t(text) }}
      </p>
    </template>

    <template #buttons>
      <div
        v-if="data.textClose && data.textConfirm"
        class="w-full flex justify-center sm:flex-row gap-x-3 px-1.5"
      >
        <v-btn
          variant="outlined"
          color="black"
          class="text-fakeBlack border-darkGrey border-1 normal-case font-normal w-1/2 text-xs"
          @click="openSendEmailsPopUpNow"
        >
          {{ $t(data.textClose) }}
        </v-btn>
        <v-btn
          variant="outlined"
          class="text-white bg-primary normal-case font-normal w-1/2 text-xs"
          :color="data.color"
          @click="openSendEmailsPopUpLater"
        >
          {{ $t(data.textConfirm) }}
        </v-btn>
      </div>
    </template>
  </pop-up-skeleton>
</template>

<script setup>
import { storeToRefs } from "pinia";
import { useRouter } from "vue-router";
import { useUserStore } from "@/store/user.js";
import { useCampaignDraftStore } from "@/store/campaign-draft.js";
import { usePopUpStore } from "@/store/pop-up.js";
import { useSnackbar } from "@/store/snackbar.js";
import PopUpSkeleton from "./PopUpSkeleton.vue";
import i18n from "@/plugins/i18n";

const snackbar = useSnackbar();
const router = useRouter();
const { lang } = storeToRefs(useUserStore());
const { data } = storeToRefs(usePopUpStore());
const { createCampaign } = useCampaignDraftStore();
const { openPopUp, closePopUp } = usePopUpStore();
const { campaignDraft } = storeToRefs(useCampaignDraftStore());

const title = data.value.title.split("<br/>")
const subtitle = data.value.subtitle?.split("<br/>")


const openSendEmailsPopUpNow = () => {
  closePopUp();
  openPopUp({
    componentName: "pop-up-validation",
    title: i18n.global.t(`Send invitation emails`),
    subtitle: "Do you wish to send invitations by email for this campaign ? They will be sent now.",
    textClose: "No, thanks",
    textConfirm: "Yes, send emails",
    icon: 'noto-incoming-envelope',
    color: "blue",
    appName: "Interviews",
    sendWithoutEmail: async () => {
      try {
        closePopUp();
        await createCampaign({ sendEmail: false, launchSchedule: false });
        await router.push({ name: "campaigns" });
      } catch (error) {
        const errorData = error?.response?.data?.message;

        if (errorData && errorData.length > 0) {
          snackbar
            .setStatus("error", errorData.join(' '))
            .checkStatus();
        } else {
          snackbar.setStatus("error", error.message).checkStatus();
        }
      }
    },
    sendWithEmail: async () => {
      try {
        closePopUp();
        await createCampaign({ sendEmail: true, launchSchedule: false });
        await router.push({ name: "campaigns" });
      } catch (error) {
        const errorData = error?.response?.data?.message;

        if (errorData && errorData.length > 0) {
          snackbar
            .setStatus("error", errorData.join(' '))
            .checkStatus();
        } else {
          snackbar.setStatus("error", error.message).checkStatus();
        }
      }
    },
  });
}

const openSendEmailsPopUpLater = () => {
  closePopUp();
  openPopUp({
    componentName: "pop-up-validation",
    title: i18n.global.t(`Send invitation emails`),
    subtitle: i18n.global.t(`Do you wish to send invitations by email for this campaign ? They will be sent on`, {date: i18n.global.d(campaignDraft.value.startline, 'short')}),
    textClose: "No, thanks",
    textConfirm: "Yes, send emails",
    icon: 'noto-incoming-envelope',
    color: "blue",
    appName: "Interviews",
    sendWithoutEmail: async () => {
      try {
        closePopUp();
        await createCampaign({ sendEmail: false, launchSchedule: true });
        await router.push({ name: "campaigns" });
      } catch (error) {
        const errorData = error?.response?.data?.message;

        if (errorData && errorData.length > 0) {
          snackbar
            .setStatus("error", errorData.join(' '))
            .checkStatus();
        } else {
          snackbar.setStatus("error", error.message).checkStatus();
        }
      }
    },
    sendWithEmail: async () => {
      try {
        closePopUp();
        await createCampaign({ sendEmail: true, launchSchedule: true});
        await router.push({ name: "campaigns" });
      } catch (error) {
        const errorData = error?.response?.data?.message;

        if (errorData && errorData.length > 0) {
          snackbar
            .setStatus("error", errorData.join(' '))
            .checkStatus();
        } else {
          snackbar.setStatus("error", error.message).checkStatus();
        }
      }
    },
  });
}
</script>
