<template>
  <svn-pro-modal
    v-model="deleteDialog"
    :width="isMdScreen ? '580' : '100%'"
    :title="title"
    no-click-animation
    :actionOneTitle="$t('Delete')"
    :action-two-title="$t('Cancel')"
    :sticky-bottom="isMdScreen ? true : false"
    :close-button-closes-modal="false"
    @click-close="deleteDialog = false"
    @click-primary-button="emit('delete-content')"
    @click-outside="deleteDialog = false"
    @click-secondary-button="deleteDialog = false"
  >
    <template #activator="{ props }">
      {{ null }}
    </template>

    <template #text>
      <div class="flex flex-col gap-6">
        <svn-pro-text
          regular
          body-medium
          color="OnSurfaceVariant"
        >
        {{ description}}
        </svn-pro-text>

        <div
          v-for="item in items"
          :key="item.id"
          class="flex flex-col gap-2 justify-center"
        >
          <div class="flex gap-4">
            <div class="w-[54px]">
              <v-img
                :height="56"
                cover
                :src="item.cover_url?.['500']"
              ></v-img>
            </div>
            <div class="flex items-center">
              <svn-pro-text
                regular
                subtitle-large
                color="OnSurfaceVariant"
              >
                {{ item.title }}
              </svn-pro-text>
            </div>
          </div>

          <svn-pro-divider color="[#767680]" class="w-full" />
        </div>
      </div>
    </template>
  </svn-pro-modal>
</template>

<script setup>
import {ref} from 'vue';
import { storeToRefs } from 'pinia';
import { useMobileStore } from '@/store/mobile';

const props = defineProps({
  items: {
    type: Object,
    default: () => {}
  },
  title: {
    type: String,
    default: () => {}
  },
  description: {
    type: String,
    default: () => {}
  },
})

const deleteDialog = ref(false)

const { isMdScreen } = storeToRefs(useMobileStore())

const emit = defineEmits(['delete-playlist'])

const capitalize = (text) => {
  return text?.charAt(0)?.toUpperCase() + text?.slice(1)?.toLowerCase();
}

defineExpose({
  deleteDialog
})
</script>