<template>

  <div class="w-full flex flex-col gap-6 items-start">
    <svn-pro-text-field
      color="primary"
      variant="outlined"
      clearable
      class="w-full max-w-[368px]"
      clear-icon="mdi-close"
      v-model="searchText"
      :label="$t('Search')"
      prepend-inner-icon="custom:mingcute:search-2-line"
      @update:model-value="search"
    />

    <sub-tab-in-targets
      :user="user"
      :data="roadmapUserTargets"
      :search-text="searchText"
      :headers="learningsHeadersTrainings"
      :pagination-data="pagination"
      @refetch-targets="refetchTargets"
    />
  </div>
</template>

<script setup>
import { debounce } from "lodash";
import { storeToRefs } from "pinia";
import { onMounted, ref, computed, defineProps } from "vue";
import { useRoadmapTargetStore } from "@/store/roadmap-target.js";
import SubTabInTargets from "@/components/BktSubTabTargetsInProfile.vue";

const props = defineProps({
  user: { type: Object, required: true }
});

const { roadmapUserTargets, pagination } = storeToRefs(useRoadmapTargetStore());

const { fetchRoadmapTargetsByUserId } = useRoadmapTargetStore();

const searchText = ref('');
const learningsHeadersTrainings = computed(() => {
  return [
    { 
      title: "Target",
      key: "targets",
      icon: true,
      align: 'start',
      sortable: true
    },
    { 
      title: "Role",
      key: "role",
      icon: false,
      align: 'start',
      sortable: true
    },
    { 
      title: "Completion",
      key: "completion",
      icon: false,
      align: 'start',
      sortable: true
    }
  ]
})

onMounted(async () => {
  try {
    await fetchRoadmapTargetsByUserId(props.user.id, searchText.value)
  } catch (error) {
    console.log(error)
  }
});

const search = debounce(async() => {
  try {
    await fetchRoadmapTargetsByUserId(props.user.id, searchText?.value)
  } catch (error) {
    console.log(error);
  }
}, 300)

const refetchTargets = async({ userId, text, page = 1 }) => {
  try {
    await fetchRoadmapTargetsByUserId(userId, text, page);
  } catch (error) {
    console.log(error)
  }
}
</script>

<style scoped>

:deep(.v-input__control) {
  color: #333333;
  font-weight: 500;
}

.floating-button {
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.20);
}

</style>
