<template>
  <pop-up-skeleton>
    <template #body>
      <div class="-mt-6 mb-8">
        <p class="text-fakeBlack text-xl font-medium">
          {{ $t(`${data?.targetField}`) }}
        </p>
      </div>

      <div class="flex flex-col justify-center space-y-2">
        <div class="border border-fakeBlack opacity-1 px-2 py-1 rounded-[4px] max-h-[228px] overflow-y-auto">
          <p class="text-darkGrey text-start text-sm font-medium">
            {{ data?.initialValue }}
          </p>
        </div>

        <div class="self-center">
          <Icon
            icon="mdi-arrow-down"
            height="24"
            width="24"
            class="text-primary"
          />
        </div>

        <div class="border border-fakeBlack px-2 py-1 rounded-[4px] max-h-[228px] overflow-y-auto">
          <p class="text-darkGrey text-start text-sm font-medium">
            {{ data?.updatedValue }}
          </p>
        </div>
      </div>
    </template>
  </pop-up-skeleton>
</template>

<script setup>
import { Icon } from '@iconify/vue';
import PopUpSkeleton from './PopUpSkeleton.vue';
import { usePopUpStore } from '@/store/pop-up';
import { storeToRefs } from 'pinia';

const { data } = storeToRefs(usePopUpStore());
</script>