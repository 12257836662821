// Exporting the convertKeysToCamel and convertKeysToSnake functions

export default {
  // Function to convert object keys to camel case
  convertKeysToCamel (obj) {
    return keysToCamelOrSnake(obj, toCamel)
  },
  // Function to convert object keys to snake case
  convertKeysToSnake (obj) {
    return keysToCamelOrSnake(obj, toSnake)
  }
}

const keysToCamelOrSnake = function (o, camelOrSnake) {
  if (isObject(o)) {
    const n = {}

    Object.keys(o)
      .forEach((k) => {
        n[camelOrSnake(k)] = keysToCamelOrSnake(o[k], camelOrSnake)
      })

    return n
  } else if (isArray(o)) {
    return o.map((i) => {
      return keysToCamelOrSnake(i, camelOrSnake)
    })
  }

  return o
}

const toCamel = (s) => {
  return s.replace(/([-_][a-z])/ig, (m) => {
    return m.toUpperCase()
      .replace('-', '')
      .replace('_', '')
  })
}

const toSnake = (s) => {
  return s.replace(/[\w]([A-Z])/g, (m) => {
    return m[0] + '_' + m[1]
  }).toLowerCase()
}

const isObject = function (o) {
  return o === Object(o) && !isArray(o) && typeof o !== 'function'
}

const isArray = function (a) {
  return Array.isArray(a)
}
