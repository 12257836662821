// Styles
import "@mdi/font/css/materialdesignicons.css";
import "vuetify/styles";

// Vuetify
import { createVuetify } from "vuetify";
// import { VuetifyDateAdapter } from 'vuetify/labs/date/adapters/vuetify'
import * as components from 'vuetify/components'
import * as labs from "vuetify/labs/components";
import { Icon } from "@iconify/vue";

const alephLight = {
  dark: false,
  colors: {
    background: '#FDFCFC',
    backgroundOverlayMultiplier: 1,
    surface: '#FBF9F9',
    surfaceOverlayMultiplier: 1,
    surfaceBright: '#FBF9F9',
    surfaceBrightOverlayMultiplier: 1,
    surfaceLight: '#F1EEEE',
    surfaceLightOverlayMultiplier: 1,
    surfaceVariant: '#E3E1EC',
    surfaceVariantOverlayMultiplier: 2,
    onSurfaceVariant: '#46464F',
    primary: '#3E52CA',
    primaryOverlayMultiplier: 2,
    primaryDarken1: '#2237B1',
    primaryDarken1OverlayMultiplier: 2,
    secondary: '#535C87',
    secondaryOverlayMultiplier: 1,
    secondaryDarken1: '#3B446D',
    secondaryDarken1OverlayMultiplier: 1,
    error: '#BA1A1A',
    errorOverlayMuliplier: 2,
    info: '#1C1B1B',
    infoOverlayMuliplier: 1,
    success: '#1C6D1E',
    successOverlayMuliplier: 1,
    warning: '#CA8100',
    warningOverlayMuliplier: 1,
    onBackground: '#1C1B1B',
    onSurface: '#1C1B1B',
    onSurfaceBright: '#1C1B1B',
    onSurfaceLight: '#1C1B1B',
    onPrimary: '#FFFFFF',
    onPrimaryDarken1: '#FFFFFF',
    onSecondary: '#FFFFFF',
    onSecondaryDarken1: '#FFFFFF',
    onError: '#FFFFFF',
    onInfo: '#FFFFFF',
    onSuccess: '#FFFFFF',
    onWarning: '#FFFFFF',
    kbd: '#212529',
    onKbd: '#FFFFFF',
    code: '#F5F5F5',
    onCode: '#000000',
    borderColor: "#767680",
    avatarBg1: "#F9C25D",
    avatarBg2: "#85E0DB",
    avatarBg3: "#FFBBBB",
    avatarBg4: "#ABDB84",
    avatarBg5: "#F8A677",
    avatarBg6: "#E1BBFF",
    avatarBg7: "#C9D5E0",
    avatarBg8: "#EFE176",
  },
  variables: {
    'border-color': '#767680',
    'border-opacity': 0.12,
    'high-emphasis-opacity': 0.87,
    'medium-emphasis-opacity': 0.6,
    'disabled-opacity': 0.38,
    'idle-opacity': 0.04,
    'hover-opacity': 0.04,
    'focus-opacity': 0.12,
    'selected-opacity': 0.08,
    'activated-opacity': 0.12,
    'pressed-opacity': 0.12,
    'dragged-opacity': 0.08,
  }
};

const alephDark = {
  dark: true,
  colors: {
    background: '#1C1B1B',
    backgroundOverlayMultiplier: 1,
    surface: '#171717',
    surfaceOverlayMultiplier: 1,
    surfaceBright: '#393838',
    surfaceBrightOverlayMultiplier: 1,
    surfaceLight: '#1F1E1E',
    surfaceLightOverlayMultiplier: 1,
    surfaceVariant: '#46464F',
    surfaceVariantOverlayMultiplier: 2,
    onSurfaceVariant: '#C7C5D0',
    primary: '#BBC3FF',
    primaryOverlayMultiplier: 2,
    primaryDarken1: '#DEE0FF',
    primaryDarken1OverlayMultiplier: 2,
    secondary: '#BBC4F5',
    secondaryOverlayMultiplier: 1,
    secondaryDarken1: '#DDE1FF',
    secondaryDarken1OverlayMultiplier: 1,
    error: '#FFB4AB',
    errorOverlayMuliplier: 2,
    info: '#E5E2E1',
    infoOverlayMuliplier: 1,
    success: '#88DA7C',
    successOverlayMuliplier: 1,
    warning: '#FFCA5F',
    warningOverlayMuliplier: 1,
    onBackground: '#E5E2E1',
    onSurface: '#E5E2E1',
    onSurfaceBright: '#E5E2E1',
    onSurfaceLight: '#E5E2E1',
    onPrimary: '#000E5E',
    onPrimaryDarken1: '#001579',
    onSecondary: '#252E55',
    onSecondaryDarken1: '#303961',
    onError: '#690005',
    onInfo: '#1C1B1B',
    onSuccess: '#003A05',
    onWarning: '#472A00',
    kbd: '#212529',
    onKbd: '#FFFFFF',
    code: '#F5F5F5',
    onCode: '#000000',
    borderColor: "#90909A",
    avatarBg1: "#F9C25D",
    avatarBg2: "#85E0DB",
    avatarBg3: "#FFBBBB",
    avatarBg4: "#ABDB84",
    avatarBg5: "#F8A677",
    avatarBg6: "#E1BBFF",
    avatarBg7: "#C9D5E0",
    avatarBg8: "#EFE176",
  }
}



const interview = {
  dark: false,
  colors: {
    black: '#000000',
    deepBlack: '#111111',
    background: "#FFFFFF",
    surface: "#FFFFFF",
    primary: "#3177B7",
    primaryHover: "#4D93D4",
    primaryActive: "#1D63A3",
    primaryLighten3: "#E2F5FE",
    tooltipBackground: "#DAE6F2",
    tooltipText: "#3177B7",
    accent: "#1260ab",
    secondary: "#71b1f3",
    success: "#86af3f",
    info: "#eed055",
    warning: "#FB8C00",
    grey: '#CDCDCD',
    error: "#FF5252",
    neutral: "#333333",
    interviewBlueSoft: "#EBF2F8",
    fakeBlack: "#333333",
    darkGrey: "#787878",
    middleGrey: "#CDCDCD",
    lightGrey: "#ECECEC",
    veryLightGrey: "#F6F6F6",
    positiveGreen: "#7BC079",
    mediumOrange: "#FFC85C",
    negativeRed: "#FF5656",
    transparentPositiveGreen: "#D8EDD7",
    transparentMediumOrange: "#fff3db",
    transparentNegativeRed: "#FAD4D4",
    mediumOrangeOpacity: "#FFFAEF",
    negativeRedOpacity: "#FFEFEF",
    positiveGreenOpacity: "#F2F9F2",
  },
};
const learn = {
  dark: false,
  colors: {
    black: "#000000",
    deepBlack: '#111111',
    background: '#FFFFFF',
    surface: '#FFFFFF',
    primary: "#EF8C64",
    primaryHover: "#FEA37E",
    primaryActive: "#DF774C",
    primaryLighten3: "#FEF4F0",
    tooltipBackground: "#FEF4F0",
    tooltipText: "#EF8C64",
    accent: "#ff7034",
    secondary: "#ffbea1",
    success: "#86af3f",
    info: "#eed055",
    warning: "#FB8C00",
    grey: '#CDCDCD',
    error: "#FF5252",
    neutral: "#333333",
    trainingOrange: "#EF8C64",
    trainingOrangeSoft: "#FEF4F0",
    fakeBlack: "#333333",
    darkGrey: "#787878",
    middleGrey: "#CDCDCD",
    lightGrey: "#ECECEC",
    veryLightGrey: "#F6F6F6",
    positiveGreen: "#7BC079",
    mediumOrange: "#FFC85C",
    negativeRed: "#FF5656",
    mediumOrangeOpacity: "#FFFAEF",
    negativeRedOpacity: "#FFEFEF",
    positiveGreenOpacity: "#F2F9F2",
    transparentPositiveGreen: "#D8EDD7",
    transparentMediumOrange: "#fff3db",
    transparentNegativeRed: "#FAD4D4"
  }
}
const roadmap = {
  dark: false,
  colors: {
    black: '#000000',
    deepBlack: '#111111',
    background: '#FFFFFF',
    surface: '#FFFFFF',
    primary: "#5C95FF",
    primaryHover: "#78A7FF",
    primaryActive: "#347BFF",
    primaryLighten3: "#EFF5FF",
    tooltipBackground: "#EFF5FF",
    tooltipText: "#5C95FF",
    accent: "#1a69ff",
    secondary: "#8fa7d5",
    success: "#86af3f",
    info: "#eed055",
    grey: '#CDCDCD',
    warning: "#FB8C00",
    error: "#FF5252",
    neutral: "#333333",
    objectiveBlueSoft: "#EFF5FF",
    fakeBlack: "#333333",
    darkGrey: "#787878",
    middleGrey: "#CDCDCD",
    lightGrey: "#ECECEC",
    veryLightGrey: "#F6F6F6",
    positiveGreen: "#7BC079",
    mediumOrange: "#FFC85C",
    negativeRed: "#FF5656",
    mediumOrangeOpacity: "#FFFAEF",
    negativeRedOpacity: "#FFEFEF",
    positiveGreenOpacity: "#F2F9F2",
    transparentPositiveGreen: "#D8EDD7",
    transparentMediumOrange: "#fff3db",
    transparentNegativeRed: "#FAD4D4"
  }
}
const company = {
  dark: false,
  colors: {
    black: '#000000',
    deepBlack: '#111111',
    background: '#FFFFFF',
    surface: '#FFFFFF',
    primary: "#333333",
    primaryHover: "#8c8c8c",
    primaryActive: "#101010",
    primaryLighten3: "#ECECEC",
    tooltipBackground: "#787878",
    tooltipText: "#FFFFFF",
    accent: "#000000",
    secondary: "#656565",
    success: "#86af3f",
    info: "#eed055",
    warning: "#FB8C00",
    grey: '#CDCDCD',
    error: "#FF5252",
    neutral: "#333333",
    fakeBlack: "#333333",
    darkGrey: "#787878",
    middleGrey: "#CDCDCD",
    lightGrey: "#ECECEC",
    veryLightGrey: "#F6F6F6",
    positiveGreen: "#7BC079",
    mediumOrange: "#FFC85C",
    negativeRed: "#FF5656",
    mediumOrangeOpacity: "#FFFAEF",
    negativeRedOpacity: "#FFEFEF",
    positiveGreenOpacity: "#F2F9F2",
    transparentPositiveGreen: "#D8EDD7",
    transparentMediumOrange: "#fff3db",
    transparentNegativeRed: "#FAD4D4"
  }
}

// Custom IconSet to use iconify Icons
const customIconSet = {
  component: Icon,
  aliases: {
    custom: {
      component: Icon,
    },
  },
  props: {
    icon: '',
  },
}

export default createVuetify(
  // https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
  {
    icons: {
      defaultSet: 'mdi',
      sets: {
        custom: customIconSet,
      }
    },
    // date: {
    //   adapter: VuetifyDateAdapter,
    // },
    theme: {
      defaultTheme: 'alephLight',
      themes: {
        alephLight,
        alephDark,
        interview,
        learn,
        roadmap,
        company
      },
    },
    components: {
      ...components,
      ...labs,
    }
  }
)
