<template>
  <router-view />
</template>
  
<script setup>
import { useRoute } from 'vue-router'
import { ref, onUpdated } from "vue";

let routeName = ref(useRoute().name)

onUpdated(() => {
  routeName.value = useRoute().name
})
</script>