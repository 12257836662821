<template>
  <svn-pro-info-tag
    :tag-type="getInfoTagType(acquired, total)"
    :text="`${acquired + '/' + total}`"
    class="w-fit h-fit"
    tag-size="compact"
  />
</template>

<script setup>
const props = defineProps({
  acquired: { type: Number, required: true },
  total: { type: Number, required: true },
})

const getInfoTagType = (acquired, total) => {
  if (acquired == 0) {
    return 'error'
  }
  else if (acquired < total) {
    return 'warning'
  }
  else if (acquired === total) {
    return 'success'
  }
}
</script>