export default class SevenImgObject {
  constructor({
    size30,
    size50,
    size70,
    size100,
    size150,
    size500,
    size1000,
    size1500,
    landscape,
    original,
  }) {
    this.size30 = size30;
    this.size50 = size50;
    this.size70 = size70;
    this.size100 = size100;
    this.size150 = size150;
    this.size500 = size500;
    this.size1000 = size1000;
    this.size1500 = size1500;
    this.landscape = landscape;
    this.original = original;
  }

  static fromJson(data) {
    return new SevenImgObject({
      size30: data?.['30'],
      size50: data?.['50'],
      size70: data?.['70'],
      size100: data?.['100'],
      size150: data?.['150'],
      size500: data?.['500'],
      size1000: data?.['1000'],
      size1500: data?.['1500'],
      landscape: data?.['landscape'],
      original: data?.['original'],
    });
  }
}
