import { defineStore } from "pinia";
import { genericFunctions, genericStates } from "../tools/genericFunctions.js";

export const useMyTeamInterviewsStore = defineStore({
  id: "myTeamInterviews",
  state: () => ({
    campaignsTmp: null,
    campaigns: null,
    campaignsPagi: null,
    campaignsToDo: null,
    campaignsToDoPagi: null,
    campaignsDone: null,
    campaignsDonePagi: null,
    ...genericStates,
  }),
  getters: {},
  actions: {
    ...genericFunctions,
    async fetchMyTeamInterviews(page) {
      try {
        this.updateParams({ interview_status: "all", "page[size]": 5, "page[number]": page })
        await this.fetchEntity(
          `/api/v2/interview_app/my_team_interviews`,
          "campaigns",
          "campaignsTmp"
        );

        if (this.campaigns) {
          this.campaigns = this.campaigns.concat(this.campaignsTmp)
        } else {
          this.campaigns = this.campaignsTmp
        }

        this.campaignsPagi = this.pagination

        if (this.error)
          return 'error'
        else if (this.campaignsPagi.total_pages <= page)
          return 'empty'
        return 'ok'
    } catch (e) {
        console.error(e)
      }
    },
    async fetchMyTeamInterviewsToDo(page) {
      try {
        this.updateParams({ interview_status: "todo", "page[size]": 5, "page[number]": page })
        await this.fetchEntity(
          `/api/v2/interview_app/my_team_interviews`,
          "campaigns",
          "campaignsTmp"
        );
        if (this.campaignsToDo) {
          this.campaignsToDo = this.campaignsToDo.concat(this.campaignsTmp)
        } else {
          this.campaignsToDo = this.campaignsTmp
        }

        this.campaignsToDoPagi = this.pagination

        if (this.error)
          return 'error'
        else if (this.campaignsToDoPagi?.total_pages <= page)
          return 'empty'
          return 'ok'
      } catch (error) {
        console.error(error)
      }
    },
    async fetchMyTeamInterviewsDone(page) {
      try {
        this.updateParams({ interview_status: "done", "page[size]": 5, "page[number]": page })
        await this.fetchEntity(
          `/api/v2/interview_app/my_team_interviews`,
          "campaigns",
          "campaignsTmp"
          );

        if (this.campaignsDone) {
          this.campaignsDone = this.campaignsDone.concat(this.campaignsTmp)
        } else {
          this.campaignsDone = this.campaignsTmp
        }

        this.campaignsDonePagi = this.pagination

        if (this.error)
          return 'error'
        else if (this.campaignsDonePagi?.total_pages <= page)
          return 'empty'
        return 'ok'
      } catch (error) {
        console.error(error)
      }
    },
    async resetStates() {
      this.campaignsTmp = null;
      this.campaigns = null;
      this.campaignsPagi = null;
      this.campaignsToDo = null;
      this.campaignsToDoPagi = null;
      this.campaignsDone = null;
      this.campaignsDonePagi = null;
    }
  },
});
