<template>
  <div class="w-full flex flex-col pt-6 desktop:!pt-10 pb-16 gap-6 bg-surface">
    <!-- Header with company name & company logo -->
    <div class="w-full mx-auto flex h-full justify-center items-center px-5">
      <svn-pro-title h5 medium>
        {{ name }}
      </svn-pro-title>
    </div>

    <!-- Different tabs -->
    <div class="flex flex-col">
      <!-- Tabs -->
      <v-tabs
        v-model="tab"
        :grow="isMobile"
        color="primary"
        class="w-full max-w-[928px] xl:max-w-[1136px] mx-auto"
      >
        <!-- Information Tab -->
        <v-tab
          value="information"
          prepend-icon="custom:mingcute:information-line"
          :text="$t('Information')"
          class="w-1/2 md:w-[180px]"
        />

        <!-- Personnalizatiotabn Tab -->
        <v-tab
          value="customization"
          prepend-icon="custom:mingcute:paint-brush-line"
          :text="$t('Customization')"
          class="w-1/2 md:w-[180px]"
        />
      </v-tabs>

      <!-- Windows -->
      <v-window v-model="tab" class="w-full max-w-[928px] xl:max-w-[1136px] mx-auto">
        <!-- Information window -->
        <v-window-item value="information">
          <window-information :company-links="links" />
        </v-window-item>

        <!-- Personnalization window -->
        <v-window-item value="customization">
          <window-personnalization />
        </v-window-item>
      </v-window>
    </div>
  </div>
</template>

<script setup>
import { storeToRefs } from 'pinia';
import { ref, watch, onMounted } from 'vue';
import { useMobileStore } from '@/store/mobile';
import { useRoute, useRouter } from 'vue-router';
import { useCompanyStore } from '@/store/company';
import WindowInformation from '@/components/companyApp/WindowInformation.vue';
import WindowPersonnalization from '@/components/companyApp/WindowPersonnalization.vue';

onMounted(async () => {
  tab.value = route?.query?.tab
});

const { isMobile } = storeToRefs(useMobileStore());
const { name, links } = storeToRefs(useCompanyStore());

const route = useRoute();
const router = useRouter();
const tab = ref('information');

watch(tab, (newVal, _) => {
  router.push({
    name: 'company',
    query: { tab: newVal }
  })
})

watch(route, () => {
  if (route.name == 'company') {
    tab.value = route?.query?.tab
  }
})
</script>