<template>
  <div class="w-screen h-screen flex items-center justify-center px-5 md:!px-12 py-16 lg:!px-0 lg:!py-0 overflow-y-auto">
    <div class="w-full max-w-[328px] flex h-[608px] md:h-[718px] lg:h-[536px] xl:max-w-[472px] xl:h-[592px] desktop:h-[712px] flex-col gap-4 xl:gap-6 relative justify-center items-center mx-auto">
      <div class="flex flex-col items-start gap-2 xl:gap-8 self-stretch">
        <svn-pro-button
          variant="text"
          :text="$t('Back to login')"
          prepend-icon="custom:mingcute:arrow-left-line"
          @click="router.back()"
        />

        <div class="w-full flex flex-col gap-2 items-start">
          <svn-pro-title :h5="!isMobile" :h6="isMobile" medium color="onSurface">
            {{ $t('Check your mail 📩') }}
          </svn-pro-title>

          <svn-pro-text body-medium regular color="onSurface">
            {{ $t('This is the first time you log in to Aleph. We just sent an email with your password to the address :') }}
          </svn-pro-text>

          <svn-pro-text body-medium bold color="onSurface">
            {{ bktPass?.email }}
          </svn-pro-text>

          <svn-pro-text body-medium regular color="onSurface">
            {{ $t('Use it to log in for the first time. If you did not receive it, check your spams, or click on the button below to send it again.') }}
          </svn-pro-text>
        </div>
      </div>

      <svn-pro-button
        class="w-full"
        variant="flat"
        :disabled="bktPass && bktPass?.timer < 60"
        :text="`Send it again ${bktPass && bktPass.timer < 60 ? `(${60 - bktPass.timer})` : ''}`"
        @click="resend"
      />

      <!-- Aleph Logo -->
      <div class="w-full flex flex-col items-start absolute top-0">
        <div
          class="flex justify-center items-center bg-cover bg-center overflow-hidden w-[120px] h-[34.5px]"
          :style="{'background-image': `url('${logo}')`}"
        />
      </div>
    </div>

    <div class="w-1/2 h-screen  hidden xl:flex">
      <div
        class="flex justify-center items-center bg-cover bg-center overflow-hidden w-full h-full"
        :style="{'background-image': `url('${bgHome}')`}"
      />
    </div>
  </div>
</template>

<script setup>
import { storeToRefs } from "pinia";
import { useRouter } from 'vue-router';
import bgHome from "@/assets/bghome.png";
import { onBeforeUnmount, ref } from 'vue';
import logo from "@/assets/logo_aleph_white.svg";
import { useSnackbar } from "@/store/snackbar.js";
import { useMobileStore } from '@/store/mobile.js';
import axiosService from "@/tools/axios-service.js";

const router = useRouter();
const snackbar = useSnackbar();
snackbar.checkStatus();

const { isMobile, isMdScreen } = storeToRefs(useMobileStore());

const bktPass = ref(
  JSON.parse(window.localStorage.getItem("bktPass"))
)

let interval = null

const setUpInterval = () => {
  interval = setInterval(() => {
    if (bktPass.value.timer > 60 && interval) {
      clearInterval(interval)
    } else {
      bktPass.value.timer += 1
      window.localStorage.setItem("bktPass", JSON.stringify(bktPass.value))
    }
  }, 1000)
}

const resend = async () => {
  const mode = bktPass?.value?.mode

  if (mode === 'create') {
    await axiosService.createPass(bktPass.value.email)
    bktPass.value.timer = 0
    setUpInterval()
  } else {
    snackbar.setBgColor('error').displaySnackBar('No email mode')
  }
}

onBeforeUnmount(() => {
  if (interval) {
    clearInterval(interval)
  }
})


if (bktPass.value && bktPass.value.timer < 60) {
  setUpInterval()
}
</script>
