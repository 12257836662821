<template>
  <div class="w-screen h-screen flex items-center justify-center px-5 md:!px-12 py-16 lg:!px-0 lg:!py-0 overflow-y-auto">
    <div class="w-full max-w-[328px] flex h-[608px] md:h-[718px] lg:h-[536px] xl:max-w-[472px] xl:h-[592px] desktop:h-[712px] flex-col gap-6 relative justify-center items-center mx-auto">
      <!-- Welcome text -->
      <div class="w-full flex flex-col gap-2 items-start">
        <svn-pro-title :h5="!isMobile" :h6="isMobile" medium color="onSurface">
          {{ $t("Create password") }}
        </svn-pro-title>

        <svn-pro-text body-medium regular color="onSurface">
          {{ $t("Please type and confirm your new password.") }}
        </svn-pro-text>
      </div>

      <div class="flex flex-col items-start gap-8 self-stretch">
        <!-- Password input -->
        <svn-pro-text-field
          v-model="password"
          :label="$t('Password')"
          type="password"
          class="w-full"
          autocomplete="new-password"
          variant="outlined"
          :error="passwordError.error"
          :error-messages="passwordError.errorMessage"
          @keyup.enter="reset"
        />

        <!-- Password confirmation input -->
        <svn-pro-text-field
          v-model="passwordConfirmation"
          :label="$t('Confirm password')"
          type="password"
          class="w-full"
          autocomplete="new-password"
          variant="outlined"
          :error="passwordError.error"
          :error-messages="passwordError.errorMessage"
          @keyup.enter="reset"
        />

        <svn-pro-button
          class="w-full"
          variant="flat"
          :text="$t('Reset password')"
          @click="reset"
        />
      </div>

      <!-- Aleph Logo -->
      <div class="w-full flex flex-col items-start absolute top-0">
        <div
          class="flex justify-center items-center bg-cover bg-center overflow-hidden w-[120px] h-[34.5px]"
          :style="{'background-image': `url('${logo}')`}"
        />
      </div>
    </div>

    <div class="w-1/2 h-screen  hidden xl:flex">
      <div
        class="flex justify-center items-center bg-cover bg-center overflow-hidden w-full h-full"
        :style="{'background-image': `url('${bgHome}')`}"
      />
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import { storeToRefs } from "pinia";
import { useRouter, useRoute } from 'vue-router'
import axiosService from "@/tools/axios-service.js";
import { useSnackbar } from "@/store/snackbar.js";
import bgHome from "@/assets/bghome.png";
import logo from "@/assets/logo_aleph_white.svg";
import { useMobileStore } from '@/store/mobile.js';
import { watch } from 'vue';

const snackbar = useSnackbar();
snackbar.checkStatus();
const router = useRouter()
const route = useRoute()

const { isMobile, isMdScreen } = storeToRefs(useMobileStore());

const password = ref()
const passwordConfirmation = ref()
const passwordError = ref({ error: false, errorMessage: '', hide_details: true })

const reset = async () => {
  if (!password.value || !passwordConfirmation.value) {
    passwordError.value = { error: true, errorMessage: "Password or confirmation can't be empty.", hide_details: false }
  } else {
    try {
      const token = route.query.reset_password_token
      if (!token) {
        snackbar.setBgColor('onSurface').displaySnackBar("You have no token come back later")
        return
      } else if (password?.value?.length < 8) {
        passwordError.value = { error: true, errorMessage: "Password must contain at least 8 characters", hide_details: false }
        snackbar.setBgColor('onSurface').displaySnackBar("Password can't be reset")
        return
      } else if (password?.value?.length >= 8 && password.value !== passwordConfirmation.value) {
        passwordError.value = { error: true, errorMessage: "Password fields aren't the same", hide_details: false }
      } else if (password.value && passwordConfirmation.value && password.value !== passwordConfirmation.value) {
        passwordError.value = { error: true, errorMessage: "Password fields aren't the same", hide_details: false }
        snackbar.setBgColor('onSurface').displaySnackBar("Password can't be reset.")
        return
      } else {
        await axiosService.resetPassConfirmation(token, password.value)
    
        router.push({name: 'check'})
        snackbar.setBgColor('onSurface').displaySnackBar("Your password has been reset")
      }
    } catch (e) {
      snackbar.setBgColor('onSurface').displaySnackBar(e?.response?.data ? e.response?.data?.message : e.message)
    }
  }
}

watch(password, (newValue, oldValue) => {
  if (newValue && passwordError.value) {
    passwordError.value = { error: false, errorMessage: "", hide_details: false }
  }
})

watch(passwordConfirmation, (newValue, oldValue) => {
  if (newValue && passwordError.value) {
    passwordError.value = { error: false, errorMessage: "", hide_details: false }
  }
})
</script>

<style scoped>
.v-text-field :deep(.v-input__details) {
  padding-inline: 0px;
}

:deep(.v-field) {
  border-radius: 8px!important;
}
</style>
