<template>
  <div class="w-full h-full relative bg-surface">
    <div class="w-full h-full max-w-[1136px] mx-auto flex flex-col gap-4 items-center pt-5 px-5 md:!px-12">
      <!-- Header Container -->
      <div class="w-full flex flex-col gap-4 xl:!px-0">
        <div class="w-full flex gap-4 justify-between items-center">
          <svn-pro-title :h5="!isMobile" :h6="isMobile" medium>
            {{ $t('Targets') }}
          </svn-pro-title>

          <div class="flex items-center gap-2">
            <!-- New target button -->
            <modal-create-new-target ref="refModalCreateNewTarget" @view-item="goToTargetShow">
              <template #activator="{ props }">
                <svn-pro-button
                  v-bind="props"
                  variant="flat"
                  prepend-icon="custom:mingcute:add-line"
                  :text="$t('New target')"
                  class="hidden md:flex"
                />
              </template>
            </modal-create-new-target>

            <!-- Archive, delete, view recently deleted -->
            <svn-pro-menu>
              <template #activator="{ props }">
                <svn-pro-icon-button
                  v-bind="props"
                  icon="custom:mingcute:more-2-fill"
                  color="onSurfaceVariant"
                  variant="text"
                />
              </template>

              <template #dropdown>
                <v-list-item
                  @click="archiveSelectedTargets"
                  value="archive_selected"
                  :disabled="!selectedUsers?.length"
                >
                  {{ $t('Archive selected targets', { selected: selectedUsers?.length || 0 }) }}
                </v-list-item>

                <svn-pro-dialog-validation
                  :width="312"
                  :action-two-title="$t('Cancel')"
                  :action-one-title="$t('Delete')"
                  :title="$t('Target(s) will be deleted')"
                  :content-text="$t('Deleted targets are stored for 30 days. After this period, they will be permanently deleted.')"
                  @click-primary-button="deleteSelectedTargets"
                >
                  <template #activator="{ props }">
                    <v-list-item
                      v-bind="props"
                      value="delete_selected"
                      class="text-error"
                      :disabled="!selectedUsers?.length"
                    >
                      {{ $t('Delete selected targets', { selected: selectedUsers?.length || 0 }) }}
                    </v-list-item>
                  </template>
                </svn-pro-dialog-validation>

                <modal-view-deleted-items
                  ref="deletedItemsRef"
                  :headers="trashesHeaders"
                  :items="roadmapTrashesTargets"
                  :pagination="paginationTrashes"
                  content-type="Target"
                  @view-item="goToTargetShow"
                  @restore="restoreDeletedItems"
                  @close-modal="closeModal"
                  @toggle-search="toggleSearchDeletedItems"
                  @delete-permanently="deletePermanentlyDeletedItems"
                >
                  <template #activator="{ props }">
                    <v-list-item
                      v-bind="props"
                      :active="false"
                      value="view_deleted"
                    >
                      <svn-pro-text body-large regular>
                        {{ $t('View deleted targets') }}
                      </svn-pro-text>
                    </v-list-item>
                  </template>
                </modal-view-deleted-items>
              </template>
            </svn-pro-menu>
          </div>
        </div>

        <div class="flex flex-col gap-4 md:flex-row">
          <!-- Searchbar -->
          <svn-pro-text-field
            v-model="currentSearch"
            variant="outlined"
            :placeholder="$t('Search')"
            prepend-inner-icon="custom:mingcute:search-2-line"
            @update:model-value="search"
          />

          <div class="flex gap-4 items-center">
            <!-- Indicator Select -->
            <svn-pro-select
              v-model="indicatorType"
              variant="outlined"
              :items="indicatorTypeValues"
              item-title="text"
              item-value="value"
              class="md:!w-[210px]"
              :label="$t('Indicator')"
              @update:model-value="updateParamsAndFetch"
            />

            <!-- Toggle Filter button -->
            <svn-pro-button
              :text="$t('Filter')"
              :append="activeFilterCount ? true : false"
              :prepend-icon="'custom:mingcute:filter-2-line'"
              :variant="activeFilterCount ? 'tonal' : 'text'"
              @click="toggleFilterVisibility"
            >
              <template v-if="activeFilterCount" #append>
                {{ `(${activeFilterCount})` }}
              </template>
            </svn-pro-button>
          </div>
        </div>
      </div>

      <!-- Filters -->
      <svn-filter-results
        class="px-5 xl:!px-0"
        :items="roadmapTargets"
        :headers="filterHeaders"
        :should-show="shouldShow"
        table-name="objective_elements"
        @clear-all-filters="clearAllFilters"
        @refetch-data="fetchTargetsAfterFilter"
        @update-active-filter-count="updateActiveFilterCount"
      />

      <!-- Targets table -->
      <targets-table
        :loading="loading"
        :search="currentSearch"
        :pagination="paginationTargets"
        :roadmap-targets="roadmapTargets"
        @fetch-new-page-page="updateParamsAndFetch"
        @delete-target="deleteTarget"
      />
    </div>

    <svn-pro-floating-action-button
      v-if="!isMdScreen"
      extended
      class="fixed bottom-4 right-4"
      variant="primary"
      :text="$t('New target')"
      prepend-icon="custom:mingcute:add-fill"
      @click="openModalNewTarget"
    />
  </div>
</template>
<script setup>
import { debounce } from "lodash";
import { storeToRefs } from "pinia";
import { ref, onMounted } from "vue";
import i18n from "@/plugins/i18n.js";
import { useRouter } from "vue-router";
import { useUserStore } from "@/store/user.js";
import { useMobileStore } from "@/store/mobile";
import { useSnackbar } from "@/store/snackbar.js";
import axiosService from "@/tools/axios-service.js";
import { useRoadmapTargetStore } from "@/store/roadmap-target.js";
import TargetsTable from "@/components/roadmapApp/target/Table.vue";
import SvnFilterResults from "../../../components/SvnFilterResults.vue";
import ModalCreateNewTarget from "../../../components/BktPopUp/Modals/ModalCreateNewTarget.vue";
import ModalViewDeletedItems from "../../../components/BktPopUp/Dialogs/learn/ModalViewDeletedItems.vue";

onMounted(async() => {
  if (!authorizedAccessLevel?.value?.includes(accessLevel.value)) {
    router.push({ name: "my_roadmaps" });
  }
  loading.value = true;
  try {
    await fetchRoadmapTargets();
    fetchRoadmapTrashesTarget();
  } catch (error) {
    snackbar.setBgColor('error').setMsg('Error fetching targets').setCustomClass(isMobile?.value ? 'mb-[88px]' : '').displaySnackBar();
  }
  loading.value = false;
})

const { isMobile, isMdScreen } = storeToRefs(useMobileStore());
const { accessLevel } = storeToRefs(useUserStore());
const { roadmapTargets, roadmapTrashesTargets, params, selectedUsers } = storeToRefs(useRoadmapTargetStore());

const router = useRouter();
const snackbar = useSnackbar();
const { deletePermanentlyRoadmapTarget, restoreRoadmapTarget, updateParams, deleteRoadmapTarget, setRoadmapTargets } = useRoadmapTargetStore();

const to = ref("");
const from = ref("");
const loading = ref(false);
const currentSearch = ref('');
const shouldShow = ref(false);
const indicatorType = ref(null);
const targetToDelete = ref(null);
const indicatorStatus = ref(null);
const deletedItemsRef = ref(null);
const activeFilterCount = ref(null);
const paginationTargets = ref({});
const paginationTrashes = ref({});
const refModalCreateNewTarget = ref(null);
const authorizedAccessLevel = ref(["super_admin", "admin", "manager_creator"]);
const trashesHeaders = ref([
  {
    align: "start",
    key: "title",
    sortable: true,
    title: i18n.global.t('Target'),
    minWidth: 200,
  },
  {
    align: "start",
    key: "deleted_at",
    sortable: false,
    title: i18n.global.t('Deleted on'),
    minWidth: 150,
  },
  {
    align: "start",
    key: "restore",
    sortable: false,
    title:"",
    width: 40,
    minWidth: 40,
  },
  {
    align: "start",
    key: "delete",
    sortable: false,
    title: "",
    width: 40,
    minWidth: 40,
  }
])
const indicatorTypeValues = ref([
  {
    text: i18n.global.t('All'),
    value: ''
  },
  {
    text: i18n.global.t('True/False'),
    value: 'boolean'
  },
  {
    text: i18n.global.t('Numeric Value'),
    value: 'numeric_value'
  },
  {
    text: i18n.global.t('Percentage'),
    value: 'percentage'
  },
  {
    text: i18n.global.t('Multi choice'),
    value: 'multi_choice'
  },
]);
const filterHeaders = ref([
  {
    name: "title",
    key: "title",
    title: i18n.global.t("Targets"),
    icon: "",
    sortable: false,
  },
  {
    name: "objectivable_id",
    key: "objectivable_id",
    title: i18n.global.t("Users"),
    icon: "",
    sortable: false,
  },
  {
    name: "completion",
    key: "completion",
    title: i18n.global.t("Completion"),
    icon: "",
    sortable: false,
  },
  {
    name: "due_date",
    key: "due_date",
    title: i18n.global.t("Deadline"),
    icon: "",
    sortable: false,
  },
]);

const search = debounce(() => {
  reloadAfterArchiveOrDelete(1, currentSearch?.value)
}, 400)

const update = (page = 1) => {
  updateParams({
    title: params?.value?.title,
    indicator_type: indicatorType?.value,
    indicator_status: indicatorStatus?.value,
    from: from?.value,
    to: to?.value,
    "page[number]": page,
  });
}

const fetchRoadmapTargets = async (page = 1, title = '', indicatorType) => {
  roadmapTargets.value = null
  const req = await axiosService.get(
    `/api/v1/roadmaps/targets`,
    {
      params: {
        'page[number]': page,
        title,
        'indicator_type': indicatorType,
      }
    }
  );
  roadmapTargets.value = req?.data?.objective_elements;
  paginationTargets.value = req?.data?.meta?.pagination;
}

const fetchRoadmapTrashesTarget = async (page = 1, title = '') => {
  roadmapTrashesTargets.value = null
  const req = await axiosService.get(
    `/api/v1/roadmaps/targets/trashes`,
    {
      params: {
        'page[number]': page,
        title
      }
    }
  );
  roadmapTrashesTargets.value = req?.data?.objective_elements;
  paginationTrashes.value = req?.data?.meta?.pagination;
}

const updateParamsAndFetch = (page = 1) => {
  loading.value = true
  setRoadmapTargets(null);
  update(page)
  fetchRoadmapTargets(page, currentSearch?.value, indicatorType?.value);
  loading.value = false
}

const reloadAfterArchiveOrDelete = (page, title) => {
  setRoadmapTargets(null);
  fetchRoadmapTargets(page, title);
}

const deleteTarget = async(id) => {
  try {
    await deleteRoadmapTarget(id);
    snackbar.setCustomClass(isMobile?.value ? 'mb-[88px]' : '').setBgColor('onSurface').setMsg('Target deleted successfully').displaySnackBar()
    reloadAfterArchiveOrDelete()
  } catch (error) {
    console.log(error);
    snackbar.setCustomClass(isMobile?.value ? 'mb-[88px]' : '').setBgColor('error').setMsg('Error while restoring a target').displaySnackBar()
  }
}

const toggleSearchDeletedItems = debounce(async (value) => {
  try {
    updateParams({ title: value })
    await fetchRoadmapTrashesTarget(1, value)
  } catch (e) {
    console.error(e);
  }
}, 300)

const restoreDeletedItems = async (id) => {
  try {
    await restoreRoadmapTarget(id)
    snackbar.setBgColor('onSurface')
    snackbar.setMsg('Target has been restored successfully.')
    snackbar.setCustomClass(isMobile?.value ? 'mb-[88px]' : '')
    snackbar.displaySnackBar()
  } catch (e) {
    snackbar.setBgColor('error')
    snackbar.setMsg('Error while restoring a target')
    snackbar.setCustomClass(isMobile?.value ? 'mb-[88px]' : '')
    snackbar.displaySnackBar()
  }
}

const deletePermanentlyDeletedItems = async () => {
  try {
    await deletePermanentlyRoadmapTarget(targetToDelete?.value)
    snackbar.setBgColor('onSurface')
    snackbar.setMsg('Target ahs been deleted successfully.')
    snackbar.setCustomClass(isMobile?.value ? 'mb-[88px]' : '')
    snackbar.displaySnackBar()
  } catch (e) {
    snackbar.setBgColor('error')
    snackbar.setMsg('Error while deleting permanently a training')
    snackbar.setCustomClass(isMobile?.value ? 'mb-[88px]' : '')
    snackbar.displaySnackBar()
  }
  targetToDelete.value = null
}

const closeModal = (id) => {
  deletedItemsRef.value.deletedItems.dialog = false
  deletedItemsRef.value.deleteTargetRef.dialogRef.dialog = true

  targetToDelete.value = id
}

const goToTargetShow = (targetId) => {
  router.push({ name: 'roadmaps_targets_show', params: { id: targetId } })
}

const openModalNewTarget = () => {
  refModalCreateNewTarget.value.modalNewTarget.dialog = true
}

const archiveSelectedTargets = async() => {
  for (const el of selectedUsers?.value) {
    try {
      await axiosService.put(`/api/v1/roadmaps/targets/${el}`, { status: "archived" });
      snackbar.setCustomClass(isMobile?.value ? 'mb-[88px]' : '').setBgColor("onSurface").displaySnackBar("Targets have been archived successfully.");
    } catch (error) {
      snackbar.setCustomClass(isMobile?.value ? 'mb-[88px]' : '').setBgColor("error").displaySnackBar("Error while archiving your targets.");
    }
  }
  selectedUsers.value = []
  fetchRoadmapTargets();
}

const deleteSelectedTargets = async() => {
  for (const el of selectedUsers?.value) {
    try {
      await axiosService.delete(`/api/v1/roadmaps/targets/${el}`);
      snackbar.setCustomClass(isMobile?.value ? 'mb-[88px]' : '').setBgColor("onSurface").displaySnackBar("Targets have been deleted successfully.");
    } catch (error) {
      snackbar.setCustomClass(isMobile?.value ? 'mb-[88px]' : '').setBgColor("error").displaySnackBar("Error while deleting your targets.");
    }
  }
  selectedUsers.value = []
  fetchRoadmapTargets();
}

const fetchTargetsAfterFilter = async(data) => {
  if (data) {
    params.value = {
      filter_data: JSON.stringify(data),
    }
  } else {
    params.value = {}
  }

  await fetchRoadmapTargetsAfterFilter()
}

const fetchRoadmapTargetsAfterFilter = async() => {
  try {
    await fetchRoadmapTargets()
  } catch (error) {
    console.log(error)
  }
}

const toggleFilterVisibility = () => {
  shouldShow.value = !shouldShow.value
}

const updateActiveFilterCount = (value) => {
  activeFilterCount.value = value
}

const clearAllFilters = async () => {
  params.value = {}
  activeFilterCount.value = null

  await fetchRoadmapTargetsAfterFilter()
}
</script>
