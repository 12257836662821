<template>
  <div class="w-full flex flex-col relative bg-white">
    <bkt-index-skeleton body-class="bkt-bg-white rounded mt-20 mb-32 px-0 pt-0 sm:!pt-5 pb-0">
      <template #title>
        <p class="mt-5 sm:!mt-0 mb-2 font-medium sm:!mb-0">
          {{ $t("All Roadmaps") }}
        </p>
      </template>

      <template #create-index>
        <div class="flex flex-row justify-center sm:!justify-end mt-2 mb-5 sm:!mt-0 sm:!mb-0">
          <bkt-new-target-button :employees="selectedUsersForTargetCreation ? selectedUsersForTargetCreation : []" />
        </div>
      </template>

      <template #body>
        <generic-index-search
          class="px-2"
          :variant="'outlined'"
          :icon="'mdi-magnify'"
          :with-reset="false"
          @fetch="reloadAfterSearch"
        />

        <div v-if="!roadmapEmployees || loading" class="flex justify-center h-[30vh] items-center w-full">
          <svn-loader loading-size="lg" />
        </div>

        <roadmap-employees-table
          v-else-if="roadmapEmployees && roadmapEmployees.length > 0"
          :roadmap-employees="roadmapEmployees"
          @update-selected-users="setSelectedUsersForTargetCreation"
        />

        <bkt-create-entity-from-index
          v-else-if="
            roadmapEmployees && roadmapEmployees.length === 0 && !params?.title
          "
          to="roadmaps_targets_new"
        >
          {{ $t("employee target") }}
        </bkt-create-entity-from-index>

        <bkt-no-entity-from-index
          v-else-if="
            roadmapEmployees && roadmapEmployees.length === 0 && params?.title
          "
        />
      </template>
    </bkt-index-skeleton>
  </div>
</template>
<script setup>
import BktIndexSkeleton from "@/components/BktIndexSkeleton.vue";
import GenericIndexSearch from "@/components/GenericIndexSearch.vue";
import BktCreateEntityFromIndex from "@/components/BktCreateEntityFromIndex.vue";
import BktNoEntityFromIndex from "@/components/BktNoEntityFromIndex.vue";
import BktNewTargetButton from "@/components/button/BktNewTargetButton.vue";
import { storeToRefs } from "pinia";
import { useRoadmapEmployeeStore } from "@/store/roadmap-employee.js";
import RoadmapEmployeesTable from "@/components/roadmapApp/employee/Table.vue";
import {ref} from "vue";
import { onMounted } from "vue";

onMounted(async() => {
  await fetchRoadmapEmployees();
})

const { roadmapEmployees } = storeToRefs(useRoadmapEmployeeStore());
const { fetchRoadmapEmployees, updateParams, setRoadmapEmployees } = useRoadmapEmployeeStore();

const selectedUsersForTargetCreation = ref([]);
const loading = ref(false)

const reloadAfterSearch = (params) => {
  setRoadmapEmployees(null);
  loading.value = true
  updateParams({
    title: params.title,
    "page[number]": 1,
  });
  fetchRoadmapEmployees().then(() => {
    loading.value = false
  });
};

const setSelectedUsersForTargetCreation = (users) => {
  selectedUsersForTargetCreation.value = users;
}
</script>
