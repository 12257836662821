import { defineStore } from "pinia";
import { genericFunctions, genericStates } from "@/tools/genericFunctions.js";
import axiosService from "@/tools/axios-service.js";

export const useLearnThemesStore = defineStore({
  id: "learn-themes",
  state: () => ({
    id: null,
    learnThemes: null,
    ...genericStates,
  }),
  getters: {},
  actions: {
    ...genericFunctions,
    async fetchThemes(text = '') {
      const res = await axiosService.get(`api/v1/themes?page[size]=${200}`, {
        params: {
          text: text,
        }
      })
      this.learnThemes = res.data.themes
    },
    async createTheme(text, themeType) {
      await axiosService.post(`api/v1/themes`, {
        text: text,
        theme_type: themeType,
      })
    },
    async deleteTheme(themeId) {
      await axiosService.delete(`/api/v1/themes/${themeId}`);
      await this.fetchThemes()
    },
  },
});
