<template>
  <svn-pro-comment-list
    :is-mobile="isMobile"
    :header-title="isInThread ? `Thread (${selectedComment?.replies?.length})` : $t('Comments ()', { count: learnModulesComments?.length || 0 })"
    :drawer-large="drawerLarge"
    :isInThread="isInThread"
    :has-list-data="isInThread || learnModulesComments?.length"
    :selectedComment="selectedComment"
    @add-comment-reply="$emit('add-comment-reply', $event)"
    @add-comment-thread-reply="$emit('add-comment-thread-reply', $event)"
    @close-thread="closeThread"
    @close-drawer="closeDrawerAndThread"
  >
    <template #list-data >
      <!-- Reply is clicked, enter Thread -->
      <div v-if="isInThread" class="w-full h-full flex flex-col gap-4">
        <svn-pro-card-comment
          :has-cta="false"
          :comment="selectedComment?.text"
          :date="selectedComment?.created_at"
          :avatar="selectedComment?.user?.avatar?.['50']"
          :username="selectedComment?.user?.fullname"
          :deletable="amICreatorOfEntityOrAdmin || comment?.user?.id === userId"
          :is-module-author="selectedComment?.user?.module_creator"
          @delete-clicked="onDeleteComment(selectedComment)"
        />

        <div class="w-full flex items-center gap-2">
          <svn-pro-text body-medium color="onSurfaceVariant" class="whitespace-nowrap">
            {{ selectedComment?.replies?.length ? selectedComment?.replies?.length > 1 ? `${selectedComment?.replies?.length} ${$t('replies')}` : `${selectedComment?.replies?.length} ${$t('reply')}` : `${selectedComment?.replies?.length} ${$t('reply')}` }}
          </svn-pro-text>

          <svn-pro-divider color="[#767680]" class="w-full" />
        </div>

        <div v-if="selectedComment?.replies?.length" class="w-full flex flex-col gap-6">
          <svn-pro-card-comment
            v-for="(comment, index) in selectedComment?.replies"
            :key="index"
            :has-cta="false"
            :comment="comment?.text"
            :date="comment?.created_at"
            :avatar="comment?.user?.avatar?.['50']"
            :username="comment?.user?.fullname"
            :deletable="amICreatorOfEntityOrAdmin || comment?.user?.id === userId"
            :is-module-author="comment?.user?.module_creator"
            @delete-clicked="onDeleteCommentInThread(comment)"
          />
        </div>
      </div>

      <!-- List of all comments (NOT IN THREAD) -->
      <div
        v-else-if="learnModulesComments?.length"
        v-for="(comment, index) in learnModulesComments"
        :key="index"
        class="w-full flex flex-col gap-4"
      >
        <svn-pro-card-comment
          has-cta
          :comment="comment?.text"
          :date="comment?.created_at"
          :replies="comment?.replies?.length"
          :username="comment?.user?.fullname"
          :reply-button-text="getReplyText(comment?.replies?.length)"
          :tooltip-text="$t('Delete comment')"
          :avatar="comment?.user?.avatar?.['50']"
          :deletable="amICreatorOfEntityOrAdmin || comment?.user?.id === userId"
          :is-module-author="comment?.user?.module_creator"
          @action-clicked="openThreadMenu(comment)"
          @delete-clicked="onDeleteComment(comment)"
        />

        <svn-pro-divider v-if="index !== learnModulesComments?.length - 1" color="[#767680]" class="w-full" />
      </div>
    </template>

    <!-- No comments -->
    <template #empty-list>
      <div
        class="w-full h-full flex flex-col justify-center items-center self-stretch flex-1"
      >
        <svn-pro-text body-large regular>
          {{ $t('No comments yet') }}
        </svn-pro-text>
      </div>
    </template>
  </svn-pro-comment-list>
</template>

<script setup>
import { ref, watch } from 'vue';
import i18n from "@/plugins/i18n.js";

const props = defineProps({
  learnModule: { type: Array },
  userId: { type: Number },
  learnModulesComments: { type: Array },
  isMobile: { type: Boolean, default: false },
  amICreatorOfEntityOrAdmin: { type: Boolean, default: false },
  drawerLarge: { type: Boolean, default: false },
  HeaderTitle: { type: String, default: '' },
})

const emit = defineEmits(['delete-comment', 'delete-comment-in-thread', 'close-drawer', 'update:modelValue'])

const userComment = ref('');
const isInThread = ref(false);
const selectedComment = ref({});

const closeDrawerAndThread = () => {
  emit('close-drawer')
  if (isInThread?.value) {
    isInThread.value = false
    selectedComment.value = {}
  }
}

const openThreadMenu = (comment) => {
  selectedComment.value = comment
  isInThread.value = true
}

const closeThread = () => {
  selectedComment.value = {}
  isInThread.value = false
}


const onDeleteComment = (comment) => {
  emit('delete-comment', comment)
}

const onDeleteCommentInThread = (comment) => {
  emit('delete-comment-in-thread', comment)
}

const getReplyText = (replies) => {
  if (replies === 0) {
    return i18n.global.t('Reply')
  } else if (replies === 1) {
    return i18n.global.t('1 reply')
  } else {
    return i18n.global.t(`${replies} replies`)
  }
}

watch(props, (newValue, oldValue) => {
  if (newValue.learnModulesComments.length) {
    selectedComment.value.replies = newValue.learnModulesComments?.find(comment => comment?.id === selectedComment?.value?.id)?.replies
  }
})
</script>