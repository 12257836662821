<template>
  <svn-pro-modal
    ref="interviewSet"
    :width="872"
    :title="$t('Interview set')"
  >
    <template #activator="{ props }">
      <slot name="activator" :props="props" />
    </template>
    
    <template #text>
      <div class="flex flex-col gap-6 md:!gap-4">
        <div class="flex flex-col gap-2 items-center">
          <svn-pro-avatar
            :size="88"
            :firstname="employee?.firstname"
            :image="employee?.avatar?.['50']"
            :type="employee?.avatar?.['50'] ? 'photo' : 'monogram'"
          />

          <svn-pro-title h6 medium>
            {{ employee?.fullname }}
          </svn-pro-title>
        </div>

        <div class="w-full flex flex-col gap-8 md:!flex-row">
          <div
            v-for="(interview, idx) in [employeeInterview, managerInterview, crossedInterview]"
            :key="idx"
            class="w-full flex flex-col flex-1 gap-6 p-8 rounded-[16px] border border-opacity-100 border-[#767680]"
            :class="interview ? '' : 'hidden opacity-0'"
          >
            <div class="flex flex-col gap-2 items-center">
              <div class="flex flex-col gap-4 items-center">
                <svn-pro-avatar
                  v-if="interview?.label === 'Employee' || interview?.label === 'Manager'"
                  :size="64"
                  :firstname="interview?.label === 'Employee' ? employee?.firstname : interviewer?.firstname"
                  :image="interview?.label === 'Employee' ? employee?.avatar?.['50'] : interviewer?.avatar?.['50']"
                  :type="interview?.label === 'Employee' ? employee?.avatar?.['50'] ? 'photo' : 'monogram' : interviewer?.avatar?.['50'] ? 'photo' : 'monogram'"
                />

                <div v-else class="flex items-center -space-x-6">
                  <svn-pro-avatar
                    :size="64"
                    :firstname="employee?.firstname"
                    :image="employee?.avatar?.['50']"
                    :type="employee?.avatar?.['50'] ? 'photo' : 'monogram'"
                  />

                  <svn-pro-avatar
                    :size="64"
                    :firstname="interviewer?.firstname"
                    :image="interviewer?.avatar?.['50']"
                    :type="interviewer?.avatar?.['50'] ? 'photo' : 'monogram'"
                  />
                </div>

                <svn-pro-title h6 medium>
                  {{ interview?.label === 'Employee' ? $t('Interviewee') : interview?.label === 'Manager' ? $t('Interviewer') : $t('Cross Review') }}
                </svn-pro-title>
              </div>

              <interview-status-chip
                :tag-size="'default'"
                :leading-item="undefined"
                :status="interview?.status"
              />
            </div>

            <svn-pro-button
              :text="$t('View answers')"
              :to="`/v2/interviews/interview_answer/${interview?.id}`"
              :disabled="interview?.status !== 'submitted'"
              variant="flat"
            />
          </div>
        </div>
      </div>
    </template>
  </svn-pro-modal>
</template>

<script setup>
import { ref } from 'vue';
import InterviewStatusChip from '../interviewApp/InterviewStatusChip.vue';

defineProps({
  employee: { type: Object, default: null },
  interviewer: { type: Object, default: null },
  employeeInterview: { type: Object, default: null },
  managerInterview: { type: Object, default: null },
  crossedInterview: { type: Object, default: null },
})

const interviewSet = ref(null)

defineExpose({
  interviewSet
})
</script>