<template>
  <svn-pro-data-table
    :headers="data?.length ? headers : []"
    :items="data ?? []"
    :items-per-page="-1"
    :loading="data ? loading : true"
    item-value="id"
    :pagination-total-pages="paginationData?.total_pages"
    @update-pagination="fetchNewPage"
  >
    <template #item="{ item }">
      <tr class="hover:bg-[#46464F14] active:bg-[#46464F1F]/[0.12]" @click="goToTargetShow(item?.id)">
        <td>
          <svn-pro-data-table-cell
            regular
            body-medium
            cell-type="text"
            :text="item?.title"
          />
        </td>

        <td>
          <svn-pro-data-table-cell
            regular
            body-medium
            cell-type="text"
            :text="item?.role"
          />
        </td>

        <td>
          <my-roadmaps-objective-indicator
            :objective-indicator="item?.objective_indicator"
          />
        </td>
      </tr>
    </template>

    <template #no-data>
      <svn-pro-empty-states
        :variant="searchText ? 'results' : 'index'"
        :title="$t('Nothing to show for now')"
        :size="isMdScreen ? 'default' : 'compact'"
        :supporting-text="searchText ? $t('Oops, we didn’t find any results matching your search.') :
          $t(`Hmm, it seems you have no modules.`)"
      />
    </template>

    <template #loading>
    </template>
  </svn-pro-data-table>
</template>

<script setup>
import { toRefs } from "vue";
import { storeToRefs } from "pinia";
import { useRouter } from "vue-router";
import { useMobileStore } from "@/store/mobile";
import { useRoadmapTargetStore } from "@/store/roadmap-target.js";
import MyRoadmapsObjectiveIndicator from "@/components/roadmapApp/MyRoadmapsObjectiveIndicator.vue";

const props = defineProps({
  user: { type: Object, required: true },
  searchText: {type: String, default: ""},
  headers: { type: Array, required: true },
  data: { type: Array, default: () => [] },
  paginationData: { type: Object, default: () => {} },
});

const emit = defineEmits(['refetch-targets']);

const { isMdScreen } = storeToRefs(useMobileStore());
const { loading } = storeToRefs(useRoadmapTargetStore());

const router = useRouter();
const { searchText } = toRefs(props);
const { setRoadmapUserTargets } = useRoadmapTargetStore();

const goToTargetShow = (targetId) => {
  router.push({ name: 'roadmaps_targets_show', params: { id: targetId } })
}

const fetchNewPage = (page) => {
  setRoadmapUserTargets(null);
  emit('refetch-targets', { userId: props.user.id, text: searchText?.value, page: page })
};
</script>

<style scoped>

:deep(.v-progress-circular--visible) {
  margin-top: 16px;
}

</style>
