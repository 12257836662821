<template>
  <v-navigation-drawer
    :model-value="modelValue"
    location="right"
    temporary
    :width="400"
    @update:model-value="emit('update:modelValue')"
  >
    <svn-pro-comment-list
      :is-mobile="true"
      :header-title="`${$t('Comments')} (${comments?.concat(newComs)?.length || 0})`"
      :drawer-large="modelValue"
      @add-comment-reply="addComment($event)"
      @close-drawer="closeDrawer"
      :has-list-data="true"
    >
      <template #list-data >
        <!-- Content -->
        <div class="w-full flex flex-col gap-4">
          <div class="w-full flex flex-col gap-2">
            <div class="flex items-center gap-2">
              <!-- Avatar Text -->
              <div class="flex flex-1 items-center gap-2">
                <svn-pro-avatar
                  :size="32"
                  :firstname="comment?.user?.fullname"
                  :image="comment?.user?.avatar?.['30']"
                  :type="isAnonymous ? 'anonym' : comment?.user?.avatar?.['30'] ? 'photo' : 'monogram'"
                />

                <svn-pro-text body-large medium class="line-clamp-1">
                  {{ isAnonymous ? `Participant ${currentIdx}` : comment?.user?.fullname }}
                </svn-pro-text>
              </div>

              <!-- Pin button -->
              <svn-pro-icon-button
                v-if="!isInterview"
                variant="text"
                :color="comment?.pinned ? 'primary' : 'onSurfaceVariant'"
                :icon="comment?.pinned ? 'custom:mingcute:pin-2-fill' : 'custom:mingcute:pin-2-line'"
                @click="pin(currentIdx, comment?.question?.id, comment?.question?.interview_app_form_id, comment?.id, !comment?.pinned)"
              />
            </div>

            <!-- Open question -->
            <div v-if="answer?.type !== InterviewQuestionType?.MCQ && answer?.type !== InterviewQuestionType?.RATING">
              <svn-pro-text body-medium medium>
                {{ comment?.answer }}
              </svn-pro-text>
            </div>

            <!-- MCQ -->
            <div
              v-if="answer?.type === InterviewQuestionType?.MCQ"
              class="w-fit flex items-center justify-center md:!ml-10 rounded-[8px] border border-opacity-100 border-borderColor px-3 py-1.5"
            >
              <svn-pro-text body-medium medium>
                {{ comment?.answer }}
              </svn-pro-text>
            </div>

            <!-- Rating -->
            <div
              v-if="answer?.type === InterviewQuestionType?.RATING"
              class="flex items-center gap-2"
            >
              <template v-if="Number(comment?.answer).toFixed(0) !== comment?.answer">
                <!-- Full starts -->
                <div
                  v-for="i of range(1, Math.floor(comment?.answer))"
                  :key="i"
                >
                  <Icon
                    icon="mingcute:star-fill"
                    height="16"
                    width="16"
                    class="text-onSurface"
                  />
                </div>

                <!-- Half star -->
                <div>
                  <Icon
                    icon="mingcute:star-half-fill"
                    height="16"
                    width="16"
                    class="text-onSurface"
                  />
                </div>

                <!-- Empty stars -->
                <div
                  v-for="i of range(1, comment?.question?.options - Math.round(comment?.answer))"
                  :key="i"
                >
                  <Icon
                    icon="mingcute:star-line"
                    height="16"
                    width="16"
                    class="text-onSurface"
                  />
                </div>
              </template>

              <template v-else>
                <!-- Full stars -->
                <div
                  v-for="i of range(1, comment?.answer)"
                  :key="i"
                >
                  <Icon
                    icon="mingcute:star-fill"
                    height="16"
                    width="16"
                    class="text-onSurface"
                  />
                </div>

                <!-- Empty stars -->
                <div
                  v-for="i of range(1, comment?.question?.options - comment?.answer)"
                  :key="i"
                >
                  <Icon
                    icon="mingcute:star-line"
                    height="16"
                    width="16"
                    class="text-onSurface"
                  />
                </div>
              </template>
            </div>
          </div>

          <!-- Comment -->
          <svn-pro-text
            v-if="comment?.comments"
            body-medium
            regular
          >
            {{ comment?.comments }}
          </svn-pro-text>

          <!-- Tags -->
          <div v-if="comment?.user?.tags?.length" class="flex items-center flex-wrap gap-2">
            <svn-pro-info-tag
              v-for="tag in comment?.user?.tags"
              :key="tag?.id"
              tag-type="info"
              tag-style="filled"
              tag-size="compact"
              :text="tag?.tag_name"
              :break-all="true"
            />
          </div>
        </div>

        <!-- Separator -->
        <div class="flex items-center gap-2">
          <svn-pro-text body-medium regular color="onSurfaceVariant" class="whitespace-nowrap">
            {{ userComments?.length ? `${userComments?.length} ${$t('comment', userComments?.length)}` : $t("No comments yet") }}
          </svn-pro-text>

          <v-divider class="border-opacity-100 border-[#46464F]" />
        </div>

        <!-- Comments list -->
        <div v-if="userComments?.length" class="flex flex-col gap-6">
          <div
            v-for="com in userComments"
            :key="com"
            class="flex flex-col gap-2"
          >
            <div class="flex gap-2 items-center">
              <svn-pro-avatar
                :size="32"
                :firstname="com?.user?.fullname?.split(' ')?.[0]"
                :image="com?.user?.avatar?.['50']"
                :type="isAnonymous ? (com?.user?.avatar ? (com?.user?.access_level === 'admin' || com?.user?.access_level === 'super_admin') ? 'photo' : 'anonym' : 'monogram') : (com?.user?.avatar ? 'photo' : 'monogram')"
              />

              <div class="flex flex-col">
                <svn-pro-text medium subtitle-large class="line-clamp-1">
                  {{ isAnonymous ? (com?.user?.access_level === 'admin' || com?.user?.access_level === 'super_admin') ? com?.user?.fullname : `Participant ${currentIdx}` : com?.user?.fullname }}
                </svn-pro-text>

                <svn-pro-text caption color="onSurfaceVariant">
                  {{  moment(com?.created_at).format('ddd DD MMMM, HH:mm') }}
                </svn-pro-text>
              </div>
            </div>

            <div class="flex flex-col px-0.5">
              <svn-pro-text body-medium regular class="break-all">
                {{ com?.text }}
              </svn-pro-text>
            </div>
          </div>
        </div>
      </template>
    </svn-pro-comment-list>
  </v-navigation-drawer>
</template>

<script setup>
import moment from "moment";
import { Icon } from "@iconify/vue";
import axios from "@/tools/axios-service.js";
import { useUserStore } from "@/store/user.js";
import { onMounted, ref, watch, computed } from "vue";
import { InterviewQuestionType } from '@/constants/types';
import { debounce } from "lodash";
import { storeToRefs } from "pinia";
import { useMobileStore } from "@/store/mobile";

const { isMobile } = storeToRefs(useMobileStore());

const props = defineProps({
  modelValue: { type: Boolean, default: false },
  isInterview: { type: Boolean, default: false },
  isAnonymous: { type: Boolean, default: false },
  showPinned: { type: Boolean, default: false },
  comments: { type: Array, default: () => [] },
  comment: { type: Object, default: () => {} },
  answer: { type: Object, default: () => {} },
  answerInterviewId: { type: Number, default: null },
  currentIdx : { type: Number, default: 1 },
  isPdf: { type: Boolean, default: false },
})

const emit = defineEmits(['close', 'update:modelValue', 'refetch-answers', 'pin'])

const { fetchUser, isAdminOrAbove } = useUserStore();

const conv = ref(null);
const newComs = ref([]);
const newComment = ref("");
const newComments = ref([]);
const userComments = computed(() => {
  return newComments?.value?.concat(newComs?.value)
})

onMounted(() => {
  // fetchUser();

  if(conv?.value) {
    conv.value.scrollTop = conv?.value?.scrollHeight
  }
  newComments.value = props?.comments
})

const addComment = debounce(async(text) => {
  try {
    const res = await axios.post(`/api/v2/interview_app/answers/${props?.answerInterviewId}/messages`, {
      text: text
    })
    if ((res.status === 200 || res.status === 201) && !props?.isInterview) {
      newComs?.value?.push({...res.data, created_at: new Date().toString().split("GMT")[0]})
    }

    emit('refetch-answers')
  } catch (e) {
    console.log(e)
  }
}, 300)

const enterAddComment = (e) => {
  if (e.key === "Enter") {
    addComment()
  }
}

const closeDrawer = () => {
  emit('close', false)
  newComments.value = []
  newComs.value = []
}

const range = (start, end) => {
  let arr = []
  for (let i = start; i <= end; i++) {
    arr?.push(i)
  }
  return arr
}

const pin = (idx, question_id, interview_form_id, interview_answer_id, pin_answer) => {
  if (props?.showPinned && !pin_answer) {
    closeDrawer();
  }
  emit("pin", idx, question_id, interview_form_id, interview_answer_id, pin_answer)
}

watch(props, (newValue, oldValue) => {
  if (newValue?.comments) {
    newComments.value = newValue?.comments
  }
  if (newValue?.modelValue === false) {
    newComments.value = []
    newComs.value = []
  }
})
</script>