<template>
  <div class="w-full h-full relative bg-surface">
    <trash-warnning
      v-if="isTrashed"
      title="This template is in trash."
      @restore="restoreDeletedItem"
      @delete-permanently="deleteTemplateRef.dialogRef.dialog = true"
    />

    <div
      class="w-full h-full md:max-w-[500px] lg:max-w-[614px] xl:max-w-[560px] mx-auto flex flex-col gap-6 md:gap-6 xl:gap-10 items-center px-5 md:!px-0 pt-5 md:pt-6 desktop:pt-10"
    >
      <div class="w-full flex items-center justify-between">
        <div class="flex items-center gap-1">
          <div v-if="route?.params?.id">
            <Icon icon="mingcute:edit-2-line" width="24" height="24" class="text-onSurface" />
          </div>

          <svn-pro-title h5 medium>
            {{ route?.params?.id ? $t('Edit a template') : $t('Create a template') }}
          </svn-pro-title>
        </div>

        <!-- Close template creation/edition & go back -->
        <svn-pro-icon-button
          icon="custom:mingcute:close-line"
          variant="text"
          color="onSurfaceVariant"
          @click="router.back()"
        />
      </div>

      <div class="w-full flex flex-col items-start gap-10">
        <div class="w-full flex flex-col items-start gap-10">
          <!-- Informations -->
          <div class="w-full flex flex-col gap-4">
            <svn-pro-title h6 medium>
              {{ $t('Informations') }}
            </svn-pro-title>

            <div class="flex flex-col gap-4">
              <!-- Title -->
              <div class="w-full flex gap-2">
                <svn-pro-text-field
                  variant="outlined"
                  v-model="title"
                  :disabled="isTrashed"
                  :error="titleError"
                  color="primary"
                  :label="$t('Title*')"
                />

                <svn-pro-tooltip :text="titleLocked ? $t('Enable edition') : $t('Disable edition')">
                  <template #activator="{ props }">
                    <svn-pro-icon-button
                      v-bind="!isTrashed ? props : null"
                      :disabled="isTrashed"
                      variant="text"
                      :icon="
                        titleLocked ? 'custom:mingcute:lock-fill' : 'custom:mingcute:lock-line'
                      "
                      :color="titleLocked ? 'primary' : 'onSurfaceVariant'"
                      @click="toggleLockStatus('title')"
                    />
                  </template>
                </svn-pro-tooltip>
              </div>

              <!-- Description -->
              <div class="w-full flex gap-2">
                <svn-pro-text-area
                  v-model="desc"
                  variant="outlined"
                  :disabled="isTrashed"
                  :rows="5"
                  :max-rows="5"
                  :label="$t('Description')"
                />

                <svn-pro-tooltip
                  :text="descriptionLocked ? $t('Enable edition') : $t('Disable edition')"
                >
                  <template #activator="{ props }">
                    <svn-pro-icon-button
                      v-bind="!isTrashed ? props : null"
                      :disabled="isTrashed"
                      variant="text"
                      :icon="
                        descriptionLocked
                          ? 'custom:mingcute:lock-fill'
                          : 'custom:mingcute:lock-line'
                      "
                      :color="descriptionLocked ? 'primary' : 'onSurfaceVariant'"
                      @click="toggleLockStatus('description')"
                    />
                  </template>
                </svn-pro-tooltip>
              </div>

              <!-- Deadline -->
              <div class="w-full flex gap-2">
                <pop-up-edit-date @save="updateDueDate">
                  <template #activator="{ props }">
                    <svn-pro-text-field
                      v-bind="props"
                      variant="outlined"
                      v-model="due_date"
                      :disabled="isTrashed"
                      clearable
                      color="primary"
                      prepend-inner-icon="custom:mingcute:calendar-2-line"
                      :label="$t('Deadline')"
                    />
                  </template>
                </pop-up-edit-date>

                <svn-pro-tooltip
                  :text="dueDateLocked ? $t('Enable edition') : $t('Disable edition')"
                >
                  <template #activator="{ props }">
                    <svn-pro-icon-button
                      v-bind="!isTrashed ? props : null"
                      :disabled="isTrashed"
                      variant="text"
                      :icon="
                        dueDateLocked ? 'custom:mingcute:lock-fill' : 'custom:mingcute:lock-line'
                      "
                      :color="dueDateLocked ? 'primary' : 'onSurfaceVariant'"
                      @click="toggleLockStatus('deadline')"
                    />
                  </template>
                </svn-pro-tooltip>
              </div>
            </div>
          </div>

          <!-- Target indicator -->
          <div class="w-full flex flex-col gap-6">
            <!-- Indicators -->
            <div class="flex flex-col gap-4 items-start">
              <svn-pro-text h6 medium>
                {{ $t('Target indicator') }}
              </svn-pro-text>

              <div class="flex gap-2 items-start">
                <v-item-group mandatory>
                  <div class="w-full flex flex-col md:grid md:grid-cols-2 md:grid-rows-2 gap-4">
                    <v-item
                      v-for="item in getBoxes"
                      :key="item?.type"
                      v-slot="{ isSelected, toggle }"
                      :value="item?.type"
                    >
                      <v-card
                        link
                        color="primary"
                        variant="outlined"
                        :disabled="item?.disabled"
                        class="w-full flex-1 border border-borderColor border-opacity-100"
                        :class="item?.selected ? 'border-primary bgOpacity' : 'border-corderColor'"
                        @click="updateIndicatorType(toggle, item)"
                      >
                        <template #default>
                          <div class="w-full h-full flex items-center gap-2 p-4 rounded">
                            <div class="flex w-full items-center gap-2">
                              <div>
                                <Icon
                                  :icon="item?.icon"
                                  height="40"
                                  width="40"
                                  class="text-onSurface"
                                />
                              </div>

                              <div class="flex flex-col items-start">
                                <svn-pro-text body-large medium>
                                  {{ item?.title }}
                                </svn-pro-text>

                                <svn-pro-text caption color="onSurfaceVariant">
                                  {{ item?.shortDesc }} {{ item?.selected }}
                                </svn-pro-text>
                              </div>
                            </div>
                            <div class="flex items-start self-stretch">
                              <svn-pro-radio-button
                                :model-value="true"
                                :value="item?.selected"
                                color="primary"
                              />
                            </div>
                          </div>
                        </template>
                      </v-card>
                    </v-item>
                  </div>
                </v-item-group>

                <svn-pro-tooltip
                  :text="indicatorLocked ? $t('Enable edition') : $t('Disable edition')"
                >
                  <template #activator="{ props }">
                    <svn-pro-icon-button
                      v-bind="!isTrashed ? props : null"
                      :disabled="isTrashed"
                      variant="text"
                      :icon="
                        indicatorLocked ? 'custom:mingcute:lock-fill' : 'custom:mingcute:lock-line'
                      "
                      :color="indicatorLocked ? 'primary' : 'onSurfaceVariant'"
                      @click="toggleLockStatus('indicator')"
                    />
                  </template>
                </svn-pro-tooltip>
              </div>
            </div>

            <!-- Indicator description & values -->
            <div class="w-full flex flex-col gap-4">
              <svn-pro-text subtitle-large medium>
                {{ getSelectedIndicatorDescription() }}
              </svn-pro-text>

              <div class="w-full flex gap-2 items-start">
                <div class="flex flex-col md:flex-row gap-4 flex-1">
                  <!-- Starting value -->
                  <svn-pro-text-field
                    v-if="indicator?.indicator_type !== 'multi_choice'"
                    v-model="indicator.starting_value"
                    variant="outlined"
                    :error="startingValueError"
                    :label="`${$t('Starting value')}*`"
                    prepend-inner-icon="custom:mingcute:flag-1-fill"
                    :type="indicator?.indicator_type === 'boolean' ? 'text' : 'number'"
                    :disabled="indicator?.attribute_disablement?.indicator_values || isTrashed"
                    @update:model-value="updateStartingValue"
                  />

                  <!-- Target value -->
                  <svn-pro-text-field
                    v-if="indicator?.indicator_type !== 'multi_choice'"
                    v-model="indicator.target_value"
                    variant="outlined"
                    :error="targetValueError"
                    :label="`${$t('Target value')}*`"
                    prepend-inner-icon="custom:mingcute:target-line"
                    :type="indicator?.indicator_type === 'boolean' ? 'text' : 'number'"
                    :disabled="indicator?.attribute_disablement?.indicator_values || isTrashed"
                    @update:model-value="updateTargetValue"
                  />

                  <!-- Other indicator types -->
                  <div
                    v-if="indicator?.indicator_type === 'multi_choice'"
                    class="w-full flex flex-col gap-4"
                  >
                    <div
                      v-for="(multiChoiceValue, idxInput) in indicator?.multi_choice_list"
                      :key="idxInput"
                      class="w-full flex gap-2 items-center"
                    >
                      <svn-pro-checkbox
                        v-model="multiChoiceValue.checkbox"
                        color="primary"
                        :disabled="indicator?.attribute_disablement?.indicator_values || isTrashed"
                      />

                      <svn-pro-text-field
                        :placeholder="$t('Option') + ` ${idxInput + 1}`"
                        variant="outlined"
                        density="comfortable"
                        :model-value="multiChoiceValue.option"
                        :disabled="indicator?.attribute_disablement?.indicator_values || isTrashed"
                        @update:model-value="updateOption(idxInput, $event)"
                      />

                      <svn-pro-icon-button
                        variant="text"
                        color="onSurfaceVariant"
                        icon="custom:mingcute:close-line"
                        :disabled="
                          idxInput === 0 ||
                          indicator?.attribute_disablement?.indicator_values ||
                          isTrashed
                        "
                        @click="removeOption(idxInput)"
                      />
                    </div>

                    <svn-pro-button
                      variant="tonal"
                      class="w-fit"
                      prepend-icon="custom:mingcute:add-line"
                      :text="$t('Add an option')"
                      :disabled="indicator?.attribute_disablement?.indicator_values || isTrashed"
                      @click="addOption"
                    />
                  </div>
                </div>

                <svn-pro-divider
                  v-if="indicator?.indicator_type === 'multi_choice'"
                  vertical
                  class="hidden sm:flex border-[#767680]"
                />

                <svn-pro-tooltip
                  :text="indicatorValueLocked ? $t('Enable edition') : $t('Disable edition')"
                >
                  <template #activator="{ props }">
                    <svn-pro-icon-button
                      v-bind="!isTrashed ? props : null"
                      :disabled="isTrashed"
                      variant="text"
                      :icon="
                        indicatorValueLocked
                          ? 'custom:mingcute:lock-fill'
                          : 'custom:mingcute:lock-line'
                      "
                      :color="indicatorValueLocked ? 'primary' : 'onSurfaceVariant'"
                      @click="toggleLockStatus('indicatorValue', !indicatorValueLocked)"
                    />
                  </template>
                </svn-pro-tooltip>
              </div>
            </div>
          </div>
        </div>

        <svn-pro-button
          class="w-full md:w-1/2 md:self-center"
          :text="$t('Save')"
          :disabled="isTrashed"
          @click="handleSubmit"
        />

        <svn-pro-dialog-validation
          ref="deleteTemplateRef"
          icon="noto:warning"
          :action-two-title="$t('Cancel')"
          :action-one-title="$t('Delete')"
          :title="$t(`Template will be deleted`)"
          :content-text="$t('This is a permanent action.')"
          @click-primary-button="deleteTemplate"
          @click-secondary-button="deleteTemplateRef.dialogRef.dialog = false"
        >
          <template #activator="{ props }">
            <div class="hidden" />
          </template>
        </svn-pro-dialog-validation>
      </div>
    </div>
  </div>
</template>

<script setup>
import moment from 'moment';
import { storeToRefs } from 'pinia';
import { Icon } from '@iconify/vue';
import { useSnackbar } from '@/store/snackbar';
import { ref, watch, computed, onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useMobileStore } from '@/store/mobile.js';
import axiosService from '@/tools/axios-service.js';
import TrashWarnning from '@/components/TrashWarnning.vue';
import { useRoadmapTemplateStore } from '@/store/roadmap-template.js';
import PopUpEditDate from '../../../components/popUpComponents/PopUpEditDate.vue';

onMounted(async () => {
  if (route.params.id) {
    const entity = await fetchRoadmapTemplate(route.params.id);

    mergeToCurrentTemplate(entity);
  }
});

const { isMdScreen } = storeToRefs(useMobileStore());

const { fetchRoadmapTemplate } = useRoadmapTemplateStore();
const { restoreRoadmapTemplate, deletePermanentlyRoadmapTemplate } = useRoadmapTemplateStore();

const snackbar = useSnackbar();
const router = useRouter();
const route = useRoute();

const titleLocked = ref(false);
const descriptionLocked = ref(false);
const dueDateLocked = ref(false);
const indicatorLocked = ref(false);
const indicatorValueLocked = ref(false);
const loading = ref(false);
const id = ref(null);
const title = ref(null);
const isTrashed = ref(false);
const titleError = ref(null);
const desc = ref(null);
const due_date = ref(null);
const startingValueError = ref(false);
const targetValueError = ref(false);
const deleteTemplateRef = ref(null);
const indicator = ref({
  indicator_type: 'boolean',
  starting_value: null,
  target_value: null,
  multi_choice_list: [{ option: '', checkbox: true }],
});
const boxes = ref([
  {
    type: 'boolean',
    icon: 'mingcute:toggle-left-line',
    title: 'True/False',
    shortDesc: 'For non-quantifiable targets, choose true and false values.',
    longDesc:
      'Define the two words you want to measure the objective. False will be the default value, then you could switch to True value.',
    selected: false,
  },
  {
    type: 'numeric_value',
    icon: 'mingcute:hashtag-line',
    title: 'Number',
    shortDesc: 'For quantifiable objectives, chose a start and target value.',
    longDesc: 'Define a starting and a target value to measure the objective completion.',
    selected: false,
  },
  {
    type: 'percentage',
    icon: 'mingcute:sale-line',
    title: 'Percent',
    shortDesc: 'Useful to measure easily a quantifiable objective.',
    longDesc: 'Use a percentage based value to measure the objective completion.',
    selected: false,
  },
  {
    type: 'multi_choice',
    icon: 'mingcute:checkbox-line',
    title: 'Multi-choice',
    shortDesc: 'For non-quantifiable targets, add and edit multiple choices.',
    longDesc:
      "Add options to qualify the objective completion. You can choose which option will be considered a target value by ticking 'target value'.",
    selected: false,
  },
]);
const getBoxes = computed(() => {
  return boxes.value.map((box) => {
    return {
      ...box,
      selected: indicator?.value?.indicator_type === box.type,
      disabled: indicator?.value?.attribute_disablement?.indicator || isTrashed.value,
    };
  });
});

const mergeToCurrentTemplate = (template) => {
  id.value = template.id;
  title.value = template.title;
  desc.value = template.description;
  isTrashed.value = template.status === 'trashed';
  due_date.value = template.due_date;
  indicator.value.indicator_type = template.objective_indicator.indicator_type;
  // this.starting_value = template.objective_indicator.indicator_type == 'multi_choice' ? '' : ''
  indicator.value.starting_value = template.objective_indicator.options.starting_value;
  indicator.value.target_value = template.objective_indicator.options.target_value;
  indicator.value.multi_choice_list = [];

  if (indicator.value.indicator_type === 'multi_choice') {
    indicator.value.multi_choice_list = template.objective_indicator.options?.multi_choice_list;
  }
  titleLocked.value = template?.attribute_disablement?.title;
  descriptionLocked.value = template?.attribute_disablement?.description;
  dueDateLocked.value = template?.attribute_disablement?.due_date;
  indicatorLocked.value = template?.attribute_disablement?.indicator;
  indicatorValueLocked.value = template?.attribute_disablement?.indicator_values;
};

const handleSubmit = async () => {
  loading.value = true;

  if (!title.value) {
    titleError.value = true;
    snackbar
      .setCustomClass('mb-5')
      .setBgColor('onSurface')
      .setMsg('Please fill all required fields (*).')
      .displaySnackBar();
  }

  if (indicator?.value?.indicator_type !== 'multi_choice') {
    if (!indicator?.value?.starting_value) {
      startingValueError.value = true;
      snackbar
        .setCustomClass('mb-5')
        .setBgColor('onSurface')
        .setMsg('Please fill all required fields (*).')
        .displaySnackBar();
    }
    if (!indicator?.value?.target_value) {
      targetValueError.value = true;
      snackbar
        .setCustomClass('mb-5')
        .setBgColor('onSurface')
        .setMsg('Please fill all required fields (*).')
        .displaySnackBar();
    }
    if (startingValueError?.value || targetValueError.value) return;
  }

  try {
    const toSend = {
      title: title.value,
      description: desc.value,
      due_date: due_date.value,
      indicator: indicator.value,
      attribute_disablement: {
        title: titleLocked.value,
        description: descriptionLocked.value,
        due_date: dueDateLocked.value,
        indicator: indicatorLocked.value,
        indicator_values: indicatorValueLocked.value,
      },
    };

    if (route.params.id) {
      await axiosService.patch(`/api/v1/roadmaps/templates/${route.params.id}`, toSend);
      snackbar
        .setCustomClass(isMdScreen?.value ? '' : 'mb-[88px]')
        .setBgColor('onSurface')
        .setMsg('Template updated successfully.')
        .displaySnackBar();
    } else {
      await axiosService.post('/api/v1/roadmaps/templates', toSend);
      snackbar
        .setCustomClass(isMdScreen?.value ? '' : 'mb-[88px]')
        .setBgColor('onSurface')
        .setMsg('Template created successfully.')
        .displaySnackBar();
    }
  } catch (e) {
    console.log('error', e.response.data);
    snackbar
      .setCustomClass(isMdScreen?.value ? '' : 'mb-[88px]')
      .setBgColor('error')
      .setMsg('Error, please try again.')
      .displaySnackBar();
  } finally {
    loading.value = false;
    await router.push({ name: 'roadmaps_templates' });
  }
};

const toggleLockStatus = (fieldName, event) => {
  switch (fieldName) {
    case 'title':
      titleLocked.value = !titleLocked.value;
      break;
    case 'description':
      descriptionLocked.value = !descriptionLocked.value;
      break;
    case 'deadline':
      dueDateLocked.value = !dueDateLocked.value;
      break;
    case 'indicator':
      indicatorLocked.value = !indicatorLocked.value;
      break;
    case 'indicatorValue':
      indicatorValueLocked.value = event;
      break;
    default:
      break;
  }
};

const getSelectedIndicatorDescription = () => {
  switch (indicator?.value?.indicator_type) {
    case 'boolean':
      return boxes?.value?.[0]?.longDesc;
    case 'numeric_value':
      return boxes?.value?.[1]?.longDesc;
    case 'percentage':
      return boxes?.value?.[2]?.longDesc;
    case 'multi_choice':
      return boxes?.value?.[3]?.longDesc;
  }
};

const restoreDeletedItem = async () => {
  try {
    await restoreRoadmapTemplate(id.value);
    snackbar
      .setCustomClass('mb-5')
      .setBgColor('onSurface')
      .setMsg('Template restored successfully.')
      .displaySnackBar();
    isTrashed.value = false;
  } catch (e) {
    snackbar
      .setCustomClass('mb-5')
      .setBgColor('error')
      .setMsg('Error while restoring template.')
      .displaySnackBar();
  }
};

const deleteTemplate = async () => {
  try {
    await deletePermanentlyRoadmapTemplate(id.value);
    snackbar
      .setCustomClass('mb-5')
      .setBgColor('onSurface')
      .setMsg('Template deleted successfully.')
      .displaySnackBar();
    await router.push({ name: 'roadmaps_templates' });
  } catch (e) {
    snackbar
      .setCustomClass('mb-5')
      .setBgColor('error')
      .setMsg('Error while deleting template.')
      .displaySnackBar();
  }
};

const updateIndicatorType = (toggle, item) => {
  toggle();
  if (isTrashed?.value) return null;
  if (!indicator?.value?.attribute_disablement?.indicator) {
    indicator.value.indicator_type = item?.type;
  }
  if (
    indicator?.value?.indicator_type !== 'multi_choice' &&
    (startingValueError?.value || targetValueError?.value)
  ) {
    startingValueError.value = false;
    targetValueError.value = false;
  }
};

const updateDueDate = (date) => {
  due_date.value = moment(date).format('DD/MM/YYYY');
};

const addOption = () => {
  if (!indicator?.value?.attribute_disablement?.indicator_values) {
    const obj = { ...indicator?.value };
    obj.multi_choice_list.push({ option: '', checkbox: false });
  }
};

const updateOption = (idxInput, newOption) => {
  if (indicator?.value?.multi_choice_list.length > idxInput) {
    const obj = { ...indicator?.value };
    obj.multi_choice_list[idxInput]['option'] = newOption;
  }
};

const removeOption = (idx) => {
  if (!indicator?.value?.attribute_disablement?.indicator_values) {
    if (idx !== 0 && !isTrashed?.value) {
      const obj = { ...indicator?.value };
      obj.multi_choice_list.splice(idx, 1);
    }
  }
};

watch(title, (newValue, oldValue) => {
  if (titleError?.value && newValue) {
    titleError.value = false;
  }
});

const updateStartingValue = () => {
  if (startingValueError?.value && indicator?.value?.starting_value) {
    startingValueError.value = false;
  }
};

const updateTargetValue = () => {
  if (targetValueError?.value && indicator?.value?.target_value) {
    targetValueError.value = false;
  }
};
</script>

<style scoped>
.bgOpacity {
  background: rgba(62, 82, 202, 0.16);
}
</style>
