<template>
  <svn-pro-card-learn
    card-type="module"
    :card-image="backgroundImage"
    :cardTitle="title"
    :duration="duration"
    :isFavorited="isFavorite"
    :reactionCount="reationCount"
    :reactionList="reationTypes"
    :shareable="true"
    :editable="editable"
    :favoriteIcon="isFavorite ? 'custom:mingcute:heart-fill' : 'custom:mingcute:heart-line'"
    shareableIcon="custom:mingcute:link-line"
    :isAcquired="submissionStatus == 'acquired'"
    @on-favorite="toggleFavorite"
    @on-share="copyModuleLink"
    @go-to-item-show="$emit('go-to-item-show')"
    :themes="themeList"
    editableIcon
  >
    <template v-if="editable" #menu>
      {{ null }}
      <svn-pro-menu v-if="!toDiscover && amICreatorOfEntityOrAdmin(moduleCreatorId)">
        <template #activator>
          <svn-pro-icon-button
            icon="custom:mingcute:more-2-line"
            variant="elevated"
            color="surface"
          />
        </template>

        <template #dropdown>
          <v-list-item
            value="edit_module"
            density="compact"
            :active="false"
            @click="duplicateModule"
          >
            {{ $t('Duplicate module') }}
          </v-list-item>

          <v-list-item
            value="delete_module"
            density="compact"
            :active="false"
            v-if="learnModule?.status !== 'draft'"
            @click="addModuleToPlaylist"
          >
            {{ $t('Add to playlist') }}
          </v-list-item>

          <v-list-item
            value="delete_module"
            density="compact"
            :active="false"
            @click="openDialogDeleteModude"
            class="text-error"
          >
            {{ $t('Delete module') }}
          </v-list-item>
        </template>
      </svn-pro-menu>
    </template>
  </svn-pro-card-learn>

  <!-- Dialog edit Playlist -->
  <dialog-delete-content
    v-if="learnModule?.learnTrainings?.length"
    ref="deleteModuleDialog"
    :items="learnModule?.learnTrainings"
    :title="$t(`Module will be deleted with their trainings`)"
    :description="
      $t(
        `If this module is the only content of a training, the training will be deleted. Training(s) containing only this module :`,
      )
    "
    @delete-content="deleteModule"
  />

  <svn-pro-dialog-validation
    v-else
    ref="deleteModuleDialog"
    icon="noto:warning"
    F
    :action-two-title="$t('Cancel')"
    :action-one-title="$t('Delete')"
    :title="$t(`Module will be deleted`)"
    :content-text="
      learnModule?.status === 'draft'
        ? $t('This is a permanent action.')
        : $t(
            'Deleted modules are stored for 30 days. After this period, they will be permanently deleted.',
          )
    "
    @click-primary-button="deleteModule"
  >
    <template #activator="{ props }">
      <div class="hidden" />
    </template>
  </svn-pro-dialog-validation>
</template>

<script setup>
import { storeToRefs } from 'pinia';
import { useMobileStore } from '@/store/mobile';
import { useSnackbar } from '@/store/snackbar';
import { useUserStore } from '@/store/user.js';
import { computed, ref } from 'vue';
import { useRouter } from 'vue-router';
import DialogDeleteContent from '@/components/BktPopUp/Dialogs/learn/DialogDeleteContent.vue';

const { isMobile } = storeToRefs(useMobileStore());
const deleteModuleDialog = ref(false);

const { amICreatorOfEntityOrAdmin } = useUserStore();

const props = defineProps({
  id: { type: Number, default: null },
  learnModule: { type: Object, default: {} },
  moduleCreatorId: { type: Number, default: null },
  backgroundImage: { type: String, default: '' },
  isFavorite: { type: Boolean, default: false },
  title: { type: String, default: '' },
  toDiscover: { type: Boolean, default: false },
  isDraft: { type: Boolean, default: false },
  editable: { type: Boolean, default: false },
  link: { type: String, default: '' },
  themeList: { type: Array, default: null },
  reactions: { type: Object, default: null },
  duration: { type: String, default: '' },
  submissionStatus: { type: String, default: '' },
});

const reationCount = computed(() => {
  let count = 0;
  filteredReations.value.forEach((r) => {
    if (r.count) {
      count = count + r.count;
    }
  });

  return count;
});

const reationTypes = computed(() => {
  let types = [];
  filteredReations.value.forEach((r) => {
    if (r.count && !types.includes(r.type)) {
      types.push(r.type);
    }
  });

  return types;
});

const filteredReations = computed(() => {
  return props.reactions?.filter((r) => r.type === 'like' || r.type === 'recommend');
});
const router = useRouter();

const emit = defineEmits([
  'duplicate-module',
  'toggle-favorite',
  'add-to-playlist',
  'delete-module',
  'go-to-item-show',
]);

const snackbar = useSnackbar();

const copyModuleLink = () => {
  const link = router.resolve({ name: 'module_show', params: { id: props?.id } });

  navigator.clipboard
    .writeText(new URL(link.href, window.location.origin).href)
    .then(() => {
      snackbar.setBgColor('onSurface');
      snackbar.setCustomClass(isMobile.value ? 'mb-[72px]' : 'mb-[16px]');
      snackbar.setMsg('Link copied to clipboard.');
      snackbar.displaySnackBar();
    })
    .catch(() => {
      snackbar.setBgColor('error');
      snackbar.setCustomClass(isMobile.value ? 'mb-[72px]' : '');
      snackbar.displaySnackBar('Error copying link to clipboard');
    });
};

const openDialogDeleteModude = () => {
  if (props.learnModule?.learnTrainings?.length) {
    deleteModuleDialog.value.deleteDialog = true;
  } else {
    deleteModuleDialog.value.dialogRef.dialog = true;
  }
};

const toggleFavorite = () => {
  emit('toggle-favorite');
};

const duplicateModule = () => {
  emit('duplicate-module');
};

const addModuleToPlaylist = () => {
  emit('add-to-playlist');
};

const deleteModule = () => {
  emit('delete-module', props.id);
};
</script>

<style scoped></style>
