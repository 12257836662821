
<template>
  <template-header-question
    :is-deleted="isDeleted"
  >
    <template #title>
      <svn-pro-info-tag
        tag-type="primary"
        :text="$t('Paragraph')"
        tag-style="filled"
        class="max-w-[900px] h-[32px] justify-self-start"
        leading-item="icon"
        icon="mingcute:text-2-line"
      />
    </template>
    <template #body>
      <div class="h-40 mb-16 mt-3">
        <quill-editor
          v-model:content="interviewQuestion.text"
          content-type="html"
          :enable="!isDeleted"
          :placeholder="$t('Type your text here')"
          @update:content="debounceUpdateQuestion($event)"
        />
      </div>
    </template>
  </template-header-question>
</template>

<script setup>
import TemplateHeaderQuestion from "@/components/interviewApp/template/Edit/TemplateHeaderQuestion.vue";
import {QuillEditor} from "@vueup/vue-quill";
import { debounce} from "lodash";

const emit = defineEmits(["input"]);

const props = defineProps({
  interviewQuestion: { type: Object, required: true },
  isDeleted: { type: Boolean, required: true }
})

const debounceUpdateQuestion = debounce((event) => {
  emit('input', event)
}, 300)
</script>
