import {createPinia} from "pinia";
import autoSetter from "pinia-plugin-autosetter";
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import router from "@/router/index.js";
import { markRaw } from 'vue';

const pinia = createPinia();

pinia.use(autoSetter);

pinia.use(piniaPluginPersistedstate)

// Implementing router in pinia store
pinia.use(({ store }) => {
  store.router = markRaw(router);
})
export default pinia