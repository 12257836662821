<template>
  <pop-up-skeleton>
    <template #body>
      <div>
        <div v-if="myCurrentPage == 0">
          <FirstSlider />
        </div>
    
        <div v-else>
          <SecondSlider />
        </div>
    
        <div class="flex-row-between-centered">
          <bkt-button
            :style="{opacity: myCurrentPage > 0 ? 1 : 0}"
            iconify="akar-icons:arrow-left"
            type="white-grey"
            @click="prev"
          >
            {{ $t('Back') }}
          </bkt-button>
    
          <bkt-button
            v-if="myCurrentPage === 0"
            iconify="akar-icons:arrow-right"
            type="interview"
            :left="false"
            @click="next"
          >
            {{ $t('Next') }}
          </bkt-button>
    
          <bkt-button
            v-else
            type="interview"
            @click="closePopUp()"
          >
            {{ $t('Close') }}
          </bkt-button>
        </div>
      </div>
    </template>
  </pop-up-skeleton>
</template>

<script setup>
import { ref } from 'vue' 
import PopUpSkeleton from '../popUpComponents/PopUpSkeleton.vue';
import BktButton from "../../components/button/BktButton.vue"
import FirstSlider from '../../components/infoFilterCategoryTagsComponents/FirstSlider.vue'
import SecondSlider from '../../components/infoFilterCategoryTagsComponents/SecondSlider.vue'
import { usePopUpStore } from "../../store/pop-up.js";

const { closePopUp } = usePopUpStore();
const myCurrentPage = ref(0)
const next = () => {
  myCurrentPage.value++
}
const prev = () => {
  myCurrentPage.value--
}
</script>

<style>
.slider-pagination-bullet {
  /*background-color: #CDCDCD !important;*/
}
.slider-pagination-bullet-active {
  background-color: #3177B7 !important;
}
.slider-wrapper {
  align-items: start !important;
}
</style>
