<template>
  <pop-up-skeleton>
    <template #title>
      <p class="text-xl text-fakeBlack font-medium">
        {{ $t('Create new playlist') }}
      </p>
    </template>

    <template #body>
      <div class="flex flex-col gap-6 mb-6">
        <!-- Title input -->
        <v-text-field
          v-model="title"
          variant="outlined"
          class="text-sm text-fakeBlack font-normal input"
          persistent-counter
          maxlength="70"
          counter
          density="compact"
        >
          <template #counter="{ counter, max = 70, value }">
            <div class="flex items-start self-stretch px-4 pt-1 self-start">
              <span class="text-fakeBlack text-xs font-normal">
                {{ value }} / {{ max }}
              </span>
            </div>
          </template>

          <template #label>
            <p class="text-sm text-darkGrey font-normal">
              {{ $t('Playlist title') }}
            </p>
          </template>
        </v-text-field>

        <!-- Description input -->
        <v-text-field
          v-model="description"
          variant="outlined"
          class="text-sm text-fakeBlack font-normal input"
          persistent-counter
          maxlength="200"
          counter
          density="compact"
        >
          <template #counter="{ counter, max = 200, value }">
            <div class="flex items-start self-stretch px-4 pt-1">
              <span class="text-fakeBlack text-xs font-normal">
                {{ value }} / {{ max }}
              </span>
            </div>
          </template>

          <template #label>
            <p class="text-sm text-darkGrey font-normal">
              {{ $t('Description') }}
            </p>
          </template>
        </v-text-field>
      </div>
    </template>

    <template #buttons>
      <v-btn
        :text="$t('Validate')"
        variant="flat"
        :disabled="!(title && description)"
        class="text-xs normal-case"
        color="primary"
        @click="createPlaylist"
      />
    </template>
  </pop-up-skeleton>
</template>

<script setup>
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import { usePopUpStore } from '@/store/pop-up';
import PopUpSkeleton from './PopUpSkeleton.vue';

const id = ref(799)

const title = ref('')

const description = ref('')

const router  = useRouter()

const { closePopUp } = usePopUpStore()

const createPlaylist = () => {
  router.push({ name: "playlist_show", params: { id: id?.value }  })
  closePopUp()
}
</script>

<style scoped>
.input :deep(.v-input__details) {
  display: flex;
  flex-direction: row-reverse;
  padding: 0px;
}
</style>
