<template>
  <div class="w-full flex flex-col items-start p-4 gap-1 rounded-[8px] border border-darkGrey">
    <!-- Title -->
    <div class="w-full">
      <!-- Block has been saved -->
      <div class="flex items-center self-stretch">
        <!-- Block title -->
        <div class="flex-1">
          <svn-pro-title h6 medium class="line-clamp-1 break-all">
            {{ block.title }}
          </svn-pro-title>
        </div>

        <!-- Buttons -->
        <div class="flex flex-end items-start gap-2">
          <!-- Edit information block -->
          <svn-pro-modal
            ref="editBlockModal"
            :title="$t('Shared information')"
            :actionOneTitle="$t('Save')"
            :primary-loading="loading"
            @click-primary-button="updateInformationBlock"
            @click-close="resetInputs"
            @click-outside="resetInputs"
          >
            <template #activator="{ props }">
              <svn-pro-icon-button
                v-bind="props"
                variant="text"
                icon="custom:mingcute:edit-2-line"
                color="onSurfaceVariant"
              />
            </template>

            <template #text>
              <div class="w-full flex flex-col gap-10">
                <!-- Title -->
                <svn-pro-text-field
                  v-model="newBlock.title"
                  :label="$t('Title*')"
                  variant="outlined"
                  :error="linkTitleError.error"
                  :error-messages="linkTitleError.errorMessage"
                  @update:model-value="updateTitle"
                />

                <!-- Link -->
                <svn-pro-text-field
                  v-model="newBlock.link"
                  :label="$t('Link*')"
                  variant="outlined"
                  :error="linkValueError.error"
                  :error-messages="linkValueError.errorMessage"
                  @update:model-value="updateLink"
                />
              </div>
            </template>
          </svn-pro-modal>

          <!-- Delete information block -->
          <svn-pro-dialog
            ref="deleteBlockModal"
            icon="noto:warning"
            :title="$t('Shared information will be deleted')"
            :content-text="`${block?.title} ${$t('will no longer be accessible to employees.')}`"
            :actionOneTitle="$t('Delete')"
            :actionTwoTitle="$t('Cancel')"
            @click-primary-button="deleteInformationBlock"
            @click-secondary-button="closeDialog"
          >
            <template #activator="{ props }">
              <svn-pro-icon-button
                v-bind="props"
                variant="text"
                icon="custom:mingcute:delete-2-line"
                color="onSurfaceVariant"
              />
            </template>
          </svn-pro-dialog>
        </div>
      </div>
    </div>

    <!-- Link -->
    <svn-pro-text body-large regular color="onSurfaceVariant" class="line-clamp-1 break-all">
      {{ block.link }}
    </svn-pro-text>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';

const props = defineProps({
  block: { type: Object, default: () => {} },
});

const emit = defineEmits(['update-information-block', 'delete-information-block'])

onMounted(() => {
  newBlock.value.title = props.block?.title
  newBlock.value.link = props.block?.link
});

const newBlock = ref({
  title: null,
  link: null,
});
const loading = ref(false);
const editBlockModal = ref(false);
const deleteBlockModal = ref(false);
const linkTitleError = ref({ error: false, errorMessage: '' });
const linkValueError = ref({ error: false, errorMessage: '' });

const updateInformationBlock = () => {
  if (!newBlock.value.title) {
    linkTitleError.value = { error: true, errorMessage: "*required" }
  }
  if (!newBlock.value.link) {
    linkValueError.value = { error: true, errorMessage: "*required" }
  }
  if (!linkTitleError.value.error && !linkValueError.value.error) {
    emit('update-information-block', { title: newBlock?.value?.title, link: newBlock?.value?.link });
    editBlockModal.value.dialog = false;
  }
}

const updateTitle = (value) => {
  if (value && linkTitleError.value.error) {
    linkTitleError.value.error = false
  }
}

const updateLink = (value) => {
  if (value && linkValueError.value.error) {
    linkValueError.value.error = false
  }
}

const resetInputs = () => {
  newBlock.value = {
    title: props?.block?.title,
    link: props?.block?.link,
  }
  linkTitleError.value = { error: false, errorMessage: "" }
  linkValueError.value = { error: false, errorMessage: "" }
}

const closeDialog = () => {
  deleteBlockModal.value.dialog = false
}

const deleteInformationBlock = () => {
  emit('delete-information-block');
  closeDialog()
}
</script>

<style scoped>
* :deep(.v-field){
  border-radius: 8px;
}
</style>