<template>
  <pop-up-manage-copilots
    ref="copilotsRef"
    :campaign-id="campaignId"
  >
    <template #activator>
      {{ null }}
    </template>
  </pop-up-manage-copilots>
</template>

<script setup>
import { ref, onMounted, watch } from "vue";
import { usePopUpStore } from "../../store/pop-up.js";
import PopUpManageCopilots from "@/components/popUpComponents/PopUpManageCopilots.vue";

const { closePopUp } = usePopUpStore();

const copilotsRef = ref({
  manageCopilots: {
    dialog: false
  }
});

onMounted(() => {
  copilotsRef.value.manageCopilots.dialog = true;
});

const props = defineProps({
  campaignId: { type: String, default: null },
  deadline: { type: String, default: null },
});

watch(() => copilotsRef.value.manageCopilots.dialog, (newVal) => {
  if (!newVal) {
    closePopUp();
  }
});
</script>
