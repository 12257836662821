<template>
  <div class="w-full flex flex-col gap-6 px-5 md:!px-0 py-5 md:!max-w-[674px] lg:!max-w-[614px] xl:!max-w-[752px] desktop:!max-w-[1136px] md:!py-6 mx-auto">
    <div />

    <div
      :class="isMdScreen ? 'justify-between' : 'flex-col gap-2 justify-center'"
      class="flex items-center w-full px-5"
    >
      <svn-pro-text subtitle-large color="onSurfaceVariant">
        {{ templateType != 'Survey' ?
          $t("Participant’s firstname interview with Person in charge fullname") :
          $t('Survey - x participants') }}
      </svn-pro-text>

      <svn-pro-text
        subtitle-large
        :class="isMdScreen ? 'self-center' : 'self-end'" 
        class="text-right"
        color="onSurfaceVariant"
      >
        {{ $t("Deadline : dd/mm/yyyy") }}
      </svn-pro-text>
    </div>

    <div
      v-if="description"
      class="break-words w-full description text-center"
      v-html="description"
    />

    <iframe
      v-if="isValidLink"
      id="ytplayer"
      class="mx-auto"
      type="text/html"
      width="100%"
      height="360"
      :src="
        'https://www.youtube.com/embed/' +
          video?.match(
            /.*(?:youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=)([^#\&\?]*).*/
          )[1]
      "
    />

    <question-component-template-preview
      v-for="(question, idx) in questionsData"
      :key="idx"
      :loop-idx="idx"
      :question="question?.data"
    />
    
    <div />
  </div>
</template>

<script setup>
import { computed } from "vue";
import { storeToRefs } from "pinia";
import { useMobileStore } from "@/store/mobile";
import QuestionComponentTemplatePreview from "@/components/interviewApp/interview_answers/QuestionComponentTemplatePreview.vue";

const { isMdScreen } = storeToRefs(useMobileStore());

const props = defineProps(["interviewQuestions", "title", "description", "video", "templateType"]);

const isValidLink = computed(() => {
  return props?.video && props?.video?.match(/youtube\.com/)
});

const questionsData = computed(() => {
  return props?.interviewQuestions?.map((question) => {
    return { data: question };
  });
});
</script>