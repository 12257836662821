import BaseLearnPiecesApi from './base-learn-pieces.api';

class EvaluationApi extends BaseLearnPiecesApi {
  constructor(model, apiUrl) {
    super(model, apiUrl);
  }
  // add scpecifique methods
}

export default EvaluationApi;
