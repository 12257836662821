<template>
  <pop-up-skeleton
    v-if="data.icon"
    :title="data.title"
    :icon="data.icon"
  >
    <template #subtitle>
      <p class="text-sm font-normal text-center text-darkGrey">
        {{ $t(data.description) }}
      </p>
    </template>

    <template #buttons>
      <div
        v-if="data.textConfirm"
        class="w-full flex justify-center"
      >
        <v-btn
          color="primary"
          type="employee"
          class="w-full sm:w-[125px] h-14"
          @click="confirmPopUp"
        >
          <span>{{ $t(data.textConfirm) }}</span>
        </v-btn>
      </div>
    </template>
  </pop-up-skeleton>

  <pop-up-skeleton v-else>
    <template #title>
      <Vue3Lottie
        animation-link="https://assets2.lottiefiles.com/packages/lf20_newtztyc.json"
        style="width: 200px; height: 200px; margin-top: -3rem;"
      />
      <p class="text-center font-medium text-xl">
        {{ data.title }}
      </p>
    </template>

    <template #body>
      <p class="text-sm font-normal text-center text-darkGrey mb-6">
        {{ $t(data.description) }}
      </p>
    </template>

    <template #buttons>
      <div
        v-if="data.textConfirm"
        class="w-full flex justify-center"
      >
        <v-btn
          color="primary"
          type="employee"
          class="w-full sm:w-[125px] h-14"
          @click="confirmPopUp"
        >
          <span>{{ $t(data.textConfirm) }}</span>
        </v-btn>
      </div>
    </template>
  </pop-up-skeleton>
</template>

<script setup>
import { storeToRefs } from "pinia";
import { usePopUpStore } from "@/store/pop-up.js";
import PopUpSkeleton from "./PopUpSkeleton.vue";

const { data } = storeToRefs(usePopUpStore());
const { confirmPopUp } = usePopUpStore();

</script>
